import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import HorizontalList from './HorizontalList'
import RemoveFirmUser from '../shared_firm/RemoveFirmUser'
import ShadowWrapper from './ShadowWrapper'
import UserSubpage from './UserSubpage'
import SessionStore from '../../../../auth/src/stores/SessionStore'
import SendFirmUserInvite from './SendFirmUserInvite'
import UserInformation from './UserInformation'

import './accountInfo.scss'

export default class AccountInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {currentUser: SessionStore.getState().currentUser}
  }

  render() {
    const userData = this.props.user.data

    const hasClients = (userData.client_seats.active + userData.client_seats.draft + userData.client_seats.pending > 0)
    const isActive = (userData.status === 'active')
    return (
      <UserSubpage className='account-info' headerText='Account Info'>
        <ShadowWrapper className='column-wrapper-sb'>
          <StatusRow
            firstName={userData.first_name}
            isActive={isActive}
            seats={userData.client_seats}
            status={userData.status}
            type={userData.type}
            params={this.props.params}
          />
          <UserInformation
            {...userData}
            accountManager={this.state.currentUser.account_manager}
            firmConfig={this.props.firmConfig}
            params={this.props.params}
          />
          <RemoveFirmUser
            userSeatId={this.props.params.firmUserSeatId}
            user={userData}
            firmId={this.props.params.firmId}
            firmConfig={this.props.firmConfig}
            isActive={isActive}
            hasClients={hasClients}
          />
        </ShadowWrapper>
      </UserSubpage>
    )
  }
}

AccountInfo.defaultProps = {
  user: {
    data: {},
    errors: [],
    message: null
  }
}

AccountInfo.propTypes = {
  firmConfig: PropTypes.object,
  user: PropTypes.shape({
    data: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.oneOf(['active', 'draft', 'pending']),
      type: PropTypes.oneOf(['AdvisorSeat', 'AssistantSeat']),
      external_id: PropTypes.string,
      client_seats: PropTypes.shape({
        active: PropTypes.number,
        draft: PropTypes.number,
        pending: PropTypes.number
      })
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired
}


const StatusMessage = props => {
  switch (props.status) {
    case 'active':
      return (<span className='status'><strong>ACTIVE</strong></span>)
    case 'pending':
      return (<span className='status'><strong>PENDING</strong> - Invitation sent but not yet accepted.</span>)
    case 'draft':
      return (<span className='status'><strong>DRAFT</strong> - Invitation hasn't been sent yet.</span>)
    default:
      break
  }
}

StatusMessage.propTypes = {
  status: PropTypes.oneOf(['active', 'draft', 'pending']).isRequired
}


const ButtonOrStats = props => {
  switch (props.status) {
    case 'active':
      if (props.type === 'AdvisorSeat') {
        return (
          <HorizontalList className='seat-data'>
            <span>{props.seats.active} {`client${props.seats.active === 1 ? '' : 's'}`} active</span>
            <span>{props.seats.pending} invited</span>
            <span>{props.seats.draft} draft</span>
          </HorizontalList>
        )
      }

      return <span />
    case 'pending':
    case 'draft':
      return (<SendFirmUserInvite firstName={props.firstName} params={props.params} status={props.status} />)
    default:
      break
  }
}

ButtonOrStats.propTypes = {
  firstName: PropTypes.string.isRequired,
  seats: PropTypes.shape({
    active: PropTypes.number,
    draft: PropTypes.number,
    pending: PropTypes.number
  }).isRequired,
  status: PropTypes.oneOf(['active', 'draft', 'pending']).isRequired,
  type: PropTypes.oneOf(['AdvisorSeat', 'AssistantSeat']).isRequired
}


const StatusRow = props => (
  <div className='row-wrapper'>
    <div>Status:</div>
    <div className={classnames('column-wrapper-sb', {'active-advisor': props.isActive && props.type === 'AdvisorSeat'})}>
      <StatusMessage status={props.status} />
      <ButtonOrStats firstName={props.firstName} seats={props.seats} status={props.status} type={props.type} params={props.params} />
    </div>
  </div>
)

StatusRow.propTypes = {
  firstName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  seats: PropTypes.shape({
    active: PropTypes.number,
    draft: PropTypes.number,
    pending: PropTypes.number
  }).isRequired,
  status: PropTypes.oneOf(['active', 'draft', 'pending']).isRequired,
  type: PropTypes.oneOf(['AdvisorSeat', 'AssistantSeat']).isRequired
}
