import {Link} from 'react-router'
import PropTypes from 'prop-types'
import React from 'react'

import Container from '../../../../lib/Container'

import './deputyInviteDeclined.scss'


export default class DeputyInviteDeclinedController extends React.Component {
  constructor(props) {
    super(props)

    this.state = {storeState: Container.getStore('deputy').getState().data}
  }

  render() {
    return (
      <DeputyInviteDeclined
        firstName={this.state.storeState.inviter_first_name}
        fullName={this.state.storeState.inviter}
        objectPronoun={this.state.storeState.inviter_object_pronoun}
        possessivePronoun={this.state.storeState.inviter_possessive_pronoun}
      />
    )
  }
}


export class DeputyInviteDeclined extends React.Component {
  render() {
    return (
      <div className='deputy-invite-declined'>
        <h3>Thank you.</h3>
        <p>
          We'll let {this.props.firstName} know that you are not interested, and we won't email you again
          about this.
        </p>
        <p>
          If you change your mind, contact {this.props.fullName} and ask {this.props.objectPronoun} to
          invite you again to be {this.props.possessivePronoun} Deputy on Everplans.
        </p>
        <p>
          If you'd like to create your own Everplan simply <Link to='/auth/sign-up'>click here to get started</Link>.
        </p>
      </div>
    )
  }
}

DeputyInviteDeclined.defaultProps = {
  firstName: 'the person who invited you',
  fullName: 'the person who invited you',
  objectPronoun: 'them',
  possessivePronoun: 'their'
}

DeputyInviteDeclined.propTypes = {
  firstName: PropTypes.string,
  fullName: PropTypes.string,
  objectPronoun: PropTypes.string,
  possessivePronoun: PropTypes.string
}
