import {connect} from 'react-redux'
import {Map} from 'immutable'
import {updateResource, readEndpoint} from 'redux-json-api'
import {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import Loader from '../../../../shared_components/NewLoader'

import {cleanResponse} from '../../../../dashboard/src/components/corp_max/redux/apiHelper'
import {constructInterstitialPath} from '../../../../lib/interstitialTools'
import {currentUser, currentUserConfig, setViewedFlagV2} from '../../../../lib/userTools'
import {findResourceByAttributeId, findResourceById} from '../../../../lib/plan_data/userData'
import MixpanelLogger from '../../../../lib/MixpanelLogger'
import routerUtils from '../../../../lib/routerUtils'

const initializeInviteIds = interstitials => (interstitials.migrated_deputy_invites ? interstitials.migrated_deputy_invites.slice(1) : [])

export const DeputiesInviteInterstitialController = props => {
  const [inviteIds, setInviteIds] = useState(() => initializeInviteIds(props.interstitials))
  const [processingAccept, setProcessingAccept] = useState(false)
  const [processingSkip, setProcessingSkip] = useState(false)

  useEffect(() => {
    props.readEndpoint('deputy-invites?include=deputy-ownership.secondary-everplan-owner')

    if (props.location.query.break)
      return

    routerUtils.push(constructInterstitialPath(props.interstitials.migrated_deputy_invites[0], 'migrated_deputy'))
  }, [])

  const popNextInvite = () => {
    setProcessingAccept(false)

    let nextInviteId

    if (inviteIds.length) {
      nextInviteId = inviteIds[0]
      setInviteIds(inviteIds.slice(1))
    }

    return nextInviteId
  }

  const navigateToNextInvite = () => {
    // Bail back to PreInterstitialsHook if the first invite shown was one surfaced because user clicked an email link to it:
    if (props.location.query.break)
      return props.returnToController()

    const nextInviteId = popNextInvite()

    if (nextInviteId)
      routerUtils.push(constructInterstitialPath(nextInviteId, 'migrated_deputy'))
    else
      props.returnToController()
  }

  const acceptDeputyInvite = () => {
    if (processingAccept || processingSkip)
      return

    setProcessingAccept(true)
    MixpanelLogger.track('click_deputy_request_accept')

    props.updateResource({
      type: 'deputy-invites',
      id: props.deputyInvite.get('id'),
      attributes: {
        accept: 'true'
      }
    }).then(() => navigateToNextInvite())
  }

  const skipDeputyInvites = () => {
    if (processingAccept || processingSkip)
      return

    setProcessingSkip(true)
    MixpanelLogger.track('click_deputy_request_not_now')

    setViewedFlagV2({
      user: props.user,
      flagToSet: 'dismissed_deputy_invites_interstitial',
      updateResource: props.updateResource,
      callback: props.returnToController
    })
  }

  return (
    <div className='deputy-invite-interstitial-controller'>
      <Loader loading={props.loading}>
        <div className='central-content-box'>
          {
            routerUtils.childrenWithProps(
              props.children,
              {
                deathReporter: props.deputyOwnership.get('death-reporter'),
                deputizer: props.deputizer,
                processingAccept,
                processingSkip,
                acceptDeputyInvite,
                skipDeputyInvites,
                deputyInvite: props.deputyInvite
              }
            )
          }
        </div>
      </Loader>
    </div>
  )
}

DeputiesInviteInterstitialController.propTypes = {
  deputyInvite: PropTypes.instanceOf(Map),
  deputyOwnership: PropTypes.instanceOf(Map),
  deputizer: PropTypes.instanceOf(Map),
  interstitials: PropTypes.shape({
    migrated_deputy_invites: PropTypes.array
  }),
  loading: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.shape({
      'break': PropTypes.string, // Key is quoted because `break` is a reserved word in JavaScript
      first: PropTypes.string
    })
  }),
  returnToController: PropTypes.func.isRequired,
  readEndpoint: PropTypes.func,
  updateResource: PropTypes.func,
  user: PropTypes.instanceOf(Map)
}

const isLoadingData = api => (
  !(
    api['deputy-ownerships'] &&
    api['secondary-everplan-owners'] &&
    api['deputy-invites']
  )
)

const mapStateToProps = ({api}, {params}) => {
  const deputyOwnership = findResourceByAttributeId({
    resourceList: cleanResponse(api['deputy-ownerships']),
    attribute: 'ownable-id',
    id: params.deputyInviteId
  })

  const userConfig = currentUserConfig(api)

  return ({
    deputyOwnership,
    deputizer: findResourceById({resourceList: cleanResponse(api['secondary-everplan-owners']), id: deputyOwnership.get('everplan-id')}),
    deputyInvite: findResourceById({resourceList: cleanResponse(api['deputy-invites']), id: params.deputyInviteId}),
    user: currentUser({userConfig, users: api.users}),
    loading: isLoadingData(api)
  })
}

export default connect(mapStateToProps, {readEndpoint, updateResource})(DeputiesInviteInterstitialController)
