import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'

import Loader from '../../../../shared_components/NewLoader'
import routerUtils from '../../../../lib/routerUtils'
import sessionUtils from '../../lib/sessionUtils'


export const OpenIdConnectLoginCallback = props => {
  const authenticate = response => {
    const token = response.data.attributes['authorization-token']
    sessionUtils.authenticateAndStartSession(token)
  }

  useEffect(() => {
    const token = props.location.query.id_token
    const organizationSsoSettingId = props.location.query.state

    const resource = {
      type: 'open-id-connect-sessions',
      attributes: {
        token,
        'organization-sso-setting-id': organizationSsoSettingId
      }
    }

    props
      .createResource(resource)
      .then(response => authenticate(response))
      .catch(() => routerUtils.push('/auth/invalid_token/sso_invalid_token'))
  }, [])

  return <Loader loading={true} />
}

OpenIdConnectLoginCallback.propTypes = {
  createResource: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      id_token: PropTypes.string,
      state: PropTypes.string
    })
  })
}

export default connect(null, {createResource})(OpenIdConnectLoginCallback)
