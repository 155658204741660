import {connect} from 'react-redux'
import {useEffect, useCallback, useState} from 'react'
import {createResource, updateResource} from 'redux-json-api'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import DeputyFeedback from './views/DeputyFeedback'
import Loader from '../../../shared_components/NewLoader'

import {useNotificationContext} from '../../../shared_components/notifications/NotificationContext'
import {cleanResponse} from '../../../dashboard/src/components/corp_max/redux/apiHelper'
import Logger from '../../../lib/NewLogger'
import routerUtils from '../../../lib/routerUtils'

const DeputyFeedbackController = props => {
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [disable, setDisable] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const disableButton = () => {
    setDisable(true) // disable submit button when there's an error (errors happens if token has expired or invalid so there's no need to give feedback)
    alwaysNotify.shortError('You already submitted feedback.')
  }

  const logEvent = eventName => Logger.log({name: eventName})

  const submitComment = useCallback(comment => {
    setProcessing(true)
    const resource = {
      id: props.deputyFeedback.get('id'),
      type: 'deputy-feedbacks',
      attributes: {token: props.params.token, comment}
    }

    logEvent('submitted_deputy_feedback')
    props.updateResource(resource).then(() => {
      routerUtils.push('/deputy-feedbacks/done')
    }).catch(disableButton)
  }, [props.deputyFeedback.size])

  useEffect(() => {
    const resource = {
      type: 'deputy-feedbacks',
      attributes: {token: props.params.token}
    }

    logEvent('clicked_deputy_feedback')
    props.createResource(resource)
      .catch(disableButton)
      .finally(() => setLoading(false))
  }, [])

  return (
    <Loader loading={loading}>
      <DeputyFeedback submitComment={submitComment} processing={processing} disable={disable} deputyFeedback={props.deputyFeedback} />
    </Loader>
  )
}

DeputyFeedbackController.propTypes = {
  createResource: PropTypes.func,
  deputyFeedback: PropTypes.instanceOf(Map),
  params: PropTypes.shape({token: PropTypes.string}),
  submitting: PropTypes.bool,
  updateResource: PropTypes.func
}

const mapStateToProps = ({api}) => ({deputyFeedback: cleanResponse(api['deputy-feedbacks']).first() || Map()})

export default connect(mapStateToProps, {createResource, updateResource})(DeputyFeedbackController)
