import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import ContactElementView from './ContactElementView'
import TextElementView from './TextElementView'

const ElementViewByType = props => {
  const elementType = () => {
    switch (props.element.get('type')) {
      case 'CONTACT':
        return ContactElementView
      case 'TEXT':
      case 'TEXT-MULTI':
        return TextElementView
      default:
        return null
    }
  }

  const fieldProps = () => {
    if (props.element.get('type') === 'CONTACT') {
      const contact = props.newContacts.find(
        newContact => props.response.get('value') === newContact.get('id')
      )

      return {
        name: contact.getIn(['data', 'first-name']),
        phoneNumber: contact.getIn(['data', 'phone-numbers', 0])
      }
    } else {
      return {value: props.response.get('value')}
    }
  }

  const Field = elementType()

  return <Field {...fieldProps()} />
}

ElementViewByType.propTypes = {
  element: PropTypes.instanceOf(Map),
  newContacts: PropTypes.instanceOf(List),
  response: PropTypes.instanceOf(Map)
}

export default ElementViewByType
