import CodeBlock from '../../CodeBlock'
import CodeExamples from '../../CodeExamples'
import CollapsibleSection from '../CollapsibleSection'
import {BoxGutterMedium} from '../../../../dashboard/src/components/blocks/Boxes'


const BodyTextSection = () => (
  <CollapsibleSection heading='Body Text'>
    <BoxGutterMedium>
      <span dangerouslySetInnerHTML={{__html: CodeExamples.BodyText}} />
      <CodeBlock>{CodeExamples.BodyText}</CodeBlock>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default BodyTextSection
