import assign from 'object-assign'
import keyMirror from 'keymirror'

import ActionPrototype from '../../../shared_components/ActionPrototype'

const ClientInviteActions = assign({}, ActionPrototype, {
  Types: keyMirror({
    DECLINED_CLIENT_INVITE: null,
    DID_RESEND_CONFIRMATION: null,
    DID_SUBMIT_ACTIVATION_FORM: null,
    GOT_CLIENT_INVITE: null,
    GOT_CLIENT_INVITE_LANDING: null
  }),
  declineClientInvite(token) {
    this.fireApi(
      'get',
      `client_onboardings/decline_invite/${token}`,
      null,
      {
        JSONHead: 'client_invite',
        successAction: this.Types.DECLINED_CLIENT_INVITE
      }
    )
  },
  fetchClientInvite(clientInviteId) {
    this.fireApi(
      'get',
      `client_onboardings/${clientInviteId}/client_invite`,
      null,
      {
        JSONHead: 'client_invite',
        successAction: this.Types.GOT_CLIENT_INVITE
      }
    )
  },
  fetchClientInviteLanding(token) {
    this.fireApi(
      'get',
      `client_onboardings/${token}/invite_landing`,
      null,
      {
        JSON: 'client_invite',
        successAction: this.Types.GOT_CLIENT_INVITE_LANDING
      }
    )
  },
  resendConfirmation(data) {
    this.fireApi(
      'post',
      'client_onboardings/resend_confirmation',
      data,
      {
        JSONHead: 'resend_confirmation',
        successAction: this.Types.DID_RESEND_CONFIRMATION
      }
    )
  },
  submitActivationForm(data, token) {
    this.fireApi(
      'post',
      `client_onboardings/${token}/client_create`,
      data,
      {
        JSONHead: 'client_invite',
        successAction: this.Types.DID_SUBMIT_ACTIVATION_FORM
      }
    )
  }
})

export default ClientInviteActions
