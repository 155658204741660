import {useState} from 'react'
import PropTypes from 'prop-types'

import Item from '../../../../../dashboard/src/components/corp_max/item/Item'

import {
  goToHouseholdDetail,
  proAddMorePath,
  proEditCompoundItemPath,
  proEditItemPath,
  proNewCompoundItemPath,
  proNewItemPath,
  proViewItemPath
} from '../../../../../lib/navigation/proNavigation'
import {useEverplanData} from '../../../../../lib/hooks'
import {itemBySlug} from '../../../../../lib/plan_data/item'


const ProItemController = props => {
  const {itemResponses, newContacts} = useEverplanData(props.params.everplanId)

  const [hideItem, setHideItem] = useState(false)

  const exit = () => {
    setHideItem(true) // needed to be set to stop the flickering of an empty item when deleting a response group.
    goToHouseholdDetail({clientSeatId: props.params.clientSeatId})
  }

  const pathParams = ({elementId, groupId}) => ({
    ...props.params,
    elementId,
    groupId,
    path: props.location.pathname
  })

  const addMorePath = ({elementId, groupId}) => proAddMorePath(pathParams({elementId, groupId}))
  const editCompoundItemPath = ({elementId, groupId}) => proEditCompoundItemPath(pathParams({elementId, groupId}))
  const editItemPath = ({elementId, groupId}) => proEditItemPath(pathParams({elementId, groupId}))
  const newCompoundItemPath = ({elementId, groupId}) => proNewCompoundItemPath(pathParams({elementId, groupId}))
  const newItemPath = ({elementId, groupId}) => proNewItemPath(pathParams({elementId, groupId}))
  const viewItemPath = ({elementId, groupId}) => proViewItemPath(pathParams({elementId, groupId}))

  const itemResponse = itemBySlug({items: itemResponses, itemSlug: props.params.itemSlug})
  const ownerships = itemResponse.get('owners')

  return (
  // `corp-max-controller` is here to ensure styling looks correct for compound items:
    <div className='pro-item-controller corp-max-controller'>
      <Item
        {...props}
        hideItem={hideItem}
        ownerships={ownerships}
        newContacts={newContacts}
        itemResponse= {itemResponse}
        addMorePath={addMorePath}
        editCompoundItemPath={editCompoundItemPath}
        editItemPath={editItemPath}
        exit={exit}
        newCompoundItemPath={newCompoundItemPath}
        newItemPath={newItemPath}
        viewItemPath={viewItemPath}
        context={'pro_client_dashboard'}
      />
    </div>
  )
}

ProItemController.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  params: PropTypes.object
}

export default ProItemController
