import PropTypes from 'prop-types'
import {Map} from 'immutable'

import EditLogoContainer from './EditLogoContainer'

const EditOrganizationLogo = props => (
  <EditLogoContainer
    field='organization-logo'
    altText='organization-logo'
    dataUrl='logoUrl'
    headerText='Organization Logo'
    setLogoUrl='setLogoDataUrl'
    organization={props.organization}
    processing={props.processing}
    {...props}
  />
)


EditOrganizationLogo.propTypes = {
  organization: PropTypes.instanceOf(Map),
  processing: PropTypes.bool
}

export default EditOrganizationLogo
