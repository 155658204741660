import PropTypes from 'prop-types'
import React from 'react'
import {Map} from 'immutable'

import Button from '../../../../shared_components/Button'
import Modal from '../../../../shared_components/Modal'
import modalWrapper from '../../../../shared_components/ModalWrapper'
import routerUtils from '../../../../lib/routerUtils'

export class StartAddClients extends React.Component {
  addClient() {
    routerUtils.push('pro/clients/add_individual')
  }

  render() {
    const hasSeats = this.props.stats.get('unlimited') || this.props.stats.get('remaining-seats') > 0
    return (
      <div className='start-add-clients'>
        {hasSeats && (
          <div>
            <button className='btn btn-primary invite-primary' onClick={this.addClient}>Add Client(s)</button>
          </div>
        )}
        {
          !hasSeats && (
            <div>
              <button className='btn btn-primary invite-primary' onClick={this.context.showModal}>Add Client(s)</button>
              <Modal visible={this.props.showModal} closer={this.context.closeModal}>
                <div>
                  <p>You have used all of the customer plans available in your current Everplans agreement. To add more customer plans please contact your account manager.</p>
                  <Button className='btn btn-cancel' onClick={this.context.closeModal}>Close</Button>
                </div>
              </Modal>
            </div>
          )
        }
      </div>
    )
  }
}

StartAddClients.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

StartAddClients.propTypes = {
  stats: PropTypes.instanceOf(Map),
  showModal: PropTypes.bool
}

StartAddClients.defaultProps = {stats: {remaining_seats: 0}}

export default modalWrapper(StartAddClients)
