import React from 'react'
import {If} from '../../../../shared_components/deprecated/IfFlag'
import Header from '../../../../shared_components/header/Header' // here solely for the class member appName
import {DropDown, DropDownMenu} from '../../../../shared_components/DropDown'
import * as Links from '../../../../shared_components/Links'

class AccountDropdown extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    var greeting = this.props.currentUser ? this.props.currentUser.display_name : ''
    return (
      <DropDown className='header-menu-item' title={greeting}>
        <DropDownMenu>
          <ul className='account-dropdown'>
            <li><Links.AccountSettings /></li>
            {this.props.currentUser.account_manager && <li><a href='#/pro/firm-admin/all-firms'>All Firms</a></li>}
            <li><Links.ProHelp /></li>
            <li><Links.SignOut /></li>
            <If flag={this.props.currentUser.firm_user}>
              <hr className='navLine' />
              {Header.appName !== 'auth' && <li><Links.MyPersonalEverplan currentUser={this.props.currentUser} /></li>}
              {Header.appName === 'auth' && this.props.currentUser.firm_user && <li><Links.MyProDashboard /></li>}
            </If>
          </ul>
        </DropDownMenu>
      </DropDown>
    )
  }
}

export default AccountDropdown
