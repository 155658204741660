import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const OAuth2Actions = new LegacyActionFactory(['SIGNED_IN'])

OAuth2Actions.signIn = params => {
  OAuth2Actions.fireApi(
    'post',
    'sessions/oauth/sign_in',
    {session: {token: params.token}},
    {
      JSONHead: 'session',
      successAction: OAuth2Actions.Types.SIGNED_IN
    }
  )
}

export default OAuth2Actions
