import {List} from 'immutable'
import PropTypes from 'prop-types'

import {blockClientRemoval} from '../../../../../lib/removeClientsTools'
import {humanizedFullName} from '../../../../../lib/nameTools'

import BlockRemovalWarning from './BlockRemovalWarning'
import RemoveClientModal from './RemoveClientModal'

const NonActiveActiveHouseholdRemoveModal = props => {
  const clientSeats = props.clientSeats

  return (
    <div className='non-active-active-household-remove-modal'>
      {
        clientSeats.map(seat => {
          const disabled = blockClientRemoval({clientSeats, seat})
          const fullName = humanizedFullName(seat)
          const firstName = seat.get('first-name')

          return (
            <div key={seat.get('id')}>
              <RemoveClientModal
                {...props}
                buttonText={`Remove ${fullName}`}
                clientSeat={seat}
                disabled={disabled}
                fullName={fullName}
                firstName={firstName}
              />

              <BlockRemovalWarning
                disabled={disabled}
                clientSeats={clientSeats}
              />
            </div>
          )
        })}
    </div>
  )
}

NonActiveActiveHouseholdRemoveModal.propTypes = {
  clientSeats: PropTypes.instanceOf(List)
}

export default NonActiveActiveHouseholdRemoveModal
