import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {List} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import {Text} from '../../../../../dashboard/src/components/blocks/Texts'
import Loader from '../../../../../shared_components/NewLoader'
import {ProResourceCenter} from '../../../../../shared_components/Links'
import Resource from './Resource'

import {cleanResponse} from '../../../../../dashboard/src/components/corp_max/redux/apiHelper'

import './proResources.scss'

export const ProResources = props => {
  const [loading, setLoading] = useState(false)
  const numberOfResources = props.hasClientActivities ? 3 : 2

  useEffect(() => {
    if (props.proResources.isEmpty()) {
      setLoading(true) // setting loading to true here so that test scenario where resources are already present won't fail - Atanda
      props.readEndpoint('pro-resources').finally(() => setLoading(false))
    }
  }, [props.proResources.size])

  return (
    <div className='pro-resources'>
      {
        !(props.proResources.isEmpty() && !loading) && (
          <Loader loading={loading}>
            <div className='featured-resources'>
              <Text className='heading flex-container'>
                <h1>Featured Resources</h1>
                <ProResourceCenter className='resources-link'>All Resources »</ProResourceCenter>
              </Text>
              <div className='resources flex-container'>
                {
                  props.proResources
                    .sortBy(resource => resource.get('sort-order'))
                    .take(numberOfResources).map(resource => <Resource key={resource.get('id')} data={resource} hasClientActivities={props.hasClientActivities} />)
                }
              </div>
            </div>
          </Loader>
        )
      }
    </div>
  )
}

ProResources.propTypes = {
  hasClientActivities: PropTypes.bool,
  proResources: PropTypes.instanceOf(List),
  readEndpoint: PropTypes.func
}

const mapStateToProps = ({api}) => ({
  proResources: cleanResponse(api['pro-resources'])
})

export default connect(mapStateToProps, {readEndpoint})(ProResources)
