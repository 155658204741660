import classnames from 'classnames'

import './boxGroup.scss'


// BoxGroup is a container to hold multiple boxes to be arranged in rows.
// All Box children must pass `wrap={true}`, as that wraps them in a div
// with the correct className of box-wrapper. --BLR
const BoxGroup = ({children, className, ...otherProps}) => (
  <div {...otherProps} className={classnames('box-group', className)}>
    {children}
  </div>
)

export default BoxGroup
