import PropTypes from 'prop-types'
import {useRef, useCallback, useState, useEffect} from 'react'

import Forms from '../../../../../shared_components/forms'
import Button from '../../../../../shared_components/Button'
import greenCheckIcon from '../../../../../shared_assets/green-check-icon.svg'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'

import './selfSignUpCompletionForm.scss'

const SelfSignUpCompletionForm = props => {
  const formRef = useRef()
  const dobRef = useRef()
  const [errors, setErrors] = useState([])

  useEffect(() => {
    MixpanelLogger.track('view_profile_completion_modal', {sign_up: 'self_sign_up'})
  }, [])

  const submitForm = useCallback(() => {
    setErrors([]) // reset the errors

    MixpanelLogger.track('click_profile_completion_continue', {sign_up: 'self_sign_up'})

    if (formRef.current) {
      const form = formRef.current

      const newFormErrors = form.validate()

      if (newFormErrors.length === 0) {
        const formData = form.formData()
        props.onSubmit(formData)
      } else {
        setErrors(newFormErrors)
      }
    }
  })

  const formErrors = () => {
    if (errors.length > 0)
      return ['Please see errors above.']
  }

  return (
    <div className='self-sign-up-completion-form'>
      <div className='confirmation-container'>
        <img alt='Email address confirmed' src={greenCheckIcon} />
        <p className='email-confirmed'> Email address confirmed</p>
      </div>
      <h1>Welcome, {props.name.first_name}.</h1>
      <p>Please provide your date of birth and create a secure password to get started.</p>
      <Forms.Form ref={formRef}>
        <Forms.DateInput label='Date of birth' ref={dobRef} name='birthdate' validator={Forms.Validators.birthdateValidator} />
        <Forms.Password label='Password' name='password' />
        <Forms.ServiceTerms name='agreement_accepted' />
        <Forms.Wrapper errors={props.formErrors || formErrors()}>
          <Button className='btn-success' loading={props.processing} onClick={submitForm}>Continue</Button>
        </Forms.Wrapper>
      </Forms.Form>
    </div>
  )
}

SelfSignUpCompletionForm.propTypes = {
  formErrors: PropTypes.string,
  name: PropTypes.shape({first_name: PropTypes.string, last_name: PropTypes.string}),
  processing: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default SelfSignUpCompletionForm
