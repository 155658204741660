import React from 'react'
import SessionStore from '../../stores/SessionStore'
import OTPSignIn from './OTPSignIn'
import preAuthHook from '../../lib/preAuthHook'


export default class TfaSecondarySignIn extends React.Component {
  componentDidMount() { preAuthHook() }

  render() {
    const phoneId = SessionStore.getState().secondaryPhone ? SessionStore.getState().secondaryPhone.id : null
    return (
      <OTPSignIn number={SessionStore.getObfuscatedSecondaryNumber()} phoneId={phoneId} />
    )
  }
}
