import PropTypes from 'prop-types'

const BackupCode = ({updateRoute}) => (
  <div className='alternate-delivery-option'>
    <input name='mode' type='radio' className='tfa-radio-btn' value='tfa_backup' onChange={updateRoute} />
    <div>
      Enter my 16-digit backup code
    </div>
  </div>
)

BackupCode.propTypes = {
  updateRoute: PropTypes.func
}

export default BackupCode
