import PropTypes from 'prop-types'
import classnames from 'classnames'

import Modals from './core/modals/Modals'
import present from '../shared_assets/present@3x.png'

import './leadGenerationModal.scss'


const LeadGenerationModal = ({
  children,
  className,
  footerText,
  footerTitle,
  headerText,
  headerTitle,
  launcherText
}) => (
  <Modals.LinkPopUpModalLarge
    className={classnames('lead-generation-modal', className)}
    launcher={<a className='launcher-text'>{launcherText}</a>}>
    <img src={present} alt='present' />
    <div className='header-section'>
      <h1>{headerTitle}</h1>
      <div className='section-text'>{headerText}</div>
    </div>
    {children}
    <div className='footer-section'>
      <h6>{footerTitle}</h6>
      <div className='section-text'>{footerText}</div>
    </div>
  </Modals.LinkPopUpModalLarge>
)

LeadGenerationModal.propTypes = {
  children: PropTypes.node,
  footerText: PropTypes.string,
  footerTitle: PropTypes.string,
  headerText: PropTypes.string,
  headerTitle: PropTypes.string,
  launcherText: PropTypes.string
}

export default LeadGenerationModal
