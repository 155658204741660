import PropTypes from 'prop-types'

import HorizontalList from '../../shared_firm/HorizontalList'
import PurchasedSeatsCount from './PurchasedSeatsCount'


const ActiveLicenseClientSeatsUsage = ({active_client_seats_count, client_seats, client_seats_used, pending_client_seats_count, unlimited}) => (
  <HorizontalList className='active-license-client-seats-usage'>
    <PurchasedSeatsCount seatsCount={client_seats} unlimited={unlimited} />
    {/* Need all these separate spans to correctly apply the styles due to how `HorizontalList` works. --BLR */}
    <span>{active_client_seats_count} Active</span>
    <span>{pending_client_seats_count} Invited</span>
    {!unlimited && <span>{client_seats - client_seats_used} Remaining</span>}
  </HorizontalList>
)

ActiveLicenseClientSeatsUsage.defaultProps = {
  active_client_seats_count: 0,
  client_seats: 0,
  client_seats_used: 0,
  pending_client_seats_count: 0,
  unlimited: false
}

ActiveLicenseClientSeatsUsage.propTypes = {
  active_client_seats_count: PropTypes.number,
  client_seats: PropTypes.number,
  client_seats_used: PropTypes.number,
  pending_client_seats_count: PropTypes.number,
  unlimited: PropTypes.bool
}

export default ActiveLicenseClientSeatsUsage
