import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import validate from 'validate.js'

import ButtonGroup from '../../../../shared_components/deprecated/ButtonGroup'
import {ControlButton} from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import ProModalForm from '../../../../shared_components/ProModalForm'
import ShadowHeader from '../shared_firm/ShadowHeader'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import FirmActions from '../../actions/FirmActions'
import {noop} from '../../../../lib/tools'
import {trimString} from '../../../../lib/textTools'

import './addFirmUser.scss'

export class AddFirmUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
    this.addFirmUser = this.addFirmUser.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }


  addFirmUser() {
    this.props.updating(() => {
      this.setState({showModal: false})
    })
    const data = Object.assign({id: this.props.firmId, advisor_seat: this.props.userType !== 'Assistant'}, this.refs.form.formData())
    if (this.refs.form.validate().length === 0) {
      const trimData = {...data, zip: trimString(data.zip), email: trimString(data.email)}
      FirmActions.addFirmUser(trimData)
    } else {
      this.props.finished()
    }
  }

  emailValidator(name, data) {
    const error = validate.single((trimString(data.email)), {presence: true, email: true})
    if (error)
      return {name: 'email', errors: [`Email ${error}.`]}
  }

  zipValidator(name, data) {
    const pattern = /\d{5}(-\d{4})?/
    const error = validate.single((trimString(data.zip)), {presence: true, format: pattern})
    if (error)
      return {name: 'zip', errors: ['Please enter a valid ZIP.']}
  }

  toggleModal() {
    this.props.finished()
    this.setState({showModal: !this.state.showModal})
  }

  render() {
    return (
      <section className='add-firm-user'>
        <ShadowHeader headerText={`${this.props.userType}s`}>
          <ControlButton
            className={classnames({'disabled-btn': this.props.noSeatsRemaining})}
            faClass='fa-plus-circle'
            buttonText={`Add ${this.props.userType}`}
            onClick={this.props.noSeatsRemaining ? noop : this.toggleModal}
          />
        </ShadowHeader>
        <ProModalForm closer={this.toggleModal} errors={this.props.errors} ref='form' title={`Add ${this.props.userType}`} visible={this.state.showModal}>
          <Forms.Name label='Name:' name='name' />
          <Forms.Input label='Email Address:' name='email' validator={this.emailValidator} />
          <Forms.Input label='ZIP Code:' name='zip' validator={this.zipValidator} />
          <ButtonGroup continueText='Add' loading={this.props.processing} onCancel={this.toggleModal} onContinue={this.addFirmUser} />
        </ProModalForm>
      </section>
    )
  }
}

AddFirmUser.defaultProps = {
  data: {},
  errors: [],
  firmId: '',
  processing: false,
  userType: ''
}

AddFirmUser.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
  finished: PropTypes.func,
  firmId: PropTypes.string,
  noSeatsRemaining: PropTypes.bool,
  processing: PropTypes.bool,
  updating: PropTypes.func,
  userType: PropTypes.string
}

// This is a new wrapper component with boilerplate api updated form fields
export default bindUpdate(AddFirmUser, 'newFirmUser', 'email')
