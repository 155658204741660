import PropTypes from 'prop-types'
import {Map} from 'immutable'

import EmailPreview from './EmailPreview'

const ClientInvitesPreview = props => {
  if (props.clientInvite.get('user-context-texts').size > 0 && props.showPreview) {
    return (
      <div className='client-inivtes-preview'>
        <EmailPreview
          disclosure={props.disclosure}
          emailBody={props.clientInvite.get('user-context-texts').first()}
          contactCard={props.contactCard}
        />
        <p className='existing-everplans-account-note'>
          Note: Some of your invitees may see a variation on the text shown above. If an invitee already has a personal
          subscription to Everplans, they'll be prompted to connect their account with your firm.
        </p>
      </div>
    )
  } else { return null }
}

ClientInvitesPreview.propTypes = {
  clientInvite: PropTypes.instanceOf(Map),
  showPreview: PropTypes.bool
}

export default ClientInvitesPreview
