import {fromJS} from 'immutable'
import PropTypes from 'prop-types'
import {useCallback, useRef} from 'react'

import Fields from '../../../../shared_components/forms/v2/fields'
import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import Form from '../../../../shared_components/forms/v2/Form'
import validators from '../../../../shared_components/forms/v2/validators'
import blueCheckIcon from '../../../../shared_assets/blue-check-icon.svg'

import './jiclPasswordForm.scss'

const JiclPasswordForm = ({onPasswordSubmit, email}) => {
  const formRef = useRef()

  const submitPassword = useCallback(() => {
    if (formRef.current) {
      const form = formRef.current
      form.onSubmit()

      if (form.isValid()) {
        onPasswordSubmit(
          fromJS({
            ...form.formData().getIn(['password-container', 'value']).toJS(),
            'agreement-accepted': form.formData().getIn(['agreement-accepted', 'value'])
          })
        )
      }
    }
  })

  return (
    <div className='jicl-password-form'>
      <div className='email-confirmation-text flex-container'>
        <img alt='blue-check-icon' src={blueCheckIcon} />
        <div>Your user name will be {email}</div>
      </div>
      <Form className='core' ref={formRef}>
        <Fields.Password
          data={fromJS({
            legend: 'Choose a strong password:',
            name: 'password-container',
            id: 'password-container'
          })}
          validator={validators.passwordValidator}
        />
        <Fields.ServiceTerms
          data={fromJS({
            name: 'agreement-accepted',
            id: 'agreement-accepted'
          })}
          validator={validators.serviceTermAndPrivacyPolicyValidator}
        />
        <Accept onClick={submitPassword}>Continue</Accept>
      </Form>
    </div>
  )
}

JiclPasswordForm.propTypes = {
  onPasswordSubmit: PropTypes.func,
  email: PropTypes.string
}

export default JiclPasswordForm
