import {useState} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import AccountSettingsActions from '../../consumer/src/actions/AccountSettingsActions'
import bindUpdate from '../BoundUpdate'
import Loader from '../NewLoader'
import ProfileCompletionForm from '../forms/ProfileCompletionForm'

import Container from '../../lib/Container'
import {currentUserConfig, currentUser} from '../../lib/userTools'
import SessionApi from '../../auth/src/web/SessionApi'
import storePrototype from '../StorePrototype'

import './profileCompletion.scss'

Container.registerStore('updatedProfile', storePrototype(AccountSettingsActions.Types.DID_UPDATE_PROFILE))

const ProfileCompletion = props => {
  const [processing, setProcessing] = useState(false)

  const onSubmit = data => {
    const {birthdate, ...account_data} = data // need to grab birthdate so it can be renamed to dob
    setProcessing(true)

    props.updating(() => {
      setProcessing(false)
      AccountSettingsActions.fetchAccountInformation(props.user.get('id'))
      props.readEndpoint('user-configs').then(() => SessionApi.fetchCurrentUser())
    })

    AccountSettingsActions.updateAccountInformation({...account_data, dob: birthdate}, props.user.get('id'))
  }

  return (
    <div className='profile-completion'>
      {
        props.user.isEmpty() ?
          <Loader /> :
          <ProfileCompletionForm
            interstitial
            name={{
              first_name: props.user.get('first-name', ''),
              last_name: props.user.get('last-name', '')
            }}
            onSubmit={onSubmit}
            processing={processing}
          />
      }
    </div>
  )
}

ProfileCompletion.propTypes = {
  updating: PropTypes.func,
  readEndpoint: PropTypes.func,
  user: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)
  const user = currentUser({userConfig, users: state.api.users})

  return {user}
}

export default connect(mapStateToProps, {readEndpoint})(bindUpdate(
  ProfileCompletion,
  'updatedProfile',
  null
))
