import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {Map} from 'immutable'

import {Accept, Cancel} from '../../../../dashboard/src/components/blocks/Buttons'
import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import DeputyInviteCustomInviteText from './DeputyInviteCustomInviteText'
import DeathReporterInstructions from '../../../../dashboard/src/components/corp_max_deputies/shared_documents/DeathReporterInstructions'

import {personalPronoun} from '../../../../lib/pronouns'
import {everplanOwnerPronoun} from '../../../../lib/pronouns'
import {humanizedFullNames} from '../../../../lib/nameTools'
import MixpanelLogger from '../../../../lib/MixpanelLogger'

import './deputyInviteInterstitial.scss'


const DeputyInviteInterstitial = props => {
  useEffect(() => {
    MixpanelLogger.track('view_deputy_request')
  }, [props.deputizer.get('id')])

  const deputizer = props.deputizer
  const household = deputizer.get('is-household')
  const pronoun = everplanOwnerPronoun({isHousehold: household, owners: deputizer.get('owners')})
  const fullName = humanizedFullNames(deputizer.get('owners'))

  return (
    <div className='deputy-invite-interstitial'>
      <h1>Invitation from {humanizedFullNames(deputizer.get('owners'))}</h1>
      <section className='deputy-invite-intro'>
        <p>
          {fullName} {household ? 'want' : 'wants'} to add you as {personalPronoun(pronoun, 'modifying-possessive')} collaborator on Everplans
          so {personalPronoun(pronoun, 'subject')} can easily share information with you.
        </p>
        <ButtonGroup>
          <Accept onClick={props.acceptDeputyInvite} processing={props.processingAccept}>Accept</Accept>
          <Cancel onClick={props.skipDeputyInvites} processing={props.processingSkip}>Not now</Cancel>
        </ButtonGroup>
      </section>
      <section className='deputy-invite-body'>
        <DeputyInviteCustomInviteText customInviteText={props.deputyInvite.get('custom-invite-text', '')} fullName={fullName} />
        <DeathReporterInstructions fullName={fullName} pronoun={pronoun} deathReporter={props.deathReporter} />
      </section>
    </div>
  )
}


DeputyInviteInterstitial.propTypes = {
  acceptDeputyInvite: PropTypes.func,
  deathReporter: PropTypes.bool,
  deputizer: PropTypes.instanceOf(Map),
  deputyInvite: PropTypes.instanceOf(Map),
  processingAccept: PropTypes.bool,
  processingSkip: PropTypes.bool,
  skipDeputyInvites: PropTypes.func
}

export default DeputyInviteInterstitial
