import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const DeathReportActions = new LegacyActionFactory([
  'GOT_EVERPLAN',
  'DID_MARK_AS_DECEASED',
  'DID_MARK_USER_DECEASED',
  'GOT_DEATH_REPORTS',
  'DID_CANCEL_DEATH_REPORT'
])

DeathReportActions.fetchEverplan = everplanId => {
  DeathReportActions.fireApi(
    'get',
    `/deputies/${everplanId}/death_report_info`,
    null,
    {
      JSONHead: 'everplan',
      successAction: DeathReportActions.Types.GOT_EVERPLAN
    }
  )
}

DeathReportActions.markAsDeceased = everplanId => {
  DeathReportActions.fireApi(
    'patch',
    `/deputies/${everplanId}/mark_as_deceased`,
    null,
    {
      JSONHead: 'deputy',
      successAction: DeathReportActions.Types.DID_MARK_AS_DECEASED
    }
  )
}

DeathReportActions.updateUser = userId => {
  DeathReportActions.fireApi(
    'patch',
    `/users/${userId}`,
    {cancel_death_reports: true},
    {
      JSONHead: 'user',
      successAction: DeathReportActions.Types.DID_CANCEL_DEATH_REPORT
    }
  )
}

DeathReportActions.buildPost('markUserDeceased', 'death_reports', {JSONHead: 'death_reports', successAction: DeathReportActions.Types.DID_MARK_USER_DECEASED})

DeathReportActions.buildBoundGet('death_reports', 'death_reports', DeathReportActions.Types.GOT_DEATH_REPORTS, 'death_reports')

export default DeathReportActions
