import classnames from 'classnames'
import PropTypes from 'prop-types'

import './clientInvitation.scss'

const ClientInvitation = props => {
  const {
    clientInviteLanding,
    handleAcceptClick,
    handleUpgradeAccountClick,
    handleDeclineClick,
    hasDeclined
  } = props

  return (
    <div className='client-invitation'>
      <div className='heading'>Welcome to Everplans, {clientInviteLanding.user_first_name}</div>
      <div className='text'>You've been invited to take advantage of a Premium Everplan, courtesy of <span>{clientInviteLanding.advisor_contact.name}</span>.</div>
      <button className='button' onClick={handleAcceptClick}>Activate your Everplan</button>
      <div className='text'>Already have an Everplan Account?</div>
      <a className='link' onClick={handleUpgradeAccountClick}>Log in for your free upgrade &raquo;</a>
      <div className='text'>Not interested?</div>
      <a className={classnames('link', {hidden: hasDeclined})} onClick={handleDeclineClick}>Decline this invitation &raquo;</a>
      <div className={classnames('declined-message-container', hasDeclined ? 'visible' : 'hidden')}>
        <div>
          {clientInviteLanding.user_first_name}, we're sorry you're not interested in creating your Everplans account.
        </div>
        <div>
          To help us keep improving our product, we'd love to know why you've decided to decline this invitation.
        </div>
        <a href='mailto:invitations@everplans.com?subject=invitation%20declined'>Send us an email here.</a>
      </div>
    </div>
  )
}

ClientInvitation.propTypes = {
  clientInviteLanding: PropTypes.shape({
    advisor_contact: PropTypes.shape({
      email: PropTypes.string,
      head_shot: PropTypes.string,
      firm_name: PropTypes.string,
      name: PropTypes.string
    }),
    user_first_name: PropTypes.string
  }),
  handleAcceptClick: PropTypes.func,
  handleDeclineClick: PropTypes.func,
  handleUpgradeAccountClick: PropTypes.func,
  hasDeclined: PropTypes.bool
}

export default ClientInvitation

