import PropTypes from 'prop-types'
import {Component} from 'react'

import ViewComplianceBcc from './ViewComplianceBcc'
import EditComplianceBcc from './EditComplianceBcc'

import ShadowHeader from '../ShadowHeader'
import ShadowWrapper from '../ShadowWrapper'

export default class ComplianceBcc extends Component {
  constructor(props) {
    super(props)

    this.state = {isEnabled: props.firmConfig.compliance_bcc_enabled}

    this.resetComplianceBccState = this.resetComplianceBccState.bind(this)
    this.toggleComplianceBccState = this.toggleComplianceBccState.bind(this)
  }

  toggleComplianceBccState() { this.setState({isEnabled: !this.state.isEnabled}) }

  resetComplianceBccState() { this.setState({isEnabled: this.props.firmConfig.compliance_bcc_enabled}) }


  render() {
    if (this.props.isFirmActive && this.props.isAccountManager) {
      return (
        <div className='compliance-bcc'>
          <ShadowHeader headerText='Compliance: Invitation BCC' />
          <ShadowWrapper>
            <div className='settings row-wrapper'>
              <p>Status: </p>
              <div className='column-wrapper'>
                <ViewComplianceBcc isEnabled={this.state.isEnabled} />
                <EditComplianceBcc
                  firmId={this.props.firmId}
                  firmConfig={this.props.firmConfig}
                  isEnabled={this.state.isEnabled}
                  isFirmActive={this.props.isFirmActive}
                  isAccountManager={this.props.isAccountManager}
                  resetComplianceBccState={this.resetComplianceBccState}
                  toggleComplianceBccState={this.toggleComplianceBccState}
                />
              </div>
            </div>
          </ShadowWrapper>
        </div>
      )
    } else {
      return null
    }
  }
}


ComplianceBcc.propTypes = {
  firmId: PropTypes.string.isRequired,
  firmConfig: PropTypes.shape({
    compliance_bcc_enabled: PropTypes.bool
  }),
  isFirmActive: PropTypes.bool,
  isAccountManager: PropTypes.bool
}
