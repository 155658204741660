import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {useState, useCallback, useEffect} from 'react'

import JiclForm from './sub_sections/JiclForm'

import {SCREEN_CONFIG, SECTION_ORDER} from '../../lib/jiclIntroConfiguration'

const JiclIntroContainer = props => {
  const [infoData, setInfoData] = useState(Map())

  const [sectionState, setSectionState] = useState({
    sectionIndex: 0,
    sectionConfig: SCREEN_CONFIG.get(SECTION_ORDER.first()),
    isLastSection: false
  })

  const updateSectionState = useCallback(() => {
    setSectionState(() => {
      const nextSectionIndex = sectionState.sectionIndex + 1

      return {
        sectionIndex: nextSectionIndex,
        sectionConfig: SCREEN_CONFIG.get(
          SECTION_ORDER.get(nextSectionIndex, SECTION_ORDER.last())
        ),
        isLastSection: nextSectionIndex + 1 === SECTION_ORDER.size
      }
    })
  }, [sectionState])

  const submitInfoData = useCallback(() => {
    props.onStepChange(infoData)
  }, [infoData])

  const onInfoDataSubmit = useCallback(
    newInfoData => {
      if (newInfoData) setInfoData(infoData.merge(newInfoData))
      updateSectionState()
    },
    [infoData, sectionState]
  )

  useEffect(() => {
    // Check to make sure that all sections have been shown to the user.
    if (sectionState.sectionIndex >= SECTION_ORDER.size) submitInfoData()
  }, [sectionState])

  return (
    <JiclForm
      sectionConfig={sectionState.sectionConfig}
      onSubmit={onInfoDataSubmit}
      className={SECTION_ORDER.get(
        sectionState.sectionIndex,
        SECTION_ORDER.last()
      )}
    />
  )
}

JiclIntroContainer.propTypes = {
  onStepChange: PropTypes.func
}

export default JiclIntroContainer
