import image from '../../assets/images/lifetime-subscription-successful-payment.svg'
import './successfulPayment.scss'

const SuccessfulPayment = () => (
  <div className='successful-payment'>
    <img alt='' className='image' src={image} />
    <h1>Order confirmed</h1>
    <p>
      Thank you for becoming an <span>Everplans Premium</span> lifetime member! We look
      forward to guiding you and your family through all of life’s stages.
    </p>
    <p>
      You will shortly receive an email to confirm and set up your Everplans account.
      If you don’t receive this email, please email us at{' '}
      <a className='email-link' href='mailto: contactus@everplans.com'>contactus@everplans.com</a>.
    </p>
  </div>
)

export default SuccessfulPayment


