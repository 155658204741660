import {fromJS, List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Fields from '../../../../shared_components/forms/v2/fields'
import validators from '../../../../shared_components/forms/v2/validators'

import {compareInt} from '../../../../lib/tools'
import {isActiveAdvisorSeat} from '../../../../lib/proTools'


const SelectAdvisor = props => {
  const firmSeatItems = props.firmSeats.reduce((collection, firmSeat) => {
    if (isActiveAdvisorSeat(firmSeat)) {
      return collection.push(
        Map({
          label: firmSeat.get('name'),
          value: firmSeat.get('name'),
          id: firmSeat.get('id')
        })
      )
    }
    return collection
  }, List())

  const setInitialAdvisorValue = () => {
    const clientSeat = props.clientSeats.find(seat =>
      compareInt(seat.get('id'), props.clientSeatId)
    ) || Map()

    const advisorId = clientSeat.get('advisor-id') || props.userConfig.get('id')

    let advisorSeat = props.firmSeats.find(
      firmSeat =>
        compareInt(firmSeat.get('user-id'), advisorId) &&
        isActiveAdvisorSeat(firmSeat)
    ) || Map()

    if (props.advisorSeatId) {
      advisorSeat = props.firmSeats.find(
        firmSeat =>
          compareInt(firmSeat.get('id'), props.advisorSeatId) &&
          isActiveAdvisorSeat(firmSeat)
      ) || Map()
    }

    return advisorSeat.get('name')
  }

  return (
    <Fields.Select
      data={fromJS({
        id: 'advisor',
        name: 'advisor',
        items: firmSeatItems.toJS(),
        legend: 'Assign To',
        value: setInitialAdvisorValue()
      })}
      validator={validators.required}
    />
  )
}

SelectAdvisor.propTypes = {
  firmSeats: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map),
  clientSeats: PropTypes.instanceOf(List),
  clientSeatId: PropTypes.number,
  advisorSeatId: PropTypes.number
}

SelectAdvisor.defaultProps = {
  clientSeats: List()
}

export default SelectAdvisor
