import PropTypes from 'prop-types'

const TextElementView = ({value, displayText}) => (
  <div className='text-element-view'>
    {displayText && <div>{displayText}:</div>}
    {value && <div>{value}</div>}
  </div>
)

TextElementView.propTypes = {
  displayText: PropTypes.string,
  value: PropTypes.string
}

export default TextElementView
