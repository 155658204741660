import Nav from './Nav'

import './formsPlayground.scss'

const FormsPlayground = props => (
  <div className='forms-playground'>
    <h1>Forms Playground</h1>
    <Nav />
    {props.children}
  </div>
)

export default FormsPlayground
