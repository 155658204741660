/** @class FirmConfig
 */
import PropTypes from 'prop-types'

import moment from 'moment'
import ShadowHeader from './ShadowHeader'
import ShadowWrapper from './ShadowWrapper'

import EditCobranding from './settings/cobranding_settings/EditCobranding'
import EditDeputyCobranding from './settings/EditDeputyCobranding'
import EditDisclosure from './settings/EditDisclosure'
import ProfessionalDesignationSettings from './settings/professional_designation_settings/ProfessionalDesignationSettings'
import EditWriteAccess from './settings/EditWriteAccess'
import DeputizableSettings from './settings/deputizable_settings/DeputizableSettings'
import ComplianceBcc from './settings/ComplianceBcc'
import CustomInviteMessage from './settings/CustomInviteMessage'
import AdvisorAutoPopulationSettings from './settings/advisor_auto_population_settings/AdvisorAutoPopulationSettings'


import './firmConfig.scss'

const FirmConfig = props => (
  <div className='firm-config'>
    {!props.firm.active && <p className='note'>Downgraded on {moment(props.firm.last_updated_at).format('MM/DD/YYYY')}.</p>}
    <ProfessionalDesignationSettings
      professionalDesignation={props.firmConfig.professional_designation}
      {...props.settingsSharedProps}
    />
    <ShadowHeader headerText='Cobranding' />
    <EditCobranding
      cobranding={props.cobranding}
      {...props.settingsSharedProps}
    />

    <CustomInviteMessage
      isHighCompliance={props.isHighCompliance}
      firmOrganization={props.firmOrganization}
      {...props.settingsSharedProps}
    />

    <ComplianceBcc
      firmConfig={props.firmConfig}
      {...props.settingsSharedProps}
    />

    <ShadowHeader headerText='Permission to Edit' />
    <EditWriteAccess
      writeAccess={props.firmConfig}
      {...props.settingsSharedProps}
    />

    <ShadowHeader headerText='Deputy Cobranding' />
    <EditDeputyCobranding
      showDeputyCobranding={props.firmConfig.deputy_cobranding}
      {...props.settingsSharedProps}
    />

    {
      props.settingsSharedProps.isAccountManager &&
      (
        <div>
          <DeputizableSettings
            {...props.settingsSharedProps}
            isDeputizable= {props.firmConfig.deputizable}
            permittedCategories={props.firmConfig.permitted_categories}
          />
          <ShadowHeader headerText='Disclosures' />
          <EditDisclosure
            disclosure={props.disclosure}
            {...props.settingsSharedProps}
          />
          <AdvisorAutoPopulationSettings
            {...props.settingsSharedProps}
            advisorAutoPopulationEnabled={props.firmConfig.advisor_auto_population_enabled}
          />

          <ShadowHeader headerText='Firm External ID' />
          <ShadowWrapper>
            <div className='settings row-wrapper'>
              <p className='firm-external-id'>{props.firm.external_id}</p>
            </div>
          </ShadowWrapper>
        </div>
      )
    }
  </div>
)

FirmConfig.propTypes = {
  cobranding: PropTypes.object,
  disclosure: PropTypes.shape({
    enabled: PropTypes.bool,
    markdown_text: PropTypes.string,
    show_in_email: PropTypes.bool,
    show_in_footer: PropTypes.bool
  }),
  firm: PropTypes.shape({
    active: PropTypes.bool,
    internal: PropTypes.bool,
    last_updated_at: PropTypes.string,
    name: PropTypes.string,
    external_id: PropTypes.string
  }).isRequired,
  firmConfig: PropTypes.shape({
    advisor_auto_population_enabled: PropTypes.bool,
    compliance_level: PropTypes.string,
    deputizable: PropTypes.bool,
    deputy_cobranding: PropTypes.bool,
    permitted_categories: PropTypes.arrayOf(PropTypes.string),
    professional_designation: PropTypes.string,
    write_access: PropTypes.bool
  }).isRequired,
  firmOrganization: PropTypes.object,
  isHighCompliance: PropTypes.bool,
  settingsSharedProps: PropTypes.shape({
    firmId: PropTypes.string,
    isAccountManager: PropTypes.bool,
    isFirmActive: PropTypes.bool
  })
}

export default FirmConfig
