import {Component} from 'react'
import PropTypes from 'prop-types'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {List} from 'immutable'
import {withRouter} from 'react-router'

import {debounce, capitalize} from '../../../../../lib/tools'

import 'primeicons/primeicons.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import './clientSummariesTables.scss'

import {
  advisorCellTemplate,
  headerTemplate,
  nameTemplate,
  lastLoginDateTemplate,
  loginCountTemplate,
  statusBodyTemplate
} from './templates/tableTemplates'
import routerUtils from '../../../../../lib/routerUtils'

const statuses = [
  {name: 'Active'},
  {name: 'Pending'},
  {name: 'Draft'},
  {name: 'Requesting Approval'},
  {name: 'Declined'},
  {name: 'Household Declined'},
  {name: 'Deceased'}
]


export class ClientSummariesTable extends Component {
  constructor(props) {
    super()
    this.state = {
      name: '',
      rows: 20,
      first: 0,
      page: 0,
      selectedStatus: this.normalizeStatusValue(props.location.query.status),
      selectedAdvisor: null,
      filters: {},
      sorted: {},
      sortOrder: -1,
      sortField: null
    }

    this.multiSortMeta = []
    this.advisors = this.advisorNames(props)

    this.onFetchData = debounce(props.onFetchData, 500)
    this.onPage = this.onPage.bind(this)
    this.onAdvisorChange = this.onAdvisorChange.bind(this)
    this.onStatusesChange = this.onStatusesChange.bind(this)
    this.onFilterFromStats = this.onFilterFromStats.bind(this)
    this.header = this.header.bind(this)
    this.searchName = this.searchName.bind(this)
    this.selectedAdvisorTemplate = this.selectedAdvisorTemplate.bind(this)
    this.onSort = this.onSort.bind(this)
    this.sortData = this.sortData.bind(this)
    this.sortFieldParams = this.sortFieldParams.bind(this)
  }

  componentDidMount() {
    this.props.onFetchData({
      filters: {status: this.state.selectedStatus}
    })
  }

  componentDidUpdate(prevProps) {
    const currentStatus = this.props.location.query.status
    const previousStatus = prevProps.location.query.status

    if (currentStatus && previousStatus !== currentStatus) {
      this.onFilterFromStats({value: this.normalizeStatusValue(currentStatus)})
      routerUtils.push('pro/clients')
    }
  }

  normalizeStatusValue(status) {
    return status && status !== 'all' ? [capitalize(status)] : []
  }

  onFilterFromStats(event) {
    this.setState(
      {
        name: '',
        first: 0,
        selectedAdvisor: null,
        selectedStatus: event.value,
        filters: {status: event.value}
      },
      () => {
        this.onFetchData({
          filters: {status: this.state.selectedStatus},
          sorted: this.sortFieldParams()
        })
      }
    )
  }

  onPage(event) {
    const tableState = {
      pageParams: {
        page: event.page,
        pageSize: event.rows
      },
      filters: this.state.filters,
      ...this.state.sortField && {
        sorted: this.sortFieldParams()
      }
    }

    this.setState({first: event.first, page: event.page}, () =>
      this.props.onFetchData(tableState)
    )
  }

  advisorNames(props) {
    return props.firmSeats
      .filter(firmSeat => firmSeat.get('seat-type') === 'AdvisorSeat')
      .map(firmSeat => ({
        value: firmSeat.get('user-id'),
        label: firmSeat.get('name')
      }))
      .toJS()
  }

  onStatusesChange(event) {
    this.setState(
      {
        first: 0,
        selectedStatus: event.value,
        filters: {...this.state.filters, status: event.value}
      },
      () => {
        this.onFetchData({
          filters: {...this.state.filters, status: this.state.selectedStatus},
          sorted: this.sortFieldParams()
        })
      }
    )
  }

  onAdvisorChange(event) {
    this.setState(
      {
        first: 0,
        selectedAdvisor: event.value,
        filters: {...this.state.filters, 'advisor-id': event.value}
      },
      () =>
        this.onFetchData({
          filters: {
            ...this.state.filters,
            'advisor-id': this.state.selectedAdvisor
          },
          sorted: this.sortFieldParams()
        })
    )
  }

  searchName(event) {
    this.setState(
      {
        name: event.target.value,
        first: 0,
        filters: {...this.state.filters, name: event.target.value}
      },
      () => this.onFetchData({filters: this.state.filters, sorted: this.sortFieldParams()})
    )
  }

  selectedAdvisorTemplate(option) {
    const advisor = this.advisors.find(name => name.value === option)
    const optionLabel = advisor ? advisor.label : null
    const optionComponent = option ?
      <span>{optionLabel}</span> :
      null

    return optionComponent
  }

  header() {
    const headerParams = {
      selectedAdvisors: this.state.selectedAdvisor,
      selectedStatuses: this.state.selectedStatus,
      selectedAdvisorTemplate: this.selectedAdvisorTemplate,
      advisorNames: this.advisors,
      onAdvisorChange: this.onAdvisorChange,
      selectedItemTemplate: this.selectedAdvisorTemplate,
      onStatusesChange: this.onStatusesChange,
      searchName: this.searchName,
      name: this.state.name,
      statuses
    }

    return headerTemplate(headerParams)
  }

  sortFieldParams() {
    return [{
      ...this.state.sortOrder === -1 && {desc: true},
      id: this.state.sortField
    }]
  }

  onSort(event) {
    this.setState({
      sortField: event.sortField,
      sortOrder: this.state.sortOrder === -1 ? 1 : -1,
      first: 0
    }, () => {
      this.sortData()
    })
  }

  sortData() {
    const tableState = {
      pageParams: {
        page: this.state.first,
        pageSize: this.state.rows
      },
      filters: this.state.filters,
      sorted: this.sortFieldParams()
    }
    this.onFetchData(tableState)
  }

  render() {
    return (
      <div className='client-summaries-table'>
        <div className='card'>
          <DataTable
            value={this.props.clients}
            paginator
            rows={this.state.rows}
            totalRecords={this.props.recordCount}
            lazy
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={this.onSort}
            header={this.header()}
            first={this.state.first}
            onPage={this.onPage}
            loading={this.props.loadingClients}
            emptyMessage='No Clients found.'>
            <Column field='name' header='Name' body={nameTemplate} sortable />
            <Column field='status' header='Status' body={statusBodyTemplate} sortable />
            <Column field='advisor-id' body={advisorCellTemplate(this.advisors)} header={this.props.professionalDesignation} sortField='advisor-id' sortable />
            <Column field='last-login-date' header='Last Login' body={lastLoginDateTemplate} sortable />
            <Column field='sign-in-count' header='# of Logins' body={loginCountTemplate} sortable />
          </DataTable>
        </div>
      </div>
    )
  }
}

ClientSummariesTable.propTypes = {
  clients: PropTypes.array,
  firmSeats: PropTypes.instanceOf(List),
  loadingClients: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.shape({
      status: PropTypes.string
    })
  }),
  onFetchData: PropTypes.func,
  professionalDesignation: PropTypes.string,
  recordCount: PropTypes.number
}

ClientSummariesTable.defaultProps = {
  location: {
    query: {}
  }
}

export default withRouter(ClientSummariesTable)
