import classnames from 'classnames'

import {BoxGutterMedium} from './Boxes'
import Cubby from '../../../../shared_components/core/boxes/Cubby'
import {TextGutterMedium} from './Texts'

import './boxWithImage.scss'


const BoxWithImage = ({children, className, tagline, heading, ...otherProps}) => (
  <BoxGutterMedium className={classnames('box-with-image flex-container flex-container-full medium-flex-container-fit', className)}>
    <img {...otherProps} className={classnames('box-image flex-child', otherProps.imgClassName)} />
    <Cubby>
      {tagline && <TextGutterMedium className='tagline'>{tagline}</TextGutterMedium>}
      {heading && <h2>{heading}</h2>}
      {children}
    </Cubby>
  </BoxGutterMedium>
)

export default BoxWithImage
