import {Fragment, useState, useCallback, useEffect} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import JiclAnythingElse from './JiclAnythingElse'
import JiclAllDone from './JiclAllDone'
import JiclOneMoreStep from './JiclOneMoreStep'


const JiclOutroContainer = props => {
  const {numberOfScreens} = props
  const [step, setStep] = useState(0)
  const [infoData, setInfoData] = useState(Map())

  const submitInfoData = useCallback(() => {
    props.onStepChange(infoData)
  }, [infoData])

  const onInfoDataSubmit = useCallback(
    newInfoData => {
      if (newInfoData) setInfoData(infoData.merge(newInfoData))
      setStep(step + 1)
    },
    [infoData, step]
  )

  useEffect(
    () => { if (step === 2) submitInfoData() },
    [step]
  )

  const sharedProps = () => ({onInfoDataSubmit, numberOfScreens})

  return (
    <Fragment>
      {step === 0 && <JiclAnythingElse {...sharedProps()} />}
      {step === 1 && <JiclAllDone {...sharedProps()} />}
      {step >= 2 && <JiclOneMoreStep {...props} />}
    </Fragment>
  )
}


JiclOutroContainer.propTypes = {
  onStepChange: PropTypes.func,
  numberOfScreens: PropTypes.number
}

export default JiclOutroContainer
