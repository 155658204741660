import PropTypes from 'prop-types'
import React from 'react'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import Button from '../../../../shared_components/Button'
import Container from '../../../../lib/Container'
import Forms from '../../../../shared_components/forms'
import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'
import * as Links from '../../../../shared_components/Links'
import PageTitle from '../../../../shared_components/PageTitle'
import PasswordResetActions from '../../actions/PasswordResetActions'
import StorePrototype from '../../../../shared_components/StorePrototype'
import WidthContainer from '../../../../shared_components/WidthContainer'
import {trimString} from '../../../../lib/textTools'

import './resetPassword.scss'

class SendResetInstructions extends React.Component {
  constructor(props) {
    super(props)

    this.sendInstructions = this.sendInstructions.bind(this)
  }
  emailValidator(name, data) {
    var constraints = {}
    constraints[name] = {email: {message: '^Email is not valid'}, presence: true}
    data.email = trimString(data.email)
    return Forms.Validators.template(name, data, constraints)
  }
  sendInstructions() {
    if (this.refs.form.validate().length === 0)
      PasswordResetActions.sendResetInstructions(this.refs.form.formData())
  }

  render() {
    return (
      <div className='reset-password'>
        <HeaderFlavor flavor='transactional' />
        <PageTitle title='Reset Password' />
        <WidthContainer>
          <h1>Forgot your password?</h1>
          <p>Please enter the email address associated with your Everplans account. We will send you a message with instructions for resetting your password.</p>
          <Forms.Form onSubmit={this.sendInstruction} ref='form' errors={this.props.errors}>
            <Forms.Input autoFocus label='Your email address:' name='email' type='email' className='email' validator={this.emailValidator} />
          </Forms.Form>
          <Button loading={this.props.processing} className='btn-success' onClick={this.sendInstructions}>Continue</Button>
        </WidthContainer>
        <div className='support-links'>
          Need Support?<Links.Help>Everplans Help Center</Links.Help>
        </div>
      </div>
    )
  }
}

Container.registerStore('send_instruction', StorePrototype(PasswordResetActions.Types.DID_SEND_INSTRUCTIONS))

SendResetInstructions.defaultProps = {
  errors: [],
  processing: false
}

SendResetInstructions.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      errors: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string
    })
  ),
  processing: PropTypes.bool
}

export default bindUpdate(SendResetInstructions, 'send_instruction', 'email')
