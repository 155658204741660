import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import DisclosureFooter from '../DisclosureFooter'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import LegacyLoader from '../LegacyLoader'
import NewLoader from '../NewLoader'
import TimeoutModal from '../TimeoutModal'

import AuthGateway from '../AuthGateway'
import Container from '../../lib/Container'
import routerUtils from '../../lib/routerUtils'
import SessionApi from '../../auth/src/web/SessionApi'
import SessionStore from '../../auth/src/stores/SessionStore'

import './appShell.scss'

export default class AppShell extends React.Component {
  constructor(props) {
    super(props)

    this.brandingStore = Container.getStore('config')

    const cobrandingData = this.brandingStore.getState().data.cobranding
    const currentUser = SessionStore.getState().currentUser
    this.state = {
      currentUser,
      isCobranded: cobrandingData.enabled,
      loading: !(currentUser && cobrandingData)
    }

    this.updateCobranding = this.updateCobranding.bind(this)
    this.updateCurrentUser = this.updateCurrentUser.bind(this)
  }
  componentDidMount() {
    this.brandingStore.addChangeListener(this.updateCobranding)
    SessionStore.addChangeListener(this.updateCurrentUser)
    SessionApi.fetchCurrentUser()
  }
  updateCobranding() { this.setState({cobranding: this.brandingStore.getState().data.cobranding.enabled}) }
  updateCurrentUser() {
    this.setState({
      loading: false,
      currentUser: SessionStore.getState().currentUser
    })
  }
  componentWillUnmount() {
    this.brandingStore.removeChangeListener(this.updateCobranding)
    SessionStore.removeChangeListener(this.updateCurrentUser)
  }
  authGatewayProps() {
    return {
      needsAccountManager: this.props.needsAccountManager,
      needsFirmUser: this.props.needsFirmUser,
      useNewLoader: this.props.useNewLoader,
      whitelist: this.props.whitelist || []
    }
  }
  headerFlavor() { return this.props.headerFlavor || 'transactional' }

  render() {
    const LoaderComponent = this.props.useNewLoader ? NewLoader : LegacyLoader
    return (
      <div className={classnames('app-shell', this.props.className)}>
        <AuthGateway {...this.authGatewayProps()}>
          <div className='app-content-wrapper'>
            <Header flavor={this.headerFlavor()} />
            <main className={classnames({cobranded: this.state.isCobranded})}>
              <TimeoutModal />
              <LoaderComponent loading={this.state.loading}>
                {routerUtils.childrenWithProps(this.props.children, {currentUser: this.state.currentUser})}
              </LoaderComponent>
            </main>
            {this.headerFlavor() === 'transactional' && <DisclosureFooter /> }
          </div>
          {this.props.includeFooter && <Footer cobranding={{enabled: this.state.isCobranded}} />}
        </AuthGateway>
      </div>
    )
  }
}

AppShell.propTypes = {
  headerFlavor: PropTypes.string,
  includeFooter: PropTypes.bool,
  needsAccountManager: PropTypes.bool,
  needsFirmUser: PropTypes.bool,
  useNewLoader: PropTypes.bool,
  whitelist: PropTypes.arrayOf(PropTypes.string)
}
