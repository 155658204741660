import {useCallback, useState, useRef} from 'react'
import {fromJS} from 'immutable'
import {Link} from 'react-router'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'

import JiclHeader from '../JiclHeader'
import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import Form from '../../../../shared_components/forms/v2/Form'
import Fields from '../../../../shared_components/forms/v2/fields'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'

import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import validators from '../../../../shared_components/forms/v2/validators'

export const JiclSignIn = props => {
  const formRef = useRef()
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const submitEmail = useCallback(() => {
    if (formRef.current) {
      const form = formRef.current
      form.onSubmit()

      if (form.isValid()) {
        setProcessing(true)

        const resource = {
          type: 'magic-links',
          attributes: {email: form.formData().getIn(['email', 'value'])}
        }

        props.createResource(resource)
          .then(() => alwaysNotify.shortSuccess('We have sent a link to your email'))
          .catch(() => alwaysNotify.shortError("Sorry, we've encountered an error."))
          .finally(() => setProcessing(false))
      }
    }
  })

  return (
    <div className='jicl-sign-in'>
      <JiclHeader />
      <div className='forms-playground'>
        <div className='section-title border-bottom'>
          Review Your Just-In-Case List
        </div>
        <TextGutterMedium>
          Enter your email address below and we'll send you a link to your
          Just-In-Case List.
        </TextGutterMedium>
        <Form className='core' ref={formRef}>
          <Fields.Email
            data={fromJS({
              legend: 'Your email address:',
              name: 'email',
              id: 'email'
            })}
            validator={validators.emailValidator}
          />
          <Accept onClick={submitEmail} processing={processing}>
            Continue
          </Accept>
        </Form>
        <div className='link-with-extra-text'>
          <span>Haven't started one? </span>
          <Link to='/jicl/start'>Create yours here.</Link>
        </div>
      </div>
    </div>
  )
}

JiclSignIn.propTypes = {
  onStepChange: PropTypes.func,
  createResource: PropTypes.func
}

export default connect(null, {createResource})(JiclSignIn)
