/** @class AccountSummary
 *
 * @desc
 * Displays summary information about the client Everplan being viewed.
 *
 * @todo Wire up the following with v2 resources:
 *.   - Deputization status text
 *.   - View Everplan link
 *.   - Statistics
 */

import PropTypes from 'prop-types'
import {Map} from 'immutable'

import ItemPermissionsContainer from '../../request_item_permissions/ItemPermissionsContainer'
import ShadowWrapper from '../../shared_firm/ShadowWrapper'
import Statistics from './Statistics'
import DeputyInformation from './DeputyInformation'


const AccountSummary = props => {
  if (props.anyoneActive) {
    return (
      <div className='account-summary'>
        <h3>Everplan Summary</h3>
        <ShadowWrapper>
          <ul>
            <li>
              <DeputyInformation currentUserIsDeputy={props.data.current_user_is_deputy} everplanSummary={props.everplanSummary} />
            </li>
            <li>
              <ItemPermissionsContainer {...props} />
            </li>
          </ul>
          <Statistics stats={props.data} everplanSummary={props.everplanSummary} />
        </ShadowWrapper>
      </div>
    )
  } else {
    return null
  }
}

AccountSummary.defaultProps = {
  allUsersDeceased: false,
  anyoneActive: false, // Hide module initially to prevent flicker if no Everplanners are active. --BLR
  data: {
    current_user_is_deputy: false,
    user_id: null
  }
}

AccountSummary.propTypes = {
  allUsersDeceased: PropTypes.bool.isRequired,
  anyoneActive: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    current_user_is_deputy: PropTypes.bool,
    user_id: PropTypes.number
  }),
  firmOwnership: PropTypes.instanceOf(Map)
}

export default AccountSummary
