/** @class Errors
 *
 * @deprecated Do not use this in new code. Use the newer approach in
 *   +shared_components/notifications/NotificationContext.jsx+ instead.
 * @desc
 * Legacy component to show flash error messages.
 */
import PropTypes from 'prop-types'

import './flash.scss'

const Errors = ({errors = []}) => {
  if (errors.length) {
    return (
      <ul>
        {errors.map((error, index) => <li key={index}>{error}</li>)}
      </ul>
    )
  } else {
    return <div />
  }
}

Errors.propTypes = {errors: PropTypes.array}

export default Errors
