import React from 'react'


const toggleErrorsAndSideLabelsWrapper = Component => {
  class ErrorsAndSideLabelsWrapper extends React.Component {
    constructor() {
      super()

      this.state = {
        showErrors: false,
        useSideLabels: false
      }

      this.toggleErrors = this.toggleErrors.bind(this)
      this.toggleSideLabels = this.toggleSideLabels.bind(this)
    }
    toggleErrors() { this.setState({showErrors: !this.state.showErrors}) }
    toggleSideLabels() { this.setState({useSideLabels: !this.state.useSideLabels}) }

    render() {
      return (
        <span>
          <form className='core'>
            <fieldset>
              <div className='fieldset-flexbox'>
                <div className='controls-flexbox flex-container checkbox-form-group'>
                  <label className='flex-child'>
                    <input name='checkbox-group' type='checkbox' onClick={this.toggleSideLabels} />
                    <span className='label-text'>Move labels to the {this.state.useSideLabels ? 'top' : 'side'}</span>
                  </label>
                  <label className='flex-child'>
                    <input name='checkbox-group' type='checkbox' onClick={this.toggleErrors} />
                    <span className='label-text'>{this.state.showErrors ? 'Hide' : 'Show'} errors</span>
                  </label>
                </div>
              </div>
            </fieldset>
          </form>
          <Component {...this.props} {...this.state} />
        </span>
      )
    }
  }

  return ErrorsAndSideLabelsWrapper
}


export default toggleErrorsAndSideLabelsWrapper

