import PropTypes from 'prop-types'

const SecondaryNumber = ({sessionState, updateRoute}) => (
  <div className='alternate-delivery-option'>
    <input name='mode' type='radio' className='tfa-radio-btn' value='tfa_secondary' onChange={updateRoute} />
    <div className='tfa-text-font-weight'>
      Resend to <strong>secondary</strong> <strong>number</strong> ({sessionState.secondaryPhone.obfuscated_number})
    </div>
  </div>
)

SecondaryNumber.propTypes = {
  sessionState: PropTypes.shape({secondaryPhone: PropTypes.object}),
  updateRoute: PropTypes.func
}

export default SecondaryNumber
