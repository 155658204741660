import {ModalFormBase, ModalFormBody} from './ModalForm'
import ProResponsiveModal from './ProResponsiveModal'

import './modalForm.scss'


export default class ProModalForm extends ModalFormBase {
  render() {
    return (
      <ProResponsiveModal modalType='pro-modal-form-content' {...this.props}>
        <ModalFormBody {...this.props} ref='form' />
      </ProResponsiveModal>
    )
  }
}
