import {Fragment, useCallback, useEffect} from 'react'

import JiclRecipientView from './JiclRecipientView'
import JiclRecipientViewBanner from '../preview/sections/JiclRecipientViewBanner'
import JiclHeader from '../JiclHeader'

import Logger from '../../../../lib/NewLogger'
import routerUtils from '../../../../lib/routerUtils'

const JiclRecipientViewContainer = props => {
  const goToSignUp = useCallback(() =>
    routerUtils.push('/auth/sign-up')
  )

  useEffect(() => {
    Logger.log({
      name: 'preview_jicl',
      payload: {
        context: 'jicl',
        flavor: 'covid',
        wildcard: 'covid'
      }
    })
  }, []) // empty array so it only logs the event on first mount i.e +componentDidMount+

  return (
    <Fragment>
      <JiclRecipientViewBanner goToSignUp={goToSignUp} />
      <JiclHeader />
      <JiclRecipientView {...props} />
      <JiclRecipientViewBanner goToSignUp={goToSignUp} />
    </Fragment>
  )
}

export default JiclRecipientViewContainer
