import PropTypes from 'prop-types'
import React from 'react'

import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'
import * as Links from '../../../../shared_components/Links'
import PageTitle from '../../../../shared_components/PageTitle'
import WidthContainer from '../../../../shared_components/WidthContainer'

class CheckResetEmail extends React.Component {
  render() {
    return (
      <div className='reset-password'>
        <PageTitle title='Reset Password' />
        <HeaderFlavor flavor='transactional' />
        <WidthContainer>
          <h1>Please check your email.</h1>
          <p>If there is an Everplans account associated with {this.props.location.query.email}, an email has been sent to that address with instructions on how to reset the password.</p>
        </WidthContainer>
        <div className='support-links'>
          Need Support?<Links.Help>Everplans Help Center</Links.Help>
        </div>
      </div>
    )
  }
}

CheckResetEmail.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      email: PropTypes.string
    })
  }).isRequired
}

export default CheckResetEmail
