import PropTypes from 'prop-types'
import React from 'react'

import ButtonGroup from '../../../../shared_components/deprecated/ButtonGroup'
import Forms from '../../../../shared_components/forms'
import LegacyLoader from '../../../../shared_components/LegacyLoader'

import {pluralize} from '../../../../lib/tools'
import routerUtils from '../../../../lib/routerUtils'

import './legacySelectAdvisor.scss'


class SelectAdvisorBase extends React.Component {
  constructor(props) {
    super(props)

    this.onContinue = this.onContinue.bind(this)
  }
  UNSAFE_componentWillMount() { this.continueIfSingleAdvisor() }
  UNSAFE_componentWillUpdate(nextProps) { this.continueIfSingleAdvisor(nextProps) }
  advisorList() { return this.sortedAdvisors().map(seat => ({isDisabled: this.isDisabled(seat), key: seat.advisor_seat_id, value: this.advisorLabel(seat)})) }
  advisorLabel(seat) {
    return (
      <div className='advisor-seat-info' key={seat.advisor_seat_id}>
        <span className='advisor-name'>{seat.name}</span>
        <span className='seat-count'>{this.seatCountString(seat)}</span>
      </div>
    )
  }
  continueIfSingleAdvisor(props = this.props) {
    if (!props.loadingAdvisorSeats && props.advisorSeats.length === 1)
      routerUtils.push(this.selectClientsPath(props.advisorSeats[0].advisor_seat_id))
  }
  defaultAdvisor() {
    const currentUserSeat = this.props.advisorSeats.find(seat => seat.is_current_user)
    if (currentUserSeat && currentUserSeat.client_counts[this.seatType()])
      return currentUserSeat.advisor_seat_id
  }
  isDisabled(seat) { return !seat.client_counts[this.seatType()] }
  onContinue() {
    if (!this.refs.form.validate().length)
      routerUtils.push(this.selectClientsPath(this.refs.form.formData().advisorSeatId))
  }
  seatCountString(seat) { return `${seat.client_counts[this.seatType()]} ${pluralize('client', seat.client_counts[this.seatType()])} in ${this.seatType()} status` }
  selectClientsPath(advisorId) { return `pro/clients/bulk_actions/select-clients/${advisorId}/${this.seatType()}` }
  sortedAdvisors() { return this.props.advisorSeats.sort((seatOne, seatTwo) => seatTwo.client_counts[this.seatType()] - seatOne.client_counts[this.seatType()]) }

  preheader() { return '' } // Subclasses should override this method and define seatType

  render() {
    const professionalDesignation = this.props.firmConfig.get('professional-designation')
    return (
      <LegacyLoader loading={this.props.loadingAdvisorSeats}>
        <div className='select-advisor'>
          {this.preheader() && <div className='preheader'>{this.preheader()}</div>}
          <h2 className='select-bulk-clients-header'>Select {professionalDesignation}</h2>
          <p>Please select which {professionalDesignation} you are sending invitations on behalf of.</p>
          <Forms.Form ref='form'>
            <Forms.RadioList
              defaultValue={this.defaultAdvisor()}
              list={this.advisorList()}
              name='advisorSeatId'
              validator={Forms.Validators.radioListValidator}
            />
          </Forms.Form>
          <ButtonGroup hideCancel={true} onContinue={this.onContinue} />
        </div>
      </LegacyLoader>
    )
  }
}

SelectAdvisorBase.defaultProps = {loadingAdvisorSeats: true}

SelectAdvisorBase.propTypes = {
  advisorSeats: PropTypes.array,
  firmConfig: PropTypes.object,
  loadingAdvisorSeats: PropTypes.bool.isRequired
}


export class SelectInviteAdvisor extends SelectAdvisorBase {
  preheader() { return 'INVITE DRAFT CLIENTS' }
  seatType() { return 'draft' }
}


export class SelectReinviteAdvisor extends SelectAdvisorBase {
  preheader() { return 'RESEND PENDING INVITATIONS' }
  seatType() { return 'pending' }
}
