import classnames from 'classnames'
import PropTypes from 'prop-types'

import './shadowHeader.scss'

const ShadowHeader = props => (
  <div className={classnames('shadow-header', props.headerClassName)}>
    <div className={classnames('shadow-title', props.titleClassName)}>{props.headerText}</div>
    {props.children}
  </div>
)

ShadowHeader.propTypes = {
  headerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  headerText: PropTypes.string
}

export default ShadowHeader
