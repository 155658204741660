import PropTypes from 'prop-types'
import {useEffect} from 'react'

import ERROR_MESSAGES from './errorMessages.json'
import * as Links from '../../../../shared_components/Links'
import PageTitle from '../../../../shared_components/PageTitle'
import TroubleshootingStep from './TroubleshootingStep'
import MixpanelLogger from '../../../../lib/MixpanelLogger'

import brokenLink from '../../../../shared_assets/broken-link.png'

import './invalidToken.scss'


const InvalidToken = props => {
  useEffect(() => {
    if (props.params.invite_type === 'consumer_invite')
      MixpanelLogger.track('view_confirmation_invalid_token')
  }, [props.params.invite_type])

  const error = ERROR_MESSAGES[props.params.invite_type]

  return (
    <div className='invalid-token'>
      <PageTitle title='Error' />
      <div className='error-wrapper'>
        <h2 className='header-text'>Sorry - something's gone wrong.</h2>
        <div className='error-info'>
          <div className='error-image-div'>
            <img alt='Broken chain link icon' className='error-image' src={brokenLink} />
          </div>
          <div className='error-text'>{error.message}</div>
        </div>
        <div className='error-suggestions'>
          <div className='error-suggestions-text'>Suggestions for troubleshooting this issue:</div>
          {
            error.options.map(
              (option, index) => <TroubleshootingStep error={error} index={index} key={option} option={option} />
            )
          }
        </div>
        <div className='error-contact'>
          If you continue to have trouble, please <Links.ContactUs>contact our help team</Links.ContactUs> and they'll get you sorted out.
        </div>
      </div>
    </div>
  )
}

InvalidToken.propTypes = {
  params: PropTypes.shape({
    invite_type: PropTypes.oneOf([
      'advisor_invite',
      'client_invite',
      'letter_download',
      'deputy_invite',
      'consumer_invite',
      'email_confirm',
      'reset_password',
      'unsubscribe'
    ])
  })
}

export default InvalidToken
