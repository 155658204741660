import {Component} from 'react'
import PropTypes from 'prop-types'
import {Table, Tr, Td} from 'reactable'

import Name from './Name'
import Status from './Status'


export default class SeatsTable extends Component {
  loadFirmUser(userId) {
    this.context.router.push(`/pro/firm/${this.props.firmId}/user/${userId}/account-info`)
  }

  render() {
    return (
      <Table className='reactable'>
        {this.props.seats.map(row => (
          <Tr key={row.id} onClick={this.loadFirmUser.bind(this, row.id)}>
            {/* Note: Reactable doesn't allow the `Td` to be moved to subcomponents, so I'm leaving them here. --BLR */}
            <Td column='name'>
              <Name accountManager={this.props.accountManager} row={row} />
            </Td>
            <Td column='status'>
              <Status accountManager={this.props.accountManager} row={row} userType={this.props.userType} />
            </Td>
            <Td column='edit' value={row.id}>
              <a>
                  Edit
              </a>
            </Td>
          </Tr>
        ))}
      </Table>
    )
  }
}

SeatsTable.contextTypes = {router: PropTypes.object}

SeatsTable.defaultProps = {
  accountManager: false
}

SeatsTable.propTypes = {
  accountManager: PropTypes.bool,
  firmId: PropTypes.string,
  seats: PropTypes.array,
  userType: PropTypes.string
}
