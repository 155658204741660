import PropTypes from 'prop-types'

import AcceptedClientRow from './AcceptedClientRow'
import NoRecords from './NoRecords'


const AcceptedClients = props => {
  if (props.accepted.length > 0) {
    return (
      <fieldset className='add-records accepted-records'>
        <p>The following {props.accepted.length} entries are ready to be added. If the list does not look right, make the necessary changes to your .CSV file and <a onClick={props.goBack}>start over</a>.</p>
        <table className='confirm-add-many'>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {props.accepted.map(client => <AcceptedClientRow {...client} key={client.email} />)}
          </tbody>
        </table>
      </fieldset>
    )
  }

  return <NoRecords goBack={props.goBack} />
}

AcceptedClients.propTypes = {
  accepted: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired
    })
  ).isRequired,
  goBack: PropTypes.func.isRequired
}

export default AcceptedClients
