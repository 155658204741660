import PropTypes from 'prop-types'

import routerUtils from '../../../../lib/routerUtils'

import './inviteClientNotification.scss'


const InviteClientNotification = props => {
  const startInvite = () => {
    routerUtils.push('pro/clients/customize_many_modal')
    props.dismissNotification()
  }

  const editEverplan = () => {
    routerUtils.push(`pro/household/${props.recipients[0].client_seat_id}`)
    props.dismissNotification()
  }

  return (
    <section className='post-task-message'>
      <p className='post-message-top'>{props.message}</p>
      <section className='post-message-bottom'>
        {
          (props.count === 1 || props.isHousehold) &&
              <span>
                <span>
                  <a className='action primary-action' onClick={editEverplan}>
                      Set up their Everplan or invite them now
                  </a>.
                </span>
              </span>

        }
        {
          (props.count > 1 && !props.isHousehold) &&
            (
              <span>
                <a className='action primary-action' onClick={startInvite}>Invite them now</a>.
              </span>
            )
        }
      </section>
    </section>
  )
}

InviteClientNotification.propTypes = {
  count: PropTypes.number,
  dismissNotification: PropTypes.func,
  isHousehold: PropTypes.bool,
  message: PropTypes.string,
  recipients: PropTypes.array
}

export default InviteClientNotification
