import PropTypes from 'prop-types'

import AuthAppClassSideEffect from './AuthAppClassSideEffect'
import {Button} from '../../../dashboard/src/components/blocks/Buttons'
import HeaderFlavor from '../../../shared_components/header/HeaderFlavor'
import PageTitle from '../../../shared_components/PageTitle'
import UnorderedList from '../../../shared_components/UnorderedList'

import routerUtils from '../../../lib/routerUtils'

import security from '../../../shared_assets/security@3x.png'

import './privacySecurity.scss'

const PrivacySecurity = ({location}) => {
  const goToTfa = () => routerUtils.push(`auth/tfa-intro?loc=${location.query.loc}`)

  return (
    <div className='privacy-security central-content-box'>
      <HeaderFlavor flavor='transactional' />
      <PageTitle title='Privacy & Security' />
      <AuthAppClassSideEffect className='auth-interstitial' />
      <div>
        <h1>Everplans is serious about security.</h1>
        <div className='flex-container'>
          <img
            alt='padlock with a check mark'
            className='flex-child'
            src={security}
          />
          <div className='flex-child'>
            <p>
              We engineered Everplans with the guiding principle of keeping your
              personal information private, secure, and protected against any
              type of attacker.
            </p>
            <UnorderedList
              children={[
                'All information in your Everplan is encrypted using the most modern standards',
                'Your personal information belongs to you alone -- only people you choose to share your information with can decrypt your data',
                'We are governed and audited under the same security and privacy frameworks -- HIPAA and SOC2 -- used by major financial institutions and healthcare providers'
              ]}
            />
          </div>
        </div>
        <Button onClick={goToTfa}>Continue</Button>
      </div>
    </div>
  )
}

PrivacySecurity.propTypes = {
  location: PropTypes.object
}

export default PrivacySecurity
