import {useRef} from 'react'
import {fromJS} from 'immutable'
import PropTypes from 'prop-types'

import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import Fields from '../../../../shared_components/forms/v2/fields'
import Form from '../../../../shared_components/forms/v2/Form'

import {sanitize} from '../../../../lib/tools'

const DeputyFeedbackForm = props => {
  const formRef = useRef()

  const onSumbit = () => {
    if (formRef.current)
      props.submitComment(sanitize(formRef.current.formData().getIn(['comment', 'value']) || ''))
  }

  return (
    <div className='deputy-feedback-form forms-playground'>
      <Form className='core' ref={formRef}>
        <Fields.TextArea
          data={
            fromJS({
              id: 'comment',
              name: 'comment'
            })
          }
          maxlength='255'
        />
      </Form>
      <Accept processing={props.processing} onClick={onSumbit} disabled={props.disable}>Submit</Accept>
    </div>
  )
}

DeputyFeedbackForm.propTypes = {
  disable: PropTypes.bool,
  processing: PropTypes.bool,
  submitComment: PropTypes.func
}

export default DeputyFeedbackForm
