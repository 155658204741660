import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import CompoundFormComponent from '../../../../shared_components/forms/CompoundFormComponent'
import Container from '../../../../lib/Container'
import FirmActions from '../../actions/FirmActions'
import Forms from '../../../../shared_components/forms'
import SessionStore from '../../../../auth/src/stores/SessionStore'


export default class PickAdvisorController extends CompoundFormComponent {
  constructor(props) {
    super(props)
    this.setInitialState(props)
    this.store = Container.getStore('firm_users') // Created already in the routes file.
    this.state = {
      activeAdvisorSeats: this.fetchActiveAdvisors(),
      currentUser: SessionStore.getState().currentUser,
      formData: {}
    }

    this.onUpdate = this.onUpdate.bind(this)
  }
  componentDidMount() {
    this.store.addChangeListener(this.onUpdate)
    FirmActions.fetchFirmUsers(this.state.currentUser.firm)
  }
  onUpdate() {
    this.setState({activeAdvisorSeats: this.fetchActiveAdvisors()})
  }
  componentWillUnmount() {
    this.store.removeChangeListener(this.onUpdate)
  }
  advisors() {
    return this.state.activeAdvisorSeats.map(seat => ({key: seat.id, value: seat.name}))
  }
  fetchActiveAdvisors() {
    const advisors = this.store.getState().data.advisors
    if (advisors)
      return advisors.seats.filter(seat => seat.status === 'ACTIVE')

    return []
  }
  formValue() {
    return this.state.formData.advisor_seat_id
  }
  setInitialAdvisorValue() {
    var currentUserSeatId = this.state.currentUser.firm_seat_id

    if (this.props.advisorSeatId)
      return this.props.advisorSeatId
    else if (this.state.activeAdvisorSeats.map(seat => seat.id).includes(currentUserSeatId))
      return currentUserSeatId
    else if (this.state.activeAdvisorSeats.length === 1)
      return this.state.activeAdvisorSeats[0].id
  }

  render() {
    return (
      <PickAdvisor
        defaultValue={this.setInitialAdvisorValue()}
        list={this.advisors()}
        ref='select'
        value={this.setInitialAdvisorValue()}
        {...this.props}
        className={classnames(this.props.className, {hidden: this.advisors().length === 1})}
      />
    )
  }
}

PickAdvisorController.defaultProps = {
  label: 'Assign To:',
  placeholder: 'Choose an Advisor',
  validator(name, data) {
    var constraints = {
      [`${name}`]: {presence: {message: '^You must select an advisor for this client'}}
    }
    return Forms.Validators.template(name, data, constraints)
  }
}

PickAdvisorController.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  validator: PropTypes.func
}


export class PickAdvisor extends CompoundFormComponent {
  constructor(props) {
    super(props)
    this.setInitialState(props)
    this.state = {formData: {}}
    this.onFormElementChange = this.onFormElementChange.bind(this)
  }
  formValue() {
    return this.state.formData.advisor_seat_id
  }
  render() {
    return (
      <Forms.Select
        allowNewValueProp={true}
        {...this.props}
      />
    )
  }
}
