import './nav.scss'

const Nav = () => (
  <nav className='forms-playground-nav'>
    <a href='#/forms-playground'>All Elements</a>
    <a href='#/forms-playground/new-contact/'>New Contact</a>
    <a href='#/forms-playground/home-insurance/'>My Home Insurance</a>
    <a href='#/forms-playground/life-insurance/'>Life Insurance</a>
    <a href='#/forms-playground/popup-modal/'>Pop Up Modal</a>
    <a href='#/forms-playground/new-forms-example'>New Forms Example</a>
    <a className='external-link' href='/#/style-guide/'>Style Guide</a>
  </nav>
)

export default Nav
