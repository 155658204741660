import {Component} from 'react'
import {Map, fromJS} from 'immutable'
import PropTypes from 'prop-types'

import EidtFormController from './EditFormController'
import Fields from '../../../../../shared_components/forms/v2/fields/index'
import {Text} from '../../../../../dashboard/src/components/blocks/Texts'
import validators from '../../../../../shared_components/forms/v2/validators'

const TEXTS = {
  enabledText: 'Clients of the organization will not be able to add a Deputy ending with certain domains.',
  disabledText: 'Clients of the organization does not have domain restrictions when adding a Deputy.'
}

export default class EditDomainRestriction extends Component {
  constructor() {
    super()

    this.submit = this.submit.bind(this)
  }

  submit(data, callback) {
    this.props.updateSettings(
      {
        'block-deputization': !data.isEmpty(),
        'domain-names': data.getIn(['domain-names', 'value'], '')
      },
      callback
    )
  }

  controllerProps() {
    return {
      ...TEXTS,
      ...this.props,
      data: this.props.organization.get('block-deputization'),
      formClassName: 'domain-form',
      headerText: 'Domain Restriction',
      submit: this.submit
    }
  }

  render() {
    return (
      <EidtFormController {...this.controllerProps()}>
        <Fields.Text
          data={
            fromJS({
              id: 'domain-names',
              legend: 'Domain Names:',
              name: 'domain-names',
              value: this.props.organization.get('domain-names')
            })
          }
          validator={validators.required}
        />
        <Text className='hint'>Enter domains separated by a comma (e.g example.com,everplans.com)</Text>
      </EidtFormController>
    )
  }
}

EditDomainRestriction.propTypes = {
  organization: PropTypes.instanceOf(Map),
  processing: PropTypes.bool,
  updateSettings: PropTypes.func
}
