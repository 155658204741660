import {useState} from 'react'
import classnames from 'classnames'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {updateResource, readEndpoint} from 'redux-json-api'

import * as ButtonTypes from '../../../../../shared_components/ButtonTypes'

import {notifyAfterSend, sendClientInvitation} from '../../../../../lib/clientInviteTools'
import {goToInviteClientModal} from '../../../../../lib/navigation/proNavigation'
import {currentUserConfig} from '../../../../../lib/userTools'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'

import './inviteClientLink.scss'

export const InviteClientLink = props => {
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const sendInvitation = () => {
    const existingClientSeat = props.clientSeats.filterNot(seat => seat.get('id') === props.clientSeat.get('id')).first()
    const existingClient = props.clients.find(client => client.client_seat_id === (existingClientSeat && parseInt(existingClientSeat.get('id'), 10)))


    // In case users have a tad bit slow internet connection, this ensures that in addition to the visual cues showing that the resend invitation link should not be clicked after trying to resend
    // The link does nothing if there is a pending request
    if (!processing) {
      setProcessing(true)

      sendClientInvitation({...props}).then(() => {
        setProcessing(false)
        props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)
        notifyAfterSend({alwaysNotify, client: props.client, existingClient})
      })
    }
  }

  const handleClick = () => {
    if (props.organization.get('organization-client-invite') === 'engagement_automation')
      sendInvitation()
    else
      goToInviteClientModal({clientSeatId: props.clientSeatIdInParam, inviteSeatId: props.clientSeat.get('id')})
  }

  const linkText = `${processing ? 'Resending' : 'Resend'} invitation`

  if (props.clientSeat.get('status') === 'Pending')
    return <a className={classnames('resend-invite-link', {processing})} onClick={handleClick}>{linkText}</a>
  else
    return <ButtonTypes.Continue loading={processing} onClick={handleClick}>Send invitation</ButtonTypes.Continue>
}

InviteClientLink.propTypes = {
  client: PropTypes.shape({name: PropTypes.string, first_name: PropTypes.string, status: PropTypes.string}),
  clients: PropTypes.arrayOf(PropTypes.object),
  clientSeatIdInParam: PropTypes.string,
  clientSeat: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map),
  clientSeats: PropTypes.arrayOf(PropTypes.object),
  readEndpoint: PropTypes.func,
  updateResource: PropTypes.func,
  organization: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => ({userConfig: currentUserConfig(api)})

export default connect(mapStateToProps, {updateResource, readEndpoint})(InviteClientLink)
