import PropTypes from 'prop-types'
import {Component} from 'react'


export default class CobrandingPreviewLink extends Component {
  render() {
    if (this.props.isAccountManager && this.props.cobranding.attributes['cobranding-enabled']) {
      return (
        <a href={`/external/#/cobranding_preview/${this.props.cobranding.id}`} className='preview' rel='noreferrer' target='_blank'>
          Open preview in new window
        </a>
      )
    } else {
      return null
    }
  }
}

CobrandingPreviewLink.propTypes = {
  cobranding: PropTypes.shape({
    attributes: PropTypes.shape({
      'cobranding-enabled': PropTypes.bool
    }),
    id: PropTypes.string
  }),
  firmId: PropTypes.string,
  isAccountManager: PropTypes.bool
}
