import {useEffect} from 'react'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'
import PropTypes from 'prop-types'

import Loader from '../../../../shared_components/NewLoader'

import {dasherize} from '../../../../lib/integrationTools'
import routerUtils from '../../../../lib/routerUtils'

export const Authorizations = props => {
  useEffect(() => {
    const payload = {
      type: 'oauth-authorizations',
      attributes: dasherize(props.location.query)
    }

    props.createResource(payload)
      .then(response => {
        const {'next-route': nextRoute, ...query} = response.data.attributes

        routerUtils.push({pathname: nextRoute, query})
      })
      .catch(() => routerUtils.replace('/'))
  }, [])

  return <Loader />
}

Authorizations.propTypes = {
  createResource: PropTypes.func,
  location: PropTypes.shape({query: PropTypes.object})
}

export default connect(null, {createResource})(Authorizations)
