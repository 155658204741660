import {connect} from 'react-redux'
import classnames from 'classnames'
import {Link} from 'react-router'
import PropTypes from 'prop-types'

import EditFirm from './EditFirm'
import {findRawResourceById} from '../../../../lib/plan_data/userData'

import './firmHeader.scss'


export const FirmHeader = props => (
  <div className={classnames('firm-header', props.className, `${props.flowType}-wrapper`)}>
    {
      props.isAccountManager &&
      props.flavor === 'firmUser' &&
        <Link className='back-to-firm-users' to={`/pro/firm/${props.firmId}/users`}>&laquo;  Back to Firm Users</Link>
    }
    <div className='header-contents row-wrapper'>
      <h1>{props.headerText}</h1>
      {props.isAccountManager && !props.isMigrated && <span className='firm-attribute'>&nbsp;Legacy</span>}
      {props.internal && <span className='firm-attribute'>&nbsp;Internal</span>}
      {
        props.isAccountManager &&
        props.flavor === 'firm' &&
          <EditFirm firmName={props.headerText} {...props} />
      }
    </div>
    {props.children}
  </div>
)


const mapStateToProps = (state, {firmId}) => {
  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: firmId
  })
  return {isMigrated: firm.get('migrated-at')}
}

FirmHeader.defaultProps = {
  className: null,
  children: null,
  flowType: 'column',
  headerText: '',
  flavor: 'firm',
  isMigrated: null
}

FirmHeader.propTypes = {
  firmId: PropTypes.string,
  flavor: PropTypes.string,
  flowType: PropTypes.oneOf(['column', 'row']),
  headerText: PropTypes.string,
  isAccountManager: PropTypes.bool,
  internal: PropTypes.bool,
  isMigrated: PropTypes.string
}

export default connect(mapStateToProps)(FirmHeader)
