import PropTypes from 'prop-types'
import React from 'react'
import {withRouter} from 'react-router'

import InterstitialsController from './InterstitialsController'
import PendingRequestWrapper from '../../../../shared_components/blocks/PendingRequestWrapper'

import bindResources from '../../../../shared_components/BindToStores'
import {constructInterstitialPath, goToInterstitials} from '../../../../lib/interstitialTools'
import Container from '../../../../lib/Container'
import InterstitialsActions from '../../actions/InterstitialsActions'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import {objectValues} from '../../../../lib/shims'
import routerUtils from '../../../../lib/routerUtils'
import sessionUtils from '../../../../auth/src/lib/sessionUtils'
import SessionStore from '../../../../auth/src/stores/SessionStore'
import storePrototype from '../../../../shared_components/StorePrototype'

Container.registerAction('interstitials', InterstitialsActions)
Container.registerStore('firstInterstitial', storePrototype(InterstitialsActions.Types.GOT_FIRST_INTERSTITIAL))


export class PreInterstitialsHook extends React.Component {
  constructor(props) {
    super(props)

    this.store = Container.getStore('firstInterstitial')

    this.state = {
      loadingFirstInterstitial: !!props.location.query.first,
      loading: true,
      showCancelDeathReport: true,
      showIntroduction: true
    }

    this.checkForHooks = this.checkForHooks.bind(this)
    this.onArrivalOfFirstInterstitialData = this.onArrivalOfFirstInterstitialData.bind(this)
  }

  componentDidMount() {
    // Go get the info about the first interstitial if the 'first' query is present:
    const firstInterstitialToken = this.props.location.query.first
    if (firstInterstitialToken) {
      this.store.addChangeListener(this.onArrivalOfFirstInterstitialData)
      InterstitialsActions.fetchFirstInterstitial(firstInterstitialToken)
    }

    // Ensure this controller always takes control when it mounts:
    if (!this.props.router.isActive('pending-requests', true))
      routerUtils.push(`pending-requests${firstInterstitialToken ? `?first=${firstInterstitialToken}` : ''}`)
  }

  onArrivalOfFirstInterstitialData() {
    const firstInterstitial = this.store.getState()

    this.setState({loadingFirstInterstitial: false})

    if (firstInterstitial.errors.length) // Fail back to base route if the fetch errors for some reason
      goToInterstitials()
    else
      this.checkForHooks(this.props.interstitials.data, firstInterstitial)
  }

  UNSAFE_componentWillReceiveProps(nextProps) { this.checkForHooks(nextProps.interstitials.data) }

  componentWillUnmount() { this.store.removeChangeListener(this.onArrivalOfFirstInterstitialData) }

  hasInterstitials(data) {
    // @todo Once client invite interstitial is live for all users, remove the deputy_only flag and pass entire data object to objectValues below
    Reflect.deleteProperty(data, 'deputy_only')

    return [].concat(...objectValues(data)).length > 0
  }

  checkForHooks(data, firstInterstitial = null) {
    const firstInterstitialEmpty = !firstInterstitial || $.isEmptyObject(firstInterstitial.data)
    const firstInterstitialToken = this.props.location.query.first

    // Don't do anything if any expected data is not yet present:
    if ($.isEmptyObject(data) || (firstInterstitialToken && firstInterstitialEmpty)) {
      // sometimes there's a first interstitial token and the data is empty and this leads to an endless spinner
      if (firstInterstitialToken)
        InterstitialsActions.fetchFirstInterstitial(firstInterstitialToken)
      return
    }

    // Continue if user has no pending requests to address:
    if (!this.hasInterstitials(data))
      return sessionUtils.sendUserToNextRoute(SessionStore.getState().currentUser)

    this.setState({loading: false})

    // Always show the option to cancel pending `DeathReports` first if they are present:
    if (data.death_reports && data.death_reports.length && this.state.showCancelDeathReport) {
      return this.setState(
        {showCancelDeathReport: false},
        () => routerUtils.push(constructInterstitialPath(null, 'cancel-death-report'))
      )
    }

    // Go to first interstitial if URL has correct key in query and the bound fetch returned data:
    if (this.props.location.query.first && !firstInterstitialEmpty) {
      const firstInterstitialData = firstInterstitial.data

      // Query string forces the Deputy interstitial controller to cede control back to this component after first interstitial is addressed:
      return routerUtils.push(constructInterstitialPath(firstInterstitialData.id, firstInterstitialData.type, 'break=true'))
    }

    // Go to the interstitials intro screen if it hasn't been shown:
    if (this.state.showIntroduction && this.props.router.isActive('pending-requests', true))
      return this.setState({showIntroduction: false}, () => routerUtils.push('pending-requests/before-we-continue'))
  }

  render() {
    return (
      <LegacyLoader loading={this.state.loading}>
        <PendingRequestWrapper loading={this.state.loadingFirstInterstitial || this.props.loadingInterstitials}>
          <div className='pre-interstitials-hook'>
            <InterstitialsController
              {...this.props}
              indexPathActive={this.props.router.isActive('pending-requests', true)}
              showingIntroduction={this.props.router.isActive('pending-requests/before-we-continue', true)}
            />
          </div>
        </PendingRequestWrapper>
      </LegacyLoader>
    )
  }
}

PreInterstitialsHook.defaultProps = {
  interstitials: {
    data: {}
  }
}

PreInterstitialsHook.propTypes = {
  interstitials: PropTypes.shape({
    data: PropTypes.shape({
      client_invite: PropTypes.number,
      death_reports: PropTypes.arrayOf(PropTypes.object),
      deputy_invites: PropTypes.arrayOf(PropTypes.number)
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }),
  loadingInterstitials: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first: PropTypes.string
    })
  }),
  router: PropTypes.shape({
    isActive: PropTypes.func
  })
}

export default withRouter(
  bindResources(
    PreInterstitialsHook,
    {name: 'interstitials', type: 'list'}
  )
)
