import aicpaSocLogo from '../../../../assets/aicpa-soc-logo.svg'
import facebookLogo from '../../../../assets/facebook-logo.svg'
import hipaaCompliantLogo from '../../../../assets/hipaa-compliant-logo.svg'
import instagramLogo from '../../../../assets/instagram-logo.svg'
import linkedinLogo from '../../../../assets/linkedin-logo.svg'

export const footerData = {
  everplansLinksLists: [
    {
      ariaLabel: 'company',
      listTitle: 'COMPANY',
      linksList: [
        {
          linkText: 'About',
          url: 'https://www.everplans.com/about'
        },
        {
          linkText: 'News',
          url: 'https://www.everplans.com/news'
        },
        {
          linkText: 'Partnerships',
          url: 'https://www.everplans.com/partnerships'
        },
        {
          linkText: 'Press',
          url: 'https://www.everplans.com/press'
        }
      ]
    },
    {
      ariaLabel: 'resources',
      listTitle: 'RESOURCES',
      linksList: [
        {
          linkText: 'Topics',
          url: 'https://www.everplans.com/topics'
        },
        {
          linkText: 'Book',
          url: 'https://www.everplans.com/incasethebook'
        }
      ]
    },
    {
      ariaLabel: 'support',
      listTitle: 'SUPPORT',
      linksList: [
        {
          linkText: 'FAQs',
          url: 'https://www.everplans.com/faq'
        },
        {
          linkText: 'Help Center',
          url: 'https://help.everplans.com/hc/en-us'
        },
        {
          linkText: 'Contact',
          url: 'https://www.everplans.com/contact'
        },
        {
          linkText: 'Security',
          url: 'https://www.everplans.com/security'
        }
      ]
    }
  ],
  everplansSocialMediaLinks: [
    {
      ariaLabel: 'Everplans Linkedin',
      logoSrc: linkedinLogo,
      url: 'https://www.linkedin.com/company/everplans'
    },
    {
      ariaLabel: 'Everplans Instagram',
      logoSrc: instagramLogo,
      url: 'https://www.instagram.com/everplans'
    },
    {
      ariaLabel: 'Everplans Facebook',
      logoSrc: facebookLogo,
      url: 'https://www.facebook.com/everplans'
    }
  ],
  complianceImages: [
    {
      altText: 'HIPAA Compliant',
      className: '',
      imageSrc: hipaaCompliantLogo
    },
    {
      altText: 'AICPA SOC',
      className: 'aicpa-soc-logo',
      imageSrc: aicpaSocLogo
    }
  ],
  everplansAgreementLinks: [
    {
      linkText: 'Terms of Service',
      url: 'https://www.everplans.com/terms-of-service'
    },
    {
      linkText: 'Privacy Policy',
      url: 'https://www.everplans.com/privacy-policy'
    }
  ]
}
