import PropTypes from 'prop-types'

import SelectFilter from '../../shared_components/SelectFilter'
import SummariesTable from '../../shared_components/summaries_table/SummariesTable'

import {monthDayYear} from '../../../../../lib/dateTools'

const defaultFirm = (organization, firms) => {
  const catchAllFirm = firms.find(firm => parseInt(firm.id, 10) === organization['catch-all-firm-id'])

  return catchAllFirm && `${catchAllFirm.id}: ${catchAllFirm.name}`
}

const OrganizationsTable = props => {
  const columns = [
    {
      id: 's/n',
      Header: 'Serial Number',
      minWidth: 40,
      sortable: false,
      Cell: columnProps => <div>{columnProps.index + 1}</div>
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 60,
      filterable: true
    },
    {
      id: 'catch-all-firm',
      Header: 'Catch All Firm',
      accessor: organization => defaultFirm(organization, props.firms),
      sortable: false,
      minWidth: 60
    },
    {
      id: 'block-deputization',
      Header: 'Domain Restriction',
      accessor: organization => (organization['block-deputization'] ? 'Yes' : 'No'),
      minWidth: 60,
      filterable: true,
      Cell: columnProps => <span>{columnProps.value}</span>,
      Filter: columnProps => (
        <SelectFilter
          {...columnProps}
          options={[{name: 'Yes', id: '1'}, {name: 'No', id: '0'}]}
        />
      )
    },
    {
      id: 'default-invite-email-text',
      Header: 'Custom Invite',
      accessor: organization => (organization['default-invite-email-text'] ? 'Yes' : 'No'),
      minWidth: 60,
      filterable: true,
      Cell: columnProps => <span>{columnProps.value}</span>,
      Filter: columnProps => (
        <SelectFilter
          {...columnProps}
          options={[{name: 'Yes', id: '1'}, {name: 'No', id: '0'}]}
        />
      )
    },
    {
      id: 'created-at',
      Header: 'Created',
      minWidth: 60,
      accessor: organization => monthDayYear(organization['created-at'], 'L')
    },
    {
      id: 'updated-at',
      Header: 'Last Updated',
      minWidth: 60,
      accessor: organization => monthDayYear(organization['updated-at'], 'L')
    }
  ]

  return (
    <SummariesTable
      {...props}
      data={props.organizations}
      columns={columns}
      loading={props.loadingOrganizations}
    />
  )
}

OrganizationsTable.propTypes = {
  firms: PropTypes.arrayOf(PropTypes.object),
  organizations: PropTypes.array,
  onFetchData: PropTypes.func,
  onRowClicked: PropTypes.func,
  pages: PropTypes.number,
  loadingOrganizations: PropTypes.bool
}

export default OrganizationsTable
