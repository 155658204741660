import {Link} from 'react-router'
import PropTypes from 'prop-types'

const ResendPendingInvites = props => (
  <li>
    {
      props.hasPendingClients ?
        <Link to='/pro/clients/bulk-actions/select-advisor/pending'>Resend Pending Invitations...</Link> :
        <a title='Resend Invitations is only available if you have clients in pending state.' className='disabled'>Resend Pending Invitations...</a>
    }
  </li>
)

ResendPendingInvites.propTypes = {hasPendingClients: PropTypes.bool}

export default ResendPendingInvites
