import React from 'react'

export default class Input extends React.Component {
  inputValue() {
    return this.refs['input'].value.trim();
  }

  type() {
    return this.props.type ? this.props.type : 'text'
  }

  render() {
    return (
      <div className="form-group">
        <Label label={this.props.label} /><input {...this.props} ref='input' type={this.type()} />
      </div>
    )
  }
}

class Label extends React.Component {
  render() {
    if (!this.props.label) return null
    return <label>{this.props.label}</label>
  }
}