import {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import routerUtils from '../../../../lib/routerUtils'
import FirmActions from '../../actions/FirmActions'
import OrganizationActions from '../../actions/OrganizationActions'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import SessionApi from '../../../../auth/src/web/SessionApi'
import SessionStore from '../../../../auth/src/stores/SessionStore'
import {findRawResourceById} from '../../../../lib/plan_data/userData'

import './firm.scss'

Container.registerAction('firm', FirmActions)
Container.registerAction('firm_config', FirmActions)
Container.registerAction('organization', FirmActions)
Container.registerAction('organizations', OrganizationActions)

export class Firm extends Component {
  constructor() {
    super()

    this.state = {
      currentUser: SessionStore.getState().currentUser
    }

    this.onUpdate = this.onUpdate.bind(this)
  }

  componentDidMount() {
    this.props.readEndpoint(`firms/${this.props.params.firmId}`)
    SessionStore.addChangeListener(this.onUpdate)
    SessionApi.fetchCurrentUser()
  }

  onUpdate() { this.setState({currentUser: SessionStore.getState().currentUser}) }

  componentWillUnmount() { SessionStore.removeChangeListener(this.onUpdate) }

  componentDidUpdate(prevProps) {
    if (prevProps.firm.data.organization_id && !this.props.organization)
      FirmActions.fetchOrganization(this.props.firm.data.organization_id)
  }

  render() {
    return (
      <LegacyLoader loading={this.props.loadingFirm || this.props.loadingFirmConfig || this.props.loadingOrganizations || this.props.loadingOrganization || this.props.loadingOrganization}>
        <div className='firm'>
          {
            routerUtils.childrenWithProps(
              this.props.children,
              {
                currentUser: this.state.currentUser,
                firm: this.props.firm,
                firm_config: this.props.firm_config,
                organization: this.props.organization,
                organizations: this.props.organizations,
                loadingOrganizations: this.props.loadingOrganizations
              }
            )
          }
        </div>
      </LegacyLoader>
    )
  }
}

Firm.defaultProps = {
  firm: {
    data: {},
    errors: [],
    message: null
  },
  firm_config: {
    data: {},
    errors: [],
    message: null
  },
  loadingFirm: true,
  loadingFirmConfig: true
}

Firm.propTypes = {
  firm: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      organization_id: PropTypes.string
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  firm_config: PropTypes.shape({
    data: PropTypes.shape({
      compliance_level: PropTypes.oneOf(['high', 'standard']),
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  loadingFirm: PropTypes.bool.isRequired,
  loadingFirmConfig: PropTypes.bool.isRequired,
  loadingOrganizations: PropTypes.bool.isRequired,
  loadingOrganization: PropTypes.bool,
  organization: PropTypes.shape({
    data: PropTypes.shape({
      organization_id: PropTypes.string
    })
  }),
  organizations: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object])
  })
}

const mapStateToProps = (state, ownProps) => {
  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: ownProps.params.firmId
  })

  return ({
    loading: firm.isEmpty() || ownProps.loadingFirm || ownProps.loadingFirmConfig || ownProps.loadingOrganizations
  })
}

export default connect(mapStateToProps, {readEndpoint})(
  bindResources(
    Firm,
    [
      {name: 'firm', type: 'item'},
      {id: 'firmId', name: 'firm_config', type: 'item'},
      {name: 'organizations', type: 'list'},
      {name: 'organization', type: 'item'}
    ]
  )
)


