import PropTypes from 'prop-types'

import './statusToggle.scss'

const StatusToggle = props => {
  return (
    <div className='row-wrapper status-toggle'>
      <p>Status:</p>
      <div className='column-wrapper'>
        <ToggleWithLabel {...props} checked={!props.enabledChecked} description={props.description.disabled} statusText={props.statusText.disabled} />
        <ToggleWithLabel {...props} checked={props.enabledChecked} description={props.description.enabled} statusText={props.statusText.enabled} />
      </div>
    </div>
  )
}

const ToggleWithLabel = props => {
  return (
    <label className='status-label'>
      <input defaultChecked={props.checked} name={`${props.name}-radio`} onChange={props.onChange} type='radio' value='disabled' />
      <span>{props.statusText}</span>
      <div className='description'>{props.description}</div>
    </label>
  )
}

StatusToggle.defaultProps = {enabledChecked: false}

StatusToggle.propTypes = {
  enabledChecked: PropTypes.bool,
  description: PropTypes.shape({
    disabled: PropTypes.string,
    enabled: PropTypes.string
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  statusText: PropTypes.shape({
    disabled: PropTypes.string,
    enabled: PropTypes.string
  }).isRequired
}

export default StatusToggle
