import {Link} from 'react-router'
import PropTypes from 'prop-types'

import HorizontalList from './HorizontalList'

import {isEnagagementAutomated} from '../../../../lib/tools'

import './navTypes.scss'
import {isNorthwesternMutualOrganization} from '../../../../lib/integrationTools'

export const AdvisorNav = props => {
  const basePath = `/pro/firm/${props.firmId}/user/${props.firmUserSeatId}`

  return (
    <HorizontalList className='firm-navigation'>
      <Link activeClassName='active' to={`${basePath}/account-info`}>
        ACCOUNT INFO
      </Link>
      <Link activeClassName='active' to={`${basePath}/contact-card`}>
        CONTACT CARD
      </Link>
      {props.firmConfig.compliance_level !== 'high' &&
        !isEnagagementAutomated(props.organization) && (
        <Link activeClassName='active' to={`${basePath}/invitation-message`}>
          INVITATION MESSAGE
        </Link>
      )}
      {
        (props.has_integrations || isNorthwesternMutualOrganization(props.organization)) && (
          <Link activeClassName='active' to={`${basePath}/integrations`}>
            INTEGRATIONS
          </Link>
        )
      }
    </HorizontalList>
  )
}

AdvisorNav.propTypes = {
  firmId: PropTypes.string.isRequired,
  firmUserSeatId: PropTypes.string.isRequired,
  has_integrations: PropTypes.bool,
  firmConfig: PropTypes.object,
  isMigrated: PropTypes.bool,
  organization: PropTypes.shape({
    data: PropTypes.object
  })
}


export default AdvisorNav
