export default [
  {
    'element-id': 'a729f189-49e6-481d-83a2-9dd76bdaefff',
    id: '0d6e8821-8611-4119-be1a-7e28ca99d1ef',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953', '8967c3b7-c48e-4735-b24e-294a44fb3d60'],
    value: 'Human',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-09T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '8deea75f-5548-49bf-b41f-465ef9f41d28',
    id: 'd0b5d4dc-80f3-4440-8076-139ac1ca5484',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953'],
    'ownership-ids': [1, 2],
    value: '',
    'created-at': '2018-03-09T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': 'd63f8cff-14aa-4e0e-9dcc-0c3c24e78c64',
    id: 'ed6c107a-e3dd-4df3-975b-a288bcfc8251',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953'],
    value: 'legalzoom.com',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-02T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': 'c4b70a1e-202e-4491-84e6-341db8355b68',
    id: '42df5cd6-6e7e-46bb-a29e-713e90f17e07',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953'],
    value: 'Online',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-06T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '512ef931-a00c-43ee-8ccf-74f6d840bdc1',
    id: '368b28d2-7b3f-4eb9-af33-8150934f7ac8',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953', '8967c3b7-c48e-4735-b24e-294a44fb3d60'],
    'ownership-ids': [1, 2],
    'created-at': '2018-03-06T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': 'c18177cf-b7d7-43ac-b3c2-2b5d47297dd1',
    id: '6b3492b8-c0c9-4cbb-b292-5fd6d87c653e',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953', '8967c3b7-c48e-4735-b24e-294a44fb3d60'],
    value: '',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-06T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '75b2e3e4-6858-447f-98ca-32978e6f9ec1',
    id: '6b3492b8-c0c9-4cbb-b292-5fd6d87c348b',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953', '8967c3b7-c48e-4735-b24e-294a44fb3d60'],
    value: 'cd',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-06T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '512ef931-a00c-43ee-8ccf-74f6d840bdc1',
    id: 'df39e9e4-e728-46ba-982a-e1fc18add514',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953', '27ea86a6-8803-40e1-a7b5-2d77d0f0dd9e'],
    value: 'Maria',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-06T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': 'a729f189-49e6-481d-83a2-9dd76bdaefff',
    id: 'ac96913e-4a79-4bd5-8d40-accbd53e5358',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953', '27ea86a6-8803-40e1-a7b5-2d77d0f0dd9e'],
    value: 'Sister',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-06T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '9e7d94e7-abe4-4e24-875d-eeef84b9b7a2',
    id: 'db6c157f-e059-459c-9b27-c86dc0692004',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953'],
    value: 'legalzoom',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-06T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '1e73cb0e-283a-4694-b5b9-9383fb4a9188',
    id: '612023dc-8776-43f9-af29-5ed6c409ca5a',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c953'],
    value: 'In the trunk of the car',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-06T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': 'c4b70a1e-202e-4491-84e6-341db8355b68',
    id: '42df5c06-6e7e-46bb-a29e-713e90f17e09',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c955'],
    value: 'Online',
    'ownership-ids': [1],
    'created-at': '2018-02-01T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '8549c85b-879b-4c84-a913-9f4128c3347e',
    id: '42df5c06-6e7e-46bb-a29e-713e90f17e22',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c124', '3fe99584-7784-42b9-b336-f0364eb3c122'],
    value: {date: '2019-11-01', mask: 7},
    'ownership-ids': [1],
    'created-at': '2018-02-01T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '590c2aca-39d4-4196-8c61-573867ec7cd4',
    id: '612023dc-8776-43f9-af29-5ed6c401123',
    'group-id': ['0521ee10-6c87-420b-a1c1-c8a8f90c5dd4', 'ad338e26-ae61-42fe-89ca-f879522e6682'],
    value: 'Cat',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-01T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '51027462-816e-41e6-9517-64e21aa1db2c',
    id: '42df5c06-6e7e-46bb-a29e-713e9076810',
    'group-id': ['0521ee10-6c87-420b-a1c1-c8a8f90c5dd4', 'ad338e26-ae61-42fe-89ca-f879522e6682'],
    value: 'Apollo',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-03T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '590c2aca-39d4-4196-8c61-573867ec7cd4',
    id: '612023dc-8776-43f9-af29-5ed6c401121',
    'group-id': ['0521ee10-6c87-420b-a1c1-c8a8f90c5dd4', 'ad338e26-ae61-42fe-89ca-f879522e66833'],
    value: 'Cat',
    'ownership-ids': [1],
    'created-at': '2018-04-04T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '51027462-816e-41e6-9517-64e21aa1db2c',
    id: '42df5c06-6e7e-46bb-a29e-713e907688',
    'group-id': ['0521ee10-6c87-420b-a1c1-c8a8f90c5dd4', 'ad338e26-ae61-42fe-89ca-f879522e66833'],
    value: 'Akela',
    'ownership-ids': [1],
    'created-at': '2018-03-05T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': 'c5f8f354-3fac-41e7-22be-4f87caeb7396',
    id: '52df5c06-3e7e-56bb-a49e-723e907684',
    'group-id': ['0521ee10-6c87-420b-a1c1-c8a8f90c5dd4', 'ad338e26-ae61-42fe-89ca-f879522e66833'],
    value: ['Atanda', 'Bayo', 'Tolu'],
    'ownership-ids': [1],
    'created-at': '2018-03-09T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': 'f5f8f354-3fac-41e7-22be-4f87caeb5647',
    id: '46df3c05-4e6e-55bb-a99e-723e907633',
    'group-id': ['0521ee10-6c87-420b-a1c1-c8a8f90c5dd4', 'ad338e26-ae61-42fe-89ca-f879522e66833'],
    value: [{count: 2}],
    'ownership-ids': [1],
    'created-at': '2018-03-09T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '555c6c0b-5a17-49a9-bc33-26524357af06',
    id: '42df5c06-6e7e-46bb-a29e-713e907699',
    'group-id': ['0521ee10-6c87-420b-a1c1-c8a8f90c5dd1', 'ad338e26-ae61-42fe-89ca-f879522e66232'],
    value: 'none',
    'ownership-ids': [1],
    'created-at': '2018-03-07T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '51027462-816e-41e6-9517-64e21aa1db2c',
    id: '42df5c06-6e7e-46bb-a29e-713e90769977',
    'group-id': ['0521ee10-6c87-420b-a1c1-c8a8f90c5679', 'ad338e26-ae61-42fe-89ca-f879522e668323'],
    value: null,
    'ownership-ids': [1],
    'created-at': '2018-03-09T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  {
    'element-id': '5a35e19e-f337-4794-9048-a541c1da775e',
    id: '42df5c06-6e7e-46bb-a29e-713e90769002',
    'group-id': ['3fe99584-7784-42b9-b336-f0364eb3c100', '8967c3b7-c48e-4735-b24e-294a44fb200'],
    value: '1',
    'ownership-ids': [1],
    'created-at': '2018-03-07T16:26:34.386Z',
    'updated-at': '2018-03-06T16:26:34.386Z'
  },
  /* Beginning of Responses for rectify conditional map tests */
  {
    id: '47dc455f-ae9f-48f3-8a76-31072cb37640',
    'updated-at': '2018-05-04T19:08:39.006Z',
    'group-id': ['3ac61f5b-2e78-4d96-8aa5-a95d70d86ab2', 'c30130c9-e3da-4bb5-acc1-df8b1abc3c9a'],
    'element-id': '197d2c4b-441e-4b75-9960-4715af470312',
    'ownership-ids': [1, 2],
    value: 'Other',
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: 'bfa8a3b5-41b9-48db-a4ad-3d8639b67a9a',
    'updated-at': '2018-05-04T19:08:39.037Z',
    value: null,
    'group-id': ['3ac61f5b-2e78-4d96-8aa5-a95d70d86ab2', 'c30130c9-e3da-4bb5-acc1-df8b1abc3c9a'],
    'element-id': '967c94cb-92da-401d-a96e-fc0be94a968e',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: '699d2dff-7fed-4cca-9847-e11665569337',
    'updated-at': '2018-05-04T19:08:39.053Z',
    value: null,
    'group-id': ['3ac61f5b-2e78-4d96-8aa5-a95d70d86ab2', 'c30130c9-e3da-4bb5-acc1-df8b1abc3c9a'],
    'element-id': 'da1019cb-4741-4b35-befc-d10183da409e',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: '205c3d51-b181-4b04-9c93-3006e63225c2',
    'updated-at': '2018-05-04T19:08:39.072Z',
    'group-id': ['3ac61f5b-2e78-4d96-8aa5-a95d70d86ab2', 'c30130c9-e3da-4bb5-acc1-df8b1abc3c9a'],
    'element-id': '0dae7783-3097-4a0c-8057-2434b5e7727a',
    'ownership-ids': [1, 2],
    value: 'Hello',
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: '47dc455f-ae9f-48f3-8a76-31072cb37642',
    'updated-at': '2018-05-04T19:08:39.006Z',
    'group-id': ['3ac61f5b-2e78-4d96-8aa5-a95d70d86ab2', 'c30130c9-e3da-4bb5-acc1-df8b1abc3c91'],
    'element-id': '197d2c4b-441e-4b75-9960-4715af470312',
    'ownership-ids': [1, 2],
    value: "Own - it's all paid for",
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: 'bfa8a3b5-41b9-48db-a4ad-3d8639b67a93',
    'updated-at': '2018-05-04T19:08:39.037Z',
    value: null,
    'group-id': ['3ac61f5b-2e78-4d96-8aa5-a95d70d86ab2', 'c30130c9-e3da-4bb5-acc1-df8b1abc3c91'],
    'element-id': 'aca9f1cb-c164-4d2f-b34c-4be3e32b3aa9',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: '699d2dff-7fed-4cca-9847-e11665569333',
    'updated-at': '2018-05-04T19:08:39.053Z',
    value: 'TX2490808',
    'group-id': ['3ac61f5b-2e78-4d96-8aa5-a95d70d86ab2', 'c30130c9-e3da-4bb5-acc1-df8b1abc3c91'],
    'element-id': '62e8de77-1c16-47e9-88a3-135c47b3c662',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  /* Beginning of Responses for compound item */
  {
    id: '1223455f-ae9f-48f3-8a76-31072cb36767',
    'updated-at': '2018-05-04T19:08:39.006Z',
    'group-id': ['356uf75b-2e78-4er6-4aa5-a95d70d81111', 'cv5730c9-e3da-4bb5-acc1-df6565653c9a'],
    'element-id': 'b32457c2-9b3b-470e-abd8-0f7db179b820',
    'ownership-ids': [1, 2],
    value: '10',
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: '1223a3b5-41b9-48db-a4ad-3d8639b66767',
    'updated-at': '2018-05-04T19:08:39.037Z',
    value: 'No notes to share here',
    'group-id': ['356uf75b-2e78-4er6-4aa5-a95d70d81111', 'cv5730c9-e3da-4bb5-acc1-df6565653c9a'],
    'element-id': 'b47f6a4b-dd52-43dd-bc05-3e5e3b706141',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: '12232dff-7fed-4cca-9847-e11665566767',
    'updated-at': '2018-05-04T19:08:39.053Z',
    value: null,
    'group-id': ['356uf75b-2e78-4er6-4aa5-a95d70d81111', 'cv5730c9-e3da-4bb5-acc1-df6565653c9a'],
    'element-id': 'ca03c7a3-10a8-4374-89b0-4b085f6ccdef',
    'ownership-ids': [1, 2],
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  /* End of Responses for compound item */
  /* Beginning of Responses for simple conditional item IDs and Vital Documentation */
  {
    id: '1223455f-ae9f-48f3-8a76-31072cb22227',
    'updated-at': '2018-05-04T19:08:39.006Z',
    'group-id': ['356uf75b-2e78-4er6-4aa5-a95d70d211', 'cv5730c9-e3da-4bb5-acc1-df65623233c9a'],
    'element-id': '29f24d23-2b4a-41a2-ba2f-895ea2c660d6',
    'ownership-ids': [1, 2],
    value: 'Social Security Card',
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: '334343434-ae9f-48f3-234r-242343245',
    'updated-at': '2018-05-04T19:08:39.006Z',
    'group-id': ['fdsdf-dsfdf-resdf-sfdf-sdfdsffew4', 'resdf-4werdsf-4bb5-sdfwer-sdferw'],
    'element-id': '29f24d23-2b4a-41a2-ba2f-895ea2c660d6',
    'ownership-ids': [1, 2],
    value: 'Other',
    'created-at': '2018-03-07T16:26:34.386Z'
  },
  {
    id: 'dsfrw424r-32df-32fd-32fs4fg-3432fdsd',
    'updated-at': '2018-05-04T19:08:39.006Z',
    'group-id': ['fdsdf-dsfdf-resdf-sfdf-sdfdsffew4', 'resdf-4werdsf-4bb5-sdfwer-sdferw'],
    'element-id': '87483h-34rer-343sdf-34df-ghb34323jhj',
    'ownership-ids': [1, 2],
    value: 'Cadet',
    'created-at': '2018-03-07T16:26:34.386Z'
  }
  /* End of Responses for simple conditioanl item */
]
