import ActionPrototype from '../../../shared_components/ActionPrototype'
import assign from 'object-assign'
import KeyMirror from 'keymirror'
import routerUtils from '../../../lib/routerUtils'

export default assign({}, ActionPrototype, {
  Types: KeyMirror({
    DID_CHANGE_PASSWORD: null,
    DID_SEND_INSTRUCTIONS: null,
    GOT_RESET_PASSWORD_TOKEN_VALIDATION: null
  }),
  changePassword(data, completePasswordChange) {
    this.fireApi(
      'put',
      'password',
      {data},
      {
        JSONHead: 'password',
        onSuccess: completePasswordChange,
        successAction: this.Types.DID_CHANGE_PASSWORD
      }
    )
  },
  fetchResetPasswordTokenValidation(token) {
    this.fireApi(
      'get',
      `password/validate_reset_password_token/${token}`,
      null,
      {
        JSONHead: 'reset_password_token',
        successAction: this.Types.GOT_RESET_PASSWORD_TOKEN_VALIDATION
      }
    )
  },
  sendResetInstructions(data) {
    this.fireApi(
      'post',
      'password',
      {email: data.email.toLowerCase().trim()},
      {
        JSONHead: 'password',
        onSuccess: () => routerUtils.push({pathname: '/auth/password/check-mail', query: data}),
        successAction: this.Types.DID_SEND_INSTRUCTIONS
      }
    )
  }
})
