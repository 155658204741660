import PropTypes from 'prop-types'
import {Component} from 'react'

import Tooltip from './Tooltip'

import routerUtils from '../../../lib/routerUtils'

class RecurringTooltip extends Component {
  constructor() {
    super()

    this.state = {showTooltip: false}

    this.toggleTooltip = this.toggleTooltip.bind(this)
  }

  toggleTooltip() { this.setState({showTooltip: !this.state.showTooltip}) }

  render() {
    return (
      <div className='recurring-tooltip'>
        {routerUtils.childrenWithProps(this.props.launcher, {onClick: this.toggleTooltip})}
        {this.state.showTooltip && <Tooltip {...this.props} />}
      </div>
    )
  }
}

RecurringTooltip.propTypes = {
  launcher: PropTypes.node
}

export default RecurringTooltip
