import AppClassController from '../../../shared_components/AppClassController'
import ClientOnboardingApp from './ClientOnboardingApp'


const ClientOnboardingController = props => (
  <AppClassController defaultClassName='client-onboarding' storeName='clientOnboardingClassName'>
    <ClientOnboardingApp {...props} />
  </AppClassController>
)

export default ClientOnboardingController
