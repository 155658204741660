import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'
import {useEffect} from 'react'
import TimeoutModal from '../../../shared_components/TimeoutModal'

import Loader from '../../../shared_components/NewLoader'
import sessionUtils from '../../../auth/src/lib/sessionUtils'
import routerUtils from '../../../lib/routerUtils'

export const JiclUserContainer = props => {
  useEffect(() => {
    // The routes here will be updated to the actual ones when they are ready.
    props.readEndpoint('settings')
    props.readEndpoint('user-configs')
      .then(response => {
        const jiclUser = response.body.data.attributes['jicl-user']

        if (jiclUser) {
          const everplanId = response.body.data.attributes['everplan-id']
          const userId = response.body.data.id

          props.readEndpoint(`users/${userId}?include=assessment`)
          props.readEndpoint(`everplan-responses/${everplanId}?include=new-contacts`)
          props.readEndpoint('jicl-configs')
        } else {
          routerUtils.push('404')
        }
      })
      // Take user to JICL sign in page when this fails.
      .catch(() => routerUtils.push('/jicl/continue'))
  }, [])

  return <div><TimeoutModal signoutCallback={sessionUtils.cleanupJiclSession} needsCheck={true} /><Loader loading={props.loading}>{props.children}</Loader></div>
}

JiclUserContainer.propTypes = {
  readEndpoint: PropTypes.func
}

const isLoadingData = state =>
  !(
    state.api.users &&
    state.api['user-configs'] &&
    state.api['jicl-configs'] &&
    state.api['everplan-responses'] &&
    state.api.settings
  )

const mapStateToProps = state => ({loading: isLoadingData(state)})

export default connect(mapStateToProps, {readEndpoint})(JiclUserContainer)
