import PropTypes from 'prop-types'
import {Link} from 'react-router'

import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import {TextGutterSmall} from '../../../../dashboard/src/components/blocks/Texts'

import './canceledDeathReport.scss'


const CanceledDeathReport = ({returnToController}) => (
  <div className='canceled-death-report central-content-box'>
    <h2>Thank you--your Everplan will remain locked.</h2>
    <TextGutterSmall>
      None of your "after death" items have been shared with your deputies. These items will remain locked.
    </TextGutterSmall>
    <TextGutterSmall>
      Please go to your <Link to='cm/deputies' className='link'>My Deputies</Link> page to review who can request
      unlocking of your "after death" items and the wait time associated with each deputy with the permission.
    </TextGutterSmall>
    <Accept onClick={returnToController}>Continue</Accept>
  </div>
)

CanceledDeathReport.propTypes = {
  returnToController: PropTypes.func
}

export default CanceledDeathReport
