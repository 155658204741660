import PropTypes from 'prop-types'
import {Component} from 'react'

import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'
import Loader from '../../../../shared_components/NewLoader'

import Container from '../../../../lib/Container'
import {getCookie} from '../../../../lib/cookieJar'
import routerUtils from '../../../../lib/routerUtils'
import SignUpActions from '../../actions/SignUpActions'
import storePrototype from '../../../../shared_components/StorePrototype'

Container.registerStore('partnerInformation', storePrototype(SignUpActions.Types.DID_GET_PARTNER_NAME))


export default class SignUpController extends Component {
  constructor() {
    super()

    this.state = {
      email: null,
      loadingPartner: true
    }

    this.onPartnerUpdate = this.onPartnerUpdate.bind(this)
    this.partnerDiscountCode = this.partnerDiscountCode.bind(this)
    this.partnerNameStore = Container.getStore('partnerInformation')
    this.setEmail = this.setEmail.bind(this)
  }

  componentDidMount() {
    this.partnerNameStore.addChangeListener(this.onPartnerUpdate)

    SignUpActions.getPartnerName(this.partnerDiscountCode())
  }

  onPartnerUpdate() {
    this.setState({
      loadingPartner: false,
      partner: this.partnerNameStore.getState().data
    })
  }

  // Sets the controller state of the email so the confirmation page can display
  setEmail(email) {
    this.setState({email: email.toLowerCase().trim()})
  }

  partnerDiscountCode() {
    const rawCookie = getCookie('ep_ref_data')
    if (rawCookie)
      return JSON.parse(rawCookie).ep_ref
    else
      return null
  }

  render() {
    return (
      <div>
        <HeaderFlavor flavor='login' />
        <Loader loading={this.state.loadingPartner}>
          {
            routerUtils.childrenWithProps(
              this.props.children,
              {
                discountCode: this.partnerDiscountCode(),
                email: this.state.email ? this.state.email.toLowerCase().trim() : '',
                partner: this.state.partner,
                query: this.props.location.query, // Partner nudge emails pass `email` and `ref` (partner discount_code) as query string keys -- JA
                router: this.context.router,
                setEmail: this.setEmail
              }
            )
          }
        </Loader>
      </div>
    )
  }
}

SignUpController.contextTypes = {router: PropTypes.object}

SignUpController.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.object
  })
}
