import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {ControlButton} from '../../../../shared_components/ButtonTypes'
import {Text} from '../../../../dashboard/src/components/blocks/Texts'
import ShadowWrapper from '../shared_firm/ShadowWrapper'
import RemoveOrganization from './RemoveOrganization'

import {monthDayYear} from '../../../../lib/dateTools'

import './overview.scss'

const Overview = props => (
  <div className='overview'>
    <h4>Summary</h4>
    <ShadowWrapper>
      <div className='details flex-container'>
        <Text className='left-content'>
          <span>ID:</span>
          <span>Created:</span>
          <span>Last Updated:</span>
        </Text>
        <Text>
          <span>{props.organization.get('id')}</span>
          <span>{monthDayYear(props.organization.get('created-at'), 'L')}</span>
          <span>{monthDayYear(props.organization.get('updated-at'), 'L')}</span>
        </Text>
      </div>
      <div className='action-buttons flex-container'>
        <ControlButton buttonText='Download Advisor Activation Report' />
        <ControlButton buttonText='Download Client Usage Report' />
      </div>
    </ShadowWrapper>
    <RemoveOrganization {...props} />
  </div>
)

Overview.propTypes = {
  organization: PropTypes.instanceOf(Map)
}

export default Overview
