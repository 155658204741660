/* eslint-disable react/display-name */
import {useRef, useCallback} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import AdvisorWithStats from './AdvisorWithStats'
import * as Buttons from '../../../../../dashboard/src/components/blocks/Buttons'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import Form from '../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../shared_components/forms/v2/fields'

import {capitalize} from '../../../../../lib/tools'
import validators from '../../../../../shared_components/forms/v2/validators'

import './selectAdvisor.scss'

const SelectAdvisor = props => {
  const formRef = useRef()
  const professionalDesignation = props.firmConfig.get('professional-designation')
  const defaultAdvisorSeat = props.firmSeats.find(seat => seat.get('user-id').toString() === props.userConfig.get('id')) || Map()

  const onContinue = useCallback(() => {
    if (formRef.current) {
      const formData = formRef.current.onSubmit()

      if (formRef.current.isValid())
        props.onContinue(formData.getIn(['advisor-seat-id', 'value']))
    }
  }, [])

  const items = props.firmSeats.sortBy(seat => seat.get('name')).map(seat => {
    const advisorClientSeats = props.clientSeats.filter(
      clientSeat => clientSeat.get('advisor-id') === seat.get('user-id') && clientSeat.get('status') === capitalize(props.params.type)
    )

    return Map({
      disabled: advisorClientSeats.size === 0,
      label: <AdvisorWithStats key={seat.get('id')} params={props.params} clientCount={advisorClientSeats.size} advisorName={seat.get('name')} />,
      value: seat.get('id')
    })
  })

  return (
    <div className='select-advisor forms-playground'>
      {props.preHeaderText && <div className='preheader'>{props.preHeaderText}</div>}
      <h2 className='select-bulk-clients-header'>Select {professionalDesignation}</h2>
      <p>Please select which {professionalDesignation} you are sending invitations on behalf of.</p>
      <Form className='core' ref={formRef}>
        <Fields.VerticalRadioList
          data={
            Map({
              id: 'advisor-seat-id',
              items,
              name: 'advisor-seat-id',
              value: defaultAdvisorSeat.get('id')
            })
          }
          validator={validators.required}
        />
      </Form>
      <ButtonGroup>
        <Buttons.Accept onClick={onContinue}>Continue »</Buttons.Accept>
      </ButtonGroup>
    </div>
  )
}

SelectAdvisor.propTypes = {
  clientSeats: PropTypes.instanceOf(List),
  firmSeats: PropTypes.instanceOf(List),
  firmConfig: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map),
  onContinue: PropTypes.func,
  params: PropTypes.shape({type: PropTypes.string}),
  preHeaderText: PropTypes.string
}

export default SelectAdvisor
