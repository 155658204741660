/** @class RequestItemPermissionsStatus
 *
 * @desc
 * Displays the status of the requested item permissions.
 *
 */

import PropTypes from 'prop-types'

import './requestItemPermissionsStatus.scss'

const RequestItemPermissionsStatus = ({hasPendingItemPermissionsInvite}) => (
  <div className='request-item-permissions-status'>
    <strong className='bold'>Permission to edit:</strong>
    {
      hasPendingItemPermissionsInvite &&
      <div className='requested-permissions-text'>
        You've already requested permission to edit this Everplan.
      </div>
    }
  </div>
)


RequestItemPermissionsStatus.propTypes = {
  hasPendingItemPermissionsInvite: PropTypes.bool
}

export default RequestItemPermissionsStatus
