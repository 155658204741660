import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import './colorSwatch.scss'


const ColorSwatch = ({color}) => (
  <div className='color-swatch flex-child'>
    <div className='color-block' style={{backgroundColor: color.value}}>
      <p className={classnames('color-value', {'dark-text': color.useDarkText})}>{color.value}</p>
    </div>
    <div className='color-name'>{color.name}</div>
  </div>
)


ColorSwatch.propTypes = {
  color: PropTypes.shape({
    name: PropTypes.string.isRequired,
    useDarkText: PropTypes.bool,
    value: PropTypes.string.isRequired
  })
}

export default ColorSwatch
