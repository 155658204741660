import PropTypes from 'prop-types'
import {Component} from 'react'

import Forms from '../../../../../shared_components/forms'

import './emailAddressInput.scss'

class EmailAddressInput extends Component {
  render() {
    return (
      <div className='email-address-input'>
        <Forms.Input
          className='email-address'
          defaultValue={this.props.email}
          type='email'
          placeholder='Email Address'
          name={this.props.name}
        />
        {
          this.props.index > 0 &&
            <a className='remove-email' onClick={() => this.props.removeEmail(this.props.emailKey)}>Remove</a>
        }
      </div>

    )
  }
}

EmailAddressInput.propTypes = {
  email: PropTypes.string,
  emailKey: PropTypes.string,
  index: PropTypes.integer,
  name: PropTypes.string,
  removeEmail: PropTypes.func
}

export default EmailAddressInput
