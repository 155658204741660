import PropTypes from 'prop-types'

const RemoveClientInvitationWarning = ({status}) => {
  if (['Pending', 'Requesting Approval'].includes(status))
    return <li>Any invitations you have sent will no longer be valid.</li>
  else return null
}

RemoveClientInvitationWarning.propTypes = {
  status: PropTypes.string
}

export default RemoveClientInvitationWarning
