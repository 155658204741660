import PropTypes from 'prop-types'

import './simplifiesYourLifeItem.scss'

const SimplifiesYourLifeItem = props => {
  const {
    content: {
      image,
      text
    }
  } = props

  return (
    <li className='simplifies-your-life-item'>
      <img alt='' src={image} />
      <div className='item-text'>{text}</div>
    </li>
  )
}

SimplifiesYourLifeItem.propTypes = {
  content: PropTypes.shape({
    image: PropTypes.string,
    text: PropTypes.string
  })
}

export default SimplifiesYourLifeItem


