import classnames from 'classnames'

import './shadowWrapper.scss'

const ShadowWrapper = props => (
  <div className={classnames('shadow-wrapper', props.className)}>
    {props.children}
  </div>
)

export default ShadowWrapper
