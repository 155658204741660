import PropTypes from 'prop-types'
import React from 'react'

import Container from '../../../../lib/Container'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import OrionActions from '../../actions/OrionActions'
import routerUtils from '../../../../lib/routerUtils'
import storePrototype from '../../../../shared_components/StorePrototype'
import SessionApi from '../../web/SessionApi'
import SessionStore from '../../stores/SessionStore'
import sessionUtils from '../../lib/sessionUtils'

Container.registerStore('orion', storePrototype(OrionActions.Types.GOT_ORION_SSO))

export class OrionSSO extends React.Component {
  constructor(props) {
    super(props)

    this.store = Container.getStore('orion')
    this.onUpdate = this.onUpdate.bind(this)
    this.handleNextCurrentUserRoute = this.handleNextCurrentUserRoute.bind(this)
  }
  componentDidMount() {
    const token = this.props.params.token || this.props.location.query.token
    OrionActions.fetchOrionSso({token})
    this.store.addChangeListener(this.onUpdate)
    SessionStore.addChangeListener(this.handleNextCurrentUserRoute)
  }

  componentWillUnmount() { SessionStore.removeChangeListener(this.handleNextCurrentUserRoute) }

  onUpdate() {
    if (this.store.getState().errors.length > 0) {
      routerUtils.setLocation(`${API_HOST}/relay/orion/login/${this.props.params.token}`)
    } else {
      const {nextRoute} = this.props.location.query

      SessionApi._setToken(this.store.getState().data.session_token)
      SessionApi.fetchCurrentUser()

      if (nextRoute)
        routerUtils.push(nextRoute)
    }
  }

  handleNextCurrentUserRoute() {
    // don't attempt to send the user to next route if SSO failed
    if (!this.store.getState().errors.length)
      sessionUtils.sendUserToNextRoute(SessionStore.getState().currentUser)
  }

  render() {
    return (
      <div><LegacyLoader loading={true} /></div>
    )
  }
}

OrionSSO.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      nextRoute: PropTypes.string,
      token: PropTypes.string
    })
  }),
  params: PropTypes.shape({
    token: PropTypes.string
  })
}

export default OrionSSO
