import PropTypes from 'prop-types'

import LinkListItem from './LinkListItem'
import './everplansLinksList.scss'

const EverplansLinksList = ({ariaLabel, linksList, listTitle}) => (
  <nav aria-label={ariaLabel} className='everplans-links-list'>
    <div className='title'>{listTitle}</div>
    <ul
      aria-label={ariaLabel}
      role='menubar'>
      {linksList && linksList.map(({linkText, url}, index) => (
        <LinkListItem
          key={index}
          role='none'
          className='everplans-link'
          linkText ={linkText}
          url={url}
        />
      ))}
    </ul>
  </nav>
)

EverplansLinksList.propTypes = {
  ariaLabel: PropTypes.string,
  linksList: PropTypes.array,
  listTitle: PropTypes.string
}

export default EverplansLinksList
