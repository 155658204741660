import PropTypes from 'prop-types'

import {addPossessive} from '../../../../../lib/tools'

import './clientApprovalNote.scss'

const ClientApprovalNote = props => {
  if (props.needsClientApproval) {
    const activeClient = props.household.clients.find(client => client.status === 'Active')

    return (
      <div className='client-approval-note'>
        <strong>NOTE: </strong>
        <span>
          Since {addPossessive(activeClient.first_name)} Everplan is already active, {activeClient.pronouns[0]} must
          authorize the addition of new users. Everplans will contact {activeClient.first_name} for authorization,
          and once we receive it, we'll send {addPossessive(props.recipient.first_name)} invitation.
        </span>
      </div>
    )
  } else {
    return null
  }
}

ClientApprovalNote.propTypes = {
  needsClientApproval: PropTypes.bool,
  recipient: PropTypes.object,
  household: PropTypes.shape({
    clients: PropTypes.array
  })
}
export default ClientApprovalNote

