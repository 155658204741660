import PropTypes from 'prop-types'

import {Text} from '../../../../../dashboard/src/components/blocks/Texts'
import {BoxGutterSmall} from '../../../../../dashboard/src/components/blocks/Boxes'

import {clientStatLabel} from '../../../../../lib/tools'

import routerUtils from '../../../../../lib/routerUtils'

import './clientStat.scss'


const VALID_STATUSES = ['active', 'pending', 'draft', 'all']

const ClientStat = props => {
  const onClick = () => {
    if (VALID_STATUSES.includes(props.label))
      routerUtils.push(`pro/clients?status=${props.label}`)
    else
      routerUtils.push('pro/clients')
    props.scrollToClients()
  }

  return (
    <BoxGutterSmall className='client-stat'>
      <a className='clickable-container' onClick={onClick}>
        <Text className='count'>{props.value}</Text>
        <Text className='label'>{clientStatLabel(props.label)}</Text>
      </a>
    </BoxGutterSmall>
  )
}

ClientStat.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  scrollToClients: PropTypes.func
}

export default ClientStat
