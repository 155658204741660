import PropTypes from 'prop-types'
import React from 'react'

import UnorderedList from '../../../../shared_components/UnorderedList'

const whatDoesThisMeanDetails = (firstName, fullName) => [
  'Currently, your account is set up as an Individual Everplan, designed for one user.',
  `If you approve this request, we will convert your Individual Everplan to a Household Everplan, and add ${fullName} as a co-administrator (see below for details). Please note that ${firstName} must also accept the invitation to join you here.`,
  `If you deny this request, your Everplan will remain unchanged and ${firstName} will not be permitted to administer your account.`
]


const whatDoesThisMean = props => (
  <div>
    <div className='subheading'>
        What does this mean?
    </div>
    <UnorderedList children={whatDoesThisMeanDetails(props.firstName, props.fullName)} />
  </div>
)

whatDoesThisMean.defaultProps = {
  firstName: '',
  fullName: ''
}

whatDoesThisMean.propTypes = {
  firstName: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired
}

export default whatDoesThisMean
