import PropTypes from 'prop-types'

import TfaIntro from '../../../../auth/src/components/tfa/TfaIntro'

import './interstitialTfaIntro.scss'

const InterstitialTfaIntro = ({returnToController, ...otherProps}) => (
  <div className='interstitial-tfa-intro'>
    <TfaIntro {...otherProps} interstitial next={returnToController} />
  </div>
)

InterstitialTfaIntro.propTypes = {returnToController: PropTypes.func}

export default InterstitialTfaIntro
