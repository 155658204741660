import classnames from 'classnames'
import PropTypes from 'prop-types'
import {Component} from 'react'
import {Map, List} from 'immutable'
import {connect} from 'react-redux'
import {readEndpoint} from 'redux-json-api'

import Container from '../../../../../lib/Container'

import ClientActions from '../../../actions/ClientActions'
import ClientAnalytics from '../client_stats/ClientsAnalytics'
import ClientsActivities from '../client_activities/ClientActivities'
import DashWithAdvisors from './DashWithAdvisors'
import NoActiveAdvisors from './NoActiveAdvisors'
import SessionStore from '../../../../../auth/src/stores/SessionStore'

import ProResources from '../pro_resources/ProResources'

import LegacyLoader from '../../../../../shared_components/LegacyLoader'
import {currentUserConfig} from '../../../../../lib/userTools'

import routerUtils from '../../../../../lib/routerUtils'
import {cleanResponse} from '../../../../../dashboard/src/components/corp_max/redux/apiHelper'
import {elementItemDictionary} from '../../../../../lib/plan_data/item'
import FeatureFlags from '../../../../../lib/FeatureFlags'

import './clients.scss'

export class Clients extends Component {
  constructor() {
    super()
    this.state = {currentUser: SessionStore.getState().currentUser}
    this.onUpdate = this.onUpdate.bind(this)
    this.getItemElementMap = this.getItemElementMap.bind(this)
  }

  componentDidMount() {
    this.props.readEndpoint('settings')
    if (FeatureFlags.isEnabled('recent_activity'))
      this.props.readEndpoint(`client-activities/${this.props.userConfig.get('firm-id')}`)
  }

  onUpdate() {
    this.props.readEndpoint(`client-stats/${this.props.userConfig.get('firm-id')}`)
  }

  getItemElementMap() {
    const {items, kits, listMappings} = this.props
    return elementItemDictionary({items, kits, listMappings})
  }

  render() {
    const activityModuleIsEnabled = FeatureFlags.isEnabled('recent_activity')
    const hasClientActivities = this.props.clientActivities && this.props.clientActivities.get('activities', List()).size > 0
    const hasActiveAdvisors = this.props.clientStats.get('active-advisor-count') > 0
    const elementItemMap = hasClientActivities ? this.getItemElementMap() : Map()
    const showClientActivities = activityModuleIsEnabled && hasActiveAdvisors && (!this.props.clientActivities || hasClientActivities)

    return (
      <section className='clients-dashboard'>
        <LegacyLoader loading={!this.state.currentUser}>
          <ClientAnalytics />
          <div className={classnames('activity-and-resources', {'flex-container': showClientActivities})}>
            {hasActiveAdvisors && activityModuleIsEnabled && <ClientsActivities clientsActivities={this.props.clientActivities || Map()} elementItemMap={elementItemMap} />}
            <ProResources hasClientActivities={showClientActivities} />
          </div>
          {hasActiveAdvisors && <DashWithAdvisors stats={this.props.clientStats} />}
          {!this.props.clientStats.isEmpty() && !hasActiveAdvisors && this.state.currentUser && <NoActiveAdvisors firmId={this.state.currentUser.firm} />}
        </LegacyLoader>
        {routerUtils.childrenWithProps(this.props.children, {firmConfig: this.props.firmConfig, clientActivities: this.props.clientActivities || Map()})}
      </section>
    )
  }
}

Clients.defaultProps = {
  clients: {
    data: {
      clients: [],
      stats: {}
    }
  }
}

Clients.propTypes = {
  clientActivities: PropTypes.instanceOf(Map),
  clients: PropTypes.shape({
    data: PropTypes.object
  }),
  clientStats: PropTypes.instanceOf(Map),
  firm: PropTypes.instanceOf(Map),
  firmConfig: PropTypes.instanceOf(Map),
  firmNotification: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  items: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => {
  const api = state.api
  return ({
    clientStats: cleanResponse(api['client-stats']).first() || Map(),
    userConfig: currentUserConfig(api),
    clientActivities: cleanResponse(api['client-activities']).first(),
    kits: state.kits,
    items: state.items,
    listMappings: state.listMappings
  })
}

Container.registerAction('clients', ClientActions)
export default connect(mapStateToProps, {readEndpoint})(Clients)
