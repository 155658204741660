import PropTypes from 'prop-types'

import DisconnectIntegrationModal from './DisconnectIntegrationModal'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'

import {integrationLogo} from '../../../../lib/integrationTools'


const Integration = props => (
  <div className='integration-line-item'>
    <div className='integration-details'>
      <img
        alt={`${props.integration} logo`}
        className='integration-logo'
        id={props.integration}
        src={integrationLogo(props.integration)}
      />
      {
        props.integration === 'symmetry' &&
        <TextGutterMedium>
          <strong>Agent ID:</strong> {props.hasIntegrations.foreign_id}
        </TextGutterMedium>
      }
      {
        props.integration === 'northwestern mutual' &&
        <TextGutterMedium>
          <strong>Status:</strong> Connected
        </TextGutterMedium>
      }
    </div>
    <div>
      {props.integration !== 'symmetry' && <DisconnectIntegrationModal {...props} />}
    </div>
  </div>
)

Integration.propTypes = {
  integration: PropTypes.string,
  hasIntegrations: PropTypes.shape({
    foreign_id: PropTypes.string
  })
}

export default Integration
