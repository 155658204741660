import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {createResource, readEndpoint} from 'redux-json-api'

import {DropDown, DropDownMenu} from '../../../../shared_components/DropDown'
import DevBulkActionsDropdown from './DevBulkActionsDropdown'
import InviteDraftClients from './views/InviteDraftClients'
import ResendPendingInvites from './views/ResendPendingInvites'

import {currentUserConfig} from '../../../../lib/userTools'
import ProCsvReportDownload from '../pro_csv_report/ProCsvReportDownload'


const BulkActionsDropdown = props => (
  <DropDown className='bulk-menu-item' title='Bulk Actions'>
    <DropDownMenu>
      <ul className='actions-dropdown'>
        <InviteDraftClients hasDraftClients={props.hasDraftClients} />
        <ResendPendingInvites hasPendingClients={props.hasPendingClients} />
        <ProCsvReportDownload
          hasClients={props.hasClients}
          createResource={props.createResource}
        />
        <DevBulkActionsDropdown />
      </ul>
    </DropDownMenu>
  </DropDown>
)

const mapStateToProps = ({api}) => ({userConfig: currentUserConfig(api)})

export default connect(mapStateToProps, {createResource, readEndpoint})(
  BulkActionsDropdown
)

BulkActionsDropdown.propTypes = {
  createResource: PropTypes.func.isRequired,
  hasClients: PropTypes.bool.isRequired,
  hasDraftClients: PropTypes.bool.isRequired,
  hasPendingClients: PropTypes.bool.isRequired,
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}
