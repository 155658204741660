import {useEffect, useState} from 'react'

import Loader from '../../../../shared_components/NewLoader'

import SessionApi from '../../../../auth/src/web/SessionApi'
import SessionStore from '../../../../auth/src/stores/SessionStore'

const InterstitialUserContainer = props => {
  const [loading, setLoading] = useState(!SessionStore.getState().currentUser)

  const onUpdate = () => {
    if (SessionStore.getState().currentUser)
      setLoading(false)
  }

  useEffect(() => {
    const currentUser = SessionStore.getState().currentUser

    SessionStore.addChangeListener(onUpdate)

    // In Firefox and Safari, making this call while a URL transtion is going on seems to hang up on the request call and as such the current valid user
    // session is cleared. To mitigate this issue, only fetch when it's certain that there'll be no hopping around from `pending-requests` route
    // or if current user has not been fetched
    if (currentUser && currentUser.has_interstitials || !currentUser)
      SessionApi.fetchCurrentUser()

    return () => { SessionStore.removeChangeListener(onUpdate) }
  }, [])

  return (
    <Loader loading={loading}>
      {props.children}
    </Loader>
  )
}

export default InterstitialUserContainer
