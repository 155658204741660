import React from 'react'


export default class InfoModal extends React.Component {
  constructor(props) {
    super(props)
    // Weird fix for IE10 babel class inheritance problem
    this.setInitialState(props)
  }
  setInitialState() {
    this.state = {showModal: false}
    this.closeModal = this.closeModal.bind(this)
    this.showModal = this.showModal.bind(this)
  }
  closeModal() { this.setState({showModal: false}) }
  showModal() { this.setState({showModal: true}) }
}
