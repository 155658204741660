import {Component} from 'react'
import PropTypes from 'prop-types'

import {ControlButton, Cancel, Continue} from '../../../../../../shared_components/ButtonTypes'
import Modal from '../../../../../../shared_components/Modal'
import modalWrapper, {MODAL_CONTEXT_TYPES} from '../../../../../../shared_components/ModalWrapper'
import ShadowHeader from '../../ShadowHeader'
import StatusToggle from '../StatusToggle'

import FirmActions from '../../../../actions/FirmActions'

export class EditAdvisorAutoPopulationSettings extends Component {
  constructor(props) {
    super(props)

    this.state = {advisorAutoPopulationEnabled: props.advisorAutoPopulationEnabled}

    this.cancel = this.cancel.bind(this)
    this.toggleStatus = this.toggleStatus.bind(this)
    this.saveAdvisorAutoPopulation = this.saveAdvisorAutoPopulation.bind(this)
  }

  toggleStatus() { this.setState({advisorAutoPopulationEnabled: !this.state.advisorAutoPopulationEnabled}) }

  saveAdvisorAutoPopulation() {
    FirmActions.updateFirmConfig({
      firmId: this.props.firmId,
      data: {firm_config: {advisor_auto_population_enabled: this.state.advisorAutoPopulationEnabled}}
    })

    this.context.closeModal()
  }

  cancel() {
    this.setState({advisorAutoPopulationEnabled: this.props.advisorAutoPopulationEnabled})
    this.context.closeModal()
  }

  render() {
    if (this.props.isFirmActive) {
      return (
        <div className='edit-config edit-advisor-auto-population'>
          <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
          <Modal visible={this.props.showModal}>
            <ShadowHeader headerText='Auto Populate Advisor Info' />
            <div className='config-wrapper'>
              <StatusToggle
                description={{
                  disabled: "Firm member's contact information does not get automatically added to clients' Everplan when clients are added.",
                  enabled: "Primary firm member's contact information is automatically added to clients' Everplan when clients are added."
                }}
                enabledChecked={this.state.advisorAutoPopulationEnabled}
                name='advisor-auto-population-enabled'
                statusText={{disabled: 'Disabled', enabled: 'Enabled'}}
                onChange={this.toggleStatus}
              />
              <div className='button-group'>
                <Cancel onClick={this.cancel} />
                <Continue loading={this.props.processing} onClick={this.saveAdvisorAutoPopulation}>Save</Continue>
              </div>
            </div>
          </Modal>
        </div>
      )
    } else {
      return null
    }
  }
}

EditAdvisorAutoPopulationSettings.contextTypes = MODAL_CONTEXT_TYPES

EditAdvisorAutoPopulationSettings.defaultProps = {
  showModal: false
}

EditAdvisorAutoPopulationSettings.propTypes = {
  advisorAutoPopulationEnabled: PropTypes.bool,
  firmId: PropTypes.string.isRequired,
  isAccountManager: PropTypes.bool,
  isFirmActive: PropTypes.bool,
  processing: PropTypes.bool,
  showModal: PropTypes.bool
}

export default modalWrapper(EditAdvisorAutoPopulationSettings)
