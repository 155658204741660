import PropTypes from 'prop-types'

import {newSubscriptionMessaging} from '../../../../lib/tools'

import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import CouponOnlyActivation from '../payment_page/CouponOnlyActivation'
import Loader from '../../../../shared_components/NewLoader'
import PartnerActions from '../../actions/PartnerActions'
import PaymentPage from '../payment_page/PaymentPage'

import './interstitialNewSubscription.scss'

Container.registerAction('partner', PartnerActions)

const InterstitialNewSubscription = props => {
  const partner = props.partner && props.partner.data

  if (props.loadingPartner)
    return <Loader loading={props.loadingPartner} />

  return (
    <div className='interstitial-new-subscription'>
      {
        partner.coupon_only_activation ?
          <CouponOnlyActivation className='core box content-box' partner={partner} nextPage={props.returnToController} /> :
          <PaymentPage
            className='core box content-box'
            interstitials
            messaging={newSubscriptionMessaging(partner)}
            nextPage={props.returnToController}
            partner={partner}
          />
      }
    </div>
  )
}

InterstitialNewSubscription.propTypes = {
  loadingPartner: PropTypes.bool,
  partner: PropTypes.shape({data: PropTypes.object}),
  returnToController: PropTypes.func
}

export default bindResources(
  InterstitialNewSubscription,
  [{name: 'partner', type: 'itemNoId'}]
)
