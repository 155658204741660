import {connect} from 'react-redux'
import React from 'react'
import {setAxiosConfig} from 'redux-json-api'
import PropTypes from 'prop-types'

import {updateAxiosConfiguration} from '../lib/axiosUtils'
import extra_storage from '../lib/extra_storage'
import SessionStore from '../auth/src/stores/SessionStore'
import {extractAuthToken} from '../lib/tools'

/**
 * This component is only used to wrap routes that have child components that need to
 * make APIv2 calls using redux-json-api. The axiosConfiguration is initialized in epRouter.js
 * but requires an update if an authToken is invalid or null during app boot.
 */
class AxiosConnectedComponent extends React.Component {
  constructor() {
    super()

    this.onUpdate = this.onUpdate.bind(this)
  }

  UNSAFE_componentWillMount() {
    SessionStore.addChangeListener(this.onUpdate)
    updateAxiosConfiguration(
      {
        setAxiosConfiguration: this.props.setAxiosConfig,
        authToken: extra_storage.getItem('authToken')
      }
    )
  }

  componentWillUnmount() { SessionStore.removeChangeListener(this.onUpdate) }

  /**
   * This ensures that the authorization token used by +redux-json-api+ is up to date when a user logs in with their TFA backup code
   * So that requests for v2 resources can be processed with the current valid token
   */
  onUpdate() {
    const session = SessionStore.getState()

    if (session.signedInWithBackup && this.props.authToken !== session.currentUser.session_token) {
      updateAxiosConfiguration(
        {
          setAxiosConfiguration: this.props.setAxiosConfig,
          authToken: extra_storage.getItem('authToken')
        }
      )
    }
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

AxiosConnectedComponent.propTypes = {
  authToken: PropTypes.string,
  setAxiosConfig: PropTypes.func
}

const mapStateToProps = ({api}) => ({authToken: extractAuthToken(api.endpoint.axiosConfig.headers.Authorization)})

export default connect(mapStateToProps, {setAxiosConfig})(AxiosConnectedComponent)
