import PropTypes from 'prop-types'

import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import HowItWorks from './HowItWorks'


const ClientInviteAccepted = ({clientInvite, returnToController}) => (
  <div className='central-content-box'>
    <div className='client-invite-accepted'>
      <h1>Your account is now connected.</h1>
      <section>
        <HowItWorks advisorFullName={clientInvite.advisor.full_name} hasAccepted={true} clientInvite={clientInvite} />
        <Accept onClick={returnToController}>Continue</Accept>
      </section>
    </div>
  </div>
)

ClientInviteAccepted.defaultProps = {
  clientInvite: {
    advisor: {
      full_name: ''
    }
  }
}

ClientInviteAccepted.propTypes = {
  clientInvite: PropTypes.shape({
    advisor: PropTypes.shape({
      full_name: PropTypes.string.isRequired
    })
  }),
  returnToController: PropTypes.func
}

export default ClientInviteAccepted
