import PropTypes from 'prop-types'

import * as Links from '../../../../shared_components/Links'

import './footer.scss'


const Footer = props => {
  if (window.location.hash.match(props.whitelist))
    return <div />

  return (
    <footer>
      <div className='pro-footer-wrapper'>
        <nav>
          <ul className='footer-menu'>
            <li className='footer-menu-item'><Links.ProHelp target='_blank' /></li>
            <li className='footer-menu-item'><Links.ContactUs target='_blank' /></li>
            <li className='footer-menu-item'><Links.Terms target='_blank' /></li>
            <li className='footer-menu-item'><Links.Privacy target='_blank' /></li>
          </ul>
        </nav>
        <div>
          <ul>
            <li><hr /></li>
            <li><span className='branding'>Everplans Professional</span></li>
          </ul>
        </div>
        <div className='copyright'><p>&copy; {new Date().getFullYear()} Everplans</p></div>
      </div>
    </footer>
  )
}

Footer.propTypes = {whitelist: PropTypes.string}

export default Footer
