import {connect} from 'react-redux'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import {Text} from '../../../../../dashboard/src/components/blocks/Texts'
import LoadingClientActivities from './LoadingClientActivities'

import {currentUserConfig} from '../../../../../lib/userTools'
import {transformActivities} from '../../../../../lib/clientActivityTools'

import './clientActivities.scss'
import ClientActivity from './ClientActivity'


export const ClientsActivities = props => {
  const {clientsActivities, elementItemMap, items} = props

  const activities = clientsActivities.get('activities', List())
  const transformedActivities = transformActivities({activities, elementItemMap, items})

  if (clientsActivities.isEmpty() || (activities.size > 0 && elementItemMap.isEmpty()))
    return <LoadingClientActivities />

  return (
    <div className='clients-activities'>
      {activities.size > 0 && (
        <div className='content'>
          <Text className='heading flex-container'>
            <h1>Recent Activities</h1>
          </Text>
          <div className='activities flex-container'>
            {transformedActivities.map(activity => (
              <ClientActivity key={activity.get('id')} activity={activity} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

ClientsActivities.propTypes = {
  clientsActivities: PropTypes.instanceOf(Map),
  elementItemMap: PropTypes.instanceOf(Map),
  items: PropTypes.instanceOf(List),
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api, items}) => ({
  userConfig: currentUserConfig(api),
  items
})

export default connect(mapStateToProps, {readEndpoint})(ClientsActivities)
