import aptibleBadge from '../shared_assets/aptible-badge.svg'

import './secureAndAccredited.scss'

const SecureAndAccredited = () => (
  <div className='secure-and-accredited'>
    <p>SECURE AND ACCREDITED</p>
    <div className='image-container'>
      <a href='https://www.aptible.com/' target='_blank'>
        <img src={aptibleBadge} className='aptible-img' />
      </a>
      <a href='https://www.mcafeesecure.com/verify?host=www.everplans.com' target='_blank'>
        <img src='https://cdn.ywxi.net/meter/www.everplans.com/101.gif' className='mcafee-img' />
      </a>
      <a href='http://www.bbb.org/new-york-city/business-reviews/online-publications/everplans-in-new-york-ny-135741/#bbbonlineclick' target='_blank'>
        <img src='https://seal-newyork.bbb.org/seals/blue-seal-120-61-beyondly-135741.png' className='bbb-img' />
      </a>
    </div>
  </div>
)

export default SecureAndAccredited
