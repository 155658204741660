import {Stack} from '@mui/material'

import * as Buttons from '../../../dashboard/src/components/blocks/Buttons'
import Button from '../../../shared_components/mui_base_components/button/Button'
import CodeBlock from '../CodeBlock'
import CodeExamples from '../CodeExamples'
import CollapsibleSection from './CollapsibleSection'
import {BoxGutterMedium} from '../../../dashboard/src/components/blocks/Boxes'

import {noop} from '../../../lib/tools'

import './buttonsSection.scss'
import LinkButton from '../../../shared_components/mui_base_components/link_button/LinkButton'

const ButtonsSection = () => (
  <CollapsibleSection className='buttons-section' heading='Buttons'>
    <BoxGutterMedium>
      <aside>
        <h3>Button (MUI)</h3>
        <Stack
          direction={{mobileSm: 'column', tabletMd: 'row'}}
          gap={2.5}
          sx={{
            alignItems: 'center',
            marginBottom: '20px'
          }}
          useFlexGap>
          <Button onClick={noop}>Primary</Button>
          <Button className='primary-button hover' onClick={noop}>Hover</Button>
          <Button
            className='primary-button active'
            onClick={noop}>
            Active
          </Button>
          <Button disabled={true} onClick={noop}>Disabled</Button>
          <Button onClick={noop} loading={true}>Primary</Button>
        </Stack>
        <Stack
          direction={{mobileSm: 'column', tabletMd: 'row'}}
          gap={2.5}
          sx={{
            alignItems: 'center',
            marginBottom: '20px'
          }}
          useFlexGap>
          <Button color='cancel' onClick={noop}>Cancel</Button>
          <Button className='cancel-button hover' color='cancel' onClick={noop}>Hover</Button>
          <Button
            className='cancel-button active'
            color='cancel'
            onClick={noop}>
            Active
          </Button>
          <Button color='cancel' disabled={true} onClick={noop}>Disabled</Button>
        </Stack>
        <Stack
          direction={{mobileSm: 'column', tabletMd: 'row'}}
          gap={2.5}
          sx={{
            alignItems: 'center',
            marginBottom: '20px'
          }}
          useFlexGap>
          <Button color='error' onClick={noop}>Exit</Button>
          <Button className='exit-button hover' color='error' onClick={noop}>Hover</Button>
          <Button
            className='exit-button active'
            color='error'
            onClick={noop}>
            Active
          </Button>
          <Button color='error' disabled={true} onClick={noop}>Disabled</Button>
          <Button
            color='error'
            loading={true}
            onClick={noop}>
            Exit
          </Button>
        </Stack>
        <CodeBlock>{CodeExamples.Buttons.MUI}</CodeBlock>
      </aside>
      <aside>
        <h3>LinkButton (MUI)</h3>
        <Stack
          direction='row'
          gap={2.5}
          sx={{
            marginBottom: '20px'
          }}>
          <LinkButton onClick={noop}>Link</LinkButton>
          <LinkButton disabled={true} onClick={noop}>Disabled</LinkButton>
        </Stack>
        <CodeBlock>{CodeExamples.Buttons.LinkButton}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: Base</h3>
        <Buttons.Button className='1337-button' onClick={noop}>Button!</Buttons.Button>
        <Buttons.Button className='1337-button' onClick={noop} processing={true}>I'm a really long Button!</Buttons.Button>
        <CodeBlock>{CodeExamples.Buttons.Button}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: Accept</h3>
        <Buttons.Accept className='1337-button' onClick={noop}>Button!</Buttons.Accept>
        <Buttons.Accept className='1337-button' onClick={noop} processing={true}>Button!</Buttons.Accept>
        <Buttons.Accept className='1337-button' onClick={noop} />
        <Buttons.Accept className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.Accept>
        <Buttons.Accept className='1337-button' onClick={noop} disabled>Disabled</Buttons.Accept>
        <CodeBlock>{CodeExamples.Buttons.Accept}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: Add</h3>
        <Buttons.Add className='1337-button' onClick={noop}>Button!</Buttons.Add>
        <Buttons.Add className='1337-button' onClick={noop} />
        <Buttons.Add className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.Add>
        <CodeBlock>{CodeExamples.Buttons.Add}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: Cancel</h3>
        <Buttons.Cancel className='1337-button' onClick={noop}>Button!</Buttons.Cancel>
        <Buttons.Cancel className='1337-button' onClick={noop} processing={true}>Button!</Buttons.Cancel>
        <Buttons.Cancel className='1337-button' onClick={noop} />
        <Buttons.Cancel className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.Cancel>
        <CodeBlock>{CodeExamples.Buttons.Cancel}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: Remove</h3>
        <Buttons.Remove className='1337-button' onClick={noop}>Button!</Buttons.Remove>
        <Buttons.Remove className='1337-button' onClick={noop} processing={true}>Button!</Buttons.Remove>
        <Buttons.Remove className='1337-button' onClick={noop} />
        <Buttons.Remove className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.Remove>
        <CodeBlock>{CodeExamples.Buttons.Remove}</CodeBlock>
      </aside>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default ButtonsSection
