import PropTypes from 'prop-types'
import React from 'react'


export default class AddManyErrors extends React.Component {
  render() {
    if (this.props.errors.length > 1 || this.props.errors[0].row) {
      return (
        <div className='errors add-many-errors'>
          <p>We were unable to process your upload. Each entry must include a first name, last name, a valid email address, and no additional information. Please address the issues in your file and upload the .csv again. </p>
          <p className='error-header'>The following row(s) had errors:</p>
          <ul>
            {this.props.errors.map(error => <li key={error.row}>{error.row.replace(/,/g, ' ')}</li>)}
          </ul>
        </div>
      )
    }

    return (
      <div className='errors add-many-errors'>
        <h4>We were unable to process your upload.  Please review the following error:</h4>
        <p>{this.props.errors[0]}</p>
      </div>
    )
  }
}

AddManyErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      error_code: PropTypes.number,
      row: PropTypes.string
    })
  )
}
