import { onBlur, onFocus } from './lib/tools'


const LifeInsuranceForm = () => (
  <section>
    <h2>Life Insurance</h2>
    <form className='core'>
      <fieldset>
        <div className='fieldset-flexbox'>
          <div className='controls-flexbox flex-container'>
            <label className='flex-child'>
              <span className='label-text'>Insurance company</span>
              <input name='input-text-1' type='text' onBlur={onBlur} onFocus={onFocus} />
            </label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className='fieldset-flexbox controls-group'>
          <legend>Insurance company type</legend>
          <div className='controls-flexbox flex-container radio-form-group'>
            <label className='flex-child'>
              <input name='radio-select-1' type='radio' value='radio-1' onBlur={onBlur} onFocus={onFocus} />
              <span className='label-text'>Term</span>
            </label>
            <label className='flex-child'>
              <input name='radio-select-1' type='radio' value='radio-2' onBlur={onBlur} onFocus={onFocus} />
              <span className='label-text'>Permanent: Whole</span>
            </label>
            <label className='flex-child'>
              <input name='radio-select-1' type='radio' value='radio-3' onBlur={onBlur} onFocus={onFocus} />
              <span className='label-text'>Permanent: Universal</span>
            </label>
            <label className='flex-child'>
              <input name='radio-select-1' type='radio' value='radio-3' onBlur={onBlur} onFocus={onFocus} />
              <span className='label-text'>Permanent: Second to die</span>
            </label>
            <label className='flex-child'>
              <input name='radio-select-1' type='radio' value='radio-3' onBlur={onBlur} onFocus={onFocus} />
              <span className='label-text'>Other</span>
            </label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className='fieldset-flexbox'>
          <div className='controls-flexbox flex-container'>
            <label className='flex-child'>
              <span className='label-text'>Policy number</span>
              <input name='input-text-1' type='text' onBlur={onBlur} onFocus={onFocus} />
            </label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className='fieldset-flexbox controls-group'>
          <legend>Policy start date</legend>
          <div className='controls-flexbox flex-container date-form-group'>
            <label className='flex-child placeholder' data-placeholder='mm / dd / yyyy'>
              <span className='label-text visually-hidden'>Policy start date</span>
              <input
                maxLength='14'
                name='policy-start-date'
                onBlur={onBlur}
                onFocus={onFocus}
                type='text'
              />
            </label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className='fieldset-flexbox controls-group'>
          <legend>Policy expiration date</legend>
          <div className='controls-flexbox flex-container date-form-group'>
            <label className='flex-child placeholder' data-placeholder='mm / dd / yyyy'>
              <span className='label-text visually-hidden'>Policy expiration date</span>
              <input
                maxLength='14'
                name='policy-expiration-date'
                onBlur={onBlur}
                onFocus={onFocus}
                type='text'
              />
            </label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className='fieldset-flexbox'>
          <div className='controls-flexbox flex-container'>
            <label className='flex-child'>
              <span className='label-text'>Value of death benefit</span>
              <input name='input-text-1' type='text' onBlur={onBlur} onFocus={onFocus} />
            </label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className='fieldset-flexbox controls-group'>
          <legend>Is your policy a long-term (LTC) or Chronic Illness Rider?</legend>
          <div className='controls-flexbox flex-container radio-form-group'>
            <label className='flex-child'>
              <input name='policy' type='radio' value='yes' />
              <span className='label-text'>Yes</span>
            </label>
            <label className='flex-child'>
              <input name='policy' type='radio' value='no' />
              <span className='label-text'>No</span>
            </label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className='fieldset-flexbox'>
          <div className='controls-flexbox flex-container'>
            <label>
              <span className='label-text'>Location of original</span>
              <input name='input-text-1' type='text' onBlur={onBlur} onFocus={onFocus} />
            </label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className='fieldset-flexbox controls-group'>
          <legend>Notes</legend>
          <div className='controls-flexbox flex-container'>
            <label className='flex-child'>
              <span className='label-text visually-hidden'>Notes</span>
              <textarea name='notes-textarea' onBlur={onBlur} onFocus={onFocus} />
            </label>
          </div>
        </div>
      </fieldset>
    </form>
  </section>
)

export default LifeInsuranceForm
