import {useState, useEffect} from 'react'

import preAuthHook from '../../lib/preAuthHook'
import routerUtils from '../../../../lib/routerUtils'
import SignInActions from '../../actions/SignInActions'
import SessionStore from '../../stores/SessionStore'

import BackupCode from './alternatives/BackupCode'
import Button from '../../../../shared_components/Button'
import * as Links from '../../../../shared_components/Links'
import PrimaryNumber from './alternatives/PrimaryNumber'
import SecondaryNumber from './alternatives/SecondaryNumber'

import './signIn.scss'

const SignInAlternatives = () => {
  const [loading, setLoading] = useState(false)
  const [altSignIn, setAltSignIn] = useState('')

  const sessionState = SessionStore.getState()

  useEffect(() => {
    preAuthHook()
  }, [])

  const cancel = () => {
    if (!routerUtils.goBack())
      routerUtils.push('auth/sign_in/tfa_primary')
  }

  const updateRoute = event => {
    setAltSignIn(event.target.value)
  }

  const handleSubmit = () => {
    const email = SessionStore.getState().preAuthEmail
    const password = SessionStore.getState().preAuthPassword

    switch (altSignIn) {
      case 'tfa_primary':
        setLoading(true)
        SignInActions.signIn(email, password)
        break
      case 'tfa_secondary':
        setLoading(true)
        SignInActions.signInSecondary(email, password)
        break
      case 'tfa_backup':
        routerUtils.push('auth/sign_in/tfa_backup')
        break
      default:
        break
    }
  }

  return (
    <div className='sign-in-alternatives'>
      <div className='login-div'>
        <div className='login-div-align'>
          <h1> Didn't get the code&#63; </h1>
          <div className='options-list'>
            {sessionState.primaryPhone && <PrimaryNumber sessionState={sessionState} updateRoute={updateRoute} />}
            {sessionState.secondaryPhone && <SecondaryNumber sessionState={sessionState} updateRoute={updateRoute} />}
            <BackupCode updateRoute={updateRoute} />
          </div>
          <div className='buttons-container'>
            <Button className='tfa-alt-sign-in-btn-primary' onClick={handleSubmit} loading={loading}>Submit</Button>
            <Button className='tfa-alt-sign-in-btn-secondary' onClick={cancel}>Cancel</Button>
          </div>
          <hr />
          <Links.Help>I'm locked out of my account</Links.Help>
        </div>
      </div>
      <div className='contained'>
        <div className='support-links'>
          Need Support&#63;<Links.Help>Everplans Help Center</Links.Help>
        </div>
      </div>
    </div>
  )
}

export default SignInAlternatives
