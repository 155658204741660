import {useEffect, Fragment, useCallback} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import ClientStat from './ClientStat'
import {Text} from '../../../../../dashboard/src/components/blocks/Texts'
import Loader from '../../../../../shared_components/NewLoader'

import {cleanResponse} from '../../../../../dashboard/src/components/corp_max/redux/apiHelper'
import {currentUserConfig} from '../../../../../lib/userTools'

import './clientAnalytics.scss'
import {scrollComponentToView} from '../../../../../lib/scrollTools'

// Add new attributes that needs to be displayed here as needed
const CLIENT_ANALYTICS_FIELDS = ['active', 'pending', 'draft']

export const ClientsAnalytics = props => {
  const {clientStats} = props

  useEffect(() => {
    if (clientStats.isEmpty())
      props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)
  }, [clientStats.size, props.userConfig.size])

  const clientTotal = clientStat => clientStat.reduce((sum, value, key) => {
    if (CLIENT_ANALYTICS_FIELDS.includes(key))
      return sum + value
    else
      return sum
  }, 0)

  const scrollToClients = useCallback(() => scrollComponentToView('dash-with-advisors'))

  return (
    <div className='clients-analytics'>
      <Loader loading={props.clientStats.isEmpty()}>
        <div className='content'>
          <Text className='heading flex-container'>
            <h1>Client Summary</h1>
            <a onClick={scrollToClients}>All Clients »</a>
          </Text>
          <div className='stats flex-container'>
            {clientStats.size > 0 && (
              <Fragment>
                <ClientStat
                  value={clientTotal(clientStats)}
                  scrollToClients={scrollToClients}
                  label='all'
                />
                {clientStats
                  .filter((_val, key) => CLIENT_ANALYTICS_FIELDS.includes(key))
                  .keySeq()
                  .map(key => (
                    <ClientStat
                      key={key}
                      label={key}
                      value={clientStats.get(key)}
                      scrollToClients={scrollToClients}
                    />
                  ))}
              </Fragment>
            )}
          </div>
        </div>
      </Loader>
    </div>
  )
}

ClientsAnalytics.propTypes = {
  clientStats: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => ({
  clientStats: cleanResponse(api['client-stats']).first() || Map(),
  userConfig: currentUserConfig(api)
})

export default connect(mapStateToProps, {readEndpoint})(ClientsAnalytics)
