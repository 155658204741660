import {List, fromJS} from 'immutable'
import {useState} from 'react'
import PropTypes from 'prop-types'

import {railsUrl} from '../../../../../lib/urlTools'
import AddManyErrors from './AddManyErrors'

import routerUtils from '../../../../../lib/routerUtils'
import buttonLoader from '../../../../assets/images/button_loader.gif'
import {getBase64} from '../../../../../lib/fileUploadTools'
import './addBulkClients.scss'


const FILE_ERROR = ['File size cannot exceed 10MB. Please check your file and try again.']
const MAX_FILE_SIZE = 10000000
const csvFormattingHelpDeskUrl =
  'https://prohelp.everplans.com/customer/portal/articles/2030336-how-should-my-spreadsheet-be-formatted-'


const AddBulkClients = props => {
  const [errors, setErrors] = useState(List())
  const [processing, setProcessing] = useState(false)

  const submitFile = ({result, error}) => {
    if (error) {
      setErrors(List(['File could not be uploaded']))
    } else {
      const payload = {
        type: 'bulk-client-validations',
        attributes: {'base64-data': result}
      }

      props
        .createResource(payload)
        .then(response => {
          const attributes = response.data.attributes

          if (attributes.errors.length) {
            setProcessing(false)
            setErrors(fromJS(attributes.errors))
          } else {
            routerUtils.push(
              `pro/clients/confirm-add-many/${attributes['batch-token']}`
            )
          }
        })
        .catch(err => {
          setErrors(fromJS(err.response.data.errors.map(responseError => responseError.title)))
          setProcessing(false)
        })
    }
  }

  const uploadCsv = event => {
    event.preventDefault()
    const file = event.target.files[0]
    setErrors(List())
    setProcessing(true)

    if (file && file.size > MAX_FILE_SIZE) {
      setErrors(List(FILE_ERROR))
    } else if (file) {
      setProcessing(true)
      getBase64({file, callback: submitFile})
    }
  }

  return (
    <div>
      <section className='add-bulk-clients'>
        <form>
          {!errors.isEmpty() && <AddManyErrors errors={errors.toJS()} />}
          <p>
            Add multiple clients by uploading a CSV file containing first names,
            last names, and email addresses. You should be able to output this
            from most CRM and customer database tools.
          </p>
          <p>
            Please read our{' '}
            <a target='_blank' href={csvFormattingHelpDeskUrl} rel='noreferrer'>
              help center article
            </a>{' '}
            or download this{' '}
            <a href={railsUrl('csv_template')}>CSV template file</a> to see the
            exact format you should use for your CSV file.
          </p>
          <div className='spinner-holder'>
            {processing ?
              (
                <div key='spinner' className='spinner'>
                  <img src={buttonLoader} />
                </div>
              ) :
              (
                <label className='btn btn-primary upload-btn'>
                  <input className='hidden' onChange={uploadCsv} type='file' />
                Upload .csv file
                </label>
              )}
          </div>
        </form>
      </section>
    </div>
  )
}

AddBulkClients.propTypes = {
  createResource: PropTypes.func
}

export default AddBulkClients
