import {useState, useCallback, useEffect, Fragment} from 'react'

import Header from '../header/Header'
import TimeoutModal from '../TimeoutModal'
import FreeEverplanAnnouncement from './FreeEverplanAnnouncement'
import ProfileCompletion from './ProfileCompletion'

import MixpanelLogger from '../../lib/MixpanelLogger'
import SessionStore from '../../auth/src/stores/SessionStore'
import routerUtils from '../../lib/routerUtils'

import './newEverplanController.scss'

export const NewEverplanController = props => {
  const [step, setStep] = useState(0)

  const onUpdate = () => {
    const user = SessionStore.getState().currentUser

    if (user) {
      if (user.has_interstitials)
        routerUtils.push('/pending-requests')
      else
        routerUtils.push('/cm/dashboard')
    }
  }

  useEffect(() => {
    MixpanelLogger.track('view_deputy_get_started')
    SessionStore.addChangeListener(onUpdate)

    return () => { SessionStore.removeChangeListener(onUpdate) }
  }, [])

  const nextStep = useCallback(() => {
    MixpanelLogger.track('click_deputy_get_started_continue')
    setStep(step + 1)
  }, [step])

  return (
    <Fragment>
      <TimeoutModal needsCheck />
      <div className='new-everplan-controller'>
        <Header flavor='transactional' />
        {step === 0 && <FreeEverplanAnnouncement nextStep={nextStep} {...props} />}
        {step === 1 && <ProfileCompletion {...props} />}
      </div>
    </Fragment>
  )
}

export default NewEverplanController
