import PropTypes from 'prop-types'

import defaultAdvisorAvatar from '../../../../../shared_assets/default-advisor-avatar.svg'

import './advisorContactCard.scss'

const AdvisorContactCard = ({advisor}) => {
  const {
    accreditation,
    firm_name: firmName,
    email: emailAddress,
    head_shot: imageUrl,
    name,
    phone: telephoneNumber,
    title,
    url
  } = advisor

  return (
    <div className='new-advisor-contact-card'>
      <div className='info-container'>
        {name && <div className='name'>{name}</div>}
        {accreditation && <div className='accreditation'>{accreditation}</div>}
        {title && <div className='job-title'>{title}</div>}
        {firmName && <div className='firm-name'>{firmName}</div>}
        <hr />
        {emailAddress && <div className='email-address'>{emailAddress}</div>}
        {telephoneNumber && <div className='telephone-number'>{telephoneNumber}</div>}
        {url && <a className='website' href={url} rel='noreferrer noopener' target='_blank'>Visit my website</a>}
      </div>
      <img alt='' src={imageUrl ? imageUrl : defaultAdvisorAvatar} />
    </div>
  )
}

AdvisorContactCard.propTypes = {
  advisor: PropTypes.shape({
    accreditation: PropTypes.string,
    email: PropTypes.string,
    firm_name: PropTypes.string,
    head_shot: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string
  })
}

export default AdvisorContactCard


