import {Component} from 'react'
import PropTypes from 'prop-types'

import Tooltip from './Tooltip'
import './hoverTooltip.scss'

export default class HoverTooltip extends Component {
  constructor() {
    super()
    this.state = {
      hover: false
    }

    this.toggleMouseOver = this.toggleMouseOver.bind(this)
  }

  toggleMouseOver() {
    this.setState({hover: !this.state.hover})
  }

  render() {
    return (
      <div
        className='hover-tooltip'
        onMouseEnter={this.toggleMouseOver}
        onMouseLeave={this.toggleMouseOver}>
        <div className='tooltip-launcher'>{this.props.launcher}</div>
        {this.state.hover && <Tooltip {...this.props} />}
      </div>
    )
  }
}

HoverTooltip.propTypes = {
  launcher: PropTypes.node
}
