import PropTypes from 'prop-types'
import React from 'react'

import {Accept, Cancel} from '../../../../dashboard/src/components/blocks/Buttons'
import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import PrivacyNotice from '../client_invite_interstitial/PrivacyNotice'
import WhatDoesThisMean from './WhatDoesThisMean'

import AddHouseholdMemberInterstitialActions from '../../actions/AddHouseholdMemberInterstitialActions'
import hollowMan from '../../assets/headshot-placeholder-circle.svg'
import {noop} from '../../../../lib/tools'

import './addHouseholdMemberInterstitial.scss'


export default class AddHouseholdMemberInterstitial extends React.Component {
  constructor(props) {
    super(props)

    this.accept = this.accept.bind(this)
    this.reject = this.reject.bind(this)
    this.respondToRequest = this.respondToRequest.bind(this)
  }
  accept() { this.respondToRequest('accept') }
  reject() { this.respondToRequest('decline') }
  respondToRequest(response) {
    this.props.updating(response)
    AddHouseholdMemberInterstitialActions.respondToAddRequest(
      {add_household_member_request: {approve_request: response === 'accept'}},
      this.props.addHouseholdMemberInviteId
    )
    this.props.finished()
  }

  render() {
    const coAdministrator = this.props.pendingCoAdministrator
    return (
      <div className='central-content-box add-household-member-interstitial'>
        <section className='invite-overview'>
          <h1>Permission to Add a Co-Administrator</h1>
          <p className='subheading'>
            {this.props.advisorFullName} would like to add the following person as a co-administrator of your Everplan:
          </p>
          <div className='co-administrator-box'>
            <img alt='cameo-style-line-drawing-of-a-silhouette-of-a-person' src={hollowMan} />
            <div className='column-wrapper co-administrator-info'>
              <div>{coAdministrator.full_name}</div>
              <div>{coAdministrator.email}</div>
            </div>
          </div>
          <ButtonGroup>
            <Accept onClick={this.accept} processing={this.props.processingAccept}>Approve request</Accept>
            <Cancel onClick={this.reject} processing={this.props.processingDecline}>Deny request</Cancel>
          </ButtonGroup>
        </section>
        <section>
          <WhatDoesThisMean firstName={coAdministrator.first_name} fullName={coAdministrator.full_name} />
          <PrivacyNotice
            advisorFullName={this.props.advisorFullName}
            hideClientPrivacyNotice={true}
            isHousehold={true}
            otherHouseholdOwner={{first_name: coAdministrator.first_name, full_name: coAdministrator.full_name}}
          />
        </section>
      </div>
    )
  }
}

AddHouseholdMemberInterstitial.defaultProps = {
  addHouseholdMemberInviteId: '',
  advisorFullName: '',
  finished: noop,
  pendingCoAdministrator: {
    email: '',
    first_name: '',
    full_name: ''
  },
  updating: noop
}

AddHouseholdMemberInterstitial.propTypes = {
  addHouseholdMemberInviteId: PropTypes.string.isRequired,
  advisorFullName: PropTypes.string,
  finished: PropTypes.func.isRequired,
  pendingCoAdministrator: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    full_name: PropTypes.string
  }),
  processingAccept: PropTypes.bool,
  processingDecline: PropTypes.bool,
  updating: PropTypes.func.isRequired
}
