import {connect} from 'react-redux'
import {readEndpoint} from 'redux-json-api'
import PropTypes from 'prop-types'
import React from 'react'

import RespondToClientInvite from './RespondToClientInvite'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import ClientInviteInterstitialActions from '../../actions/ClientInviteInterstitialActions'
import Container from '../../../../lib/Container'
import Loader from '../../../../shared_components/NewLoader'
import MixpanelLogger from '../../../../lib/MixpanelLogger'
import routerUtils from '../../../../lib/routerUtils'
import storePrototype from '../../../../shared_components/StorePrototype'
import SessionApi from '../../../../auth/src/web/SessionApi'

export class RespondToClientInviteController extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      client_invite: {},
      processingAccept: false,
      processingDecline: false
    }

    this.accept = this.accept.bind(this)
    this.decline = this.decline.bind(this)
    this.submitResponse = this.submitResponse.bind(this)
  }

  componentDidMount() { MixpanelLogger.track('view_client_invitation') }

  accept() {
    MixpanelLogger.track('click_client_invitation_accept')
    this.setState(
      {client_invite: {response: 'accept'}, processingAccept: true},
      () => this.submitResponse()
    )
  }

  decline() {
    MixpanelLogger.track('click_client_invitation_no_thanks')
    this.setState(
      {client_invite: {response: 'decline'}, processingDecline: true},
      () => this.submitResponse()
    )
  }

  submitResponse() {
    this.props.updating(() => {
      if (this.state.client_invite.response === 'accept') {
        SessionApi.fetchCurrentUser() // ensures that session store has the most recent user data, in case user state has changed as a result of responding interstitials
        this.props.readEndpoint('user-configs')
          .then(() => routerUtils.push(`pending-requests/clients/${this.props.params.clientInviteId}/accepted`))
      } else {
        this.props.returnToController()
      }
    })

    ClientInviteInterstitialActions.respondToClientInvite({client_invite: this.state.client_invite}, this.props.params.clientInviteId)

    this.props.finished()
  }

  render() {
    return (
      <Loader loading={this.state.processingAccept || this.state.processingDecline}>
        <div className='central-content-box'>
          <RespondToClientInvite
            {...this.state}
            accept={this.accept}
            clientInvite={this.props.clientInvite}
            decline={this.decline}
            submitResponse={this.submitResponse}
          />
        </div>
      </Loader>
    )
  }
}

RespondToClientInviteController.defaultProps = {
  advisor: {
    full_name: ''
  }
}

RespondToClientInviteController.propTypes = {
  advisor: PropTypes.shape({
    full_name: PropTypes.string.isRequired
  }),
  finished: PropTypes.func.isRequired,
  params: PropTypes.shape({
    clientInviteId: PropTypes.string.isRequired
  }),
  updating: PropTypes.func.isRequired
}

Container.registerStore(
  'clientInviteInterstitial',
  storePrototype(ClientInviteInterstitialActions.Types.DID_RESPOND_TO_CLIENT_INVITE)
)

export default connect(null, {readEndpoint})(bindUpdate(RespondToClientInviteController, 'clientInviteInterstitial', null))
