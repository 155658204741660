import React from 'react'
import GlobalAnnouncementBanner from '../../../dashboard/src/components/feedback/GlobalAnnouncementBanner'

import './deputyFeedbackApp.scss'

const DeputyFeedbackApp = ({children}) => (
  <React.Fragment>
    <GlobalAnnouncementBanner />
    <div className='deputy-feedback-app'>

      <main>{children}</main>
    </div>
  </React.Fragment>
)


export default DeputyFeedbackApp
