/** @class WidthContainer
 *
 * @desc
 * Component used to set a `max-width` of `960px` and to also centralize the display.
 */

import classnames from 'classnames'
import './widthContainer.scss'


const WidthContainer = ({children, className}) => (
  <div className={classnames('width-container', className)}>
    {children}
  </div>
)

export default WidthContainer
