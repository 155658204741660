import PropTypes from 'prop-types'
import React from 'react'

import AdvisorContactInfo from './AdvisorContactInfo'
import bindResource from '../../../../shared_components/BindToStores'
import headshotPlaceholder from '../../../../shared_assets/default-advisor-avatar.svg'
import Container from '../../../../lib/Container'
import currentUserWrapper from '../../../../shared_components/currentUserWrapper'
import EditContactCardModal from './EditContactCardModal'
import FirmActions from '../../actions/FirmActions'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import ShadowWrapper from './ShadowWrapper'
import storeWrapper from '../../../../shared_components/storeWrapper'
import UserSubpage from './UserSubpage'

import ContactCardStore from '../../stores/ContactCardStore'

import './contactCard.scss'

export class ContactCard extends React.Component {
  componentDidMount() {
    if (!this.props.currentUser.account_manager && this.props.firm.data.organization_id)
      FirmActions.fetchOrganization(this.props.firm.data.organization_id)
  }

  loadingOrganization() { return !this.props.currentUser.account_manager && this.props.firm.data.organization_id && !this.props.organization.data.id }

  render() {
    const contactCard = this.props.contact_card.data
    return (
      <UserSubpage className='firm-advisor-contact-card' headerText='Contact Card'>
        <p>This contact card appears on client dashboards.</p>
        <LegacyLoader loading={this.props.loadingContactCard || this.loadingOrganization()}>
          <ShadowWrapper className='row-wrapper'>
            <div className='column-wrapper-sb'>
              <HeadShot headShot={contactCard.head_shot} />
              <EditContactCardModal contactCard={this.props.contact_card.data} {...this.props} />
            </div>
            <div className='column-wrapper advisor-info'>
              <h4>{contactCard.name}</h4>
              <div className='row-wrapper'>
                <UserInfo contactCard={contactCard} />
                <AdvisorContactInfo contactCard={contactCard} />
              </div>
            </div>
          </ShadowWrapper>
        </LegacyLoader>
      </UserSubpage>
    )
  }
}

ContactCard.defaultProps = {
  contact_card: {
    data: {},
    errors: [],
    message: null
  },
  currentUser: {},
  organization: {data: {}},
  loadingContactCard: true
}

ContactCard.propTypes = {
  contact_card: PropTypes.shape({
    data: PropTypes.shape({
      accreditation: PropTypes.string,
      email: PropTypes.string,
      firm_name: PropTypes.string,
      head_shot: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.shape({
    account_manager: PropTypes.bool
  }),
  firm: PropTypes.shape({
    data: PropTypes.object
  }),
  organization: PropTypes.shape({
    data: PropTypes.object
  }),
  loadingContactCard: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    firmId: PropTypes.string,
    firmUserSeatId: PropTypes.string
  }).isRequired
}

Container.registerAction('contact_card', FirmActions)
Container.registerStore('contact_card', ContactCardStore)
export default bindResource(
  storeWrapper(
    currentUserWrapper(ContactCard),
    [{actionTypes: [FirmActions.Types.GOT_ORGANIZATION], name: 'organization'}]
  ),
  {name: 'contact_card', type: 'itemAllParams'}
)


// HELPER COMPONENTS:

const HeadShot = props => {
  if (props.headShot)
    return <img alt='contact avatar' className='head-shot' src={props.headShot} />

  return <img alt='default contact avatar' className='head-shot' src={headshotPlaceholder} />
}

HeadShot.propTypes = {headShot: PropTypes.string}


const UserInfo = props => (<div className='column-wrapper'>
  <UserInfoRow>{props.contactCard.accreditation}</UserInfoRow>
  <UserInfoRow>{props.contactCard.title}</UserInfoRow>
  <UserInfoRow>{props.contactCard.firm_name}</UserInfoRow>
</div>)

UserInfo.propTypes = {
  contactCard: PropTypes.shape({
    accreditation: PropTypes.string,
    firm_name: PropTypes.string,
    title: PropTypes.string
  }).isRequired
}


const UserInfoRow = props => {
  if (props.children)
    return <div className='user-info-row'>{props.children}</div>

  return <span />
}
