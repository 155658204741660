import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const OrionActions = new LegacyActionFactory(['GOT_ORION_SSO', 'LINKED_ORION'])
OrionActions.fetchOrionSso = params => {
  OrionActions.fireApi(
    'post',
    'integrations/orion/login',
    {token: params.token},
    {
      JSONHead: 'session',
      successAction: OrionActions.Types.GOT_ORION_SSO
    }
  )
}

OrionActions.linkOrion = token => {
  OrionActions.fireApi(
    'post',
    'integrations/orion/link/',
    {token},
    {successAction: OrionActions.Types.LINKED_ORION}
  )
}

export default OrionActions
