import PropTypes from 'prop-types'

import './selectFilter.scss'


const SelectFilter = ({filter, onChange, options}) => (
  <select
    className='select-filter'
    onChange={event => onChange(event.target.value)}
    value={filter ? filter.value : ''}>
    <option value=''>Show All</option>
    {
      options.map(option => (
        <option
          key={option.id}
          value={option.id}>
          {option.name}
        </option>
      ))
    }
  </select>
)

SelectFilter.propTypes = {
  filter: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func
}
export default SelectFilter
