import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {pluralize} from '../../../../../lib/tools'

import StatBox from '../../../../../shared_components/StatBox'


const Statistics = props => {
  const completedItemsCount = props.everplanSummary.get('completed-items').size
  const deputiesCount = props.everplanSummary.get('deputies-count')
  return (
    <section className='stats row-wrapper'>
      <StatBox
        boxStyle='black-box'
        className='updated'
        label={`${pluralize('Section', completedItemsCount)} Started`}
        stat={completedItemsCount}
      />
      <StatBox
        boxStyle='pending-box'
        className='pending'
        label={`${pluralize('Deputy', deputiesCount, 'Deputies')} Added`}
        stat={deputiesCount}
      />
    </section>
  )
}

Statistics.defaultProps = {stats: {}}

Statistics.propTypes = {
  everplanSummary: PropTypes.instanceOf(Map),
  stats: PropTypes.shape({
    items_empty: PropTypes.number
  })
}

export default Statistics
