import classnames from 'classnames'
import PropTypes from 'prop-types'

import './badges.scss'


export const Badge = props => <span {...props} className={classnames('badge', 'core', props.className)} />

export const ArticleBadge = props => <Badge {...props} className={classnames('article-badge', props.className)}>Article</Badge>

export const ChecklistBadge = props => <Badge {...props} className={classnames('checklist-badge', props.className)}>Checklist</Badge>

export const GuideBadge = props => <Badge {...props} className={classnames('guide-badge', props.className)}>Guide</Badge>

export const BadgePicker = ({badgeType}) => {
  switch (badgeType) {
    case 'checklist': return <ChecklistBadge />
    case 'guide': return <GuideBadge />
    case 'article':
    default: // Fall back to article if badgeType isn't passed.
      return <ArticleBadge />
  }
}

BadgePicker.propTypes = {
  badgeType: PropTypes.oneOf(['article', 'checklist', 'guide'])
}
