import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'

import Loader from '../../../../shared_components/NewLoader'
import routerUtils from '../../../../lib/routerUtils'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

export const OpenIdConnectLinkCallback = props => {
  const {alwaysNotify} = useNotificationContext()

  const goToPreviousPage = () => {
    routerUtils.push(decodeURIComponent(props.location.query.state))
  }

  useEffect(() => {
    const token = props.location.query.id_token
    const resource = {
      type: 'open-id-connect-integrations',
      attributes: {token}
    }

    props
      .createResource(resource)
      .then(() => { alwaysNotify.shortSuccess('Your accounts are successfully linked.') })
      .catch(() => { alwaysNotify.shortError('Sorry, your account could not be connected at this time. Please try again.') })
      .finally(() => {
        // This is needed so that the user can see the success notification.
        setTimeout(goToPreviousPage, 2000)
      })
  }, [])

  return <Loader loading={true} />
}

OpenIdConnectLinkCallback.propTypes = {
  createResource: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      id_token: PropTypes.string,
      state: PropTypes.string
    })
  })
}

export default connect(null, {createResource})(OpenIdConnectLinkCallback)
