import {connect} from 'react-redux'
import {Map} from 'immutable'
import {useState, useEffect} from 'react'
import {createResource, updateResource} from 'redux-json-api'
import PropTypes from 'prop-types'

import {
  findEverplanResponseByEverplanId,
  filterContactsByEverplanId,
  findRawResourceByAttributeId
} from '../../../../lib/plan_data/userData'
import {currentUserConfig, currentUser} from '../../../../lib/userTools'
import Logger from '../../../../lib/NewLogger'

import JiclPreview from './JiclPreview'

const JiclPreviewContainer = props => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    Logger.log({
      name: 'view_jicl_preview',
      payload: {
        context: 'jicl',
        flavor: 'covid',
        wildcard: 'covid'
      }
    })
  }, []) // empty array so it only logs the event on first mount i.e +componentDidMount+

  return (
    <JiclPreview
      {...props}
      openModal={() => setShowModal(true)}
      closeModal={() => setShowModal(false)}
      showModal={showModal}
    />
  )
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)
  const everplanResponse = findEverplanResponseByEverplanId({
    everplanResponses: state.api['everplan-responses'],
    everplanId: userConfig.get('everplan-id')
  })
  const user = currentUser({userConfig, users: state.api.users})
  const jiclConfig = findRawResourceByAttributeId({
    rawResource: state.api['jicl-configs'],
    attribute: 'user-id',
    id: userConfig.get('id')
  })

  return {
    everplanResponse,
    kits: state.kits,
    items: state.items,
    listMappings: state.listMappings,
    views: state.views,
    elements: state.elements,
    newContacts: filterContactsByEverplanId({
      newContacts: state.api['new-contacts'],
      everplanId: userConfig.get('everplan-id')
    }),
    user,
    jiclConfig
  }
}

JiclPreviewContainer.propTypes = {
  jiclConfig: PropTypes.instanceOf(Map)
}

export default connect(mapStateToProps, {createResource, updateResource})(
  JiclPreviewContainer
)
