import PropTypes from 'prop-types'
import {Component, Fragment} from 'react'

import Closer from '../../../../shared_components/core/closer/Closer'
import ConfirmationModal from '../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'

import {addPossessive} from '../../../../lib/tools'
import bindUpdate from '../../../../shared_components/BoundUpdate'
import Container from '../../../../lib/Container'
import DeputyActions from '../../actions/DeputyActions'
import routerUtils from '../../../../lib/routerUtils'
import storePrototype from '../../../../shared_components/StorePrototype'

import './declineDeputyInvite.scss'

export class DeclineDeputyInvite extends Component {
  constructor(props) {
    super(props)

    this.state = {showModal: false}
    this.decline = this.decline.bind(this)
    this.closer = this.closer.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  decline() {
    this.props.updating(() => routerUtils.push('client_onboarding/deputy-invite-declined'))
    DeputyActions.declineDeputyInviteAsPendingOnlyDeputy(this.props.token)
    this.props.finished()
  }

  toggleModal() {
    this.setState({showModal: !this.state.showModal})
  }

  closer() { return <Closer closer={this.toggleModal} /> }

  render() {
    return (
      <Fragment>
        <div className='decline-deputy-invite'>
          <h5>Not interested?</h5>
          <p>
            <a onClick={this.toggleModal}>Decline this invitation</a> if you don't want to be one
            of {addPossessive(this.props.invitedByFirstName)} deputies. We'll let {this.props.invitedByFirstName} know,
            and we won't email you again about this.
          </p>
          {this.props.errors.length ? <div className='errors'>{this.props.errors[0].errors[0]}</div> : null}
        </div>
        <ConfirmationModal
          confirmButtonText='Decline'
          headingText='Decline this invitation?'
          onClose={this.toggleModal}
          onConfirmation={this.decline}
          open={this.state.showModal}
        />
      </Fragment>
    )
  }
}

DeclineDeputyInvite.defaultProps = {
  errors: [],
  invitedByFirstName: '',
  token: ''
}

DeclineDeputyInvite.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  finished: PropTypes.func,
  invitedByFirstName: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  updating: PropTypes.func
}

Container.registerStore('declineDeputyInvite', storePrototype(DeputyActions.Types.DID_DECLINE_DEPUTY_INVITE))
export default bindUpdate(DeclineDeputyInvite, 'declineDeputyInvite', 'declineDeputyInvite')
