import classnames from 'classnames'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {BoxGutterSmall} from '../../../../../dashboard/src/components/blocks/Boxes'
import {Text, TextGutterSmall} from '../../../../../dashboard/src/components/blocks/Texts'

import {capitalize} from '../../../../../lib/tools'

import './resource.scss'

const Resource = ({data, hasClientActivities}) => (
  <BoxGutterSmall className={classnames('resource', {'stack-resources': hasClientActivities})}>
    <a className='flex-container' target='_blank' rel='noopener noreferrer' href={data.get('url')}>
      <div className='image-container'>
        <img alt={data.get('title')} src={data.get('image')} />
      </div>
      <Text className='content'>
        <span className='resource-category'>{capitalize(data.get('category'))}</span>
        <h2>{data.get('title')}</h2>
        <TextGutterSmall>{data.get('description')}</TextGutterSmall>
      </Text>
    </a>
  </BoxGutterSmall>
)

Resource.propTypes = {
  data: PropTypes.instanceOf(Map),
  hasClientActivities: PropTypes.bool
}

export default Resource
