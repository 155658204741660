import React from 'react'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import AuthGateway from '../../../shared_components/AuthGateway'
import LegacyLoader from '../../../shared_components/LegacyLoader'
import SessionStore from '../../../auth/src/stores/SessionStore'
import SessionApi from '../../../auth/src/web/SessionApi'
import Header from '../../../shared_components/header/Header'
import DisclosureFooter from '../../../shared_components/DisclosureFooter'
import routerUtils from '../../../lib/routerUtils'
import PageTitle from '../../../shared_components/PageTitle'

import './clientOnboardingApp.scss'

export default class ClientOnboardingApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      user: SessionStore.getState().currentUser
    }
    this.onUpdate = this.onUpdate.bind(this)
    this.isClientInvitationLandingPage = this.isClientInvitationLandingPage.bind(this)
  }
  componentDidMount() {
    SessionStore.addChangeListener(this.onUpdate)
    SessionApi.fetchCurrentUser()
  }
  componentWillUnmount() {
    SessionStore.removeChangeListener(this.onUpdate)
  }
  onUpdate() {
    this.setState({
      loading: false,
      user: SessionStore.getState().currentUser
    })
  }

  isClientInvitationLandingPage() {
    return this.props.location.pathname.includes('client_onboarding/invitation')
  }

  render() {
    return (
      <LegacyLoader loading={this.state.loading}>
        <PageTitle title= 'Welcome' />
        <div className={this.props.className}>
          <Header />
          <AuthGateway whitelist={['client_onboarding/invitation', 'client_onboarding/client_invite', 'client_onboarding/deputy_confirm', 'client_onboarding/deputy-invite-declined']}>
            <main className={classnames('main-content', {'with-client-invitation': this.isClientInvitationLandingPage()})}>
              {routerUtils.childrenWithProps(this.props.children, {user: this.state.user})}
            </main>
          </AuthGateway>
          <DisclosureFooter />
        </div>
      </LegacyLoader>
    )
  }
}

ClientOnboardingApp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}
