import {Link} from 'react-router'
import PropTypes from 'prop-types'
import React from 'react'

import LoggedInMenu from './LoggedInMenu'

import Container from '../../../../lib/Container'
import Dev from '../../../../dev_only'
import FirmActions from '../../actions/FirmActions'
import SessionStore from '../../../../auth/src/stores/SessionStore'
import storePrototype from '../../../../shared_components/StorePrototype'

import '../../../../shared_components/header/header.scss'

import './header.scss'


export default class ProHeaderController extends React.Component {
  constructor(props) {
    super(props)
    this.state = {data: this.currentUser() || {}}

    this.onUpdate = this.onUpdate.bind(this)
  }

  componentDidMount() { SessionStore.addChangeListener(this.onUpdate) }

  onUpdate() { this.setState({data: this.currentUser()}) }

  componentWillUnmount() { SessionStore.removeChangeListener(this.onUpdate) }

  currentUser() { return SessionStore.getState().currentUser }

  render() { return <Header currentUser={this.state.data} /> }
}

export class Header extends React.Component {
  constructor() {
    super()

    this.registerActionAndStores()
    this.store = Container.getStore('firmUsers')
    this.firmConfigStore = Container.getStore('firmConfig')
    this.action = Container.getAction('firm_users')
    this.firmConfigAction = Container.getAction('firm_config')

    this.state = {
      firmUsers: this.listFirmUsers(),
      firmConfig: this.firmConfigStore.getState().data
    }

    this.onFirmConfigUpdate = this.onFirmConfigUpdate.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
  }

  registerActionAndStores() {
    Container.registerAction('firm_users', FirmActions)
    Container.registerStore('firmUsers', storePrototype(FirmActions.Types.GOT_FIRM_USERS))
    Container.registerStore('firmConfig', storePrototype(FirmActions.Types.GOT_FIRM_CONFIG))
  }

  componentDidMount() {
    this.firmConfigStore.addChangeListener(this.onFirmConfigUpdate)
    this.store.addChangeListener(this.onUpdate)
    if (!this.state.firmUsers.length && this.props.currentUser && this.props.currentUser.firm) {
      this.action.fetchFirmUsers(this.props.currentUser.firm)
      FirmActions.fetchFirmConfig(this.props.currentUser.firm)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentUser = nextProps.currentUser
    /**
     * We don't want account manager to fetch the firm users or config here so it doesn't
     * overwrite the firm_config later in the app when it's fetching for individual firms. - TK
     */
    if (currentUser && !currentUser.account_manager && currentUser.firm && !this.state.firmUsers.length) {
      this.action.fetchFirmUsers(currentUser.firm)
      FirmActions.fetchFirmConfig(currentUser.firm)
    }
  }

  onUpdate() {
    this.setState({firmUsers: this.listFirmUsers()})
  }

  onFirmConfigUpdate() {
    this.setState({
      firmConfig: this.firmConfigStore.getState().data
    })
  }

  componentWillUnmount() {
    this.store.removeChangeListener(this.onUpdate)
    this.firmConfigStore.removeChangeListener(this.onFirmConfigUpdate)
  }

  listFirmUsers() {
    const storeState = this.store.getState().data
    return storeState.advisors && storeState.advisors.seats ? storeState.advisors.seats : []
  }

  render() {
    const currentUser = this.props.currentUser
    if (currentUser && Object.keys(currentUser).length > 0) {
      return (
        <header className='pro-header'>
          <Dev.Title title={this.props.currentUser.id} />
          <nav>
            <div className='header-content row-wrapper-sb'>
              <Link activeClassName='active' to='/pro' className='branding'>Everplans Professional</Link>
              <LoggedInMenu currentUser={currentUser} advisors={this.state.firmUsers} professionalDesignation={this.state.firmConfig.professional_designation} />
            </div>
          </nav>
        </header>
      )
    } else {
      return (
        <header className='pro-header'>
          <nav>
            <div className='header-content row-wrapper-sb'>
              <span className='branding'>Everplans Professional</span>
            </div>
          </nav>
        </header>
      )
    }
  }
}

Header.defaultProps = {currentUser: {}}

Header.propTypes = {currentUser: PropTypes.object}
