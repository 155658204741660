import PropTypes from 'prop-types'
import React from 'react'

const ViewComplianceBcc = props => (
  <div className='view-compliance-bcc'>
    <p className='header'>{props.isEnabled ? 'Enabled' : 'Disabled'}</p>
    <p className='description'>{props.isEnabled ?
      'A copy of each client invitation will be sent to the addresses listed below.' :
      'No copy of the client invitation will be sent.'}</p>
  </div>
)

ViewComplianceBcc.propTypes = {
  isEnabled: PropTypes.bool
}

export default ViewComplianceBcc
