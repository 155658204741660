// This is to display questions in the JICL flow that are related to Guide.
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Accept, Cancel} from '../../../../../dashboard/src/components/blocks/Buttons'
import {TextGutterLarge} from '../../../../../dashboard/src/components/blocks/Texts'

import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'

import './jiclGuide.scss'


const JiclGuide = props => {
  const {alwaysNotify} = useNotificationContext()

  const guideConfig = props.sectionConfig.get('guideConfig')

  const submitGuide = value => {
    props.onGuideSubmit(value)

    if (value)
      alwaysNotify.shortSuccess("Great! We'll email you the information shortly.")
  }

  return (
    <div className='jicl-guide'>
      {guideConfig.get('notes').map(paragraph => (
        <TextGutterLarge
          key={paragraph}
          dangerouslySetInnerHTML={{__html: paragraph}}
        />
      ))}
      <div>
        <Accept onClick={() => submitGuide(true)}>
          {guideConfig.get('yesButtonText')}
        </Accept>
        <Cancel onClick={() => submitGuide(false)}>
          {guideConfig.get('noButtonText')}
        </Cancel>
      </div>
    </div>
  )
}

JiclGuide.propTypes = {
  onGuideSubmit: PropTypes.func,
  sectionConfig: PropTypes.instanceOf(Map)
}

export default JiclGuide
