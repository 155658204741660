import {everplansBlackLogo} from '../images'

import './jiclUserViewBanner.scss'


const JiclUserViewBanner = () => (
  <div className='jicl-user-view-banner'>
    <img
      className='everplans-logo'
      src={everplansBlackLogo}
      alt='everplans-logo'
    />
    <div className='body-text'>
      Make sure everyone you know has an Everplan.
    </div>
  </div>
)

export default JiclUserViewBanner
