import React from 'react'

import PropTypes from 'prop-types'

import Button from '../../../../shared_components/Button'
import Container from '../../../../lib/Container'
import Forms from '../../../../shared_components/forms'
import LegacyLoader from '../../../../shared_components/LegacyLoader'

import bindResources from '../../../../shared_components/BindToStores'
import {goToInterstitials} from '../../../../lib/interstitialTools'
import InviteActions from '../../actions/InviteActions'
import SessionApi from '../../../../auth/src/web/SessionApi'
import storePrototype from '../../../../shared_components/StorePrototype'

import './firmUserConfirm.scss'

Container.registerAction('invite', InviteActions)
Container.registerStore('firm_confirm', storePrototype(InviteActions.Types.CONFIRMED_ADVISOR))

class FirmUserConfirm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {errors: []}

    this.onConfirm = this.onConfirm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    this.store().addChangeListener(this.onConfirm)
  }
  componentWillUnmount() {
    this.store().removeChangeListener(this.onConfirm)
  }
  store() {
    return Container.getStore('firm_confirm')
  }
  onConfirm() {
    var confirmStoreState = this.store().getState()
    if (confirmStoreState.errors.length === 0) {
      SessionApi._setToken(confirmStoreState.data.token)
      SessionApi.fetchCurrentUser() // since we are no longer forwarding session, we need current user to be present in session store - Atanda
      goToInterstitials()
    } else {
      this.setState({errors: confirmStoreState.errors, loading: false, processing: false})
    }
  }
  termsValidator(name, data) {
    if (data[name] !== true)
      return {name, errors: ['You must agree to the terms of service and privacy policy.']}
  }
  handleSubmit() {
    var errors = this.refs.form.validate()
    var data = this.refs.form.formData()
    data.token = this.props.params.inviteId
    if (errors.length < 1)
      this.setState({errors, processing: true}, () => InviteActions.confirmFirmUser(data))
    else
      this.setState({errors})
  }
  formErrors() {
    if (this.state.errors.length > 0)
      return ['Please review the errors above.']
  }
  render() {
    var data = this.props.invite ? this.props.invite.data : {}
    return (
      <LegacyLoader loading={this.props.loading}>
        <div className='firm-user-confirm'>
          <h1 className='heading-1'>Welcome, {data.first_name}.</h1>
          <h3 className='heading-3'>Create your password to get started.</h3>
          <Forms.Form ref='form'>
            <Forms.Password label='Password' name='password' />
            <Forms.ServiceTerms name='agreement' validator={this.termsValidator} />
            <Forms.Wrapper label=' ' errors={this.formErrors()}>
              <Button loading={this.state.processing} onClick={this.handleSubmit} className='btn btn-primary' ref='button'>Confirm My Account</Button>
            </Forms.Wrapper>
          </Forms.Form>
        </div>
      </LegacyLoader>
    )
  }
}

FirmUserConfirm.propTypes = {
  invite: PropTypes.shape({
    data: PropTypes.shape({})
  }),
  params: PropTypes.shape({
    inviteId: PropTypes.string
  })
}

const onBoundUpdate = function(newBoundData) {
  if (newBoundData.errors && newBoundData.errors.length > 0) {
    this.goToThere('/auth/invalid_token/advisor_invite')
  } else {
    this.setState({invite: newBoundData,
      loading: false,
      loadingInvite: false})
  }
}

export default bindResources(FirmUserConfirm, {name: 'invite', type: 'item'}, onBoundUpdate)
