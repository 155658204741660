import PropTypes from 'prop-types'
import {useState, useCallback} from 'react'
import {Map} from 'immutable'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'

import JiclPasswordForm from './JiclPasswordForm'
import JiclProfileForm from './JiclProfileForm'
import JiclHeader from '../JiclHeader'

import {currentUserConfig, currentUser} from '../../../../lib/userTools'

export const JiclUserUpgradeContainer = props => {
  const [step, setStep] = useState(0)
  const [userData, setUserData] = useState(Map())

  const onPasswordSubmit = useCallback(
    newUserData => {
      setUserData(userData.merge(newUserData))
      setStep(step + 1)
    },
    [userData, step]
  )

  const submitUserData = profileData => {
    const resource = {
      type: 'jicl-upgrades',
      attributes: {
        ...profileData.toJS(),
        ...userData.toJS()
      }
    }

    return props.createResource(resource)
  }

  return (
    <div className='jicl-user-upgrade-container'>
      <JiclHeader />
      <div className='forms-playground'>
        <div className='section-title border-bottom'>
          Let's finish setting up your account
        </div>
        {step === 0 && <JiclPasswordForm onPasswordSubmit={onPasswordSubmit} email={props.email} />}
        {step === 1 && <JiclProfileForm submitUserData={submitUserData} firstName={props.firstName} />}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)
  const user = currentUser({userConfig, users: state.api.users})

  return {
    email: user.getIn(['emails', 0, 'email-address']),
    firstName: user.get('first-name')
  }
}

JiclUserUpgradeContainer.propTypes = {
  children: PropTypes.node,
  createResource: PropTypes.func,
  email: PropTypes.string
}

export default connect(mapStateToProps, {createResource})(JiclUserUpgradeContainer)
