import {fromJS} from 'immutable'
import PropTypes from 'prop-types'
import {useCallback, useRef, useState} from 'react'

import Fields from '../../../../shared_components/forms/v2/fields'
import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import Form from '../../../../shared_components/forms/v2/Form'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'

import {dateFromForm, dateToISOString} from '../../../../lib/dateTools'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import routerUtils from '../../../../lib/routerUtils'
import validators from '../../../../shared_components/forms/v2/validators'

const JiclProfileForm = ({submitUserData, firstName}) => {
  const {alwaysNotify} = useNotificationContext()
  const formRef = useRef()
  const [processing, setProcessing] = useState(false)
  const name = firstName ? firstName.split(' ') : []

  const submitProfile = useCallback(() => {
    if (formRef.current) {
      const form = formRef.current
      form.onSubmit()

      if (form.isValid()) {
        setProcessing(true)

        const formData = form.formData()
        const pronounsValue = formData.getIn(['pronouns', 'value'])

        const data = fromJS({
          ...formData.getIn(['name', 'value']).toJS(), // name
          birthdate: dateToISOString(dateFromForm(formData.getIn(['birthdate', 'value']))),
          // In the backend gender will map to the correct pronoun, where null is they/them
          gender: pronounsValue === 'nb' ? null : pronounsValue,
          ...formData.getIn(['zip-or-country-code', 'value']).toJS() // zip or country
        })

        submitUserData(data)
          .then(() => routerUtils.push('/auth/security?loc=1'))
          .catch(() => {
            setProcessing(false)
            alwaysNotify.shortError("Sorry, we've encountered an error.")
          })
      }
    }
  })

  return (
    <div className='jicl-profile-form'>
      <TextGutterMedium>
        Please tell us a little bit about yourself so that we can make your
        experience more personalized and meaningful.
      </TextGutterMedium>
      <Form className='core' ref={formRef}>
        <Fields.Name
          data={
            fromJS({
              id: 'name',
              legend: 'Your name',
              name: 'name',
              value: {
                'first-name': name[0],
                'last-name': name[1]
              }
            })
          }
          validator={validators.requiredNameFirstLastValidator}
        />
        <Fields.Date
          data={
            fromJS({
              id: 'birthdate',
              legend: 'Date of birth',
              name: 'birthdate'
            })
          }
          validator={validators.birthdateValidator}
        />
        <Fields.VerticalRadioList
          data={
            fromJS({
              id: 'pronouns',
              items: [
                {label: 'She/Her', value: 'f'},
                {label: 'He/Him', value: 'm'},
                {label: 'They/Them', value: 'nb'}
              ],
              legend: 'Choose one',
              name: 'pronouns'
            })
          }
          validator={validators.requiredPronounsValidator}
        />
        <Fields.ZipOrCountry
          data={
            fromJS({
              id: 'zip-or-country-code',
              legend: 'ZIP code',
              name: 'zip-or-country-code'
            })
          }
          upgrade
          validator={validators.zipOrCountryValidator}
        />
        <Accept onClick={submitProfile} processing={processing}>Continue</Accept>
      </Form>
    </div>
  )
}

JiclProfileForm.propTypes = {
  firstName: PropTypes.string,
  submitUserData: PropTypes.func
}

export default JiclProfileForm
