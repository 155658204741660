// NOTE: multi-line code examples need an extra blank line at the end to allow for balanced padding in the code block styles. --BLR

// Text
const BodyText = `
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et nisi vitae ex vulputate dictum. Donec et interdum dolor. Proin tincidunt nunc quis eros mollis blandit. Sed pharetra est egestas diam tempus, a sagittis leo porta.
  </p>
  <p>
    Maecenas semper sapien tempor vehicula eleifend. Morbi in libero vitae enim rutrum hendrerit. Curabitur convallis felis vitae venenatis suscipit. Fusce lacinia velit orci. Praesent faucibus urna a ultricies lacinia. In consectetur blandit odio id aliquet. Integer id turpis mauris.
  </p>

`

// Headings
const DashboardHeading = `
  <DashboardHeading>Dashboard heading (MUI)</DashboardHeading>

`

const Header = `
  <h1>h1 Heading</h1>
  <h2>h2 Heading</h2>
  <h3>h3 Heading</h3>
  <h4>h4 Heading</h4>
  <h5>h5 Heading</h5>
  <h6>h6 Heading</h6>

`

// Font scales
const FontScale0 = `.a-css-selector { @include fontScale(0); }`

const FontScale1 = `.a-css-selector { @include fontScale(1); }`

const FontScale2 = `.a-css-selector { @include fontScale(2); }`

const FontScale3 = `.a-css-selector { @include fontScale(3); }`

const FontScale4 = `.a-css-selector { @include fontScale(4); }`

const FontScale5 = `.a-css-selector { @include fontScale(5); }`

const FontScale6 = `.a-css-selector { @include fontScale(6); }`

// Font weight scales
const FontWeightScale1 = `.a-css-selector { @include fontWeightScale(1); }`

const FontWeightScale2 = `.a-css-selector { @include fontWeightScale(2); }`

const FontWeightScale3 = `.a-css-selector { @include fontWeightScale(3); }`

const FontWeightScale4 = `.a-css-selector { @include fontWeightScale(4); }`

const FontWeightScale5 = `.a-css-selector { @include fontWeightScale(5); }`


// Buttons
const MUI = `
    <Button onClick={handleClick}>Primary</Button>
    <Button onClick={handleClick}>Hover</Button>
    <Button onClick={handleClick}>Active</Button>
    <Button disabled={isDisabled} onClick={handleClick}>Disabled</Button>
    <Button onClick={handleClick} loading={isLoading}>Primary</Button>
  
    <Button color='cancel' onClick={handleCancelClick}>Cancel</Button>
    <Button color='cancel' onClick={handleCancelClick}>Hover</Button>
    <Button color='cancel' onClick={handleCancelClick}>Active</Button>
    <Button color='cancel' disabled={isDisabled} onClick={handleCancelClick}>Disabled</Button>
  
    <Button color='error' onClick={handleExitClick}>Exit</Button>
    <Button color='error' onClick={handleExitClick}>Hover</Button>
    <Button color='error' onClick={handleExitClick}>Active</Button>
    <Button color='error' disabled={isDisabled} onClick={handleExitClick}>Disabled</Button>
    <Button color='error' loading={isLoading} onClick={handleExitClick}>Exit</Button>

`

const LinkButton = `
    <LinkButton onClick={handleClick}>Link</LinkButton>
    <LinkButton disabled={isDisabled} onClick={handleClick}>Disabled</LinkButton>
  
`

const Accept = `
  <Buttons.Accept className='1337-button' onClick={this.acceptSomething}>Button!</Buttons.Accept>
  <Buttons.Accept className='1337-button' onClick={this.acceptSomething} />{/* Default text is 'Accept' */}
  <Buttons.Accept className='1337-button' onClick={this.acceptSomething} disabled>Disabled</Buttons.Accept>

`

const Add = `
  <Buttons.Add className='1337-button' onClick={this.addSomething}>Button!</Buttons.Add>
  <Buttons.Add className='1337-button' onClick={this.addSomething} />{/* Default text is 'Add' */}

`

const Button = `<Buttons.Button className='1337-button' onClick={this.doSomething}>Button!</Buttons.Button>{/* No default text */}`

const Cancel = `
  <Buttons.Cancel className='1337-button' onClick={this.cancelSomething}>Button!</Buttons.Cancel>
  <Buttons.Cancel className='1337-button' onClick={this.cancelSomething} />{/* Default text is 'Cancel' */}

`

const Remove = `
  <Buttons.Remove className='1337-button' onClick={this.removeSomething}>Button!</Buttons.Remove>
  <Buttons.Remove className='1337-button' onClick={this.removeSomething} />{/* Default text is 'Remove' */}

`

// Text
const Text = `<Texts.Text className='1337-text-not-a-paragraph'>Here's some text that isn't quite a paragraph! By default, it has no bottom margin.</Texts.Text>`

const TextGutterSmall = `<Texts.TextGutterSmall>Here is a Text block with a bottom margin of 10px.</Texts.TextGutterSmall>`

const TextGutterMeduim = `<Texts.TextGutterMeduim>Here is a Text block with a bottom margin of 20px.</Texts.TextGutterMeduim>`

const TextGutterLarge = `<Texts.TextGutterLarge>Here is a Text block with a bottom margin of 30px.</Texts.TextGutterLarge>`

const TextGutterExtraLarge = `<Texts.TextGutterExtraLarge>Here is a Text block with a bottom margin of 40px.</Texts.TextGutterExtraLarge>`

// Content Box
const ContentBox = `<ContentBoxes.ContentBox className='1337-content-box'>This is a Content Box.</ContentBoxes.ContentBox>`

const ContentBoxGutterSmall = `<ContentBoxes.ContentBoxGutterSmall>This is a Content Box with a bottom margin of 10px.</ContentBoxes.ContentBoxGutterSmall>`

const ContentBoxGutterMedium = `<ContentBoxes.ContentBoxGutterMedium>This is a Content Box with a bottom margin of 20px.</ContentBoxes.ContentBoxGutterMedium>`

const ContentBoxGutterLarge = `<ContentBoxes.ContentBoxGutterLarge>This is a Content Box with a bottom margin of 30px.</ContentBoxes.ContentBoxGutterLarge>`

const ContentBoxGutterExtraLarge = `<ContentBoxes.ContentBoxGutterExtraLarge>This is a Content Box with a bottom margin of 40px.</ContentBoxes.ContentBoxGutterExtraLarge>`

// Box
const Box = `<Box className='1337-box'>This is a Box, which is built on top of a Content Box</Box>`

// Box with Heading
const BoxWithHeading = `<BoxWithHeading className='1337-box-with-heading' heading='Here is my Heading'>This is a box with a Heading and text. The required props are heading and children. The children represents the content of the box that is not the heading, where the heading prop defines the properties of the heading.</BoxWithHeading>`

// Box with Image
const BoxWithImage = `
  <BoxWithImage
    className='1337-box-with-image'
    src='https://www.everplans.com/assets/secure_sharing.svg'
    alt='Secure Sharing, Everplans'
    imgClassName='an-img-class'
    tagline='Here is a tagline'{/* This prop is optional */}
    heading='My Super Awesome Heading'{/* This prop is optional */}
  >
    This is Box with an Image. The required props are alt, children, and src. children represents the contents of the Box that aren't the image, while alt and src define the properties of the image in the Box. The optional props are heading, tagline, className, and imageClassName. Any other props than those mentioned are passed to the image.
  </BoxWithImage>

`

const BoxWithBadge = `
<BoxGroup>
  <BoxWithBadge badgeType='article' heading='Massive Explosion at Article Accelerator'>
    A four-alarm fire still rages after a tragic explosion at a local article accelerator early this morning. The fire began in the
    basement of the headquarters of FasText, a startup founded by renowned liar James Frey, and quickly engulfed the boiler, leading
    to a blast heard for miles around.
  </BoxWithBadge>
  <BoxWithBadge badgeType='checklist' heading="Anton Checklist: Don't Forget the Gun from Act I">
    Do you ever have trouble remembering the little details that critics always seem to use to tear apart your plays in their
    reviews? You'll never have that trouble again if you use our brand new Anton Checklist! Don't let critics slam you for
    forgetting to bring back that gun you introduced in the first act.
  </BoxWithBadge>
  <BoxWithBadge badgeType='guide' heading='Guides and Dolls'>
    Do you like dolls? And are you the kind of traveler who reads the entire guidebook before you even board the plane for a trip?
    Well, then you'll love <i>Guides and Dolls</i>, our new guide for where to find the best information about dolls you can go
    see in foreign museums!
  </BoxWithBadge>
</BoxGroup>

`

// ////////////////////////////////////////////////////////////////////////////
// MODALS


const Confirmation = `
  <ConfirmationModal
    bodyText='All information associated with it will be removed.'
    cancelButtonText='No'
    confirmButtonText='Delete'
    headingText='Are you sure you want to delete XYZ?'
    loading={loading}
    onClose={closeConfirmationModal}
    onConfirmation={doSomething}
    open={showConfirmationModal}
    showWarningIcon={true}
  />
`

const Large = `
  <LargeModal onClose={closeTheModal} open={showModal}>
    <Lorem paragraph={2} />
  </LargeModal>

  `

const Small = `
  <SmallModal onClose={closeTheModal} open={showModal}>
    <Lorem paragraph={2} />
  </SmallModal>

`

const ButtonPopUpModal = `
<Modals.ButtonPopUpModal launcher={<Button>Button pop up!</Button>}>
  <Lorem paragraphCount={3} />
</Modals.ButtonPopUpModal>

`

const PromptPopUpModal = `
<Modals.LinkPromptPopUpModal launcher={<a>Prompt link pop up!</a>}>
  <header>
    <h2>h2 Prompt Heading</h2>
  </header>
  <TextGutterMedium>
    This is a Prompt Pop Up Modal. It should be used when you want a user to input and then submit information via a form.
    Generally this type of modal is expected to contain a heading, a form, a Cancel button, and a submit button (technically this
    will be an Accept button). The closer X can be suppressed by passing a showCloser prop of false.
  </TextGutterMedium>
  <form>
    <Inputs.Text placeholder='Placeholder Text' />
  </form>
  <Buttons.Accept>Submit</Buttons.Accept>
</Modals.LinkPromptPopUpModal>

`

const SuccessPopUpModal = `
<Modals.SuccessPopUpModal launcher={<Buttons.Button>Success button pop up!</Buttons.Button>}>
  <header>
    <img src={successCheckmarkIcon} alt='Success checkmark' />
    <h2>h2 Success Modal Heading</h2>
  </header>
  <TextGutterMedium>
    Hooray! You are seeing our Success pop up modal!
  </TextGutterMedium>
  <TextGutterMedium>
    You will normally see this modal to confirm an action - for example - sending an invitaiton to be your Deputy.
  </TextGutterMedium>
  <Buttons.Button>Done</Buttons.Button>
</Modals.SuccessPopUpModal>

`

const ButtonPopUpModalLarge = `
<Modals.ButtonPopUpModalLarge launcher={<Buttons.Button>Large button Pop Up!</Buttons.Button>}>
  <ModalHeaderBottomDivider className='a-class-selector' heading='h2 Large Modal Heading with Border Divider' banner='Hello, I am a banner' />
  <TextGutterMedium>
    This is a Large Pop Up Modal. It should be used when you want to display a form or a large amount of content.
    Generally this type of modal is expected to contain a heading, a form, a Cancel and Submit button. As an option you can add a banner above the heading.
  </TextGutterMedium>
  <Lorem paragraphCount={8} />
  <ButtonGroup>
    <Buttons.Cancel />
    <Buttons.Accept />
  </ButtonGroup>
</Modals.ButtonPopUpModalLarge>

`

const LinkPopUpModalLarge = `
<Modals.LinkPopUpModalLarge launcher={<a>Link pop up!</a>}>
  <ModalHeader className='a-class-selector' heading='h2 Large Modal Heading' banner='Hello, I am a banner' />
  <TextGutterMedium>
    This is a Large Pop Up Modal. It should be used when you want to display a form or a large amount of content.
    Generally this type of modal is expected to contain a heading, a form, a Cancel and Submit button. As an option you can add a banner above the heading.
  </TextGutterMedium>
  <Lorem paragraphCount={8} />
  <ButtonGroup>
    <Buttons.Cancel />
    <Buttons.Accept />
  </ButtonGroup>
</Modals.LinkPopUpModalLarge>

`

const FeedbackModal = `
<Modals.FeedbackModal closerFillColor={buttonTextLight} launcher={<Buttons.Button>User Feedback Modal</Buttons.Button>}>
  <header>
    <h2>How was your experience adding a Deputy?</h2>
  </header>
  <FeedbackForm
    items={[
      {label: 'Great', value: 'Great', image: GreatEmojiIcon},
      {label: 'Good', value: 'Good', image: GoodEmojiIcon},
      {label: 'Not Good', value: 'Not Good', image: MehEmojiIcon},
      {label: 'Terrible', value: 'Terrible', image: TerribleEmojiIcon}
    ]}
    placeholder='What can we do better?'
    checkboxLabel="I'm ok with Everplans reaching out to me via email to gather additional details about my experience."
  >
    <Buttons.Button>Submit</Buttons.Button>
  </FeedbackForm>
</Modals.FeedbackModal>

`

// Currently not using the Sliding Modals and is replaced by the Large Pop Up Modal. --KW
const ButtonSlidingModal = `
<Modals.ButtonSlidingModal closerComponent={Closer} launcher={<Buttons.Button>Sliding!</Buttons.Button>} heading='h2 Sliding Modal Heading'>
  <Lorem paragraphCount={2} />
  <Inputs.Text placeholder='Placeholder Text' />
  <ButtonGroup>
    <Buttons.Cancel />
    <Buttons.Accept />
  </ButtonGroup>
</Modals.ButtonSlidingModal>

`

const LinkSlidingModal = `
<Modals.LinkSlidingModal launcher={<a>Link sliding modal!</a>} heading='h2 Sliding Modal Heading'>
  <Lorem paragraphCount={2} />
  <Inputs.Text placeholder='Placeholder Text' />
  <ButtonGroup>
    <Buttons.Cancel />
    <Buttons.Accept />
  </ButtonGroup>
</Modals.LinkSlidingModal>

`

const UnorderedList = `
<UnorderedList children={['First list item', 'Second list item', 'Third list item']} />
`

export default {
  BodyText,
  Buttons: {
    MUI,
    LinkButton,
    Accept,
    Add,
    Button,
    Cancel,
    Remove
  },
  Box,
  BoxWithBadge,
  BoxWithHeading,
  BoxWithImage,
  ContentBox,
  ContentBoxGutterSmall,
  ContentBoxGutterMedium,
  ContentBoxGutterLarge,
  ContentBoxGutterExtraLarge,
  FontScale0,
  FontScale1,
  FontScale2,
  FontScale3,
  FontScale4,
  FontScale5,
  FontScale6,
  FontWeightScale1,
  FontWeightScale2,
  FontWeightScale3,
  FontWeightScale4,
  FontWeightScale5,
  Headings: {
    DashboardHeading,
    Header
  },
  PopUpModals: {
    Confirmation,
    Large,
    Small,
    Button: ButtonPopUpModal,
    Feedback: FeedbackModal,
    LargeModals: {
      Button: ButtonPopUpModalLarge,
      Link: LinkPopUpModalLarge
    },
    Prompt: PromptPopUpModal,
    Success: SuccessPopUpModal
  },
  SlidingModals: {
    Button: ButtonSlidingModal,
    Link: LinkSlidingModal
  },
  Text,
  TextGutterSmall,
  TextGutterMeduim,
  TextGutterLarge,
  TextGutterExtraLarge,
  UnorderedList
}
