import PropTypes from 'prop-types'

import ClientErrorRow from './ClientErrorRow'


const SkippedUl = props => {
  if (props.clients.length > 0) {
    return (
      <fieldset className='add-records'>
        <span>{props.description}</span>
        <ul className='ul-skipped'>
          {props.clients.map(client => <ClientErrorRow key={client.email} {...client} />)}
        </ul>
      </fieldset>
    )
  }

  return <span />
}

SkippedUl.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired
    })
  ).isRequired,
  description: PropTypes.string.isRequired
}

export default SkippedUl
