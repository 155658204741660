// This is the template for screens in the JICL Wizard flow that do not belong to
// the responses, assessments or guides.

import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {Fragment, useCallback, useRef} from 'react'
import classnames from 'classnames'

import {Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import Form from '../../../../../shared_components/forms/v2/Form'
import {TextGutterMedium} from '../../../../../dashboard/src/components/blocks/Texts'
import JiclFormField from './JiclFormField'
import JiclFormFooter from './JiclFormFooter'

import './jiclForm.scss'


const JiclForm = ({sectionConfig, onSubmit, className}) => {
  const header = sectionConfig.get('header')
  const notes = sectionConfig.get('notes')
  const fields = sectionConfig.get('fields')
  const buttonText = sectionConfig.get('buttonText')
  const footer = sectionConfig.get('footer')
  const formRef = useRef()

  const submitData = useCallback(() => {
    if (!fields.isEmpty()) {
      const form = formRef.current
      form.onSubmit()

      if (form.isValid())
        onSubmit(form.formData().map(field => field.get('value')))
    } else {
      onSubmit()
    }
  })

  return (
    <div className={classnames('jicl-form', className)}>
      <div className='section-title border-bottom'>{header}</div>
      <Fragment>
        {notes.map(note => (
          <TextGutterMedium key={note}>{note}</TextGutterMedium>
        ))}
      </Fragment>
      {fields ?
        (
          <Form className='core' ref={formRef}>
            {fields.map(formField => (
              <JiclFormField
                key={formField.get('legend')}
                formField={formField}
              />
            ))}
            <Accept onClick={submitData}>{buttonText}</Accept>
          </Form>
        ) :
        (
          <Accept onClick={submitData}>{buttonText}</Accept>
        )}
      <JiclFormFooter footer={footer} />
    </div>
  )
}


JiclForm.propTypes = {
  sectionConfig: PropTypes.instanceOf(Map),
  onSubmit: PropTypes.func,
  className: PropTypes.string
}

export default JiclForm
