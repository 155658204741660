import classnames from 'classnames'

import AppShell from './AppShell'

import Loader from '../NewLoader'
import routerUtils from '../../lib/routerUtils'

import './pendingRequestWrapper.scss'


// NOTE: when using this wrapper you must put your own <div className='central-content-box'> inside another Loader
// to prevent the white box in the center of the screen from showing in a weird state!
const PendingRequestWrapper = props => {
  const {children, ...otherProps} = props
  return (
    <AppShell className={classnames('pending-request', props.className)} useNewLoader={true} whitelist={props.whitelist}>
      <Loader loading={props.loading}>
        {routerUtils.childrenWithProps(children, otherProps)}
      </Loader>
    </AppShell>
  )
}

PendingRequestWrapper.defaultProps = {
  className: '',
  loading: true
}

export default PendingRequestWrapper
