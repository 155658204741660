import PropTypes from 'prop-types'

import {addPossessive} from '../../../../lib/tools'
import {Accept, Cancel} from '../../../../dashboard/src/components/blocks/Buttons'
import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import HowItWorks from './HowItWorks'
import PrivacyNotice from './PrivacyNotice'

const RespondToClientInvite = props => {
  const clientInvite = props.clientInvite
  const advisorName = clientInvite.advisor.full_name

  return (
    <div>
      <h1>Request from {advisorName}</h1>
      <div className='invite-overview column-wrapper'>
        <div className='row-wrapper'>
          {clientInvite.advisor.headshot && <img alt='advisor-headshot' className='headshot' src={clientInvite.advisor.headshot} />}
          <div className='column-wrapper'>
            <div className='subheading'>
              {advisorName} would like to sponsor your subscription on Everplans
              {clientInvite.is_household ? ` and merge your Everplan with ${addPossessive(clientInvite.other_household_owner.full_name)}.` : '.'}
            </div>
            <p>
              Connect your account today so that {advisorName} can provide guidance and help as you progress through your planning.
            </p>
          </div>
        </div>
        <ButtonGroup>
          <Cancel onClick={props.decline} processing={props.processingDecline}>No, thanks</Cancel>
          <Accept onClick={props.accept} processing={props.processingAccept}>Accept invitation</Accept>
        </ButtonGroup>
      </div>
      <div>
        <HowItWorks advisorFullName={advisorName} hasAccepted={false} clientInvite={clientInvite} />
        <PrivacyNotice
          advisorFullName={advisorName}
          isHousehold={clientInvite.is_household}
          otherHouseholdOwner={clientInvite.other_household_owner}
        />
      </div>
    </div>
  )
}


RespondToClientInvite.defaultProps = {
  clientInvite: {
    advisor: {
      full_name: '',
      headshot: null
    },
    is_household: false,
    other_household_owner: {
      first_name: '',
      full_name: ''
    }
  },
  showProfileForm: false
}

RespondToClientInvite.propTypes = {
  accept: PropTypes.func,
  decline: PropTypes.func,
  clientInvite: PropTypes.shape({
    advisor: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
      headshot: PropTypes.string
    }),
    is_household: PropTypes.bool.isRequired,
    other_household_owner: PropTypes.shape({
      full_name: PropTypes.string,
      first_name: PropTypes.string
    })
  }),
  processingAccept: PropTypes.bool,
  processingDecline: PropTypes.bool,
  showProfileForm: PropTypes.bool.isRequired
}

export default RespondToClientInvite
