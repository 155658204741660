/** @class RequestItemPermissionsContainer
 *
 * @desc
 * Container to display information about the status of requested item permissions and option to request if permitted.
 *
 * @todo Wire up the following with v2 resources:
 *.   - Check if the client being invited needs client approval
 */

import {Component} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {updateResource} from 'redux-json-api'

import RequestItemPermissionsStatus from './RequestItemPermissionsStatus'
import RequestItemPermissionsModal from '../../request_item_permissions/RequestItemPermissionsModal'

import {findRawResourceByAttributeId} from '../../../../../lib/plan_data/userData'
import {currentUserConfig} from '../../../../../lib/userTools'
import {hasPendingRequestPermissionsInvite, defaultRequestItemPermissionIds} from '../../../../../lib/itemPermissionTools'

import './requestItemPermissionsContainer.scss'

class RequestItemPermissionsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      savedItemPermissions: false,
      itemPermissionIds: defaultRequestItemPermissionIds({
        firmOwnership: props.firmOwnership,
        firmUserConfig: props.firmUserConfig,
        anyoneActive: props.anyoneActive
      })
    }

    this.saveItemPermissions = this.saveItemPermissions.bind(this)
  }

  linkText() {
    return this.state.savedItemPermissions ? 'View or modify permission to edit' : 'Choose sections to edit'
  }

  saveItemPermissions(checkedItemIds) {
    this.props.saveItemPermissions(checkedItemIds)

    this.setState({savedItemPermissions: true, itemPermissionIds: checkedItemIds})
  }


  render() {
    if (this.props.firmConfig.get('write-access') && !this.props.needsClientApproval) {
      const pendingInvite = hasPendingRequestPermissionsInvite(this.props.requestItemPermissionsInvite)

      return (
        <div className='request-item-permissions-container'>
          <RequestItemPermissionsStatus hasPendingItemPermissionsInvite={pendingInvite} />
          <RequestItemPermissionsModal
            {...this.props}
            itemPermissionIds={this.state.itemPermissionIds}
            linkText={this.linkText()}
            onConfirm={this.saveItemPermissions}
            showLink={!pendingInvite}
          />
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = ({api}, {clientSeat}) => ({
  firmConfig: findRawResourceByAttributeId({
    rawResource: api['firm-configs'],
    attribute: 'firm-id',
    id: clientSeat.get('firm-id')
  }),
  firmUserConfig: findRawResourceByAttributeId({
    rawResource: api['firm-user-configs'],
    attribute: 'user-id',
    id: currentUserConfig(api).get('id')
  }),
  firmOwnership: findRawResourceByAttributeId({
    rawResource: api['firm-ownerships'],
    attribute: 'everplan-id',
    id: clientSeat.get('everplan-id')
  }),
  requestItemPermissionsInvite: findRawResourceByAttributeId({
    rawResource: api['request-item-permissions-invites'],
    attribute: 'everplan-id',
    id: clientSeat.get('everplan-id')
  })
})


RequestItemPermissionsContainer.propTypes = {
  anyoneActive: PropTypes.bool,
  firmConfig: PropTypes.instanceOf(Map),
  firmOwnership: PropTypes.instanceOf(Map),
  firmUserConfig: PropTypes.instanceOf(Map),
  items: PropTypes.instanceOf(List),
  needsClientApproval: PropTypes.bool,
  requestItemPermissionsInvite: PropTypes.instanceOf(Map),
  saveItemPermissions: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default connect(mapStateToProps, {updateResource})(RequestItemPermissionsContainer)
