import {fromJS} from 'immutable'
import React from 'react'

import * as Buttons from '../../dashboard/src/components/blocks/Buttons'
import CheckboxList from '../../shared_components/forms/v2/fields/CheckboxList'
import Form from '../../shared_components/forms/v2/Form'
import {ModalHeaderBottomDivider} from '../../shared_components/core/modals/ModalHeaders'
import Modals from '../../shared_components/core/modals/Modals'
import VerticalRadioList from '../../shared_components/forms/v2/fields/VerticalRadioList'
import Text from '../../shared_components/forms/v2/fields/Text'
import TextArea from '../../shared_components/forms/v2/fields/TextArea'

import {onBlur, onFocus} from './lib/tools'
import validators from '../../shared_components/forms/v2/validators'


class NewFormsExample extends React.Component {
  constructor() {
    super()

    this.state = {
      errors: {},
      processing: false
    }

    this.submit = this.submit.bind(this)
  }
  submit() {
    this.setState({processing: true})
    this.refs.form.onSubmit()
    setTimeout( // Pretend we're sending off to the server for errors
      () => this.setState({
        errors: fromJS({'notes-id': ['Not enough text!']}),
        processing: false
      }),
      3000
    )
  }

  render() {
    return (
      <section>
        <h2>New Forms Example</h2>
        <Modals.ButtonPopUpModalLarge launcher={<Buttons.Button>Forms v2</Buttons.Button>}>
          <ModalHeaderBottomDivider heading='My Home Insurance' />
          <Form className='core' errors={this.state.errors} ref='form'>
            <Text
              data={
                fromJS({
                  id: 'insurance-company-id',
                  legend: 'Insurance company',
                  name: 'insurance-company',
                  value: 'Company default!'
                })
              }
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.required}
            />
            <CheckboxList
              data={
                fromJS({
                  id: 'insurance-company-type-id',
                  items: [
                    {label: 'Homeowners', value: 'Homeowners'},
                    {label: 'Renters', value: 'Renters'},
                    {label: 'Liability/Umbrella', value: 'Liability/Umbrella'},
                    {label: 'Mortgage', value: 'Mortgage'},
                    {label: 'Flood', value: 'Flood'},
                    {label: 'Earthquake', value: 'Earthquake'},
                    {label: 'Tornado', value: 'Tornado'},
                    {label: 'Other', value: 'Other'}
                  ],
                  legend: 'Insurance Company Type',
                  name: 'insurance-company-type'
                })
              }
              validator={validators.requiredImmutableList}
            />
            <Text
              data={
                fromJS({
                  id: 'policy-number-id',
                  legend: 'Policy number',
                  name: 'policy-number'
                })
              }
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.required}
            />
            <Text
              data={
                fromJS({
                  id: 'policy-start-date-id',
                  legend: 'Policy start date',
                  name: 'policy-start-date'
                })
              }
              maxLength='14'
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.required}
            />
            <Text
              data={
                fromJS({
                  id: 'policy-expiration-date-id',
                  legend: 'Policy expiration date',
                  name: 'policy-expiration-date'
                })
              }
              maxLength='14'
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.required}
            />
            <Text
              data={
                fromJS({
                  id: 'value-of-death-benefit-id',
                  legend: 'Value of death benefit',
                  name: 'value-death-benefit'
                })
              }
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.required}
            />
            <VerticalRadioList
              data={
                fromJS({
                  id: 'policy-id',
                  items: [
                    {label: 'Yes', value: 'yes'},
                    {label: 'No', value: 'no'}
                  ],
                  legend: 'Is your policy a long-term (LTC) or Chronic Illness Rider?',
                  name: 'policy'
                })
              }
              validator={validators.required}
            />
            <Text
              data={
                fromJS({
                  id: 'location-of-original-id',
                  legend: 'Location of original',
                  name: 'location-original-policy'
                })
              }
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.required}
            />
            <TextArea
              data={
                fromJS({
                  id: 'notes-id',
                  legend: 'Notes',
                  name: 'notes',
                  placeholder: 'Notes'
                })
              }
              validator={validators.required}
            />
          </Form>
          <Buttons.Accept onClick={this.submit} processing={this.state.processing} />
        </Modals.ButtonPopUpModalLarge>
      </section>
    )
  }
}

export default NewFormsExample

