import moment from 'moment'
import PropTypes from 'prop-types'

import SelectFilter from '../../shared_components/SelectFilter'
import SummariesTable from '../../shared_components/summaries_table/SummariesTable'

import {formatDate} from '../../../../../lib/dateTools'

const licenseExpiration = firmSummary => (
  firmSummary.status === 'active' ? formatDate(firmSummary['license-expiration-date']) : 'Downgraded'
)

const organizationName = (firmSummary, organizations) => {
  const firmOrganization = organizations.data.find(organization => organization.id === firmSummary['organization-id'])
  return firmOrganization ? firmOrganization.name : ''
}

const calculateLicenseStatus = ({original}) => {
  const licenseDate = moment(original['license-expiration-date'])

  if (original.status !== 'active' || licenseDate.isBefore()) // checks if the date is before todays date
    return 'expired'
  if (licenseDate.isBetween(moment(), moment().days(30)))
    return 'expiring' // 1 month || 30 days

  return ''
}


const FirmSummariesTable = props => {
  const columns = [
    {
      Header: 'Firm Id',
      accessor: 'id',
      minWidth: 60,
      filterable: true
    },
    {
      Header: 'Firm',
      accessor: 'name',
      minWidth: 160,
      filterable: true
    },
    {
      Header: 'Active Clients',
      accessor: 'active-client-count',
      minWidth: 90
    },
    {
      Header: 'Pending Clients',
      accessor: 'pending-client-count',
      minWidth: 90
    },
    {
      Header: 'Advisor Seats Purchased',
      accessor: 'advisor-seats',
      minWidth: 90
    },
    {
      Header: 'Assistant Seats Purchased',
      accessor: 'assistant-seats',
      minWidth: 90
    },
    {
      id: 'status',
      Header: 'License Expiration',
      accessor: firmSummary => licenseExpiration(firmSummary),
      filterable: true,
      minWidth: 110,
      Cell: columnProps => <span className={calculateLicenseStatus(columnProps)}>{columnProps.value}</span>,
      Filter: columnProps => (
        <SelectFilter
          {...columnProps}
          options={[{name: 'Active', id: '0'}, {name: 'Downgraded', id: '1'}]}
        />
      )
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      minWidth: 90
    },
    {
      id: 'last-login-date',
      Header: 'Most Recent Sign In',
      accessor: firmSummary => formatDate(firmSummary['last-login-date'])
    },
    {
      id: 'organization-id',
      Header: 'Organization',
      accessor: firmSummary => organizationName(firmSummary, props.organizations),
      filterable: true,
      minWidth: 130,
      Filter: columnProps => <SelectFilter {...columnProps} options={props.organizations.data} />
    },
    {
      id: 'internal',
      Header: 'Internal',
      accessor: firmSummary => (firmSummary.internal ? 'Internal' : ''),
      minWidth: 90,
      filterable: true,
      Filter: columnProps => (
        <SelectFilter
          {...columnProps}
          options={[{name: 'Internal', id: 'true'}, {name: 'Not Internal', id: 'false'}]}
        />
      )
    }
  ]

  return (
    <SummariesTable
      {...props}
      data={props.firmSummaries}
      columns={columns}
      loading={props.loadingFirms || props.loadingOrganizations}
    />
  )
}

FirmSummariesTable.propTypes = {
  firmSummaries: PropTypes.array,
  loadingFirms: PropTypes.bool,
  loadingOrganizations: PropTypes.bool,
  onFetchData: PropTypes.func,
  organizations: PropTypes.object,
  onRowClick: PropTypes.func,
  pages: PropTypes.number
}

export default FirmSummariesTable
