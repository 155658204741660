import PropTypes from 'prop-types'
import {Map} from 'immutable'

import JiclPreviewResponseGroupContainer from './sections/JiclPreviewResponseGroupContainer'
import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import JiclContactInformation from './sections/JiclContactInformation'
import JiclAdditionalInformation from './sections/JiclAdditionalInformation'
import JiclSendToRecipientsModal from './sections/JiclSendToRecipientsModal'
import JiclHeader from '../JiclHeader'
import JiclUserViewBanner from '../preview/sections/JiclUserViewBanner'
import JiclUserUpgradeBanner from '../upgrade/JiclUserUpgradeBanner'

import {SECTION_ORDER} from '../../lib/jiclMainSectionConfiguration'
import './jiclPreview.scss'

const JiclPreview = ({
  openModal,
  closeModal,
  showModal,
  onListSent,
  createResource,
  jiclConfig,
  ...otherProps
}) => (
  <div className='jicl-preview'>
    <JiclSendToRecipientsModal
      closeModal={closeModal}
      showModal={showModal}
      onListSent={onListSent}
      createResource={createResource}
    />
    <JiclUserUpgradeBanner />
    <JiclHeader />
    <div className='preview-title'> Review and Send Your List </div>
    <JiclContactInformation
      userName={otherProps.user.get('first-name')}
      lastUpdatedAt={jiclConfig.get('updated-at')}
    />
    {SECTION_ORDER.map(section => (
      <JiclPreviewResponseGroupContainer
        {...otherProps}
        key={section}
        section={section}
      />
    ))}
    <JiclAdditionalInformation
      jiclConfig={jiclConfig}
      user={otherProps.user}
      updateResource={otherProps.updateResource}
    />
    <div className='button-container'>
      <Accept onClick={openModal}>Send My Just-In-Case List</Accept>
    </div>
    <JiclUserViewBanner />
  </div>
)

JiclPreview.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  onListSent: PropTypes.func,
  createResource: PropTypes.func,
  jiclConfig: PropTypes.instanceOf(Map)
}

export default JiclPreview
