import {Map} from 'immutable'
import PropTypes from 'prop-types'

import DeputyFeedbackForm from './DeputyFeedbackForm'
import DeputyFeedbackHeader from './DeputyFeedbackHeader'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'

import thumbsUp from '../../../../shared_assets/thumbs_up.png'

import './deputyFeedback.scss'

const DeputyFeedback = props => (
  <div className='deputy-feedback'>
    <DeputyFeedbackHeader />
    <img className='thumbs-up' src={thumbsUp} alt='thumbs-up' />
    <TextGutterMedium className='prompt'>
      Thanks for sending {props.deputyFeedback.get('planholder-name')} encouragement! Would you like to add a message?
    </TextGutterMedium>
    <DeputyFeedbackForm {...props} />
  </div>
)

DeputyFeedback.propTypes = {deputyFeedback: PropTypes.instanceOf(Map)}

export default DeputyFeedback
