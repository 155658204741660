import PropTypes from 'prop-types'
import {Map} from 'immutable'

import UnorderedList from '../../../../../shared_components/UnorderedList'

import './requestItemPermissionInviteIntroductoryMessage.scss'


const RequestItemPermissionInviteIntroductoryMessage = ({advisorContactCard}) => (
  <div className='request-item-permission-invite-introductory-message row-wrapper'>
    {
      advisorContactCard.get('avatar-url') &&
      <img alt={`${advisorContactCard.get('name')}'s head shot`} src={advisorContactCard.get('avatar-url')} />
    }
    <div className='permission-details'>
      <h4 className='heading-4'>
        {advisorContactCard.get('name')} would like permission to edit the sections of your Everplan shown below.
      </h4>
      <p>
        By granting permission for a section, you allow {advisorContactCard.get('name')} to do
        the following on your behalf:
      </p>
      <UnorderedList
        children={[
          'Edit, view, and download the contents of that section',
          'Add, remove and download attached documents within that section'
        ]}
      />
    </div>
  </div>
)

RequestItemPermissionInviteIntroductoryMessage.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map)
}

export default RequestItemPermissionInviteIntroductoryMessage
