// Note: This is an ES6 updated version of the CustomContent/ImageUpload.react.
// TODO: Turn this into a shared/Forms Component
import {Component} from 'react'
import PropTypes from 'prop-types'
import fileWhitelist from '../../../../lib/file_whitelist'

export default class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image_url: null,
      errors: ''
    }
    this.handleFile = this.handleFile.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit(event) {
    event.preventDefault()
  }
  handleFile(event) {
    this.handleSubmit(event)
    var reader = new FileReader()
    var file = event.target.files[0];
    var ext = file.name.split('.').pop().toLowerCase()
    if (fileWhitelist.includes(ext)) {
      reader.onload = function(upload) {
        this.setState({
          image_url: upload.target.result,
          error: ''
        })
        this.props.onImageLoad(upload.target.result)
      }.bind(this)

      reader.readAsDataURL(event.target.files[0])
    } else {
      this.setState({error: 'Invalid file format.'})
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} encType='multipart/form-data'>
        <div className='btn-file'>
          {this.props.children}
          <input type='file' onChange={this.handleFile} className='btn-file-input' />
          <p className='form-errors'>{this.state.error}</p>
        </div>
      </form>
    )
  }
}

ImageUpload.propTypes = {
  onImageLoad: PropTypes.func
}
