import checklist from '../../../shared_assets/checklist.png'
import './jiclHeader.scss'

const JiclHeader = () => (
  <header className='jicl-header flex-container'>
    <img src={checklist} />
    <div className='header-text'>Just-In-Case List</div>
  </header>
)

export default JiclHeader
