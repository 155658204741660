import PropTypes from 'prop-types'
import {Component} from 'react'

import EmailPreview from './EmailPreview'

import './clientInvitesPreview.scss'


export default class ClientInvitesPreview extends Component {
  render() {
    if (!this.props.emailBodyTexts[0] || this.props.hidden)
      return null

    return (
      <div className='client-invites-preview'>
        <EmailPreview
          buttonText={this.props.emailBodyTexts[0].button_text}
          contactCard={this.props.contactCard}
          emailText={this.props.emailBodyTexts[0].email_text}
          disclosure={this.props.disclosure}
        />
        <p className='existing-everplans-account-note'>
          Note: Some of your invitees may see a variation on the text shown above. If an invitee already has a personal
          subscription to Everplans, they'll be prompted to connect their account with your firm.
        </p>
      </div>
    )
  }
}

ClientInvitesPreview.propTypes = {
  contactCard: PropTypes.object.isRequired,
  emailBodyTexts: PropTypes.arrayOf(PropTypes.shape({
    button_text: PropTypes.string.isRequired,
    email_text: PropTypes.string.isRequired
  }).isRequired).isRequired,
  hidden: PropTypes.bool.isRequired,
  disclosure: PropTypes.shape({
    enabled: PropTypes.bool,
    markdown_text: PropTypes.string,
    show_in_email: PropTypes.bool
  })
}

ClientInvitesPreview.defaultProps = {
  emailBodyTexts: [{}],
  hidden: true
}
