import PropTypes from 'prop-types'

import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'

const DeputyInviteCustomInviteText = ({customInviteText, fullName}) => {
  if (customInviteText) {
    return (
      <div className='preview-body'>
        <TextGutterMedium className='section-header'>Message from {fullName}:</TextGutterMedium>
        <TextGutterMedium className='custom-invite-text'>
          {customInviteText}
        </TextGutterMedium>
      </div>
    )
  } else {
    return null
  }
}

DeputyInviteCustomInviteText.propTypes = {
  customInviteText: PropTypes.string,
  fullName: PropTypes.string
}

export default DeputyInviteCustomInviteText
