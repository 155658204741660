import assign from 'object-assign'
import KeyMirror from 'keymirror'

import ActionPrototype from '../../../shared_components/ActionPrototype'

const InviteSettingsActions = assign({}, ActionPrototype, {
  Types: KeyMirror({
    GOT_INVITE_SETTINGS: null
  }),
  fetchInviteSettings(advisorSeatId, clientSeatId) {
    const settingsPath = clientSeatId ? `clients/invites/settings/${advisorSeatId}/${clientSeatId}` : `clients/invites/settings/${advisorSeatId}`
    this.fireApi(
      'get',
      settingsPath,
      null,
      {JSONHead: 'settings', successAction: this.Types.GOT_INVITE_SETTINGS}
    )
  }
})

export default InviteSettingsActions
