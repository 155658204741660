import {fromJS, List, Map} from 'immutable'
import {useRef, useCallback, useState} from 'react'
import PropTypes from 'prop-types'

import Form from '../../../../shared_components/forms/v2/Form'
import Fields from '../../../../shared_components/forms/v2/fields'
import validators from '../../../../shared_components/forms/v2/validators'
import * as Buttons from '../../../../dashboard/src/components/blocks/Buttons'

import InviteClientNotification from './InviteClientNotification'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'
import {selectedFirmSeatId} from '../../../../lib/proTools'

import './addHouseholdClients.scss'
import SelectAdvisor from './SelectAdvisor'

const AddHouseholdClients = props => {
  const formRef = useRef()
  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState(List())
  const {alwaysNotify, dismissToastNotification} = useNotificationContext()

  const clientInfo = formData => {
    const firstClientName = formData.getIn(['first-household-name', 'value'])
    const secondClientName = formData.getIn(['second-household-name', 'value'])

    return {
      'client-data': [
        {
          email: formData.getIn(['first-household-email', 'value']),
          'first-name': firstClientName.get('first-name'),
          'last-name': firstClientName.get('last-name')
        },
        {
          email: formData.getIn(['second-household-email', 'value']),
          'first-name': secondClientName.get('first-name'),
          'last-name': secondClientName.get('last-name')
        }
      ],
      'advisor-seat-id': selectedFirmSeatId({
        name: formData.getIn(['advisor', 'value']),
        firmSeats: props.firmSeats
      })
    }
  }

  const showSuccessNotification = client => {
    alwaysNotify.longSuccess(
      <InviteClientNotification
        count={2}
        dismissNotification={dismissToastNotification}
        isHousehold={true}
        message='Your household members have been added.'
        recipients={[{client_seat_id: client['client-seat-ids'][0]}]}
        firm={props.firm}
      />
    )
  }

  const isEmailUnique = formData => {
    const firstEmail = formData.getIn(['first-household-email', 'value'])
    const secondEmail = formData.getIn(['second-household-email', 'value'])

    return firstEmail !== secondEmail
  }

  const submitForm = () => {
    const form = formRef.current
    const formData = form.onSubmit()

    if (formData) {
      if (!isEmailUnique(formData))
        return setErrors(List(['Emails for each member must be unique']))

      setProcessing(true)
      const payload = {type: 'clients', attributes: clientInfo(formData)}

      props
        .createResource(payload)
        .then(response => {
          props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)
          showSuccessNotification(response.data.attributes)
          props.closer()
        })
        .catch(err => {
          setProcessing(false)
          setErrors(List(err.response.data.errors.map(error => error.title)))
        })
    }
  }

  const clearServerErrors = useCallback(() => {
    if (!processing || !errors.isEmpty()) setErrors(List())
  }, [processing, errors])

  return (
    <div className='add-household-clients forms-playground'>
      <Form
        onChange={clearServerErrors}
        className='core'
        ref={formRef}
        showFormLevelError={!errors.isEmpty()}
        invalidFormError={errors}>
        <div className='first-household-member'>
          <TextGutterMedium>Household member 1</TextGutterMedium>
          <Fields.Name
            data={fromJS({
              id: 'first-household-name',
              name: 'first-household-name'
            })}
            validator={validators.requiredNameFirstLastValidator}
          />
          <Fields.Email
            data={Map({
              id: 'first-household-email',
              name: 'first-household-email',
              legend: 'Email'
            })}
            validator={validators.requiredCustomFieldNameCallback('email')}
          />
        </div>
        <div className='second-household-member'>
          <TextGutterMedium>Household member 2</TextGutterMedium>
          <Fields.Name
            data={fromJS({
              id: 'second-household-name',
              name: 'second-household-name'
            })}
            validator={validators.requiredNameFirstLastValidator}
          />
          <Fields.Email
            data={Map({
              id: 'second-household-email',
              name: 'second-household-email',
              legend: 'Email'
            })}
            validator={validators.requiredCustomFieldNameCallback('email')}
          />
        </div>
        <SelectAdvisor
          userConfig={props.userConfig}
          firmSeats={props.firmSeats}
          advisorSeatId={props.advisorSeatId}
        />
      </Form>
      <ButtonGroup>
        <Buttons.Cancel onClick={props.closer} />
        <Buttons.Accept onClick={submitForm} processing={processing}>
          Add
        </Buttons.Accept>
      </ButtonGroup>
    </div>
  )
}

AddHouseholdClients.propTypes = {
  advisorSeatId: PropTypes.number,
  closer: PropTypes.func,
  createResource: PropTypes.func,
  readEndpoint: PropTypes.func,
  clientSeats: PropTypes.instanceOf(List),
  firm: PropTypes.instanceOf(Map),
  firmSeats: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map)
}

export default AddHouseholdClients
