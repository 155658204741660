import {Map} from 'immutable'
import PropTypes from 'prop-types'

import EditFirmUserEmail from '../firm_admin/EditFirmUserEmail'

const UserInformation = props => {
  const role = props.type === 'AssistantSeat' ? 'Assistant' : props.firmConfig.professional_designation
  return (
    <div className='column-wrapper-sb user-information'>
      <div className='row-wrapper'>
        <span>Name:</span>{props.name}
      </div>
      <div className='row-wrapper'>
        <span>Email:</span>{props.email}
        { /* Only displays change email link for Account Managers if the User and Seat are both draft */
          props.draft_user && props.accountManager && props.status === 'draft' &&
          <EditFirmUserEmail params={props.params} email={props.email} />
        }
      </div>
      <div className='row-wrapper'>
        <span>Role:</span>{role}
      </div>
      <div className='row-wrapper'>
        <span>External ID:</span>{props.external_id}
      </div>
    </div>
  )
}

UserInformation.propTypes = {
  email: PropTypes.string.isRequired,
  firmConfig: PropTypes.instanceOf(Map),
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['AdvisorSeat', 'AssistantSeat']).isRequired,
  externalId: PropTypes.string
}

export default UserInformation
