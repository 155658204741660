import PropTypes from 'prop-types'

import {ControlButton} from '../../../../../shared_components/ButtonTypes'
import Modal from '../../../../../shared_components/Modal'
import ShadowWrapper from '../../shared_firm/ShadowWrapper'

import './settingsContainer.scss'

const SettingsContainer = ({data, enabledText, disabledText, children, flavor, showModal, toggleModal}) => {
  const enabledTitle = flavor === 'invite-text' ? 'Custom' : 'Enabled'
  const disabledTitle = flavor === 'invite-text' ? 'Everplans Standard' : 'Disabled'

  return (
    <ShadowWrapper>
      <div className='settings-container forms-playground flex-container'>
        <p>Status:</p>
        <div className='details'>
          <div className='status'>{data ? enabledTitle : disabledTitle}</div>
          <div className='description'>{data ? enabledText : disabledText}</div>
          <div className='edit-config'>
            <ControlButton faClass='fa-pencil-square-o' onClick={toggleModal} buttonText='Edit' />
            <Modal closer={toggleModal} visible={showModal}>
              {children}
            </Modal>
          </div>
        </div>
      </div>
    </ShadowWrapper>
  )
}

SettingsContainer.propTypes = {
  data: PropTypes.oneOf([PropTypes.bool, PropTypes.object]),
  enabledText: PropTypes.string,
  disabledText: PropTypes.string,
  toggleModal: PropTypes.func,
  flavor: PropTypes.string,
  showModal: PropTypes.bool
}

export default SettingsContainer
