import PropTypes from 'prop-types'
import React from 'react'

import Container from '../../../../lib/Container'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import OAuth2Actions from '../../actions/OAuth2Actions'
import routerUtils from '../../../../lib/routerUtils'
import SessionApi from '../../web/SessionApi'
import SessionStore from '../../stores/SessionStore'
import sessionUtils from '../../lib/sessionUtils'
import storePrototype from '../../../../shared_components/StorePrototype'

Container.registerStore('oauth', storePrototype(OAuth2Actions.Types.SIGNED_IN))

export default class Oauth2SSO extends React.Component {
  constructor() {
    super()

    this.state = {loading: true, errorMessage: null}
    this.store = Container.getStore('oauth')
    this.onUpdate = this.onUpdate.bind(this)
    this.handleNextCurrentUserRoute = this.handleNextCurrentUserRoute.bind(this)
  }
  componentDidMount() {
    const token = this.props.params.token
    this.store.addChangeListener(this.onUpdate)
    OAuth2Actions.signIn({token})
    SessionStore.addChangeListener(this.handleNextCurrentUserRoute)
  }

  componentWillUnmount() { SessionStore.removeChangeListener(this.handleNextCurrentUserRoute) }

  onUpdate() {
    const storeState = this.store.getState()
    if (storeState.errors.length > 0) {
      this.setState({loading: false, error_message: 'Unexpected SSO error, please try again.'})
    } else {
      const {nextRoute} = this.props.location.query

      SessionApi._setToken(storeState.data.session_token)
      SessionApi.fetchCurrentUser()

      if (nextRoute)
        routerUtils.push(nextRoute)
    }
  }

  handleNextCurrentUserRoute() {
    // don't attempt to send the user to next route if SSO failed
    if (!this.store.getState().errors.length)
      sessionUtils.sendUserToNextRoute(SessionStore.getState().currentUser)
  }

  render() {
    return <div><LegacyLoader loading={this.state.loading} />{this.state.errorMessage}</div>
  }
}

Oauth2SSO.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      nextRoute: PropTypes.string,
      token: PropTypes.string
    })
  }),
  params: PropTypes.shape({token: PropTypes.string})
}
