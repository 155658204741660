import PropTypes from 'prop-types'
import {Component} from 'react'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import Container from '../../../../lib/Container'
import modalWrapper from '../../../../shared_components/ModalWrapper'
import storePrototype from '../../../../shared_components/StorePrototype'
import FirmForm from '../../components/firm_admin/FirmForm'

import FirmActions from '../../actions/FirmActions'

import './editFirm.scss'

Container.registerStore('updated-firm', storePrototype(FirmActions.Types.DID_UPDATE_FIRM))

export class EditFirm extends Component {
  constructor() {
    super()

    this.editFirm = this.editFirm.bind(this)
  }
  editFirm(data) {
    this.props.updating(() => this.context.closeModal())
    FirmActions.editFirm({firmId: this.props.firmId, ...data})
    this.props.finished()
  }

  render() {
    return (
      <div className='edit-firm'>
        <a href='#' onClick={this.context.showModal}>Edit</a>
        <FirmForm
          actionText='Save'
          closeModal={this.context.closeModal}
          loadingOrganizations={this.props.loadingOrganizations}
          modalheaderText='Edit Firm'
          submit={this.editFirm}
          showModal={this.context.showModal}
          toggleModal={this.toggleModal}
          processing={this.props.processing}
          {...this.props}
        />
      </div>
    )
  }
}

EditFirm.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

EditFirm.defaultProps = {
  errors: [],
  showModal: false,
  organizations: {
    data: []
  }
}

EditFirm.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  firmId: PropTypes.string.isRequired,
  firmName: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  finished: PropTypes.func,
  updating: PropTypes.func,
  loadingOrganizations: PropTypes.bool,
  organizations: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object)
  }),
  organizationId: PropTypes.string,
  processing: PropTypes.bool
}

export default modalWrapper(bindUpdate(EditFirm, 'updated-firm', 'firm_name'))
