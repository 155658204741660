/*
  The purpose of this wrapper it to house a collection of horizontal radio lists that share the same labels.
  Required  `props` are `legend` and it's children (`HorizontalRadioList` component(s)).
  An example would be listing the Deputy permissions form. --KTW
*/
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './radioCollection.scss'


const RadioCollection = ({legend, children, className}) => (
  <div className={classnames('radio-collection', className)}>
    <legend className='radio-collection-heading'>{legend}</legend>
    {children}
  </div>
)

RadioCollection.propTypes = {legend: PropTypes.string}

RadioCollection.defaultProps = {legend: ''}

export default RadioCollection
