import assign from 'object-assign'
import KeyMirror from 'keymirror'

import ActionPrototype from '../../../shared_components/ActionPrototype'
import AppDispatcher from '../../../lib/ep-dispatcher'
import jsonStatham from '../../../lib/jsonStatham'

const ConfirmActions = assign({}, ActionPrototype, {
  Types: KeyMirror({
    DID_CONFIRM_DEPUTY: null,
    GOT_DEPUTY: null
  }),
  confirmDeputy(data) {
    jsonStatham.post('deputies/confirm/', {deputy: data})
      .done(response => {
        AppDispatcher.dispatch({
          actionType: ConfirmActions.Types.DID_CONFIRM_DEPUTY,
          data: response
        })
      })
      .fail(response => {
        AppDispatcher.dispatch({
          actionType: ConfirmActions.Types.DID_CONFIRM_DEPUTY,
          data: {errors: response.errors}
        })
      })
  },
  fetchDeputy(deputyId) {
    jsonStatham.get(`deputies/${deputyId}/invite_info`, {})
      .done(data => {
        AppDispatcher.dispatch({
          actionType: ConfirmActions.Types.GOT_DEPUTY,
          data: (data.invite ? data.invite : data)
        })
      })
      .fail(response => {
        AppDispatcher.dispatch({
          actionType: ConfirmActions.Types.GOT_DEPUTY,
          data: {errors: response.errors}
        })
      })
  }
})

export default ConfirmActions
