import {useState} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import {readEndpoint, updateResource} from 'redux-json-api'
import PropTypes from 'prop-types'

import OrganizationDefaultInviteText from './OrganizationDefaultInviteText'
import EditCatchAllFirm from './EditCatchAllFirm'
import EditContactCardLogo from './EditContactCardLogo'
import EditDomainRestriction from './EditDomainRestriction'
import EditMandatoryTfa from './EditMandatoryTfa'
import EditOrganizationLogo from './EditOrganizationLogo'
import ShadowHeader from '../../shared_firm/ShadowHeader'

import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import {findRawResourceByAttributeValue} from '../../../../../lib/plan_data/userData'

export const OrganizationConfig = props => {
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const fetchOrganizationAndNotify = () => {
    props.readEndpoint(`organizations/${props.organization.get('id')}?include=organization-config`).then(() => {
      alwaysNotify.shortSuccess('Organization setting has been updated.')
    })
  }

  const updateConfig = (resource, callback) => {
    props.updateResource(resource).then(() => {
      setProcessing(false)
      fetchOrganizationAndNotify()
      callback()
    }).catch(error => {
      setProcessing(false)
      callback(error)
    })
  }
  const updateSettings = (data, callback) => {
    setProcessing(true)

    const resource = {
      type: 'organizations',
      id: props.organization.get('id'),
      attributes: data
    }

    updateConfig(resource, callback)
  }


  const updateConfiguration = (data, callback) => {
    setProcessing(true)

    const resource = {
      type: 'organization-configs',
      id: props.organizationConfig.get('id'),
      attributes: data
    }

    updateConfig(resource, callback)
  }

  const sharedProps = {
    ...props,
    processing,
    updateSettings
  }
  return (
    <div>
      <ShadowHeader headerText='Organization Logo' />
      <EditOrganizationLogo {...sharedProps} />
      <ShadowHeader headerText='Contact Card Default Image' />
      <EditContactCardLogo {...sharedProps} />
      <ShadowHeader headerText='Catch All Firm' />
      <EditCatchAllFirm {...sharedProps} />
      <ShadowHeader headerText='Domain Restriction' />
      <EditDomainRestriction {...sharedProps} />
      <OrganizationDefaultInviteText {...sharedProps} />
      <ShadowHeader headerText='Mandatory Two-Factor Authentication for Clients' />
      <EditMandatoryTfa {...props} processing={processing} updateSettings={updateConfiguration} />
    </div>
  )
}

OrganizationConfig.propTypes = {
  organization: PropTypes.instanceOf(Map),
  organizationConfig: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  updateResource: PropTypes.func
}

const mapStateToProps = ({api}, ownProps) => {
  const organizationConfig = findRawResourceByAttributeValue({
    attribute: 'organization-id',
    value: ownProps.params.organizationId,
    rawResource: api['organization-configs']
  })

  return {organizationConfig}
}

export default connect(mapStateToProps, {readEndpoint, updateResource})(OrganizationConfig)
