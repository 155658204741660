import PropTypes from 'prop-types'
import {Component} from 'react'

import * as ButtonTypes from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import Modal from '../../../../shared_components/Modal'
import NewLoader from '../../../../shared_components/NewLoader'

export default class FirmForm extends Component {
  constructor() {
    super()

    this.submit = this.submit.bind(this)
  }

  submit() {
    if (this.refs.form.validate().length === 0)
      this.props.submit(this.refs.form.formData())
  }

  render() {
    return (
      <Modal closer={this.props.toggleModal} visible={this.props.showModal}>
        <div className='modal-header'>{this.props.modalheaderText}</div>
        <NewLoader loading={this.props.loadingOrganizations}>
          <Forms.Form ref='form' errors={[{name: 'firm_name', errors: this.props.errors}]}>
            <Forms.Input
              name='firm_name'
              label='Firm Name:'
              validator={Forms.Validators.required}
              defaultValue={this.props.firmName}
            />
            <Forms.Select
              defaultValue={this.props.organizationId}
              name='organization_id'
              label='Organization'
              list={this.props.organizations.data.map(organization => ({key: organization.id, value: organization.name}))}
              placeholder='No Organization'
            />
            <Forms.Checkbox
              defaultValue={this.props.internal}
              label='Internal test firm'
              name='internal'
              className='internal'
              value={this.props.internal}
            />
            {
              this.props.actionText === 'Add' &&
              <Forms.Checkbox
                className='legacy-firm'
                label='Legacy Firm'
                name='legacy_firm'
              />
            }
          </Forms.Form>
        </NewLoader>
        <div className='button-group'>
          <ButtonTypes.Cancel onClick={this.props.closeModal} />
          <ButtonTypes.Continue loading={this.props.processing} onClick={this.submit}>{this.props.actionText}</ButtonTypes.Continue>
        </div>
      </Modal>
    )
  }
}

FirmForm.propTypes = {
  actionText: PropTypes.string,
  closeModal: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  firmName: PropTypes.string,
  modalheaderText: PropTypes.string,
  internal: PropTypes.bool,
  loadingOrganizations: PropTypes.bool,
  organizations: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object)
  }),
  organizationId: PropTypes.string,
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  submit: PropTypes.func,
  toggleModal: PropTypes.func
}

FirmForm.defaultProps = {
  modalheaderText: 'Add Firm'
}
