import {Component} from 'react'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'
import PropTypes from 'prop-types'

import {isWhitelistedDomain} from '../../../../lib/urlTools'
import {generateSessionId} from '../../../../lib/tools'
import Loader from '../../../../shared_components/NewLoader'
import {parseUtmParams} from '../../../../lib/partner'
import routerUtils from '../../../../lib/routerUtils'

export class RelayController extends Component {
  componentDidMount() {
    const parsedUtms = parseUtmParams(this.props.location.query)
    delete parsedUtms.ep_ref_data // ep_ref_data does not need be part of utm params - Atanda
    const resource = {
      type: 'utms',
      attributes: {utm: {...parsedUtms, session_id: generateSessionId()}}
    }
    const {nextPath} = this.props.location.query

    this.props.createResource(resource).then(response => {
      if (nextPath && isWhitelistedDomain(nextPath))
        routerUtils.setLocation(nextPath)
      else
        routerUtils.push('/#/auth/sign_in')
    })
  }

  render() {
    return <Loader loading={this.props.processing} />
  }
}

RelayController.propTypes = {
  createResource: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.object
  }),
  processing: PropTypes.bool
}

const mapStateToProps = ({api}) => ({processing: api.isCreating === 1})

export default connect(mapStateToProps, {createResource})(RelayController)
