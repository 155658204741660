import PropTypes from 'prop-types'
import {Map} from 'immutable'

import ChangeAdvisorModal from '../change_advisor/ChangeAdvisorModal'

const HouseholdHeading = props => (
  <span className='household-heading row-wrapper-sb'>
    <h1>{props.name}</h1>
    <span>
      <strong>{props.firmConfig.get('professional-designation')}:</strong> {props.advisor.name}
      <ChangeAdvisorModal
        activeAdvisorCount={props.advisor.active_advisor_count}
        anyOwnersActive={props.isAnyEverplanUserActive}
        clientSeatId={props.params.clientSeatId.toString()}
        name={props.name}
        firmConfig={props.firmConfig}
      />
    </span>
  </span>
)

HouseholdHeading.propTypes = {
  advisor: PropTypes.shape({
    active_advisor_count: PropTypes.number,
    name: PropTypes.string
  }),
  all_owner_names: PropTypes.string,
  firmConfig: PropTypes.instanceOf(Map),
  isAnyEverplanUserActive: PropTypes.bool,
  name: PropTypes.string,
  params: PropTypes.shape({
    clientSeatId: PropTypes.string.isRequired
  })
}

export default HouseholdHeading
