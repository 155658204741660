import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'

import Modal from '../../../../shared_components/Modal'

import bindResources from '../../../../shared_components/BindToStores'
import BulkInviteActions from '../../actions/BulkInviteActions'
import Container from '../../../../lib/Container'
import routerUtils from '../../../../lib/routerUtils'
import SessionStore from '../../../../auth/src/stores/SessionStore'
import wrapModal from '../../../../shared_components/ModalWrapper'

import {userFirmOrganization} from '../../../../lib/userTools'

import './bulkResend.scss'

export class BulkSendOrResendController extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      advisor: {},
      currentUser: SessionStore.getState().currentUser,
      recipients: []
    }
    this.closeModal = this.closeModal.bind(this)
    this.next = this.next.bind(this)
  }
  componentDidMount() {
    this.context.showModal()
  }
  next(advisor, recipients) {
    this.setState({
      advisor,
      recipients
    })
    routerUtils.push('pro/clients/bulk_actions/customize')
  }
  closeModal(event) {
    if (event)
      event.preventDefault()
    this.context.closeModal()
    routerUtils.push('pro/clients')
  }

  render() {
    return (
      <div className='bulk-resend'>
        <Modal visible={this.props.showModal} closer={this.closeModal}>
          {
            routerUtils.childrenWithProps(
              this.props.children,
              {
                advisor: this.state.advisor,
                advisorSeats: this.props.advisor_seats.data,
                closer: this.closeModal,
                firmConfig: this.props.firmConfig,
                loadingAdvisorSeats: this.props.loadingAdvisorSeats,
                next: this.next,
                organization: this.props.organization,
                recipients: this.state.recipients,
                showModal: this.context.showModal
              }
            )
          }
        </Modal>
      </div>
    )
  }
}

BulkSendOrResendController.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

BulkSendOrResendController.defaultProps = {
  advisor_seats: {data: []},
  loading: true
}

BulkSendOrResendController.propTypes = {
  advisor_seats: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        status: PropTypes.oneOf(['ACTIVE', 'DRAFT', 'PENDING']),
        client_counts: PropTypes.shape({
          active: PropTypes.number,
          draft: PropTypes.number,
          pending: PropTypes.number,
          total: PropTypes.number
        })
      })
    ),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  firmConfig: PropTypes.object,
  loadingAdvisorSeats: PropTypes.bool.isRequired,
  organization: PropTypes.instanceOf(Map),
  showModal: PropTypes.bool
}

const mapStateToProps = state => ({
  organization: userFirmOrganization({...state})
})

Container.registerAction('advisor_seats', BulkInviteActions)
export default connect(mapStateToProps)(bindResources(wrapModal(BulkSendOrResendController), {name: 'advisor_seats', type: 'list'}))
