import {forwardRef} from 'react'

import Form from '../../../../../shared_components/forms/v2/Form'
import Element from '../../../../../dashboard/src/components/corp_max/item/Element'

const JiclElements = forwardRef((props, ref) => {
  const {elements, formData, groupId, onUpdate, sectionConfig, ...otherProps} = props

  return (
    <Form
      className='core jicl-elements'
      ref={ref}
      onUpdate={onUpdate}
      groupId={groupId}>
      <div>
        {elements.map(element => (
          <Element
            {...otherProps}
            key={element.get('id')}
            formData={formData}
            element={element}
            hidePhoneField={sectionConfig.get('hidePhoneField')}
          />
        ))}
      </div>
    </Form>
  )
})

export default JiclElements
