import withSideEffect from 'react-side-effect'

import Container from '../../../lib/Container'
import { RenderNullComponent } from '../../../lib/tools'

const reducePropsToState = propsList => {
  let innermostProps = propsList[propsList.length - 1]
  if (innermostProps)
    return innermostProps.className
}

const handleStateChangeOnClient = className => {
  if (className)
    Container.getStore('authAppClassName').receiveData({className})
}

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient
)(RenderNullComponent)
