import PropTypes from 'prop-types'
import {useEffect} from 'react'

import MixpanelLogger from '../../../../../lib/MixpanelLogger'

const CheckEmail = props => {
  useEffect(() => {
    MixpanelLogger.track('view_email_sent')
  }, [])

  return (
    <div className='info-container'>
      <div className='container-content'>
        <h1>Please check your email to confirm your account</h1>
        <div className='email-info'>
          <p>We've sent an email to <strong>{props.email}</strong> to confirm your account.</p>
          <p>If you don't receive the email within a couple minutes, please check the spam folder in your email program. The subject line of the email is "Confirmation instructions."</p>
          <a onClick={props.resendConfirmationEmail}>Resend confirmation email</a>
        </div>
      </div>
    </div>
  )
}

CheckEmail.defaultProps = {
  email: ''
}

CheckEmail.propTypes = {
  email: PropTypes.string,
  resendConfirmationEmail: PropTypes.func
}

export default CheckEmail
