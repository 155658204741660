import PropTypes from 'prop-types'
import {useState, useCallback} from 'react'
import {Map} from 'immutable'

import JiclResponseGroupContainer from '../../../sections/sub_sections/JiclResponseGroupContainer'
import Modals from '../../../../../../shared_components/core/modals/Modals'
import Closer from '../../../../../../shared_components/core/closer/Closer'
import {useNotificationContext} from '../../../../../../shared_components/notifications/NotificationContext'
import Logger from '../../../../../../lib/NewLogger'
import {pencil} from '../../images'

const JiclEditResponseGroupContainer = props => {
  const [showModal, setShowModal] = useState(false)
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const openModal = () => setShowModal(true)

  const closeModal = () => {
    setProcessing(false)
    setShowModal(false)
  }

  const onResponseGroupSubmit = useCallback(
    updatedResponses => {
      setProcessing(true)
      const resource = {
        type: 'everplan-responses',
        id: props.everplanResponse.get('id'),
        attributes: {responses: updatedResponses}
      }
      // This is needed because new-contacts is updated alongside the responses - Kay
      const params = {include: 'new-contacts'}

      props.updateResource(resource, {params}).then(() => {
        alwaysNotify.shortSuccess('Response updated successfully')
        closeModal()
      })
      Logger.log({name: 'edit_jicl_response'})
    },
    [props.section]
  )

  const closer = () => <Closer closer={closeModal} />

  return (
    <div className='jicl-edit-response-group-container'>
      <img
        onClick={openModal}
        className='edit-icon'
        src={pencil}
        alt='pencil-icon'
      />

      {showModal && (
        <Modals.PopUpModalLarge
          showModal={showModal}
          className='forms-playground'
          closerComponent={closer}
          wrapperContainerClass='.jicl-preview'>
          <div>
            <div className='section-title border-bottom'>
              {props.sectionConfig.get('header')}
            </div>
            <JiclResponseGroupContainer
              {...props}
              processing={processing}
              editResponseGroup={true}
              onResponseGroupSubmit={onResponseGroupSubmit}
              onResponseCancel={closeModal}
            />
          </div>
        </Modals.PopUpModalLarge>
      )}
    </div>
  )
}

JiclEditResponseGroupContainer.propTypes = {
  everplanResponse: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func,
  section: PropTypes.string,
  sectionConfig: PropTypes.instanceOf(Map)
}

export default JiclEditResponseGroupContainer
