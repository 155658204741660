import $ from 'jquery'
import 'github-markdown-css' // TODO: Build internal markdown css and remove this package. Card Created for task --KW
import React from 'react'

// Typography
import BodyTextSection from './sections/typography/BodyTextSection'
import FontScaleSection from './sections/typography/FontScaleSection'
import FontWeightScaleSection from './sections/typography/FontWeightScaleSection'
import HeadingsSection from './sections/typography/HeadingsSection'

// Components
import ButtonsSection from './sections/ButtonsSection'
import ContentBoxesSection from './sections/ContentBoxesSection'
import ListsSection from './sections/ListsSection'
import ModalsSection from './sections/ModalsSection'
import TextSection from './sections/TextSection'
import TooltipSection from './sections/TooltipSection'

// Other
import ColorsSection from './sections/ColorsSection'

import './styleGuide.scss'


export default class StyleGuide extends React.Component {
  UNSAFE_componentWillMount() { $('html').toggleClass('core', true) } // Need to act as if this exists inside consumer.js --BLR
  componentWillUnmount() { $('html').toggleClass('core', false) } // Don't mess with other pages in app.js

  render() {
    return (
      <div className='style-guide'>
        <h1>Style Guide</h1>
        <a href='/#/forms-playground/' className='external-link'>Forms Playground</a>
        <FontScaleSection />
        <FontWeightScaleSection />
        <HeadingsSection />
        <BodyTextSection />
        <TextSection />
        <ColorsSection />
        <ButtonsSection />
        <ContentBoxesSection />
        <ModalsSection />
        <ListsSection />
        <TooltipSection />
      </div>
    )
  }
}
