import PropTypes from 'prop-types'

import EditAdvisorAutoPopulationSettings from './EditAdvisorAutoPopulationSettings'
import ShadowWrapper from '../../ShadowWrapper'
import ShadowHeader from '../../ShadowHeader'

export const AdvisorAutoPopulationSettings = props => (
  <div className='advisor-auto-population-settings'>
    <ShadowHeader headerText='Auto Populate Advisor Info' />
    <ShadowWrapper>
      <div className='settings row-wrapper'>
        <p>Status:</p>
        <div className='column-wrapper'>
          <p>{props.advisorAutoPopulationEnabled ? 'Enabled' : 'Disabled'}</p>
          <p className='description'>
            {
              props.advisorAutoPopulationEnabled ?
                "Primary firm member's contact information is automatically added to clients' Everplan when clients are added." :
                "Firm member's contact information does not get automatically added to clients' Everplan when clients are added."
            }
          </p>
          <EditAdvisorAutoPopulationSettings {...props} />
        </div>
      </div>
    </ShadowWrapper>
  </div>
)

AdvisorAutoPopulationSettings.propTypes = {advisorAutoPopulationEnabled: PropTypes.bool}

export default AdvisorAutoPopulationSettings
