import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Container from '../lib/Container'
import routerUtils from '../lib/routerUtils'
import storePrototype from './StorePrototype'


export default class AppClassController extends React.Component {
  constructor(props) {
    super(props)

    this.store = Container.getStore(props.storeName)
    if (!this.store)
      this.store = Container.registerStore(props.storeName, storePrototype())

    if (props.defaultClassName && !this.store.getState().data.className)
      Container.getStore(props.storeName).receiveData({className: props.defaultClassName})

    this.state = {className: props.defaultClassName}

    this.updateClassName = this.updateClassName.bind(this)
  }
  componentDidMount() {
    this.updateClassName()
    this.store.addChangeListener(this.updateClassName)
  }
  updateClassName() {
    this.setState({
      className: classnames(this.props.defaultClassName, this.store.getState().data.className)
    })
  }
  componentWillUnmount() { this.store.removeChangeListener(this.updateClassName) }

  render() {
    let {children, defaultClassName, storeName, ...otherProps} = this.props
    return routerUtils.childrenWithProps(
      this.props.children,
      Object.assign({}, otherProps, this.state)
    )
  }
}

AppClassController.propTypes = {
  defaultClassName: PropTypes.string,
  storeName: PropTypes.string.isRequired
}
