export default {
  advisor_invite: {
    message: 'Sorry, the link you just used to activate your Everplans Professional account is not valid.',
    options: [
      "If you've already used this link to confirm this Everplans Professional invitation, you should try to log in now.",
      'You may have input the wrong link. If so, please give it another try - make sure you copied the complete link from the confirmation email you received.',
      'There may be an issue with your Everplans Professional account. Please contact the account manager who invited you to find out what you should do next.'
    ]
  },
  client_invite: {
    message: 'The link you just used to activate the Everplans premium account offered to you by your advisor is not valid.',
    options: [
      "If you've already used this link to confirm your Everplans Premium account, you should try to login now.",
      'You may have input the wrong link. If so, please give it another try - make sure you copied the complete link from the confirmation email you received.',
      'The Professional who invited you to have this account may have since rescinded that invitation. Please contact the Advisor who invited you to find out what they would like you to do next.'
    ]
  },
  letter_download: {
    message: "The link you entered to view someone's Just-In-Case Letter is not valid.",
    options: [
      'You may have input the wrong link. If so, please give it another try—<strong>make sure you copied the complete link</strong> from the confirmation email you received.',
      'The Just-In-Case Letter might no longer be available. For security purposes, download links only remain active for a fixed period of time. <strong>Contact the person who sent you the letter</strong> and ask them for a copy'
    ]
  },
  jicl_view: {
    message: 'The link you used to access your Just-In-Case List has timed out.',
    options: [
      "If you've already created your Just-In-Case List, visit us here and we'll send you another link."
    ]
  },
  deputy_invite: {
    message: "The link you just used to become the Deputy for someone's Everplans account is not valid.",
    options: [
      'If you’ve <strong>already accepted</strong> this Deputy invitation, you should try to log in now.',
      'If you’ve <strong>already declined</strong> this Deputy invitation, and have changed your mind, you should contact the person who invited you and ask them to resend your invitation.',
      'The person who invited you to be a Deputy may have since <strong>rescinded</strong> that invitation. Please contact the person who invited you to find out what they would like you to do next.',
      'You may have input the <strong>wrong link</strong>. If so, please give it another try--make sure you copied the complete link from the confirmation email you received.'
    ]
  },
  consumer_invite: {
    message: 'Sorry, but the link you just used to complete the setup of your Everplans account is not valid.',
    options: [
      "If you've already used this link to confirm this Everplans account, you should try to log in now.",
      'You may have input the wrong link. If so, please give it another try - make sure you copied the complete link from the confirmation email you received.'
    ]
  },
  email_confirm: {
    message: 'Sorry, but the link you just used to verify your email is not valid.',
    options: [
      "If you've already used this link to verify your email, you should try to log in now.",
      'You may have input the wrong link. If so, please give it another try - make sure you copied the complete link from the confirmation email you received.'
    ]
  },
  reset_password: {
    message: 'Sorry, but the link you just used to reset your password is not valid.',
    options: [
      "If you've already used this link to reset your password, you should try to log in now using your new password.",
      'You may have input the wrong link. If so, please give it another try - make sure you copied the complete link from the email you received.'
    ]
  },
  unsubscribe: {
    message: 'Sorry, but the link you just used to unsubscribe is not valid.',
    options: [
      "If you've already used this link to unsubscribe, There's no need to do this again.",
      'You may have input the wrong link. If so, please give it another try - make sure you copied the complete link from the email you received.'
    ]
  },
  sso_invalid_token: {
    message: 'Sorry, but an error occured with the token you tried to use.',
    options: [
      'The token might have expired',
      'You may have input the wrong link.',
      'Your account is not properly linked.'
    ]
  }
}
