import AppDispatcher from '../../../lib/ep-dispatcher'
import FirmActions from '../actions/FirmActions'
import StorePrototype from '../../../shared_components/StorePrototype'

const FirmUserConfigStore = StorePrototype()

AppDispatcher.register(action => {
  if ([FirmActions.Types.DID_UPDATE_FIRM_USER_CONFIG, FirmActions.Types.GOT_FIRM_USER_CONFIG].includes(action.actionType))
    FirmUserConfigStore.receiveData(action.data)
})

export default FirmUserConfigStore
