
import PropTypes from 'prop-types'
import * as ButtonTypes from '../../../../shared_components/ButtonTypes'

const InvitePage = props => (
  <span className='invite-page'>
    <h3>Send New Invitation?</h3>
    <p>You've changed the email address for {props.first_name} {props.last_name}, which means that the activation link you previously sent to this client will no longer work.</p><br/>
    <p>Would you like to send a new invitation to {props.email}?</p><br />
    <div className='button-group'>
      <ButtonTypes.Cancel onClick={props.closeModal} />
      <ButtonTypes.Continue loading={props.processing} onClick={props.inviteClient}>Send New Invitation</ButtonTypes.Continue>
    </div>
  </span>
)

InvitePage.propTypes = {
  closeModal: PropTypes.func,
  editClient: PropTypes.func,
  inviteClient: PropTypes.func,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  processing: PropTypes.bool,
  readEndpoint: PropTypes.func
}

export default InvitePage
