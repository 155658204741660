import PropTypes from 'prop-types'

import {Accept} from '../../dashboard/src/components/blocks/Buttons'
import {Text, TextGutterMedium} from '../../dashboard/src/components/blocks/Texts'

import freemiumAnnouncementIcon from '../../consumer/src/assets/images/freemiumAnnouncementIcon.png'

import './freeEverplanAnnouncement.scss'

const FreeEverplanAnnouncement = props => (
  <div className='free-everplan-announcement'>
    <h1 className='heading'>Get started with your own Everplan.</h1>
    <div className='description'>
      <img alt='Everplans network graphic' src={freemiumAnnouncementIcon} />
      <div className='announcement'>
        <TextGutterMedium>
          The Everplans app offers a seamless way to organize your items
          and share aspects of your life with those you trust.
        </TextGutterMedium>
        <Text>Please answer a few questions to finish setting up your account.</Text>
      </div>
    </div>
    <Accept onClick={props.nextStep}>Continue</Accept>
  </div>
)

FreeEverplanAnnouncement.propTypes = {
  nextStep: PropTypes.func,
  onSkip: PropTypes.func
}

export default FreeEverplanAnnouncement
