import classnames from 'classnames'
import {Map} from 'immutable'
import {memo} from 'react'
import PropTypes from 'prop-types'

import AddResponseGroup from './AddResponseGroup'
import ItemDetailsNoResponseGroups from './ItemDetailsNoResponseGroups'

import './itemHeader.scss'

const ItemHeader = props => (
  <header
    className={classnames('item-header flex-container',
      {empty: props.itemResponse.get('response-groups').isEmpty()})}>
    <h3 className='flex-child'>{props.itemResponse.get('header')}</h3>
    {
      props.hasEditPermission ?
        <AddResponseGroup {...props} /> :
        <ItemDetailsNoResponseGroups {...props} />
    }
  </header>
)

ItemHeader.propTypes = {
  hasEditPermission: PropTypes.bool,
  itemResponse: PropTypes.instanceOf(Map)
}

const areEqual = (prevProps, nextProps) => (
  prevProps.everplanSummary.equals(nextProps.everplanSummary) &&
  prevProps.itemResponse.equals(nextProps.itemResponse)
)

export default memo(ItemHeader, areEqual)
