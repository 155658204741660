import {useEffect} from 'react'
import {connect} from 'react-redux'
import {createResource, readEndpoint} from 'redux-json-api'
import PropTypes from 'prop-types'

import Loader from '../../../../shared_components/NewLoader'

import routerUtils from '../../../../lib/routerUtils'

export const OpenIdConnectLogin = props => {
  const authorize = responses => {
    const orgSettingId = responses[0].body.data.id
    const orgSettingAttributes = responses[0].body.data.attributes
    const nonce = responses[1].data.attributes.nonce
    const redirectUrl = encodeURIComponent(`${WEBAPPS_HOST}/open-id-connect/login`)
    const url = `https://login.microsoftonline.com/${orgSettingAttributes['tenant-id']}/oauth2/v2.0/authorize?\
      client_id=${orgSettingAttributes['client-id']}\
      &redirect_uri=${redirectUrl}\
      &response_type=id_token\
      &response_mode=form_post\
      &scope=openid\
      &state=${orgSettingId}\
      &nonce=${nonce}\
    `

    routerUtils.setLocation(url)
  }

  useEffect(() => {
    const resource = {
      type: 'nonces',
      attributes: {}
    }
    const id = props.params.organizationSsoSettingId

    Promise.all([
      props.readEndpoint(`organization-sso-settings/${id}`),
      props.createResource(resource)
    ])
      .then(responses => authorize(responses))
      .catch(() => routerUtils.push('/auth/invalid_token/sso_invalid_token'))
  }, [])

  return <Loader loading={true} />
}

OpenIdConnectLogin.propTypes = {
  createResource: PropTypes.func,
  readEndpoint: PropTypes.func,
  params: PropTypes.shape({
    organizationSsoSettingId: PropTypes.string
  })
}

export default connect(null, {createResource, readEndpoint})(OpenIdConnectLogin)
