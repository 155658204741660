/** @class ButtonGroup
 *
 * @deprecated Do not use this in new code. Use the newer version in
 *   +shared_components/core/buttons/ButtonGroup.react.js+ instead.
 * @desc
 * Legacy component to group a pair of cancel and continue +buttons+.
 */
import PropTypes from 'prop-types'

import React from 'react'

import {ButtonGroupWrapper} from '../blocks/Blocks'
import {Cancel, Continue} from '../ButtonTypes'

import {noop} from '../../lib/tools'


const ButtonGroup = props => (
  <ButtonGroupWrapper className={props.className}>
    {
      !props.hideCancel &&
      (
        <Cancel onClick={props.onCancel}>
          {props.cancelText}
        </Cancel>
      )
    }
    <Continue loading={props.loading} onClick={props.onContinue}>
      {props.continueText}
    </Continue>
  </ButtonGroupWrapper>
)

ButtonGroup.defaultProps = {
  cancelText: 'Cancel',
  continueText: 'Continue »',
  hideCancel: false,
  loading: false,
  onCancel: noop,
  onContinue: noop
}

ButtonGroup.propTypes = {
  cancelText: PropTypes.string,
  continueText: PropTypes.string,
  hideCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func
}

export default ButtonGroup
