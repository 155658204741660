import {Route} from 'react-router'

import AxiosConnectedComponent from '../../../shared_components/AxiosConnectedComponent'
import DeputyFeedbackApp from '../components/DeputyFeedbackApp'
import DeputyFeedbackController from '../components/DeputyFeedbackController'
import DeputyFeedbackDone from '../components/DeputyFeedbackDone'

import '../../../dashboard/styles/main.scss' // PLEASE DO NOT MOVE! We need to import our reset and global styles rules BEFORE importing components --KW

export default (
  <Route component={AxiosConnectedComponent}>
    <Route component={DeputyFeedbackApp}>
      <Route path='deputy-feedbacks'>
        <Route path='start/:token' component={DeputyFeedbackController} />
        <Route path='done' component={DeputyFeedbackDone} />
      </Route>
    </Route>
  </Route>
)
