import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import {constructInterstitialPath, goToInterstitials} from '../../../../lib/interstitialTools'
import {currentUserConfig} from '../../../../lib/userTools'
import InterstitialsActions from '../../actions/InterstitialsActions'
import routerUtils from '../../../../lib/routerUtils'
import sessionUtils from '../../../../auth/src/lib/sessionUtils'
import SessionStore from '../../../../auth/src/stores/SessionStore'


export class InterstitialsController extends React.Component {
  constructor() {
    super()

    this.returnToController = this.returnToController.bind(this)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Ensure request data present in props before trying to navigate based on it:
    if (nextProps.showingIntroduction)
      return

    if (nextProps.indexPathActive) // Only redirect if this route--not a child--is active
      this.processNext(nextProps.interstitials.data)
  }
  processNext(data) {
    let nextPath

    if (data.client_invites && data.client_invites[0])
      nextPath = constructInterstitialPath(data.client_invites[0], 'client')
    // To handle Permission to Request Item Permissions Interstitial
    else if (data.request_item_permissions_invites && data.request_item_permissions_invites[0])
      nextPath = constructInterstitialPath(data.request_item_permissions_invites[0], 'request-item-permissions')
    else if (data.add_household_member_invites && data.add_household_member_invites.length)
      nextPath = constructInterstitialPath(data.add_household_member_invites[0], 'add_household_member')
    else if (data.migrated_deputy_invites && data.migrated_deputy_invites.length)
      nextPath = constructInterstitialPath(null, 'migrated_deputy') // No need to pass ID since Deputy-specific controller handles it
    else if (data.mandatory_tfa_setup && data.mandatory_tfa_setup.length)
      nextPath = constructInterstitialPath(null, 'mandatory-tfa')
    else if (data.advisor_sso_setup && data.advisor_sso_setup.length)
      nextPath = constructInterstitialPath(null, 'advisor-sso-setup')
    else if (data.subscription_setup && data.subscription_setup.length)
      nextPath = constructInterstitialPath(null, 'new-subscription')

    if (nextPath)
      return routerUtils.push(nextPath)

    sessionUtils.sendUserToNextRoute(SessionStore.getState().currentUser)
  }
  returnToController() {
    this.props.updating(() => goToInterstitials())
    InterstitialsActions.fetchInterstitials()
    this.props.finished()
  }

  render() {
    return (
      <div className='interstitials-controller'>
        {
          routerUtils.childrenWithProps(
            this.props.children,
            {
              interstitials: this.props.interstitials.data,
              returnToController: this.returnToController,
              userConfig: this.props.userConfig
            }
          )
        }
      </div>
    )
  }
}

InterstitialsController.defaultProps = {
  indexPathActive: false,
  interstitials: {data: {}}
}

InterstitialsController.propTypes = {
  finished: PropTypes.func,
  indexPathActive: PropTypes.bool.isRequired,
  interstitials: PropTypes.shape({
    data: PropTypes.shape({
      client_invite: PropTypes.number,
      deputy_invites: PropTypes.arrayOf(PropTypes.number),
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }),
  showingIntroduction: PropTypes.bool,
  updating: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => ({userConfig: currentUserConfig(api)})

export default connect(mapStateToProps)(bindUpdate(InterstitialsController, 'interstitials', null))
