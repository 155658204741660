import PropTypes from 'prop-types'
import {Map} from 'immutable'
import classnames from 'classnames'

import NewProgressBar from '../../../../../shared_components/NewProgressBar'
import {SUB_SECTION_COMPONENTS} from '../../../lib/jiclMainSectionConfiguration'

const JiclSubSection = ({
  className,
  sectionIndex,
  subSection,
  numberOfScreens,
  ...otherProps
}) => {
  const SubSectionComponent = SUB_SECTION_COMPONENTS[subSection]

  return (
    <div className={classnames(className)}>
      <div className='section-title'>
        {otherProps.sectionConfig.get('header')}
      </div>
      <NewProgressBar step={sectionIndex + 1} total={numberOfScreens} />
      <SubSectionComponent {...otherProps} />
    </div>
  )
}

JiclSubSection.propTypes = {
  className: PropTypes.string,
  numberOfScreens: PropTypes.number,
  subSection: PropTypes.string,
  sectionConfig: PropTypes.instanceOf(Map),
  sectionIndex: PropTypes.number
}

export default JiclSubSection
