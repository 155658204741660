import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'

import {everplansBlackLogo} from '../preview/images'
import Logger from '../../../../lib/NewLogger'
import routerUtils from '../../../../lib/routerUtils'

import './jiclUserUpgradeBanner.scss'

const JiclUserUpgradeBanner = () => {
  const goToUpgradeUrl = () => {
    Logger.log({
      name: 'click_jicl_upgrade',
      payload: {
        context: 'jicl',
        flavor: 'covid',
        wildcard: 'covid'
      }
    })
    routerUtils.push('/saved-jicl/upgrade')
  }

  return (
    <div className='jicl-user-upgrade-banner'>
      <img
        className='everplans-logo'
        src={everplansBlackLogo}
        alt='everplans-logo'
      />
      <h3>Sign up for Everplans</h3>
      <TextGutterMedium>
        Sign up today to manage a wider range of information and get better control of what you share. It's free, secure, and quick.
      </TextGutterMedium>
      <Accept onClick={goToUpgradeUrl}>Sign up for Everplans</Accept>
    </div>
  )
}

export default JiclUserUpgradeBanner
