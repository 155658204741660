import {addPossessive} from './tools'
import ClientActions from '../pro/src/actions/ClientActions'


/**
 * Returns a promise after sending client invitation so that extra actions can be performed as needed when used
 * Actions include but not limited to **notifications**, **closing modals**, etc
 * @param {Map} clientSeat
 * @param {Object} invitePayload
 * @param {Function} updateResource
 * @param {Function} readEndpoint
 * @returns {Promise<void>}
 */

export const sendClientInvitation = ({clientSeat, invitePayload = {}, updateResource, readEndpoint}) => {
  const payload = {
    type: 'client-seats',
    id: clientSeat.get('id'),
    attributes: {'client-invite': invitePayload}
  }

  return updateResource(payload)
    .then(() => readEndpoint(`everplans/${clientSeat.get('everplan-id')}?include=request-item-permissions-invite`))
    .then(() => ClientActions.fetchHousehold(clientSeat.get('id')))
}

/**
 * Returns the notification message to be displayed after client invitation based on the client's status
 * @param {Object} client
 * @param {Object} existingClient
 * @returns {String}
 */

export const notificationMessage = ({client, existingClient}) => {
  switch (client.status) {
    case 'Requesting Approval':
      return `We've sent a request to ${existingClient.first_name} to add ${client.first_name} to this Everplan.`
    case 'Pending':
      return `${addPossessive(client.name)} invitation has been resent.`
    default:
      return `${client.name} has been invited. ${addPossessive(client.first_name)} status is PENDING until ${client.first_name} accepts the invitation.`
  }
}

/**
 * Triggers the display of toast notifications
 * @param {Object} client
 * @param {Object} existingClient
 * @returns {void}
 */
export const notifyAfterSend = ({alwaysNotify, client, existingClient = {}}) => {
  const message = notificationMessage({client, existingClient})

  if (client.status === 'Pending')
    alwaysNotify.shortSuccess(message)
  else
    alwaysNotify.longSuccess(message)
}
