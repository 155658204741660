import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {Fragment, useRef, useState} from 'react'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'

import Form from '../../../../../shared_components/forms/v2/Form'

import AcceptedClients from './AcceptedClients'
import SkippedUl from './SkippedUl'
import * as Buttons from '../../../../../dashboard/src/components/blocks/Buttons'

import {currentUserConfig} from '../../../../../lib/userTools'
import {
  filterRawResourceByAttributeId,
  findRawResourceByAttributeId
} from '../../../../../lib/plan_data/userData'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import InviteClientNotification from '../InviteClientNotification'
import {selectedFirmSeatId} from '../../../../../lib/proTools'
import SelectAdvisor from '../SelectAdvisor'


export const ConfirmAddBulkClients = props => {
  const [processing, setProcessing] = useState(false)
  const [hasError, setHasErrors] = useState(false)
  const formRef = useRef()
  const {alwaysNotify} = useNotificationContext()

  const stats = props.bulkClientValidation


  const showSuccessNotification = () => {
    alwaysNotify.longSuccess(
      <InviteClientNotification
        message={
          "Your file has been successfully uploaded. We'll email you once it's processed. It should only take a few min."
        }
        firm={props.firm}
      />
    )
  }

  const addClients = () => {
    const form = formRef.current
    const formData = form.onSubmit()

    if (formData) {
      setProcessing(true)

      const payload = {
        type: 'bulk-clients',
        attributes: {
          'batch-token': stats.get('batch-token'),
          'advisor-seat-id': selectedFirmSeatId({
            name: formData.getIn(['advisor', 'value']),
            firmSeats: props.firmSeats
          })
        }
      }

      props
        .createResource(payload)
        .then(() => {
          showSuccessNotification()
          props.closer()
        })
        .catch(() => {
          setProcessing(false)
          setHasErrors(true)
        })
    }
  }

  const showErrorMessage = stats.isEmpty() || hasError

  return (
    <Fragment>
      {showErrorMessage && (
        <div>
          <p>There was a problem with your file.</p>
          <p>
            Please check your .csv and{' '}
            <a onClick={props.router.goBack}>click here</a> to try again.
          </p>
        </div>
      )}
      {!showErrorMessage && (
        <div className='group'>
          <article className='add-many'>
            <AcceptedClients
              accepted={stats.get('accepted').toJS()}
              goBack={props.router.goBack}
            />
            <SkippedUl
              clients={stats.get('skipped').toJS()}
              description='The following entries have previously been added or invited by you and will not be included in this upload.  For more options, please go to their client detail page.'
            />
            <SkippedUl
              clients={stats.get('technical').toJS()}
              description='The following entries cannot be added for technical reasons and will not be included in this upload. Please contact your account manager for futher details.'
            />
            <SkippedUl
              clients={stats.get('duplicate').toJS()}
              description='The following entries have an email address that is used more than once in your file. This email address will only be included once in this upload.'
            />
            <section className='forms-playground'>
              {!stats.get('accepted').isEmpty() ?
                (
                  <span>
                    <Form className='core' ref={formRef}>
                      <SelectAdvisor
                        userConfig={props.userConfig}
                        firmSeats={props.firmSeats}
                      />
                    </Form>
                    <Buttons.Accept onClick={addClients} processing={processing}>
                    Add Clients
                    </Buttons.Accept>
                  </span>
                ) :
                (
                  <Buttons.Cancel onClick={props.closer} />
                )}
            </section>
          </article>
        </div>
      )}
    </Fragment>
  )
}

ConfirmAddBulkClients.propTypes = {
  bulkClientValidation: PropTypes.instanceOf(Map),
  closer: PropTypes.func,
  createResource: PropTypes.func,
  firmSeats: PropTypes.instanceOf(List),
  firm: PropTypes.instanceOf(Map),
  router: PropTypes.object,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}, {params}) => {
  const userConfig = currentUserConfig(api)
  const firmId = userConfig.get('firm-id')

  return {
    bulkClientValidation: findRawResourceByAttributeId({
      attribute: 'batch-token',
      id: params.batchToken,
      rawResource: api['bulk-client-validations']
    }),
    firmSeats: filterRawResourceByAttributeId({
      attribute: 'firm-id',
      rawResource: api['firm-seats'],
      id: firmId
    }),
    userConfig
  }
}

export default connect(mapStateToProps, {createResource})(ConfirmAddBulkClients)

