import React from 'react'

import AccountController from '../../../../dashboard/src/components/AccountController'
import AppShell from '../../../../shared_components/blocks/AppShell'

export default class AccountInformationController extends React.Component {
  render() {
    return (
      <AppShell headerFlavor='app' includeFooter={true}>
        <AccountController legacyAccountSettings={true} params={this.props.params} />
      </AppShell>
    )
  }
}
