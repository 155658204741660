import classnames from 'classnames'
import PropTypes from 'prop-types'

import './complianceImage.scss'

const ComplianceImage = ({altText, className, imageSrc}) => (
  <div className={classnames('compliance-image', className)}>
    <img alt={altText} className='compliance-img' src={imageSrc} />
  </div>
)

ComplianceImage.propTypes = {
  altText: PropTypes.string,
  className: PropTypes.string,
  imageSrc: PropTypes.string
}

export default ComplianceImage
