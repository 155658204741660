import CodeBlock from '../../CodeBlock'
import CodeExamples from '../../CodeExamples'
import CollapsibleSection from '../CollapsibleSection'
import {BoxGutterMedium} from '../../../../dashboard/src/components/blocks/Boxes'


const FontScaleSection = () => (
  <CollapsibleSection heading='Font Weight Scale System'>
    <BoxGutterMedium>
      <p>Font weight scale values are: 300, 400, 600, 700, and 800</p>
      <aside>
        <h3>Font Weight Scale 1</h3>
        <p className='font-weight-scale-1'>
          This text uses font weight scale 1: a font weight of 300 (Light).
        </p>
        <CodeBlock>{CodeExamples.FontWeightScale1}</CodeBlock>
      </aside>
      <aside>
        <h3>Font Weight Scale 2</h3>
        <p className='font-weight-scale-2'>
          This text uses font weight scale 2: a font weight of 400 (Normal--the default font weight).
        </p>
        <CodeBlock>{CodeExamples.FontWeightScale2}</CodeBlock>
      </aside>
      <aside>
        <h3>Font Weight Scale 3</h3>
        <p className='font-weight-scale-3'>
          This text uses font weight scale 3: a font weight of 600 (Semi Bold).
        </p>
        <CodeBlock>{CodeExamples.FontWeightScale3}</CodeBlock>
      </aside>
      <aside>
        <h3>Font Weight Scale 4</h3>
        <p className='font-weight-scale-4'>
          This text uses font weight scale 4: a font weight of 700 (Bold).
        </p>
        <CodeBlock>{CodeExamples.FontWeightScale4}</CodeBlock>
      </aside>
      <aside>
        <h3>Font Weight Scale 5</h3>
        <p className='font-weight-scale-5'>
          This text uses font weight scale 5: a font weight of 800 (Extra Bold).
        </p>
        <CodeBlock>{CodeExamples.FontWeightScale5}</CodeBlock>
      </aside>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default FontScaleSection
