import PropTypes from 'prop-types'
import {Component} from 'react'

import DisclosureActions from '../../../../../shared_actions/DisclosureActions'

import bindUpdate from '../../../../../shared_components/BoundUpdate'
import {ControlButton, Cancel, Continue} from '../../../../../shared_components/ButtonTypes'
import Forms from '../../../../../shared_components/forms'
import Modal from '../../../../../shared_components/Modal'
import modalWrapper, {MODAL_CONTEXT_TYPES} from '../../../../../shared_components/ModalWrapper'
import ShadowHeader from '../ShadowHeader'
import StatusToggle from './StatusToggle'
import MarkdownText from './MarkdownText'

import './editDisclosure.scss'

import ContactAccountManagerNote from './ContactAccountManagerNote'
import ShadowWrapper from '../ShadowWrapper'

export class EditDisclosure extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDisclosureEnabled: props.disclosure.enabled,
      markdownText: props.disclosure.markdown_text
    }

    this.cancel = this.cancel.bind(this)
    this.toggleDisclosureStatus = this.toggleDisclosureStatus.bind(this)
    this.saveDisclosure = this.saveDisclosure.bind(this)
    this.setMarkdownText = this.setMarkdownText.bind(this)
  }

  setMarkdownText(event) {
    this.setState({markdownText: event.target.value})
  }

  toggleDisclosureStatus() {
    this.setState({isDisclosureEnabled: !this.state.isDisclosureEnabled})
  }

  saveDisclosure() {
    this.props.updating(() => this.context.closeModal())

    if (!this.state.isDisclosureEnabled) {
      DisclosureActions.updateDisclosure({
        firmId: this.props.firmId,
        data: {disclosure: {enabled: false}}
      })
    } else {
      const errors = this.refs.form.validate()
      if (errors.length === 0) {
        DisclosureActions.updateDisclosure({
          firmId: this.props.firmId,
          data: {disclosure: {...this.refs.form.formData(), enabled: true}}
        })
      }
    }

    this.props.finished()
  }

  cancel() {
    this.setState({isDisclosureEnabled: this.props.disclosure.enabled, markdownText: this.props.disclosure.markdown_text})
    this.context.closeModal()
  }

  render() {
    const isDisclosureEnabled = this.state.isDisclosureEnabled
    return (

      <ShadowWrapper>
        <div className='edit-disclosure settings row-wrapper'>
          <p>Status: </p>
          <div className='column-wrapper'>
            <p>{this.props.disclosure.enabled ? 'Include' : 'Do not include'}</p>
            <p className='description' />
            {
              this.props.isFirmActive && this.props.isAccountManager &&
              (
                <div className='edit-config edit-disclosure'>
                  <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
                  <Modal visible={this.props.showModal}>
                    <ShadowHeader headerText='Disclosures' />
                    <div className='config-wrapper'>
                      <StatusToggle
                        description={{
                          disabled: '',
                          enabled: ''
                        }}
                        enabledChecked={isDisclosureEnabled}
                        name='enabled'
                        onChange={this.toggleDisclosureStatus}
                        statusText={{disabled: 'Do not include', enabled: 'Include'}}
                      />
                      {
                        isDisclosureEnabled &&
                      (
                        <section className='disclosure-form'>
                          <Forms.Form errors={this.props.errors} ref='form'>
                            <div className='markdown-label'>Enter plain text or markdown</div>
                            <Forms.TextArea
                              name='markdown_text'
                              defaultValue={this.props.disclosure.markdown_text}
                              validator={Forms.Validators.required}
                              onChange={this.setMarkdownText}
                            />
                            {
                              this.state.markdownText &&
                              (
                                <div className='markdown-preview-container'>
                                  <div className='markdown-label'>Preview</div>
                                  <div className='markdown-preview'>
                                    <MarkdownText displayText={this.state.markdownText} />
                                  </div>
                                </div>
                              )
                            }
                            <div className='checkboxes'>
                              <Forms.Checkbox label='Add to invitation email' name='show_in_email' value={this.props.disclosure.show_in_email} />
                              <Forms.Checkbox label='Add to global footer' name='show_in_footer' value={this.props.disclosure.show_in_footer} />
                            </div>
                          </Forms.Form>
                        </section>
                      )
                      }
                      <div className='button-group'>
                        <Cancel onClick={this.cancel} />
                        <Continue loading={this.props.processing} onClick={this.saveDisclosure}>Save</Continue>
                      </div>
                    </div>
                  </Modal>
                </div>
              )
            }
            <ContactAccountManagerNote isAccountManager={this.props.isAccountManager} />
          </div>
        </div>
      </ShadowWrapper>

    )
  }
}

EditDisclosure.contextTypes = MODAL_CONTEXT_TYPES

EditDisclosure.defaultProps = {
  disclosure: {},
  errors: [],
  showModal: false
}

EditDisclosure.propTypes = {
  statusText: PropTypes.string,
  isAccountManager: PropTypes.bool,
  description: PropTypes.string,
  firmId: PropTypes.string.isRequired,
  disclosure: PropTypes.shape({
    enabled: PropTypes.bool,
    markdown_text: PropTypes.string,
    show_in_footer: PropTypes.bool,
    show_in_email: PropTypes.bool
  }).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  finished: PropTypes.func,
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  isFirmActive: PropTypes.bool,
  updating: PropTypes.func
}

export default modalWrapper(bindUpdate(EditDisclosure, 'disclosure', null))
