import Logo from '../../shared_assets/logos2x.png'

const AsSeenMap = () => (
  <div>
    <p>AS SEEN IN</p>
    <img id='media-logos' src={Logo} width='252' height='99' useMap='media-logo-map' />
    <map name='media-logo-map'>
      <area shape='rect' coords='0,0,161,23' target='_blank' href='http://www.nytimes.com/2014/03/29/your-money/navigating-the-logistics-of-death-ahead-of-time.html' alt='The New York Times' title='New York Times' />
      <area shape='rect' coords='166,0,252,23' target='_blank' href='https://www.everplans.com/blog/everplans-featured-in-aarp-bulletin' alt='AARP' title='AARP' />
      <area shape='rect' coords='0,36,95,64' target='_blank' href='http://www.bloomberg.com/news/videos/b/1c343132-a206-48ff-95d7-48f73e23f621' alt='Bloomberg Television' title='Bloomberg Television' />
      <area shape='rect' coords='100,30,151,68' target='_blank' href='http://www.cnbc.com/2015/07/30/do-you-have-life-after-death-digital-archives.html' alt='CNBC' title='CNBC' />
      <area shape='rect' coords='155,31,252,62' target='_blank' href='http://money.usnews.com/money/personal-finance/articles/2015/12/03/passwords-and-powers-of-attorney-your-digital-estate-planning-options' alt='US News and World Report' title='US News and World Report' />
      <area shape='rect' coords='0,77,209,99' target='_blank' href='http://www.wsj.com/articles/the-best-online-tools-for-retirement-planning-and-living-1421726470' alt='The Wall Street Journal' title='The Wall Street Journal' />
    </map>
  </div>
)

export default AsSeenMap
