import PropTypes from 'prop-types'
import {useRef} from 'react'

import * as ButtonTypes from '../../../../shared_components/ButtonTypes'
import ClientActions from '../../actions/ClientActions'
import Forms from '../../../../shared_components/forms'
import {useEverplanData} from '../../../../lib/hooks'

const EditPage = props => {
  const formRef = useRef()
  const {refetchItemResponses} = useEverplanData(props.everplanId)

  const fetchNeededClientInformation = () => {
    refetchItemResponses({everplanId: props.everplanId})
    props.readEndpoint(`everplans/${props.everplanId}?include=firm-ownership.secondary-everplan-owner,client-seats`)
  }

  const editClient = () => {
    const form = formRef.current
    const formData = form.formData()

    let updatedPendingEmail = props.status === 'Pending' && formData.email !== props.email

    props.updating(() => {
      updatedPendingEmail ? props.changePage(true) : props.closeModal()
      fetchNeededClientInformation()
    })

    if (form.validate().length === 0) {
      ClientActions.updateClient(Object.assign({client_seat_id: props.clientSeatId}, formData))
      if (props.errors.length > 0)
        updatedPendingEmail = false
    } else {
      props.finished()
    }
  }


  return (
    <span className='edit-page'>
      <h3>Edit Name and Email</h3>
      <Forms.Form ref={formRef} errors={props.errors}>
        <Forms.Input name='first_name' defaultValue={props.first_name} label='First Name:' validator={Forms.Validators.required} />
        <Forms.Input name='last_name' defaultValue={props.last_name} label='Last Name:' validator={Forms.Validators.required} />
        <Forms.Input name='email' defaultValue={props.email} label='Email Address:' validator={Forms.Validators.emailValidator} />
      </Forms.Form>
      <div className='button-group'>
        <ButtonTypes.Cancel onClick={props.closeModal} />
        <ButtonTypes.Continue loading={props.processing} onClick={editClient}>Save</ButtonTypes.Continue>
      </div>
    </span>
  )
}

EditPage.propTypes = {
  clientSeatId: PropTypes.string,
  changePage: PropTypes.func,
  closeModal: PropTypes.func,
  data: PropTypes.shape({
    clients: PropTypes.array
  }),
  editClient: PropTypes.func,
  everplanId: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  finished: PropTypes.func,
  processing: PropTypes.bool,
  readEndpoint: PropTypes.func,
  status: PropTypes.string,
  updating: PropTypes.func
}


export default EditPage
