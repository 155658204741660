import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {Privacy} from './Links'
import UnorderedList from './UnorderedList'
import ResponsiveModal from './ResponsiveModal'

import wrapModal from './ModalWrapper'

import './privacyNoticeModal.scss'

const otherHouseholdOwnerAccessibleList = [
  'Add, modify, and remove the secure data in your Everplan.',
  'Add, modify, and remove Deputy permissions, which control who else can view or make changes to your Everplan.',
  'Revoke your access to this Household Everplan.'
]

const otherHouseholdOwnerInaccessibleList = [
  'View or modify your password or security settings.',
  'View or modify the Everplans of other people who have made you a Deputy.'
]

export class HouseholdClientPrivacyNoticeModal extends React.Component {
  render() {
    return (
      <span className={classnames('privacy-notice-modal', this.props.className)}>
        <a onClick={this.context.showModal}>{this.props.linkText}</a>
        <ResponsiveModal closer={this.context.modalClosed} visible={this.props.showModal}>
          <div className='modal-title'>
            <h3>Privacy Notice</h3>
          </div>
          <p>
            The subscription you've been provided by {this.props.advisorFullName} is for a <strong>Household
            Everplan</strong>, to be shared with {this.props.otherHouseholdOwner.full_name}.
          </p>
          <p>
            As a co-administrator, <strong>{this.props.otherHouseholdOwner.first_name} will be able to</strong>:
          </p>
          <UnorderedList children={otherHouseholdOwnerAccessibleList} />
          <p>
            <strong>{this.props.otherHouseholdOwner.first_name} will not be able to:</strong>
          </p>
          <UnorderedList children={otherHouseholdOwnerInaccessibleList} />
          <p>See the complete <Privacy target='_blank'>Everplans Privacy Policy</Privacy>.</p>
        </ResponsiveModal>
      </span>
    )
  }
}

HouseholdClientPrivacyNoticeModal.contextTypes = {
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func,
  showModal: PropTypes.func
}

HouseholdClientPrivacyNoticeModal.defaultProps = {
  advisorFullName: '',
  className: '',
  otherHouseholdOwner: {
    first_name: '',
    full_name: ''
  },
  linkText: 'Learn more',
  showModal: false
}

HouseholdClientPrivacyNoticeModal.propTypes = {
  advisorFullName: PropTypes.string.isRequired,
  otherHouseholdOwner: PropTypes.shape({
    first_name: PropTypes.string,
    full_name: PropTypes.string
  }),
  linkText: PropTypes.string,
  showModal: PropTypes.bool
}

export default wrapModal(HouseholdClientPrivacyNoticeModal)
