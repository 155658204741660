import {useEffect} from 'react'
import {withRouter} from 'react-router'
import PropTypes from 'prop-types'

import AppClassController from '../../../shared_components/AppClassController'
import AuthApp from './AuthApp'

import extra_storage from '../../../lib/extra_storage'

const AuthAppController = props => {
  useEffect(() => extra_storage.redirectIfNoCookies(), [])

  return (
    <AppClassController defaultClassName='auth' storeName='authAppClassName'>
      <AuthApp {...props} />
    </AppClassController>
  )
}

AuthAppController.propTypes = {
  router: PropTypes.shape({
    isActive: PropTypes.func
  })
}

export default withRouter(AuthAppController)
