import {useState} from 'react'

import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {readEndpoint, updateResource} from 'redux-json-api'

import Header from '../header/Header'
import Logger from '../../lib/NewLogger'
import routerUtils from '../../lib/routerUtils'
import {railsUrl, webAppsUrl, corpMaxDashboardUrl} from '../../lib/urlTools'
import currentUserWrapper from '../currentUserWrapper'
import TimeoutModal from '../TimeoutModal'
import TermsOfServiceAndPrivacyPolicy from './TermsOfServiceAndPrivacyPolicy'

import './termsOfServiceAndPrivacyPolicyContainer.scss'

export const TermsOfServiceAndPrivacyPolicyContainer = props => {
  const [accepting, setAccepting] = useState(false)
  const [declining, setDeclining] = useState(false)

  const logEvent = ({name, payload = {}}) => {
    Logger.log({
      name,
      payload
    })
  }

  const handleAccept = () => {
    if (accepting || declining) return

    setAccepting(true)

    const payload = {
      type: 'users',
      id: props.currentUser.id,
      attributes: {
        'agreement-accepted': true
      }
    }

    props.updateResource(payload).then(() => {
      logEvent({name: 'accept_terms_and_privacy_policy'})

      props.readEndpoint('user-configs').then(() => {
        if (props.currentUser.has_interstitials)
          routerUtils.setLocation(webAppsUrl('pending-requests'))
        else if (props.currentUser.firm_user)
          routerUtils.setLocation(webAppsUrl('pro'))
        else
          routerUtils.setLocation(corpMaxDashboardUrl)
      })
    })
  }

  const handleDecline = () => {
    if (accepting || declining) return

    setDeclining(true)
    logEvent({name: 'decline_terms_and_privacy_policy'})
    routerUtils.setLocation(railsUrl('sign_out'))
  }

  return (
    <div className='terms-of-service-and-privacy-policy-container'>
      <Header flavor='transactional' />
      <TimeoutModal needsCheck={true} />
      <TermsOfServiceAndPrivacyPolicy
        accepting={accepting}
        declining={declining}
        onAccept={handleAccept}
        onDecline={handleDecline}
      />
    </div>
  )
}

TermsOfServiceAndPrivacyPolicyContainer.propTypes = {
  currentUser: PropTypes.shape({
    firm_user: PropTypes.bool,
    has_interstitials: PropTypes.bool,
    id: PropTypes.number
  }),
  readEndpoint: PropTypes.func,
  updateResource: PropTypes.func
}

export default connect(null, {readEndpoint, updateResource})(currentUserWrapper(TermsOfServiceAndPrivacyPolicyContainer, true))
