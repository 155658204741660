import {useCallback, useState} from 'react'

import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'

import {Button} from '../../../../dashboard/src/components/blocks/Buttons'
import routerUtils from '../../../../lib/routerUtils'
import './canceledPayment.scss'

export const CanceledPayment = props => {
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback(() => {
    setShowError(false)
    setLoading(true)
    const payload = {
      type: 'stripe-checkouts',
      attributes: {'partner-code': 'd8991b3d81f7d796'}
    }

    props.createResource(payload).then(response => {
      const {url} = response.data.attributes

      routerUtils.setLocation(url)
      setLoading(false)
    })
      .catch(() => {
        setLoading(false)
        setShowError(true)
      })
  })

  return (
    <div className='canceled-payment'>
      <h1>Payment unsuccessful</h1>
      <p>Your card did not go through. Please try again.</p>
      <p>
        If you’re having trouble, please email us at{' '}
        <a className='email-link' href='mailto: contactus@everplans.com'>contactus@everplans.com</a>
        {' '}and a member of our Customer Success team will take good care of you.
      </p>
      <Button className='button' onClick={handleClick} processing={loading}>Sign up for Everplans</Button>
      {showError && <div className='error'>Sorry, something went wrong. Please try again.</div>}
    </div>
  )
}

CanceledPayment.propTypes = {
  createResource: PropTypes.func
}

export default connect(null, {createResource})(CanceledPayment)
