import React from 'react'

import ClientInviteActions from '../../actions/ClientInviteActions'


export default class ChangedEmail extends React.Component {
  constructor(props) {
    super(props)
    this.resendEmail = this.resendEmail.bind(this)
  }
  resendEmail() {
    ClientInviteActions.resendConfirmation({
      token: this.props.token,
      email: this.props.email
    })
  }

  render() {
    return (
      <span>
        <div className='changed-email'>
          <h3>We've sent an email to {this.props.email} to confirm your account</h3>
          <p>If you don't receive your email within a couple minutes, please check the spam folder in your email program.</p>
          <div>
            <p className='resend'>
              I didn't get the email, <a className='resend-btn' onClick={this.resendEmail}>please resend it.</a>
            </p>
          </div>
        </div>
      </span>
    )
  }
}
