import PropTypes from 'prop-types'

import {Text} from '../../blocks/Texts'

import {addPossessive} from '../../../../../lib/tools'
import {personalPronoun} from '../../../../../lib/pronouns'


const DeathReporterInstructions = ({deathReporter, fullName, pronoun}) => {
  if (deathReporter) {
    return (
      <Text className='death-reporter-instructions'>
        <strong>Important:</strong> You've been named by {fullName} as someone who can unlock the "after death" items
        of {personalPronoun(pronoun, 'modifying-possessive')} Everplan for you and for any other individuals who might be waiting
        to view {addPossessive(fullName)} "after death" items. When the time comes, request unlocking by logging in and
        submitting a notice of {addPossessive(fullName)} death to Everplans.
      </Text>
    )
  } else {
    return null
  }
}

DeathReporterInstructions.defaultProps = {
  deathReporter: false,
  fullName: ''
}

DeathReporterInstructions.propTypes = {
  deathReporter: PropTypes.bool,
  fullName: PropTypes.string,
  pronoun: PropTypes.object
}

export default DeathReporterInstructions
