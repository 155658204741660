import {useCallback, useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {deleteResource, readEndpoint} from 'redux-json-api'

import {downloadPlainData} from '../../../../lib/fileDownload'
import {usePrevious} from '../../../../lib/hooks'
import {cleanResponse} from '../../../../dashboard/src/components/corp_max/redux/apiHelper'

import DownloadStatus from '../../../../shared_components/DownloadStatus'

export const POLLING_TIME_INTERVAL = 10000

export const ProCsvDownloadStatus = props => {
  const {proCsvReport} = props
  const [jobId, setJobId] = useState(null)
  const prevProCsvReport = usePrevious(proCsvReport)

  const clearPolling = useCallback(() => {
    const csv = proCsvReport.get('csv')

    if (jobId) clearInterval(jobId)
    setJobId(null)

    if (csv && !prevProCsvReport.equals(proCsvReport)) {
      const today = new Date().toJSON().slice(0, 10)

      downloadPlainData({fileData: csv.toJS(), fileName: `everplans_client_information_${today}.csv`})
      props.deleteResource({type: 'pro-csv-reports', id: proCsvReport.get('id')})
    }
  }, [proCsvReport, jobId])

  const startPolling = useCallback(() => {
    // clear any existing download polling before starting a new one.
    clearPolling()
    setJobId(
      setInterval(
        () => props.readEndpoint(`pro-csv-reports/${proCsvReport.get('id')}`),
        POLLING_TIME_INTERVAL
      )
    )
  }, [proCsvReport])

  useEffect(() => {
    if (!proCsvReport.isEmpty())
      proCsvReport.get('csv') ? clearPolling() : startPolling()
  }, [proCsvReport.get('csv')])

  useEffect(() => clearPolling, [])

  if (proCsvReport.isEmpty() || proCsvReport.get('csv'))
    return null
  else
    return <DownloadStatus />
}

ProCsvDownloadStatus.propTypes = {
  deleteResource: PropTypes.func,
  proCsvReport: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func
}

const mapStateToProps = ({api}) => ({
  proCsvReport: cleanResponse(api['pro-csv-reports']).last() || Map()
})

export default connect(mapStateToProps, {deleteResource, readEndpoint})(
  ProCsvDownloadStatus
)
