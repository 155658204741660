/** @class ItemDetailsNoResponseGroups
 *
 * @desc
 * Class that displays information about items where no detailed information is visible
 * to the current user. This is either items where no information has been added or
 * items where information has been added but it is not visible or editable by the current
 * user.
 */
import classnames from 'classnames'
import {Fragment} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {monthDayYear} from '../../../../../lib/dateTools'

import './itemDetailsNoResponseGroups.scss'

const ItemDetailsNoResponseGroups = ({everplanSummary, itemResponse}) => {
  if (itemResponse.get('response-groups').isEmpty()) {
    const lastUpdatedDate = everplanSummary.getIn(['completed-items', itemResponse.get('item-id'), 'last_updated_at'])

    return (
      <span className={classnames('item-details-no-response-groups flex-child flex-container', {'item-details-added': lastUpdatedDate})}>
        {
          lastUpdatedDate ?
            (
              <Fragment>
                <i className='fa fa-check-circle' />
                Updated {monthDayYear(lastUpdatedDate, 'L')}
              </Fragment>
            ) :
            'Not started'
        }
      </span>
    )
  } else {
    return null
  }
}

ItemDetailsNoResponseGroups.propTypes = {
  everplanSummary: PropTypes.instanceOf(Map),
  itemResponse: PropTypes.instanceOf(Map)
}

export default ItemDetailsNoResponseGroups
