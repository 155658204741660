import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import ContactMethodRow from './ContactMethodRow'

const AdvisorContactInfo = props => (<div className={classnames('column-wrapper-sb', 'contact-info', props.className)}>
  <ContactMethodRow className='fa-envelope-o' data={props.contactCard.email}>
    <a href={`mailto:${props.contactCard.email}`}>Send an Email</a>
  </ContactMethodRow>
  <ContactMethodRow className='fa-phone' data={props.contactCard.phone}>
    {props.contactCard.phone}
  </ContactMethodRow>
  <ContactMethodRow className='fa-globe' data={props.contactCard.url}>
    <a href={props.contactCard.url} target='_blank' rel='noreferrer'>Visit my Website</a>
  </ContactMethodRow>
</div>)

AdvisorContactInfo.propTypes = {
  contactCard: PropTypes.shape({
    accreditation: PropTypes.string,
    email: PropTypes.string,
    firm_name: PropTypes.string,
    head_shot: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string
  }).isRequired
}

export default AdvisorContactInfo
