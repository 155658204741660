import classnames from 'classnames'
import PropTypes from 'prop-types'

import ClientSeatsUsage from './seats_usage/ClientSeatsUsage'
import SeatTypeDetails from './SeatTypeDetails'


const LicenseSeatData = props => (
  <div className={classnames('license-seat-data', {'no-border-bottom': !props.isActive})}>
    <ClientSeatsUsage {...props.data} />
    <SeatTypeDetails
      seats={props.data.advisor_seats}
      seatsUsed={props.data.advisor_seats_used}
      seatTypeName='Advisor Seats'
      status={props.data.status}
    />
    <SeatTypeDetails
      seats={props.data.assistant_seats}
      seatsUsed={props.data.assistant_seats_used}
      seatTypeName='Assistant Seats'
      status={props.data.status}
    />
  </div>
)

LicenseSeatData.propTypes = {
  data: PropTypes.shape({
    advisor_seats: PropTypes.number,
    advisor_seats_used: PropTypes.number,
    assistant_seats: PropTypes.number,
    assistant_seats_used: PropTypes.number,
    client_seats: PropTypes.number,
    client_seats_used: PropTypes.number,
    status: PropTypes.string,
    unlimited: PropTypes.bool
  }),
  isActive: PropTypes.bool
}

export default LicenseSeatData
