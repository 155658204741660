import AppDispatcher from '../../../lib/ep-dispatcher'
import FirmActions from '../actions/FirmActions'
import StorePrototype from '../../../shared_components/StorePrototype'

const ContactCardStore = StorePrototype()

AppDispatcher.register(action => {
  if ([FirmActions.Types.DID_UPDATE_CONTACT_CARD, FirmActions.Types.GOT_CONTACT_CARD].includes(action.actionType))
    ContactCardStore.receiveData(action.data)
})

export default ContactCardStore
