import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {useState, useCallback, useMemo, useLayoutEffect, useRef} from 'react'
import uuid from 'uuid'

import JiclElements from './JiclElements'

import {ELEMENT_IDS_TO_SKIP} from '../../../lib/jiclMainSectionConfiguration'

import {
  elementsPerViewByType,
  filterElementsByView
} from '../../../../../lib/viewTools'
import {rectifyConditionalMaps} from '../../../../../lib/plan_data/item'

const JiclElementsContainer = props => {
  const viewElements = elementsPerViewByType({
    view: props.view,
    elements: props.elements,
    type: 'elements'
  })

  const groupId = useMemo(
    () => (
      props.data.isEmpty() ?
        [uuid.v4(), uuid.v4()] :
        props.data.first().get('group-id').toJS()
    ),
    [props.view]
  )

  const [elements, setElements] = useState(viewElements)
  const [formData, setFormData] = useState(Map())
  const formRef = useRef()

  const updateElements = responses => {
    const updatedElements = rectifyConditionalMaps({
      responses,
      parentElement: Map(),
      childElements: viewElements,
      kits: props.kits,
      listMappings: props.listMappings
    })

    setElements(
      filterElementsByView({
        elements: updatedElements.filterNot(element => ELEMENT_IDS_TO_SKIP.includes(element.get('id'))),
        view: props.view
      }).map(element =>
        (element.get('type') === 'CONTACT' ? element.set('type', 'SIMPLE_CONTACT') : element)
      )
    )
  }

  const onUpdate = useCallback(() => {
    const updatedFormData = formRef.current ? formRef.current.formData() : Map()

    if (!formData.equals(updatedFormData)) {
      props.onResponsesUpdated(updatedFormData)
      updateElements(updatedFormData)
      setFormData(updatedFormData)
    }
  }, [formData])

  useLayoutEffect(() => {
    const updatedFormData = formRef.current.formData()
    props.onResponsesUpdated(updatedFormData)
    updateElements(updatedFormData)
  }, [])

  return (
    <JiclElements
      {...props}
      elements={elements}
      groupId={groupId}
      formData={formData}
      onUpdate={onUpdate}
      ref={formRef}
    />
  )
}

JiclElementsContainer.propTypes = {
  data: PropTypes.instanceOf(Map),
  elements: PropTypes.instanceOf(Map),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  onResponsesUpdated: PropTypes.func,
  view: PropTypes.instanceOf(Map)
}

export default JiclElementsContainer
