import {useEffect} from 'react'
import {createResource} from 'redux-json-api'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import NewLoader from '../../../shared_components/NewLoader'
import PageTitle from '../../../shared_components/PageTitle'

import Logger from '../../../lib/NewLogger'
import sessionUtils from '../lib/sessionUtils'
import routerUtils from '../../../lib/routerUtils'

export const MagicLink = props => {
  useEffect(() => {
    const resource = {
      type: 'magic-sessions',
      attributes: {token: props.params.token}
    }
    const eventPayload = {
      context: 'jicl',
      flavor: 'covid',
      wildcard: 'covid'
    }

    // safe to say they clicked the magic link in their email if they got here
    Logger.log({name: 'click_magic_link', payload: eventPayload})

    props.createResource(resource).catch(error => {
      Logger.log({name: 'invalid_token', payload: eventPayload})
      routerUtils.push('/auth/invalid_token/jicl_view')
    })
  }, []) // run this onetime on load.

  useEffect(() => {
    if (!props.loading) {
      const authorization_token = props.session_data.data[0].attributes['authorization-token']

      sessionUtils.jiclAuthenticate(
        authorization_token,
        props.location.query.nextPath
      )
    }
  }, [props.session_data]) // parse and set the auth token when we hear back from the API

  return (
    <div className='magic-link central-content-box'>
      <PageTitle title='Logging you in...' />
      <NewLoader />
    </div>
  )
}

const mapStateToProps = ({api}) => ({
  loading: !api['magic-sessions'],
  session_data: api['magic-sessions']
})


MagicLink.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.object
  }),
  session_data: PropTypes.shape({
    data: PropTypes.array
  })
}

export default connect(mapStateToProps, {createResource})(MagicLink)
