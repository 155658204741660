import PropTypes from 'prop-types'

const ContactDetails = ({contactCard}) => (
  <div className='email-contact'>
    <h5>Need help? Have questions about what this is?</h5>
    <p>
      Please contact {contactCard.name} for assistance with or questions about this email.
    </p>
    <div className='advisor-contact-card'>
      <div className='contact-card-box'>
        <div className='advisor-desc'>
          <h2 className='advisor-name'>{contactCard.name}</h2>
          <div className='advisor-info'>
            {contactCard.firm ? <span>{contactCard.firm}<br /></span> : ''}
            {contactCard.phone ? <span>{contactCard.phone}<br /></span> : ''}
            {contactCard.email ? <a>{contactCard.email}</a> : ''}
          </div>
        </div>
      </div>
    </div>
    <p className='email-help'>
      Alternatively, the Everplans customer support team can be reached at <a>help.everplans.com</a>
    </p>
  </div>
)

ContactDetails.propTypes = {contactCard: PropTypes.object.isRequired}

ContactDetails.defaultProps = {contactCard: {}}

export default ContactDetails
