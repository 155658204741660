import {fromJS, List, Map} from 'immutable'
import {useRef, useCallback, useState} from 'react'
import PropTypes from 'prop-types'

import Form from '../../../../shared_components/forms/v2/Form'
import Fields from '../../../../shared_components/forms/v2/fields'
import validators from '../../../../shared_components/forms/v2/validators'
import * as Buttons from '../../../../dashboard/src/components/blocks/Buttons'
import {flattenClientActivities} from '../../../../lib/clientActivityTools'

import InviteClientNotification from './InviteClientNotification'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import './addIndividualClient.scss'
import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import {selectedFirmSeatId} from '../../../../lib/proTools'
import SelectAdvisor from './SelectAdvisor'

const AddIndividualClient = props => {
  const formRef = useRef()
  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState(Map())
  const {alwaysNotify, dismissToastNotification} = useNotificationContext()

  const allActivities = flattenClientActivities(props.clientActivities.get('activities', List()))
  const deputyActivity = allActivities.find(activity => activity.get('id', '').toString() === props.params.userId) || Map()

  const basicAddClientInfo = formData => ({
    'client-data': [
      {
        email: formData.getIn(['email', 'value']),
        'first-name': formData.getIn(['name', 'value', 'first-name']),
        'last-name': formData.getIn(['name', 'value', 'last-name'])
      }
    ],
    'advisor-seat-id': selectedFirmSeatId({
      name: formData.getIn(['advisor', 'value']),
      firmSeats: props.firmSeats
    })
  })

  const showSuccessNotification = (client, formData) => {
    const firstName = formData.getIn(['name', 'value', 'first-name'])
    const lastName = formData.getIn(['name', 'value', 'last-name'])
    alwaysNotify.longSuccess(
      <InviteClientNotification
        count={1}
        dismissNotification={dismissToastNotification}
        isIndividual={true}
        message={`${firstName} ${lastName} has been added`}
        recipients={[{client_seat_id: client['client-seat-ids'][0]}]}
        firm={props.firm}
      />
    )
  }

  const addClient = () => {
    const form = formRef.current
    const formData = form.onSubmit()

    if (formData) {
      setProcessing(true)
      const payload = {
        type: 'clients',
        attributes: basicAddClientInfo(formData)
      }

      props
        .createResource(payload)
        .then(response => {
          props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)
          showSuccessNotification(response.data.attributes, formData)
          props.closer()
        })
        .catch(err => {
          setProcessing(false)
          const emailErrors = err.response.data.errors.map(error => error.title)
          setErrors(fromJS({email: emailErrors}))
        })
    }
  }

  const clearServerErrors = useCallback(() => {
    if (!processing || !errors.isEmpty()) setErrors(Map())
  }, [processing, errors])

  return (
    <div className='add-individual-client forms-playground'>
      <Form
        onChange={clearServerErrors}
        className='core'
        ref={formRef}
        errors={errors.toJS()}>
        <Fields.Name
          data={fromJS({
            id: 'name',
            name: 'name',
            value: {
              'first-name': deputyActivity.get('first-name'),
              'last-name': deputyActivity.get('last-name')
            }
          })}
          validator={validators.requiredNameFirstLastValidator}
        />
        <Fields.Email
          data={Map({
            id: 'email',
            name: 'email',
            legend: 'Email',
            value: deputyActivity.get('email')
          })}
          validator={validators.required}
        />
        <SelectAdvisor
          userConfig={props.userConfig}
          firmSeats={props.firmSeats}
          clientSeats={props.clientSeats}
          clientSeatId={props.params.clientSeatId}
          advisorSeatId={props.advisorSeatId}
        />
      </Form>
      <ButtonGroup>
        <Buttons.Cancel onClick={props.closer} />
        <Buttons.Accept onClick={addClient} processing={processing}>
          Add
        </Buttons.Accept>
      </ButtonGroup>
    </div>
  )
}

AddIndividualClient.propTypes = {
  advisorSeatId: PropTypes.number,
  clientActivities: PropTypes.instanceOf(Map),
  closer: PropTypes.func,
  createResource: PropTypes.func,
  readEndpoint: PropTypes.func,
  clientSeats: PropTypes.instanceOf(List),
  firm: PropTypes.instanceOf(Map),
  firmSeats: PropTypes.instanceOf(List),
  params: PropTypes.shape({
    clientSeatId: PropTypes.string,
    userId: PropTypes.string
  }),
  userConfig: PropTypes.instanceOf(Map)
}


export default AddIndividualClient

