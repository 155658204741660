import PropTypes from 'prop-types'

const RemoveActiveClientWarnings = ({firstName, status}) => {
  if (status === 'Active') {
    return (
      <div>
        <li>{firstName} will no longer have a subscription sponsored by you.</li>
        <li>{firstName} will need to purchase a subscription independently in order to keep using Everplans Premium.</li>
      </div>
    )
  } else { return null }
}

RemoveActiveClientWarnings.propTypes = {
  firstName: PropTypes.string,
  status: PropTypes.string
}

export default RemoveActiveClientWarnings
