import PropTypes from 'prop-types'
import {Map, List} from 'immutable'

import './clientsOrWelcome.scss'
import ClientsSummariesContainer from '../clients_table/ClientsSummariesContainer'
import EmptyClients from './EmptyClients'
import ClientsHeader from './ClientsHeader'

const ClientsOrWelcome = props => {
  const hasClients = props.stats.get('total-seats-count') > 0
  return (
    <div className='clients-or-welcome'>
      {hasClients && (
        <div className='content'>
          <ClientsHeader {...props} hasClients={hasClients} />
          <ClientsSummariesContainer
            className='clients-table'
            professionalDesignation={props.firmConfig.get(
              'professional-designation'
            )}
            firmSeats={props.firmSeats}
            readEndpoint={props.readEndpoint}
          />
        </div>
      )}
      {!hasClients && <EmptyClients stats={props.stats} />}
    </div>
  )
}

ClientsOrWelcome.propTypes = {
  firmConfig: PropTypes.instanceOf(Map),
  firmSeats: PropTypes.instanceOf(List),
  readEndpoint: PropTypes.func,
  stats: PropTypes.instanceOf(Map)
}

export default ClientsOrWelcome
