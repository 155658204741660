import {IndexRoute, Redirect, Route} from 'react-router'

import AxiosConnectedComponent from '../../../shared_components/AxiosConnectedComponent'
import {MetaDataContainerWithUserConfigAndUser} from '../../../dashboard/src/components/corp_max/everplan_preview/MetaDataContainer'

import AccountInformationController from '../components/accountInformation/AccountInformationController'
import UnsubscribeComplete from '../components/unsubscribe/UnsubscribeComplete'

// //////////////////////////////////////////////////////////////////////////////
// Interstitials

import PreInterstitialsHook from '../components/interstitials/PreInterstitialsHook'
import BeforeWeContinue from '../components/interstitials/BeforeWeContinue'
import InterstitialUserContainer from '../components/interstitials/InterstitialUserContainer'


// CorpMax Deputy invite interstitial
import DeputiesInviteInterstitialController from '../components/interstitials/DeputiesInviteInterstitialController'
import DeputyInviteInterstitial from '../components/interstitials/DeputyInviteInterstitial'

// Client invite interstitial:
import ClientInviteAccepted from '../components/client_invite_interstitial/ClientInviteAccepted'
import ClientInviteInterstitialController from '../components/client_invite_interstitial/ClientInviteInterstitialController'
import RespondToClientInviteController from '../components/client_invite_interstitial/RespondToClientInviteController'

// Add household member interstitial:
import AddHouseholdMemberInterstitialController from '../components/add_hh_member_interstitial/AddHouseholdMemberInterstitialController'
import AddHouseholdMemberInterstitial from '../components/add_hh_member_interstitial/AddHouseholdMemberInterstitial'
import HouseholdMemberAddedConfirmation from '../components/add_hh_member_interstitial/HouseholdMemberAddedConfirmation'

// CancelDeathReportInterstitial:
import CancelDeathReportInterstitial from '../components/interstitials/CancelDeathReportInterstitial'
import CanceledDeathReport from '../components/interstitials/CanceledDeathReport'

// Add item permissions interstitial:
import RequestItemPermissionInviteController from '../components/interstitials/request_item_permissions_invite/RequestItemPermissionInviteController'

import InterstitialTfaIntro from '../components/interstitials/InterstitialTfaIntro'
import InterstitialOpenIdConnectLink from '../components/interstitials/InterstitialOpenIdConnectLink'
import InterstitialNewSubscription from '../components/interstitials/InterstitialNewSubscription'

import PendingRequiredActionCheck from '../components/PendingRequiredActionCheck'
import NewEverplanController from '../../../shared_components/new_everplan_annoucement/NewEverplanController'

// //////////////////////////////////////////////////////////////////////////////
// MUMPS

import NewSubscriptionController from '../components/new_subscription/NewSubscriptionController'

// Lifetime Subscription
import LifetimeSubscriptionPaymentStatusController from '../components/lifetime_subscription/LifetimeSubscriptionPaymentStatusController'

import RelayController from '../components/relay/RelayController'

import TermsOfServiceAndPrivacyPolicyContainer from '../../../shared_components/terms_of_service_and_privacy_policy/TermsOfServiceAndPrivacyPolicyContainer'


export default (
  /* The AxiosConnectedComponent is only used to setAxiosConfig headers used to make APIv2 calls. */
  <Route component={AxiosConnectedComponent}>
    <Redirect from='letter/*' to='cm/dashboard' />
    <Redirect from='download/*' to='cm/dashboard' />
    <Route path='account-settings/:userId' component={AccountInformationController} />
    <Route path='lifetime-subscription' component={LifetimeSubscriptionPaymentStatusController} />
    <Route component={MetaDataContainerWithUserConfigAndUser}>
      <Route component={PendingRequiredActionCheck}>
        <Route
          component={TermsOfServiceAndPrivacyPolicyContainer}
          path='terms-of-service-and-privacy-policy'
        />
        <Route path='profile-completion' component={NewEverplanController} />
        <Route component={InterstitialUserContainer}>
          <Route component={PreInterstitialsHook} path='pending-requests'>
            <Route component={RequestItemPermissionInviteController} path='request-item-permissions/:requestId' />
            <Route component={BeforeWeContinue} path='before-we-continue' />
            <Route path='cancel-death-report'>
              <IndexRoute component={CancelDeathReportInterstitial} />
              <Route component={CanceledDeathReport} path='done' />
            </Route>
            <Route component={AddHouseholdMemberInterstitialController} path='households/:addHouseholdMemberInviteId'>
              <Route component={AddHouseholdMemberInterstitial} path='details' />
              <Route component={HouseholdMemberAddedConfirmation} path='approved' />
            </Route>
            <Route component={ClientInviteInterstitialController} path='clients/:clientInviteId'>
              <Route component={RespondToClientInviteController} path='details' />{/* User can accept or decline the invite */}
              <Route component={ClientInviteAccepted} path='accepted' />{/* Confirmation of client invite acceptance */}
            </Route>
            <Route component={DeputiesInviteInterstitialController} path='migrated-deputies'>
              <Route component={DeputyInviteInterstitial} path=':deputyInviteId' />
            </Route>
            <Route component={InterstitialTfaIntro} path='mandatory-tfa' />
            <Route component={InterstitialOpenIdConnectLink} path='advisor-sso-setup' />
            <Route path='new-subscription' component={InterstitialNewSubscription} />
          </Route>
        </Route>
      </Route>
      <Route path='new-subscription' component={NewSubscriptionController} />
    </Route>
    <Route path='unsubscribe'>
      <Route path='deputy-updates' component={UnsubscribeComplete} />
    </Route>
    <Route path='relay/engagements' component={RelayController} />
  </Route>
)
