import PropTypes from 'prop-types'

const ContactAccountManagerNote = ({isAccountManager}) => {
  if (isAccountManager) {
    return <noscript />
  } else {
    return (
      <p className='note'>
        <strong>Note</strong>: If you'd like to change this setting, please contact your account manager.
      </p>
    )
  }
}

ContactAccountManagerNote.propTypes = {
  isAccountManager: PropTypes.bool
}

export default ContactAccountManagerNote
