import {useEffect, useState, useCallback, useRef} from 'react'
import classnames from 'classnames'
import {Map, List} from 'immutable'
import {connect} from 'react-redux'
import {readEndpoint} from 'redux-json-api'
import PropTypes from 'prop-types'

import * as Buttons from '../../../../dashboard/src/components/blocks/Buttons'
import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import Form from '../../../../shared_components/forms/v2/Form'
import Loader from '../../../../shared_components/NewLoader'
import HighComplianceNote from '../customize_invite/HighComplianceNote'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'
import ToAndFrom from '../customize_invite/views/ToAndFrom'
import PersonalMessage from '../customize_invite/views/PersonalMessage'
import EmailPreviews from '../customize_invite/EmailPreviews'

import {cleanResponse} from '../../../../dashboard/src/components/corp_max/redux/apiHelper'
import {currentUserConfig} from '../../../../lib/userTools'
import {compareAsStrings, findRawResourceByAttributeId} from '../../../../lib/plan_data/userData'
import {pluralize} from '../../../../lib/tools'

import './customizeBulkClientInviteController.scss'

export const CustomizeBulkClientInviteController = props => {
  const [showRecipients, setShowRecipients] = useState(false)
  const [processing, setProcessing] = useState(false)
  const isHighCompliance = props.firmConfig.get('compliance-level') === 'high'
  const formRef = useRef()

  useEffect(() => {
    if ((props.clientInvite.isEmpty() && !props.recipientSeats.isEmpty()) || props.contactCard.isEmpty()) {
      const clientSeat = props.recipientSeats.first()

      props.readEndpoint(`client-invites/${clientSeat.get('invite-id')}`).then(
        () => props.readEndpoint(`advisor-contact-cards/${clientSeat.get('everplan-id')}`)
      )
    }
  }, [props.clientInvite.size, props.recipientSeats.size, props.contactCard.size])

  const toggleShow = useCallback(() => setShowRecipients(!showRecipients), [showRecipients])

  const submit = useCallback(event => {
    if (event)
      event.preventDefault()

    if (formRef.current) {
      const formData = formRef.current.onSubmit()

      if (formRef.current.isValid) {
        setProcessing(true)

        const payload = {
          type: 'bulk-client-invites',
          attributes: {
            recipients: props.recipients.toJS(),
            'invite-text': formData.getIn(['personal-message', 'value']),
            'save-edits': formData.getIn(['save-edits', 'value'])
          }
        }

        props.sendInvitations(payload, () => setProcessing(false))
      }
    }
  }, [props.sendInvitations])

  return (
    <Loader loading={props.clientInvite.isEmpty()}>
      <div className='customize-bulk-client-invite-controller forms-playground'>
        <h3 className='customize-email-header'>
          {props.recipientSeats.first().get('status') === 'Pending' ? 'Resend' : 'Send'} {pluralize('Invitation', props.recipientSeats.size)}
        </h3>
        {props.error && <TextGutterMedium className={classnames({error: props.error})}>{props.error}</TextGutterMedium>}
        <div>
          <HighComplianceNote isHighCompliance={isHighCompliance} />
          <ToAndFrom {...props} isHighCompliance={isHighCompliance} showRecipients={showRecipients} toggleShow={toggleShow} />
          <Form className={classnames('core', {'high-compliance': isHighCompliance})} ref={formRef}>
            {
              !isHighCompliance && (
                <PersonalMessage advisor={props.advisor} firmUserConfig={props.firmUserConfig} userConfig={props.userConfig} />
              )
            }
          </Form>
          <EmailPreviews
            isHighCompliance={isHighCompliance}
            clientInvite={props.clientInvite}
            contactCard={props.contactCard}
            disclosure={props.disclosure}
          />
          <ButtonGroup>
            <Buttons.Cancel onClick={props.closer}>Cancel</Buttons.Cancel>
            <Buttons.Accept processing={processing} onClick={submit}>Send Invitation</Buttons.Accept>
          </ButtonGroup>
        </div>
      </div>
    </Loader>
  )
}

CustomizeBulkClientInviteController.propTypes = {
  advisor: PropTypes.instanceOf(Map),
  contactCard: PropTypes.instanceOf(Map),
  clientInvite: PropTypes.instanceOf(Map),
  disclosure: PropTypes.instanceOf(Map),
  clientSeats: PropTypes.instanceOf(List),
  error: PropTypes.string,
  closer: PropTypes.func,
  sendInvitations: PropTypes.func,
  firmConfig: PropTypes.instanceOf(Map),
  firmUserConfig: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map),
  recipientSeats: PropTypes.instanceOf(List),
  readEndpoint: PropTypes.func,
  recipients: PropTypes.instanceOf(List)
}

const mapStateToProps = ({api}, {clientSeats, recipients}) => {
  const userConfig = currentUserConfig(api)
  const recipientSeats = clientSeats.filter(seat => recipients.map(recipient => recipient.get('client-seat-id')).includes(parseInt(seat.get('id'), 10)))
  const clientInvite = cleanResponse(api['client-invites']).find(
    invite => compareAsStrings(invite.get('id'), recipientSeats.first().get('invite-id'))
  ) || Map()

  return ({
    userConfig,
    recipientSeats,
    clientInvite,
    contactCard: findRawResourceByAttributeId({
      rawResource: api['advisor-contact-cards'],
      attribute: 'everplan-id',
      id: recipientSeats.first().get('everplan-id')
    }),
    disclosure: findRawResourceByAttributeId({rawResource: api.disclosures, attribute: 'disclosable-id', id: userConfig.get('firm-id')}),
    firmConfig: findRawResourceByAttributeId({rawResource: api['firm-configs'], attribute: 'firm-id', id: userConfig.get('firm-id')}),
    firmUserConfig: findRawResourceByAttributeId({rawResource: api['firm-user-configs'], attribute: 'user-id', id: userConfig.get('id')})
  })
}

export default connect(mapStateToProps, {readEndpoint})(CustomizeBulkClientInviteController)
