import PropTypes from 'prop-types'

import Fields from '../../../../../../shared_components/forms/v2/fields'
import validators from '../../../../../../shared_components/forms/v2/validators'

import {fromJS} from 'immutable'

const WhiteLabelFields = ({cobranding}) => (
  <div className='white-label-fields'>
    <Fields.Text
      data={
        fromJS({
          id: 'cobranding-background-color',
          legend: 'Background Color:',
          name: 'cobranding-background-color',
          value: cobranding['cobranding-background-color'] || '#FFFFFF'
        })
      }
      validator={validators.hexCodeValidator}
    />
    <Fields.Text
      data={
        fromJS({
          id: 'cobranding-text-color',
          legend: 'Text Color:',
          name: 'cobranding-text-color',
          value: cobranding['cobranding-text-color'] || '#515151'
        })
      }
      validator={validators.hexCodeValidator}
    />
  </div>
)

WhiteLabelFields.propTypes = {
  cobranding: PropTypes.object
}

export default WhiteLabelFields
