import PropTypes from 'prop-types'

import Fields from '../../../../../../shared_components/forms/v2/fields'
import validators from '../../../../../../shared_components/forms/v2/validators'

import {fromJS} from 'immutable'


const SharedCobrandingFields = ({cobranding}) => (
  <div className='shared-cobranding-fields'>
    <Fields.Text
      data={
        fromJS({
          id: 'cobranding-url-string',
          legend: 'Custom URL:',
          name: 'cobranding-url-string',
          value: cobranding['cobranding-url-string']
        })
      }
      validator={validators.urlStringValidator}
    />
  </div>
)

SharedCobrandingFields.propTypes = {
  cobranding: PropTypes.object
}

export default SharedCobrandingFields
