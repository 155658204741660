import {Link} from 'react-router'
import HorizontalList from './HorizontalList'
import PropTypes from 'prop-types'

import './navTypes.scss'

const FirmNav = props => {
  var navItems = [
    <Link activeClassName='active' key='firm-users' to={`/pro/firm/${props.firmId}/users`}>FIRM USERS</Link>,
    <Link activeClassName='active' key='settings' to={`/pro/firm/${props.firmId}/settings`}>SETTINGS</Link>
  ]
  if (props.isAccountManager)
    navItems.unshift(<Link activeClassName='active' key='license' to={`/pro/firm/${props.firmId}/licenses`}>LICENSE</Link>)
  return (
    <HorizontalList className='firm-navigation'>
      {navItems}
    </HorizontalList>
  )
}

FirmNav.defaultProps = {
  firmId: '',
  isAccountManager: false
}

FirmNav.propTypes = {
  firmId: PropTypes.string.isRequired,
  isAccountManager: PropTypes.bool.isRequired
}

export default FirmNav
