import InfoModal from './InfoModal'
import LearnMore from '../../../../shared_components/LearnMore'
import Modal from '../../../../shared_components/Modal'


export default class PrivacyLearnMore extends InfoModal {
  constructor(props) {
    super(props)
    this.setInitialState(props)
  }

  render() {
    return (
      <span>
        <a onClick={this.showModal} className='branding'>Learn more</a>
        <Modal visible={this.state.showModal} closer={this.closeModal}>
          <LearnMore advisorName={this.props.data.advisor_name} />
        </Modal>
      </span>
    )
  }
}
