import {Fragment, useCallback} from 'react'
import PropTypes from 'prop-types'

const ProCsvReportDownload = props => {
  const prepareReportDownload = useCallback(
    () => props.createResource({type: 'pro-csv-reports', attributes: {}}),
    []
  )

  return (
    <Fragment>
      {props.hasClients && (
        <li>
          <a onClick={prepareReportDownload}>Download CSV Summary</a>
        </li>
      )}
    </Fragment>
  )
}

ProCsvReportDownload.propTypes = {
  createResource: PropTypes.func,
  hasClients: PropTypes.bool
}

export default ProCsvReportDownload
