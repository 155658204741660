import '../web/SessionApi'
import '../web/TfaApi'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import AuthAppClassSideEffect from './AuthAppClassSideEffect'
import {GlobalAnnouncementContext} from '../../../shared_components/ApplicationContainer'
import GlobalFooter from '../../../shared_components/footer/Footer'
import Header from '../../../shared_components/header/Header'
import DisclosureFooter from '../../../shared_components/DisclosureFooter'
import PageTitle from '../../../shared_components/PageTitle'
import SessionStore from '../stores/SessionStore'
import routerUtils from '../../../lib/routerUtils'

import './authApp.scss'


export default class AuthApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUser: SessionStore.getState().currentUser,
      loading: true
    }
    this.onUpdate = this.onUpdate.bind(this)
  }
  getChildContext() { return {location: this.props.location} /* Think about keeping this high-level... */ }
  componentDidMount() {
    SessionStore.addChangeListener(this.onUpdate)
  }
  onUpdate() {
    this.setState({
      currentUser: SessionStore.getState().currentUser,
      loading: false
    })
  }
  componentWillUnmount() { SessionStore.removeChangeListener(this.onUpdate) }

  displayTheFooter() {
    return this.props.location.pathname !== '/auth/sign-up' &&
    this.props.location.pathname !== '/auth/sign_in'
  }

  render() {
    return (
      <div className={this.props.className}>
        <AuthAppClassSideEffect className='auth' />
        <div className={classnames('auth-container wrapper', {'with-global-announcement-banner': this.context.globalAnnouncementText})}>
          <PageTitle title='Log In to Your Everplan' />
          <div id='header-one'><Header /></div>
          <main className='main-content'>
            {routerUtils.childrenWithProps(this.props.children, {currentUser: this.state.currentUser})}
          </main>
          <div className='push' />
        </div>
        <DisclosureFooter />
        {
          this.displayTheFooter() &&
            <GlobalFooter location={this.props.location} />
        }
      </div>
    )
  }
}

AuthApp.childContextTypes = {
  router: PropTypes.object,
  location: PropTypes.object
}

AuthApp.contextType = GlobalAnnouncementContext

AuthApp.propTypes = {
  location: PropTypes.object
}
