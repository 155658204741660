// TODO: this component should only be used if it is being displayed inline. If you want a modalized version use the
// ClientPrivacyNoticeModal
const LearnMore = props => {
  return (
    <span>
      <div className='privacy-modal'>
        <h3>Privacy Notice</h3>
        <p>In order to help you progress with Everplans, <strong>{props.advisorName} will have access to the following information</strong> about how you are using this website:</p>
        <ul className='modal-li'>
          <li>What parts of your Everplan you have completed</li>
          <li>Whether you have uploaded documents</li>
          <li>Changes to your username or email address</li>
          <li>Date and time of your log-ins</li>
        </ul>
        <p>Unless you explicitly grant read/write Deputy permission, <strong>{props.advisorName} will not be able to:</strong></p>
        <ul className='modal-li'>
          <li>Download, open, or view specific information that you enter into your Everplan, including account numbers, notes, addresses, contacts, preferences, or any other items that you chose to add.</li>
          <li>Download, open, or view the contents of any files you've added to your Everplan.</li>
        </ul>
        <p>Lastly, in the event that your death is reported to Everplans by another person, your advisor will be notified.</p>
        <hr></hr>
        <p>See the complete <a href='https://www.everplans.com/privacy-policy' target='_blank'>Everplans Privacy Policy</a>.</p>
      </div>
    </span>
  )
}

export default LearnMore
