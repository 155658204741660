import PropTypes from 'prop-types'

import {pluralize} from '../../../../../lib/tools'

const Status = ({accountManager, row, userType}) => (
  <span>
    {row.status}
    {
      accountManager &&
      row.status === 'ACTIVE' &&
      userType === 'Advisor' &&
      <div>{row.active_client_count || 0} {pluralize('client', row.active_client_count)} active</div>
    }
  </span>
)

Status.propTypes = {
  accountManager: PropTypes.bool,
  row: PropTypes.shape({
    active_client_count: PropTypes.number,
    status: PropTypes.string
  }),
  userType: PropTypes.string
}

export default Status
