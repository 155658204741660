import PropTypes from 'prop-types'

import Fields from '../../../../../../shared_components/forms/v2/fields'

import {fromJS} from 'immutable'


const EverplansBrandedFields = ({cobranding}) => (
  <div className='everplans-branded-fields'>
    <Fields.Text
      data={
        fromJS({
          id: 'cobranding-prefix',
          legend: 'Custom prefix:',
          name: 'cobranding-prefix',
          value: cobranding['cobranding-prefix'] || 'Brought to you courtesy of'
        })
      }
    />
  </div>
)

EverplansBrandedFields.propTypes = {
  cobranding: PropTypes.object
}

export default EverplansBrandedFields
