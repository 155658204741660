import PropTypes from 'prop-types'

import ButtonGroup from '../core/buttons/ButtonGroup'
import * as Links from '../Links'
import {Accept, Cancel} from '../../dashboard/src/components/blocks/Buttons'

import attorney from '../../shared_assets/attorney.png'
import './termsOfServiceAndPrivacyPolicy.scss'

const TermsOfServiceAndPrivacyPolicy = props => (
  <div className='terms-of-service-and-privacy-policy'>
    <div className='content'>
      <h1 className='title'>Everplans’ Updated Terms of Service and Privacy Policy</h1>
      <section className='body'>
        <div className='img-container'>
          <img alt='' src={attorney} />
        </div>
        <div className='body-text'>
          <p>
            We recently made some changes to our Terms of Service and Privacy Policy. We invite you to review
            all of the changes to our {<Links.Terms target='_blank' />} and <Links.Privacy target='_blank'>Privacy Policy</Links.Privacy> in their entirety to understand how they may impact you.
          </p>
          <p>
            As always, we are here to help. Please write to us at
            <a href='mailto:help@everplans.com'> help@everplans.com</a> if you have any questions or need assistance.
          </p>
        </div>
      </section>
      <ButtonGroup>
        <Cancel onClick={props.onDecline} processing={props.declining}>Decline</Cancel>
        <Accept onClick={props.onAccept} processing={props.accepting}>Accept</Accept>
      </ButtonGroup>
    </div>
  </div>
)

TermsOfServiceAndPrivacyPolicy.propTypes = {
  accepting: PropTypes.bool,
  declining: PropTypes.bool,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func
}

export default TermsOfServiceAndPrivacyPolicy
