// This is to display questions in the JICL flow that are related to Assessment
// and not Response.
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Accept, Cancel} from '../../../../../dashboard/src/components/blocks/Buttons'
import {TextGutterLarge} from '../../../../../dashboard/src/components/blocks/Texts'

const JiclAssessment = props => {
  const assessmentConfig = props.sectionConfig.get('assessmentConfig')

  return (
    <div className='jicl-assessment'>
      {assessmentConfig.get('notes').map(paragraph => (
        <TextGutterLarge key={paragraph}>{paragraph}</TextGutterLarge>
      ))}
      <div className='assessment-button-group'>
        <Accept onClick={() => props.onAssessmentSubmit(true)}>
          {assessmentConfig.get('yesButtonText')}
        </Accept>
        <Cancel onClick={() => props.onAssessmentSubmit(false)}>
          {assessmentConfig.get('noButtonText')}
        </Cancel>
        {
          assessmentConfig.get('notSure') &&
            <Cancel onClick={() => props.onAssessmentSubmit(false)}>
              {assessmentConfig.get('notSure')}
            </Cancel>
        }
      </div>
    </div>
  )
}

JiclAssessment.propTypes = {
  onAssessmentSubmit: PropTypes.func,
  sectionConfig: PropTypes.instanceOf(Map)
}

export default JiclAssessment
