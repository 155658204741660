import AsSeenMap from './as_seen/AsSeenMap'
import SecureAndAccredited from './SecureAndAccredited'

import './legacyRightRail.scss'


const LegacyRightRail = () => (
  <div className='legacy-right-rail'>
    <AsSeenMap />
    <SecureAndAccredited />
  </div>
)

export default LegacyRightRail
