import classnames from 'classnames'
import PropTypes from 'prop-types'

import {BoxGutterMedium} from './Boxes'
import * as Badges from './Badges'
import {TextGutterMedium} from './Texts'

import './boxWithBadge.scss'


const BoxWithBadge = ({badgeType, children, heading, ...otherProps}) => (
  <BoxGutterMedium {...otherProps} className={classnames('core', 'box-with-badge', otherProps.className)} wrap={true}>
    <Badges.BadgePicker badgeType={badgeType} />
    {heading && <h3>{heading}</h3>}
    <TextGutterMedium>{children}</TextGutterMedium>
  </BoxGutterMedium>
)

BoxWithBadge.propTypes = {
  badgeType: PropTypes.oneOf(['article', 'checklist', 'guide']).isRequired,
  heading: PropTypes.string
}

export default BoxWithBadge
