import {useCallback, useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {createResource, readEndpoint} from 'redux-json-api'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Closer from '../../../../shared_components/core/closer/Closer'
import routerUtils from '../../../../lib/routerUtils'
import Modals from '../../../../shared_components/core/modals/Modals'

import {currentUserConfig, userFirmOrganization} from '../../../../lib/userTools'
import {cleanResponse} from '../../../../dashboard/src/components/corp_max/redux/apiHelper'
import {filterRawResourceByAttributeId} from '../../../../lib/plan_data/userData'
import {pluralize} from '../../../../lib/tools'
import {isActiveAdvisorSeat} from '../../../../lib/proTools'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import './bulkSendOrResendController.scss'

const PRE_HEADER_TEXT = {draft: 'INVITE DRAFT CLIENTS', pending: 'RESEND PENDING INVITATIONS'}

const BulkSendOrResendController = props => {
  const [selectedAdvisor, setSelectedAdvisor] = useState(Map())
  const [recipients, setRecipients] = useState(List())
  const [error, setError] = useState()
  const {alwaysNotify} = useNotificationContext()

  useEffect(() => {
    const firmId = props.userConfig.get('firm-id')

    props.readEndpoint(`firms/${firmId}?include=client-seats`)
  }, [])

  const closeModal = useCallback(event => {
    if (event)
      event.preventDefault()

    routerUtils.push('pro/clients')
  }, [])

  const next = (advisor, clients) => {
    setSelectedAdvisor(advisor)
    setRecipients(clients)

    routerUtils.push('pro/clients/bulk-actions/customize')
  }

  const closer = useCallback(() => <Closer closer={closeModal} />, [])

  const sendInvitations = (payload, callback) => {
    setError('')
    props.createResource(payload).then(response => {
      closeModal()
      props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)

      const count = response.data.attributes['invites-sent']

      alwaysNotify.longSuccess(
        <span>
          {count} {count === 1 ? 'client has' : 'clients have'} been invited.
          Their {count === 1 ? 'status is' : 'statuses are'} PENDING until invitations are accepted.
        </span>
      )
    }).catch(err => {
      if (err.response)
        setError(err.response.data.errors[0].detail)

      alwaysNotify.shortError(`Oops! something went wrong while trying to invite your ${pluralize('client', recipients.size)}`)
    }).finally(() => callback && callback())
  }

  return (
    <div className='bulk-send-or-resend-controller'>
      <Modals.LinkPopUpModalLarge closerComponent={closer} showModalOnMount>
        {
          routerUtils.childrenWithProps(
            props.children,
            {
              next,
              recipients,
              sendInvitations,
              error,
              advisor: selectedAdvisor,
              preHeaderText: PRE_HEADER_TEXT[props.params.type],
              clientSeats: props.clientSeats,
              firmSeats: props.firmSeats.filter(seat => isActiveAdvisorSeat(seat)),
              closer: closeModal,
              firmConfig: props.firmConfig,
              loading: props.firmSeats.isEmpty() || props.clientSeats.isEmpty(),
              organization: props.organization,
              userConfig: props.userConfig
            }
          )
        }
      </Modals.LinkPopUpModalLarge>
    </div>
  )
}

BulkSendOrResendController.propTypes = {
  clientSeats: PropTypes.instanceOf(List),
  createResource: PropTypes.func,
  readEndpoint: PropTypes.func,
  firmConfig: PropTypes.instanceOf(Map),
  firmSeats: PropTypes.instanceOf(List),
  params: PropTypes.shape({type: PropTypes.string}),
  organization: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map),
  showModal: PropTypes.bool
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)

  return {
    userConfig,
    clientSeats: cleanResponse(state.api['client-seats']),
    organization: userFirmOrganization({...state}),
    firmSeats: filterRawResourceByAttributeId({
      attribute: 'firm-id',
      rawResource: state.api['firm-seats'],
      id: userConfig.get('firm-id')
    })
  }
}

export default connect(mapStateToProps, {createResource, readEndpoint})(BulkSendOrResendController)
