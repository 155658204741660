export default [
  {
    id: 'b79bd1ce-e94c-4c7a-95e0-fb372e6891fa',
    elements: [
      {
        id: 'b0f476b4-f4f4-4818-ad37-859c0331945c',
        name: 'Type of Phone Number',
        ordinality: 'SELECT-OTHER',
        type: 'TEXT'
      },
      {
        id: 'c7f8f984-2fac-41e7-87be-4f87caeb7266',
        name: 'Phone Number',
        ordinality: 'NONE',
        type: 'TEXT'
      }
    ],
    name: 'Phone Number'
  },
  {
    id: '3fe99584-7784-42b9-b336-f0364eb3c953',
    elements: [
      {
        id: '9f718686-5409-4fbc-a31b-b104b4076b2d',
        name: 'Will Basic Info',
        ordinality: 'NONE',
        type: '4d6de633-032e-4d51-aadc-1ee6e4afa6db',
        legacyQuestionFieldId: 1
      },
      {
        addMore: true,
        id: '8886be2d-bc06-48f0-9492-9a205f86e5ce',
        name: 'Executors',
        ordinality: 'NONE',
        type: 'e10f1b41-236a-4197-a8e7-9677bde4cf2d',
        legacyQuestionFieldId: 2
      },
      {
        addMore: true,
        id: '6ef1a28c-2c8a-4186-8e42-b9cb8b4357e0',
        name: 'Guardians',
        ordinality: 'NONE',
        type: 'f8ed3d81-9ed3-4cf9-806d-46ffbb9e3ecf',
        legacyQuestionFieldId: 3
      }
    ],
    name: 'Will'
  },
  {
    id: '7ec2fd95-a793-4249-a011-7083f6e48449',
    elements: [
      {
        id: '86988c82-d744-465d-81f3-888e324a28a4',
        name: 'Name of plumbus',
        ordinality: 'NONE',
        type: 'TEXT-MULTI',
        legacyQuestionFieldId: 2111
      }
    ],
    name: 'Plumbus'
  },
  {
    id: '1b79f8cf-bb13-4b8d-8505-d703d263dbf9',
    elements: [
      {
        id: 'e9f9175b-6833-4731-ac73-740d74dafef9',
        name: 'First Name',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: '13bde0f4-c3a7-4117-aabf-3a998e5645f1',
        name: 'Middle Name',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: '9f4070b1-9eed-4fb3-ae27-ba91cd49359a',
        name: 'Last Name',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: 'a0834191-c771-4597-8a29-17a8a9eaa3c6',
        name: 'Prefix/Title',
        ordinality: 'SELECT-OTHER',
        type: 'TEXT'
      },
      {
        id: '981ecf22-6222-43b5-bed9-8212a57acded',
        name: 'Suffix',
        ordinality: 'SELECT-OTHER',
        type: 'TEXT'
      },
      {
        id: 'c5295abb-1286-4be6-a79e-e7d0ecac8637',
        name: 'Birthday',
        ordinality: 'NONE',
        type: 'Date'
      },
      {
        id: 'a655d192-df97-4156-9964-015213fcf306',
        name: 'Relationship',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: '4730df21-687a-48ad-858b-9bc7feeecb42',
        name: 'Gender',
        ordinality: 'SELECT',
        type: 'TEXT'
      },
      {
        id: 'a7d9c750-d90a-4598-aa04-c2379c3cbd90',
        name: 'Photo',
        ordinality: 'NONE',
        type: 'IMAGE'
      },
      {
        id: 'd8d0e66a-d929-417d-961b-d4d570899858',
        name: 'Address',
        ordinality: 'NONE',
        type: '1fce3a22-44ca-4b82-bbc6-3ab2164e94f4'
      },
      {
        id: '8e32d16f-c260-4276-989e-b61783ce6c1e',
        name: 'Phone',
        ordinality: 'NONE',
        type: 'b79bd1ce-e94c-4c7a-95e0-fb372e6891fa'
      },
      {
        id: 'e43fe884-9b2c-4b5f-ad4e-94fd19fc7ade',
        name: 'Email',
        ordinality: 'NONE',
        type: '643cd512-5ff6-4029-8c90-7ecf57a21706'
      },
      {
        id: 'a6d69baf-470a-4b54-b923-f817e33eaef5',
        name: 'Website',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: '74a1a06a-9cca-459a-885a-6e6f58d487f0',
        name: 'Notes',
        ordinality: 'NONE',
        type: 'TEXT-MULTI'
      }
    ],
    name: 'Contact'
  },
  {
    id: 'ad2f8ab9-b77e-4865-be09-2f31702e16df',
    elements: [
      {
        id: 'b32457c2-9b3b-470e-abd8-0f7db179b820',
        name: 'Who is it?',
        ordinality: 'NONE',
        type: 'CONTACT',
        legacyQuestionFieldId: 2121
      },
      {
        id: 'b47f6a4b-dd52-43dd-bc05-3e5e3b706141',
        name: 'Notes & Instructions',
        ordinality: 'NONE',
        type: 'TEXT-MULTI',
        legacyQuestionFieldId: 2001
      },
      {
        id: 'ca03c7a3-10a8-4374-89b0-4b085f6ccdef',
        name: 'Plumbus',
        type: 'FILE',
        legacyQuestionFieldId: 2211
      }
    ],
    name: 'Beneficiary(s)'
  },
  {
    id: '643cd512-5ff6-4029-8c90-7ecf57a21706',
    elements: [
      {
        id: '32c84efa-8863-4de7-acc0-8463c6fd08b6',
        name: 'Type of email',
        ordinality: 'SELECT-OTHER',
        type: 'TEXT'
      },
      {
        id: 'a0666300-a646-43b5-9403-13dce757fe30',
        name: 'Email addres',
        ordinality: 'NONE',
        type: 'TEXT'
      }
    ],
    name: 'Email address'
  },
  {
    id: '4d6de633-032e-4d51-aadc-1ee6e4afa6db',
    elements: [
      {
        'list-mapping': '983e94c9-7e55-48f8-b00a-5f6c15a0daf6',
        id: 'c4b70a1e-202e-4491-84e6-341db8355b68',
        name: 'How did you create your will',
        type: 'TEXT',
        addMore: false,
        ordinality: 'SELECT-WITH-OTHER',
        selectType: 'Select',
        conditional: false
      },
      {
        id: '8549c85b-879b-4c84-a913-9f4128c3347e',
        name: 'When was it last updated',
        ordinality: 'NONE',
        type: 'DATE',
        legacyQuestionFieldId: 1002
      },
      {
        id: '1e73cb0e-283a-4694-b5b9-9383fb4a9188',
        name: 'Where do you keep the signed document',
        ordinality: 'NONE',
        type: 'TEXT',
        legacyQuestionFieldId: 899
      },
      {
        id: '9fa4fc14-d279-4ef8-a9f4-e1df30a4ede0',
        name: 'Upload of will',
        ordinality: 'NONE',
        type: 'FILE'
      },
      {
        id: '8deea75f-5548-49bf-b41f-465ef9f41d28',
        name: 'Notes and instructions',
        ordinality: 'NONE',
        type: 'TEXT-MULTI'
      },
      {
        id: '70b20ddb-daf6-44fd-ab6c-be547393dea8',
        name: 'Warren',
        type: 'TEXT'
      }
    ],
    name: 'Will Basic Info'
  },
  {
    id: '6b2f4ec7-5d3f-4da4-804f-c4f9906ba840',
    elements: [
      {
        id: '1b1f9c2c-1f40-48d0-b698-2a395f564023',
        name: 'Name of Insurance Company',
        ordinality: 'NONE',
        type: 'TEXT',
        placeholder: 'e.g KBL',
        legacyQuestionFieldId: 1053
      },
      {
        id: '272a5db0-be28-401c-a379-a72a48ea6f62',
        name: 'Policy Document File',
        ordinality: 'NONE',
        type: 'FILE',
        legacyQuestionFieldId: 1064
      },
      {
        id: '08f2db3f-afbf-4f49-b003-b2cbc0b7a8e4',
        name: 'Location of original policy',
        ordinality: 'NONE',
        type: 'TEXT',
        legacyQuestionFieldId: 1063
      },
      {
        id: '304d44a6-fb19-497b-a303-82bf60c26eb8',
        name: 'Type of policy',
        ordinality: 'SELECT-OTHER',
        type: 'TEXT',
        legacyQuestionFieldId: 1054
      },
      {
        id: '1eb83b3e-7fc3-40a4-92f9-490c87a032da',
        name: 'Policy Number',
        ordinality: 'NONE',
        type: 'TEXT',
        legacyQuestionFieldId: 1058
      },
      {
        id: '72dc9586-caa0-461b-9d15-0cd49808ff7b',
        name: 'Name of insured person',
        ordinality: 'NONE',
        type: 'CONTACT',
        legacyQuestionFieldId: 1265
      },
      {
        id: 'e20a9795-a790-4587-a9d1-6eb97994ff4c',
        name: 'Policy start date',
        ordinality: 'NONE',
        type: 'DATE',
        legacyQuestionFieldId: 1059
      },
      {
        id: 'bdea1ab9-53ff-4a6e-b240-842eb9549731',
        name: 'Policy expiration date',
        ordinality: 'NONE',
        type: 'DATE',
        legacyQuestionFieldId: 1060
      },
      {
        id: '2b257aa1-168f-4d5f-adb4-ace2dc40b0a2',
        name: 'Value of death benefit',
        ordinality: 'NONE',
        type: 'CURRENCY',
        legacyQuestionFieldId: 1061
      },
      {
        id: '6f5539ec-475a-4277-ad52-ee89166e2969',
        name: 'Does policy have Long-Term Care of Chronic Illness rider?',
        ordinality: 'SELECT',
        type: 'TEXT',
        legacyQuestionFieldId: 1056
      },
      {
        id: 'b90d2243-02c1-4664-b94b-637db231694c',
        name: 'Notes about rider',
        ordinality: 'NONE',
        type: 'TEXT-MULTI',
        legacyQuestionFieldId: 1062
      },
      {
        id: '4120870c-a158-4cf1-a33a-2afb5b0d7bf1',
        name: 'Insurance agent',
        ordinality: 'NONE',
        type: 'CONTACT',
        legacyQuestionFieldId: 1062
      },
      {
        id: '9d33bf54-2943-4dae-932b-fe6894e87095',
        name: 'Notes & instructions',
        ordinality: 'NONE',
        type: 'TEXT-MULTI',
        legacyQuestionFieldId: 1065
      },
      {
        id: '7a606341-b9dc-48b1-b6cc-876b6a7183c5',
        name: 'Plumber',
        type: '7ec2fd95-a793-4249-a011-7083f6e48449',
        legacyQuestionFieldId: 1222
      }
    ],
    name: '(Life insurance Yaniv) Policy Info'
  },
  {
    id: 'e10f1b41-236a-4197-a8e7-9677bde4cf2d',
    elements: [
      {
        id: 'a729f189-49e6-481d-83a2-9dd76bdaefff',
        name: 'Type of Executor',
        ordinality: 'SELECT',
        type: 'TEXT'
      },
      {
        id: '512ef931-a00c-43ee-8ccf-74f6d840bdc1',
        name: 'Who is the Executor',
        ordinality: 'NONE',
        type: 'CONTACT',
        legacyQuestionFieldId: 222
      },
      {
        id: 'c18177cf-b7d7-43ac-b3c2-2b5d47297dd1',
        name: 'Notes & Instructions',
        ordinality: 'NONE',
        type: 'TEXT-MULTI'
      }
    ],
    name: 'Executor'
  },
  {
    id: '1fce3a22-44ca-4b82-bbc6-3ab2164e94f4',
    elements: [
      {
        id: '17027f4f-5b76-4420-8490-53564d61e2ea',
        name: 'Type of address',
        ordinality: 'SELECT-OTHER',
        type: 'TEXT'
      },
      {
        id: '6100bcd8-62fa-4435-b878-f3d4661f642a',
        name: 'Address 1',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: '7f2fd57b-db01-4271-bf5f-0c482a57c922',
        name: 'Address 2',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: '7402b3fb-834b-425e-ab0d-3e02c3d10071',
        name: 'City',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: '22ffa0e8-1913-49db-8e4b-348541c498d8',
        name: 'State',
        ordinality: 'SELECT',
        type: 'STATE'
      },
      {
        id: '63f0407e-4748-499b-ac15-226ad4a4ba99',
        name: 'Zip/Postal',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: '8622818a-97d5-4830-8e24-fb671f39a541',
        name: 'Country',
        ordinality: 'SELECT',
        type: 'TEXT'
      }
    ],
    name: 'Address'
  },
  {
    id: '6ee07675-0658-48b1-988a-bdcd14895076',
    elements: [
      {
        id: '0763a29b-d800-4ddb-8aa7-7dc89244340f',
        name: 'Rick',
        type: '7ec2fd95-a793-4249-a011-7083f6e48449'
      },
      {
        id: '0a7e2a5c-8b88-48dc-83e1-e53f2dd9c15e',
        name: 'Morty',
        ordinality: 'SELECT-OTHER',
        type: 'TEXT-MULTI'
      }
    ],
    name: 'BartTest'
  },
  {
    id: 'bf4e0329-391b-4129-a73e-5342b1dadc4f',
    elements: [
      {
        id: '9e7d94e7-abe4-4e24-875d-eeef84b9b7a2',
        name: 'Name of Service',
        ordinality: 'NONE',
        type: 'TEXT'
      },
      {
        id: 'd63f8cff-14aa-4e0e-9dcc-0c3c24e78c64',
        name: 'Online Service URL',
        ordinality: 'NONE',
        type: 'TEXT'
      }
    ],
    name: 'Online Conditional'
  },
  {
    id: '2b1666df-b7dd-410e-81ad-d9a9710904d6',
    elements: [
      {
        addMore: false,
        id: '0f8ab40d-bae7-4309-a721-052d32e3fb7d',
        name: 'Policy Info',
        ordinality: 'NONE',
        type: '6b2f4ec7-5d3f-4da4-804f-c4f9906ba840'
      },
      {
        addMore: true,
        id: 'ddce415a-2c09-4eb9-a6f9-1cf55271a3e5',
        name: 'Beneficiary(s)',
        ordinality: 'NONE',
        type: 'ad2f8ab9-b77e-4865-be09-2f31702e16df'
      }
    ],
    name: 'Life Insurance'
  },
  {
    id: 'f1d2d05b-3ac8-41ea-8721-4302f3330fc4',
    elements: [
      {
        id: '75b2e3e4-6858-447f-98ca-32978e6f9ec1',
        name: 'Attorney Contact Info',
        ordinality: 'NONE',
        type: 'CONTACT'
      },
      {
        id: '0c93a0cc-1cb0-47c1-872a-57281ef9e8af',
        name: 'State of corp',
        ordinality: 'NONE',
        type: 'TEXT'
      }
    ],
    name: 'Attorney Conditional'
  },
  {
    id: 'f8ed3d81-9ed3-4cf9-806d-46ffbb9e3ecf',
    elements: [
      {
        id: '09770420-3dd2-491a-a414-f52ba327027a',
        name: 'Type of Guardian',
        ordinality: 'SELECT',
        'list-mapping': '8e5fe3e3-25ed-4c2c-a5fb-4b1c989cce4d',
        type: 'TEXT'
      },
      {
        id: '9f12e47c-ce2a-4f3d-8f86-337437c802bb',
        name: 'Who is it?',
        ordinality: 'NONE',
        type: 'CONTACT'
      },
      {
        id: 'd310fecd-b103-414c-addc-f389b5102252',
        name: 'Notes & Instructions',
        ordinality: 'NONE',
        type: 'TEXT-MULTI'
      }
    ],
    name: 'Guardian'
  },
  {
    id: '4b82c8b0-9607-43ec-ba9d-e9a11b2128d2',
    name: 'Financial Advisors-Financial Advisors',
    elements: [
      {
        id: '56feea08-a73e-4d0c-94ff-9980d1102cf6',
        name: 'Advisors',
        type: 'ddd0f990-4ee3-43a0-9aa8-f0747b63001a',
        addMore: false,
        ordinality: 'NONE'
      }
    ]
  },
  {
    id: 'ddd0f990-4ee3-43a0-9aa8-f0747b63001a',
    name: 'Financial Advisors-Advisors',
    elements: [
      {
        id: 'fd8a36dc-b630-4886-aef3-800feba042e9',
        name: 'Type of advisor',
        type: 'TEXT',
        addMore: false,
        ordinality: 'SELECT-WITH-OTHER',
        selectType: 'Select',
        conditional: false,
        'list-mapping': 'f0783af7-5c8d-47c7-890d-0c3670c54a7f',
        legacyQuestionFieldId: 1539
      },
      {
        id: '807b1b54-19c9-4980-884c-c264057a241a',
        name: 'Advisor contact info',
        type: 'CONTACT',
        addMore: false,
        ordinality: 'NONE',
        selectType: 'null',
        conditional: false,
        legacyQuestionFieldId: 1533
      }
    ]
  },
  {
    id: 'f216dc88-d40c-4a63-8985-2d986ccd39f4',
    name: 'Pets-My Pets',
    elements: [
      {
        id: '0a76c81d-1779-4f7b-bde5-1bbe28edbb91',
        name: 'Basic Info',
        type: '62220123-02b6-4cda-af46-fe1be388457a',
        addMore: false,
        ordinality: 'NONE'
      }
    ]
  },
  {
    id: '62220123-02b6-4cda-af46-fe1be388457a',
    name: 'My Pets-Basic Info',
    elements: [
      {
        id: '590c2aca-39d4-4196-8c61-573867ec7cd4',
        name: 'What type of Pet is this?',
        type: 'TEXT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        legacyQuestionFieldId: 1452
      },
      {
        id: '51027462-816e-41e6-9517-64e21aa1db2c',
        name: 'Name',
        type: 'TEXT',
        addMore: true,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        legacyQuestionFieldId: 1453
      },
      {
        id: '555c6c0b-5a17-49a9-bc33-26524357af06',
        name: 'Detail any health and diet needs or requirements',
        type: 'TEXT-MULTI',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        legacyQuestionFieldId: 1456
      },
      {
        id: '5a35e19e-f337-4794-9048-a541c1da775e',
        name: 'Veterinarian contact info',
        type: 'CONTACT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        legacyQuestionFieldId: 1455
      },
      {
        id: 'c5f8f354-3fac-41e7-22be-4f87caeb7396',
        name: 'What type(s) of pets do you have?',
        ordinality: 'NONE',
        type: 'TEXT',
        selectType: null,
        conditional: false
      },
      {
        id: 'f5f8f354-3fac-41e7-22be-4f87caeb5647',
        name: 'Plumbus',
        type: 'FILE'
      }
    ]
  },
  // Kits for 'IDs and Vital Documentation' which is a conditional is a simple conditional item
  {
    id: '2f5f0985-2eb2-4a99-a73c-7f0e51ee0275',
    name: 'About Me',
    elements: [
      {
        id: 'a00b0d93-8868-4716-80e6-5e4eb65332c9',
        name: 'About Me-Vital Documentation',
        type: '9a1bd0c4-a6ef-4b94-8afb-f75eecd5a4f8',
        addMore: true,
        ordinality: 'NONE'
      }
    ]
  },
  {
    id: '9a1bd0c4-a6ef-4b94-8afb-f75eecd5a4f8',
    name: 'My Basic Information-Vital Documentation',
    elements: [
      {
        id: '29f24d23-2b4a-41a2-ba2f-895ea2c660d6',
        name: 'Type of document or ID',
        type: 'TEXT',
        addMore: false,
        ordinality: 'SELECT-WITH-OTHER',
        selectType: 'Select',
        conditional: false,
        placeholder: '',
        'list-mapping': 'bf2353cf-ede9-469b-a435-5902ce1880e5',
        legacyQuestionFieldId: 1067
      }
    ],
    legacyQuestionFieldGroupId: 229
  },
  {
    id: '8e0937d0-5cd5-48f1-b9b8-285d058bbd02',
    name: 'Social Security Card-kit',
    elements: [
      {
        id: '20ab41ea-3c2e-4803-bcb8-374b83768bc4',
        name: 'Social Security Number',
        type: 'TEXT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: true,
        placeholder: '',
        legacyQuestionFieldId: 1209
      },
      {
        id: 'cf8a716f-b603-43c1-ada7-748b96a4bce4',
        name: 'Location of Social Security Card',
        type: 'TEXT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: true,
        placeholder: 'Example: In my wallet',
        legacyQuestionFieldId: 1210
      }
    ],
    legacyQuestionFieldOptionId: 630
  },
  {
    id: 'b48ef133-a571-4f0e-893d-109a47fd1f46',
    name: "Driver's License-kit",
    elements: [
      {
        id: 'bb883b1f-d94d-4b85-9a2d-0d2eeb809084',
        name: 'License Number',
        type: 'TEXT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: true,
        placeholder: '',
        legacyQuestionFieldId: 1212
      },
      {
        id: '35a9f574-42ac-4849-af84-8355f22e5288',
        name: "Location of Driver's License",
        type: 'TEXT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: true,
        placeholder: '',
        legacyQuestionFieldId: 1215
      }
    ],
    legacyQuestionFieldOptionId: 631
  },
  {
    id: '78d9fddf-6764-4f0e-893d-109a47fd1f46',
    name: 'Other-kit',
    elements: [
      {
        id: '87483h-34rer-343sdf-34df-ghb34323jhj',
        name: 'Other Document name',
        type: 'TEXT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: true,
        placeholder: '',
        legacyQuestionFieldId: 1000
      }
    ],
    legacyQuestionFieldOptionId: 6312
  },
  // End of kits for 'IDs and Vital Documentation'
  // Beginning of kits for advance directive
  {
    id: '30c04330-7cc1-460a-b75d-316c33ffe4ce',
    name: 'Advance Directive',
    elements: [
      {
        id: '11131392-3b6a-4d15-ac3f-dbf4663cd7f2',
        name: 'Health Care Proxy',
        type: '0548824c-956b-4647-adee-ee19a5ffa2b9',
        addMore: false,
        ordinality: 'NONE'
      },
      {
        id: 'c0bf1ed2-7ffd-4d3d-b382-38deb9937891',
        name: 'Living Will',
        type: '5658f057-35db-4045-9ba9-d2352fb205f7',
        addMore: false,
        ordinality: 'NONE'
      }
    ]
  },
  {
    id: '0548824c-956b-4647-adee-ee19a5ffa2b9',
    name: 'Health Care Proxy-Health Care Proxy',
    elements: [
      {
        id: 'e75ca733-2bd9-4728-b93e-e508425c7246',
        name: "Where's your Health Care Proxy valid?",
        type: 'STATE',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        placeholder: '',
        legacyQuestionFieldId: 1666
      },
      {
        id: 'b6664494-8016-46c1-b116-50c51962b102',
        name: 'Who did you name as your Health Care Proxy?',
        type: 'CONTACT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        placeholder: '',
        legacyQuestionFieldId: 1667
      }
    ]
  },
  {
    id: '5658f057-35db-4045-9ba9-d2352fb205f7',
    name: 'Living Will-Living Will',
    elements: [
      {
        id: '4978b42b-5624-4215-b934-04e62222e83e',
        name: 'Where do you keep the signed Living Will?',
        type: 'TEXT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        placeholder: '',
        legacyQuestionFieldId: 1684
      }
    ]
  },
  // End of kits for advance directive
  // Beginning of kits for Power of Attorney
  {
    id: '60fd87e7-98a4-4cd1-9bc3-a06dac1464b9',
    name: 'Power Of Attorney-My Power Of Attorney',
    elements: [
      {
        id: 'c70bfeab-7736-4bef-8129-8711c0d51b74',
        name: 'Basic Info',
        type: 'c4d8df18-26a2-414c-8918-4e810e7d3e8e',
        addMore: false,
        ordinality: 'NONE'
      }
    ]
  },
  {
    id: 'c4d8df18-26a2-414c-8918-4e810e7d3e8e',
    name: 'My Power Of Attorney-Basic Info',
    elements: [
      {
        id: '01e738f5-5e15-4944-9e6b-af34f94711ac',
        name: 'Who did you name as Power Of Attorney (POA)?',
        type: 'CONTACT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        placeholder: '',
        legacyQuestionFieldId: 917
      },
      {
        id: 'a774a953-d651-44d1-95d0-cdd21c08e38e',
        name: ' Who did you name as your alternate Power Of Attorney (POA)?',
        type: 'CONTACT',
        addMore: true,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        placeholder: '',
        legacyQuestionFieldId: 1745
      },
      {
        id: '852a6a62-2291-413f-91bf-3794cd429c93',
        name: 'Upload a scan, photo, or PDF of POA document',
        type: 'FILE',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        placeholder: '',
        legacyQuestionFieldId: 927
      },
      {
        id: 'dbb44697-0c91-4204-a9d5-10f9c087c23b',
        name: 'Where do you keep the signed and notarized document?',
        type: 'TEXT',
        addMore: false,
        ordinality: 'NONE',
        selectType: null,
        conditional: false,
        placeholder: '',
        legacyQuestionFieldId: 926
      }
    ]
  },
  {
    'version-id': '7065a2f4-be08-437a-935a-da0bf41b052f',
    name: 'Burial, Cremation, Or Donation-What I Want Done With My Body',
    elements: [
      {
        id: 'd8513c27-b6e2-42a8-ba11-f3edbe09df7b',
        name: 'General Preference',
        type: '32ff3ae5-3f6b-47b5-b983-e93444cd3dfb',
        addMore: false,
        ordinality: 'NONE'
      },
      {
        id: 'e01d8975-6000-42d8-8187-8d72992c4076',
        name: 'Funeral Home Arrangements',
        type: 'dc368f70-1c8f-431d-b487-714924d28eaf',
        addMore: false,
        ordinality: 'NONE'
      },
      {
        id: '355958c4-8058-4d68-9795-c3e73876885b',
        name: 'Other Payment Arrangements',
        type: 'b2a0c7d2-a118-48ba-b9d2-51b245c6447f',
        addMore: true,
        ordinality: 'NONE'
      }
    ],
    id: '4f7c02b5-1217-45f0-9b00-c69875747e55'
  }
]
