import {Link} from 'react-router'
import PropTypes from 'prop-types'

import HorizontalList from '../shared_firm/HorizontalList'

import './organizationNav.scss'

const OrganizationNav = props => (
  <HorizontalList className='organization-nav'>
    <Link activeClassName='active' key='overview' to={`/pro/organization-admin/organizations/${props.organizationId}/overview`}>Overview</Link>
    <Link activeClassName='active' key='settings' to={`/pro/organization-admin/organizations/${props.organizationId}/settings`}>Settings</Link>
  </HorizontalList>
)

OrganizationNav.propTypes = {
  organizationId: PropTypes.string
}

export default OrganizationNav
