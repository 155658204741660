import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import JiclPreviewResponseElement from './JiclPreviewResponseElement'

import {elementsPerViewByType} from '../../../../../../lib/viewTools'
import {rectifyConditionalMaps} from '../../../../../../lib/plan_data/item'

const JiclPreviewResponseGroupPerView = props => {
  const viewElements = rectifyConditionalMaps({
    responses: props.responses,
    parentElement: Map(),
    childElements: elementsPerViewByType({
      view: props.view,
      elements: props.elements,
      type: 'elements'
    }),
    kits: props.kits,
    listMappings: props.listMappings
  })

  return (
    <div>
      {viewElements.map(viewElement => (
        <JiclPreviewResponseElement
          key={viewElement.get('id')}
          responses={props.responses}
          element={viewElement}
          newContacts={props.newContacts}
          viewName={props.view.get('name')}
          elementViewTextConfig={props.sectionConfig.getIn([
            'viewConfig',
            'elementViewTextConfig'
          ])}
        />
      ))}
    </div>
  )
}

JiclPreviewResponseGroupPerView.propTypes = {
  elements: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  newContacts: PropTypes.instanceOf(List),
  responses: PropTypes.instanceOf(List),
  sectionConfig: PropTypes.instanceOf(Map),
  view: PropTypes.instanceOf(Map)
}

export default JiclPreviewResponseGroupPerView
