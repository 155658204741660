import PropTypes from 'prop-types'


const HighComplianceNote = ({isHighCompliance}) => {
  if (isHighCompliance) {
    return (
      <section className='high-compliance-note'>
        <span className='bold'>
          Note:
        </span> Since this invitation message will come from Everplans, it is helpful to contact your client(s) in advance—from
        your official email system—explaining what to expect from Everplans and to keep an eye out for Everplans communications.
      </section>
    )
  } else {
    return <noscript />
  }
}

HighComplianceNote.propTypes = {
  isHighCompliance: PropTypes.bool
}

export default HighComplianceNote
