import PropTypes from 'prop-types'
import React from 'react'

import Button from '../../../../shared_components/Button'
import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'
import * as Links from '../../../../shared_components/Links'
import PageTitle from '../../../../shared_components/PageTitle'
import routerUtils from '../../../../lib/routerUtils'
import WidthContainer from '../../../../shared_components/WidthContainer'

export default class PasswordResetComplete extends React.Component {
  constructor(props) {
    super(props)

    this.goToLogin = this.goToLogin.bind(this)
  }
  goToLogin() {
    routerUtils.push('auth/sign_in')
  }
  render() {
    return (
      <div className='reset-password'>
        <PageTitle title='Reset Password' />
        <HeaderFlavor flavor='transactional' />
        <WidthContainer>
          <h1>Password reset complete.</h1>
          <p>We've changed your Everplans password.</p>
          <p>Please record your new password and keep it in a safe place.</p>
          <Button className='btn-success' onClick={this.goToLogin}>Log in with new password</Button>
        </WidthContainer>
        <div className='support-links'>
          Need Support?<Links.Help>Everplans Help Center</Links.Help>
        </div>
      </div>
    )
  }
}

PasswordResetComplete.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      email: PropTypes.string
    })
  }).isRequired
}

PasswordResetComplete.contextTypes = {router: PropTypes.object}
