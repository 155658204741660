import {Component} from 'react'
import PropTypes from 'prop-types'
import {List} from 'immutable'

import {ControlButton, Cancel, Continue} from '../../../../../../shared_components/ButtonTypes'
import ItemPermissionsSelectForm from '../../../../../../shared_components/item_permissions/ItemPermissionsSelectForm'
import Modal from '../../../../../../shared_components/Modal'
import modalWrapper, {MODAL_CONTEXT_TYPES} from '../../../../../../shared_components/ModalWrapper'
import ShadowHeader from '../../ShadowHeader'
import StatusToggle from '../StatusToggle'

import FirmActions from '../../../../actions/FirmActions'

import '../editFirmConfig.scss'

export class EditDeputizable extends Component {
  constructor(props) {
    super(props)

    this.state = {isDeputizableEnabled: props.isDeputizable, permittedCategories: this.permittedCategories()}

    this.cancel = this.cancel.bind(this)
    this.toggleDeputizableStatus = this.toggleDeputizableStatus.bind(this)
    this.updatePermittedCategories = this.updatePermittedCategories.bind(this)
    this.saveDeputizableSetting = this.saveDeputizableSetting.bind(this)
  }

  toggleDeputizableStatus() { this.setState({isDeputizableEnabled: !this.state.isDeputizableEnabled}) }

  updatePermittedCategories(permittedCategories) {
    this.setState({permittedCategories})
  }

  saveDeputizableSetting() {
    FirmActions.updateFirmConfig({
      firmId: this.props.firmId,
      data: {firm_config: {deputizable: this.state.isDeputizableEnabled, permitted_categories: this.state.permittedCategories}}
    })

    this.context.closeModal()
  }

  permittedCategories() {
    if (this.props.permittedCategories.length > 0)
      return this.props.permittedCategories
    else
      return this.props.items.map(item => item.get('id')).toArray()
  }

  cancel() {
    this.setState({isDeputizableEnabled: this.props.isDeputizable, permittedCategories: this.permittedCategories()})
    this.context.closeModal()
  }

  render() {
    if (this.props.isFirmActive) {
      return (
        <div className='edit-config edit-deputizable'>
          <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
          <Modal visible={this.props.showModal}>
            <ShadowHeader headerText='Advisors as Deputies' />
            <div className='config-wrapper'>
              <StatusToggle
                description={{
                  disabled: 'Clients cannot add Advisors or Assistants as Deputies.',
                  enabled: 'Clients are permitted to add Advisors and Assistants as Deputies.'
                }}
                enabledChecked={this.state.isDeputizableEnabled}
                name='deputizable'
                onChange={this.toggleDeputizableStatus}
                statusText={{disabled: 'Not Allowed', enabled: 'Allowed'}}
              />
              {
                this.state.isDeputizableEnabled &&
                (
                  <ItemPermissionsSelectForm
                    checkedItemIds={this.state.permittedCategories}
                    onCheck={this.updatePermittedCategories}
                  />
                )
              }
              <div className='button-group'>
                <Cancel onClick={this.cancel} />
                <Continue loading={this.props.processing} onClick={this.saveDeputizableSetting}>Save</Continue>
              </div>
            </div>
          </Modal>
        </div>
      )
    } else {
      return null
    }
  }
}

EditDeputizable.contextTypes = MODAL_CONTEXT_TYPES

EditDeputizable.defaultProps = {
  deputizable: {},
  showModal: false
}

EditDeputizable.propTypes = {
  finished: PropTypes.func,
  firmId: PropTypes.string.isRequired,
  isAccountManager: PropTypes.bool,
  isDeputizable: PropTypes.bool,
  isFirmActive: PropTypes.bool,
  items: PropTypes.instanceOf(List),
  permittedCategories: PropTypes.arrayOf(PropTypes.string),
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  updating: PropTypes.func
}

export default modalWrapper(EditDeputizable)
