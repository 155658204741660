import PropTypes from 'prop-types'
import React from 'react'

import FirmActions from '../../../actions/FirmActions'

import {ControlButton, Cancel, Continue} from '../../../../../shared_components/ButtonTypes'
import Modal from '../../../../../shared_components/Modal'
import modalWrapper from '../../../../../shared_components/ModalWrapper'
import ShadowHeader from '../ShadowHeader'
import StatusToggle from './StatusToggle'

import './editFirmConfig.scss'

import ContactAccountManagerNote from './ContactAccountManagerNote'
import ShadowWrapper from '../ShadowWrapper'

export class EditWriteAccess extends React.Component {
  constructor(props) {
    super(props)

    this.state = {isEnabled: this.props.writeAccess.write_access}

    this.resetWriteAccessState = this.resetWriteAccessState.bind(this)
    this.saveWriteAcess = this.saveWriteAcess.bind(this)
    this.toggleWriteAccess = this.toggleWriteAccess.bind(this)
  }
  toggleWriteAccess() {
    this.setState({isEnabled: !this.state.isEnabled})
  }
  saveWriteAcess() {
    FirmActions.updateFirmConfig({
      firmId: this.props.firmId,
      data: {firm_config: {write_access: this.state.isEnabled}}
    })
    this.context.closeModal()
  }
  resetWriteAccessState() {
    this.setState({isEnabled: this.props.writeAccess.write_access})
    this.context.closeModal()
  }

  render() {
    return (
      <ShadowWrapper>
        <div className='settings row-wrapper'>
          <p>Status: </p>
          <div className='column-wrapper'>
            <p>{this.props.writeAccess.write_access ? 'Enabled' : 'Disabled'}</p>
            <p className='description'>{this.props.writeAccess.write_access ?
              "Firm Users can request permission to edit a client's Everplan. Clients can grant permission through their account settings or when signing up." :
              "Firm Users cannot edit a client's Everplan once the client has activated their account. Clients cannot grant permission to Firm Users."}</p>
            {
              this.props.isFirmActive && this.props.isAccountManager &&
              (
                <div className='edit-config'>
                  <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
                  <Modal visible={this.props.showModal}>
                    <ShadowHeader headerText='Permission to Edit' />
                    <div className='config-wrapper'>
                      <StatusToggle
                        description={{
                          disabled: "Firm Users cannot edit a client's Everplan once the client has activated their account. Clients cannot grant permission to Firm Users.",
                          enabled: "Firm Users can request permission to edit a client's Everplan. Clients can grant permission through their account settings or when signing up."
                        }}
                        enabledChecked={this.state.isEnabled}
                        name='write_access'
                        onChange={this.toggleWriteAccess}
                        statusText={{
                          disabled: 'Disabled',
                          enabled: 'Enabled'
                        }}
                      />
                      <div className='button-group'>
                        <Cancel onClick={this.resetWriteAccessState} />
                        <Continue loading={this.props.processing} onClick={this.saveWriteAcess}>Save</Continue>
                      </div>
                    </div>
                  </Modal>
                </div>
              )
            }
            <ContactAccountManagerNote isAccountManager={this.props.isAccountManager} />
          </div>
        </div>
      </ShadowWrapper>
    )
  }
}

EditWriteAccess.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

EditWriteAccess.defaultProps = {
  writeAccess: {},
  showModal: false
}

EditWriteAccess.propTypes = {
  statusText: PropTypes.string,
  isAccountManager: PropTypes.bool,
  firmId: PropTypes.string.isRequired,
  description: PropTypes.string,
  writeAccess: PropTypes.bool,
  showModal: PropTypes.bool,
  isFirmActive: PropTypes.bool
}

export default modalWrapper(EditWriteAccess)
