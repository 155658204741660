import EditDefaultInviteText from './EditDefaultInviteText'
import ShadowHeader from '../../shared_firm/ShadowHeader'

const OrganizationDefaultInviteText = props => {
  if (props.organization.get('organization-client-invite') === 'everplans_standard') {
    return (
      <div className='organization-default-invite-text'>
        <ShadowHeader headerText='Default Client Invite Text' />
        <EditDefaultInviteText {...props} />
      </div>
    )
  } else {
    return null
  }
}

export default OrganizationDefaultInviteText
