// TODO: this component should only be used if it is being displayed inline. If you want a modalized version use the
// HouseholdClientPrivacyNoticeModal
const HouseholdLearnMore = props => {
  return (
    <span>
      <div className='privacy-modal'>
        <h3>Privacy Notice</h3>
        <p>The subscription you've been provided by {props.data.advisor_name} is for a <strong>Household Everplan</strong>, to be shared with {props.data.hp_partner_name}.</p>
        <p>As a co-administrator, <strong>{props.data.hp_partner_first_name} will be able to</strong>:</p>
        <ul className='modal-li'>
          <li>Add, modify, and remove the secure data in your Everplan.</li>
          <li>Add, modify, and remove Deputy permissions, which control who else can view or make changes to your Everplan.</li>
          <li>Revoke your access to this Household Everplan.</li>
        </ul>
        <p><strong>{props.data.hp_partner_first_name} will not be able to:</strong></p>
        <ul className='modal-li'>
          <li>View or modify your password or security settings.</li>
          <li>View or modify the Everplans of other people who have made you a Deputy.</li>
        </ul>
        <hr></hr>
        <p>See the complete <a href='https://www.everplans.com/privacy-policy' target='_blank'>Everplans Privacy Policy</a>.</p>
      </div>
    </span>
  )
}

export default HouseholdLearnMore
