import React from 'react'
import Container from '../../../../lib/Container'
import {MyProDashboard} from '../../../../shared_components/Links'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import StorePrototype from '../../../../shared_components/StorePrototype'
import Errors from '../../../../shared_components/deprecated/Errors'

export default class OrionLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {processing: true, errors: []}

    this.action = Container.getAction('orion_sso')
    this.store = Container.registerStore('link_store', StorePrototype(this.action.Types.LINKED_ORION))

    this.onUpdate = this.onUpdate.bind(this)
  }
  componentDidMount() {
    this.store.addChangeListener(this.onUpdate)
    this.action.linkOrion(this.props.params.token)
  }
  componentWillUnmount() { this.store.removeChangeListener(this.onUpdate) }
  onUpdate() {
    this.setState({processing: false, errors: this.store.getState().errors})
  }
  message() {
    if (this.state.errors.length > 0)
      return <Errors errors={this.state.errors} />
    else
      return <div>Single Sign On is all Setup Click <MyProDashboard>here</MyProDashboard> to continue.</div>
  }

  render() {
    return (
      <LegacyLoader loading={this.state.processing}>
        <div style={{width: '100%', 'text-align': 'center', 'margin-top': '30vh'}} >
          {this.message()}
        </div>
      </LegacyLoader>
    )
  }
}
