import {fromJS, List} from 'immutable'

import JiclAssessment from '../components/sections/sub_sections/JiclAssessment'
import JiclGuide from '../components/sections/sub_sections/JiclGuide'
import JiclResponseGroupContainer from '../components/sections/sub_sections/JiclResponseGroupContainer'

import * as icons from '../components/preview/images'

export const SCREEN_CONFIG = fromJS({
  'primary-care-physician': {
    header: 'Your Primary Care Physician',
    previewHeader: 'Primary Care Physician',
    viewIntroText: '',
    responseIntroTexts: [
      'Your loved ones may need to speak with your doctor if you require care or become ill.'
    ],
    viewConfig: {
      names: ['primary-doctor'],
      addAnotherNames: [],
      elementViewTextConfig: {}
    },
    assessmentConfig: {},
    icon: icons.contactNote
  },
  'health-insurance': {
    header: 'Health Insurance',
    viewIntroText: '',
    responseIntroTexts: [
      "Share the basic information about your health coverage so your loved ones aren't scrambling around for it at the worst possible time."
    ],
    viewConfig: {
      names: ['health-insurance-jicl'],
      addAnotherNames: [],
      elementViewTextConfig: {
        'befa9ce5-9789-4060-bc6c-6157a290005b':
          'The type of health insurance plan I have is',
        'bb0da9b1-23cc-466e-9287-c06df93ea50b':
          'My health insurance provider is',
        '0e050f29-7933-476f-830f-5548640c6a95':
          'My health insurance provider is',
        '835ce349-5f6d-4d02-a7a1-32081722b282':
          'My health insurance provider is'
      }
    },
    assessmentConfig: {},
    icon: icons.healthInsurance
  },
  'health-care-proxy': {
    header: 'Health Care Proxy',
    viewIntroText:
      'I have named a Health Care Proxy, who can make medical decisions on my behalf if I am unable to do so.',
    responseIntroTexts: [
      'Share some information about your Proxy.'
    ],
    viewConfig: {
      names: ['health-care-proxy-jicl'],
      addAnotherNames: [],
      elementViewTextConfig: {
        'af246527-e6a5-44b9-9ca3-a23ac3084dfb': 'My Health Care Proxy is',
        'c1dbebeb-6f0f-45de-9289-4cb769c4747a':
          'The original, signed copy of my Health Care Proxy document can be found here'
      }
    },
    assessmentConfig: {
      notes: [
        'Have you named a Health Care Proxy who can make health care decisions on your behalf if you are no longer able to do so?'
      ],
      yesButtonText: 'I have a Health Care Proxy',
      noButtonText: 'Not yet',
      notSure: "I'm not sure what this is",
      value: 'advance-directive'
    },
    guideConfig: {
      notes: [
        '<strong>Health Care Proxy</strong> is a person who can make health care decisions on your behalf if you are no longer able to do so.',
        'Naming a <strong>Health Care Proxy</strong> is as simple as downloading the correct form for your state, completing it, and signing.',
        'Would you like to receive more information about naming a <strong>Health Care Proxy</strong> and the actual form to get it done?'
      ],
      yesButtonText: 'Yes, send me info',
      noButtonText: 'No thanks',
      value: 'health-care-proxy'
    },
    icon: icons.healthCare,
    hidePhoneField: true
  },
  'those-in-your-care': {
    header: 'Those In Your Care',
    previewHeader: 'Guardian For My Dependents',
    viewIntroText: '',
    responseIntroTexts: [
      "If you have any type of dependents -- kids, pets, parents -- who should take care of them if you couldn't?"
    ],
    viewConfig: {
      names: ['other-important-contact-jicl'],
      addAnotherNames: [],
      elementViewTextConfig: {
        '0073dbf9-f925-4fab-854c-5cadb946e3a1':
          'The person I named as a guardian for my dependents is'
      }
    },
    assessmentConfig: {},
    icon: icons.contactNote,
    hidePhoneField: true
  },
  'financial-basics': {
    header: 'Financial Basics',
    viewIntroText: 'I have accounts at the following financial institutions:',
    responseIntroTexts: [
      "Let's create a simple map of where you bank and invest. If something happens to you, your family will know where to go for help.",
      "☝️ We're only asking these questions for the purpose of creating your list. Your data will only be visible to you and the people you share the list with."
    ],
    viewConfig: {
      names: [
        'financial-basics-checking-jicl',
        'financial-basics-savings-jicl',
        'financial-basics-investment-or-retirement-jicl'
      ],
      addAnotherNames: [],
      elementViewTextConfig: {
        '8c173abe-5452-4af0-a952-aefc88160942': {
          'financial-basics-checking-jicl': 'My Checking account is with',
          'financial-basics-savings-jicl': 'My Savings account is with',
          'financial-basics-investment-or-retirement-jicl':
            'My Investments account is with'
        }
      }
    },
    assessmentConfig: {},
    icon: icons.dollarInCircle
  },
  'life-insurance': {
    header: 'Life Insurance',
    viewIntroText: '',
    responseIntroTexts: [
      'Who provides your Life Insurance? This will let your family know where to file a claim.'
    ],
    viewConfig: {
      names: ['life-insurance-jicl'],
      addAnotherNames: [],
      elementViewTextConfig: {
        'f7d54148-1d9c-4556-b8fc-4c1deadeda71':
          'I have a life insurance policy with the following carrier'
      }
    },
    assessmentConfig: {
      notes: [
        'Do you have a Life Insurance policy? (including the one you might have gotten through your employer)'
      ],
      yesButtonText: 'Yes, I have Life Insurance',
      noButtonText: 'No',
      value: 'life-insurance'
    },
    icon: icons.umbrella
  },
  will: {
    header: 'Last Will And Testament',
    viewIntroText: "I've made a Will.",
    responseIntroTexts: [
      'Make sure your family knows where you keep your Will.'
    ],
    viewConfig: {
      names: ['will-jicl'],
      addAnotherNames: [],
      elementViewTextConfig: {
        'cb548a0a-7edb-4567-a5c9-b9b8ba2bb2d0':
          'The original, signed copy can be found here'
      }
    },
    assessmentConfig: {
      notes: ['Do you have a Will?'],
      yesButtonText: 'Yes',
      noButtonText: 'No',
      value: 'will'
    },
    guideConfig: {
      notes: [
        "Getting a <strong>Will</strong> in place isn't as hard as you think and doesn't have to cost a fortune.",
        'Would you like to receive more information about how to create a <strong>Will</strong>?'
      ],
      yesButtonText: 'Yes, send me info',
      noButtonText: 'No thanks',
      value: 'will'
    },
    icon: icons.scroll
  },
  'password-manager': {
    header: 'Password Manager',
    viewIntroText: '',
    responseIntroTexts: [
      'Access to passwords, especially the unlock code to your phone, can be a virtual lifesaver in an emergency.',
      '☝️ Again, this information will only be visible to you and the people you share the list with.'
    ],
    viewConfig: {
      names: ['password-manager-jicl'],
      addAnotherNames: [],
      elementViewTextConfig: {
        '13046c88-bf9d-4daf-8fc3-a36557f491fd': 'My passwords are stored',
        '800d904a-51a2-419d-b28c-36f6f134d160':
          'The master password can be acccessed by'
      }
    },
    assessmentConfig: {},
    icon: icons.key
  },
  'trusted-neighbor': {
    header: 'A Trusted Neighbor or Friend',
    previewHeader: 'A Trusted Neighbor',
    viewIntroText: '',
    responseIntroTexts: [
      "Your family might need to get in touch with someone who lives near you if they can't physically get to you. Who should they contact?"
    ],
    viewConfig: {
      names: ['your-trusted-neighbor-jicl'],
      addAnotherNames: [],
      elementViewTextConfig: {
        '106c6b97-f023-44c5-ad0f-d7b7166dc131': 'If you need to get in touch with someone who lives near me, you can contact'
      }
    },
    assessmentConfig: {},
    icon: icons.contactNote
  }
})

export const SECTION_ORDER = List([
  'primary-care-physician',
  'health-insurance',
  'health-care-proxy',
  'those-in-your-care',
  'financial-basics',
  'life-insurance',
  'will',
  'password-manager',
  'trusted-neighbor'
])


export const SUB_SECTION_COMPONENTS = {
  assessment: JiclAssessment,
  guide: JiclGuide,
  responses: JiclResponseGroupContainer
}

// These are element ids that are to be skipped on the form but should be shown
// on the preview to users that had them filled in previously.
export const ELEMENT_IDS_TO_SKIP = ['800d904a-51a2-419d-b28c-36f6f134d160']

export const ADDITIONAL_INFO = fromJS({
  header: 'Anything else?',
  viewIntroText: 'Additional Information',
  responseIntroTexts: [
    'This is where you can share thoughts or instructions your family needs to know in these uncertain times (example: location of important documents, details about your home, how to care for your pet, etc…)'
  ]
})
