import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import JiclPreviewEmptySection from './sub_sections/JiclPreviewEmptySection'
import JiclPreviewNonEmptyResponseGroup from './sub_sections/JiclPreviewNonEmptyResponseGroup'
import JiclEditResponseGroupContainer from './sub_sections/JiclEditResponseGroupContainer'

import './jiclPreviewResponseGroup.scss'

const JiclPreviewResponseGroup = props => {
  const sectionViewsWithResponses = props.sectionViews.filter(sectionView =>
    props.formattedResponses.get(sectionView.get('id'))
  )

  const headerText =
    props.sectionConfig.get('previewHeader') ||
    props.sectionConfig.get('header')

  if (sectionViewsWithResponses.isEmpty() && !props.user) {
    return null
  } else {
    return (
      <div className='jicl-preview-response-group'>
        <div className='section-header'>
          <img src={props.sectionConfig.get('icon')} />
          <h5>{headerText}</h5>
        </div>
        <div className='section-body'>
          {
            sectionViewsWithResponses.isEmpty() ?
              <JiclPreviewEmptySection /> :
              <JiclPreviewNonEmptyResponseGroup
                {...props}
                sectionViewsWithResponses={sectionViewsWithResponses}
              />
          }
          {props.user && <JiclEditResponseGroupContainer {...props} />}
        </div>
      </div>
    )
  }
}

JiclPreviewResponseGroup.propTypes = {
  formattedResponses: PropTypes.instanceOf(List),
  sectionViews: PropTypes.instanceOf(List),
  sectionConfig: PropTypes.instanceOf(Map)
}

export default JiclPreviewResponseGroup
