import PropTypes from 'prop-types'

import HorizontalList from '../shared_firm/HorizontalList'


const SeatTypeDetails = props => (
  <div className='seat-type-details'>
    <h5>{props.seatTypeName}</h5>
    {
      props.status === 'active' ?
        (
          <HorizontalList>
            {
              (props.hidePurchased && props.unlimited) ?
                <span>Unlimited</span> :
                <span>{props.seats} Purchased</span>
            }
            <span>{props.seatsUsed} Used</span>
            {
              !props.hidePurchased &&
              !props.unlimited &&
              <span>{props.seats - props.seatsUsed} Remaining</span>
            }
          </HorizontalList>
        ) :
        <span>{props.seats} Purchased</span>
    }
  </div>
)

SeatTypeDetails.propTypes = {
  hidePurchased: PropTypes.bool,
  seatTypeName: PropTypes.string,
  seats: PropTypes.number,
  seatsUsed: PropTypes.number,
  status: PropTypes.string,
  unlimited: PropTypes.bool
}

export default SeatTypeDetails
