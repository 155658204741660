/** @class ItemDetails
 *
 * @desc
 * Class that displays a single `Item` and information about data entered into
 * the `Everplan` being viewed that is associated with that `Item`.
 */
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import {isEditableItemResponse, responseGroupsMap} from '../../../../../lib/plan_data/itemResponsesHelper'
import {findResourceByAttributeId} from '../../../../../lib/plan_data/userData'
import ItemResponseGroups from '../../../../../shared_components/item_response_groups/ItemResponseGroups'
import ItemHeader from './ItemHeader'

import './itemDetails.scss'

const ItemDetails = props => {
  const hasEditPermission = isEditableItemResponse({
    firmOwnership: props.firmOwnership,
    itemResponse: props.itemResponse,
    context: props.context
  })

  const itemView = findResourceByAttributeId({resourceList: props.itemViews, id: props.itemResponse.get('item-id'), attribute: 'item-id'})
  const path = `${props.location.pathname}/${props.itemResponse.get('everplan-id')}`
  const responseGroups = responseGroupsMap(props.itemResponse.get('response-groups'))

  return (
    <div className='item-details flex-child'>
      <ItemHeader
        {...props}
        hasEditPermission={hasEditPermission}
      />
      <ItemResponseGroups
        {...props}
        // Need to have the key of this prop match that used in the other places `ItemResponseGroups` is rendered:
        responseGroups={responseGroups}
        hasEditPermission={hasEditPermission}
        itemView={itemView}
        path={path}
      />
    </div>
  )
}

ItemDetails.propTypes = {
  context: PropTypes.string,
  firmOwnership: PropTypes.instanceOf(Map),
  itemResponse: PropTypes.instanceOf(Map),
  itemViews: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  location: PropTypes.object,
  owners: PropTypes.instanceOf(List)
}

export default ItemDetails
