import PropTypes from 'prop-types'
import {Component} from 'react'

import Container from '../../../../lib/Container'
import Flash from '../../../../shared_components/deprecated/Flash'
import PageTitle from '../../../../shared_components/PageTitle'
import SignUpActions from '../../actions/SignUpActions'
import TwoStepCheckEmail from './two_step_sign_up/CheckEmail'
import WidthContainer from '../../../../shared_components/WidthContainer'

import routerUtils from '../../../../lib/routerUtils'

import './checkEmail.scss'


export default class CheckEmail extends Component {
  constructor() {
    super()

    this.state = {flash: ''}

    this.store = Container.getStore('resendConfirmationEmail')

    this.dismissFlash = this.dismissFlash.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.resendConfirmationEmail = this.resendConfirmationEmail.bind(this)
  }

  UNSAFE_componentWillMount() {
    if (!this.props.email)
      routerUtils.push('auth/sign-up')
  }

  componentDidMount() {
    this.store.addChangeListener(this.onUpdate)
  }

  onUpdate() {
    if (this.store.getState().errors.length === 0)
      this.setState({flash: "We've resent your confirmation email."})
  }

  componentWillUnmount() { this.store.removeChangeListener(this.onUpdate) }

  resendConfirmationEmail() {
    SignUpActions.resendConfirmationEmail({
      email: this.props.email ? this.props.email.toLowerCase().trim() : '',
      medium: 'web post sign up'
    })
  }

  dismissFlash() { this.setState({flash: ''}) }

  render() {
    return (
      <div className='check-email'>
        <PageTitle title='Check email' />
        {
          this.props.email !== null &&
          (
            <WidthContainer>
              <Flash flash={this.state.flash} closer={this.dismissFlash} />
              <TwoStepCheckEmail
                email={this.props.email ? this.props.email.toLowerCase() : ''}
                resendConfirmationEmail={this.resendConfirmationEmail}
              />
            </WidthContainer>
          )
        }
      </div>
    )
  }
}

CheckEmail.contextTypes = {router: PropTypes.object}

CheckEmail.defaultProps = {
  email: '',
  partner: {}
}

CheckEmail.propTypes = {
  email: PropTypes.string,
  partner: PropTypes.shape({
    name: PropTypes.string
  })
}
