import PropTypes from 'prop-types'
import {Map} from 'immutable'
import {Component} from 'react'

import {Cancel, Continue, ControlButton} from '../../../../../shared_components/ButtonTypes'
import ImageUpload from '../../admin/ImageUpload'
import Modal from '../../../../../shared_components/Modal'
import ShadowHeader from '../../shared_firm/ShadowHeader'
import modalWrapper from '../../../../../shared_components/ModalWrapper'

import ShadowWrapper from '../../shared_firm/ShadowWrapper'

import './editLogoContainer.scss'

export class EditLogoContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      [this.props.dataUrl]: props.organization.get(props.field)
    }

    this.resetState = this.resetState.bind(this)
    this.submit = this.submit.bind(this)
    this.setLogoDataUrl = this.setLogoDataUrl.bind(this)
    this.setContactCardImageUrl = this.setContactCardImageUrl.bind(this)
  }

  setLogoDataUrl(imageUrl) {
    this.setState({[this.props.dataUrl]: imageUrl})
  }

  setContactCardImageUrl(imageUrl) {
    this.setState({[this.props.dataUrl]: imageUrl})
  }

  submit() {
    this.props.updateSettings(
      {
        [this.props.field]: this.state[this.props.dataUrl]
      },
      this.context.closeModal
    )
  }

  resetState() {
    this.setState({
      [this.props.dataUrl]: this.props.organization.get(this.props.field)
    })
    this.context.closeModal()
  }

  render() {
    const imageUrl = this.state[this.props.dataUrl]
    return (
      <ShadowWrapper>
        <div className='edit-logo-container'>
          {
            imageUrl &&
            <div className='flex-container logo-wrapper'>
              <span>Logo:</span>
              <img src={imageUrl} alt={this.props.altText} />
            </div>
          }
          <div>
            <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
            <Modal visible={this.props.showModal}>
              <ShadowHeader headerText={this.props.headerText} />
              <div className='logo-upload-wrapper flex-container'>
                {
                  imageUrl &&
                  <img src={imageUrl} alt={this.props.altText} />
                }
                <ImageUpload onImageLoad={this[this.props.setLogoUrl]}>
                  <a>{imageUrl ? 'Change' : 'Upload'}</a>
                </ImageUpload>
              </div>
              <div className='button-group'>
                <Cancel onClick={this.resetState} />
                <Continue loading={this.props.processing} onClick={this.submit}>Save</Continue>
              </div>
            </Modal>
          </div>
        </div>
      </ShadowWrapper>
    )
  }
}

EditLogoContainer.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

EditLogoContainer.defaultProps = {
  showModal: false
}

EditLogoContainer.propTypes = {
  altText: PropTypes.string,
  dataUrl: PropTypes.string,
  field: PropTypes.string,
  headerText: PropTypes.string,
  organization: PropTypes.instanceOf(Map),
  processing: PropTypes.bool,
  setLogoUrl: PropTypes.func,
  showModal: PropTypes.bool,
  updateSettings: PropTypes.func
}

export default modalWrapper(EditLogoContainer)
