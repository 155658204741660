import {Route} from 'react-router'

import AxiosConnectedComponent from '../../../shared_components/AxiosConnectedComponent'
import MetaDataContainer from '../../../dashboard/src/components/corp_max/everplan_preview/MetaDataContainer'
import JiclWizardController from '../components/JiclWizardController'
import JiclSignIn from '../components/sections/JiclSignIn'
import JiclPreviewContainer from '../components/preview/JiclPreviewContainer'
import JiclApp from '../components/JiclApp'
import JiclUserContainer from '../components/JiclUserContainer'
import JiclRecipientContainer from '../components/JiclRecipientContainer'
import JiclUserUpgradeContainer from '../components/upgrade/JiclUserUpgradeContainer'
import MagicLink from '../../../auth/src/components/MagicLink'

import '../../../dashboard/styles/main.scss' // PLEASE DO NOT MOVE! We need to import our reset and global styles rules BEFORE importing components --KW

export default (
  <Route component={MetaDataContainer}>
    <Route component={JiclApp}>
      <Route path='jicl'>
        <Route path='magic-link/:token' component={MagicLink} />
        <Route component={JiclWizardController} path='start' />
        <Route component={JiclSignIn} path='continue' />
        <Route component={JiclRecipientContainer} path='recipients/:token' />
      </Route>
      <Route component={AxiosConnectedComponent}>
        <Route component={JiclUserContainer} path='saved-jicl'>
          <Route component={JiclUserUpgradeContainer} path='upgrade' />
          <Route component={JiclPreviewContainer} path='preview' />
        </Route>
      </Route>
    </Route>
  </Route>
)
