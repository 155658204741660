import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import JiclPreviewResponseGroupPerView from './JiclPreviewResponseGroupPerView'

const JiclPreviewNonEmptyResponseGroup = props => (
  <div>
    {props.sectionConfig.get('viewIntroText') && (
      <div className='view-intro-text'>
        {props.sectionConfig.get('viewIntroText')}
      </div>
    )}
    <div>
      {props.sectionViewsWithResponses.map(view => (
        <JiclPreviewResponseGroupPerView
          {...props}
          key={view.get('id')}
          view={view}
          responses={props.formattedResponses.get(view.get('id'), List())}
        />
      ))}
    </div>
  </div>
)

JiclPreviewNonEmptyResponseGroup.propTypes = {
  formattedResponses: PropTypes.instanceOf(List),
  sectionViewsWithResponses: PropTypes.instanceOf(List),
  sectionConfig: PropTypes.instanceOf(Map)
}

export default JiclPreviewNonEmptyResponseGroup
