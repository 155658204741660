import PropTypes from 'prop-types'
import React from 'react'

import {Cancel, Continue, ControlButton} from '../../../../../shared_components/ButtonTypes'
import Modal from '../../../../../shared_components/Modal'
import modalWrapper from '../../../../../shared_components/ModalWrapper'
import ShadowHeader from '../ShadowHeader'
import StatusToggle from './StatusToggle'

import FirmActions from '../../../actions/FirmActions'

import './editFirmConfig.scss'

import ContactAccountManagerNote from './ContactAccountManagerNote'
import ShadowWrapper from '../ShadowWrapper'

export class EditDeputyCobranding extends React.Component {
  constructor(props) {
    super(props)

    this.state = {showDeputyCobranding: props.showDeputyCobranding}

    this.resetDeputyCobrandingState = this.resetDeputyCobrandingState.bind(this)
    this.toggleDeputyCobranding = this.toggleDeputyCobranding.bind(this)
    this.updateDeputyCobrandingSetting = this.updateDeputyCobrandingSetting.bind(this)
  }
  toggleDeputyCobranding() { this.setState({showDeputyCobranding: !this.state.showDeputyCobranding}) }
  updateDeputyCobrandingSetting() {
    FirmActions.updateFirmConfig({
      firmId: this.props.firmId,
      data: {firm_config: {deputy_cobranding: (this.state.showDeputyCobranding)}}
    })
    this.context.closeModal()
  }
  resetDeputyCobrandingState() {
    this.setState({showDeputyCobranding: this.props.showDeputyCobranding})
    this.context.closeModal()
  }

  render() {
    return (
      <ShadowWrapper>
        <div className='settings row-wrapper'>
          <p>Status: </p>
          <div className='column-wrapper'>
            <p>{this.props.showDeputyCobranding ? 'Enabled' : 'Disabled'}</p>
            <p className='description'>{this.props.showDeputyCobranding ?
              "Deputies of clients will see the Advisor's contact information." :
              "Deputies of clients will not see the Advisor's contact information."}</p>
            {
              this.props.isFirmActive && this.props.isAccountManager &&
              (
                <div className='edit-config'>
                  <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
                  <Modal visible={this.props.showModal}>
                    <ShadowHeader headerText='Deputy Cobranding' />
                    <div className='config-wrapper'>
                      <StatusToggle
                        description={{
                          disabled: "Deputies of clients will not see the Advisor's contact information.",
                          enabled: "Deputies of clients will see the Advisor's contact information."
                        }}
                        enabledChecked={this.state.showDeputyCobranding}
                        name='deputy_cobranding'
                        onChange={this.toggleDeputyCobranding}
                        statusText={{
                          disabled: 'Disabled',
                          enabled: 'Enabled'
                        }}
                      />
                    </div>
                    <div className='button-group'>
                      <Cancel onClick={this.resetDeputyCobrandingState} />
                      <Continue onClick={this.updateDeputyCobrandingSetting}>Save</Continue>
                    </div>
                  </Modal>
                </div>
              )
            }
            <ContactAccountManagerNote isAccountManager={this.props.isAccountManager} />
          </div>
        </div>
      </ShadowWrapper>
    )
  }
}

EditDeputyCobranding.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

EditDeputyCobranding.defaultProps = {
  isHighCompliance: false,
  showModal: false
}

EditDeputyCobranding.propTypes = {
  statusText: PropTypes.string,
  isAccountManager: PropTypes.bool,
  description: PropTypes.string,
  isHighCompliance: PropTypes.bool.isRequired,
  firmId: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  isFirmActive: PropTypes.bool,
  showDeputyCobranding: PropTypes.bool
}

export default modalWrapper(EditDeputyCobranding)
