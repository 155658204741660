import HouseholdLearnMore from '../../../../shared_components/HouseholdLearnMore'
import InfoModal from './InfoModal'
import Modal from '../../../../shared_components/Modal'


export default class HouseholdLearnMoreModal extends InfoModal {
  constructor(props) {
    super(props)
    this.setInitialState(props)
  }

  render() {
    return (
      <span>
        <a onClick={this.showModal} className='branding'>Learn more.</a>
        <Modal visible={this.state.showModal} closer={this.closeModal}>
          <HouseholdLearnMore data={this.props.data} />
        </Modal>
      </span>
    )
  }
}
