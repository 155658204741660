/** @class RequestItemPermissionInviteController
 *
 * @desc
 * Top level controller that shows the requested item permissions interstitial
 * requested by an advisor on a client's everplan.
 *
 */

import {Component} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {updateResource, readEndpoint} from 'redux-json-api'

import {findRawResourceByAttributeId} from '../../../../../lib/plan_data/userData'
import {requestedItemPermissions} from '../../../../../lib/itemPermissionTools'

import Loader from '../../../../../shared_components/NewLoader'
import RequestItemPermissionInvite from './RequestItemPermissionInvite'


class RequestItemPermissionInviteController extends Component {
  constructor() {
    super()

    this.submitItemPermissions = this.submitItemPermissions.bind(this)
  }

  componentDidMount() {
    const everplanId = this.props.userConfig.get('everplan-id')
    this.props.readEndpoint(`everplans/${everplanId}?include=firm-ownership,request-item-permissions-invite`)
    this.props.readEndpoint(`advisor-contact-cards/${everplanId}`)
  }

  submitItemPermissions({checkedItemIds}) {
    const payload = {
      type: 'request-item-permissions-invites',
      id: this.props.requestItemPermissionInvite.get('id'),
      attributes: {
        'accepted-item-permissions': requestedItemPermissions({
          itemIds: checkedItemIds,
          permissionType: 'edit'
        }),
        status: checkedItemIds.length ? 'accepted' : 'declined'
      }
    }

    this.props.updateResource(payload).then(this.props.returnToController)
  }

  render() {
    return (
      <Loader loading={this.props.loading}>
        <RequestItemPermissionInvite
          advisorContactCard={this.props.advisorContactCard}
          submitItemPermissions={this.submitItemPermissions}
          checkedItemIds={
            this.props.requestItemPermissionInvite.get('item-permissions', Map())
              .keySeq()
              .toArray()
          }
        />
      </Loader>
    )
  }
}

RequestItemPermissionInviteController.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  corpMaxActions: PropTypes.shape({
    reshapeItems: PropTypes.func,
    reshapeCategories: PropTypes.func
  }),
  firmOwnership: PropTypes.instanceOf(Map),
  interstitials: PropTypes.shape({
    request_item_permissions_invites: PropTypes.array
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({'break': PropTypes.string})
  }),
  params: PropTypes.shape({requestId: PropTypes.string}),
  readEndpoint: PropTypes.func,
  returnToController: PropTypes.func,
  requestItemPermissionInvite: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const isLoadingData = ({advisorContactCard, firmOwnership, requestItemPermissionInvite}) => (
  (
    advisorContactCard.isEmpty() ||
    firmOwnership.isEmpty() ||
    requestItemPermissionInvite.isEmpty()
  )
)

const mapStateToProps = (state, {params, userConfig}) => {
  const requestItemPermissionInvite = findRawResourceByAttributeId({
    rawResource: state.api['request-item-permissions-invites'],
    attribute: 'id',
    id: params.requestId
  })

  const everplanId = userConfig.get('everplan-id')

  const advisorContactCard = findRawResourceByAttributeId({
    rawResource: state.api['advisor-contact-cards'],
    attribute: 'everplan-id',
    id: everplanId
  })

  const firmOwnership = findRawResourceByAttributeId({
    rawResource: state.api['firm-ownerships'],
    attribute: 'everplan-id',
    id: everplanId
  })

  return ({
    loading: isLoadingData({
      advisorContactCard,
      firmOwnership,
      requestItemPermissionInvite
    }),
    advisorContactCard,
    requestItemPermissionInvite,
    firmOwnership,
    userConfig
  })
}

export default connect(mapStateToProps, {readEndpoint, updateResource})(RequestItemPermissionInviteController)
