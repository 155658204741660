export default {
  id: 12345,
  'dismissed-item-ids': [],
  data: {
    dismissed_item_ids: [],
    deputies_migrated_at: '2019-01-03T16:20:20.939+00:00',
    responses_migrated_at: '2019-01-03T16:20:23.961+00:00',
    pending_migration_file_count: 0
  },
  'responses-migrated-at': '2019-01-03T16:20:23.961+00:00'
}
