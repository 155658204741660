import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {currentUserConfig} from '../../../../lib/userTools'
import {findRawResourceById, findRawResourceByAttributeId} from '../../../../lib/plan_data/userData'
import {agentReferralUrl} from '../../../../lib/urlTools'

import LeadGenerationModal from '../../../../shared_components/LeadGenerationModal'

import './agentLeadGenerationModal.scss'

// Putting this out here since they are all static text.
const TEXT_PROPS = {
  footerTitle: 'Suggested text to include when sharing the link:',
  headerText: "Get new leads by sharing the link below with prospects, or even on Facebook. Anyone with the link will be able to create an Everplan sponsored by you, and you'll be notified so you can reach out and contact them.",
  headerTitle: 'Give Everplans by Email or Text',
  launcherText: 'Give Everplans by Email or Text'
}

export const AgentLeadGenerationModal = ({advisorSeat, organization, organizationConfig}) => {
  const footerText = `${organization.get('name')} offers Everplans free of charge as a way to help families get organized, securely share critical information with loved ones, and stay on top of what's important.`
  const referralUrl = agentReferralUrl({
    organizationReferralCode: organizationConfig.get('referral-code'),
    firmUserReferralCode: advisorSeat.get('referral-code')
  })

  if (organizationConfig.get('firm-user-landing-page-enabled') && advisorSeat.get('seat-type') === 'AdvisorSeat') {
    return (
      <LeadGenerationModal
        {...TEXT_PROPS}
        footerText={footerText}
        className='agent-lead-generation-modal'>
        <div className='middle-section'>
          <h6>Share the link below via text or email:</h6>
          <div className='url'>{referralUrl}</div>
        </div>
      </LeadGenerationModal>
    )
  } else {
    return null
  }
}

AgentLeadGenerationModal.propTypes = {
  organization: PropTypes.instanceOf(Map),
  organizationConfig: PropTypes.instanceOf(Map),
  advisorSeat: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)

  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: userConfig.get('firm-id')
  })

  const organization = findRawResourceById({
    rawResource: state.api.organizations,
    id: firm.get('organization-id')
  })

  const organizationConfig = findRawResourceByAttributeId({
    rawResource: state.api['organization-configs'],
    attribute: 'organization-id',
    id: firm.get('organization-id')
  })

  // just grabbing the firm_seat using the user_id since a user can't have more than one firm_seat
  const advisorSeat = findRawResourceByAttributeId({
    rawResource: state.api['firm-seats'],
    attribute: 'user-id',
    id: userConfig.get('id')
  })

  return {
    advisorSeat,
    organization,
    organizationConfig
  }
}

export default connect(mapStateToProps)(AgentLeadGenerationModal)
