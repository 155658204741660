import PropTypes from 'prop-types'
import React from 'react'

import LegacyLoader from '../../../../shared_components/LegacyLoader'

import ClientActions from '../../actions/ClientActions'
import Container from '../../../../lib/Container'
import routerUtils from '../../../../lib/routerUtils'

Container.registerAction('client', ClientActions)

export default class Client extends React.Component {
  render() {
    return (
      <LegacyLoader loading={this.props.loadingClient} />
    )
  }
}

Client.contextTypes = {router: PropTypes.object}
Client.onBoundUpdate = props => {
  if (props.data.client_seat_id)
    routerUtils.push(`pro/household/${props.data.client_seat_id}`)
}

Client.propTypes = {loadingClient: PropTypes.bool.isRequired}
