import PropTypes from 'prop-types'
import {useEffect, useState, useRef} from 'react'

import {Cancel, Continue, ControlButton} from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import Modal from '../../../../shared_components/Modal'
import modalWrapper from '../../../../shared_components/ModalWrapper'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import FirmActions from '../../actions/FirmActions'
import storeWrapper from '../../../../shared_components/storeWrapper'
import {usePrevious} from '../../../../lib/hooks'

const EditInvitationMessageModal = (props, context) => {
  const formRef = useRef()
  const prevInvitationMessage = usePrevious(props.invitationMessage)
  const {isDifferent} = useNotificationContext()

  const [processing, setProcessing] = useState(false)

  const updateFirmUserConfig = () => {
    setProcessing(true)
    const form = formRef.current
    const errors = form.validate()

    if (errors.length === 0) {
      FirmActions.updateFirmUserConfig({firm_user_config: form.formData()}, props.params)
      context.closeModal()
    }

    setProcessing(false)
  }


  useEffect(() => {
    isDifferent(props.invitationMessage.data, prevInvitationMessage.data).shortSuccess('Your changes have been saved.')
    isDifferent(props.invitationMessage.errors, prevInvitationMessage.errors).shortError("We're sorry, but something went wrong while trying to update the invitation message. Please try again.")
  }, [props.invitationMessage])

  return (
    <div className='edit-invitation-message'>
      <ControlButton buttonText='Edit' faClass='fa-pencil-square-o' onClick={context.showModal} />
      <Modal closer={context.closeModal} visible={props.showModal}>
        <h2>Invitation Message</h2>
        <p>{props.messageDescription}</p>
        <Forms.Form onSubmit={updateFirmUserConfig} ref={formRef}>
          <Forms.TextArea
            autoFocus
            defaultValue={props.currentInvitationMessage}
            name='default_invite_email_text'
            tabIndex='1'
            wrap={false}
          />
        </Forms.Form>
        <div className='button-group'>
          <Cancel onClick={context.closeModal} tabIndex='3' />
          <Continue loading={processing} onClick={updateFirmUserConfig} tabIndex='2'>
            Save
          </Continue>
        </div>
      </Modal>
    </div>
  )
}

EditInvitationMessageModal.defaultProps = {
  invitationMessage: {
    data: {},
    errors: [],
    message: null
  }
}

EditInvitationMessageModal.propTypes = {
  currentInvitationMessage: PropTypes.string.isRequired,
  invitationMessage: PropTypes.object,
  messageDescription: PropTypes.string.isRequired,
  params: PropTypes.shape({
    firmId: PropTypes.string,
    firmUserSeatId: PropTypes.string
  }).isRequired,
  showModal: PropTypes.bool.isRequired
}

EditInvitationMessageModal.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

export default storeWrapper(
  modalWrapper(EditInvitationMessageModal),
  [{actionTypes: [FirmActions.Types.DID_UPDATE_FIRM_USER_CONFIG], name: 'invitationMessage'}]
)
