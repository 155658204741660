import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {addPossessive} from '../../../../lib/tools'
import Fields from '../../../../shared_components/forms'

import './customMessageEditor.scss'

const CustomMessageEditor = props => {
  const advisorIsCurrentUser = () => props.clientSeat.get('advisor-id') === props.userConfig.get('id')

  return (
    <div className='custom-message-editor'>
      {!props.isHighCompliance &&
        <span>
          <p className='bold email-greeting'>Personal message{!advisorIsCurrentUser() && ` from ${props.contactCard.name}`}:</p>
          <Fields.TextArea
            defaultValue={props.customText}
            name='invite_text'
            wrap={false}
          />
          {!props.needsClientApproval &&
            <Fields.Checkbox
              label={`Save any edits I make to ${advisorIsCurrentUser() ? 'my' : addPossessive(props.contactCard.name)} default template.`}
              className='save-edits-checkbox'
              name='save_edits'
            />
          }
        </span>
      }
    </div>
  )
}

CustomMessageEditor.propTypes = {
  clientSeat: PropTypes.instanceOf(Map),
  contactCard: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  customText: PropTypes.string.isRequired,
  isHighCompliance: PropTypes.bool,
  needsClientApproval: PropTypes.bool,
  userConfig: PropTypes.instanceOf(Map)
}

CustomMessageEditor.defaultProps = {
  contactCard: {name: ''},
  customText: ''
}

export default CustomMessageEditor
