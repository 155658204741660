import PropTypes from 'prop-types'

import * as Links from '../../../../shared_components/Links'

import './troubleshootingStep.scss'


const TroubleshootingStep = props => (
  <div className='troubleshooting-step'>
    <div className='error-circle'>{props.index + 1}</div>
    <div className='troubleshooting-step-text'>
      <div dangerouslySetInnerHTML={{__html: props.option}} />
      {props.index === 0 &&
        !props.error.message.match('Just-In-Case') &&
        !props.error.message.match('unsubscribe') && (
        <Links.LogIn className='btn btn-error-login'>
          Log in to Everplans&nbsp;&raquo;
        </Links.LogIn>
      )}
      {props.error.message.match('Just-In-Case List') && (
        <Links.JiclLogIn className='btn btn-error-login jicl'>
          Access your Just-In-Case List&nbsp;&raquo;
        </Links.JiclLogIn>
      )}
    </div>
  </div>
)

TroubleshootingStep.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string)
  }),
  index: PropTypes.number,
  option: PropTypes.string
}

export default TroubleshootingStep
