import PropTypes from 'prop-types'
import {useState, useCallback} from 'react'
import {Map} from 'immutable'

import JiclAnythingElse from '../../../sections/JiclAnythingElse'
import Modals from '../../../../../../shared_components/core/modals/Modals'
import Closer from '../../../../../../shared_components/core/closer/Closer'
import {useNotificationContext} from '../../../../../../shared_components/notifications/NotificationContext'
import Logger from '../../../../../../lib/NewLogger'
import {pencil} from '../../images'

const JiclEditAdditionalInformationContainer = ({jiclConfig, updateResource}) => {
  const [showModal, setShowModal] = useState(false)
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const openModal = () => setShowModal(true)

  const closeModal = () => {
    setProcessing(false)
    setShowModal(false)
  }

  const onSubmit = useCallback(data => {
    setProcessing(true)
    const id = jiclConfig.get('id')
    const attributes = {data: {value: data.toJS()}}

    updateResource({type: 'jicl-configs', id, attributes})
      .then(() => {
        alwaysNotify.shortSuccess('Additional Info updated successfully')
        closeModal()
      })
    Logger.log({name: 'edit_jicl_response'})
  })

  const closer = () => <Closer closer={closeModal} />

  return (
    <div className='jicl-edit-response-group-container'>
      <img
        onClick={openModal}
        className='edit-icon'
        src={pencil}
        alt='pencil-icon'
      />

      {showModal && (
        <Modals.PopUpModalLarge
          showModal={showModal}
          className='forms-playground'
          closerComponent={closer}
          wrapperContainerClass='.jicl-preview'>
          <JiclAnythingElse
            onInfoDataSubmit={onSubmit}
            editScreen={true}
            processing={processing}
            onResponseCancel={closeModal}
            defaultValue={jiclConfig.getIn(['data', 'additional-info'])}
          />
        </Modals.PopUpModalLarge>
      )}
    </div>
  )
}

JiclEditAdditionalInformationContainer.propTypes = {
  jiclConfig: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func
}

export default JiclEditAdditionalInformationContainer
