import * as Boxes from '../../../dashboard/src/components/blocks/Boxes'
import BoxGroup from '../../../dashboard/src/components/blocks/BoxGroup'
import BoxWithBadge from '../../../dashboard/src/components/blocks/BoxWithBadge'
import BoxWithHeading from '../../../dashboard/src/components/blocks/BoxWithHeading'
import BoxWithImage from '../../../dashboard/src/components/blocks/BoxWithImage'
import CodeBlock from '../CodeBlock'
import CodeExamples from '../CodeExamples'
import CollapsibleSection from './CollapsibleSection'
import * as ContentBoxes from '../../../dashboard/src/components/blocks/ContentBoxes'
import secureSharingPNG from '../../../shared_assets/image-placeholder.png'
import {TextGutterMedium} from '../../../dashboard/src/components/blocks/Texts'


const ContentBoxesSection = () => (
  <CollapsibleSection heading='Content Boxes'>
    <Boxes.Box>
      <aside>
        <h3>Base: Content Box</h3>
        <ContentBoxes.ContentBox className='1337-content-box'>
          <TextGutterMedium>
            Hello, I am a Content Box. I only care about my positioning on the page. My only style is padding.
          </TextGutterMedium>
          <TextGutterMedium>
            Don't believe me? Then open your browser's dev tools and inspect my element for proof!
          </TextGutterMedium>
        </ContentBoxes.ContentBox>
        <CodeBlock>{CodeExamples.ContentBox}</CodeBlock>
      </aside>
      <aside>
        <h3>Content Box: Bottom gutter variants</h3>
        <TextGutterMedium>Aside from our base Content Box component, we can add a class to the component to provide four bottom gutter spacing options:</TextGutterMedium>
        <h4>Content Box: Small bottom gutter</h4>
        <aside>
          <ContentBoxes.ContentBoxGutterSmall>
            <TextGutterMedium>
              This Content Box component has the class <code>.gutter-small</code> which gives a bottom margin of <code>10px</code>.
            </TextGutterMedium>
          </ContentBoxes.ContentBoxGutterSmall>
          <CodeBlock>{CodeExamples.ContentBoxGutterSmall}</CodeBlock>
        </aside>
        <h4>Content Box: Medium bottom gutter</h4>
        <aside>
          <ContentBoxes.ContentBoxGutterMedium>
            <TextGutterMedium>
              This Content Box component has the class <code>.gutter-meduim</code> which gives a bottom margin of <code>20px</code>.
            </TextGutterMedium>
          </ContentBoxes.ContentBoxGutterMedium>
          <CodeBlock>{CodeExamples.ContentBoxGutterMedium}</CodeBlock>
        </aside>
        <h4>Content Box: Large bottom gutter</h4>
        <aside>
          <ContentBoxes.ContentBoxGutterLarge>
            <TextGutterMedium>
              This Content Box component has the class <code>.gutter-large</code> which gives a bottom margin of <code>30px</code>.
            </TextGutterMedium>
          </ContentBoxes.ContentBoxGutterLarge>
          <CodeBlock>{CodeExamples.ContentBoxGutterLarge}</CodeBlock>
        </aside>
        <h4>Content Box: Extra-large bottom gutter</h4>
        <aside>
          <ContentBoxes.ContentBoxGutterExtraLarge>
            <TextGutterMedium>
              This Content Box component has the class <code>.gutter-extra-large</code> which gives a bottom margin of <code>40px</code>.
            </TextGutterMedium>
          </ContentBoxes.ContentBoxGutterExtraLarge>
          <CodeBlock>{CodeExamples.ContentBoxGutterExtraLarge}</CodeBlock>
        </aside>
      </aside>
      <aside>
        <h3>Box</h3>
        <Boxes.Box className='1337-box'>
          <TextGutterMedium>
            And I am a Box, which is built on top of a Content Box. I also have additional styles such as a background-color, border, and box-shadow.
          </TextGutterMedium>
        </Boxes.Box>
        <CodeBlock>{CodeExamples.Box}</CodeBlock>
      </aside>
      <aside>
        <h3>Box: Bottom gutter variants</h3>
        <TextGutterMedium>Aside from our base Content Box component, we can add a class to the component to provide four bottom gutter spacing options:</TextGutterMedium>
        <h4>Box: Small bottom gutter</h4>
        <aside>
          <Boxes.BoxGutterSmall>
            <TextGutterMedium>
              This Box component has the class <code>.gutter-small</code> which gives a bottom margin of <code>10px</code>.
            </TextGutterMedium>
          </Boxes.BoxGutterSmall>
          <CodeBlock>{CodeExamples.ContentBoxGutterSmall}</CodeBlock>
        </aside>
        <h4>Box: Medium bottom gutter</h4>
        <aside>
          <Boxes.BoxGutterMedium>
            <TextGutterMedium>
              This Box component has the class <code>.gutter-meduim</code> which gives a bottom margin of <code>20px</code>.
            </TextGutterMedium>
          </Boxes.BoxGutterMedium>
          <CodeBlock>{CodeExamples.ContentBoxGutterSmall}</CodeBlock>
        </aside>
        <h4>Box: Large bottom gutter</h4>
        <aside>
          <Boxes.BoxGutterLarge>
            <TextGutterMedium>
              This Box component has the class <code>.gutter-large</code> which gives a bottom margin of <code>30px</code>.
            </TextGutterMedium>
          </Boxes.BoxGutterLarge>
          <CodeBlock>{CodeExamples.ContentBoxGutterSmall}</CodeBlock>
        </aside>
        <h4>Box: Extra Large bottom gutter</h4>
        <aside>
          <Boxes.BoxGutterExtraLarge>
            <TextGutterMedium>
              This Box component has the class <code>.gutter-extra-large</code> which gives a bottom margin of <code>40px</code>.
            </TextGutterMedium>
          </Boxes.BoxGutterExtraLarge>
          <CodeBlock>{CodeExamples.ContentBoxGutterSmall}</CodeBlock>
        </aside>
      </aside>
      <aside>
        <h3>Box with Heading</h3>
        <BoxWithHeading className='1337-box-with-heading' heading='Here is my Heading'>
          <TextGutterMedium>
            This is a box with a Heading and text. The required props are <strong>heading</strong> and <strong>children</strong>. The <strong>children</strong> represents the content of the box that is not the heading, where the <strong>heading</strong> prop defines the properties of the heading.
          </TextGutterMedium>
        </BoxWithHeading>
        <CodeBlock>{CodeExamples.BoxWithHeading}</CodeBlock>
      </aside>
      <aside>
        <h3>Box with Image</h3>
        <BoxWithImage
          className='1337-box-with-image'
          src={secureSharingPNG}
          alt='Secure Sharing, Everplans'
          imgClassName='an-img-class'
          tagline='A generic tagline'
          heading='h2 Heading'>
          <TextGutterMedium>
            This is Box with an Image. The required props are <strong>alt</strong>, <strong>children</strong>, and <strong>src</strong>. <strong>children</strong> represents the contents of the Box that aren't the image, while <strong>alt</strong> and <strong>src</strong> define the properties of the image in the Box. The optional props are <strong>heading</strong>, <strong>tagline</strong>, <strong>className</strong>, and <strong>imageClassName</strong>. Any other props than those mentioned are passed to the image.
          </TextGutterMedium>
        </BoxWithImage>
        <CodeBlock>{CodeExamples.BoxWithImage}</CodeBlock>
      </aside>
      <aside>
        <h3>Box with Badge</h3>
        <TextGutterMedium>
          The Boxes with Badges below are wrapped in a BoxGroup that automatically aligns them into a columnar layout. This
          layout can be tweaked using the flexRows Sass mixin to customize min-width of children, max-width of the container,
          number of columns, etc.
        </TextGutterMedium>
        <TextGutterMedium>
          Resize this window to see how the BoxGroup breaks the boxes onto different lines when necessary, allows boxes on lines
          that are not 'full' grow to fill the row, and more!
        </TextGutterMedium>
        <BoxGroup>
          <BoxWithBadge badgeType='article' heading='Massive Explosion at Article Accelerator'>
            A four-alarm fire still rages after a tragic explosion at a local article accelerator early this morning. The fire began in the
            basement of the headquarters of FasText, a startup founded by renowned liar James Frey, and quickly engulfed the boiler, leading
            to a blast heard for miles around.
          </BoxWithBadge>
          <BoxWithBadge badgeType='checklist' heading="Anton Checklist: Don't Forget the Gun from Act I">
            Do you ever have trouble remembering the little details that critics always seem to use to tear apart your plays in their
            reviews? You'll never have that trouble again if you use our brand new Anton Checklist! Don't let critics slam you for
            forgetting to bring back that gun you introduced in the first act.
          </BoxWithBadge>
          <BoxWithBadge badgeType='guide' heading='Guides and Dolls'>
            Do you like dolls? And are you the kind of traveler who reads the entire guidebook before you even board the plane for a trip?
            Well, then you'll love <i>Guides and Dolls</i>, our new guide for where to find the best information about dolls you can go
            see in foreign museums!
          </BoxWithBadge>
        </BoxGroup>
        <CodeBlock>{CodeExamples.BoxWithBadge}</CodeBlock>
      </aside>
    </Boxes.Box>
  </CollapsibleSection>
)

export default ContentBoxesSection
