import PropTypes from 'prop-types'

import {Icon} from '../../../../shared_components/Icon'

import './contactMethodRow.scss'

const ContactMethodRow = props => {
  if (props.data)
    return <div className='row-wrapper contact-row'><Icon className={props.className} />{props.children}</div>

  return <div />
}

ContactMethodRow.propTypes = {
  data: PropTypes.string
}

export default ContactMethodRow
