import {connect} from 'react-redux'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {updateResource} from 'redux-json-api'
import {useState, useCallback, useEffect} from 'react'

import JiclSubSection from './sub_sections/JiclSubSection'

import {SCREEN_CONFIG, SECTION_ORDER} from '../../lib/jiclMainSectionConfiguration'
import {filterViewsByName} from '../../../../lib/viewTools'


const JiclMainSectionContainer = props => {
  const getSectionViews = sectionConfig =>
    filterViewsByName({
      viewNames: sectionConfig.getIn(['viewConfig', 'names']),
      views: props.views
    })

  const setSubSection = sectionConfig => (
    sectionConfig.get('assessmentConfig').isEmpty() ?
      'responses' :
      'assessment'
  )

  const [responses, setResponses] = useState(List())
  const [assessments, setAssessments] = useState(Map())
  const [guides, setGuides] = useState(Map())

  const [sectionState, setSectionState] = useState(() => {
    const sectionConfig = SCREEN_CONFIG.get(SECTION_ORDER.first())

    return {
      sectionIndex: 0,
      subSection: setSubSection(sectionConfig),
      sectionConfig,
      sectionViews: getSectionViews(sectionConfig),
      isLastSection: false
    }
  })

  const updateSectionState = useCallback(() => {
    setSectionState(() => {
      const nextSectionIndex = sectionState.sectionIndex + 1
      const sectionConfig = SCREEN_CONFIG.get(
        SECTION_ORDER.get(nextSectionIndex, SECTION_ORDER.last())
      )

      return {
        sectionIndex: nextSectionIndex,
        subSection: setSubSection(sectionConfig),
        sectionConfig,
        sectionViews: getSectionViews(sectionConfig),
        isLastSection: nextSectionIndex + 1 === SECTION_ORDER.size
      }
    })
  }, [sectionState])

  const submitJiclData = useCallback(() => {
    props.onStepChange(Map({assessments, responses, guides}))
  }, [assessments, guides, responses])

  const onAssessmentSubmit = useCallback(
    assessmentResponse => {
      const assessmentKey = sectionState.sectionConfig.getIn(['assessmentConfig', 'value'])
      setAssessments(assessments.merge(Map({[assessmentKey]: assessmentResponse})))

      if (assessmentResponse)
        setSectionState({...sectionState, subSection: 'responses'})
      else if (!sectionState.sectionConfig.get('guideConfig', Map()).isEmpty())
        setSectionState({...sectionState, subSection: 'guide'})
      else
        updateSectionState()
    },
    [assessments, sectionState]
  )

  const onGuideSubmit = useCallback(
    guideResponse => {
      const guideKey = sectionState.sectionConfig.getIn(['guideConfig', 'value'])
      setGuides(guides.merge(Map({[guideKey]: guideResponse})))
      updateSectionState()
    },
    [guides, sectionState]
  )

  const onResponseGroupSubmit = useCallback(
    newResponses => {
      setResponses(responses.concat(newResponses))
      updateSectionState()
    },
    [responses, sectionState]
  )

  useEffect(() => {
    // Check to make sure that all sections have been shown to the user.
    if (sectionState.sectionIndex >= SECTION_ORDER.size) submitJiclData()
  }, [sectionState])

  return (
    <JiclSubSection
      {...props}
      className={SECTION_ORDER.get(
        sectionState.sectionIndex,
        SECTION_ORDER.last()
      )}
      responses={Map()}
      sectionViews={sectionState.sectionViews}
      onAssessmentSubmit={onAssessmentSubmit}
      onGuideSubmit={onGuideSubmit}
      sectionConfig={sectionState.sectionConfig}
      onResponseGroupSubmit={onResponseGroupSubmit}
      subSection={sectionState.subSection}
      sectionIndex={sectionState.sectionIndex}
    />
  )
}

JiclMainSectionContainer.propTypes = {
  views: PropTypes.instanceOf(List),
  onStepChange: PropTypes.func
}

const mapStateToProps = state => ({
  kits: state.kits,
  items: state.items,
  listMappings: state.listMappings,
  views: state.views,
  elements: state.elements
})

export default connect(mapStateToProps, {updateResource})(JiclMainSectionContainer)
