import PropTypes from 'prop-types'
import React from 'react'
import {Link} from 'react-router'
import {List, Map} from 'immutable'
import {connect} from 'react-redux'
import {createResource, readEndpoint} from 'redux-json-api'

import LegacyLoader from '../../../../shared_components/LegacyLoader'
import routerUtils from '../../../../lib/routerUtils'
import {currentUserConfig} from '../../../../lib/userTools'
import {
  findResourceById,
  filterRawResourceByAttributeId
} from '../../../../lib/plan_data/userData'
import Modal from '../../../../shared_components/Modal'
import ModalWrapper from '../../../../shared_components/ModalWrapper'
import {cleanResponse} from '../../../../dashboard/src/components/corp_max/redux/apiHelper'

import './pickAdder.scss'


export class PickAdder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {activeLink: ''}

    this.returnToClients = this.returnToClients.bind(this)
  }
  componentDidMount() { this.context.showModal() }
  activateLink(activeLink) { this.setState({activeLink}) }
  returnToClients() {
    routerUtils.push('pro/clients')
    this.context.closeModal()
  }

  render() {
    return (
      <Modal visible={this.props.showModal} closer={this.returnToClients}>
        <div className='pick-adder'>
          <ul className='add-options'>
            <li className='submenu' onClick={this.activateLink.bind(this, '')}>
              <Link to='pro/clients/add_individual' activeClassName='active-link'>ADD INDIVIDUAL</Link>
            </li>
            <li className='submenu' onClick={this.activateLink.bind(this, 'household')}>
              <Link to='pro/clients/add_household' activeClassName='active-link' className={this.state.activeLink === 'household' ? 'active-link' : ''}>ADD HOUSEHOLD</Link>
            </li>
            <li className='submenu' onClick={this.activateLink.bind(this, 'many')}>
              <Link to='pro/clients/add_many' activeClassName='active-link' className={this.state.activeLink === 'many' ? 'active-link' : ''}>UPLOAD .CSV</Link>
            </li>
          </ul>
          <LegacyLoader loading={this.state.loading}>
            {
              routerUtils.childrenWithProps(
                this.props.children,
                {
                  closer: this.returnToClients,
                  clientActivities: this.props.clientActivities,
                  createResource: this.props.createResource,
                  readEndpoint: this.props.readEndpoint,
                  firmSeats: this.props.firmSeats,
                  firm: this.props.firm,
                  clientSeats: this.props.clientSeats,
                  userConfig: this.props.userConfig
                }
              )
            }
          </LegacyLoader>
        </div>
      </Modal>
    )
  }
}

PickAdder.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

PickAdder.propTypes = {
  clientActivities: PropTypes.instanceOf(Map),
  clientSeats: PropTypes.instanceOf(List),
  createResource: PropTypes.func,
  readEndpoint: PropTypes.func,
  firm: PropTypes.instanceOf(Map),
  firmSeats: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => {
  const userConfig = currentUserConfig(api)
  const firmId = userConfig.get('firm-id')

  return {
    clientSeats: cleanResponse(api['client-seats']),
    firm: findResourceById({
      resourceList: cleanResponse(api.firms),
      id: firmId
    }),
    firmSeats: filterRawResourceByAttributeId({
      attribute: 'firm-id',
      rawResource: api['firm-seats'],
      id: firmId
    }),
    userConfig
  }
}

export default connect(mapStateToProps, {createResource, readEndpoint})(
  ModalWrapper(PickAdder)
)
