import {Fragment} from 'react'
import ShadowHeader from '../ShadowHeader'
import EditCompliance from '../settings/EditCompliance'
import {isEnagagementAutomated} from '../../../../../lib/tools'

const CustomInviteMessage = props => {
  if (isEnagagementAutomated(props.firmOrganization)) {
    return null
  } else {
    return (
      <Fragment>
        <ShadowHeader headerText='Custom Invite Message' />
        <EditCompliance
          isHighCompliance={props.isHighCompliance}
          {...props}
        />
      </Fragment>
    )
  }
}

export default CustomInviteMessage
