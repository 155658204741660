import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {BoxGutterMedium} from './Boxes'
import Closer from '../../../../shared_components/core/closer/Closer'


const BoxWithHeading = ({children, closer, heading, ...otherProps}) => (
  <BoxGutterMedium {...otherProps} className={classnames('core box-with-heading', otherProps.className)} >
    <div className='box-lid flex-container'>
      {heading && <h3 className='flex-child'>{heading}</h3>}
      {closer && <Closer alt='dismiss' className='box-closer flex-child' closer={closer} />}
    </div>
    {children}
  </BoxGutterMedium>
)

BoxWithHeading.propTypes = {
  closer: PropTypes.func,
  heading: PropTypes.string
}

export default BoxWithHeading
