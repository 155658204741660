import {useCallback, useState} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Loader from '../../../../shared_components/NewLoader'
import PickRecipients from './views/PickRecipients'

const PickRecipientsController = props => {
  const [processing, setProcessing] = useState(false)
  const advisorSeat = props.firmSeats.find(seat => seat.get('id') === props.params.advisorSeatId) || Map()

  const onContinue = useCallback(recipients => {
    if (props.organization.get('organization-client-invite') === 'engagement_automation') {
      setProcessing(true)
      props.sendInvitations({type: 'bulk-client-invites', attributes: {recipients: recipients.toJS()}}, () => setProcessing(false))
    } else { props.next(advisorSeat, recipients) }
  }, [props.organization.size])

  return (
    <Loader loading={props.loading}>
      <PickRecipients {...props} processing={processing} advisorSeat={advisorSeat} onContinue={onContinue} />
    </Loader>
  )
}

PickRecipientsController.propTypes = {
  firmSeats: PropTypes.instanceOf(List),
  next: PropTypes.func,
  sendInvitations: PropTypes.func,
  organization: PropTypes.instanceOf(Map),
  params: PropTypes.shape({advisorSeatId: PropTypes.string})
}

export default PickRecipientsController
