import CodeBlock from '../CodeBlock'
import CodeExamples from '../CodeExamples'
import CollapsibleSection from './CollapsibleSection'
import {BoxGutterMedium} from '../../../dashboard/src/components/blocks/Boxes'
import * as Texts from '../../../dashboard/src/components/blocks/Texts'


const TextSection = () => (
  <CollapsibleSection heading='Text'>
    <BoxGutterMedium>
      <aside>
        <h3>Text: Base</h3>
        <Texts.Text className='1337-text'>
          This is one Text block. Text blocks are different from paragraphs. They are used, for example, for the text of To Dos or the blurb about privacy at
          the bottom of the My Professional Partner page.
        </Texts.Text>
        <Texts.Text className='1337-text'>
          Here is a second text block. Notice the only styles are font size?
        </Texts.Text>
        <CodeBlock>{CodeExamples.Text}</CodeBlock>
      </aside>
      <aside>
        <h3>Text: Bottom gutter variants</h3>
        <Texts.TextGutterMedium>Aside from our base Text block component, we can add a class to the component to provide four bottom gutter spacing options:</Texts.TextGutterMedium>
        <h4>Text: Small bottom gutter</h4>
        <aside>
          <Texts.TextGutterSmall>
            This Text block component has the class <code>.gutter-small</code> which gives a bottom margin of <code>10px</code>.
          </Texts.TextGutterSmall>
          <CodeBlock>{CodeExamples.TextGutterSmall}</CodeBlock>
        </aside>
        <h4>Text: Medium bottom gutter</h4>
        <aside>
          <Texts.TextGutterMedium>
            This Text block component has the class <code>.gutter-meduim</code> which gives a bottom margin of <code>20px</code>.
          </Texts.TextGutterMedium>
          <CodeBlock>{CodeExamples.TextGutterMeduim}</CodeBlock>
        </aside>
        <h4>Text: Large bottom gutter</h4>
        <aside>
          <Texts.TextGutterLarge>
            This Text block component has the class <code>.gutter-large</code> which gives a bottom margin of <code>30px</code>.
          </Texts.TextGutterLarge>
          <CodeBlock>{CodeExamples.TextGutterLarge}</CodeBlock>
        </aside>
        <h4>Text: Extra-large bottom gutter</h4>
        <aside>
          <Texts.TextGutterExtraLarge>
            This Text block component has the class <code>.gutter-extra-large</code> which gives a bottom margin of <code>40px</code>.
          </Texts.TextGutterExtraLarge>
          <CodeBlock>{CodeExamples.TextGutterExtraLarge}</CodeBlock>
        </aside>
      </aside>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default TextSection
