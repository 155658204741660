import {Link} from 'react-router'
import PropTypes from 'prop-types'

const InviteDraftClients = props => (
  <li>
    {
      props.hasDraftClients ?
        <Link to='/pro/clients/bulk-actions/select-advisor/draft'>Invite Draft Clients...</Link> :
        <a title='Invite draft clients is only available if you have clients in draft state.' className='disabled'>Invite Draft Clients...</a>
    }
  </li>
)

InviteDraftClients.propTypes = {hasDraftClients: PropTypes.bool}

export default InviteDraftClients
