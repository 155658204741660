import {fromJS, List} from 'immutable'


export const SCREEN_CONFIG = fromJS({
  'get-started': {
    header: "Let's get started",
    notes: [
      "In the next few minutes, we're going to help you compile a list of simple yet useful bits of information about your life.",
      "You can skip questions you're not ready to answer and come back to them later before sending your list. (Or leave them blank if they don't apply.)"
    ],
    fields: [],
    buttonText: 'Get started',
    footer: [
      {
        startText: 'Already started a Just-In-Case List? ',
        linkText: 'Log in here.',
        path: '/jicl/continue'
      },
      {
        startText: 'Already have an Everplans account? ',
        linkText: 'Log in here.',
        endText: ' and keep planning',
        path: '/auth/sign_in'
      }
    ]
  },
  'who-should-know': {
    header: 'Who needs to know?',
    notes: [
      "If an emergency struck, who is the person you'd be helping the most by sharing this information?",
      'It might not be an obvious choice. Your spouse or partner might already know all this info. What about your children, parents, a best friend, or another person you trust to come through in a pinch?',
      'Or you can keep it for yourself and share it later.'
    ],
    fields: [
      {
        field: 'Text',
        legend: "What's their name?",
        name: 'recipient-name',
        shortDescription: 'You can change your mind later.'
      }
    ],
    buttonText: 'Continue',
    footer: []
  },
  'securing-your-account': {
    header: 'Securing your account',
    notes: [
      "Let's set up your account so none of your information is lost. Fear not, your info will be kept completely secure!"
    ],
    fields: [
      {
        field: 'Text',
        legend: "What's your name?",
        name: 'name',
        validator: 'required'
      },
      {
        field: 'Email',
        legend: "What's your email address?",
        name: 'email',
        shortDescription: "We won't sell your contact information, ever.",
        validator: 'emailValidator'
      }
    ],
    buttonText: 'Continue',
    footer: [
      {
        startText: 'You can read more about our ',
        linkText: 'security practice.',
        linkComponentName: 'Security',
        target: '_blank'
      }
    ]
  }
})


export const SECTION_ORDER = List([
  'get-started',
  'who-should-know',
  'securing-your-account'
])
