import {Component} from 'react'
import PropTypes from 'prop-types'
import {fromJS} from 'immutable'

import {ControlButton, Cancel, Continue} from '../../../../../../shared_components/ButtonTypes'
import Form from '../../../../../../shared_components/forms/v2/Form'
import VerticalRadioList from '../../../../../../shared_components/forms/v2/fields/VerticalRadioList'
import Modal from '../../../../../../shared_components/Modal'
import modalWrapper, {MODAL_CONTEXT_TYPES} from '../../../../../../shared_components/ModalWrapper'
import ShadowHeader from '../../ShadowHeader'
import validators from '../../../../../../shared_components/forms/v2/validators'

import FirmActions from '../../../../actions/FirmActions'

export class EditProfessionalDesignation extends Component {
  constructor(props) {
    super(props)

    this.state = {professionalDesignation: props.professionalDesignation}

    this.cancel = this.cancel.bind(this)
    this.saveProfessionalDesignation = this.saveProfessionalDesignation.bind(this)
  }

  saveProfessionalDesignation() {
    const formData = this.refs.form.formData()
    FirmActions.updateFirmConfig({
      firmId: this.props.firmId,
      data: {firm_config: {professional_designation: formData.getIn(['professional-designation', 'value'])}}
    })

    this.context.closeModal()
  }

  cancel() {
    this.setState({professionalDesignation: this.props.professionalDesignation})
    this.context.closeModal()
  }

  render() {
    if (this.props.isFirmActive) {
      return (
        <div className='edit-config edit-professional-designation'>
          <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
          <Modal visible={this.props.showModal}>
            <ShadowHeader headerText='Professional Designation' />
            <div className='forms-playground'>
              <Form className='core' ref='form'>
                <VerticalRadioList
                  data={
                    fromJS({
                      id: 'professional-designation',
                      items: [
                        {label: 'Advisor', value: 'Advisor'},
                        {label: 'Agent', value: 'Agent'},
                        {label: 'Attorney', value: 'Attorney'},
                        {label: 'Funeral Director', value: 'Funeral Director'},
                        {label: 'Planner', value: 'Planner'},
                        {label: 'Professional', value: 'Professional'}
                      ],
                      legend: 'Designation',
                      name: 'professional-designation',
                      value: this.props.professionalDesignation
                    })
                  }
                  validator={validators.requiredImmutableList}
                />
              </Form>
              <div className='button-group'>
                <Cancel onClick={this.cancel} />
                <Continue loading={this.props.processing} onClick={this.saveProfessionalDesignation}>Save</Continue>
              </div>
            </div>
          </Modal>
        </div>
      )
    } else {
      return null
    }
  }
}

EditProfessionalDesignation.contextTypes = MODAL_CONTEXT_TYPES

EditProfessionalDesignation.defaultProps = {
  showModal: false
}

EditProfessionalDesignation.propTypes = {
  finished: PropTypes.func,
  firmId: PropTypes.string.isRequired,
  isAccountManager: PropTypes.bool,
  professionalDesignation: PropTypes.string,
  isFirmActive: PropTypes.bool,
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  updating: PropTypes.func
}

export default modalWrapper(EditProfessionalDesignation)
