import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'

import FirmConfig from './FirmConfig'

import FirmHeader from '../shared_firm/FirmHeader'
import FirmNav from '../shared_firm/FirmNav'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import cobrandingWrapper from '../../../../shared_components/wrappers/cobrandingWrapper'
import CobrandingActions from '../../../../shared_actions/CobrandingActions'
import Container from '../../../../lib/Container'
import DisclosureActions from '../../../../shared_actions/DisclosureActions'
import FirmActions from '../../actions/FirmActions'
import firmHeaderProps from '../../lib/firmHeaderPropsHelper'
import storePrototype from '../../../../shared_components/StorePrototype'
import storeWrapper from '../../../../shared_components/storeWrapper'
import {usePrevious} from '../../../../lib/hooks'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

Container.registerAction('disclosure', DisclosureActions)
Container.registerAction('firm_config', FirmActions)

Container.registerStore(
  'disclosure',
  storePrototype([
    DisclosureActions.Types.DID_UPDATE_DISCLOSURE,
    DisclosureActions.Types.GOT_DISCLOSURE
  ])
)

const FirmConfigController = props => {
  const disclosureStore = Container.getStore('disclosure')

  const {isDifferent} = useNotificationContext()
  const prevFirmConfigUpdated = usePrevious(props.firmConfigUpdated)
  const [loadingDisclosure, setLoadingDisclosure] = useState(true)
  const [disclosure, setDisclosure] = useState({
    data: {},
    error: [],
    message: null
  })

  const notificationOnUpdates = (updatedData, previousData) => {
    isDifferent(updatedData.data, previousData.data).shortSuccess('Firm settings updated.')
    isDifferent(updatedData.errors, previousData.errors).shortError(
      "We're sorry, but something went wrong while updating this firm's settings. Please try again or reach out to the current Dev on Call."
    )
  }

  const onDisclosureUpdate = () => {
    if (disclosure.data && disclosure.data.id)
      notificationOnUpdates(disclosureStore.getState(), disclosure)

    setDisclosure({...disclosureStore.getState()})
    setLoadingDisclosure(false)
  }

  useEffect(() => {
    disclosureStore.addChangeListener(onDisclosureUpdate)
    DisclosureActions.fetchDisclosure(props.params.firmId)

    return () => { disclosureStore.removeChangeListener(onDisclosureUpdate) }
  }, [])

  useEffect(() => {
    notificationOnUpdates(props.firmConfigUpdated, prevFirmConfigUpdated)
  }, [props.firmConfigUpdated])


  const firmConfig = props.firm_config.data
  const isHighCompliance = firmConfig.compliance_level === 'high'
  const settingsSharedProps = {
    firmId: props.params.firmId,
    isAccountManager: props.currentUser.account_manager,
    isFirmActive: props.firm.data.active
  }

  return (
    <div>
      <FirmHeader {...firmHeaderProps(props)}>
        <FirmNav firmId={props.params.firmId} isAccountManager={props.currentUser.account_manager} />
      </FirmHeader>
      <LegacyLoader loading={props.loadingCobranding || loadingDisclosure}>
        <FirmConfig
          cobranding={props.cobranding}
          disclosure={disclosure.data}
          firm={props.firm.data}
          firmConfig={firmConfig}
          isHighCompliance={isHighCompliance}
          settingsSharedProps={settingsSharedProps}
          firmOrganization={props.organization}
        />
      </LegacyLoader>
    </div>
  )
}

FirmConfigController.defaultProps = {
  cobranding: {
    attributes: {}
  },
  firm: {
    data: {},
    errors: [],
    message: null
  },
  firm_config: {
    data: {},
    errors: [],
    message: null
  }
}

FirmConfigController.propTypes = {
  cobranding: PropTypes.object,
  currentUser: PropTypes.shape({account_manager: PropTypes.bool}),
  disclosure: PropTypes.shape({
    data: PropTypes.shape({
      enabled: PropTypes.bool,
      markdown_text: PropTypes.string,
      show_in_email: PropTypes.bool,
      show_in_footer: PropTypes.bool
    })
  }),
  firm: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.bool,
      organization_id: PropTypes.string
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  firm_config: PropTypes.shape({
    data: PropTypes.shape({
      compliance_level: PropTypes.string,
      deputizable: PropTypes.bool
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  firmConfigUpdated: PropTypes.shape({
    data: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.string)
  }),
  loadingCobranding: PropTypes.bool,
  loadingDisclosure: PropTypes.bool,
  params: PropTypes.shape({
    firmId: PropTypes.string.isRequired
  }),
  organization: PropTypes.shape({
    data: PropTypes.object
  })
}

export default storeWrapper(
  cobrandingWrapper(FirmConfigController, props => props.firm.data.cobranding_id),
  [
    {
      actionTypes: [
        CobrandingActions.Types.DID_UPDATE_COBRANDING,
        FirmActions.Types.DID_UPDATE_FIRM_CONFIG
      ],
      name: 'firmConfigUpdated'
    }
  ]
)
