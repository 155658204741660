import assign from 'object-assign'
import KeyMirror from 'keymirror'

import ActionPrototype from '../../../shared_components/ActionPrototype'

const ReferralActions = assign({}, ActionPrototype, {
  Types: KeyMirror({
    GOT_REFERRAL: null
  }),
  fetchReferral(referralId) {
    this.fireApi('get', `/referrals/${referralId}`, null, {successAction: this.Types.GOT_REFERRAL, JSONHead: 'referral'})
  }
})

export default ReferralActions
