import customImage from '../../../../shared_assets/custom.svg'
import expertImage from '../../../../shared_assets/expert_guidance.svg'
import securityImage from '../../../../shared_assets/security.svg'
import sharingImage from '../../../../shared_assets/secure_sharing.svg'

const Offers = () => (
  <div className='offers'>
    <h3>What we offer</h3>
    <div className='offer-box'>
      <img src={sharingImage} />
      <p>Secure sharing of information</p>
    </div>
    <div className='offer-box'>
      <img src={expertImage} />
      <p>Expert guidance and resources</p>
    </div>
    <div className='offer-box'>
      <img src={customImage} />
      <p>Custom fit for your situation</p>
    </div>
    <div className='offer-box'>
      <img src={securityImage} />
      <p>Security you can rely on</p>
    </div>
  </div>
)

export default Offers
