import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import {Cancel, Continue, ControlButton} from '../../../../shared_components/ButtonTypes'
import Container from '../../../../lib/Container'
import FirmActions from '../../actions/FirmActions'
import Modal from '../../../../shared_components/Modal'
import modalWrapper from '../../../../shared_components/ModalWrapper'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import routerUtils from '../../../../lib/routerUtils'
import {usePrevious} from '../../../../lib/hooks'

import storePrototype from '../../../../shared_components/StorePrototype'

import './downgradeFirm.scss'


Container.registerAction('downgrade_firm', FirmActions)
Container.registerStore('downgrade_firm', storePrototype([FirmActions.Types.DID_DOWNGRADE_FIRM]))

export const DowngradeFirm = (props, context) => {
  const prevData = usePrevious(props.data)
  const prevError = usePrevious(props.errors)
  const [processing, setProcessing] = useState(false)
  const {isDifferent} = useNotificationContext()

  const downgrade = () => {
    setProcessing(true)
    props.updating(() => routerUtils.push('pro/firm-admin/all-firms'))
    FirmActions.downgradeFirm(props.firmId)
    props.finished()
  }

  useEffect(() => {
    isDifferent(props.data, prevData).shortSuccess(props.data.message)
    isDifferent(props.errors, prevError).error("We're sorry, but something went wrong while downgrading this firm. Please try again.")
    if (props.errors.length > 0)
      context.closeModal()
  }, [props.data, props.errors])

  return (
    <div className='downgrade-firm'>
      <ControlButton
        buttonText='Downgrade firm'
        onClick={context.showModal}
      />
      <Modal visible={props.showModal} closer={context.closeModal}>
        <div className='modal-header'>Are you sure you want to downgrade this firm?</div>
        <p className='sub-header'>You are about to downgrade {props.firm.name}.</p>
        <ul>
          <li>Firm members will lose access to Everplans Professional.</li>
          <li>Firm members and clients will be de-associated from the firm and receive a free version of Everplans.</li>
          <li>Any draft or pending client invitations and data will be removed.</li>
          <li>A list of firm members and active clients will be preserved.</li>
          <li>Settings and customizations (probranding, messages) will be preserved.</li>
          <li>Notifications will be emailed to active clients and firm members.</li>
        </ul>
        <p className='call-out'>This action might take a few minutes.</p>
        <div className='button-group'>
          <Cancel onClick={context.closeModal} tabIndex={0} />
          <Continue onClick={downgrade} loading={processing}>Downgrade firm</Continue>
        </div>
      </Modal>
    </div>
  )
}

export default modalWrapper(bindUpdate(DowngradeFirm, 'downgrade_firm', null))

DowngradeFirm.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

DowngradeFirm.propTypes = {
  finished: PropTypes.func,
  firmId: PropTypes.string,
  firm: PropTypes.shape({
    name: PropTypes.string
  }),
  data: PropTypes.shape({
    message: PropTypes.string
  }),
  errors: PropTypes.arrayOf(PropTypes.string),
  showModal: PropTypes.bool,
  updating: PropTypes.func
}
