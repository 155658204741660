import PropTypes from 'prop-types'
import React from 'react'

import {Cancel, Continue, ControlButton} from '../../../../shared_components/ButtonTypes'
import Modal from '../../../../shared_components/Modal'
import ModalWrapper from '../../../../shared_components/ModalWrapper'

import FirmActions from '../../actions/FirmActions'

export class DisconnectIntegrationModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {processing: false}
    this.disconnectIntegration = this.disconnectIntegration.bind(this)
  }
  disconnectIntegration(data) {
    this.setState({processing: true})
    FirmActions.removeIntegration(this.props.integration)
    this.context.closeModal()
    this.setState({processing: false})
  }

  render() {
    const integration = this.props.integration
    return (
      <div className='disconnect-button'>
        <ControlButton buttonText='Disconnect' faClass='fa-close' onClick={this.context.showModal} />
        <Modal visible={this.props.showModal}>
          <h2>Are you sure you want to disconnect?</h2><br />
          <p>Disconnecting this integration will remove your ability to sign into Everplans from your {integration.charAt(0).toUpperCase() + integration.slice(1)} portal.</p>
          <div className='button-group'>
            <Cancel onClick={this.context.closeModal} tabIndex='3' />
            <Continue loading={this.state.processing} onClick={this.disconnectIntegration} tabIndex='2'>Disconnect</Continue>
          </div>
        </Modal>
      </div>
    )
  }
}

DisconnectIntegrationModal.propTypes = {
  integrations: PropTypes.object,
  showModal: PropTypes.bool.isRequired
}

DisconnectIntegrationModal.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

export default ModalWrapper(DisconnectIntegrationModal)
