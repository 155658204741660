import PropTypes from 'prop-types'
import {useState, useEffect} from 'react'

import FirmHeader from '../shared_firm/FirmHeader'
import FirmForm from './FirmForm'

import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import Container from '../../../../lib/Container'
import routerUtils from '../../../../lib/routerUtils'
import FirmActions from '../../actions/FirmActions'

const FirmsTableHeader = props => {
  const newFirmStore = Container.getStore('newFirm')
  const [processing, setProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {alwaysNotify, dismissToastNotification} = useNotificationContext()

  const handleNotificationClick = firmId => {
    routerUtils.push(`/pro/firm/${firmId}/licenses`)
    dismissToastNotification()
  }

  const notifyAndFetchFirmSummaries = firmData => {
    props.fetchFirmSummaries()

    alwaysNotify.longSuccess(
      <span>
        <a onClick={() => handleNotificationClick(firmData.firm_id)}>
          {firmData.name}
        </a> has been added.
      </span>
    )
  }

  const onUpdate = () => {
    const storeState = newFirmStore.getState()
    setProcessing(false)

    if (storeState.errors.length === 0) {
      notifyAndFetchFirmSummaries(storeState.data)
      setShowModal(false)
    } else {
      alwaysNotify.shortError(storeState.errors)
    }
  }

  useEffect(() => {
    newFirmStore.addChangeListener(onUpdate)

    return () => {
      newFirmStore.removeChangeListener(onUpdate)
    }
  }, [])

  const toggleModal = event => {
    if (event) event.preventDefault()

    setShowModal(prevShowModal => !prevShowModal)
  }

  const downloadFirmStats = () => {
    const resource = {
      type: 'firm-stats-report-downloads',
      attributes: {}
    }
    props.createResource(resource).then(response => {
      alwaysNotify.shortSuccess(response.data.attributes.message)
    })
  }

  const addFirm = data => {
    setProcessing(true)
    FirmActions.addFirm(data)
  }

  return (
    <span>
      <FirmHeader className='space-between' flowType='row' headerText='Pro Admin: Firms'>
        <div className='table-header-links flex-container'>
          <a className='firm-stats-download' onClick={downloadFirmStats}>Download All Firm Stats</a>
          <a className='btn btn-primary' onClick={toggleModal}>Add Firm</a>
        </div>
      </FirmHeader>
      <FirmForm
        actionText='Add'
        closeModal={toggleModal}
        loadingOrganizations={props.loadingOrganizations}
        processing={processing}
        submit={addFirm}
        showModal={showModal}
        toggleModal={toggleModal}
        organizations={props.organizations}
      />
    </span>
  )
}

FirmsTableHeader.propTypes = {
  createResource: PropTypes.func,
  loadingOrganizations: PropTypes.bool,
  fetchFirmSummaries: PropTypes.func,
  organizations: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object)
  })
}

export default FirmsTableHeader
