import PropTypes from 'prop-types'

import ClientPrivacyNoticeModal from '../../../../shared_components/ClientPrivacyNoticeModal'
import HouseholdClientPrivacyNoticeModal from '../../../../shared_components/HouseholdClientPrivacyNoticeModal'
import UnorderedList from '../../../../shared_components/UnorderedList'

const clientPrivacyNoticeText = advisorFullName => [
  <span key='privacyNoticeText'>
    Your subscription will come courtesy of {advisorFullName}. In order to help you progress in
    your planning, certain information about how you are using Everplans Premium may be shared
    with {advisorFullName}. <ClientPrivacyNoticeModal advisorFullName={advisorFullName} />
  </span>
]

const householdPrivacyNoticeText = (advisorFullName, otherHouseholdOwner) => [
  <span key='householdPrivacyNoticeText' className='householdPrivacyNoticeText'>
    Your subscription is for a Household Everplan, to be shared with {otherHouseholdOwner.full_name}. {otherHouseholdOwner.first_name} is
    a co-administrator, and will be able to view, edit, and manage all parts of the Everplan that you have been
    provided. <HouseholdClientPrivacyNoticeModal advisorFullName={advisorFullName} otherHouseholdOwner={otherHouseholdOwner} />
  </span>
]


const PrivacyNotice = props => (
  <div className='privacy-notice'>
    <div className='subheading'>Privacy Notice{props.isHousehold && !props.hideClientPrivacyNotice && 's'}</div>
    {
      !props.hideClientPrivacyNotice &&
      <UnorderedList children={clientPrivacyNoticeText(props.advisorFullName)} />
    }
    {
      props.isHousehold &&
      <UnorderedList children={householdPrivacyNoticeText(props.advisorFullName, props.otherHouseholdOwner)} />
    }
  </div>
)

PrivacyNotice.defaultProps = {advisorFullName: ''}

PrivacyNotice.propTypes = {
  advisorFullName: PropTypes.string.isRequired,
  hideClientPrivacyNotice: PropTypes.bool,
  isHousehold: PropTypes.bool,
  otherHouseholdOwner: PropTypes.shape({
    full_name: PropTypes.string,
    first_name: PropTypes.string
  })
}

export default PrivacyNotice
