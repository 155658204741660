import PropTypes from 'prop-types'
import {Component} from 'react'

import ClientInvitesPreview from './ClientInvitesPreview'


export default class LegacyEmailPreviews extends Component {
  constructor(props) {
    super(props)
    this.state = {showPreview: props && props.isHighCompliance}

    this.togglePreview = this.togglePreview.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) { this.setState({showPreview: newProps.isHighCompliance}) }

  togglePreview() { this.setState({showPreview: !this.state.showPreview}) }

  render() {
    return (
      <div className='email-previews'>
        { this.props.isHighCompliance ?
          null :
          (
            <a onClick={this.togglePreview} className='show-preview-link'>
              <i className={`fa fa-caret-${(this.state.showPreview ? 'down' : 'right')}`} />
              Preview the information Everplans will automatically include in the invitation
            </a>
          )
        }
        <ClientInvitesPreview
          contactCard={this.props.contactCard}
          emailBodyTexts={this.props.emailTexts}
          disclosure={this.props.disclosure}
          hidden={this.state.isHighCompliance ? false : !this.state.showPreview}
        />
      </div>
    )
  }
}

LegacyEmailPreviews.propTypes = {
  emailTexts: PropTypes.arrayOf(PropTypes.shape({
    button_text: PropTypes.string,
    email_text: PropTypes.string
  }).isRequired).isRequired,
  isHighCompliance: PropTypes.bool.isRequired,
  contactCard: PropTypes.object.isRequired,
  disclosure: PropTypes.shape({
    enabled: PropTypes.bool,
    markdown_text: PropTypes.string,
    show_in_email: PropTypes.bool
  })
}

LegacyEmailPreviews.defaultProps = {
  emailTexts: [{}],
  isHighCompliance: false,
  contactCard: {}
}
