/** @class AddResponseGroup
 *
 * @desc
 * `button` that opens the add item modal so a firm user can add a new response group
 * to a client's plan.
 **/

import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {ControlButton} from '../../../../../shared_components/ButtonTypes'

import {categorySlug} from '../../../../../lib/plan_data/category'
import {firstElementIdForItem} from '../../../../../lib/plan_data/item'
import {proNewItemPath} from '../../../../../lib/navigation/proNavigation'
import routerUtils from '../../../../../lib/routerUtils'
import {firstTopKitParentElementId} from '../../../../../lib/plan_data/itemResponsesHelper'

import './addResponseGroup.scss'

export default class AddResponseGroup extends Component {
  constructor() {
    super()

    this.addResponseGroup = this.addResponseGroup.bind(this)
  }

  /** Constructs the URL path to the Item Modal when requesting to add a new item response. */
  addResponseGroup() {
    routerUtils.push(
      proNewItemPath({
        clientSeatId: this.props.clientSeat.get('id'),
        elementId: firstTopKitParentElementId(this.props.itemResponse),
        everplanId: this.props.itemResponse.get('everplan-id'),
        itemSlug: this.props.itemResponse.get('slug'),
        slug: categorySlug(this.props.category)
      })
    )
  }

  render() {
    return (
      <ControlButton
        buttonText='Add'
        className='add-response-group flex-child'
        onClick={this.addResponseGroup}
      />
    )
  }
}

AddResponseGroup.propTypes = {
  category: PropTypes.instanceOf(Map),
  clientSeat: PropTypes.instanceOf(Map),
  everplan: PropTypes.instanceOf(Map),
  hasEditPermission: PropTypes.bool,
  itemResponse: PropTypes.instanceOf(Map),
  kits: PropTypes.instanceOf(Map)
}
