import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {readEndpoint} from 'redux-json-api'
import {Map, List} from 'immutable'

import AgentLeadGenerationModal from '../../organization_referrals/AgentLeadGenerationModal'
import ClientsOrWelcome from './ClientsOrWelcome'

import {cleanResponse} from '../../../../../dashboard/src/components/corp_max/redux/apiHelper'
import {currentUserConfig} from '../../../../../lib/userTools'

import {
  findResourceById,
  findRawResourceByAttributeId,
  filterRawResourceByAttributeId
} from '../../../../../lib/plan_data/userData'

import './dashWithAdvisors.scss'


export class DashWithAdvisors extends React.Component {
  // passing children down to a non-route level component - StartAddClients - for rendering is anti-pattern, but can't refactor everythig at once don't do this going forward -- BJK
  render() {
    return (
      <div className='dash-with-advisors'>
        <AgentLeadGenerationModal />
        <ClientsOrWelcome
          readEndpoint={this.props.readEndpoint}
          stats={this.props.stats}
          firm={this.props.firm}
          firmConfig={this.props.firmConfig}
          firmSeats={this.props.firmSeats}
          router={this.context.router}
          children={this.props.children}
        />
      </div>
    )
  }
}

DashWithAdvisors.contextTypes = {router: PropTypes.object}

DashWithAdvisors.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.object),
  firm: PropTypes.instanceOf(Map),
  firmConfig: PropTypes.instanceOf(Map),
  firmSeats: PropTypes.instanceOf(List),
  readEndpoint: PropTypes.func,
  stats: PropTypes.instanceOf(Map)
}

// Redux resource fetching that is needed for the shared functionality of the CM
// firm detail as well as the legacy page -- SIMI
const mapStateToProps = ({api}) => {
  const firmId = currentUserConfig(api).get('firm-id')
  return ({
    firm: findResourceById({
      resourceList: cleanResponse(api.firms),
      id: firmId
    }),
    firmConfig: findRawResourceByAttributeId({
      attribute: 'firm-id',
      rawResource: api['firm-configs'],
      id: firmId
    }),
    firmSeats: filterRawResourceByAttributeId({
      attribute: 'firm-id',
      rawResource: api['firm-seats'],
      id: firmId
    })
  })
}

export default connect(mapStateToProps, {readEndpoint})(DashWithAdvisors)
