import PropTypes from 'prop-types'
import React from 'react'

import ChangedEmail from './ChangedEmail'
import ClientInviteForm from './ClientInviteForm'
import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'
import LegacyLoader from '../../../../shared_components/LegacyLoader'

import Container from '../../../../lib/Container'
import routerUtils from '../../../../lib/routerUtils'
import SessionApi from '../../../../auth/src/web/SessionApi'

import './clientInviteController.scss'


export default class ClientInviteController extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      changedEmail: false,
      changedEmailValue: null,
      errors: []
    }

    this.onUpdate = this.onUpdate.bind(this)
    this.resetEmailError = this.resetEmailError.bind(this)
  }
  componentDidMount() { this.store().addChangeListener(this.onUpdate.bind(this)) }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // NOTE: This is an anti-pattern that should be removed once bindToStores can handle data-conditional transitions
    if (nextProps.client_invite.data.confirmed || nextProps.client_invite.errors.length > 0)
      routerUtils.setLocation(`${WEBAPPS_HOST}/#/auth/invalid_token/client_invite`)
  }
  componentWillUnmount() { this.store().removeChangeListener(this.onUpdate.bind(this)) }
  onUpdate() {
    const storeState = this.store().getState()

    if (storeState.errors.length > 0) {
      this.setState({errors: [{name: 'email', errors: storeState.errors}]})
    } else if (storeState.data.changed_email) {
      this.setState({changedEmail: true, changedEmailValue: storeState.data.email})
    } else {
      SessionApi._setToken(storeState.data.confirmation)
      SessionApi.fetchCurrentUser()
      routerUtils.push('/auth/security?loc=2')
    }
  }

  resetEmailError() { this.setState({errors: []}) }
  store() { return Container.getStore('client_invite_accept') }

  render() {
    var clientInvite = this.props.client_invite.data ? this.props.client_invite.data : {}
    return (
      <LegacyLoader loading={this.props.loadingClientInvite}>
        <HeaderFlavor flavor='guest-user' />
        <div className='client-invite-controller'>
          {
            (this.state.changedEmail === true || clientInvite.changed_email === true) &&
            <ChangedEmail token={this.props.params.clientInviteId} email={this.state.changedEmailValue || clientInvite.email} />
          }

          {
            (this.state.changedEmail === false && clientInvite.changed_email === false) &&
            (
              <ClientInviteForm
                clientInvite={clientInvite}
                params={this.props.params}
                errors={this.state.errors}
                resetEmailError={this.resetEmailError}
              />
            )
          }
        </div>
      </LegacyLoader>
    )
  }
}

ClientInviteController.onBoundUpdate = function (newBoundData) {
  if ((newBoundData.data && newBoundData.data.confirmed) || (newBoundData.errors && newBoundData.errors.length > 0)) {
    this.goToThere('/auth/invalid_token/client_invite')
  } else {
    this.setState({
      client_invite: newBoundData,
      loading: false,
      loadingClientInvite: false
    })
  }
}

ClientInviteController.contextTypes = {router: PropTypes.func.isRequired}

ClientInviteController.defaultProps = {errors: [], client_invite: {data: {}, errors: [], message: null}}

ClientInviteController.propTypes = {
  processing: PropTypes.bool,
  loadingClientInvite: PropTypes.bool.isRequired,
  client_invite: PropTypes.shape({
    data: PropTypes.shape({
      changed_email: PropTypes.bool,
      confirmed: PropTypes.bool,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      premium: PropTypes.bool,
      advisor_name: PropTypes.string
    }),
    errors: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  params: PropTypes.shape({
    clientInviteId: PropTypes.string
  })
}
