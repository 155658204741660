import {fromJS, Map, List} from 'immutable'

import {elementDictionary} from '../../../lib/plan_data/item'

import categories from './categories'
import items from './items'
import kits from './kits'
import listMappings from './listMappings'
import everplan from './everplan'
import responses from './responses'
import keyFieldMappings from '../../../lib/test/fixtures/keyFieldMappings'
import ownerships from './ownerships'
import {views} from './views'

const immutableKits = fromJS(kits)
export const kitDictionary = immutableKits.reduce((dictionary, kit) => dictionary.set(kit.get('id'), kit), Map())
export const elementsDictionary = elementDictionary(immutableKits)
export const listMappingDictionary = fromJS(listMappings).reduce((dictionary, listMapping) => dictionary.set(listMapping.get('id'), listMapping), Map())
// used for redux corpMaxData (just the key fields tool tests right now)-- ZD
export const corpMaxData = {
  categories: fromJS(categories),
  items: fromJS(items),
  kits: kitDictionary,
  listMappings: listMappingDictionary
}

// used for everything else -- ZD
export const allTheThings = {
  ...corpMaxData,
  everplan: fromJS(everplan),
  keyFieldMappings: fromJS(keyFieldMappings).getIn([0, 'data']),
  owners: ownerships,
  ownerships,
  responses: fromJS(responses),
  kitaverse: {
    kitDictionary
  },
  views,
  elements: elementsDictionary,
  itemViews: List()
}
