import PropTypes from 'prop-types'
import {Map} from 'immutable'

import ContactDetails from '../ContactDetails'
import MarkdownText from '../../shared_firm/settings/MarkdownText'

import './emailPreview.scss'

const EmailPreview = ({description, contactCard, emailBody, disclosure}) => (
  <div className='email-preview'>
    {description && <p className='description'><strong>{description}</strong></p>}
    <div className='preview-body'>
      <button className='btn email-button' disabled>{`${emailBody.get('button-text')} »`}</button>
      <div className='email-text' dangerouslySetInnerHTML={{__html: emailBody.get('email-text')}} />
      <ContactDetails
        contactCard={{
          name: contactCard.get('name'),
          phone: contactCard.get('phone'),
          email: contactCard.get('email'),
          firm: contactCard.get('firm-name')
        }}
      />
      {
        disclosure.get('enabled') && disclosure.get('show-in-email') &&
        (
          <div className='email-disclosure'>
            <div className='email-disclosure-header'>Disclosure:</div>
            <MarkdownText displayText={disclosure.get('markdown-text')} />
          </div>
        )
      }
    </div>
  </div>
)

EmailPreview.propTypes = {
  contactCard: PropTypes.instanceOf(Map),
  description: PropTypes.string,
  disclosure: PropTypes.instanceOf(Map),
  emailBody: PropTypes.instanceOf(Map)
}

export default EmailPreview
