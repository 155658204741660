import {Component} from 'react'
import {connect} from 'react-redux'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import ClientSummariesTable from './ClientSummariesTable'

import {cleanApiResponseData} from '../../../../../lib/tools'
import {
  filterToQueryString,
  pageNumberAndSize,
  sortValue
} from '../../../../../lib/summariesTableTools'
import {currentUserConfig} from '../../../../../lib/userTools'
import {cleanResponse} from '../../../../../dashboard/src/components/corp_max/redux/apiHelper'

class ClientsSummariesContainer extends Component {
  constructor() {
    super()

    this.state = {loadingClients: true, clients: [], pages: -1}

    this.fetchClients = this.fetchClients.bind(this)
  }

  readEndpointQuery(tableState) {
    return tableState ?
      `${pageNumberAndSize(tableState.pageParams)}${this.sortValue(tableState)}${filterToQueryString(tableState.filters)}` :
      ''
  }

  fetchClients(tableState) {
    this.setState({loadingClients: true})

    this.props
      .readEndpoint(`client-summaries?${this.readEndpointQuery(tableState)}`)
      .then(response => this.onClientsDataFetched(response))
  }

  onClientsDataFetched(response) {
    this.setState({
      loadingClients: false,
      clients: cleanApiResponseData(response.body.data),
      pages: response.body.meta['page-count'],
      recordCount: response.body.meta['record-count']
    })
  }

  componentDidUpdate(prevProps) {
    const {addedClients, bulkClientInvites} = this.props

    if (prevProps.addedClients.size !== addedClients.size || prevProps.bulkClientInvites.size !== bulkClientInvites.size) {
      this.props.readEndpoint(`firms/${this.props.userConfig.get('firm-id')}?include=client-seats`)
      this.fetchClients()
    }
  }

  sortValue(tableState) {
    return sortValue(tableState.sorted)
  }

  render() {
    return (
      <div className='clients-summaries-container'>
        <ClientSummariesTable
          {...this.state}
          {...this.props}
          onFetchData={this.fetchClients}
        />
      </div>
    )
  }
}

ClientsSummariesContainer.propTypes = {
  addedClients: PropTypes.instanceOf(List),
  bulkClientInvites: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func
}

const mapStateToProps = ({api}) => ({
  addedClients: cleanResponse(api.clients),
  bulkClientInvites: cleanResponse(api['bulk-client-invites']),
  userConfig: currentUserConfig(api)
})

export default connect(mapStateToProps)(ClientsSummariesContainer)

