import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {List, Map} from 'immutable'

import AccountInformation from './account_information/AccountInformation'
import AccountSummary from './account_summary/AccountSummary'
import ClientActions from '../../actions/ClientActions'
import EverplanDetails from './everplan_details/EverplanDetails'
import HouseholdHeading from './heading/HouseholdHeading'
import RemoveClientController from './remove_clients/RemoveClientController'

import {allUsersDeceased} from '../../../../lib/tools'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import storeWrapper from '../../../../shared_components/storeWrapper'
import {usePrevious} from '../../../../lib/hooks'


const Household = props => {
  const prevClientInformation = usePrevious(props.editClientNotification.data)
  const {isDifferent} = useNotificationContext()

  useEffect(() => {
    isDifferent(props.editClientNotification.data, prevClientInformation).shortSuccess('Client information updated.')
  }, [props.editClientNotification.data])

  const isAnyEverplanUserActive = props.clientSeats.some(clientSeat => clientSeat.get('status') === 'Active')

  const household = props.household.data

  return (
    <div>
      {props.children}
      <div className='household'>
        <HouseholdHeading
          {...household}
          firmConfig={props.firmConfig}
          isAnyEverplanUserActive={isAnyEverplanUserActive}
          params={props.params}
        />
        <AccountInformation
          clientSeats={props.clientSeats}
          clients={props.household.data.clients}
          params={props.params}
        />
        <AccountSummary
          anyoneActive={isAnyEverplanUserActive}
          allUsersDeceased={allUsersDeceased(household.clients)}
          data={household}
          everplanSummary={props.everplanSummary}
          firmOwnership={props.firmOwnership}
        />
        <EverplanDetails {...props} everplanSummary={props.everplanSummary} />
        <RemoveClientController
          {...props}
        />
      </div>
    </div>
  )
}

Household.defaultProps = {
  household: {
    data: {
      name: '',
      advisor: {name: ''},
      clients: [],
      plan_stats: [],
      items_updated: 0,
      user_id: null
    }
  }
}

Household.propTypes = {
  editClientNotification: PropTypes.shape({
    data: PropTypes.shape({
      message: PropTypes.string
    })
  }),
  everplanSummary: PropTypes.instanceOf(Map),
  firmConfig: PropTypes.instanceOf(Map),
  firmOwnership: PropTypes.instanceOf(Map),
  owners: PropTypes.instanceOf(List),
  household: PropTypes.shape({
    data: PropTypes.shape({
      advisor: PropTypes.shape({
        name: PropTypes.string
      }),
      items_updated: PropTypes.number,
      name: PropTypes.string,
      clients: PropTypes.array
    })
  }).isRequired,
  params: PropTypes.shape({
    clientSeatId: PropTypes.string.isRequired
  }),
  clientSeats: PropTypes.instanceOf(List)
}

export default storeWrapper(
  Household,
  [{actionTypes: [ClientActions.Types.DID_UPDATE_CLIENT], name: 'editClientNotification'}]
)
