import {Link} from 'react-router'
import PropTypes from 'prop-types'

import {DropDown, DropDownMenu} from '../../../../shared_components/DropDown'


const AdvisorSetting = props => {
  const soleAdvisor = props.advisors[0] ? props.advisors[0].id : null
  return (
    <div>
      {
        props.advisors.length > 1 ?
          (
            <DropDown title={`${props.professionalDesignation}s`} className='advisor-settings'>
              <DropDownMenu>
                <ul className='advisor-dropdown'>
                  {props.advisors.map(advisor => <li key={advisor.id}><Link to={`pro/firm/${props.firm}/user/${advisor.id}/account-info`}>{advisor.name}</Link></li>)}
                </ul>
              </DropDownMenu>
            </DropDown>
          ) :
          <Link activeClassName='active' to={`pro/firm/${props.firm}/user/${soleAdvisor}/account-info`} className='header-link'>{`${props.professionalDesignation}s`}</Link>
      }
    </div>
  )
}

AdvisorSetting.propTypes = {
  advisors: PropTypes.array,
  firm: PropTypes.number,
  professionalDesignation: PropTypes.string
}

export default AdvisorSetting
