import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {createResource} from 'redux-json-api'
import {useEffect} from 'react'

import Loader from '../../../shared_components/NewLoader'
import JiclRecipientViewContainer from './preview/JiclRecipientViewContainer'
import {fromJS} from 'immutable'
import routerUtils from '../../../lib/routerUtils'

const JiclRecipientContainer = props => {
  useEffect(() => {
    const resource = {
      attributes: {token: props.params.token},
      type: 'jicl-responses'
    }

    props.createResource(resource).catch(() => {
      routerUtils.push('/auth/invalid_token/consumer_invite')
    })
  }, []) // Run this only on componentDidMount

  return (
    <Loader loading={props.loading}>
      <JiclRecipientViewContainer {...props} />
    </Loader>
  )
}

JiclRecipientContainer.propTypes = {
  createResource: PropTypes.func,
  params: PropTypes.shape({
    token: PropTypes.string
  })
}

const mapStateToProps = state => {
  if (state.api['jicl-responses']) {
    const jiclData = state.api['jicl-responses'].data[0].attributes

    return {
      loading: false,
      everplanResponse: fromJS({responses: jiclData.responses}),
      newContacts: fromJS(jiclData.contacts),
      jiclConfig: fromJS(jiclData['jicl-config']),
      kits: state.kits,
      items: state.items,
      listMappings: state.listMappings,
      views: state.views,
      elements: state.elements
    }
  } else {
    return {loading: true}
  }
}

export default connect(mapStateToProps, {createResource})(
  JiclRecipientContainer
)
