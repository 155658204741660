/** @class RequestItemPermissionInvite
 *
 * @desc
 * Class that shows the requested item permissions interstitial requested by an
 * advisor on a client's everplan.
 *
 * @todo Add proper styling.
 */

import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Accept, Cancel} from '../../../../../dashboard/src/components/blocks/Buttons'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import ItemPermissionsSelectForm from '../../../../../shared_components/item_permissions/ItemPermissionsSelectForm'
import RequestItemPermissionInviteIntroductoryMessage from './RequestItemPermissionInviteIntroductoryMessage'

import './requestItemPermissionInvite.scss'


export default class RequestItemPermissionInvite extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checkedItemIds: props.checkedItemIds,
      processingAccept: false,
      processingDecline: false
    }

    this.onCheck = this.onCheck.bind(this)
    this.declineItemPermissions = this.declineItemPermissions.bind(this)
    this.acceptItemPermissions = this.acceptItemPermissions.bind(this)
  }

  acceptItemPermissions() {
    this.setState({processingAccept: true})
    this.props.submitItemPermissions({checkedItemIds: this.state.checkedItemIds})
  }

  declineItemPermissions() {
    this.setState({processingDecline: true})
    this.props.submitItemPermissions({checkedItemIds: []})
  }

  onCheck(checkedItemIds) { this.setState({checkedItemIds}) }

  render() {
    return (
      <div className='request-item-permission-invite'>
        <h1 className='heading'>Permission to Edit</h1>
        <RequestItemPermissionInviteIntroductoryMessage
          advisorContactCard={this.props.advisorContactCard}
        />
        <ItemPermissionsSelectForm
          checkedItemIds={this.state.checkedItemIds}
          onCheck={this.onCheck}
          permittedItemIds={this.props.checkedItemIds} // Used to render only the item permissions an advisor has requested
        />
        <ButtonGroup>
          <Cancel
            onClick={this.declineItemPermissions}
            disabled={this.state.processingAccept}
            processing={this.state.processingDecline}>
            No, thanks
          </Cancel>
          <Accept
            onClick={this.acceptItemPermissions}
            disabled={!this.state.checkedItemIds.length || this.state.processingDecline}
            processing={this.state.processingAccept}>
            Grant permission
          </Accept>
        </ButtonGroup>
      </div>
    )
  }
}


RequestItemPermissionInvite.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  checkedItemIds: PropTypes.array,
  submitItemPermissions: PropTypes.func
}
