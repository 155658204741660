import moment from 'moment'
import PropTypes from 'prop-types'
import {useEffect} from 'react'

import AddLicense from './AddLicense'
import DowngradeFirm from './DowngradeFirm'
import FirmHeader from '../shared_firm/FirmHeader'
import FirmLicense from './FirmLicense'
import FirmNav from '../shared_firm/FirmNav'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import NoLicensesOfType from './NoLicensesOfType'

import ShadowHeader from '../shared_firm/ShadowHeader'

import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import FirmActions from '../../actions/FirmActions'
import firmHeaderProps from '../../lib/firmHeaderPropsHelper'
import storeWrapper from '../../../../shared_components/storeWrapper'
import {usePrevious} from '../../../../lib/hooks'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import './firmLicenses.scss'


Container.registerAction('firm_licenses', FirmActions)

export const FirmLicenses = props => {
  const prevAddedLicense = usePrevious(props.addedLicense)
  const prevArchivedLicense = usePrevious(props.archivedLicense)
  const {isDifferent} = useNotificationContext()

  useEffect(() => {
    isDifferent(props.addedLicense.data, prevAddedLicense.data).shortSuccess('License added.')
    isDifferent(props.addedLicense.errors, prevAddedLicense.errors).shortError(
      "We're sorry, but something went wrong while adding this license. Please try again or reach out to the current Dev on Call."
    )
    isDifferent(props.archivedLicense.data, prevArchivedLicense.data).shortSuccess('License archived.')
    isDifferent(props.archivedLicense.errors, prevArchivedLicense.errors).shortError(
      "We're sorry, but something went wrong while archiving this license. Please try again or reach out to the current Dev on Call."
    )
  }, [props.addedLicense, props.archivedLicense])

  const isFirmActive = props.firm.data.active
  const activeLicense = props.firm_licenses.data.active_license
  const archivedLicenses = props.firm_licenses.data.archived_licenses

  return (
    <div>
      <FirmHeader {...firmHeaderProps(props)}>
        <FirmNav firmId={props.params.firmId} isAccountManager={props.currentUser.account_manager} />
      </FirmHeader>
      <LegacyLoader loading={props.loadingFirmLicenses}>
        <div className='firm-licenses'>
          {isFirmActive ?
            <AddLicense firmId={props.params.firmId} activeLicense={activeLicense} /> :
            <p className='note'>Downgraded on {moment(props.firm.data.last_updated_at).format('MM/DD/YYYY')}</p>
          }
          {$.isEmptyObject(activeLicense) ?
            <NoLicensesOfType type='active' /> :
            <FirmLicense license={activeLicense} firmId={props.params.firmId} />
          }
          <ShadowHeader headerText='Archived Licenses' />
          {archivedLicenses.length === 0 ?
            <NoLicensesOfType type='archived' /> :
            archivedLicenses.map(archivedLicense => (
              <FirmLicense firmId={props.params.firmId} key={archivedLicense.id} license={archivedLicense} />
            ))
          }
          {isFirmActive && <DowngradeFirm firmId={props.params.firmId} firm={props.firm.data} />}
        </div>
      </LegacyLoader>
    </div>
  )
}

FirmLicenses.defaultProps = {
  firm: {
    data: {},
    errors: [],
    message: null
  },
  firm_licenses: {
    data: {
      active_license: {},
      archived_licenses: []
    },
    errors: [],
    message: null
  },
  loadingFirm: true,
  loadingFirmLicenses: true
}

FirmLicenses.propTypes = {
  addedLicense: PropTypes.object,
  archivedLicense: PropTypes.object,
  currentUser: PropTypes.shape({
    account_manager: PropTypes.bool
  }),
  firm: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.bool,
      last_updated_at: PropTypes.string
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  firm_licenses: PropTypes.shape({
    data: PropTypes.shape({
      active_license: PropTypes.object,
      archived_licenses: PropTypes.arrayOf(PropTypes.object)
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  loadingFirm: PropTypes.bool.isRequired,
  loadingFirmLicenses: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    firmId: PropTypes.string.isRequired
  }),
  organizations: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object)
  })
}


export default storeWrapper(
  bindResources(
    FirmLicenses,
    [
      {id: 'firmId', name: 'firm_licenses', type: 'item'}
    ]
  ),
  [
    {actionTypes: [FirmActions.Types.DID_ADD_LICENSE], name: 'addedLicense'},
    {actionTypes: [FirmActions.Types.DID_ARCHIVE_LICENSE], name: 'archivedLicense'}
  ]
)
