import * as Buttons from '../../dashboard/src/components/blocks/Buttons'
import {ModalHeaderBottomDivider} from '../../shared_components/core/modals/ModalHeaders'
import Modals from '../../shared_components/core/modals/Modals'
import MyHomeInsuranceForm from './MyHomeInsuranceForm'

const LargePopUpModalExample = () => (
  <section>
    <h2>Large Pop Up Modal Example</h2>
    <Modals.ButtonPopUpModalLarge launcher={<Buttons.Button>Pop Up!</Buttons.Button>}>
      <ModalHeaderBottomDivider heading='My Home Insurance' />
      <MyHomeInsuranceForm />
    </Modals.ButtonPopUpModalLarge>
  </section>
)

export default LargePopUpModalExample
