export default [
  {
    data: {
      'financial-accounts-my-tax-returns': {
        keyFieldOption1: [
          {
            type: '168d0eac-5f63-4502-b251-2f654f5a7ed5',
            name: 'Tax year'
          },
          {
            type: 'STRING',
            name: ' Tax Year'
          }
        ],
        keyFieldOption2: [
          {
            type: 'STRING',
            name: 'Tax Year'
          }
        ]
      },
      'about-me-about-me-q-vital-documentation': {
        keyFieldOption1: [
          {
            type: '29f24d23-2b4a-41a2-ba2f-895ea2c660d6',
            name: 'Type of document or ID'
          }
        ],
        keyFieldOption2: [
          {
            type: 'STRING',
            name: 'Document/ID'
          }
        ]
      },
      'pets-my-pets': {
        keyFieldOption1: [
          {
            type: '590c2aca-39d4-4196-8c61-573867ec7cd4',
            name: 'What type of Pet is this?'
          },
          {
            type: 'STRING',
            name: '-'
          },
          {
            type: '51027462-816e-41e6-9517-64e21aa1db2c',
            name: 'Name'
          }
        ],
        keyFieldOption2: [
          {
            type: '51027462-816e-41e6-9517-64e21aa1db2c',
            name: 'Name'
          }
        ],
        keyFieldOption3: [
          {
            type: '590c2aca-39d4-4196-8c61-573867ec7cd4',
            name: 'What type of Pet is this?'
          }
        ],
        keyFieldOption4: [
          {
            type: '5a35e19e-f337-4794-9048-a541c1da775e',
            name: 'Veterinarian contact info'
          }
        ],
        keyFieldOption5: [
          {
            type: 'STRING',
            name: 'Pet'
          }
        ]
      },
      'homes-and-real-estate-storage': {
        keyFieldOption1: [
          {
            type: 'STRING',
            name: 'Storage Facility'
          }
        ]
      },
      'digital-world-devices': {
        keyFieldOption1: [
          {
            type: 1283,
            name: 'Type of device'
          },
          {
            type: 'STRING',
            name: ':'
          },
          {
            type: 1285,
            name: 'Make and model of this device'
          }
        ],
        keyFieldOption2: [
          {
            type: 1283,
            name: 'Type of device'
          }
        ],
        keyFieldOption3: [
          {
            type: 'STRING',
            name: 'Device'
          }
        ]
      },
      'will-details-about-your-will': {
        keyFieldOption1: [
          {
            type: 'STRING',
            name: 'Updated on: '
          },
          {
            type: '8549c85b-879b-4c84-a913-9f4128c3347e',
            name: 'When was it last updated'
          }
        ]
      }
    }
  }
]
