import React from 'react'
import SessionStore from '../../stores/SessionStore'
import OTPSignIn from './OTPSignIn'
import PageTitle from '../../../../shared_components/PageTitle'
import preAuthHook from '../../lib/preAuthHook'

export default class TfaPrimarySignIn extends React.Component {
  componentDidMount() { preAuthHook() }

  render() {
    var session = SessionStore.getState()
    return (
      <div>
        <PageTitle title='2-Step Verification' />
        <OTPSignIn number={SessionStore.getObfuscatedPrimaryNumber()} phoneId={session.primaryPhone ? session.primaryPhone.id : null} />
      </div>
    )
  }
}
