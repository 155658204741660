/** @class ItemPermissionsContainer
 *
 * @desc
 * Container to display information about the status of requested item permissions and option to request if permitted.
 *
 * @todo Wire up the following with v2 resources:
 *.   - Item permission status (client deceased Information)
 */

import {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, updateResource} from 'redux-json-api'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import ItemPermissionsStatus from './ItemPermissionsStatus'
import RequestItemPermissionsModal from './RequestItemPermissionsModal'

import {currentUserConfig} from '../../../../lib/userTools'
import {findRawResourceByAttributeId} from '../../../../lib/plan_data/userData'
import {
  firmHasEditPermissions,
  requestedItemPermissions,
  hasPendingRequestPermissionsInvite,
  showFirmRequestPermissionLink,
  defaultRequestItemPermissionIds
} from '../../../../lib/itemPermissionTools'

class ItemPermissionsContainer extends Component {
  constructor() {
    super()

    this.sendRequest = this.sendRequest.bind(this)
  }

  sendRequest(checkedItemIds) {
    const attributes = {
      'invited-by-id': this.props.userConfig.get('id'),
      'everplan-id': this.props.everplanSummary.get('id'),
      'item-permissions': requestedItemPermissions({itemIds: checkedItemIds, permissionType: 'edit'}),
      status: 'pending'
    }

    if (this.props.requestItemPermissionsInvite.isEmpty())
      this.createInvite(attributes)
    else
      this.updateInvite(attributes)
  }

  updateInvite(attributes) {
    this.props.updateResource({
      type: 'request-item-permissions-invites',
      id: this.props.requestItemPermissionsInvite.get('id'),
      attributes
    })
  }

  createInvite(attributes) {
    this.props.createResource({
      type: 'request-item-permissions-invites',
      attributes
    })
  }

  linkText(hasEditPermissions) {
    return hasEditPermissions ? 'Manage Permissions to Edit' : 'Request Permission to Edit'
  }

  render() {
    const {requestItemPermissionsInvite, firmConfig, firmOwnership, allUsersDeceased} = this.props
    const hasEditPermissions = firmHasEditPermissions(firmOwnership)

    return (
      <div className='item-permissions-container'>
        <ItemPermissionsStatus
          allUsersDeceased={allUsersDeceased}
          hasPendingRequestItemPermissionsInvite={hasPendingRequestPermissionsInvite(requestItemPermissionsInvite)}
          writeAccess={firmConfig.get('write-access')}
          hasEditPermissions={hasEditPermissions}
        />
        <RequestItemPermissionsModal
          {...this.props}
          onConfirm={this.sendRequest}
          confirmationText='Send Request'
          linkText={this.linkText(hasEditPermissions)}
          itemPermissionIds={this.props.defaultRequestItemPermissionIds}
          requireItemPermissionSelection
          showLink={showFirmRequestPermissionLink({
            allUsersDeceased,
            firmConfig,
            requestItemPermissionsInvite
          })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({api}, ownProps) => {
  const userConfig = currentUserConfig(api)

  const requestItemPermissionsInvite = findRawResourceByAttributeId({
    rawResource: api['request-item-permissions-invites'],
    attribute: 'everplan-id',
    id: ownProps.everplanSummary.get('id')
  })

  const firmUserConfig = findRawResourceByAttributeId({
    rawResource: api['firm-user-configs'],
    attribute: 'user-id',
    id: userConfig.get('id')
  })

  return ({
    firmConfig: findRawResourceByAttributeId({
      rawResource: api['firm-configs'],
      attribute: 'firm-id',
      id: ownProps.firmOwnership.get('ownable-id')
    }),
    firmOwnership: ownProps.firmOwnership,
    defaultRequestItemPermissionIds: defaultRequestItemPermissionIds({
      anyoneActive: ownProps.anyoneActive,
      firmOwnership: ownProps.firmOwnership,
      firmUserConfig
    }),
    firmUserConfig,
    requestItemPermissionsInvite,
    userConfig
  })
}

ItemPermissionsContainer.propTypes = {
  allUsersDeceased: PropTypes.bool,
  createResource: PropTypes.func,
  defaultRequestItemPermissionIds: PropTypes.array,
  everplanSummary: PropTypes.instanceOf(Map),
  firmConfig: PropTypes.instanceOf(Map),
  firmOwnership: PropTypes.instanceOf(Map),
  firmUserConfig: PropTypes.instanceOf(Map),
  items: PropTypes.instanceOf(List),
  requestItemPermissionsInvite: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default connect(mapStateToProps, {createResource, updateResource})(ItemPermissionsContainer)
