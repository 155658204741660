import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const ClientInviteInterstitialActions = new LegacyActionFactory([
  'DID_RESPOND_TO_CLIENT_INVITE',
  'GOT_CLIENT_INVITE'
])

ClientInviteInterstitialActions.fetchClientInvite = clientInviteId => {
  ClientInviteInterstitialActions.fireApi(
    'get',
    `clients/invites/${clientInviteId}`,
    null,
    {
      JSONHead: 'client_invite',
      successAction: ClientInviteInterstitialActions.Types.GOT_CLIENT_INVITE
    }
  )
}

ClientInviteInterstitialActions.respondToClientInvite = (data, clientInviteId) => {
  ClientInviteInterstitialActions.fireApi(
    'put',
    `clients/onboarding/${clientInviteId}`,
    data,
    {
      JSONHead: 'client_invite',
      successAction: ClientInviteInterstitialActions.Types.DID_RESPOND_TO_CLIENT_INVITE
    }
  )
}

export default ClientInviteInterstitialActions
