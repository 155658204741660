import React from 'react'

import {connect} from 'react-redux'
import {readEndpoint} from 'redux-json-api'
import PropTypes from 'prop-types'

import Flash from '../../../../shared_components/deprecated/Flash'
import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'
import storePrototype from '../../../../shared_components/StorePrototype'
import {addQueryToPath} from '../../../../lib/interstitialTools'
import Container from '../../../../lib/Container'
import routerUtils from '../../../../lib/routerUtils'
import SessionStore from '../../stores/SessionStore'
import sessionUtils from '../../lib/sessionUtils'
import SignInMessageActions from '../../actions/SignInMessageActions'

import './signIn.scss'

Container.registerStore('sign_in_messages', storePrototype(SignInMessageActions.Types.GOT_FLASH_MESSAGE))

export class SignInController extends React.Component {
  constructor(props) {
    super()

    const headerFlavor = this.isSignInPage(props.location) ?
      'sign-up' :
      'transactional'

    this.state = {flash: null, headerFlavor}
    this.store = Container.getStore('sign_in_messages')
    this.onUpdate = this.onUpdate.bind(this)
  }

  UNSAFE_componentWillMount() { if (sessionUtils.hasSession()) sessionUtils.wipeSession() }

  componentDidMount() {
    SessionStore.addChangeListener(this.onUpdate)
    this.store.addChangeListener(this.onUpdate)

    if (this.props.location.query.msg_id)
      SignInMessageActions.getFlashMessage(this.props.location.query.msg_id)
  }
  onUpdate() {
    const session = SessionStore.getState()

    if (session.preAuthenticated) {
      this.setState({headerFlavor: 'transactional'})

      if (session.phoneSentTo === 'primary')
        routerUtils.push(this.appendFirstInterstitialQuery('/auth/sign_in/tfa_primary'))
      else if (session.phoneSentTo === 'secondary')
        routerUtils.push(this.appendFirstInterstitialQuery('/auth/sign_in/tfa_secondary'))
    } else if (session.currentUser) {
      if (!session.signedInWithBackup)
        this.forwardSignedInUser()
    }

    this.setState({flash: this.store.getState().data.message})
  }

  appendFirstInterstitialQuery(path) {
    return addQueryToPath(
      path,
      this.props.location.query.first ? `first=${this.props.location.query.first}` : ''
    )
  }

  forwardSignedInUser() {
    const user = SessionStore.getState().currentUser

    this.props.readEndpoint(`users/${user.id}?include=partner`).then(response => {
      const {attributes} = response.body.data
      const needsRequiredAction = !(user['account_manager'] || user.admin)

      if (needsRequiredAction && !attributes['agreement-accepted']) {
        routerUtils.push('/terms-of-service-and-privacy-policy')
      } else if (needsRequiredAction && !attributes['profile-complete']) {
        // basically don't bother deputy only users until they are migrated (The deputy only part would be removed as part of post-release cleanup) - Atanda
        routerUtils.push('/profile-completion')
      } else if (user.has_interstitials) {
        routerUtils.push(this.appendFirstInterstitialQuery('/pending-requests'))
      } else if (this.props.location.query.nextRoute) {
        routerUtils.push(this.props.location.query.nextRoute)
      } else {
        let parameters = [user]
        const {included} = response.body

        if (included && included.length > 0)
          parameters = [...parameters, included[0].attributes]

        sessionUtils.sendUserToNextRoute(...parameters)
      }
    })
  }

  componentWillUnmount() {
    SessionStore.removeChangeListener(this.onUpdate)
    this.store.removeChangeListener(this.onUpdate)
  }

  isSignInPage(location) {
    return location && location.pathname === '/auth/sign_in'
  }

  render () {
    return (
      <div className='js-page-container'>
        <HeaderFlavor flavor={this.state.headerFlavor} />
        {!this.isSignInPage(this.props.location) && <Flash flash={this.state.flash} />}
        <div id='tfa-app-container'>
          {routerUtils.childrenWithProps(this.props.children, {flashMessage: this.state.flash})}
        </div>
      </div>
    )
  }
}

SignInController.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.object
  }),
  readEndpoint: PropTypes.func
}

export default connect(null, {readEndpoint})(SignInController)
