import PropTypes from 'prop-types'

import {addPossessive} from '../../../../lib/tools'
import UnorderedList from '../../../../shared_components/UnorderedList'

const howItWorksDetails = props => {
  const hasAccepted = props.hasAccepted
  const advisorFullName = props.advisorFullName
  const clientInvite = props.clientInvite
  const details = [
    `You won't be billed for as long as you remain with ${addPossessive(advisorFullName)} program.`,
    `You${hasAccepted ? ' can' : "'ll be able to"} opt out of ${addPossessive(advisorFullName)} program at any point.`
  ]

  // Changed this to unshift so that it can prepend the items to the array so as to follow the order that Product wants -KAY
  // Conditional bullet points when the user has an everplan in some state
  if (clientInvite.advisor_added_item_to_everplan)
    details.unshift(`Keep an eye out for new items in your Everplan which ${advisorFullName} has prepared on your behalf.`)

  if (clientInvite.merging_deputies)
    details.unshift(`Make sure to recheck your Deputy permissions${hasAccepted ? '.' : ' once this action is complete.'}`)

  if (clientInvite.merging_active_plan)
    details.unshift(`Your Individual Everplan — and any data within it — ${hasAccepted ? 'was' : 'will be'} merged with ${addPossessive(clientInvite.other_household_owner.full_name)} to form a joint Household Everplan.`)

  return details.slice()
}


const HowItWorks = props => (
  <div>
    <div className='subheading'>
      {props.hasAccepted ? `Your subscription to Everplans Premium is now sponsored by ${props.advisorFullName}.` : 'How It Works'}
    </div>
    <UnorderedList children={howItWorksDetails(props)} />
  </div>
)

HowItWorks.defaultProps = {
  advisorFullName: '',
  hasAccepted: false,
  clientInvite: {
    merging_active_plan: false,
    advisor_added_item_to_everplan: false,
    merging_deputies: false,
    other_household_owner: {
      first_name: '',
      full_name: ''
    }
  }
}

HowItWorks.propTypes = {
  advisorFullName: PropTypes.string.isRequired,
  hasAccepted: PropTypes.bool,
  clientInvite: PropTypes.shape({
    merging_active_plan: PropTypes.bool,
    advisor_added_item_to_everplan: PropTypes.bool,
    merging_deputies: PropTypes.bool,
    other_household_owner: PropTypes.shape({
      full_name: PropTypes.string,
      first_name: PropTypes.string
    })
  })
}

export default HowItWorks
