import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {createResource, readEndpoint} from 'redux-json-api'
import PropTypes from 'prop-types'
import {Map} from 'immutable'


import Loader from '../../../../shared_components/NewLoader'
import {ControlButton} from '../../../../shared_components/ButtonTypes'
import {currentUserConfig} from '../../../../lib/userTools'

import {
  findRawResourceById,
  findRawResourceByAttributeId
} from '../../../../lib/plan_data/userData'
import routerUtils from '../../../../lib/routerUtils'


export const OpenIdConnectLink = props => {
  const [loading, setLoading] = useState(false)

  const authorize = response => {
    const nonce = response.data.attributes.nonce
    const returnUrl = props.nextPath ? props.nextPath : props.location.pathname
    const redirectUrl = encodeURIComponent(`${WEBAPPS_HOST}/open-id-connect/link`)
    const url = `https://login.microsoftonline.com/${props.organizationSsoSetting.get(
      'tenant-id'
    )}/oauth2/v2.0/authorize?\
      client_id=${props.organizationSsoSetting.get('client-id')}\
      &redirect_uri=${redirectUrl}\
      &response_type=id_token\
      &response_mode=form_post\
      &scope=openid\
      &state=${encodeURIComponent(returnUrl)}\
      &nonce=${nonce}\
    `

    routerUtils.setLocation(url)
  }

  useEffect(() => {
    const firmId = props.userConfig.get('firm-id')
    if (!props.userConfig.isEmpty() && props.organizationSsoSetting.isEmpty()) {
      props.readEndpoint(
        `firms/${firmId}?include=organization.organization-sso-setting`
      )
    }
  }, [props.userConfig.isEmpty(), props.organizationSsoSetting.isEmpty()])

  const onClick = () => {
    setLoading(true)

    props
      .createResource({type: 'nonces', attributes: {}})
      .then(response => authorize(response))
      .catch(() => routerUtils.push('/auth/invalid_token/sso_invalid_token'))
  }

  return (
    <Loader loading={loading || props.organizationSsoSetting.isEmpty()}>
      <ControlButton
        buttonText='Connect Microsoft Account'
        onClick={onClick}
      />
    </Loader>
  )
}

OpenIdConnectLink.propTypes = {
  createResource: PropTypes.func,
  readEndpoint: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  nextPath: PropTypes.string,
  organizationSsoSetting: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => {
  const userConfig = currentUserConfig(api)

  const firm = findRawResourceById({
    rawResource: api.firms,
    id: userConfig.get('firm-id')
  })

  const organizationSsoSetting = findRawResourceByAttributeId({
    rawResource: api['organization-sso-settings'],
    attribute: 'organization-id',
    id: firm.get('organization-id')
  })

  return {
    userConfig,
    organizationSsoSetting
  }
}

export default connect(mapStateToProps, {createResource, readEndpoint})(
  OpenIdConnectLink
)
