export const pageNumberAndSize = tableState => tableState ? `page[number]=${tableState.page + 1}&page[size]=${tableState.pageSize}` : ''

export const filteredValueQuery = ({filtered}) => {
  if (filtered.length)
  // Needed to account for multiple filters -- ZD
    return filtered.reduce((collection, filter) => `${collection}&filter[${filter.id}]=${filter.value}`, '')
  else
    return ''
}

export const filterToQueryString = (filterObj, overrides = {}) => {
  const copy = Object.assign(overrides || {}, filterObj)
  let filterString = ''
  Object.entries(copy).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length)
      filterString = filterString.concat(`&filter[${key}]=${value.join(',')}`)
    else if (!Array.isArray(value) && value)
      filterString = filterString.concat(`&filter[${key}]=${value}`)
  })

  return filterString
}

export const sortValue = sortFields => {
  if (sortFields && sortFields.length && sortFields[0].id) {
    return `&sort=${sortFields[0].desc ? '-' : ''}${
      sortFields[0].id
    }`
  } else {
    return ''
  }
}
