import PropTypes from 'prop-types'
import {List} from 'immutable'

import {blockRemovalWarningText} from '../../../../../lib/removeClientsTools'

import './blockRemovalWarning.scss'

const BlockRemovalWarning = ({clientSeats, disabled}) => {
  if (disabled)
    return <p className='block-removal-warning'>{blockRemovalWarningText(clientSeats)}</p>
  else
    return null
}

BlockRemovalWarning.propTypes = {
  disabled: PropTypes.bool,
  clientSeats: PropTypes.instanceOf(List)
}

export default BlockRemovalWarning
