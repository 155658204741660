import {useState} from 'react'
import {connect} from 'react-redux'
import {deleteResource} from 'redux-json-api'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import * as ButtonTypes from '../../../../shared_components/ButtonTypes'
import Modal from '../../../../shared_components/Modal'
import {Text} from '../../../../dashboard/src/components/blocks/Texts'

import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import routerUtils from '../../../../lib/routerUtils'

export const RemoveOrganization = props => {
  const [processing, setProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const toggleModal = () => { setShowModal(prevShowModal => !prevShowModal) }

  const removeOrganization = () => {
    setProcessing(true)
    const resource = {type: 'organizations', id: props.organization.get('id')}

    props.deleteResource(resource).then(() => {
      toggleModal()
      setProcessing(false)

      routerUtils.push('pro/organization-admin/organizations')
      alwaysNotify.shortSuccess('Organization successfully deleted.')
    })
  }


  return (
    <div>
      <ButtonTypes.ControlButton onClick={toggleModal} buttonText='Delete Organization' />
      <Modal visible={showModal}>
        <Text>Are you sure you want to delete this organization?</Text>
        <div className='button-group'>
          <ButtonTypes.Cancel onClick={toggleModal} />
          <ButtonTypes.Continue loading={processing} onClick={removeOrganization} />
        </div>
      </Modal>
    </div>
  )
}

RemoveOrganization.propTypes = {
  deleteResource: PropTypes.func,
  organization: PropTypes.instanceOf(Map)
}

export default connect(null, {deleteResource})(RemoveOrganization)
