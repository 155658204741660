import {Component} from 'react'
import {Map, fromJS} from 'immutable'
import PropTypes from 'prop-types'

import EditFormController from './EditFormController'
import Fields from '../../../../../shared_components/forms/v2/fields/index'

import validators from '../../../../../shared_components/forms/v2/validators'

const TEXTS = {
  enabledText: 'Clients not assigned to a particular firm will be assigned to this firm.',
  disabledText: "There's no catch all firm for this organization."
}

export default class EditCatchAllFirm extends Component {
  constructor() {
    super()

    this.submit = this.submit.bind(this)
  }

  submit(data, callback) {
    this.props.updateSettings(
      {
        'catch-all-firm-id': data.getIn(['catch-all-firm-id', 'value'], '')
      },
      callback
    )
  }

  controllerProps() {
    return {
      ...TEXTS,
      ...this.props,
      headerText: 'Catch-All Firm',
      data: this.props.organization.get('catch-all-firm-id'),
      submit: this.submit
    }
  }

  render() {
    return (
      <EditFormController {...this.controllerProps()}>
        <Fields.Text
          data={
            fromJS({
              id: 'catch-all-firm-id',
              legend: 'Catch All Firm ID:',
              name: 'catch-all-firm-id',
              value: this.props.organization.getIn(['catch-all-firm-id'], '')
            })
          }
          validator={validators.required}
        />
      </EditFormController>
    )
  }
}


EditCatchAllFirm.propTypes = {
  organization: PropTypes.instanceOf(Map),
  processing: PropTypes.bool,
  updateSettings: PropTypes.func
}
