import {Route} from 'react-router'
import bindResources from '../../../shared_components/BindToStores'

import ClientInvite from '../components/client/ClientInviteController'
import ClientInvitationLandingPageController from '../components/client/client_invite_landing_page/ClientInvitationLandingPageController'
import DeputyConfirm from '../components/deputy_confirm/DeputyConfirm'
import DeputyInviteDeclined from '../components/deputy_confirm/DeputyInviteDeclined'

import storePrototype from '../../../shared_components/StorePrototype'
import Container from '../../../lib/Container'
import ConfirmActions from '../actions/ConfirmActions'
import ReferralActions from '../actions/ReferralActions'
import ClientInviteActions from '../actions/ClientInviteActions'
import ClientOnboardingController from '../components/ClientOnboardingController'

Container.registerAction('deputy', ConfirmActions)
Container.registerAction('referral', ReferralActions)

Container.registerStore('referral', storePrototype(ReferralActions.Types.GOT_REFERRAL)) // TrialOrPremium
Container.registerStore('confirm', storePrototype(ConfirmActions.Types.DID_CONFIRM_DEPUTY)) // DeputyConfirm
Container.registerStore('deputy', storePrototype(ConfirmActions.Types.GOT_DEPUTY)) // DeputyConfirm

// client onboarding
Container.registerAction('client_invite', ClientInviteActions)
Container.registerStore('client_invite', storePrototype(ClientInviteActions.Types.GOT_CLIENT_INVITE))
Container.registerStore('client_invite_accept', storePrototype(ClientInviteActions.Types.DID_SUBMIT_ACTIVATION_FORM))

export default (
  <Route>
    <Route path='client_onboarding' component={ClientOnboardingController}>
      <Route path='invitation/:clientInviteId' component={ClientInvitationLandingPageController} />
      <Route path='client_invite/:clientInviteId' component={bindResources(ClientInvite, {name: 'client_invite', type: 'item'}, ClientInvite.onBoundUpdate)} />
      <Route path='deputy_confirm/:deputyId' component={bindResources(DeputyConfirm, {name: 'deputy', type: 'item'}, DeputyConfirm.onBoundUpdate)} />
      <Route path='deputy-invite-declined' component={DeputyInviteDeclined} />
    </Route>
  </Route>
)
