import PropTypes from 'prop-types'

import './everplansSocialMediaLinkListItem.scss'

const EverplansSocialMediaLinkListItem = ({ariaLabel, logoSrc, url}) => (
  <li className='everplans-social-media-link-list-item'>
    <a
      aria-label={ariaLabel}
      href={url}
      rel='noreferrer'
      target='_blank'>
      <img
        alt='Social Media'
        className='social-media-logo'
        src={logoSrc}
      />
    </a>
  </li>
)

EverplansSocialMediaLinkListItem.propTypes = {
  ariaLabel: PropTypes.string,
  logoSrc: PropTypes.string,
  url: PropTypes.string
}

export default EverplansSocialMediaLinkListItem
