import classnames from 'classnames'
import PropTypes from 'prop-types'
import {useEffect} from 'react'

import AdvisorNav from './AdvisorNav'
import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import routerUtils from '../../../../lib/routerUtils'
import FirmActions from '../../actions/FirmActions'
import FirmHeader from './FirmHeader'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import storeWrapper from '../../../../shared_components/storeWrapper'
import {usePrevious} from '../../../../lib/hooks'

const FirmUser = props => {
  const prevParams = usePrevious(props.params)
  const prevSentFirmUserInvite = usePrevious(props.sentFirmUserInvite)
  const {isDifferent} = useNotificationContext()

  useEffect(() => {
    if (props.params !== prevParams)
      FirmActions.fetchFirmUser(props.params)
  }, [props.params])

  useEffect(() => {
    isDifferent(props.sentFirmUserInvite.data, prevSentFirmUserInvite.data).shortSuccess('Invitation sent.')
    isDifferent(props.sentFirmUserInvite.errors, prevSentFirmUserInvite.errors).shortError(
      "We're sorry, but something went wrong while inviting this user. Please try again or reach out to the current Dev on Call."
    )
  }, [props.sentFirmUserInvite])

  const isAccountManager = props.currentUser.account_manager
  const isAdvisor = props.firm_user.data.type === 'AdvisorSeat'

  return (
    <LegacyLoader loading={props.loadingFirmUser}>
      <FirmHeader className={classnames({'no-border': !isAdvisor})} headerText={props.firm_user.data.name} isAccountManager={isAccountManager} firmId={props.params.firmId} flavor='firmUser'>
        {
          isAdvisor &&
            (
              <AdvisorNav
                firmId={props.params.firmId}
                firmUserSeatId={props.params.firmUserSeatId}
                has_integrations={props.firm_user.data.has_integrations}
                isAccountManager={isAccountManager}
                firmConfig={props.firm_config.data}
                organization={props.organization}
              />
            )
        }
      </FirmHeader>
      <div className='firm-user'>
        {
          routerUtils.childrenWithProps(
            props.children,
            {
              firm: props.firm,
              organizations: props.organizations,
              organization: props.organization,
              user: props.firm_user,
              hasIntegrations: props.firm_user.data.has_integrations,
              firmConfig: props.firm_config.data
            }
          )
        }
      </div>
    </LegacyLoader>
  )
}

FirmUser.defaultProps = {
  currentUser: {
    account_manager: false
  },
  firm_user: {
    data: {},
    errors: [],
    message: null
  },
  loadingFirmUser: true
}

FirmUser.propTypes = {
  currentUser: PropTypes.shape({
    account_manager: PropTypes.bool
  }).isRequired,
  sentFirmUserInvite: PropTypes.object,
  firm_config: PropTypes.object,
  firm: PropTypes.object,
  organization: PropTypes.object,
  organizations: PropTypes.object,
  firm_user: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      external_id: PropTypes.string,
      has_integrations: PropTypes.bool
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  loadingFirmUser: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    firmId: PropTypes.string,
    firmUserSeatId: PropTypes.string
  }).isRequired
}


Container.registerAction('firm_user', FirmActions)
export default storeWrapper(
  bindResources(FirmUser, {name: 'firm_user', type: 'itemAllParams'}),
  [{actionTypes: [FirmActions.Types.DID_SEND_FIRM_USER_INVITE], name: 'sentFirmUserInvite'}]
)
