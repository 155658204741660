import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import {readEndpoint, updateResource} from 'redux-json-api'
import {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import Closer from '../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../shared_components/core/modals/Modals'
import InviteClient from './InviteClient'
import Loader from '../../../../../shared_components/NewLoader'
import {ModalHeaderBottomDivider} from '../../../../../shared_components/core/modals/ModalHeaders'

import Container from '../../../../../lib/Container'
import DisclosureActions from '../../../../../shared_actions/DisclosureActions'
import EmailTextsStore from '../../../stores/EmailTextsStore'
import InviteSettingsActions from '../../../actions/InviteSettingsActions'
import storePrototype from '../../../../../shared_components/StorePrototype'

import {currentUserConfig} from '../../../../../lib/userTools'
import {findRawResourceById, filterRawResourceByEverplanId} from '../../../../../lib/plan_data/userData'
import {goToHouseholdDetail} from '../../../../../lib/navigation/proNavigation'
import {notifyAfterSend, sendClientInvitation} from '../../../../../lib/clientInviteTools'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'

Container.registerStore('disclosureStore', storePrototype([DisclosureActions.Types.GOT_DISCLOSURE]))

const InviteClientModalContainer = props => {
  const inviteSettingStore = Container.getStore('inviteSettings')
  const disclosureStore = Container.getStore('disclosureStore')
  const householdStore = Container.getStore('household')
  const {alwaysNotify} = useNotificationContext()

  const [contactCard, setContactCard] = useState({})
  const [customText, setCustomText] = useState('')
  const [emailTexts, setEmailTexts] = useState([])
  const [disclosure, setDisclosure] = useState({})
  const [isHighCompliance, setIsHighCompliance] = useState(false)
  const [loadingInviteSettings, setLoadingInviteSettings] = useState(true)
  const [loadingDisclosure, setLoadingDisclosure] = useState(true)
  const [household, setHousehold] = useState(householdStore.getState().data)


  const onUpdate = () => {
    const data = inviteSettingStore.getState().data

    setContactCard(data.contact_card)
    setCustomText(data.default_invite_email_text)
    setEmailTexts(data.user_context_texts)
    setIsHighCompliance(data.compliance_level === 'high')
    setLoadingInviteSettings(false)
  }

  const onDisclosureUpdate = () => {
    setDisclosure(disclosureStore.getState().data)
    setLoadingDisclosure(false)
  }

  const onHouseholdUpdate = () => { setHousehold(householdStore.getState().data) }

  useEffect(() => {
    inviteSettingStore.addChangeListener(onUpdate)
    disclosureStore.addChangeListener(onDisclosureUpdate)
    householdStore.addChangeListener(onHouseholdUpdate)

    InviteSettingsActions.fetchInviteSettings(household.advisor.advisor_seat_id, props.clientSeat.get('id'))
    DisclosureActions.fetchDisclosure(props.clientSeat.get('firm-id'))

    return () => {
      inviteSettingStore.removeChangeListener(onUpdate)
      EmailTextsStore.removeChangeListener(onUpdate)
      disclosureStore.removeChangeListener(onDisclosureUpdate)
      householdStore.removeChangeListener(onHouseholdUpdate)
    }
  }, [])

  const legacyClientInfo = household.clients.find(client => String(client.client_seat_id) === props.clientSeat.get('id'))

  const closeModal = () => goToHouseholdDetail({clientSeatId: props.params.clientSeatId})

  const handleSubmit = data => {
    const activeClient = household.clients.find(client => client.status === 'Active')

    sendClientInvitation({
      ...props,
      invitePayload: {
        'invite-text': data.inviteText,
        'save-edits': data.saveEdits,
        'item-permissions': data.itemPermissions
      }
    }).then(() => {
      props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)
      notifyAfterSend({alwaysNotify, client: legacyClientInfo, existingClient: activeClient})
      closeModal()
    })
  }

  const needsClientApproval = () => (
    props.clientSeats.size === 2 &&
      !props.everplan.get('is-household')
  )

  const headerText = () => {
    if (needsClientApproval())
      return 'Add Personal Message'
    else
      return `${props.clientSeat.get('status') === 'Pending' ? 'Resend' : 'Send'} Invitation`
  }

  const closer = () => <Closer closer={closeModal} />

  return (
    <Modals.FullHeightModalLarge showModal={true} closerComponent={closer}>
      <Loader loading={loadingInviteSettings || loadingDisclosure || !legacyClientInfo}>
        <ModalHeaderBottomDivider heading={headerText()} />
        <InviteClient
          {...props}
          closeModal={closeModal}
          contactCard={contactCard}
          customText={customText}
          emailTexts={emailTexts}
          disclosure={disclosure}
          isHighCompliance={isHighCompliance}
          household={household}
          handleSubmit={handleSubmit}
          needsClientApproval={needsClientApproval()}
          recipient={legacyClientInfo}
        />
      </Loader>
    </Modals.FullHeightModalLarge>
  )
}

const mapStateToProps = (state, ownProps) => {
  const clientSeat = findRawResourceById({
    rawResource: state.api['client-seats'],
    id: ownProps.params.inviteSeatId
  })

  return ({
    clientSeat,
    clientSeats: filterRawResourceByEverplanId({
      id: clientSeat.get('everplan-id'),
      rawResource: state.api['client-seats']
    }),
    everplan: findRawResourceById({
      rawResource: state.api.everplans,
      id: clientSeat.get('everplan-id')
    }),
    userConfig: currentUserConfig(state.api),
    items: state.items
  })
}

InviteClientModalContainer.propTypes = {
  clientSeat: PropTypes.instanceOf(Map),
  clientSeats: PropTypes.instanceOf(List),
  everplan: PropTypes.instanceOf(Map),
  params: PropTypes.shape({
    clientSeatId: PropTypes.string.isRequired
  }),
  readEndpoint: PropTypes.func,
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default connect(mapStateToProps, {updateResource, readEndpoint})(InviteClientModalContainer)
