import {Component} from 'react'
import PropTypes from 'prop-types'
import {fromJS, Map} from 'immutable'

import * as ButtonTypes from '../../../../shared_components/ButtonTypes'
import Form from '../../../../shared_components/forms/v2/Form'
import Fields from '../../../../shared_components/forms/v2/fields/index'
import validators from '../../../../shared_components/forms/v2/validators'
import Modal from '../../../../shared_components/Modal'
import NewLoader from '../../../../shared_components/NewLoader'
import UploadWrapper from './UploadWrapper'

import './organizationForm.scss'

export default class OrganizationForm extends Component {
  constructor() {
    super()

    this.submit = this.submit.bind(this)
    this.setLogoDataUrl = this.setLogoDataUrl.bind(this)
    this.setContactCardImageUrl = this.setContactCardImageUrl.bind(this)
    this.state = {contactCardImageUrl: '', dataUrl: ''}
  }

  submit() {
    this.refs.form.submit()

    if (this.refs.form.isValid()) {
      this.props.submit(
        this.refs.form.formData().merge(
          Map({
            'organization-logo': this.state.dataUrl,
            'contact-card-logo': this.state.contactCardImageUrl
          })
        )
      )
    }
  }

  setLogoDataUrl(dataUrl) { this.setState({dataUrl}) }

  setContactCardImageUrl(dataUrl) {
    this.setState({contactCardImageUrl: dataUrl})
  }

  render() {
    return (
      <div className='organization-form forms-playground'>
        <Modal closer={this.props.toggleModal} visible={this.props.showModal}>
          <div className='modal-header'>{this.props.modalheaderText}</div>
          <NewLoader loading={this.props.loadingOrganizations}>
            <Form ref='form' className='core side-labels'>
              <Fields.Text
                data={
                  fromJS({
                    id: 'organization-name',
                    legend: 'Name',
                    name: 'organization-name',
                    value: this.props.organizationName
                  })
                }
                validator={validators.required}
              />
            </Form>
            <UploadWrapper
              imageUrl={this.state.dataUrl}
              labelText='Organization Logo'
              className='organization-logo'
              setImageUrl={this.setLogoDataUrl}
            />
            <UploadWrapper
              imageUrl={this.state.contactCardImageUrl}
              labelText='Contact Card Image'
              className='contact-card-image'
              setImageUrl={this.setContactCardImageUrl}
            />
            <div className='button-group'>
              <ButtonTypes.Cancel onClick={this.props.closeModal} />
              <ButtonTypes.Continue loading={this.props.processing} onClick={this.submit}>{this.props.actionText}</ButtonTypes.Continue>
            </div>
          </NewLoader>
        </Modal>
      </div>
    )
  }
}

OrganizationForm.propTypes = {
  actionText: PropTypes.string,
  closeModal: PropTypes.func,
  errors: PropTypes.array,
  loadingOrganizations: PropTypes.bool,
  modalheaderText: PropTypes.string,
  organizationName: PropTypes.string,
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  submit: PropTypes.func,
  toggleModal: PropTypes.func
}
