import {useCallback, useState} from 'react'

import classnames from 'classnames'

import submitEmailIcon from '../../../../assets/send.svg'
import './mailchimpSubscriptionForm.scss'

const url = `https://everplans.us7.list-manage.com/subscribe/post-json?u=${MAILCHIMP_USER_ID}&id=${MAILCHIMP_AUDIENCE_ID}&f_id=00ecc2e1f0`

const MailchimpSubscriptionForm = () => {
  const [emailAddress, setEmailAddress] = useState()
  const [message, setMessage] = useState()
  const [isSending, setIsSending] = useState(false)

  const isValidEmailAddress = () => {
    if (!emailAddress || !emailAddress.length)
      return false
    else if (emailAddress.indexOf('@') === -1)
      return false

    return true
  }

  const submitForm = async () => {
    const response = await $.ajax({
      url,
      contentType: 'application/json; charset=utf-8',
      data: {EMAIL: emailAddress},
      dataType: 'jsonp',
      cache: false,
      jsonp: 'c'
    })

    setIsSending(false)
    setMessage(response.msg)
  }

  const handleClick = useCallback(() => {
    if (isValidEmailAddress()) {
      setMessage('sending...')
      setIsSending(true)
      submitForm()
    } else {
      // eslint-disable-next-line no-console
      console.error('A valid email address must be provided.')
    }
  })

  const handleChange = useCallback(event => {
    setMessage('')
    setEmailAddress(event.target.value)
  }, [])

  return (
    <div className='mailchimp-subscription-form'>
      <div className='newsletter-sign-up-label'>JOIN OUR NEWSLETTER</div>
      {message && <div className={classnames('message', {'sending-color': isSending})} id='subscribe-result'>{message}</div>}
      <div>
        <div className='newsletter-sign-up-container'>
          <input
            autoComplete='email'
            className='newsletter-sign-up-input'
            onChange={handleChange}
            placeholder='Enter your email'
            title='Enter your email'
            type='email'
          />
          <div
            className='submit-email-button'
            onClick={handleClick}>
            <img
              alt='submit email address icon'
              className='submit-email-icon'
              src={submitEmailIcon}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MailchimpSubscriptionForm
