import {Map} from 'immutable'
import {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'

import {Cancel, Continue} from '../../../../../shared_components/ButtonTypes'
import {ControlLink} from '../../../../../shared_components/Links'
import Forms from '../../../../../shared_components/forms'
import Modal from '../../../../../shared_components/Modal'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import PickAdvisor from '../../clients/PickAdvisor'

import {usePrevious} from '../../../../../lib/hooks'
import ClientSeatActions from '../../../actions/ClientSeatActions'
import modalWrapper from '../../../../../shared_components/ModalWrapper'
import storeWrapper from '../../../../../shared_components/storeWrapper'

import './changeAdvisorModal.scss'

const ChangeAdvisorModal = (props, context) => {
  const formRef = useRef()
  const prevChangeAdvisor = usePrevious(props.changeAdvisor)
  const [processing, setProcessing] = useState(false)
  const {isDifferent} = useNotificationContext()

  const changeAdvisor = () => {
    setProcessing(true)
    const form = formRef.current
    if (form.validate().length === 0) {
      ClientSeatActions.changeAdvisor(form.formData(), props.clientSeatId)
      context.modalClosed()
    }

    setProcessing(false)
  }

  useEffect(() => {
    isDifferent(props.changeAdvisor.data, prevChangeAdvisor.data).shortSuccess(`${props.firmConfig.get('professional-designation')} updated.`)
    isDifferent(props.changeAdvisor.errors, prevChangeAdvisor.errors).shortError("We're sorry, but something went wrong while trying to change advisors. Please try again.")
  }, [props.changeAdvisor])


  // Do not show the option to change a client's advisor if the firm has only one active advisor:
  if (props.activeAdvisorCount === 1)
    return null

  const professionalDesignation = props.firmConfig.get('professional-designation')
  return (
    <span className='change-advisor'>
      <ControlLink href='#' onClick={context.showModal}>Change</ControlLink>
      <Modal visible={props.showModal}>
        <h3>Change {professionalDesignation} for {props.name}</h3>
        <ActiveClientWarning
          anyOwnersActive={props.anyOwnersActive}
          professionalDesignation={professionalDesignation}
        />
        <div className='form-wrapper'>
          <Forms.Form onSubmit={changeAdvisor} ref={formRef}>
            <PickAdvisor
              autoFocus
              className='select-new-advisor'
              label={`Select a new ${professionalDesignation.toLowerCase()}:`}
              name='advisor_seat_id'
              tabIndex='1'
            />
          </Forms.Form>
          <div className='button-group'>
            <Cancel onClick={context.modalClosed} tabIndex='3' />
            <Continue loading={processing} onClick={changeAdvisor} tabIndex='2'>Save</Continue>
          </div>
        </div>
      </Modal>
    </span>
  )
}

ChangeAdvisorModal.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

ChangeAdvisorModal.defaultProps = {
  activeAdvisorCount: 1,
  anyOwnersActive: false,
  changeAdvisor: {data: {}},
  name: '',
  showModal: false
}

ChangeAdvisorModal.propTypes = {
  changeAdvisor: PropTypes.object,
  activeAdvisorCount: PropTypes.number.isRequired,
  anyOwnersActive: PropTypes.bool.isRequired,
  clientSeatId: PropTypes.string.isRequired,
  firmConfig: PropTypes.instanceOf(Map),
  name: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired
}

export default storeWrapper(
  modalWrapper(ChangeAdvisorModal),
  [{actionTypes: [ClientSeatActions.Types.DID_CHANGE_ADVISOR], name: 'changeAdvisor'}]
)


const ActiveClientWarning = props => {
  if (props.anyOwnersActive) {
    return (
      <div className='active-client-warning'>
        Note: When you change an active client's {props.professionalDesignation.toLowerCase()}, they are immediately notified by Everplans. Also, be aware
        that the new {props.professionalDesignation.toLowerCase()} will not automatically be given Deputy access to the client's Everplan.
      </div>
    )
  }

  return <span />
}

ActiveClientWarning.propTypes = {
  anyOwnersActive: PropTypes.bool.isRequired,
  professionalDesignation: PropTypes.object
}

