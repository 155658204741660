import assign from 'object-assign'
import ActionPrototype from '../../../shared_components/ActionPrototype'

import KeyMirror from 'keymirror'

const ClientActions = assign({}, ActionPrototype, {
  Types: KeyMirror({
    DID_ADD_TO_EXISTING_PLAN: null,
    DID_UPDATE_CLIENT: null,
    GOT_CLIENTS: null,
    GOT_HOUSEHOLD: null,
    GOT_EMAIL_TEXTS: null
  }),
  addToExistingPlan(data) {
    this.fireApi(
      'post',
      `household/add_to_existing_plan/${data.clientSeatId}`,
      data,
      {
        successAction: this.Types.DID_ADD_TO_EXISTING_PLAN,
        JSONHead: 'household'
      }
    )
  },
  fetchClients() {
    this.fireApi(
      'get',
      'clients',
      null,
      {
        successAction: this.Types.GOT_CLIENTS,
        JSONHead: 'clients'
      }
    )
  },
  fetchHousehold(clientSeatId) {
    this.fireApi(
      'get',
      `clients/${clientSeatId}`,
      null,
      {
        successAction: this.Types.GOT_HOUSEHOLD,
        JSONHead: 'household'
      }
    )
  },
  updateClient(data) {
    this.fireApi(
      'put',
      `clients/${data.client_seat_id}`,
      data,
      {
        JSONHead: 'household',
        successAction: this.Types.DID_UPDATE_CLIENT
      }
    )
  },
  getEmailTexts() {
    this.fireApi(
      'get',
      'clients/invites/choose_email_text', null,
      {
        successAction: this.Types.GOT_EMAIL_TEXTS
      }
    )
  }
})

export default ClientActions
