import PropTypes from 'prop-types'

import './contactElementView.scss'

const ContactElementView = ({name, phoneNumber}) => (
  <div className='contact-element-view'>
    {name && <div className='name'>{name}</div>}
    {phoneNumber && <div className='phone-number'>{phoneNumber}</div>}
  </div>
)

ContactElementView.propTypes = {
  name: PropTypes.string,
  phoneNumber: PropTypes.string
}

export default ContactElementView
