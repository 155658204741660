import PropTypes from 'prop-types'

import {Link} from '../../../../../../shared_components/Links'

const LinkListItem = ({className, linkText, target, url, ...otherProps}) => (
  <li className='link-list-item' {...otherProps}>
    <Link
      className={className}
      href={url}
      target={target}>
      {linkText}
    </Link>
  </li>
)

LinkListItem.propTypes = {
  className: PropTypes.string,
  linkText: PropTypes.string,
  role: PropTypes.string,
  target: PropTypes.string,
  url: PropTypes.string
}

export default LinkListItem
