export const isActiveAdvisorSeat = firmSeat =>
  firmSeat.get('status') === 'active' &&
  firmSeat.get('seat-type') === 'AdvisorSeat'


export const selectedFirmSeatId = ({name, firmSeats}) =>
  firmSeats
    .find(
      firmSeat => firmSeat.get('name') === name && isActiveAdvisorSeat(firmSeat)
    )
    .get('id')
