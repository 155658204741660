import PropTypes from 'prop-types'
import React from 'react'

import bindResources from '../../../../shared_components/BindToStores'
import ClientInviteInterstitialActions from '../../actions/ClientInviteInterstitialActions'
import Container from '../../../../lib/Container'
import Loader from '../../../../shared_components/NewLoader'
import routerUtils from '../../../../lib/routerUtils'

import './clientInviteInterstitialController.scss'


export class ClientInviteInterstitialController extends React.Component {
  render() {
    return (
      <div className='client-invite-interstitial'>
        <Loader loading={this.props.loadingClientInvite}>
          {
            routerUtils.childrenWithProps(
              this.props.children,
              {
                clientInvite: this.props.client_invite.data,
                returnToController: this.props.returnToController
              }
            )
          }
        </Loader>
      </div>
    )
  }
}

ClientInviteInterstitialController.defaultProps = {
  children: '',
  client_invite: {
    data: {}
  },
  loadingClientInvite: true
}

ClientInviteInterstitialController.propTypes = {
  loadingClientInvite: PropTypes.bool.isRequired
}

Container.registerAction('client_invite', ClientInviteInterstitialActions)
export default bindResources(ClientInviteInterstitialController, {name: 'client_invite', type: 'item'})
