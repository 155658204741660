import PropTypes from 'prop-types'

import Dev from '../../../../../dev_only'
import EditClientModalController from '../../clients/EditClientModalController'


const LegacyName = props => {
  const editClientAvailable = props.client.status !== 'Active' && props.client.status !== 'Denied' && !props.client.deceased_at
  return (
    <div className='user-details'>
      <p>
        <strong>{props.client.name}</strong>
        <Dev.Block> (User ID: {props.client.user_id}, ClientSeat ID: {props.client.client_seat_id})</Dev.Block>
      </p>
      <p className='user-email'>{props.client.email}</p>
      {
        editClientAvailable &&
        (
          <EditClientModalController
            {...props.client}
            everplanId={props.everplanId}
            clientSeatId={props.client.client_seat_id}
          />
        )
      }
    </div>
  )
}

LegacyName.defaultProps = {
  client: {
    email: '',
    name: ''
  }
}

LegacyName.propTypes = {
  client: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    client_seat_id: PropTypes.number,
    status: PropTypes.oneOf(['Active', 'Declined', 'Denied', 'Draft', 'Pending', 'Requesting Approval'])
  })
}

export default LegacyName
