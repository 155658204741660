import {Fragment} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import Fields from '../../../../../shared_components/forms/v2/fields'

import {addPossessive} from '../../../../../lib/tools'
import {compareAsStrings} from '../../../../../lib/plan_data/userData'

const PersonalMessage = props => {
  const advisorNameOrPossessive = compareAsStrings(props.advisor.get('user-id'), props.userConfig.get('id')) ? 'my' : addPossessive(props.advisor.get('name'))

  return (
    <Fragment>
      <Fields.TextArea
        data={
          Map({
            id: 'personal-message',
            legend: <strong>Personal message:</strong>,
            name: 'personal-message',
            value: props.firmUserConfig.get('default-invite-email-text')
          })
        }
      />
      <Fields.SimpleCheckbox
        data={Map({
          id: 'save-edits',
          legend: `Save any edits I make to ${advisorNameOrPossessive} default template.`,
          name: 'save-edits'
        })}
      />
    </Fragment>
  )
}

PersonalMessage.propTypes = {
  advisor: PropTypes.instanceOf(Map),
  firmUserConfig: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

export default PersonalMessage
