import PropTypes from 'prop-types'
import React from 'react'

import {If, Else} from '../../../../shared_components/deprecated/IfFlag'

import './legacyToAndFrom.scss'

export default class LegacyToAndFrom extends React.Component {
  render() {
    return (
      <div className='invite-to-and-from'>
        <div className='client-email'>
          <span className='label'>To: </span>
          { this.props.recipients.length > 1 ?
            <MultipleRecipients people={this.props.recipients} /> :
            (
              <span>
                <span>
                  {this.props.recipients[0].first_name} {this.props.recipients[0].last_name}
                </span>
                {` (${this.props.recipients[0].email})`}
              </span>
            )
          }
        </div>
        <div className='client-email'>
          <span className='label'>From: </span>
          <span>
            <If flag={this.props.isHighCompliance}>
              <span>Everplans</span>
            </If>
            <Else flag={this.props.isHighCompliance}>
              <span>{this.props.contactCard.name} via Everplans</span>
            </Else>
          </span>
        </div>
      </div>
    )
  }
}

LegacyToAndFrom.propTypes = {
  inviteSettings: PropTypes.object.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  })).isRequired
}

LegacyToAndFrom.defaultProps = {
  inviteSettings: {},
  recipients: [{}]
}

class MultipleRecipients extends React.Component {
  constructor(props) {
    super(props)

    this.state = {showStatus: false}
    this.toggleShow = this.toggleShow.bind(this)
  }
  toggleShow() { this.setState({showStatus: !this.state.showStatus}) }

  render() {
    return (
      <span className='multiple-recipient-names'>
        <span>{this.props.people.length} Recipients</span>
        <a onClick={this.toggleShow} className='show-recipient-names'>
          {(this.state.showStatus ? 'hide' : 'show')}
        </a>
        <If flag={this.state.showStatus} >
          <div className='multiple-recipients-list'>
            <ul >
              {this.props.people.map((person, index) => (
                <li key={index}>
                  <span className='name-list'>{`${person.first_name} ${person.last_name}`}</span>
                  <span className='email-label'>{person.email.toLowerCase()}</span>
                </li>
              ))}
            </ul>
          </div>
        </If>
      </span>
    )
  }
}
