import PropTypes from 'prop-types'

import organizedImage1 from '../../../assets/organized-img-1.svg'
import organizedImage2 from '../../../assets/organized-img-2.svg'
import organizedImage3 from '../../../assets/organized-img-3.svg'
import OrganizesYourLifeItem from './OrganizesYourLifeItem'
import './organizesYourLifeInfo.scss'

const OrganizesYourLifeInfo = ({handleActivateButtonClick}) => {
  const contentArray = [
    {
      image: organizedImage1,
      textOne: '84%',
      textTwo: 'are less productive when they feel disorganzied'
    },
    {
      image: organizedImage2,
      textOne: '86%',
      textTwo: 'are less stressed when they are organized'
    },
    {
      image: organizedImage3,
      textOne: '65%',
      textTwo: 'feel anxious when critical info and docs are all over the place'
    }
  ]

  return (
    <div className='organizes-your-life-info'>
      <div className='title'>Your best life. Organized.</div>
      <ul className='container'>
        {contentArray.map((content, index) => (
          <OrganizesYourLifeItem
            key={index}
            content={content}
          />
        ))}
      </ul>
      <button onClick={handleActivateButtonClick}>Activate your Everplan</button>
    </div>
  )
}
OrganizesYourLifeInfo.propTypes = {
  handleActivateButtonClick: PropTypes.func
}

export default OrganizesYourLifeInfo

