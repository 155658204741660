import PropTypes from 'prop-types'
import React from 'react'

import {Cancel, Continue, ControlButton} from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import Modal from '../../../../shared_components/Modal'
import ShadowHeader from '../shared_firm/ShadowHeader'

import Container from '../../../../lib/Container'
import FirmActions from '../../actions/FirmActions'

import './addLicense.scss'

export default class AddLicense extends React.Component {
  constructor(props) {
    super(props)

    this.newLicenseStore = Container.getStore('newLicense')

    this.state = {
      newLicenseState: {data: {}, errors: [], message: null},
      processing: false,
      showModal: false,
      unlimited: false
    }

    this.addLicense = this.addLicense.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.toggleUnlimitedStatus = this.toggleUnlimitedStatus.bind(this)
  }
  componentDidMount() {
    this.newLicenseStore.addChangeListener(this.onUpdate)
  }
  onUpdate() {
    var newState = {
      newLicenseState: this.newLicenseStore.getState(),
      processing: false
    }

    if (newState.newLicenseState.errors.length === 0)
      newState.showModal = false

    this.setState(newState)
  }
  componentWillUnmount() {
    this.newLicenseStore.removeChangeListener(this.onUpdate)
  }
  toggleModal(event) {
    if (event)
      event.preventDefault()

    this.setState({
      showModal: !this.state.showModal
    })
  }

  addLicense() {
    this.setState({
      newLicenseState: {data: {}, errors: [], message: null},
      processing: true
    })

    if (this.refs.form.validate().length === 0) {
      FirmActions.addLicense({
        data: {license: this.refs.form.formData()},
        firmId: this.props.firmId
      })
    } else {
      this.setState({processing: false})
    }
  }
  toggleUnlimitedStatus() {
    this.setState({unlimited: !this.state.unlimited})
  }

  render() {
    const activeLicense = this.props.activeLicense
    const startDate = new Date()
    const month = startDate.getMonth() + 1
    const day = startDate.getDate()
    const year = startDate.getFullYear()

    return (
      <div className='add-license'>
        <ShadowHeader headerText='Current License'>
          <ControlButton
            buttonText='Add license'
            faClass='fa-plus-circle'
            onClick={this.toggleModal}
          />
        </ShadowHeader>
        <Modal closer={this.toggleModal} visible={this.state.showModal}>
          <div className='modal-header'>Add License</div>
          <Forms.Form errors={[{name: 'firm_name', errors: this.state.newLicenseState.errors}]} onSubmit={this.addLicense} ref='form'>
            <Forms.DateInput autoFocus label='Start Date:' name='start_date' placeholder='MM/DD/YYYY' tabIndex={1} defaultValue={{month, day, year}} />
            <Forms.DateInput label='End Date:' name='end_date' placeholder='MM/DD/YYYY' tabIndex={2} defaultValue={{month, day, year: year + 1}} />
            <Forms.Input className='wide' label='Cost:' name='amount' tabIndex={3} validator={Forms.Validators.positiveInteger} defaultValue={activeLicense.amount} />
            <Forms.Input className='wide' label='Payment Method:' name='payment_method' tabIndex={4} validator={Forms.Validators.required} defaultValue={activeLicense.payment_method} />
            <Forms.Wrapper className='row-wrapper' label='Client Accounts: '>
              <Forms.Input className='narrow' label='Client Accounts:' name='client_seats' tabIndex={5} validator={Forms.Validators.positiveInteger} wrap={false} disabled={this.state.unlimited} defaultValue={activeLicense.client_seats ? activeLicense.client_seats : null} />
              <label className='unlimited-wrapper'><Forms.Checkbox label= '' name='unlimited' className='unlimited' wrap={false} onChange={this.toggleUnlimitedStatus} /> Unlimited</label>
            </Forms.Wrapper>
            <Forms.Input className='narrow' label='Advisor Seats:' name='advisor_seats' tabIndex={6} validator={Forms.Validators.positiveInteger} defaultValue={activeLicense.advisor_seats} />
            <Forms.Input className='narrow' label='Assistant Seats:' name='assistant_seats' tabIndex={7} validator={Forms.Validators.positiveInteger} defaultValue={activeLicense.assistant_seats} />
            <Forms.TextArea className='textarea' label='Notes:' name='notes' tabIndex={8} defaultValue={activeLicense.notes} />
          </Forms.Form>
          <div className='button-group'>
            <Cancel onClick={this.toggleModal} tabIndex={0} />
            <Continue onClick={this.addLicense} processing={this.state.processing} tabIndex={9}>Add</Continue>
          </div>
        </Modal>
      </div>
    )
  }
}

AddLicense.propTypes = {
  firmId: PropTypes.string.isRequired,
  activeLicense: PropTypes.shape({
    unlimited: PropTypes.bool
  })
}

AddLicense.defaultProps = {
  activeLicense: {}
}
