import {fromJS, Map} from 'immutable'
import PropTypes from 'prop-types'
import {Fragment} from 'react'

import Fields from '../../../../../shared_components/forms/v2/fields'
import validators from '../../../../../shared_components/forms/v2/validators'


const JiclFormField = ({formField}) => {
  const Field = Fields[formField.get('field')]
  const validator = formField.get('validator')

  const fieldProps = {
    data: fromJS({
      legend: formField.get('legend'),
      name: formField.get('name'),
      id: formField.get('name')
    }),
    ...(validator && {validator: validators[validator]})
  }

  return (
    <Fragment>
      <Field {...fieldProps} />
      {formField.get('shortDescription') && (
        <p className='field-description'>{formField.get('shortDescription')}</p>
      )}
    </Fragment>
  )
}


JiclFormField.propTypes = {
  formField: PropTypes.instanceOf(Map)
}

export default JiclFormField
