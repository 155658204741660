const firmHeaderProps = props => ({
  headerText: props.firm.data.name,
  internal: props.firm.data.internal,
  firmId: props.params.firmId,
  isAccountManager: props.currentUser.account_manager,
  organizationId: props.firm.data.organization_id,
  organizations: props.organizations,
  loadingOrganizations: props.loadingOrganizations
})

export default firmHeaderProps
