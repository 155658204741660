import classnames from 'classnames'
import PropTypes from 'prop-types'
import {Component} from 'react'
import moment from 'moment'

import LicenseSeatData from './LicenseSeatData'
import ShadowWrapper from '../shared_firm/ShadowWrapper'


export default class FirmLicense extends Component {
  render() {
    const dateFormat = 'MM/DD/YYYY'
    const isActive = (this.props.license.status === 'active')

    return (
      <ShadowWrapper className={classnames('firm-license', {not_active: !isActive})}>
        <table className='no-border license-summary'>
          <tbody>
            {
              isActive &&
              (
                <tr>
                  <td>Status:</td>
                  <td>{this.props.license.status}</td>
                </tr>
              )
            }
            <tr>
              <td>Cost:</td>
              <td>{this.props.license.amount}</td>
            </tr>
            <tr>
              <td>Date Range:</td>
              <td>
                {moment(this.props.license.start_date).format(dateFormat)} - {moment(this.props.license.end_date).format(dateFormat)}
              </td>
            </tr>
            <tr>
              <td>Payment Method:</td>
              <td>{this.props.license.payment_method}</td>
            </tr>
            <tr>
              <td>Notes:</td>
              <td>{this.props.license.notes}</td>
            </tr>
          </tbody>
        </table>
        <LicenseSeatData data={this.props.license} isActive={isActive} />
      </ShadowWrapper>
    )
  }
}

FirmLicense.propTypes = {
  license: PropTypes.shape({
    amount: PropTypes.string,
    end_date: PropTypes.string,
    notes: PropTypes.string,
    payment_method: PropTypes.string,
    start_date: PropTypes.string,
    status: PropTypes.string
  })
}
