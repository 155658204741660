import classnames from 'classnames'
import {fromJS} from 'immutable'
import React from 'react'

import * as Buttons from '../../dashboard/src/components/blocks/Buttons'
import ButtonGroup from '../../shared_components/core/buttons/ButtonGroup'
import Error from './Error'
import Fields from '../../shared_components/forms/v2/fields/'
import Form from '../../shared_components/forms/v2/Form'

import {onBlur, onFocus} from './lib/tools'
import toggleErrorsAndSideLabelsWrapper from './lib/toggleErrorsAndSideLabelsWrapper'

import './newContactForm.scss'


export class NewContactForm extends React.Component {
  constructor() {
    super()

    this.state = {
      DOB: '',
      keyCode: null,
      phoneNumber: ''
    }

    this.onChangePhone = this.onChangePhone.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.submit = this.submit.bind(this)
  }

  onKeyPress(event) { this.setState({keyCode: event.keyCode}) }

  onChangeDate(event) {
    const keyCode = this.state.keyCode
    let setDOB = event.target.value

    if (keyCode > 31 && (keyCode < 48 || keyCode > 57) && (keyCode < 36 || keyCode > 40)) { this.setState({DOB: this.state.DOB}) } else {
      if ((setDOB.length === 2 || setDOB.length === 5) && keyCode !== 8)
        setDOB += '/'

      this.setState({DOB: setDOB})
    }
  }

  onChangePhone(event) {
    const keyCode = this.state.keyCode
    let setPhoneNumber = event.target.value
    const currentPhoneNumber = this.state.phoneNumber

    if (keyCode > 31 && (keyCode < 48 || keyCode > 57) && (keyCode < 36 || keyCode > 40)) { this.setState({phoneNumber: currentPhoneNumber}) } else {
      if ((setPhoneNumber.length === 3 || setPhoneNumber.length === 7) && keyCode !== 8)
        setPhoneNumber += '-'

      this.setState({phoneNumber: setPhoneNumber})
    }
  }

  submit() {
    this.setState({processing: true})
    this.refs.form.onSubmit()
    setTimeout(() => this.setState({processing: false}), 3000) // Pretend we're sending off to the server for errors
  }

  render() {
    return (
      <section>
        <h2>New Contact</h2>
        <Form className={classnames('core new-contact-form', {'side-labels': this.props.useSideLabels})} ref='form'>
          <fieldset>
            <div className='fieldset-flexbox controls-group'>
              <legend>Name</legend>
              <div className='controls-flexbox flex-container name-form-group with-middle-initial'>
                <label className={classnames('first-name flex-child placeholder', {error: this.props.showErrors})} data-placeholder='First'>
                  <span className='label-text visually-hidden'>First name</span>
                  <input name='first-name' type='text' onFocus={onFocus} onBlur={onBlur} />
                </label>
                <label className={classnames('flex-child middle-initial placeholder', {error: this.props.showErrors})} data-placeholder='MI'>
                  <span className='label-text visually-hidden'>Middle Initial</span>
                  <input name='middle-initial' type='text' onFocus={onFocus} onBlur={onBlur} />
                </label>
                <label className={classnames('flex-child last-name placeholder', {error: this.props.showErrors})} data-placeholder='Last'>
                  <span className='label-text visually-hidden'>Last name</span>
                  <input name='last-name' type='text' onFocus={onFocus} onBlur={onBlur} />
                </label>
                <Error showErrors={this.props.showErrors} />
              </div>
            </div>
          </fieldset>
          <Fields.Email
            data={
              fromJS({
                id: 'input-email',
                legend: 'Email',
                name: 'input-email'
              })
            }
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <Fields.Tel
            data={
              fromJS({
                id: 'input-telephone',
                legend: 'Phone number',
                name: 'input-telephone',
                placeholder: 'Enter phone number'
              })
            }
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <Fields.Address
            data={
              fromJS({
                id: 'address-group',
                legend: 'Address',
                name: 'address-group'
              })
            }
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <Fields.Date
            data={
              fromJS({
                id: 'date-of-birth',
                legend: 'Date of birth',
                name: 'date-of-birth',
                placeholder: 'MM/DD/YYYY'
              })
            }
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <Fields.Text
            data={
              fromJS({
                id: 'company-name',
                legend: 'Company name',
                name: 'company-name'
              })
            }
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <Fields.Text
            data={
              fromJS({
                id: 'website',
                legend: 'Website',
                name: 'website'
              })
            }
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <Fields.RadioList
            data={
              fromJS({
                id: 'gender',
                items: [
                  {label: 'Male', value: 'male'},
                  {label: 'Female', value: 'female'}
                ],
                legend: 'Gender',
                name: 'gender'
              })
            }
          />
          <Fields.TextArea
            data={
              fromJS({
                id: 'textarea-notes',
                legend: 'Notes',
                name: 'textarea-notes'
              })
            }
          />
          <ButtonGroup>
            <Buttons.Cancel />
            <Buttons.Button onClick={this.submit} processing={this.state.processing}>Submit</Buttons.Button>
          </ButtonGroup>
        </Form>
      </section>
    )
  }
}

export default toggleErrorsAndSideLabelsWrapper(NewContactForm)
