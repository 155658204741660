import PropTypes from 'prop-types'
import React from 'react'

import AddHouseholdMemberInterstitialActions from '../../actions/AddHouseholdMemberInterstitialActions'
import bindResource from '../../../../shared_components/BindToStores'
import bindUpdate from '../../../../shared_components/BoundUpdate'
import {capitalize} from '../../../../lib/tools'
import Container from '../../../../lib/Container'
import Loader from '../../../../shared_components/NewLoader'
import routerUtils from '../../../../lib/routerUtils'
import storePrototype from '../../../../shared_components/StorePrototype'

Container.registerAction('add_household_member_request', AddHouseholdMemberInterstitialActions)
Container.registerStore(
  'addHouseholdMemberInterstitial',
  storePrototype(AddHouseholdMemberInterstitialActions.Types.DID_RESPOND_TO_ADD_REQUEST)
)


export class AddHouseholdMemberInterstitialController extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      processingAccept: false,
      processingDecline: false
    }

    this.updating = this.updating.bind(this)
  }
  UNSAFE_componentWillReceiveProps(nextProps) { // Bail to top-level controller if invite data comes back with an error
    const errors = nextProps.add_household_member_request.errors
    if (errors && errors.length)
      this.props.returnToController()
  }
  updating(response) {
    this.setState({[`processing${capitalize(response)}`]: true})
    this.props.updating(() => {
      if (response === 'accept')
        routerUtils.push(`pending-requests/households/${this.props.params.addHouseholdMemberInviteId}/approved`)
      else
        this.props.returnToController()
    })
  }

  render() {
    const request = this.props.add_household_member_request
    return (
      <Loader loading={this.props.loadingAddHouseholdMemberRequest || !!request.errors.length}>
        {
          routerUtils.childrenWithProps(
            this.props.children,
            {
              addHouseholdMemberInviteId: this.props.params.addHouseholdMemberInviteId,
              advisorFullName: request.data.advisor_full_name,
              finished: this.props.finished,
              pendingCoAdministrator: request.data.pending_co_administrator,
              processingAccept: this.state.processingAccept,
              processingDecline: this.state.processingDecline,
              returnToController: this.props.returnToController,
              updating: this.updating
            }
          )
        }
      </Loader>
    )
  }
}

AddHouseholdMemberInterstitialController.defaultProps = {
  add_household_member_request: {
    data: {
      advisor_full_name: '',
      pending_co_administrator: {
        email: '',
        first_name: '',
        full_name: ''
      }
    }
  }
}

AddHouseholdMemberInterstitialController.propTypes = {
  add_household_member_request: PropTypes.shape({
    data: PropTypes.shape({
      advisor_full_name: PropTypes.string,
      pending_co_administrator: PropTypes.shape({
        email: PropTypes.string,
        first_name: PropTypes.string,
        full_name: PropTypes.string
      })
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }),
  finished: PropTypes.func.isRequired,
  loadingAddHouseholdMemberRequest: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    addHouseholdMemberInviteId: PropTypes.string.isRequired
  }),
  returnToController: PropTypes.func,
  updating: PropTypes.func.isRequired
}

export default bindResource(
  bindUpdate(AddHouseholdMemberInterstitialController, 'addHouseholdMemberInterstitial', null),
  {id: 'addHouseholdMemberInviteId', name: 'add_household_member_request', type: 'item'}
)
