import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'
import ClientActions from './ClientActions'

const ClientSeatActions = new LegacyActionFactory([
  'DID_CHANGE_ADVISOR'
])

ClientSeatActions.changeAdvisor = (data, clientSeatId) => {
  ClientSeatActions.fireApi(
    'put',
    `/clients/${clientSeatId}/change_advisor/${data.advisor_seat_id}`,
    null,
    {
      JSONHead: 'household',
      successAction: ClientSeatActions.Types.DID_CHANGE_ADVISOR
    }
  )
}

export default ClientSeatActions
