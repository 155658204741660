import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const AddHouseholdMemberInterstitialActions = new LegacyActionFactory([
  'DID_RESPOND_TO_ADD_REQUEST',
  'GOT_ADD_HOUSEHOLD_MEMBER_REQUEST'
])

AddHouseholdMemberInterstitialActions.fetchAddHouseholdMemberRequest = addHouseholdMemberInviteId => {
  AddHouseholdMemberInterstitialActions.fireApi(
    'get',
    `add_household_member_request/${addHouseholdMemberInviteId}`,
    null,
    {
      JSONHead: 'add_household_member_request',
      successAction: AddHouseholdMemberInterstitialActions.Types.GOT_ADD_HOUSEHOLD_MEMBER_REQUEST
    }
  )
}

AddHouseholdMemberInterstitialActions.respondToAddRequest = (data, addHouseholdMemberInviteId) => {
  AddHouseholdMemberInterstitialActions.fireApi(
    'post',
    `household/accept_or_decline/${addHouseholdMemberInviteId}`,
    data,
    {
      JSONHead: 'add_household_member_request',
      successAction: AddHouseholdMemberInterstitialActions.Types.DID_RESPOND_TO_ADD_REQUEST
    }
  )
}

export default AddHouseholdMemberInterstitialActions
