import PropTypes from 'prop-types'
import {Map} from 'immutable'

import EditLogoContainer from './EditLogoContainer'

const EditContactCardLogo = props => (
  <EditLogoContainer
    field='contact-card-logo'
    altText='contact-card-logo'
    dataUrl='contactCardImageUrl'
    headerText='Contact Card Default Image'
    setLogoUrl='setContactCardImageUrl'
    organization={props.organization}
    processing={props.processing}
    {...props}
  />
)


EditContactCardLogo.propTypes = {
  organization: PropTypes.instanceOf(Map),
  processing: PropTypes.bool
}

export default EditContactCardLogo
