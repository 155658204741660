import PropTypes from 'prop-types'
import classnames from 'classnames'

import './organizationHeader.scss'

const OrganizationHeader = props => (
  <div className={classnames('organization-header', props.className)}>
    <div className='header-contents'>
      <h1>{props.headerText}</h1>
    </div>
    {props.children}
  </div>
)

OrganizationHeader.propTypes = {headerText: PropTypes.string}

export default OrganizationHeader
