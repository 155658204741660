import classnames from 'classnames'
import {Component} from 'react'
import {Link} from 'react-router'
import PropTypes from 'prop-types'

import Button from '../../../../shared_components/Button'
import * as Links from '../../../../shared_components/Links'
import SignInActions from '../../actions/SignInActions'
import SessionStore from '../../stores/SessionStore'

import phoneWithTwoFactorAuthenticationCode from '../../../assets/images/phone-with-two-factor-authentication-code.png'

import './signIn.scss'

class OTPSignIn extends Component {
  constructor() {
    super()

    this.state = {
      error: SessionStore.getState().signInError,
      code: null,
      loading: false
    }

    this.submitCode = this.submitCode.bind(this)
    this.update = this.update.bind(this)
    this.updateCode = this.updateCode.bind(this)
  }

  componentDidMount() {
    SessionStore.addChangeListener(this.update)
  }

  componentWillUnmount() {
    SessionStore.removeChangeListener(this.update)
  }

  submitCode() {
    if (!this.state.code || this.state.code.length !== 6) {
      this.setState({error: 'Please enter a six digit code.'})
    } else {
      this.setState({loading: true})

      SignInActions.signInWithCode({
        code: this.state.code,
        email: SessionStore.getState().preAuthEmail,
        password: SessionStore.getState().preAuthPassword,
        phoneId: this.props.phoneId,
        trustMe: this.refs.trustThisComputer.checked
      })
    }
  }

  update() {
    this.setState({
      error: SessionStore.getState().signInError,
      loading: !SessionStore.getState().signInError
    })
  }

  updateCode(event) {
    event.preventDefault()
    this.setState({code: event.target.value, error: null})
  }

  render() {
    const codeInputClasses = classnames({
      'tfa-code-input': true,
      'field-error': this.state.error
    })

    const errorMessage = classnames({
      'tfa-error-message': true,
      hidden: !this.state.error
    })

    return (
      <div>
        <div className='login-div'>
          <div className='login-div-align'>
            <h1 className='tfa-header-text'>2-Step Verification</h1>
            <div className='tfa-code-instructions'>
              <img
                alt='Phone with two factor authentication code'
                className='code-verify-img'
                src={phoneWithTwoFactorAuthenticationCode}
              />
              <span className='ep-login-alternate-text'>
                Please enter the 6-digit code
                <br />
                we just sent to {this.props.number}
              </span>
            </div>
            <form className='tfa-code-form'>
              <div>
                <input placeholder='Enter 6-digit code' ref='code' onChange={this.updateCode} className={codeInputClasses} />
                <div>
                  <div className='sign-in-checkbox-align'>
                    <input type='checkbox' ref='trustThisComputer' />
                    <span>Trust this computer for 30 days.</span>
                  </div>
                  <div className='tfa-login-btn'>
                    <Button className='sign-in-btn-primary' onClick={this.submitCode} loading={this.state.loading}>Verify</Button>
                  </div>
                </div>
                <div className={errorMessage}>{this.state.error}</div>
              </div>
            </form>
            <hr className='login-in-horizontal' />
            <Link to='/auth/sign_in/tfa_alternatives' className='tfa_alternatives'>Problems receiving your code?</Link>
          </div>
        </div>
        <div className='contained'>
          <div className='support-links'>
            Need Support&#63;<Links.Help>Everplans Help Center</Links.Help>
          </div>
        </div>
      </div>
    )
  }
}

OTPSignIn.propTypes = {
  number: PropTypes.number,
  phoneId: PropTypes.number
}

export default OTPSignIn
