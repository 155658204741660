import { onBlur, onFocus } from './lib/tools'


const MyHomeInsuranceForm = () => (
  <form className='core'>
    <fieldset>
      <div className='fieldset-flexbox'>
        <div className='controls-flexbox flex-container'>
          <label className='flex-child'>
            <span className='label-text'>Insurance company</span>
            <input name='input-text-1' type='text' onBlur={onBlur} onFocus={onFocus} />
          </label>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div className='fieldset-flexbox controls-group'>
        <legend>Insurance company type</legend>
        <div className='controls-flexbox flex-container checkbox-form-group'>
          <label className='flex-child'>
            <input name='insurance-company-type' type='checkbox' value='Homeowners' />
            <span className='label-text'>Homeowners</span>
          </label>
          <label className='flex-child'>
            <input name='insurance-company-type' type='checkbox' value='Renters' />
            <span className='label-text'>Renters</span>
          </label>
          <label className='flex-child'>
            <input name='insurance-company-type' type='checkbox' value='Liability / Umbrella' />
            <span className='label-text'>Liability / Umbrella</span>
          </label>
          <label className='flex-child'>
            <input name='insurance-company-type' type='checkbox' value='Mortgage' />
            <span className='label-text'>Mortgage</span>
          </label>
          <label className='flex-child'>
            <input name='insurance-company-type' type='checkbox' value='Flood' />
            <span className='label-text'>Flood</span>
          </label>
          <label className='flex-child'>
            <input name='insurance-company-type' type='checkbox' value='Earthquake' />
            <span className='label-text'>Earthquake</span>
          </label>
          <label className='flex-child'>
            <input name='insurance-company-type' type='checkbox' value='Tornado' />
            <span className='label-text'>Tornado</span>
          </label>
          <label className='flex-child'>
            <input name='insurance-company-type' type='checkbox' value='Other' />
            <span className='label-text'>Other</span>
          </label>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div className='fieldset-flexbox'>
        <div className='controls-flexbox flex-container'>
          <label className='flex-child'>
            <span className='label-text'>Policy number</span>
            <input name='input-text-1' type='text' onBlur={onBlur} onFocus={onFocus} />
          </label>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div className='fieldset-flexbox'>
        <div className='controls-flexbox flex-container'>
          <label className='flex-child'>
            <span className='label-text'>Location of original</span>
            <input name='input-text-1' type='text' onBlur={onBlur} onFocus={onFocus} />
          </label>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div className='fieldset-flexbox controls-group'>
        <legend>Notes</legend>
        <div className='controls-flexbox flex-container'>
          <label className='flex-child'>
            <span className='label-text visually-hidden'>Notes</span>
            <textarea name='notes-textarea' onBlur={onBlur} onFocus={onFocus} />
          </label>
        </div>
      </div>
    </fieldset>
  </form>
)

export default MyHomeInsuranceForm
