import PropTypes from 'prop-types'

const PrimaryNumber = ({sessionState, updateRoute}) => (
  <div className='alternate-delivery-option'>
    <input name='mode' type='radio' className='tfa-radio-btn' value='tfa_primary' onChange={updateRoute} />
    <div className='tfa-text-font-weight'>
      Resend to <strong>primary</strong> <strong>number</strong> ({sessionState.primaryPhone.obfuscated_number})
    </div>
  </div>
)

PrimaryNumber.propTypes = {
  sessionState: PropTypes.shape({primaryPhone: PropTypes.object}),
  updateRoute: PropTypes.func
}

export default PrimaryNumber
