import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const BulkInviteActions = new LegacyActionFactory([
  'DID_REINVITE_CLIENTS',
  'GOT_ADVISOR_SEATS',
  'GOT_DRAFT_CLIENTS',
  'GOT_PENDING_CLIENTS'
])

BulkInviteActions.buildBoundGet('advisor_seats', 'firms/users/advisor_seats', BulkInviteActions.Types.GOT_ADVISOR_SEATS) // fetchAdvisorSeats

BulkInviteActions.fetchDraftClients = advisorSeatId => {
  BulkInviteActions.fireApi(
    'get',
    `clients/bulk/${advisorSeatId}/draft_client_seats`,
    null,
    {
      JSONHead: 'draft_clients',
      successAction: BulkInviteActions.Types.GOT_DRAFT_CLIENTS
    }
  )
}

BulkInviteActions.fetchPendingClients = advisorSeatId => {
  BulkInviteActions.fireApi(
    'get',
    `clients/bulk/${advisorSeatId}/pending_client_seats`,
    null,
    {
      JSONHead: 'pending_clients',
      successAction: BulkInviteActions.Types.GOT_PENDING_CLIENTS
    }
  )
}

BulkInviteActions.buildPost(
  'sendOrResendInvitationBulk',
  'clients/bulk/invite_or_reinvite_many',
  {
    successAction: BulkInviteActions.Types.DID_REINVITE_CLIENTS
  }
)

export default BulkInviteActions
