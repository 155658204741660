/*
The `HorizontalList` component wraps each of its children in an `li` tag and displays them inline with stylized spacing
between them. It is important to note that each `li` will wrap a direct child and its descendants--no 'grandchildren'
or other descendents get their own `li` wrapper. Direct children are the top-level wrappers nested immediately within
the `HorizontalList`.

For example, consider this list:
  <HorizontalList>
    <a href='google.com'>Google!</a>
    <div>The Rock</div>
    <span>
      <span>National Treasure</span>
      <span>National Treasure 2</span>
    </span>
  </HorizontalList>

This will generate the following code, which shows that the two spans that are not direct children get wrapped into
the same li:
  <ul class='horizontal-list'>
    <li><a href='google.com'>Google!</a></li>
    <li><div>The Rock</div></li>
    <li>
      <span>
        <span>National Treasure</span>
        <span>National Treasure 2</span>
      </span>
    </li>
  </ul>
*/

import React from 'react'
import classnames from 'classnames'

import './horizontalList.scss'

export default class HorizontalList extends React.Component {
  wrapChildren() {
    return React.Children.toArray(this.props.children).map(kid => <li key={kid.key}>{kid}</li>)
  }

  render() {
    return <ul className={classnames('horizontal-list', this.props.className)}>{this.wrapChildren()}</ul>
  }
}
