import PropTypes from 'prop-types'
import StartAddClients from '../StartAddClients'
import {Map} from 'immutable'

import './emptyClients.scss'

const EmptyClients = ({stats}) => (
  <div className='content empty-clients'>
    <h1>Welcome to Your Dashboard!</h1>
    <h2>
      Here you can invite clients, review their activity, and manage
      your settings.
    </h2>
    <h2>You do not currently have any clients added to this firm.</h2>
    <StartAddClients stats={stats} />
  </div>
)

EmptyClients.propTypes = {
  stats: PropTypes.instanceOf(Map)
}

export default EmptyClients
