import PropTypes from 'prop-types'
import {useEffect} from 'react'

import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import Integration from './Integration'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import UserSubpage from './UserSubpage'
import ShadowWrapper from './ShadowWrapper'
import FirmActions from '../../actions/FirmActions'
import storePrototype from '../../../../shared_components/StorePrototype'

import OpenIdConnectLink from '../../../../auth/src/components/sso/OpenIdConnectLink'
import {isNorthwesternMutualOrganization} from '../../../../lib/integrationTools'
import {usePrevious} from '../../../../lib/hooks'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import './integrations.scss'

export const Integrations = props => {
  const integrations = props.integrations.data.integrations
  const prevIntegrations = usePrevious(integrations)
  const {alwaysNotify} = useNotificationContext()

  useEffect(() => {
    if (props.integrations.data.integrations.length < prevIntegrations.length)
      alwaysNotify.shortSuccess('Integration disconnected.')
  }, [props.integrations.data.integrations])

  const noIntegrationDisplay = () => {
    if (isNorthwesternMutualOrganization(props.organization))
      return <OpenIdConnectLink location={props.location} />
    else return <p>You do not have any active integrations.</p>
  }

  return (
    <UserSubpage className='edit-integrations' headerText='Integrations'>
      <ShadowWrapper>
        <LegacyLoader loading={props.loadingIntegrations}>
          {integrations.length ?
            integrations.map(integration => (
              <Integration
                integration={integration}
                key={integration}
                hasIntegrations={props.hasIntegrations}
              />
            )) :
            noIntegrationDisplay()
          }
        </LegacyLoader>
      </ShadowWrapper>
    </UserSubpage>
  )
}

Integrations.defaultProps = {
  integrations: {
    data: {
      integrations: []
    },
    errors: [],
    message: null
  }
}

Integrations.propTypes = {
  integrations: PropTypes.shape({
    data: PropTypes.shape({
      integrations: PropTypes.arrayOf(PropTypes.string)
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  loadingIntegrations: PropTypes.bool.isRequired,
  hasIntegrations: PropTypes.object,
  organization: PropTypes.object,
  location: PropTypes.object
}

Container.registerAction('integrations', FirmActions)
Container.registerStore('integrations', storePrototype([FirmActions.Types.DID_REMOVE_INTEGRATION, FirmActions.Types.GOT_INTEGRATIONS]))
export default bindResources(Integrations, {name: 'integrations', type: 'itemAllParams'})
