import {List} from 'immutable'
import PropTypes from 'prop-types'

import {TextGutterMedium} from '../../../../../dashboard/src/components/blocks/Texts'
import {Accept, Cancel} from '../../../../../dashboard/src/components/blocks/Buttons'
import JiclElementsContainer from './JiclElementsContainer'

const JiclResponseGroup = props => {
  const {
    onResponseGroupSubmit,
    responseIntroTexts,
    sectionViews,
    viewResponses,
    editResponseGroup,
    processing,
    ...otherProps
  } = props

  return (
    <div className='jicl-response-group'>
      {responseIntroTexts.map(text => (
        <TextGutterMedium key={text}>{text}</TextGutterMedium>
      ))}
      {sectionViews.map(view => (
        <JiclElementsContainer
          {...otherProps}
          data={viewResponses(view)}
          view={view}
          key={view.get('id')}
        />
      ))}
      {editResponseGroup ?
        (
          <div>
            <Accept onClick={onResponseGroupSubmit} processing={processing}>
            Save
            </Accept>
            <Cancel onClick={props.onResponseCancel} />
          </div>
        ) :
        (
          <Accept onClick={onResponseGroupSubmit}>Continue</Accept>
        )}
    </div>
  )
}

JiclResponseGroup.defaultProps = {
  editResponseGroup: false,
  processing: false
}

JiclResponseGroup.propTypes = {
  onResponseGroupSubmit: PropTypes.func,
  onResponseCancel: PropTypes.func,
  responseIntroTexts: PropTypes.string,
  sectionViews: PropTypes.instanceOf(List),
  viewResponses: PropTypes.func,
  editResponseGroup: PropTypes.bool,
  processing: PropTypes.bool
}

export default JiclResponseGroup
