import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const SignInMessageActions = new LegacyActionFactory([
  'GOT_FLASH_MESSAGE'
])

SignInMessageActions.getFlashMessage = messageId => {
  SignInMessageActions.fireApi(
    'get',
    `auth/auth/${messageId}`,
    null,
    {
      JSONHead: 'auth',
      successAction: SignInMessageActions.Types.GOT_FLASH_MESSAGE
    }
  )
}

export default SignInMessageActions
