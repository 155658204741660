import appImage from '../../../assets/theAppImage.png'
import './theApp.scss'

const TheApp = () => (
  <div className='the-app'>
    <div className='main-container'>
      <div className='title'>All the pieces of your world in one place</div>
      <img alt='' src={appImage} />
    </div>
  </div>
)

export default TheApp
