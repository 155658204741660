import GlobalAnnouncementBanner from '../../../dashboard/src/components/feedback/GlobalAnnouncementBanner'

import './jiclApp.scss'

const JiclApp = ({children}) => (
  <div className='jicl-app'>
    <GlobalAnnouncementBanner />
    <main>{children}</main>
  </div>
)


export default JiclApp
