import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {useState, useCallback} from 'react'

import JiclResponseGroup from './JiclResponseGroup'

import {dependentResponsesFromView} from '../../../../../lib/viewTools'
import {updateResponsesWithTypeDate} from '../../../../../lib/responsesHelper'

const JiclResponseGroupContainer = props => {
  const [responses, setResponses] = useState(Map())

  const onResponsesUpdated = newResponses =>
    setResponses(prevResponses => prevResponses.merge(newResponses))

  const formatResponses = updatedResponses =>
    updateResponsesWithTypeDate(updatedResponses.toList())
      .groupBy(response => response.get('view-id'))
      .map((responsesList, viewId) =>
        dependentResponsesFromView({
          groupId: responsesList.getIn([0, 'group-id']),
          view: props.views.find(view => view.get('id') === viewId),
          responses: responsesList
        })
      )
      .toList()
      .flatten(true)

  const onResponseGroupSubmit = () => {
    props.onResponseGroupSubmit(
      formatResponses(props.responses.merge(responses))
    )
    setResponses(Map())
  }

  const viewResponses = useCallback(
    view => {
      if (props.responses.isEmpty()) {
        return List()
      } else {
        return props.responses.filter(
          response => response.get('view-id') === view.get('id')
        )
      }
    },
    [props.responses]
  )

  return (
    <JiclResponseGroup
      {...props}
      onResponseGroupSubmit={onResponseGroupSubmit}
      onResponsesUpdated={onResponsesUpdated}
      responseIntroTexts={props.sectionConfig.get('responseIntroTexts')}
      sectionViews={props.sectionViews}
      viewResponses={viewResponses}
    />
  )
}

JiclResponseGroupContainer.propTypes = {
  onResponseGroupSubmit: PropTypes.func,
  responses: PropTypes.instanceOf(Map),
  sectionConfig: PropTypes.instanceOf(Map),
  sectionViews: PropTypes.instanceOf(List),
  views: PropTypes.instanceOf(List)
}

export default JiclResponseGroupContainer
