import PropTypes from 'prop-types'
import {Component} from 'react'

import PageTitle from '../../../../shared_components/PageTitle'
import TwoStepSignUp from './two_step_sign_up/SignUp'
import WidthContainer from '../../../../shared_components/WidthContainer'

import Container from '../../../../lib/Container'

import signUpImage from '../../../assets/images/sign-up.svg'

import './signUp.scss'

export default class SignUp extends Component {
  constructor() {
    super()

    this.consumerCreditCardRequired = this.consumerCreditCardRequired.bind(this)
  }

  consumerCreditCardRequired() {
    return Container.getStore('config') ?
      Container.getStore('config').getState().data.active_consumer_partner.credit_card_required :
      false
  }

  render() {
    return (
      <div className='sign-up'>
        <PageTitle title='Sign Up' />
        <WidthContainer className='row-wrapper-sb'>
          <div>
            <img alt='' className='hero-image' src={signUpImage} />
            <div className='heading'>
              <h1>Sign up for Everplans</h1>
            </div>
            <div className='intro'>
              <span>
                {
                  this.props.discountCode || this.props.query.ep_ref ?
                    'All of your most important information, in one secure place.' :
                    'Start for free now.'
                }
              </span>
            </div>
            <TwoStepSignUp query={this.props.query} setEmail={this.props.setEmail} />
          </div>
        </WidthContainer>
      </div>
    )
  }
}

SignUp.defaultProps = {
  partner: {}
}

SignUp.propTypes = {
  discountCode: PropTypes.string,
  partner: PropTypes.shape({
    name: PropTypes.string
  }),
  query: PropTypes.shape({
    ep_ref: PropTypes.string
  }),
  setEmail: PropTypes.func
}
