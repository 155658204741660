import KeyMirror from 'keymirror'
import ActionPrototype from '../../../shared_components/ActionPrototype'

var InviteActions = Object.assign({}, ActionPrototype, {
  Types: KeyMirror({CONFIRMED_ADVISOR: null, GOT_INVITE: null}),
  fetchInvite(token) {
    this.fireApi('get', `firms/users/invite/${token}` , {},
      {JSONHead: 'invite', successAction: InviteActions.Types.GOT_INVITE})
  },
  confirmFirmUser(data) {
    this.fireApi('post', `firms/users/confirm/${data.token}`,
      {
        firm_user: {
          password: data.password,
          agreement_accepted: data.agreement
        }
      },
      {JSONHead: 'firm_user', successAction: InviteActions.Types.CONFIRMED_ADVISOR})
  }
})

export default InviteActions
