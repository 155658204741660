import PropTypes from 'prop-types'

import Cubby from '../../../shared_components/core/boxes/Cubby'

import {randomElement} from '../../../lib/tools'

const LOREM_PARAGRAPHS = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et nisi vitae ex vulputate dictum. Donec et interdum dolor. Proin tincidunt nunc quis eros mollis blandit. Sed pharetra est egestas diam tempus, a sagittis leo porta.',
  'Donec viverra neque leo, et ultricies metus semper et. Aenean ante tortor, condimentum eu dictum non, mollis id ligula. Aenean molestie ullamcorper diam ac viverra. Sed accumsan magna sit amet justo feugiat volutpat.',
  'In massa dolor, faucibus vitae ullamcorper consequat, vulputate nec tellus. Quisque varius rutrum turpis, vitae posuere justo pharetra vel. Etiam quis molestie arcu, a convallis enim. Vestibulum justo enim, interdum eget mollis sed, tempor in leo.',
  'Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed tempus turpis non odio porttitor ornare. Etiam mattis tortor id suscipit cursus. Curabitur nec massa laoreet, pretium est euismod, porta ligula.',
  'Nam rutrum in felis vitae elementum. Pellentesque luctus augue sit amet orci porta, at maximus neque condimentum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aliquam imperdiet pellentesque dictum.',
  'Suspendisse ornare a mi fringilla vestibulum. Duis sit amet blandit felis. Quisque auctor aliquam metus, vel vehicula libero commodo id. Vestibulum iaculis congue risus cursus mollis. Nulla vestibulum risus ex, sed tempor mauris elementum non.',
  'Aliquam vel massa turpis. Nunc ullamcorper porttitor diam, sed luctus urna suscipit non. Aenean dignissim justo accumsan mi rhoncus ultricies. Sed ultrices interdum lectus, quis porttitor tellus ultrices non. Suspendisse tempor arcu vitae ipsum consectetur sodales.'
]


const Lorem = ({paragraphCount}) => (
  <Cubby className='lorem'>
    {
      // This approach for looping is taken from a StackOverflow answer. Visit the page below for more info. --BLR
      // http://stackoverflow.com/a/37417004
      [...Array(paragraphCount)].map((counter, index) => <p key={index}>{randomElement(LOREM_PARAGRAPHS)}</p>)
    }
  </Cubby>
)

Lorem.defaultProps = {paragraphCount: 2}

Lorem.propTypes = {paragraphCount: PropTypes.number}

export default Lorem

