import {useRef, useCallback, useState} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import * as Buttons from '../../../../../dashboard/src/components/blocks/Buttons'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import Form from '../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../shared_components/forms/v2/fields'
import {TextGutterMedium, Text} from '../../../../../dashboard/src/components/blocks/Texts'

import {capitalize} from '../../../../../lib/tools'
import validators from '../../../../../shared_components/forms/v2/validators'
import routerUtils from '../../../../../lib/routerUtils'

import './pickRecipients.scss'

const PickRecipients = props => {
  const [selectAll, setSelectAll] = useState(false)
  const formRef = useRef()
  const advisorClientSeats = props.clientSeats.filter(
    seat => seat.get('status') === capitalize(props.params.type) && seat.get('advisor-id') === props.advisorSeat.get('user-id')
  )

  const selectAllRecipients = checkAll => {
    const allRecipients = Array.from(document.getElementsByName('recipients'))

    if (checkAll) {
      allRecipients.forEach(recipient => {
        if (!recipient.checked)
          recipient.click()
      })
    } else {
      allRecipients.forEach(recipient => {
        if (recipient.checked)
          recipient.click()
      })
    }
  }

  const updateCheckAll = event => {
    const formValues = formRef.current.formData().getIn(['recipients', 'value']) || List()


    if (event.target.checked && formValues.size === (advisorClientSeats.size - 1))
      setSelectAll(true)
    else
      setSelectAll(false)
  }

  const goBack = useCallback(() => routerUtils.goBack(), [])

  const onContinue = useCallback(event => {
    if (event)
      event.preventDefault()

    if (formRef.current) {
      const formData = formRef.current.onSubmit()

      if (formRef.current.isValid())
        props.onContinue(formData.getIn(['recipients', 'value']).map(clientSeatId => Map({'client-seat-id': parseInt(clientSeatId, 10)})))
    }
  }, [])

  const toggleSelectAll = useCallback(event => {
    if (event) {
      updateCheckAll(event)
    } else {
      const checkAll = !selectAll

      setSelectAll(checkAll)
      selectAllRecipients(checkAll)
    }
  }, [selectAll])

  const items = advisorClientSeats.sortBy(seat => capitalize(seat.get('last-name'))).map(clientSeat => Map({
    label: (
      <div className='flex-container items-label'>
        <Text className='flex-container client-name'>
          <span>{capitalize(clientSeat.get('last-name'))}, {clientSeat.get('first-name')}</span>
        </Text>
        <Text className='flex-container client-status'>
          <span>{clientSeat.get('status')}</span>
        </Text>
        <Text className='flex-container advisor-name'>
          <span>{props.advisorSeat.get('name')}</span>
        </Text>
      </div>
    ),
    value: clientSeat.get('id')
  }))

  return (
    <div className='pick-recipients'>
      {props.preHeaderText && <div className='preheader'>{props.preHeaderText}</div>}
      <h2 className='select-bulk-clients-header'>Select Clients</h2>
      <TextGutterMedium>
        Below are the clients of <strong>{props.advisorSeat.get('name')}</strong> who are in draft status.
        Please select which ones you would like to invite to Everplans.
      </TextGutterMedium>
      <div className='forms-playground'>
        <Form className='core' ref={formRef}>
          <Fields.SimpleCheckbox
            data={Map({
              id: 'select-all',
              legend: (
                <div className='flex-container checkbox-header'>
                  <Text className='heading'><strong>Clients</strong></Text>
                  <Text className='status-label'><strong>Status</strong></Text>
                  <Text className='advisor-label'><strong>Advisor</strong></Text>
                </div>
              ),
              name: 'select-all'
            })}
            checked={selectAll}
            onChange={toggleSelectAll}
          />
          <Fields.CheckboxList
            data={
              Map({
                id: 'recipients',
                items,
                name: 'recipients'
              })
            }
            className='recipients'
            onClick={toggleSelectAll}
            validator={validators.requiredImmutableList}
          />
        </Form>
      </div>
      <ButtonGroup>
        <Buttons.Cancel onClick={goBack}>« Back</Buttons.Cancel>
        <Buttons.Accept processing={props.processing} onClick={onContinue}>Continue</Buttons.Accept>
      </ButtonGroup>
    </div>
  )
}

PickRecipients.propTypes = {
  advisorSeat: PropTypes.instanceOf(Map),
  clientSeats: PropTypes.instanceOf(List),
  clientSummaries: PropTypes.instanceOf(List),
  onContinue: PropTypes.func,
  params: PropTypes.shape({type: PropTypes.string}),
  preHeaderText: PropTypes.string,
  processing: PropTypes.bool
}

export default PickRecipients
