import {Fragment, useCallback, useEffect, useState} from 'react'

import PropTypes from 'prop-types'

import routerUtils from '../../../../../lib/routerUtils'
import NewLoader from '../../../../../shared_components/NewLoader'
import storeWrapper from '../../../../../shared_components/storeWrapper'
import ClientInviteActions from '../../../actions/ClientInviteActions'
import Footer from './footer/Footer'
import AdvisorContactCard from './AdvisorContactCard'
import TheApp from './TheApp'
import ClientInvitation from './ClientInvitation'
import OrganizesYourLifeInfo from './OrganizesYourLifeInfo'
import SimplifiesYourLifeInfo from './SimplifiesYourLifeInfo'
import './clientInvitationLandingPageController.scss'

const ClientInvitationLandingPageController = props => {
  const [hasDeclined, setHasDeclined] = useState(false)
  const [loading, setLoading] = useState(true)
  const [clientInviteLanding, setClientInviteLanding] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      await ClientInviteActions.fetchClientInviteLanding(props.params.clientInviteId)
    }

    if (props.clientInviteLanding.data.invite_landing) {
      setLoading(false)
      setClientInviteLanding({...props.clientInviteLanding.data.invite_landing})
    } else {
      fetchData()
    }
  }, [props.clientInviteLanding.data])

  const handleAcceptClick = useCallback(() => {
    routerUtils.setLocation(clientInviteLanding.yes_link)
  }, [clientInviteLanding])

  const handleDeclineClick = useCallback(() => {
    ClientInviteActions.declineClientInvite(props.params.clientInviteId)
    setHasDeclined(true)
  }, [props.params.clientInviteId, setHasDeclined])

  const handleUpgradeAccountClick = useCallback(() => {
    routerUtils.setLocation(clientInviteLanding.already_have_everplan_path)
  }, [clientInviteLanding])

  if (loading) {
    return <NewLoader loading={loading} />
  } else {
    return (
      <Fragment>
        <div className='client-invitation-landing-page'>
          <div className='hero-container'>
            <div className='left-side'>
              <ClientInvitation
                clientInviteLanding={clientInviteLanding}
                handleAcceptClick={handleAcceptClick}
                handleDeclineClick={handleDeclineClick}
                handleUpgradeAccountClick={handleUpgradeAccountClick}
                hasDeclined={hasDeclined}
              />
            </div>
            <div className='right-side'>
              <AdvisorContactCard
                advisor={clientInviteLanding.advisor_contact}
              />
            </div>
          </div>
          <SimplifiesYourLifeInfo handleActivateButtonClick={handleAcceptClick} />
          <div className='organization-container'>
            <OrganizesYourLifeInfo handleActivateButtonClick={handleAcceptClick} />
          </div>
          <TheApp />
        </div>
        <Footer />
      </Fragment>
    )
  }
}

ClientInvitationLandingPageController.propTypes = {
  clientInviteLanding: PropTypes.shape({
    data: PropTypes.shape({
      invite_landing: PropTypes.object
    })
  }),
  params: PropTypes.shape({
    clientInviteId: PropTypes.string
  })
}

export default storeWrapper(
  ClientInvitationLandingPageController,
  [{actionTypes: [ClientInviteActions.Types.GOT_CLIENT_INVITE_LANDING], name: 'clientInviteLanding'}]
)
