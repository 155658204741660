import React from 'react'
import {IndexRoute, Route} from 'react-router'

import '../../dashboard/styles/main.scss' // PLEASE DO NOT MOVE! We need to import our reset and global styles rules BEFORE importing components --KW

import BasicFormElements from '../forms_playground/BasicFormElements'
import FormsPlayground from '../forms_playground/FormsPlayground'
import FormSideLabels from '../forms_playground/FormSideLabels'
import MyHomeInsurance from '../forms_playground/MyHomeInsurance'
import LargePopUpModalExample from '../forms_playground/LargePopUpModalExample'
import LifeInsurance from '../forms_playground/LifeInsuranceForm'
import NewContactForm from '../forms_playground/NewContactForm'
import NewFormsExample from '../forms_playground/NewFormsExample'
// import SlidingModalExample from '../forms_playground/SlidingModalExample' // Currently not using the Sliding Modals and is replaced by the Large Pop Up Modal. --KW
import StyleGuide from '../style_guide/StyleGuide'

export default (
  <Route>
    <Route component={FormsPlayground} path='forms-playground'>
      <IndexRoute component={BasicFormElements} />
      <Route component={FormSideLabels} path='side-labels' />
      <Route component={LargePopUpModalExample} path='popup-modal' />
      <Route component={LifeInsurance} path='life-insurance' />
      <Route component={MyHomeInsurance} path='home-insurance' />
      <Route component={NewContactForm} path='new-contact' />
      <Route component={NewFormsExample} path='new-forms-example' />
      {/* <Route component={SlidingModalExample} path='slide-modal' /> */}
    </Route>
    <Route path='style-guide'>
      <IndexRoute component={StyleGuide} />
    </Route>
  </Route>
)
