import PropTypes from 'prop-types'
import {Map} from 'immutable'

import RecipientsDetails from './RecipientsDetails'

const ToAndFrom = props => (
  <div className='to-and-from'>
    <div className='details'>
      <strong className='label'>To:</strong>
      <RecipientsDetails {...props} />
    </div>
    <div className='details'>
      <strong className='label'>From:</strong>
      <span className='advisor-details'>{props.isHighCompliance ? 'Everplans' : `${props.advisor.get('name')} via Everplans`}</span>
    </div>
  </div>
)

ToAndFrom.propTypes = {
  advisor: PropTypes.instanceOf(Map),
  isHighCompliance: PropTypes.bool
}

export default ToAndFrom
