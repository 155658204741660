import PropTypes from 'prop-types'

import './organizesYourLifeItem.scss'

const OrganizesYourLifeItem = props => {
  const {
    content: {
      image,
      textOne,
      textTwo
    }
  } = props

  return (
    <li className='organizes-your-life-item'>
      <img alt='' src={image} />
      <div className='item-text'>{textOne}</div>
      <div className='item-text'>{textTwo}</div>
    </li>
  )
}

OrganizesYourLifeItem.propTypes = {
  content: PropTypes.shape({
    image: PropTypes.string,
    textOne: PropTypes.string,
    textTwo: PropTypes.string
  })
}

export default OrganizesYourLifeItem


