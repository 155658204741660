import {Component} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import OrganizationHeader from './OrganizationHeader'
import OrganizationNav from './OrganizationNav'
import Loader from '../../../../shared_components/NewLoader'

import {findRawResourceById} from '../../../../lib/plan_data/userData'
import routerUtils from '../../../../lib/routerUtils'


class Organization extends Component {
  componentDidMount() { this.props.readEndpoint(`organizations/${this.props.params.organizationId}?include=organization-config`) }

  render() {
    return (
      <Loader loading={this.props.loading}>
        <div className='organization'>
          <OrganizationHeader className='column' headerText={this.props.organization.get('name')}>
            <OrganizationNav organizationId={this.props.organization.get('id')} />
          </OrganizationHeader>
          {routerUtils.childrenWithProps(this.props.children, {organization: this.props.organization})}
        </div>
      </Loader>
    )
  }
}

const mapStateToProps = ({api}, ownProps) => {
  const organization = findRawResourceById({rawResource: api.organizations, id: ownProps.params.organizationId})

  return {organization, loading: organization.isEmpty()}
}

Organization.propTypes = {
  organization: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  params: PropTypes.shape({organizationId: PropTypes.string})
}

export default connect(mapStateToProps, {readEndpoint})(Organization)
