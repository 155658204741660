import {useCallback, useState} from 'react'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'
import PropTypes from 'prop-types'

import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import {Cancel, Continue} from '../../../../shared_components/ButtonTypes'
import {ContentBoxGutterMedium} from '../../../../dashboard/src/components/blocks/ContentBoxes'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'
import UnorderedList from '../../../../shared_components/UnorderedList'

import routerUtils from '../../../../lib/routerUtils'

import './permissions.scss'

export const Permissions = props => {
  const [loading, setLoading] = useState(false)

  const yes = useCallback(() => {
    const params = props.location.query

    const payload = {
      type: 'oauth-agreements',
      attributes: {
        'client-id': params['client-id'],
        'redirect-uri': params['redirect-uri'],
        state: params.state
      }
    }
    setLoading(true)

    props.createResource(payload)
      .then(response => {
        const redirectUrl = response.data.attributes['redirect-url']

        if (redirectUrl)
          routerUtils.setLocation(redirectUrl)
        else
          routerUtils.replace('/')
      })
      .catch(() => routerUtils.replace('/'))
      .finally(() => setLoading(false))
  }, [props.location.query])

  const no = useCallback(() => routerUtils.replace('/'), [])

  return (
    <article className='permissions'>
      <header>
        <h1>Grant Permission to Redtail</h1>
      </header>
      <ContentBoxGutterMedium>
        <TextGutterMedium><strong>Redtail would like to be able to:</strong></TextGutterMedium>
        <UnorderedList children={['Log you into Everplans Professional via single sign-on', "Access information about your clients' Everplans"]} />
        <ButtonGroup>
          <Cancel children="Don't Allow" onClick={no} />
          <Continue loading={loading} children='Allow' onClick={yes} />
        </ButtonGroup>
      </ContentBoxGutterMedium>
    </article>
  )
}

Permissions.propTypes = {
  createResource: PropTypes.func,
  location: PropTypes.shape({query: PropTypes.object})
}

export default connect(null, {createResource})(Permissions)
