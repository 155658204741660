import ReactTable from 'react-table'

import PropTypes from 'prop-types'

import './summariesTable.scss'

const SummariesTable = props => (
  <ReactTable
    {...props}
    manual
    defaultSorted={[{id: 'name', desc: false}]}
    className='summaries-table -striped -highlight'
    resizable={false}
    getTdProps={(state, rowInfo) => ({
      onClick: () => props.onRowClick(rowInfo.original.id)
    })}
  />
)

SummariesTable.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.array,
  onRowClick: PropTypes.func
}

export default SummariesTable
