import PropTypes from 'prop-types'

import ShadowWrapper from '../shared_firm/ShadowWrapper'

const NoLicensesOfType = ({type}) => (
  <ShadowWrapper>
    <p>
      {
        type === 'active' ?
          'There is no active license.' :
          'There are no archived licenses.'
      }
    </p>
  </ShadowWrapper>
)

NoLicensesOfType.propTypes = {
  type: PropTypes.string
}

export default NoLicensesOfType
