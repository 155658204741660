import {BoxGutterMedium} from '../../../dashboard/src/components/blocks/Boxes'
import CodeBlock from '../CodeBlock'
import CodeExamples from '../CodeExamples'
import CollapsibleSection from './CollapsibleSection'
import {TextGutterMedium} from '../../../dashboard/src/components/blocks/Texts'
import UnorderedList from '../../../shared_components/UnorderedList'


const unorderedListData = [
  'Hi! I am an unordered list item!',
  'Me too! It\'s so nice to meet you!',
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et nisi vitae ex vulputate dictum. Donec et interdum dolor. Proin tincidunt nunc quis eros mollis blandit. Sed pharetra est egestas diam tempus, a sagittis leo porta. Donec viverra neque leo, et ultricies metus semper et. Aenean ante tortor, condimentum eu dictum non, mollis id ligula. Aenean molestie ullamcorper diam ac viverra. Sed accumsan magna sit amet justo feugiat volutpat.',
  'You can see above how a list item with long text is neatly indented when wrapping into multiple lines.'
]

const ListsSection = () => (
  <CollapsibleSection heading='Lists'>
    <BoxGutterMedium>
      <aside>
        <h3>Lists: Unordered List</h3>
        <TextGutterMedium>
          Unordered lists are used to display a bulleted list of items. The required <code>props</code> for this component is <code>children</code> and must pass an array of items.
        </TextGutterMedium>
        <UnorderedList children={unorderedListData} />
        <CodeBlock>{CodeExamples.UnorderedList}</CodeBlock>
      </aside>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default ListsSection
