import PropTypes from 'prop-types'
import {Component} from 'react'
import moment from 'moment'

import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import DeathReportActions from '../../actions/DeathReportActions'
import NewLoader from '../../../../shared_components/NewLoader'
import storeWrapper from '../../../../shared_components/storeWrapper'
import {TextGutterSmall} from '../../../../dashboard/src/components/blocks/Texts'
import {timeWithZoneMonthDayYear} from '../../../../lib/dateTools'

import {addPossessive} from '../../../../lib/tools'
import routerUtils from '../../../../lib/routerUtils'
import {addVerificationPeriodToDate, verificationPeriodMinutesToString} from '../../../../lib/deathVerificationPeriodTools'

import './cancelDeathReportInterstitial.scss'

Container.registerAction('death_reports', DeathReportActions)

export class CancelDeathReportInterstitial extends Component {
  constructor() {
    super()

    this.state = {processing: false}

    this.onCancel = this.onCancel.bind(this)
    this.latestDeathReport = this.latestDeathReport.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.canceledDeathReport.data.updated_at)
      routerUtils.push('/#/pending-requests/cancel-death-report/done')
  }

  onCancel() {
    this.setState({processing: true})

    DeathReportActions.updateUser(this.latestDeathReport().death_reportable.id)
  }

  latestDeathReport() {
    const now = moment.now()

    return this.props.death_reports.data.length && this.props.death_reports.data.reduce((previousReport, currentReport) => {
      if (addVerificationPeriodToDate(previousReport.created_at, previousReport.death_verification_period).diff(now) < addVerificationPeriodToDate(currentReport.created_at, currentReport.death_verification_period).diff(now))
        return previousReport
      else
        return currentReport
    })
  }

  render() {
    const deathReport = this.latestDeathReport() || {}
    const deathReporterName = deathReport.death_reporter && `${deathReport.death_reporter.first_name} ${deathReport.death_reporter.last_name}`

    return (
      <NewLoader loading={this.props.loadingDeathReports}>
        <div className='cancel-death-report-interstitial central-content-box'>
          <h2 className='heading'>{deathReporterName || 'Someone'} is trying to unlock your Everplan</h2>
          <TextGutterSmall>
            {deathReporterName || 'A deputy'} requested access to the "after death"
            items of your Everplan on {timeWithZoneMonthDayYear({date: deathReport.created_at})}.
          </TextGutterSmall>
          <TextGutterSmall>
            Please be assured that none of your "after death" items have been shared with your deputies at this time. Based on the wait
            time you placed on {addPossessive(deathReport.death_reporter ? deathReport.death_reporter.first_name : 'this deputy')} unlocking
            permission, we are holding the release of your Everplan for {verificationPeriodMinutesToString(deathReport.death_verification_period)},
            until {timeWithZoneMonthDayYear({date: deathReport.created_at, additionalHours: deathReport.death_verification_period / 60})}.
          </TextGutterSmall>
          <TextGutterSmall>
            If you don't want the "after death" items of your Everplan to be unlocked, please click below.
            If you don't take action, these items will be made visible
            to {deathReport.death_reporter ? `${deathReporterName} and any other deputies` : 'all deputies'} who were granted
            "after death" access.
          </TextGutterSmall>
          <Accept processing={this.state.processing} onClick={this.onCancel}>Keep my Everplan locked</Accept>
        </div>
      </NewLoader>
    )
  }
}

CancelDeathReportInterstitial.propTypes = {
  canceledDeathReport: PropTypes.shape({
    data: PropTypes.shape({
      updated_at: PropTypes.string
    })
  }),
  loadingDeathReports: PropTypes.bool,
  death_reports: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object)
  })
}

CancelDeathReportInterstitial.defaultProps = {
  loadingDeathReports: false,
  death_reports: {
    data: []
  }
}

export default storeWrapper(
  bindResources(
    CancelDeathReportInterstitial,
    [{name: 'death_reports', type: 'list'}]
  ),
  [{name: 'canceledDeathReport', actionTypes: [DeathReportActions.Types.DID_CANCEL_DEATH_REPORT]}]
)
