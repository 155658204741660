import PropTypes from 'prop-types'
import React from 'react'
import {If} from './deprecated/IfFlag'
import classnames from 'classnames'

export class DropDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {open: props.open}

    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.openDropdown = this.openDropdown.bind(this)
    this.closeDropdown = this.closeDropdown.bind(this)
  }
  toggleDropdown(event) {
    if (!this.state.open)
      this.openDropdown(event)
    else
      this.closeDropdown(event)
  }

  openDropdown(event) {
    event.stopPropagation()
    document.addEventListener('click', this.closeDropdown)
    document.addEventListener('touchenter', this.closeDropdown)
    this.setState({open: true})
  }

  closeDropdown(event) {
    event.stopPropagation()
    document.removeEventListener('click', this.closeDropdown)
    document.removeEventListener('touchenter', this.closeDropdown)
    this.setState({open: false})
  }


  getChildContext() {
    return {closer: this.closeDropdown}
  }

  render() {
    return (
      <div className={classnames(this.props.className)}>
        <span className='title-text' onClick={this.toggleDropdown}>
          <span>{this.props.title}</span>
          <i className='fa fa-caret-down' />
        </span>
        <If flag={this.state.open}>{this.props.children}</If>
      </div>
    )
  }
}


DropDown.childContextTypes = {closer: PropTypes.func}

export class DropDownMenu extends React.Component {
  render() {
    return (
      <div onClick={this.context.closer}>
        {this.props.children}
      </div>
    )
  }
}


DropDownMenu.contextTypes = {closer: PropTypes.func}
