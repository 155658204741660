import PropTypes from 'prop-types'
import {useCallback} from 'react'

import {
  TextGutterMedium,
  TextGutterSmall
} from '../../../../dashboard/src/components/blocks/Texts'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import './jiclOneMoreStep.scss'

const JiclOneMoreStep = props => {
  const {alwaysNotify} = useNotificationContext()

  const resendConfirmationLink = useCallback(() => {
    const resource = {
      type: 'magic-links',
      attributes: {email: props.email}
    }

    props.createResource(resource)
      .then(() => alwaysNotify.shortSuccess(`Email resent to ${props.email}`))
  })

  return (
    <div className='jicl-one-more-step'>
      <div className='section-title'>One More Step!</div>
      <div className='instruction-text'>
        <TextGutterMedium>
          We compiled your Just-In-Case List and sent a personalized link to{' '}
          <span className='semi-bold-text'>{props.email}.</span>
        </TextGutterMedium>
        <TextGutterMedium>
          Please look for an email from{' '}
          <span className='semi-bold-text'>Everplans</span> in your inbox.
        </TextGutterMedium>
      </div>
      <TextGutterSmall>Didn't get the email?</TextGutterSmall>
      <a onClick={resendConfirmationLink}>Resend Confirmation Link</a>
    </div>
  )
}

JiclOneMoreStep.propTypes = {
  email: PropTypes.string,
  createResource: PropTypes.func
}

export default JiclOneMoreStep
