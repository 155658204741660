import PropTypes from 'prop-types'
import {useEffect} from 'react'

import * as ButtonTypes from '../../../../shared_components/ButtonTypes'
import Modal from '../../../../shared_components/Modal'
import modalWrapper from '../../../../shared_components/ModalWrapper'

import routerUtils from '../../../../lib/routerUtils'
import SessionStore from '../../../../auth/src/stores/SessionStore'

import {addPossessive} from '../../../../lib/tools'
import {usePrevious} from '../../../../lib/hooks'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import boundUpdate from '../../../../shared_components/BoundUpdate'
import Container from '../../../../lib/Container'
import FirmActions from '../../actions/FirmActions'
import storePrototype from '../../../../shared_components/StorePrototype'

const RemoveFirmUser = (props, context) => {
  const prevData = usePrevious(props.data)
  const prevError = usePrevious(props.errors)
  const currentUser = SessionStore.getState().currentUser
  const {isDifferent} = useNotificationContext()

  useEffect(() => {
    isDifferent(props.data, prevData).shortSuccess(`${props.data.name} was removed.`)
    isDifferent(props.errors, prevError).shortError("We're sorry, but something went wrong while removing that user. Please try again.")
  }, [props.data, props.errors])

  const removeFirmUser = () => {
    props.updating(() => {
      context.closeModal()

      const isRemovingSelf = currentUser.firm_seat_id === parseInt(props.userSeatId, 10)

      if (isRemovingSelf)
        routerUtils.push('cm/dashboard')
      else
        routerUtils.push(`/pro/firm/${props.firmId}/users`)
    })

    FirmActions.removeFirmUser({firmUserSeatId: props.userSeatId, firmId: props.firmId})

    props.finished()
  }

  const isCurrentUser = (currentUser.firm_seat_id === parseInt(props.userSeatId, 10))

  return (
    <div className='remove-firm-user'>
      {props.isActive && props.hasClients ?
        <div className='column-wrapper-sb advisor-with-clients'>
          <strong>Trying to remove this user?</strong>
          <div>
              You must first reassign clients to another {props.firmConfig.professional_designation.toLowerCase()}.
          </div>
        </div> :
        <a href='#' onClick={context.showModal}>Remove user</a>
      }
      <Modal visible={props.showModal}>
        { isCurrentUser ?
          <p>Are you sure you want to remove your account? You will no longer have access to Everplans Professional once you are removed.</p> :
          <p>Are you sure you want to remove {addPossessive(props.user.name)} account? They will no longer have access to Everplans Professional once they are removed.</p>
        }
        <div className='button-group'>
          <ButtonTypes.Cancel onClick={context.closeModal} />
          <ButtonTypes.Continue onClick={removeFirmUser}>Remove {props.user.first_name}</ButtonTypes.Continue>
        </div>
      </Modal>
    </div>
  )
}

RemoveFirmUser.defaultProps = {
  user: {},
  showModal: false
}

RemoveFirmUser.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  finished: PropTypes.func,
  firmConfig: PropTypes.object,
  firmId: PropTypes.string.isRequired,
  hasClients: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  showModal: PropTypes.bool,
  updating: PropTypes.func,
  user: PropTypes.object,
  userSeatId: PropTypes.string.isRequired
}

RemoveFirmUser.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func,
  router: PropTypes.object
}

Container.registerStore('removeFirmUser', storePrototype([FirmActions.Types.DID_REMOVE_FIRM_USER]))
export default boundUpdate(modalWrapper(RemoveFirmUser), 'removeFirmUser', null)
