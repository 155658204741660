import React from 'react'

import PendingRequestWrapper from '../../../../shared_components/blocks/PendingRequestWrapper'
import Loader from '../../../../shared_components/NewLoader'
import PageTitle from '../../../../shared_components/PageTitle'

export default class UnsubscribeComplete extends React.Component {
  render() {
    return (
      <PendingRequestWrapper loading={false} whitelist={['unsubscribe/deputy-updates']}>
        <Loader loading={false}>
          <div className='unsubscribe central-content-box'>
            <PageTitle title='Unsubscribe Complete' />
            <h1>You've been unsubscribed.</h1>
            <p>You will no longer receive updates about changes to the plans you are a Deputy for.</p>
          </div>
        </Loader>
      </PendingRequestWrapper>
    )
  }
}
