/** @class RequestItemPermissionsModal
 *
 * @desc
 * Modal for selecting item permissions to be requested by an advisor/firm
 *
 */

import {Component} from 'react'
import {Map, fromJS} from 'immutable'
import PropTypes from 'prop-types'

import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import {Cancel, Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import Fields from '../../../../shared_components/forms/v2/fields/index'
import Closer from '../../../../shared_components/core/closer/Closer'
import Form from '../../../../shared_components/forms/v2/Form'
import Modals from '../../../../shared_components/core/modals/Modals'
import ItemPermissionsSelectForm from '../../../../shared_components/item_permissions/ItemPermissionsSelectForm'
import Logger from '../../../../lib/NewLogger'
import {ModalHeader} from '../../../../shared_components/core/modals/ModalHeaders'

import {requestedItemPermissions} from '../../../../lib/itemPermissionTools'

import './requestItemPermissionsModal.scss'


export default class RequestItemPermissionsModal extends Component {
  constructor() {
    super()
    // checkedItemIds is set to an empty array, because the toggle will always initialize it with the right item ids.
    this.state = {showModal: false, checkedItemIds: []}

    this.onCheck = this.onCheck.bind(this)
    this.closer = this.closer.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
      // making sure that the form has the correct item permissions checked when toggled from the client detail page or the
      // during the client invite flow.
      checkedItemIds: this.props.itemPermissionIds
    })
  }

  closer() { return <Closer closer={this.toggleModal} /> }

  onCheck(checkedItemIds) { this.setState({checkedItemIds}) }

  onConfirm() {
    this.saveDefaultPermissions()
    this.props.onConfirm(this.state.checkedItemIds)
    this.toggleModal()
  }

  saveDefaultPermissions() {
    if (!this.refs.form.formData().getIn(['default-item-permissions-checkbox', 'value']).isEmpty()) {
      Logger.log({name: 'advisor_saved_default_item_permissions'})

      this.props.updateResource({
        type: 'firm-user-configs',
        id: this.props.firmUserConfig.get('id'),
        attributes: {
          'default-item-permissions': requestedItemPermissions({
            itemIds: this.state.checkedItemIds,
            permissionType: 'edit'
          })
        }
      })
    }
  }

  render() {
    return (
      <span className='request-item-permissions-modal forms-playground'>
        {
          this.props.showLink &&
          <a className='item-permissions-modal-link' onClick={this.toggleModal}>
            {this.props.linkText}
          </a>
        }
        <Modals.FullHeightModalLarge showModal={this.state.showModal} closerComponent={this.closer}>
          <ModalHeader
            heading='For which sections would you like permission to edit?'
            note="Note: some sections may be restricted by your firm's compliance settings."
          />
          <ItemPermissionsSelectForm
            checkedItemIds={this.state.checkedItemIds}
            onCheck={this.onCheck}
            permittedItemIds={this.props.firmOwnership.get('manageable-edit-item-ids')}

          />

          <ButtonGroup>
            <Cancel onClick={this.toggleModal} />
            <Accept
              onClick={this.onConfirm}
              disabled={this.props.requireItemPermissionSelection && !this.state.checkedItemIds.length}>
              {this.props.confirmationText}
            </Accept>
          </ButtonGroup>
          <Form className='core' ref='form'>
            <Fields.CheckboxList
              data={
                fromJS({
                  id: 'default-item-permissions-checkbox',
                  items: [{value: 'true', label: 'Save as default settings'}],
                  name: 'default-item-permissions-checkbox',
                  value: []
                })
              }
            />
          </Form>
        </Modals.FullHeightModalLarge>
      </span>
    )
  }
}

RequestItemPermissionsModal.defaultProps = {
  confirmationText: 'Save',
  linkText: 'Request Permission to Edit'
}

RequestItemPermissionsModal.propTypes = {
  confirmationText: PropTypes.string,
  firmOwnership: PropTypes.instanceOf(Map),
  itemPermissionIds: PropTypes.array,
  linkText: PropTypes.string,
  onConfirm: PropTypes.func,
  requireItemPermissionSelection: PropTypes.bool,
  showLink: PropTypes.bool,
  firmUserConfig: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func
}
