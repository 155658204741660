import {fromJS} from 'immutable'
import {femalePronouns, malePronouns, nonBinaryPronouns} from './pronouns'

export const ownersWithSameLastName = fromJS([
  {
    id: '1',
    'first-name': 'Kayode',
    'last-name': 'Baba',
    email: 'kay1@e.com',
    pronoun: malePronouns,
    gender: 'm'
  },
  {
    id: '2',
    'first-name': 'Joshua',
    'last-name': 'Baba',
    email: 'josh1@e.com',
    gender: 'f',
    pronoun: nonBinaryPronouns
  }
])

export default fromJS([
  {
    id: '1',
    'everplan-id': 2,
    'first-name': 'Kayode',
    'last-name': 'Baba',
    email: 'kay1@e.com',
    pronoun: malePronouns,
    gender: 'm',
    'ownable-type': 'User',
    'ownable-id': 1
  },
  {
    id: '2',
    'everplan-id': 2,
    'first-name': 'Joshua',
    'last-name': 'Kaba',
    email: 'josh1@e.com',
    gender: 'f',
    pronoun: femalePronouns,
    'ownable-type': 'Invite',
    'ownable-id': 2
  }
])
