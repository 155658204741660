import PropTypes from 'prop-types'


const ClientErrorRow = props => <li className='errors'>{props.first_name} {props.last_name} ({props.email})</li>

ClientErrorRow.propTypes = {
  email: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired
}

export default ClientErrorRow
