import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import {useMemo} from 'react'

import ElementViewByType from './view_fields/ElementViewByType'

const JiclPreviewResponseElement = ({
  elementViewTextConfig,
  responses,
  element,
  newContacts,
  viewName
}) => {
  const elementResponse = responses.find(
    response => response.get('element-id') === element.get('id')
  )

  const elementViewText = useMemo(() => {
    const text = elementViewTextConfig.get(element.get('id'))
    return Map.isMap(text) ? text.get(viewName) : text
  }, [viewName, element])

  if (elementResponse) {
    return (
      <div className='jicl-preview-response-element'>
        {elementViewText && <div className='response-intro-text'>{elementViewText}: </div>}
        <ElementViewByType
          newContacts={newContacts}
          response={elementResponse}
          element={element}
        />
      </div>
    )
  } else {
    return null
  }
}

JiclPreviewResponseElement.propTypes = {
  elementViewTextConfig: PropTypes.instanceOf(Map),
  responses: PropTypes.instanceOf(List),
  element: PropTypes.instanceOf(Map),
  newContacts: PropTypes.instanceOf(List),
  viewName: PropTypes.string
}

export default JiclPreviewResponseElement
