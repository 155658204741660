import Button from '../../../../../shared_components/Button'
import WidthContainer from '../../../../../shared_components/WidthContainer'
import CheckImage from '../../../../assets/images/checkmark-icon.svg'

export default props => (
  <WidthContainer>
    <div className='confirm'>
      <h1>{props.firstName}, your email address is confirmed!</h1>
      <div className='confirm-content'>
        <img src={CheckImage} />
        <p>
            There are just a couple more things we need to cover (security settings and subscription options)
            in order to finish setting up your account
        </p>
      </div>
      <Button className='btn-success' loading={props.processing} onClick={props.nextStep}>
          Continue account setup &raquo;
      </Button>
    </div>
  </WidthContainer>
)
