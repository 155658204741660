import {Fragment} from 'react'
import PropTypes from 'prop-types'

import {pluralize} from '../../../../../lib/tools'

const AdvisorWithStats = ({advisorName, params, clientCount}) => (
  <Fragment>
    <span className='advisor-name'>{advisorName}</span>
    <span className='seat-count'>{clientCount} {pluralize('client', clientCount)} in {params.type} status</span>
  </Fragment>
)

AdvisorWithStats.propTypes = {
  advisorName: PropTypes.string,
  clientCount: PropTypes.number,
  params: PropTypes.shape({type: PropTypes.string})
}

export default AdvisorWithStats
