import PropTypes from 'prop-types'

import Header from '../../../../shared_components/header/Header'
import CanceledPayment from './CanceledPayment'
import SuccessfulPayment from './SuccessfulPayment'
import './lifetimeSubscriptionPaymentStatusController.scss'

const LifetimeSubscriptionPaymentStatusController = props => {
  const {location: {query: {success}}} = props

  return (
    <div className='lifetime-subscription-payment-status-controller'>
      <Header flavor='transactional' />
      {success === 'true' ? <SuccessfulPayment /> : <CanceledPayment />}
    </div>
  )
}

LifetimeSubscriptionPaymentStatusController.propTypes = {
  location: PropTypes.object
}

export default LifetimeSubscriptionPaymentStatusController
