/** @module proNavigation
 *
 * @desc
 * Module containing pro-related helpers for constructing paths, constructing URLs,
 * and navigating around the pro app
 */

import routerUtils from '../routerUtils'

// /////////////////////////////////////////////////////////////////////////////
// HELPERS

/** If `param` is present returns slash plus the `param`, otherwise returns an empty string. */
export const paramIfPresent = param => `${param ? `/${param}` : ''}`

/** Returns in order `groupId` and `elementId`, including each only if it is present. */
export const groupAndElementIds = ({elementId, groupId}) => `${paramIfPresent(groupId)}${paramIfPresent(elementId)}`

// /////////////////////////////////////////////////////////////////////////////
// PATHS
export const householdDetailPath = ({clientSeatId}) => `pro/household/${clientSeatId}`

export const inviteClientModalPath = ({clientSeatId, inviteSeatId}) => `${householdDetailPath({clientSeatId})}/invite/${inviteSeatId}`
// START: write access paths

/** Returns the base path shared by many of the write access paths created below. */
export const proItemPathBase = ({clientSeatId, everplanId, itemSlug, slug}) => (
  `${householdDetailPath({clientSeatId})}/${everplanId}/category/${slug}/item/${itemSlug}`
)

/** Returns the path for adding more information to a section within a compound item that allows multiple additions. */
export const proAddMorePath = ({clientSeatId, everplanId, itemSlug, slug, groupId}) => (
  `${proItemPathBase({clientSeatId, everplanId, itemSlug, slug})}/add-more/${groupId}`
)

/** Returns the path for editing a simple response group. */
export const proEditItemPath = ({clientSeatId, everplanId, itemSlug, slug, groupId}) => (
  `${proItemPathBase({clientSeatId, everplanId, itemSlug, slug})}/edit/${groupId}`
)

/** Returns the path for editing a compound response group. */
export const proEditCompoundItemPath = ({clientSeatId, everplanId, itemSlug, slug, groupId}) => (
  `${proItemPathBase({clientSeatId, everplanId, itemSlug, slug})}/edit/compound/${groupId}`
)

/** Returns the path for adding a new compound response group. */
export const proNewCompoundItemPath = ({everplanId, itemSlug, slug, elementId, groupId, clientSeatId}) => (
  `${proItemPathBase({clientSeatId, everplanId, itemSlug, slug})}/new/compound${paramIfPresent(elementId)}${paramIfPresent(groupId)}`
)

/** Returns the path for adding a new simple response group. */
export const proNewItemPath = ({everplanId, itemSlug, slug, elementId, clientSeatId}) => (
  `${proItemPathBase({clientSeatId, everplanId, itemSlug, slug})}/new${elementId ? `/${elementId}` : ''}`
)

/** Returns the path for viewing a response group (i.e., the view where the user can choose to edit the data). */
export const proViewItemPath = ({clientSeatId, everplanId, itemSlug, slug, groupId, elementId}) => (
  `${proItemPathBase({clientSeatId, everplanId, itemSlug, slug})}/view${groupAndElementIds({elementId, groupId})}`
)

// END: write access paths

// /////////////////////////////////////////////////////////////////////////////
// NAVIGATORS

export const goToHouseholdDetail = ({clientSeatId}) => routerUtils.push(householdDetailPath({clientSeatId}))

export const goToInviteClientModal = ({clientSeatId, inviteSeatId}) => routerUtils.push(inviteClientModalPath({clientSeatId, inviteSeatId}))
