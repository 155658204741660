import {Component} from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Forms from './index'

import Validators from '../forms/Validators'
import MixpanelLogger from '../../lib/MixpanelLogger'
import greenCheckIcon from '../../shared_assets/green-check-icon.svg'

import './profileCompletionForm.scss'

class ProfileCompletionForm extends Component {
  constructor(props) {
    super(props)

    this.state = {errors: []}

    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    MixpanelLogger.track(this.props.interstitial ? 'view_profile_creation' : 'view_profile_completion_modal')
  }

  formErrors() {
    if (this.state.errors.length > 0)
      return ['Please see errors above.']
  }

  submitForm() {
    MixpanelLogger.track(this.props.interstitial ? 'click_profile_creation_continue' : 'click_profile_completion_continue')

    const errors = this.refs.form.validate()

    if (errors.length === 0) {
      const formData = this.refs.form.formData()

      this.props.onSubmit(formData)
    } else {
      if (this.props.interstitial) MixpanelLogger.track('view_profile_creation_error')

      this.setState({errors})
    }
  }

  overrideProps() {
    let props = {}

    if (this.props.interstitial)
      props = {validator: null}

    return props
  }

  /**
   * This handles validating that zip code is valid if provided but not required
   * @returns {Object}
   */
  zipOrCountryValidator() {
    let props = {}

    if (this.props.interstitial) {
      props = {validator: (name, data) => {
        const pattern = /\d{5}(-\d{4})?/

        if (data.zip) {
          const trimData = {...data, zip: data.zip.trim()}

          return Validators.template(name, trimData, {zip: {presence: true, format: pattern}})
        }
      }}
    }

    return props
  }

  render() {
    return (
      <div className='profile-completion-form'>
        <div className='confirmation-container'>
          <img alt='Email address confirmed' src={greenCheckIcon} />
          <p className='email-confirmed'>Email address confirmed</p>
        </div>
        <h1 className='heading'>Let's finish setting up your account</h1>
        <p className='instructions'>First, please tell us <strong>a little bit about yourself</strong> so that we can make your experience with Everplans more personalized and meaningful.</p>
        <Forms.Form onSubmit={this.props.onSubmit} ref='form'>
          <Forms.Name name='name' className='name' defaultValue={this.props.name} />
          <Forms.DateInput label='Date of birth' ref='dob' name='birthdate' validator={Forms.Validators.birthdateValidator} />
          <Forms.ZipOrCountry name='zipOrCountry' defaultValue={{zip: this.props.zip}} {...this.zipOrCountryValidator()} />
          <Forms.PronounsInput {...this.overrideProps()} />
          <Forms.Wrapper errors={this.props.formErrors || this.formErrors()}>
            <Button className='btn-success' loading={this.props.processing} onClick={this.submitForm}>Continue</Button>
          </Forms.Wrapper>
        </Forms.Form>
      </div>
    )
  }
}

ProfileCompletionForm.propTypes = {
  formErrors: PropTypes.string,
  name: PropTypes.shape({first_name: PropTypes.string, last_name: PropTypes.string}),
  processing: PropTypes.bool,
  interstitial: PropTypes.bool,
  onSubmit: PropTypes.func,
  zip: PropTypes.string
}

export default ProfileCompletionForm
