import {Component} from 'react'

import PropTypes from 'prop-types'

import sessionUtils from '../../../../auth/src/lib/sessionUtils'
import SessionApi from '../../../../auth/src/web/SessionApi'
import ConfirmActions from '../../actions/ConfirmActions'
import DeclineDeputyInvite from './DeclineDeputyInvite'
import Faq from './Faq'
import Offers from './Offers'
import Container from '../../../../lib/Container'
import {presentLinkingVerb, legacyPersonalPronoun, presentPossessionVerb} from '../../../../lib/pronouns'
import {addPossessive} from '../../../../lib/tools'
import Forms from '../../../../shared_components/forms'
import Button from '../../../../shared_components/Button'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import LegacyRightRail from '../../../../shared_components/LegacyRightRail'
import MixpanelLogger from '../../../../lib/MixpanelLogger'

import './deputyConfirm.scss'

export default class DeputyConfirm extends Component {
  constructor() {
    super()

    this.store = Container.getStore('confirm')

    this.state = {errors: [], processing: false}

    this.logIn = this.logIn.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
  }

  componentDidMount() {
    MixpanelLogger.track('view_deputy_acceptance')
    this.store.addChangeListener(this.onConfirm)
  }

  componentWillUnmount() { this.store.removeChangeListener(this.onConfirm) }

  onConfirm() {
    const state = this.store.getState()

    if (state.errors.length < 1) {
      sessionUtils.authenticateAndStartSession(state.data.deputy.session_token, 'cm/deputy-for')
      // After a new deputy only user confirms their account, there's no user object event though the auth token set above is valid. Hence the call below
      SessionApi.fetchCurrentUser()
    } else {
      this.setState({
        errors: state.errors,
        processing: false
      })
    }
  }

  logIn() {
    MixpanelLogger.track('click_deputy_create_account')

    const errors = this.refs.form.validate()

    if (errors.length === 0) {
      this.setState({processing: true})
      ConfirmActions.confirmDeputy(
        Object.assign(
          this.refs.form.formData(),
          {invitation_token: this.props.params.deputyId}
        )
      )
    } else {
      this.setState({errors})
    }
  }

  formErrors() {
    if (this.state.errors.length > 0)
      return ['Please see errors above.']
  }

  render() {
    if (this.props.loadingDeputy)
      return <LegacyLoader loading={this.props.loadingDeputy} />

    const {household, inviter_first_name, ...data} = this.props.deputy.data
    const pronoun = household ? null : data.inviter_pronoun

    return (
      <div className='deputy-confirm'>
        <div>
          <div className='row-wrapper-sb'>
            <div className='deputy-welcome'>
              <h1>Welcome to Everplans, {data.first_name}</h1>
              <p>
                <span className='bold'>{data.inviter}</span> {presentLinkingVerb({plural: household})} getting organized using an app called Everplans.
              </p>
              <p>{inviter_first_name} added you as a collaborator on Everplans so {legacyPersonalPronoun(pronoun, 'subject')} can easily share information with you.</p>
              <p>
                Please choose a strong password to get started and see the information {inviter_first_name} {presentPossessionVerb({plural: household})} shared with you.
              </p>
              <Forms.Form ref='form' onSubmit={this.logIn}>
                <Forms.DateInput label='Date of birth' ref='dob' name='birthdate' validator={Forms.Validators.birthdateValidator} />
                <Forms.Password className='password-control' name='password' label='Set password' />
                <Forms.ServiceTerms name='agreement_accepted' />
                <Forms.Wrapper label=' ' className='password' errors={this.formErrors()}>
                  <Button className='btn-success' loading={this.state.processing} onClick={this.logIn}>
                      View {household ? 'their' : addPossessive(inviter_first_name)} Everplan
                  </Button>
                </Forms.Wrapper>
              </Forms.Form>
            </div>
            <LegacyRightRail />
          </div>
          <div>
            <DeclineDeputyInvite inviteId={data.invite_id} invitedByFirstName={inviter_first_name} token={this.props.params.deputyId} />
            <Faq subscriptionPrice={data.active_subscription_price} />
            <Offers />
          </div>
        </div>
      </div>
    )
  }
}

DeputyConfirm.onBoundUpdate = function (newBoundData) {
  if (newBoundData.errors && newBoundData.errors.length > 0)
    this.goToThere('/auth/invalid_token/deputy_invite')
  else
    this.setState({deputy: newBoundData, loading: false, loadingDeputy: false})
}

DeputyConfirm.contextTypes = {
  router: PropTypes.object
}

DeputyConfirm.propTypes = {
  loadingDeputy: PropTypes.bool.isRequired,
  params: PropTypes.shape({deputyId: PropTypes.string}),
  processing: PropTypes.bool,
  query: PropTypes.object,
  deputy: PropTypes.shape({
    data: PropTypes.shape({
      first_name: PropTypes.string,
      household: PropTypes.bool,
      invite_id: PropTypes.number,
      inviter: PropTypes.string,
      inviter_first_name: PropTypes.string,
      inviter_pronoun: PropTypes.object,
      last_name: PropTypes.string
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    msg: PropTypes.string
  })
}

DeputyConfirm.defaultProps = {
  loading: true,
  loadingDeputy: true,
  params: {deputyId: ''},
  processing: false,
  deputy: {
    data: {},
    errors: [],
    msg: ''
  }
}
