import PropTypes from 'prop-types'
import React from 'react'

import bindResource from '../../../../shared_components/BindToStores'
import Button from '../../../../shared_components/Button'
import Container from '../../../../lib/Container'
import Forms from '../../../../shared_components/forms'
import * as Links from '../../../../shared_components/Links'
import Loader from '../../../../shared_components/NewLoader'
import PageTitle from '../../../../shared_components/PageTitle'
import PasswordResetActions from '../../actions/PasswordResetActions'
import routerUtils from '../../../../lib/routerUtils'
import StorePrototype from '../../../../shared_components/StorePrototype'
import WidthContainer from '../../../../shared_components/WidthContainer'

import './resetPassword.scss'

export class ResetPassword extends React.Component {
  constructor(props) {
    super(props)

    this.changePassword = this.changePassword.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.state = {
      loading: false,
      processing: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reset_password_token_validation && nextProps.reset_password_token_validation.errors[0] === 'Invalid token') {
      this.setState({loading: true}) // Prevent flicker where form shows then transition happens
      routerUtils.push('/auth/invalid_token/reset_password')
    }
  }

  changePassword() {
    if (this.refs.form.validate().length === 0) {
      this.setState({processing: true})
      PasswordResetActions.changePassword(
        Object.assign(
          this.refs.form.formData(),
          {reset_password_token: this.props.params.password_reset_token}
        ),
        this.onPasswordChange
      )
    }
  }
  onPasswordChange() {
    var reset_password = this.props.reset_password ? this.props.reset_password : {}
    this.setState({processing: false})
    if (reset_password.errors.length === 0)
      this.context.router.push({pathname: '/auth/password/reset-complete'})
  }

  render() {
    var errors = this.props.reset_password ? this.props.reset_password.errors : []
    return (
      <div className='reset-password'>
        <PageTitle title='Reset Password' />
        <Loader loading={this.props.loadingResetPasswordTokenValidation || this.state.loading}>
          <WidthContainer>
            <h1>Please reset your password.</h1>
            <Forms.Form onSubmit={this.changePassword} ref='form' errors={this.props.errors} name='password-form'>
              <Forms.Password className='password-control' name='password' label='Choose a new password' />
            </Forms.Form>
            <Forms.Wrapper label=' ' errors={errors}>
              <Button className='btn-success' loading={this.state.processing} onClick={this.changePassword}>Change my password</Button>
            </Forms.Wrapper>
          </WidthContainer>
          <div className='support-links'>
            Need Support?<Links.Help>Everplans Help Center</Links.Help>
          </div>
        </Loader>
      </div>
    )
  }
}

ResetPassword.defaultProps = {
  errors: [],
  reset_password: {
    data: {},
    errors: []
  }
}

ResetPassword.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  reset_password_token_validation: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.string)
  }),
  reset_password: PropTypes.shape({
    data: PropTypes.shape({
      email: PropTypes.string
    }),
    errors: PropTypes.arrayOf(PropTypes.string)
  }),
  loadingResetPasswordTokenValidation: PropTypes.bool,
  params: PropTypes.shape({
    password_reset_token: PropTypes.string
  }).isRequired
}
ResetPassword.contextTypes = {router: PropTypes.object}

Container.registerAction('reset_password_token_validation', PasswordResetActions)
Container.registerStore('reset_password', StorePrototype([PasswordResetActions.Types.DID_CHANGE_PASSWORD]))
export default bindResource(ResetPassword, {id: 'password_reset_token', name: 'reset_password_token_validation', type: 'item'})
