import classnames from 'classnames'
import PropTypes from 'prop-types'
import {useEffect} from 'react'

import AddFirmUser from './AddFirmUser'
import HorizontalList from '../shared_firm/HorizontalList'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import FirmHeader from '../shared_firm/FirmHeader'
import FirmNav from '../shared_firm/FirmNav'
import moment from 'moment'
import SeatsTable from './seats_table/SeatsTable'
import ShadowWrapper from '../shared_firm/ShadowWrapper'

import FirmActions from '../../actions/FirmActions'
import firmHeaderProps from '../../lib/firmHeaderPropsHelper'
import storeWrapper from '../../../../shared_components/storeWrapper'
import {usePrevious} from '../../../../lib/hooks'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import './firmUsers.scss'


const FirmUsers = props => {
  const prevFirmUserInvited = usePrevious(props.firmUserInvited)
  const {isDifferent} = useNotificationContext()

  useEffect(() => {
    isDifferent(prevFirmUserInvited.data, props.firmUserInvited.data).longSuccess(
      <span>
        <a href={`${WEBAPPS_HOST}/#/pro/firm/${props.firmUserInvited.data.firm_id}/user/${props.firmUserInvited.data.seat_id}/account-info`}>
          {props.firmUserInvited.data.name}
        </a> was added.
      </span>
    )
    isDifferent(prevFirmUserInvited.errors, props.firmUserInvited.errors).longError("We're sorry, but something went wrong while adding that user. Please try again.")
  }, [props.firmUserInvited])

  return (
    <div>
      <FirmHeader {...firmHeaderProps(props)}>
        <FirmNav firmId={props.params.firmId} isAccountManager={props.currentUser.account_manager} />
      </FirmHeader>
      <LegacyLoader loading={props.loadingFirmUsers}>
        <section className='firm-users'>
          {props.firm_users.data.has_active_license &&
              <div>
                <FirmUserSection
                  accountManager={props.currentUser.account_manager}
                  data={props.firm_users.data.advisors}
                  firmId={props.params.firmId}
                  userType={props.firm_config.data.professional_designation}
                />
                <FirmUserSection
                  accountManager={props.currentUser.account_manager}
                  data={props.firm_users.data.assistants}
                  firmId={props.params.firmId}
                  userType='Assistant'
                />
              </div>
          }
          {!props.firm_users.data.has_active_license && props.firm.data.active &&
              <p className='note'>No active licenses found.  Please contact your account manager.</p>
          }
          {!props.firm.data.active && <p className='note'>Downgraded on {moment(props.firm.data.last_updated_at).format('MM/DD/YYYY')}.</p>}
        </section>
      </LegacyLoader>
    </div>
  )
}

FirmUsers.defaultProps = {
  currentUser: {},
  firm_users: {
    data: {},
    errors: [],
    message: null
  },
  loadingFirmUsers: true
}

FirmUsers.propTypes = {
  currentUser: PropTypes.object,
  firm: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.bool,
      last_updated_at: PropTypes.string
    })
  }).isRequired,
  firmUserInvited: PropTypes.object,
  firm_config: PropTypes.object,
  firm_users: PropTypes.shape({
    data: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  loadingFirmUsers: PropTypes.bool.isRequired,
  organizations: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object])
  }),
  params: PropTypes.object
}

export default storeWrapper(
  FirmUsers,
  [{actionTypes: [FirmActions.Types.DID_ADD_FIRM_USER], name: 'firmUserInvited'}]
)

const FirmUserSection = props => {
  const noSeatsRemaining = props.data.seat_data.seats_remaining === 0

  return (
    <section className={classnames('firm-user-section', props.userType.toLowerCase())}>
      <AddFirmUser userType={props.userType} firmId={props.firmId} noSeatsRemaining={noSeatsRemaining} />
      {noSeatsRemaining && <p className='note'>To add seats to your license, please contact your account manager.</p>}
      <ShadowWrapper>
        <SeatDataList data={props.data.seat_data} />
        <section className='seat-table'>
          {
            props.data.seat_data.seats_used > 0 ?
              <SeatsTable accountManager={props.accountManager} firmId={props.firmId} seats={props.data.seats} userType={props.userType} /> :
              <p className='note'>No {`${props.userType.toLowerCase()}s`} have been added.</p>
          }
        </section>
      </ShadowWrapper>
    </section>
  )
}

const SeatDataList = props => (
  <HorizontalList className='seat-data'>
    <span>{props.data.seats_purchased} {`Seat${props.data.seats_purchased === 1 ? '' : 's'}`} Purchased</span>
    <span>{props.data.seats_used} Used</span>
    <span>{props.data.seats_remaining} Remaining</span>
  </HorizontalList>
)
