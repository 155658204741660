import PropTypes from 'prop-types'
import {Map} from 'immutable'

import JiclPreviewResponseGroupContainer from './sections/JiclPreviewResponseGroupContainer'
import {SECTION_ORDER} from '../../lib/jiclMainSectionConfiguration'
import JiclContactInformation from './sections/JiclContactInformation'
import JiclAdditionalInformation from './sections/JiclAdditionalInformation'

import './jiclPreview.scss'

const JiclRecipientView = props => (
  <div className='jicl-recipient-view'>
    <JiclContactInformation
      userName={props.jiclConfig.get('first-name')}
      lastUpdatedAt={props.jiclConfig.get('updated-at')}
    />
    {SECTION_ORDER.map(section => (
      <JiclPreviewResponseGroupContainer
        {...props}
        key={section}
        section={section}
      />
    ))}
    <JiclAdditionalInformation jiclConfig={props.jiclConfig} />
  </div>
)

JiclRecipientView.propTypes = {
  jiclConfig: PropTypes.instanceOf(Map)
}

export default JiclRecipientView
