import OrionLogo from '../pro/assets/images/integration_logos/orion.png'
import RedtailLogo from '../pro/assets/images/integration_logos/redtail.svg'
import SymmetryLogo from '../pro/assets/images/integration_logos/symmetry.png'
import NorthwesternMutualLogo from '../pro/assets/images/integration_logos/northwestern-mutual.png'

export const integrationLogo = integration => {
  switch (integration) {
    case 'orion':
      return OrionLogo
    case 'redtail':
      return RedtailLogo
    case 'symmetry':
      return SymmetryLogo
    case 'northwestern mutual':
      return NorthwesternMutualLogo
    default:
      return null
  }
}

export const isNorthwesternMutualOrganization = organization =>
  organization && organization.data.name.toLowerCase() === 'northwestern mutual'

export const dasherize = params => Object.keys(params).reduce(
  (accumulator, key) => ({...accumulator, [key.replace('_', '-')]: params[key]}),
  {}
)
