import Header from './header/Header'
import Footer from './footer/Footer'
import PageTitle from '../../../shared_components/PageTitle'

import AuthGateway from '../../../shared_components/AuthGateway'

import GlobalAnnouncementBanner from '../../../dashboard/src/components/feedback/GlobalAnnouncementBanner'

import './custom_content/contactCard.scss' // TODO: figure out what styles from this old file are still required. --BLR
import './proApp.scss'

const ProApp = props => (
  <div className='pro'>
    <GlobalAnnouncementBanner />
    <div className='wrapper'>
      <PageTitle title='Everplans Pro' />
      <Header />
      <AuthGateway location={props.location} whitelist={['/pro/confirm_invite']} needsFirmUser={true}>
        <main>
          {props.children}
        </main>
      </AuthGateway>
      <div className='push' />
    </div>
    <Footer whitelist='/pro/confirm_invite' />
  </div>
)


export default ProApp
