import { onBlur, onFocus } from './lib/tools'


const FormSideLabels = () => (
  <article>
    <h2>Basic Form Elements - Side Labels</h2>
    <article>
      <h3>Input Text</h3>
      <form className='core side-labels'>
        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Legend text</legend>
            <div className='controls-flexbox flex-container'>
              <label className='flex-child placeholder' data-placeholder='Placeholder text'>
                <span className='label-text visually-hidden'>Label text</span>
                <input name='input-text-1' type='text' onBlur={onBlur} onFocus={onFocus} />
                <div className='error-tex visually-hidden'>Error message</div>
              </label>
              <label className='error flex-child placeholder' data-placeholder='Placeholder error text'>
                <span className='label-text visually-hidden'>Label text</span>
                <input name='input-text-2' type='text' onBlur={onBlur} onFocus={onFocus} />
                <div className='error-text visually-hidden'>Error message</div>
              </label>
              <label className='flex-child placeholder' data-placeholder='Placeholder disabled text'>
                <span className='label-text visually-hidden'>Label text</span>
                <input disabled name='input-text-3' type='text' onBlur={onBlur} onFocus={onFocus} />
                <div className='error-text visually-hidden'>Error message</div>
              </label>
            </div>
          </div>
        </fieldset>
      </form>
    </article>

    <article>
      <h3>Select Controls</h3>
      <form className='core side-labels'>
        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Legend text</legend>
            <div className='controls-flexbox flex-container'>
              <label className='flex-child select'>
                <span className='label-text visually-hidden'>Select option</span>
                <select name='select-option-1'>
                  <option disabled selected>Select option</option>
                  <option value='1'>Option one</option>
                  <option value='2'>Option two</option>
                  <option value='3'>Option three</option>
                </select>
                <div className='error-text'>Error message</div>
              </label>
              <label className='error flex-child select'>
                <span className='label-text visually-hidden'>Select error option</span>
                <select name='select-option-2'>
                  <option disabled selected>Select error option</option>
                  <option value='1'>Option one</option>
                  <option value='2'>Option two</option>
                  <option value='3'>Option three</option>
                </select>
                <div className='error-text'>Error message</div>
              </label>
              <label className='disabled flex-child select'>
                <span className='label-text visually-hidden'>Select disabled option</span>
                <select name='select-option-3' disabled>
                  <option disabled selected>Select disabled option</option>
                  <option value='1'>Option one</option>
                  <option value='2'>Option two</option>
                  <option value='3'>Option three</option>
                </select>
                <div className='error-text'>Error message</div>
              </label>
            </div>
          </div>
        </fieldset>
      </form>
    </article>

    <article>
      <h3>Textarea</h3>
      <form className='core side-labels'>
        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Notes</legend>
            <div className='controls-flexbox flex-container'>
              <label className='flex-child placeholder-hide-focus' data-placeholder='Notes for yourself...'>
                <span className='label-text visually-hidden'>Notes</span>
                <textarea name='notes-textarea' onBlur={onBlur} onFocus={onFocus} />
                <div className='error-text'>Error message</div>
              </label>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Notes</legend>
            <div className='controls-flexbox flex-container'>
              <label className='error flex-child placeholder-hide-focus' data-placeholder='Notes for yourself...'>
                <span className='label-text visually-hidden'>Notes</span>
                <textarea name='notes-textarea' onBlur={onBlur} onFocus={onFocus} />
                <div className='error-text'>Error message</div>
              </label>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Notes</legend>
            <div className='controls-flexbox flex-container'>
              <label className='flex-child placeholder-hide-focus' data-placeholder='Notes for yourself...'>
                <span className='label-text visually-hidden'>Notes</span>
                <textarea disabled name='notes-textarea' onBlur={onBlur} onFocus={onFocus} />
                <div className='error-text'>Error message</div>
              </label>
            </div>
          </div>
        </fieldset>
      </form>
    </article>

    <article>
      <h3>Radio Controls</h3>
      <form className='core side-labels'>
        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Radio legend</legend>
            <div className='controls-flexbox flex-container radio-form-group'>
              <label className='flex-child'>
                <input name='radio-select-1' type='radio' value='radio-1' onBlur={onBlur} onFocus={onFocus} />
                <span className='label-text'>Radio 1</span>
              </label>
              <label className='flex-child'>
                <input name='radio-select-1' type='radio' value='radio-2' onBlur={onBlur} onFocus={onFocus} />
                <span className='label-text'>Radio 2</span>
              </label>
              <label className='flex-child'>
                <input name='radio-select-1' type='radio' value='radio-3' onBlur={onBlur} onFocus={onFocus} />
                <span className='label-text'>Radio 3</span>
              </label>
            </div>
            <div className='error-text'>Error message</div>
          </div>
        </fieldset>

        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Radio error</legend>
            <div className='controls-flexbox error flex-container radio-form-group'>
              <label className='flex-child'>
                <input name='radio-select-2' type='radio' value='radio-1' />
                <span className='label-text'>Radio 1</span>
              </label>
              <label className='flex-child'>
                <input name='radio-select-2' type='radio' value='radio-2'/>
                <span className='label-text'>Radio 2</span>
              </label>
              <label className='flex-child'>
                <input name='radio-select-2' type='radio' value='radio-3' />
                <span className='label-text'>Radio 3</span>
              </label>
            </div>
            <div className='error-text'>Error message</div>
          </div>
        </fieldset>

        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Radio disabled</legend>
            <div className='controls-flexbox flex-container radio-form-group'>
              <label className='flex-child'>
                <input disabled name='radio-select-3' type='radio' value='radio-1'/>
                <span className='label-text'>Radio 1</span>
              </label>
              <label className='flex-child'>
                <input disabled name='radio-select-3' type='radio' value='radio-2'/>
                <span className='label-text'>Radio 2</span>
              </label>
              <label className='flex-child'>
                <input disabled name='radio-select-3' type='radio' value='radio-3' />
                <span className='label-text'>Radio 3</span>
              </label>
            </div>
            <div className='error-text'>Error message</div>
          </div>
        </fieldset>
      </form>
    </article>

    <article>
      <h3>Checkbox Controls</h3>
      <form className='core side-labels'>
        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Legend text</legend>
            <div className='controls-flexbox flex-container checkbox-form-group'>
              <label className='flex-child'>
                <input name='checkbox-group' type='checkbox' value='checkbox-1' />
                <span className='label-text'>Checkbox 1</span>
              </label>
              <label className='flex-child'>
                <input name='checkbox-group' type='checkbox' value='checkbox-2' />
                <span className='label-text'>Checkbox 2</span>
              </label>
              <label className='flex-child'>
                <input name='checkbox-group' type='checkbox' value='checkbox-3' />
                <span className='label-text'>Checkbox 3</span>
              </label>
            </div>
            <div className='error-text'>Error message</div>
          </div>
        </fieldset>

        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Legend text</legend>
            <div className='controls-flexbox error flex-container checkbox-form-group'>
              <label className='flex-child'>
                <input name='checkbox-group' type='checkbox' value='checkbox-1' />
                <span className='label-text'>Checkbox 1</span>
              </label>
              <label className='flex-child'>
                <input name='checkbox-group' type='checkbox' value='checkbox-2' />
                <span className='label-text'>Checkbox 2</span>
              </label>
              <label className='flex-child'>
                <input name='checkbox-group' type='checkbox' value='checkbox-3' />
                <span className='label-text'>Checkbox 3</span>
              </label>
            </div>
            <div className='error-text'>Error message</div>
          </div>
        </fieldset>

        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Legend text</legend>
            <div className='controls-flexbox flex-container checkbox-form-group'>
              <label className='flex-child'>
                <input disabled name='checkbox-group' type='checkbox' value='checkbox-1' />
                <span className='label-text'>Checkbox 1</span>
              </label>
              <label className='flex-child'>
                <input disabled name='checkbox-group' type='checkbox' value='checkbox-2' />
                <span className='label-text'>Checkbox 2</span>
              </label>
              <label className='flex-child'>
                <input disabled name='checkbox-group' type='checkbox' value='checkbox-3' />
                <span className='label-text'>Checkbox 3</span>
              </label>
            </div>
            <div className='error-text'>Error message</div>
          </div>
        </fieldset>
      </form>
    </article>

    <article>
      <h3>Name Group</h3>
      <form className='core side-labels'>
        <fieldset>
          <div className='fieldset-flexbox'>
            <legend>Name</legend>
            <div className='controls-flexbox flex-container name-form-group'>
              <label className='first-name flex-child placeholder' data-placeholder='First'>
                <span className='label-text visually-hidden'>First name</span>
                <input name='first-name' type='text' onFocus={onFocus} onBlur={onBlur} />
              </label>
              <label className='flex-child middle-initial placeholder' data-placeholder='MI'>
                <span className='label-text visually-hidden'>Middle Initial</span>
                <input name='middle-initial' type='text' onFocus={onFocus} onBlur={onBlur} />
              </label>
              <label className='flex-child last-name placeholder' data-placeholder='Last'>
                <span className='label-text visually-hidden'>Last name</span>
                <input name='last-name' type='text' onFocus={onFocus} onBlur={onBlur} />
              </label>
              <div className='error-text'>Error message</div>
            </div>
          </div>
        </fieldset>
      </form>
    </article>
  </article>
)

export default FormSideLabels
