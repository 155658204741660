import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import DeputyFeedbackHeader from './views/DeputyFeedbackHeader'
import {TextGutterMedium} from '../../../dashboard/src/components/blocks/Texts'

import {cleanResponse} from '../../../dashboard/src/components/corp_max/redux/apiHelper'

import paperPlane from '../../../shared_assets/paper_plane.png'

import './deputyFeedbackDone.scss'

export const DeputyFeedbackDone = ({deputyFeedback}) => (
  <div className='deputy-feedback-done'>
    <DeputyFeedbackHeader />
    <img src={paperPlane} alt='paper-plane' className='paper-plane' />
    <TextGutterMedium className='message'>Thanks! We'll let {deputyFeedback.get('planholder-name')} know.</TextGutterMedium>
  </div>
)

DeputyFeedbackDone.propTypes = {deputyFeedback: PropTypes.instanceOf(Map)}

const mapStateToProps = ({api}) => ({deputyFeedback: cleanResponse(api['deputy-feedbacks']).first() || Map()})

export default connect(mapStateToProps)(DeputyFeedbackDone)
