import {useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import Loader from '../../../../shared_components/NewLoader'
import SelectAdvisor from './views/SelectAdvisor'

import routerUtils from '../../../../lib/routerUtils'

const VALID_BULK_INVITE_TYPES = ['draft', 'pending']

const SelectAdvisorController = props => {
  const selectClientsPath = advisorSeatId => `pro/clients/bulk-actions/select-clients/${advisorSeatId}/${props.params.type}`
  const invalidPath = !VALID_BULK_INVITE_TYPES.includes(props.params.type)

  useEffect(() => {
    if (invalidPath)
      routerUtils.push('404')
    else if (!props.loading && props.firmSeats.size === 1)
      routerUtils.push(selectClientsPath(props.firmSeats.getIn([0, 'id'])))
  }, [props.loading, props.firmSeats.size, props.params.type])

  const onContinue = useCallback(advisorSeatId => {
    routerUtils.push(selectClientsPath(advisorSeatId))
  }, [])

  return (
    <Loader loading={props.loading || invalidPath}>
      <SelectAdvisor {...props} onContinue={onContinue} />
    </Loader>
  )
}

SelectAdvisorController.propTypes = {
  clientSeats: PropTypes.instanceOf(List),
  firmConfig: PropTypes.instanceOf(Map),
  firmSeats: PropTypes.instanceOf(List),
  params: PropTypes.shape({type: PropTypes.string})
}

export default SelectAdvisorController
