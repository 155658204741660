import {useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import {Map} from 'immutable'

import ClientInvitesPreview from './views/ClientInvitesPreview'

import './emailPreviews.scss'

const EmailPreviews = props => {
  const [showPreview, setShowPreview] = useState(false)

  const togglePreview = useCallback(() => setShowPreview(!showPreview), [showPreview])

  return (
    <div className='email-previews'>
      {
        !props.isHighCompliance && (
          <a onClick={togglePreview} className='show-preview-link'>
            <i className={`fa fa-caret-${(showPreview ? 'down' : 'right')}`} />
            Preview the information Everplans will automatically include in the invitation
          </a>
        )
      }
      <ClientInvitesPreview {...props} showPreview={showPreview || props.isHighCompliance} />
    </div>
  )
}

EmailPreviews.propTypes = {
  clientInvite: PropTypes.instanceOf(Map),
  isHighCompliance: PropTypes.bool
}

export default EmailPreviews
