import {Fragment, useState, useCallback, useEffect} from 'react'
import {connect} from 'react-redux'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {createResource} from 'redux-json-api'

import JiclHeader from './JiclHeader'
import JiclMainSectionContainer from './sections/JiclMainSectionContainer'
import JiclIntroContainer from './sections/JiclIntroContainer'
import JiclOutroContainer from './sections/JiclOutroContainer'

import {SECTION_ORDER} from '../lib/jiclMainSectionConfiguration'
import Logger from '../../../lib/NewLogger'
import {parseUtmParams} from '../../../lib/partner'

const numberOfScreens = SECTION_ORDER.size + 2

const JiclWizardController = props => {
  const {content, campaign, term, source, medium} = parseUtmParams(props.location.query)
  const [step, setStep] = useState(0)
  const [jiclData, setJiclData] = useState(Map())

  const submitJustEmail = data => {
    const resource = {
      type: 'acquisition-emails',
      attributes: {
        name: data.get('name'),
        'email-address': data.get('email')
      }
    }
    return props.createResource(resource)
  }

  const onStepChange = useCallback(
    (newjiclData = null) => {
      if (step === 0) {
        Logger.log({name: 'start_jicl'})
        submitJustEmail(newjiclData)
      }

      if (newjiclData) setJiclData(jiclData.merge(newjiclData))

      setStep(step + 1)
    },
    [jiclData, step]
  )

  const submitJiclData = () => {
    const marketingParams = Map({content, campaign, term, source, medium}).filter(value => value) // remove utms that are undefined

    const resource = {
      type: 'jicl-users',
      attributes: {
        // only add the marketing params if there are utm params that are not undefined - Atanda
        ...(!marketingParams.isEmpty() && {'marketing-params': marketingParams.toJS()}),
        responses: jiclData.get('responses', List()).toJS(),
        assessment: jiclData.get('assessments', Map()).toJS(),
        guides: jiclData.get('guides', Map()).toJS(),
        'first-name': jiclData.get('name'),
        email: jiclData.get('email'),
        'additional-info': jiclData.get('additional-info')
      }
    }
    return props.createResource(resource)
  }

  const sharedProps = () => ({onStepChange, numberOfScreens})

  useEffect(
    () => { if (step === 3) submitJiclData() },
    [step]
  )

  return (
    <Fragment>
      <JiclHeader />
      <div className='jicl-wizard-controller forms-playground'>
        {step === 0 && <JiclIntroContainer onStepChange={onStepChange} />}
        {step === 1 && <JiclMainSectionContainer {...sharedProps()} />}
        {step >= 2 && <JiclOutroContainer {...sharedProps()} email={jiclData.get('email')} createResource={props.createResource} />}
      </div>
    </Fragment>
  )
}

JiclWizardController.propTypes = {
  createResource: PropTypes.func,
  location: PropTypes.shape({query: PropTypes.object})
}

export default connect(null, {createResource})(JiclWizardController)
