import PropTypes from 'prop-types'
import {Map} from 'immutable'

import JiclEditAdditionalInformationContainer from '../sections/sub_sections/JiclEditAdditionalInformationContainer'
import JiclPreviewEmptySection from '../sections/sub_sections/JiclPreviewEmptySection'
import {oneFingerUp} from '../images'
import {ADDITIONAL_INFO} from '../../../lib/jiclMainSectionConfiguration'

import './jiclAdditionalInformation.scss'

const JiclAdditionalInformation = ({jiclConfig, user, updateResource}) => {
  const additionalInformation = jiclConfig.getIn(['data', 'additional-info'])

  if (!user && !additionalInformation) return null

  return (
    <div className='jicl-additional-information'>
      <div className='section-header'>
        <img src={oneFingerUp} alt='scroll-icon' />
        <h5>{ADDITIONAL_INFO.get('viewIntroText')}</h5>
      </div>
      {
        additionalInformation ?
          (
            <div className='section-body'>
              {additionalInformation}
            </div>
          ) :
          <JiclPreviewEmptySection />
      }
      {user && <JiclEditAdditionalInformationContainer jiclConfig={jiclConfig} updateResource={updateResource} />}
    </div>
  )
}

JiclAdditionalInformation.propTypes = {
  jiclConfig: PropTypes.instanceOf(Map),
  user: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func
}

export default JiclAdditionalInformation
