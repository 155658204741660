import PropTypes from 'prop-types'
import {wholeOrRoundCentsToDollars} from '../../../../lib/subscriptionTools'

const Faq = ({subscriptionPrice = 7500}) => (
  <div className='faq'>
    <h3>Frequently asked questions</h3>
    <h5 className='faq-subheader'>What is an Everplan?</h5>
    <p>
      An Everplan is a secure, digital archive of everything your loved ones will need should something happen to you.
      An Everplan contains legal documents, account information, important passwords,
      end-of-life wishes, information about your house, emergency contacts, health information, and more.
    </p>
    <h5 className='faq-subheader'>What is a deputy?</h5>
    <p>
      A deputy is a trusted individual who has access to some or all of someone's Everplan.
    </p>
    <h5 className='faq-subheader'>Why must I create a password?</h5>
    <p>
      Everplans takes security very seriously, and we want to make sure that only authorized users
      have access to someone's Everplan. By choosing a password, you are creating login
      credentials so that you can return to view someone's Everplan—or create your own—at a later date.
    </p>
    <h5 className='faq-subheader'>How secure is Everplans?</h5>
    <p>
      Everplans uses bank-level AES-256 encryption, SSL using 2048-bit certificates and other industry-leading security technology to guard data.
      We encrypt every single personally identifiable field in the database, including your name and email address,
      and we regularly audit our environments and code for security issues.
    </p>
    <h5 className='faq-subheader'>How much does it cost?</h5>
    <p>
      Everplans is free for deputies. Everplans Premium is available for {wholeOrRoundCentsToDollars({number: subscriptionPrice})}/year to those who would like to add an unlimited number of items and get additional guidance.
    </p>
  </div>
)

Faq.propTypes = {
  subscriptionPrice: PropTypes.number
}

export default Faq
