import PropTypes from 'prop-types'
import {useState, useCallback, useEffect, useRef, Fragment} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import {readEndpoint} from 'redux-json-api'


import Button from '../../../../shared_components/Button'
import * as Links from '../../../../shared_components/Links'
import SignInActions from '../../actions/SignInActions'
import Modal from '../../../../shared_components/Modal'
import Loader from '../../../../shared_components/NewLoader'

import {currentUserConfig} from '../../../../lib/userTools'
import preAuthHook from '../../lib/preAuthHook'
import {railsUrl, tfaUrl} from '../../../../lib/urlTools'
import routerUtils from '../../../../lib/routerUtils'
import SessionStore from '../../stores/SessionStore'
import {findRawResourceByAttributeId, findRawResourceById} from '../../../../lib/plan_data/userData'

const TfaBackupSignIn = (props, context) => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState()
  const session = SessionStore.getState()
  const inputRef = useRef()

  const clearErrors = useCallback(() => setError(null))

  const onUpdate = useCallback(() => {
    const {currentUser} = session

    if (session.signInError) {
      setLoading(false)
      setError(session.signInError)
    } else if (session.signedInWithBackup) {
      // Prevent 500 when non-client user tries to read this endpoint and also make sure there's a current user data
      props.readEndpoint('user-configs')

      if (currentUser.client)
        props.readEndpoint(`everplans/${session.currentUser.everplan.id}?include=firm.organization.organization-config,firm-ownership`)
      else if (currentUser.firm_user)
        props.readEndpoint(`firms/${currentUser.firm}?include=client-seats,firm-config,disclosure,firm-seats,organization.organization-config`)

      setShowModal(true)
      setLoading(false)
    }
  }, [session])

  const goToTfaSettings = useCallback(() => routerUtils.setLocation(tfaUrl), [session])

  const forwardSignedInUser = useCallback(() => routerUtils.setLocation(railsUrl(session.nextPath || '/')), [session])

  const submitBackupCode = useCallback(() => {
    if (inputRef.current) {
      const email = session.preAuthEmail
      const password = session.preAuthPassword
      const code = inputRef.current.value.trim()

      if (code) {
        if (email && password) {
          setLoading(true)
          SignInActions.submitBackupCode(email, password, code)
        } else {
          context.router.push('auth/sign_in')
        }
      } else {
        setError('Enter your backup code.')
      }
    }
  }, [session])

  useEffect(() => {
    SessionStore.addChangeListener(onUpdate)
    preAuthHook()

    return () => {
      SessionStore.removeChangeListener(onUpdate)
    }
  }, [])

  return (
    <div>
      <div className='login-div'>
        <div className='login-div-align'>
          <h1>Enter Backup Code</h1>
          <div className='tfa-code-instructions'>
            <span className='ep-login-alternate-text'>
              Please enter your 16-digit backup code you were given
              when you enrolled in Two-Step Verification {session.lastEnabledAt && `on ${session.lastEnabledAt}.`}
            </span>
          </div>
          <form className='tfa-code-form'>
            <input ref={inputRef} className='tfa-code-input' onFocus={clearErrors} placeholder='16-digit backup code' />
            <Button className='tfa-alt-sign-in-btn-primary' onClick={submitBackupCode} loading={loading}>Submit</Button>
            <Button className='tfa-alt-sign-in-btn-secondary' onClick={context.router.goBack}>Cancel</Button>
            <div className='error'>{error}</div>
          </form>
          <Modal visible={showModal}>
            {
              props.loading ?
                <Loader loading /> :
                <Fragment>
                  <h3>Security Notice</h3>
                  <p>
                    For security reasons, a backup code can only be used once to log into an Everplans account.
                    Your Two-Step Verification has been disabled until you turn it back on and generate a new backup code.
                  </p>
                  <Button onClick={goToTfaSettings}>Turn on two-step verification again</Button>
                  {!props.organizationConfig.get('mandatory-tfa-enabled') && <a className='continue-link' onClick={forwardSignedInUser}>Continue without it »</a>}
                </Fragment>
            }
          </Modal>
        </div>
      </div>
      <div className='contained'>
        <div className='support-links'>
          Need Support?
          <Links.Help>Everplans Help Center</Links.Help>
        </div>
      </div>
    </div>
  )
}

TfaBackupSignIn.contextTypes = {router: PropTypes.object}

TfaBackupSignIn.propTypes = {
  userConfig: PropTypes.instanceOf(Map),
  organizationConfig: PropTypes.instanceOf(Map),
  loading: PropTypes.bool,
  readEndpoint: PropTypes.func
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)

  const firmOwnership = findRawResourceByAttributeId({
    rawResource: state.api['firm-ownerships'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })

  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: firmOwnership.get('ownable-id') || userConfig.get('firm-id')
  })

  const organizationConfig = findRawResourceByAttributeId({
    rawResource: state.api['organization-configs'],
    attribute: 'organization-id',
    id: firm.get('organization-id')
  })

  return {
    userConfig,
    organizationConfig,
    loading: state.api.isReading > 0
  }
}

export default connect(mapStateToProps, {readEndpoint})(TfaBackupSignIn)
