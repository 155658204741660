import PropTypes from 'prop-types'
import React from 'react'

import showInputIcon from '../shared_assets/v2/unmask-icon.svg'
import hideInputIcon from '../shared_assets/v2/mask-icon.svg'

import './passwordInput.scss'

export default class PasswordInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showPassword: false
    }

    this.toggleMask = this.toggleMask.bind(this)
    this.type = this.type.bind(this)
  }

  toggleMask(event) {
    event.preventDefault()

    this.setState({showPassword: !this.state.showPassword})
  }

  inputValue() {
    return this.refs.input.value.trim()
  }

  type() {
    return this.state.showPassword ? 'text' : 'password'
  }

  render() {
    return (
      <div className='password-input'>
        <Label label={this.props.label} />
        <input {...this.props} ref='input' type={this.type()} />
        <button className='mask-icon-and-text' onClick={this.toggleMask} type='button'>
          <img alt='' className='mask-icon' src={this.state.showPassword ? hideInputIcon : showInputIcon} />
          {this.state.showPassword ? 'Hide' : 'Show'}
        </button>
      </div>
    )
  }
}

PasswordInput.propTypes = {
  label: PropTypes.string
}

class Label extends React.Component {
  render() {
    if (!this.props.label) return null
    return <label>{this.props.label}</label>
  }
}

Label.propTypes = {
  label: PropTypes.string
}

