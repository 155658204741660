import LegacyActionFactory from '../shared_components/LegacyActionFactory'

const DisclosureActions = new LegacyActionFactory(['GOT_DISCLOSURE', 'DID_UPDATE_DISCLOSURE'])

DisclosureActions.fetchDisclosure = firmId => {
  DisclosureActions.fireApi(
    'get',
    `/firms/${firmId}/disclosures`,
    null,
    {
      JSONHead: 'disclosure',
      successAction: DisclosureActions.Types.GOT_DISCLOSURE
    }
  )
}


DisclosureActions.updateDisclosure = data => {
  DisclosureActions.fireApi(
    'patch',
    `/firms/${data.firmId}/disclosures`,
    data.data,
    {
      JSONHead: 'disclosure',
      successAction: DisclosureActions.Types.DID_UPDATE_DISCLOSURE
    }
  )
}

export default DisclosureActions
