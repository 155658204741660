import {Component} from 'react'
import {connect} from 'react-redux'
import {readEndpoint} from 'redux-json-api'
import PropTypes from 'prop-types'

import OrganizationsTableHeader from '../OrganizationsTableHeader'
import OrganizationsTable from './OrganizationsTable'

import {cleanApiResponseData} from '../../../../../lib/tools'
import {webAppsUrl} from '../../../../../lib/urlTools'
import {filteredValueQuery, pageNumberAndSize} from '../../../../../lib/summariesTableTools'

class OrganizationsTableContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {loadingOrganizations: true, organizations: [], pages: -1}

    this.fetchOrganizations = this.fetchOrganizations.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
  }

  readEndpointQuery(tableState) {
    return tableState ?
      `${pageNumberAndSize(tableState)}${this.sortValue(tableState)}${filteredValueQuery(tableState)}` :
      'sort=name'
  }

  fetchOrganizations(tableState) {
    this.setState({loadingOrganizations: true})

    this.props.readEndpoint(`organizations?include=catch-all-firm&${this.readEndpointQuery(tableState)}`).then(response => this.onOrganizationsDataFetched(response))
  }

  onOrganizationsDataFetched(response) {
    this.setState({
      loadingOrganizations: false,
      /**
        Need to get the data directly from the response, because the `redux-json-api` library just appends newly fetched data to already
        existing data, therefore if we use `mapStateToProps` the data will not be filtered or sorted correctly.
      */
      organizations: cleanApiResponseData(response.body.data),
      pages: response.body.meta['page-count']
    })
  }

  sortValue(tableState) {
    const sorted = tableState.sorted

    if (sorted.length)
      // Needed to account for multiple filters -- ZD
      return `&sort=${tableState.sorted[0].desc ? '-' : ''}${tableState.sorted[0].id}`
    else
      return ''
  }

  onRowClick(organizationId) {
    window.open(webAppsUrl(`pro/organization-admin/organizations/${organizationId}/overview`), '_blank').focus()
  }

  render() {
    return (
      <div>
        <OrganizationsTableHeader loadingOrganizations={this.state.loadingOrganizations} fetchOrganizations={this.fetchOrganizations} />
        <OrganizationsTable {...this.state} {...this.props} onFetchData={this.fetchOrganizations} onRowClick={this.onRowClick} />
      </div>
    )
  }
}

OrganizationsTableContainer.propTypes = {readEndpoint: PropTypes.func}

const mapStateToProps = ({api}) => ({firms: cleanApiResponseData(api.firms.data)})

export default connect(mapStateToProps, {readEndpoint})(OrganizationsTableContainer)
