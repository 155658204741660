import React from 'react'
import AuthGateway from '../../../../shared_components/AuthGateway'

import '../shared_firm/firm.scss'

export default class FirmAdmin extends React.Component {
  render() {
    return (
      <div className='firm firm-admin'>
        <AuthGateway needsAccountManager={true} needsFirmUser={true}>
          <div>
            {this.props.children}
          </div>
        </AuthGateway>
      </div>
    )
  }
}
