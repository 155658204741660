import PropTypes from 'prop-types'
import BulkActionsDropdown from '../../bulk/BulkActionsDropdown'
import StartAddClients from '../StartAddClients'

import {Text} from '../../../../../dashboard/src/components/blocks/Texts'
import {Map} from 'immutable'

const ClientsHeader = props => {
  const hasPendingClients = props.stats.get('pending') > 0
  const hasDraftClients = props.stats.get('draft') > 0
  return (
    <div className='clients-header'>
      <Text className='heading flex-container sub-header'>
        <h1>Client List</h1>
        <div className='right-sub-header'>
          <StartAddClients stats={props.stats} router={props.router} children={props.children} />
          {props.hasClients && (
            <BulkActionsDropdown
              hasDraftClients={hasDraftClients}
              hasPendingClients={hasPendingClients}
              hasClients={props.hasClients}
              professionalDesignation={props.firmConfig.get(
                'professional-designation'
              )}
            />
          )}
        </div>
      </Text>
    </div>
  )
}

ClientsHeader.propTypes = {
  router: PropTypes.object,
  firmConfig: PropTypes.instanceOf(Map),
  hasClients: PropTypes.bool,
  stats: PropTypes.instanceOf(Map)
}

export default ClientsHeader
