import PropTypes from 'prop-types'

import simplifiesImage1 from '../../../assets/simplifies-img-1.svg'
import simplifiesImage2 from '../../../assets/simplifies-img-2.svg'
import simplifiesImage3 from '../../../assets/simplifies-img-3.svg'
import simplifiesImage4 from '../../../assets/simplifies-img-4.svg'
import simplifiesImage5 from '../../../assets/simplifies-img-5.svg'
import SimplifiesYourLifeItem from './SimplifiesYourLifeItem'
import './simplifiesYourLifeInfo.scss'

const SimplifiesYourLifeInfo = ({handleActivateButtonClick}) => {
  const contentArray = [
    {
      image: simplifiesImage1,
      text: 'Organize family documents, IDs, vital info, and accounts'
    },
    {
      image: simplifiesImage2,
      text: 'Securely share items from your Everplan with people you trust'
    },
    {
      image: simplifiesImage3,
      text: 'Access informative articles, videos, and checklists'
    },
    {
      image: simplifiesImage4,
      text: 'Receive reminders and alerts about important dates'
    },
    {
      image: simplifiesImage5,
      text: 'Let our smart planning system guide your next step'
    }
  ]

  return (
    <div className='simplifies-your-life-info'>
      <div className='main-container'>
        <div className='title'>Everplans simplifies your life</div>
        <div className='subtitle'>No more sticky notes, dusty binders, and outdated files</div>
        <ul className='container'>
          {contentArray.map((content, index) => (
            <SimplifiesYourLifeItem
              key={index}
              content={content}
            />
          ))}
        </ul>
        <button className='accept-button' onClick={handleActivateButtonClick}>Activate your Everplan</button>
      </div>
    </div>
  )
}
SimplifiesYourLifeInfo.propTypes = {
  handleActivateButtonClick: PropTypes.func
}

export default SimplifiesYourLifeInfo


