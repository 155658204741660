import {useState, useRef} from 'react'
import classnames from 'classnames'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import * as ButtonTypes from '../../../../../shared_components/ButtonTypes'
import ShadowHeader from '../../shared_firm/ShadowHeader'
import StatusToggle from '../../shared_firm/settings/StatusToggle'
import SettingsContainer from './SettingsContainer'

import Form from '../../../../../shared_components/forms/v2/Form'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'

import './editFormController.scss'

const EditFormController = props => {
  const formRef = useRef()
  const [enabled, setEnabled] = useState(!!props.data)
  const [showModal, setShowModal] = useState(false)
  const {alwaysNotify} = useNotificationContext()


  const toggleModal = error => {
    // Not using the message here 'cause it just say unauthorized (Pundit error) - Atanda
    if (error && error.message)
      alwaysNotify.shortError('Firm does not exist or does not belong to this organization.')

    setShowModal(prevShowModal => !prevShowModal)
  }

  const onChange = () => { setEnabled(prevEnabled => !prevEnabled) }

  const updateSettings = (data = Map()) => { props.submit(data, toggleModal) }

  const submit = event => {
    if (event)
      event.preventDefault()

    const form = formRef.current

    if (form) {
      form.onSubmit()

      if (form.isValid())
        updateSettings(form.formData())
    } else { updateSettings() }
  }


  const containerProps = () => ({
    ...props,
    toggleModal,
    showModal
  })

  const {flavor} = props

  return (
    <div className='edit-form-controller'>
      <SettingsContainer {...containerProps()}>
        <ShadowHeader headerClassName='heading' headerText={props.headerText} />
        <div className='content'>
          <StatusToggle
            description={{disabled: props.disabledText, enabled: props.enabledText}}
            enabledChecked={enabled}
            name='cobranding'
            onChange={onChange}
            statusText={{
              disabled: flavor === 'invite-text' ? 'Everplans Standard' : 'Disabled',
              enabled: flavor === 'invite-text' ? 'Custom' : 'Enabled'
            }}
          />
          {
            enabled &&
              <Form ref={formRef} className={classnames('core', props.formClassName)}>
                {props.children}
              </Form>
          }

        </div>
        <div className='button-group'>
          <ButtonTypes.Cancel onClick={toggleModal} />
          <ButtonTypes.Continue loading={props.processing} onClick={submit}>Save</ButtonTypes.Continue>
        </div>
      </SettingsContainer>
    </div>
  )
}

EditFormController.propTypes = {
  data: PropTypes.oneOf([PropTypes.bool, PropTypes.object]),
  disabledText: PropTypes.string,
  enabledText: PropTypes.string,
  flavor: PropTypes.string,
  formClassName: PropTypes.string,
  headerText: PropTypes.string,
  processing: PropTypes.bool,
  submit: PropTypes.func,
  updateSettings: PropTypes.func
}

export default EditFormController
