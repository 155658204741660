import {Component} from 'react'
import {Map, fromJS} from 'immutable'
import PropTypes from 'prop-types'

import EditFormController from './EditFormController'

import Fields from '../../../../../shared_components/forms/v2/fields/index'
import validators from '../../../../../shared_components/forms/v2/validators'

const TEXTS = {
  disabledText: 'Firm members will see the Everplans standard client invite text as the default personal message.',
  enabledText: 'Firm members will see the custom client invite text as the default personal message.'
}

export default class EditDefaultInviteText extends Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  submit(data, callback) {
    this.props.updateSettings(
      {
        'default-invite-email-text': data.getIn(['client-invite-text-personal-message', 'value'], '')
      },
      callback
    )
  }

  controllerProps() {
    return {
      ...TEXTS,
      ...this.props,
      data: this.props.organization.get('default-invite-email-text'),
      flavor: 'invite-text',
      headerText: 'Default Client Invite Text',
      submit: this.submit
    }
  }

  render() {
    return (
      <EditFormController {...this.controllerProps()}>
        <Fields.TextArea
          data={
            fromJS({
              id: 'client-invite-text-personal-message',
              legend: 'Client Invite Text Personal Message',
              name: 'client-invite-text-personal-message',
              value: this.props.organization.get('default-invite-email-text')
            })
          }
          validator={validators.required}
        />
      </EditFormController>
    )
  }
}

EditDefaultInviteText.propTypes = {
  organization: PropTypes.instanceOf(Map),
  processing: PropTypes.bool,
  updateSettings: PropTypes.func
}
