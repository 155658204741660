import PropTypes from 'prop-types'
import {Component} from 'react'

import WidthContainer from '../../../../../shared_components/WidthContainer'
import ProfileCompletionForm from '../../../../../shared_components/forms/ProfileCompletionForm'
import SelfSignUpCompletionForm from './SelfSignUpCompletionForm'


import Container from '../../../../../lib/Container'
import SessionApi from '../../../web/SessionApi'
import SignUpActions from '../../../actions/SignUpActions'

export default class ConfirmUser extends Component {
  constructor() {
    super()

    this.store = Container.getStore('confirm_user')

    this.state = {
      errors: [],
      processing: false
    }

    this.onUpdate = this.onUpdate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }
  componentDidMount() {
    this.store.addChangeListener(this.onUpdate)
  }
  componentWillUnmount() {
    this.store.removeChangeListener(this.onUpdate)
  }
  onUpdate() {
    var state = this.store.getState()
    this.setState({errors: state.errors, processing: false})
    if (state.errors.length === 0) {
      SessionApi._setToken(state.data.session_token)
      this.props.nextStep(state.data)
    }
  }

  onSubmit(data) {
    data.confirmationToken = this.props.confirmationToken

    this.setState({processing: true}, () => {
      SignUpActions.confirmUser(data, null)
    })
  }
  hasErrors() {
    return this.state.errors.length > 0
  }
  formErrors() {
    if (this.hasErrors())
      return ['Please see errors above.']
  }

  sharedProps(extraData) {
    const name = {
      first_name: this.props.user.data.firstName || extraData.first_name,
      last_name: this.props.user.data.lastName || extraData.last_name
    }

    return {
      formErrors: this.formErrors(), // this would surface errors as a result of the network on the form
      name,
      onSubmit: this.onSubmit,
      processing: this.state.processing || this.props.processing
    }
  }

  render() {
    const extraData = this.props.user.data.extra_data ? JSON.parse(this.props.user.data.extra_data) : {}
    const zip = this.props.user.data.zip || extraData.zip_code

    return (
      <WidthContainer>
        <div className='two-step-confirm-user'>
          {
            this.props.user.data.partner_lifetime_subscription_access ||
            this.props.user.data.partner_self_sign_up ?
              <SelfSignUpCompletionForm {...this.sharedProps(extraData)} /> :
              <ProfileCompletionForm {...this.sharedProps(extraData)} zip={zip} />
          }
        </div>
      </WidthContainer>
    )
  }
}

ConfirmUser.contextTypes = {
  router: PropTypes.func.isRequired
}

ConfirmUser.propTypes = {
  confirmationToken: PropTypes.string,
  nextStep: PropTypes.func,
  processing: PropTypes.bool,
  user: PropTypes.shape({
    data: PropTypes.shape({
      extra_data: PropTypes.object,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      partner_lifetime_subscription_access: PropTypes.bool,
      partner_name: PropTypes.string,
      partner_self_sign_up: PropTypes.bool,
      zip: PropTypes.string
    })
  })
}
