import CodeBlock from '../../CodeBlock'
import CodeExamples from '../../CodeExamples'
import CollapsibleSection from '../CollapsibleSection'
import DashboardHeading from '../../../../shared_components/mui_base_components/dashboard_heading/DashboardHeading'
import {BoxGutterMedium} from '../../../../dashboard/src/components/blocks/Boxes'

const HeadingsSection = () => (
  <CollapsibleSection heading='Headings'>
    <BoxGutterMedium>
      <aside>
        <DashboardHeading>Dashboard heading (MUI)</DashboardHeading>
        <CodeBlock>{CodeExamples.Headings.DashboardHeading}</CodeBlock>
      </aside>
      <span dangerouslySetInnerHTML={{__html: CodeExamples.Headings.Header}} />
      <CodeBlock>{CodeExamples.Headings.Header}</CodeBlock>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default HeadingsSection
