import ActionPrototype from '../../../shared_components/ActionPrototype'
import assign from 'object-assign'
import KeyMirror from 'keymirror'

export default assign({}, ActionPrototype, {
  Types: KeyMirror({
    DID_ADD_FIRM: null,
    DID_ADD_FIRM_USER: null,
    DID_ADD_LICENSE: null,
    DID_ARCHIVE_LICENSE: null,
    DID_EDIT_FIRM_USER: null,
    DID_REMOVE_FIRM_USER: null,
    DID_REMOVE_INTEGRATION: null,
    DID_SEND_FIRM_USER_INVITE: null,
    DID_UPDATE_FIRM_USER_CONFIG: null,
    DID_UPDATE_CONTACT_CARD: null,
    DID_UPDATE_FIRM: null,
    DID_DOWNGRADE_FIRM: null,
    DID_UPDATE_FIRM_CONFIG: null,
    GOT_FIRM_USER_CONFIG: null,
    GOT_CONTACT_CARD: null,
    GOT_FIRM: null,
    GOT_FIRM_LICENSES: null,
    GOT_FIRM_USER: null,
    GOT_FIRM_USERS: null,
    GOT_FIRMS: null,
    GOT_FIRM_CONFIG: null,
    GOT_INTEGRATIONS: null,
    GOT_ORGANIZATION: null
  }),

  addFirm(data) {
    this.fireApi(
      'post',
      '/firms',
      {firm: data},
      {
        JSONHead: 'firm',
        successAction: this.Types.DID_ADD_FIRM
      }
    )
  },

  addLicense(data) {
    this.fireApi(
      'post',
      `/admin/${data.firmId}/licenses`,
      data.data,
      {
        JSONHead: 'license',
        onSuccess: () => this.fetchFirmLicenses(data.firmId),
        successAction: this.Types.DID_ADD_LICENSE
      }
    )
  },

  archiveLicense(data) {
    this.fireApi(
      'put',
      `/admin/${data.firmId}/licenses/${data.licenseId}`,
      null,
      {
        JSONHead: 'license',
        onSuccess: () => this.fetchFirmLicenses(data.firmId),
        successAction: this.Types.DID_ARCHIVE_LICENSE
      }
    )
  },

  downgradeFirm(id) {
    this.fireApi(
      'put',
      `/firms/${id}/downgrade`,
      null,
      {
        JSONHead: 'firm',
        successAction: this.Types.DID_DOWNGRADE_FIRM
      }
    )
  },

  editFirmUser(data) {
    this.fireApi(
      'put',
      `/firms/${data.firmId}/users/${data.firmUserSeatId}`,
      {firm_user: data},
      {
        JSONHead: 'firm_user',
        onSuccess: () => this.fetchFirmUser({firmId: data.firmId, firmUserSeatId: data.firmUserSeatId}),
        successAction: this.Types.DID_EDIT_FIRM_USER
      }
    )
  },

  editFirm(data) {
    this.fireApi(
      'put',
      `/firms/${data.firmId}`,
      {firm: data},
      {
        JSONHead: 'firm',
        onSuccess: () => this.fetchFirm(data.firmId), // Trigger update to table of all firms
        successAction: this.Types.DID_UPDATE_FIRM
      }
    )
  },

  fetchFirmUserConfig(data) {
    this.fireApi(
      'get',
      `/firms/${data.firmId}/firm_user_config/${data.firmUserSeatId}`,
      null,
      {
        JSONHead: 'firm_user_config',
        successAction: this.Types.GOT_FIRM_USER_CONFIG
      }
    )
  },

  fetchContactCard(params) {
    this.fireApi(
      'get',
      `/firms/${params.firmId}/contact_cards/${params.firmUserSeatId}`,
      null,
      {
        JSONHead: 'contact_card',
        successAction: this.Types.GOT_CONTACT_CARD
      }
    )
  },

  fetchFirm(firmId) {
    this.fireApi(
      'get',
      `/firms/${firmId}`,
      null,
      {
        successAction: this.Types.GOT_FIRM, JSONHead: 'firm'
      }
    )
  },

  fetchOrganization(organizationId) {
    this.fireApi(
      'get',
      `/organizations/${organizationId}`,
      null,
      {
        successAction: this.Types.GOT_ORGANIZATION, JSONHead: 'organization'
      }
    )
  },
  fetchFirmLicenses(firmId) {
    this.fireApi(
      'get',
      `/admin/${firmId}/licenses`,
      null,
      {
        successAction: this.Types.GOT_FIRM_LICENSES, JSONHead: 'licenses'
      }
    )
  },

  fetchFirms() {
    this.fireApi(
      'get',
      '/firms',
      null,
      {
        successAction: this.Types.GOT_FIRMS, JSONHead: 'firms'
      }
    )
  },

  fetchFirmUser(params) {
    this.fireApi(
      'get',
      `/firms/${params.firmId}/users/${params.firmUserSeatId}`,
      null,
      {
        successAction: this.Types.GOT_FIRM_USER, JSONHead: 'firm_user'
      }
    )
  },

  fetchFirmUsers(firmId) {
    this.fireApi(
      'get',
      `/firms/${firmId}/users`,
      null,
      {
        successAction: this.Types.GOT_FIRM_USERS, JSONHead: 'firm_users'
      }
    )
  },

  addFirmUser(data) {
    this.fireApi(
      'post',
      `/firms/${data.id}/users`,
      {firm_user: data},
      {
        JSONHead: 'firm_user',
        onSuccess: () => this.fetchFirmUsers(data.id),
        successAction: this.Types.DID_ADD_FIRM_USER
      }
    )
  },

  inviteFirmUser(data) {
    this.fireApi(
      'post',
      `/firms/${data.firmId}/users/${data.firmUserSeatId}/invite`,
      {firm_user: data},
      {
        JSONHead: 'firm_user',
        onSuccess: () => this.fetchFirmUser({firmId: data.firmId, firmUserSeatId: data.firmUserSeatId}),
        successAction: this.Types.DID_SEND_FIRM_USER_INVITE
      }
    )
  },

  fetchFirmConfig(firmId) {
    this.fireApi(
      'get',
      `/firms/${firmId}/firm_config`,
      null,
      {
        successAction: this.Types.GOT_FIRM_CONFIG, JSONHead: 'firm_config'
      }
    )
  },

  fetchIntegrations() {
    this.fireApi(
      'get',
      '/integrations',
      null,
      {
        successAction: this.Types.GOT_INTEGRATIONS, JSONHead: 'integrations'
      }
    )
  },

  removeFirmUser(params) {
    this.fireApi(
      'delete',
      `/firms/${params.firmId}/users/${params.firmUserSeatId}`,
      null,
      {
        JSONHead: 'firm_user',
        onSuccess: () => this.fetchFirmUsers(params.firmId),
        successAction: this.Types.DID_REMOVE_FIRM_USER
      }
    )
  },

  removeIntegration(integration) {
    this.fireApi(
      'delete',
      `/integrations/${integration}`,
      null,
      {
        JSONHead: 'integrations', successAction: this.Types.DID_REMOVE_INTEGRATION
      }
    )
  },

  updateFirmUserConfig(data, params) {
    this.fireApi(
      'post',
      `/firms/${params.firmId}/firm_user_config/${params.firmUserSeatId}`,
      data,
      {
        JSONHead: 'firm_user_config',
        successAction: this.Types.DID_UPDATE_FIRM_USER_CONFIG
      }
    )
  },

  updateContactCard(data, params) {
    this.fireApi(
      'post',
      `/firms/${params.firmId}/contact_cards/${params.firmUserSeatId}`,
      data,
      {
        JSONHead: 'contact_card',
        successAction: this.Types.DID_UPDATE_CONTACT_CARD
      }
    )
  },

  updateFirmConfig(data) {
    this.fireApi(
      'put',
      `/firms/${data.firmId}/firm_config`,
      data.data,
      {
        onSuccess: () => this.fetchFirmConfig(data.firmId),
        successAction: this.Types.DID_UPDATE_FIRM_CONFIG,
        JSONHead: 'firm_config'
      }
    )
  }
})
