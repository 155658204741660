import {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {createResource, readEndpoint} from 'redux-json-api'

import FirmSummariesTable from './FirmSummariesTable'
import FirmsTableHeader from '../FirmsTableHeader'

import {cleanApiResponseData} from '../../../../../lib/tools'
import {webAppsUrl} from '../../../../../lib/urlTools'
import {filteredValueQuery, pageNumberAndSize, sortValue} from '../../../../../lib/summariesTableTools'

export class FirmSummariesTableContainer extends Component {
  constructor() {
    super()

    this.state = {
      loadingOrganizations: true,
      loadingFirms: true,
      pages: -1,
      firmSummaries: [],
      organizations: {data: []}
    }

    this.onFetchData = this.onFetchData.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
    this.fetchFirmSummaries = this.fetchFirmSummaries.bind(this)
  }

  componentDidMount() {
    this.props.readEndpoint('organizations').then(response =>
      // Did not put this in the mapStateToProps, otherwise the organization responses will get remunged everytime anything changes.
      this.setState({
        loadingOrganizations: false,
        organizations: {data: cleanApiResponseData(response.body.data)}
      })
    )
  }

  sortValue(tableState) {
    const sorted = tableState.sorted
    /**
      `license-expiration-date' needs to be sorted by the date, but filtered by the status (active/downgraded),
      and until we upgrade to v7 of the `react-table` library (which is still in alpha), there is no way to set different
      sort and filter ids. --ZD
    */
    const sortId = tableState.sorted[0].id === 'status' ? 'license-expiration-date' : tableState.sorted[0].id

    sorted[0] = {...sorted[0], id: sortId}
    return sortValue(sorted)
  }

  fetchFirmSummaries() {
    this.setState({loadingFirms: true})

    this.props.readEndpoint('firm-summaries?&sort=name')
      .then(response => this.onFirmSummariesDataFetched(response))
  }

  onFirmSummariesDataFetched(response) {
    this.setState({
      loadingFirms: false,
      /**
        Need to get the data directly from the response, because the `redux-json-api` library just appends newly fetched data to already
        existing data, therefore if we use `mapStateToProps` the data will not be filtered or sorted correctly.
      */
      firmSummaries: cleanApiResponseData(response.body.data),
      pages: response.body.meta['page-count']
    })
  }

  onFetchData(tableState) {
    this.setState({loadingFirms: true})

    this.props.readEndpoint(
      `firm-summaries?${
        pageNumberAndSize(tableState)
      }${this.sortValue(tableState)
      }${filteredValueQuery(tableState)}`
    ).then(response => this.onFirmSummariesDataFetched(response))
  }


  onRowClick(firmId) {
    /**
      Workaround for not being able to put an anchor tag, since we just pass a function to the onClick method,
      provided by the `react-table` library  -- ZD
    */
    window.open(webAppsUrl(`pro/firm/${firmId}/licenses`), '_blank').focus()
  }
  render() {
    return (
      <div>
        <FirmsTableHeader
          loadingOrganizations={this.state.loadingOrganizations}
          organizations={this.state.organizations}
          fetchFirmSummaries={this.fetchFirmSummaries}
          createResource={this.props.createResource}
        />
        <FirmSummariesTable
          {...this.state}
          onFetchData={this.onFetchData}
          onRowClick={this.onRowClick}
        />
      </div>
    )
  }
}

FirmSummariesTableContainer.propTypes = {
  readEndpoint: PropTypes.func,
  createResource: PropTypes.func
}

export default connect(null, {readEndpoint, createResource})(FirmSummariesTableContainer)
