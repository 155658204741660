import CodeBlock from '../../CodeBlock'
import CodeExamples from '../../CodeExamples'
import CollapsibleSection from '../CollapsibleSection'
import {BoxGutterMedium} from '../../../../dashboard/src/components/blocks/Boxes'


const FontScaleSection = () => (
  <CollapsibleSection heading='Font Scale System'>
    <p>Typeface is <a href='https://typekit.com/fonts/proxima-nova' target='_blank' rel='noreferrer'>Proxima Nova</a></p>
    <p>Font sizes used are 14px, 15px, 16px, 17px, 18px, 20px, and 28px)</p>
    <p>Each font scale also defines the letter spacing and line height, the values of which are listed by scale level below.</p>
    <BoxGutterMedium>
      <aside>
        <div className='font-scale-0'>Font Scale 0</div>
        <ul>
          <li>Font size: 14px/0.875rem</li>
          <li>Letter spacing: 0.28px/0.0175rem</li>
          <li>Line height: 18.2px/1.1375rem</li>
          <li>Primarily used for: Navigation, small text</li>
        </ul>
        <CodeBlock>{CodeExamples.FontScale0}</CodeBlock>
      </aside>
      <aside>
        <div className='font-scale-1'>Font Scale 1</div>
        <ul>
          <li>Font size: 15px/0.9375rem</li>
          <li>Letter spacing: 0.3px/0.01875rem</li>
          <li>Line height: 19.5px/1.21875rem</li>
          <li>Primarily used for: Button Text</li>
        </ul>
        <CodeBlock>{CodeExamples.FontScale1}</CodeBlock>
      </aside>
      <aside>
        <div className='font-scale-2'>Font Scale 2</div>
        <ul>
          <li>Font size: 16px/1rem</li>
          <li>Letter spacing: 0.32px/0.02rem</li>
          <li>Line height: 20.8px/1.3rem</li>
          <li>Primarily used for: Body, Navigation</li>
        </ul>
        <CodeBlock>{CodeExamples.FontScale2}</CodeBlock>
      </aside>
      <aside>
        <div className='font-scale-3'>Font Scale 3</div>
        <ul>
          <li>Font size: 17px/1.0625rem</li>
          <li>Letter spacing: 0.34px/0.02125rem</li>
          <li>Line height: 22.1px/1.38125rem</li>
          <li>Primarily used for: h3 subheading</li>
        </ul>
        <CodeBlock>{CodeExamples.FontScale3}</CodeBlock>
      </aside>
      <aside>
        <div className='font-scale-4'>Font Scale 4</div>
        <ul>
          <li>Font size: 18px/1.125rem</li>
          <li>Letter spacing: 0.36px/0.0225rem</li>
          <li>Line height: 23.4px/1.4625rem</li>
          <li>Primarily used for: h2 subheading</li>
        </ul>
        <CodeBlock>{CodeExamples.FontScale4}</CodeBlock>
      </aside>
      <aside>
        <div className='font-scale-5'>Font Scale 5</div>
        <ul>
          <li>Font size: 20px/1.25rem</li>
          <li>Letter spacing: 0.4px/0.025rem</li>
          <li>Line height: 26px/1.625rem</li>
          <li>Primarily used for: h1 heading</li>
        </ul>
        <CodeBlock>{CodeExamples.FontScale5}</CodeBlock>
      </aside>
      <aside>
        <div className='font-scale-6'>Font Scale 6</div>
        <ul>
          <li>Font size: 28px/1.75rem</li>
          <li>Letter spacing: 0.56px/0.035rem</li>
          <li>Line height: 36.4px/2.275rem</li>
          <li>Primarily used for: h1 heading</li>
        </ul>
        <CodeBlock>{CodeExamples.FontScale6}</CodeBlock>
      </aside>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default FontScaleSection
