export default [
  {
    name: 'Eldercare',
    'sort-order': 3,
    id: '292bbbfa-d3e1-4307-926e-c176dfd7f5f4',
    links: {
      self: 'http://localhost:7000/api/v2/categories/292bbbfa-d3e1-4307-926e-c176dfd7f5f4'
    },
    type: 'categories'
  },
  {
    name: 'My Life',
    'sort-order': 2,
    id: 'b3e4028d-6138-424d-a47f-3493f64ee0b8',
    links: {
      self: 'http://localhost:7000/api/v2/categories/b3e4028d-6138-424d-a47f-3493f64ee0b8'
    },
    type: 'categories'
  },
  {
    name: 'Financial',
    'sort-order': 1,
    id: '1c9af535-ffa9-43e0-9755-c8cee6ae3057',
    links: {
      self: 'http://localhost:7000/api/v2/categories/1c9af535-ffa9-43e0-9755-c8cee6ae3057'
    },
    type: 'categories'
  },
  {
    name: 'Family and Loved Ones',
    'sort-order': 4,
    id: '9c6024c6-251a-4507-b841-99fdc7181363',
    links: {
      self: 'http://localhost:7000/api/v2/categories/9c6024c6-251a-4507-b841-99fdc7181363'
    },
    type: 'categories'
  },
  {
    id: 'ef54efda-8a26-4bf4-b217-7284cf793767',
    name: 'Legal',
    'sort-order': 5,
    type: 'categories'
  },
  {
    id: 'hthhehee-8a26-4bf4-b217-7284c7yehuhe',
    name: 'Digital',
    'sort-order': 6,
    type: 'categories'
  }
]
