import PropTypes from 'prop-types'
import React from 'react'

import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'
import UnorderedList from '../../../../shared_components//UnorderedList'


const HouseholdMemberAddedConfirmation = props => {
  const detailListItems = [
    `Your advisor will send an invitation to ${props.pendingCoAdministrator.first_name} to join your Everplan.`,
    `Once ${props.pendingCoAdministrator.first_name} accepts, they will be able to view, edit, and manage your Everplan.`
  ]
  return (
    <div className='central-content-box'>
      <div className='household-member-added-confirmation'>
        <h1>Your approval is sent.</h1>
        <section>
          <div className='subheading'>
            We've let {props.advisorFullName} know it's OK to add {props.pendingCoAdministrator.full_name} as a co-administrator of your Everplan.
          </div>
          <UnorderedList children={detailListItems} />
          <Accept onClick={props.returnToController}>Continue</Accept>
        </section>
      </div>
    </div>
  )
}

HouseholdMemberAddedConfirmation.defaultProps = {
  advisorFullName: '',
  pendingCoAdministrator: {
    first_name: '',
    full_name: ''
  }
}

HouseholdMemberAddedConfirmation.propTypes = {
  advisorFullName: PropTypes.string.isRequired,
  pendingCoAdministrator: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired
  }),
  returnToController: PropTypes.func
}

export default HouseholdMemberAddedConfirmation
