import PropTypes from 'prop-types'


const AcceptedClientRow = props => <tr><td>{props.first_name}</td><td>{props.last_name}</td><td>{props.email}</td></tr>

AcceptedClientRow.propTypes = {
  email: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired
}

export default AcceptedClientRow
