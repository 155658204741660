import {fromJS, Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {useCallback, useState, useRef} from 'react'
import PhoneInput, {
  isValidPhoneNumber
} from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'

import {Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import Closer from '../../../../../shared_components/core/closer/Closer'
import Fields from '../../../../../shared_components/forms/v2/fields'
import Form from '../../../../../shared_components/forms/v2/Form'
import Modals from '../../../../../shared_components/core/modals/Modals'
import {TextGutterMedium} from '../../../../../dashboard/src/components/blocks/Texts'

import validators from '../../../../../shared_components/forms/v2/validators'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'

import './jiclSendToRecipientsModal.scss'

const JiclSendToRecipientsModal = props => {
  const [formData, setFormData] = useState(Map({name: '', phone_number: ''}))
  const [showPhoneError, setShowPhoneError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const formRef = useRef()
  const {alwaysNotify} = useNotificationContext()

  const onPhoneNumberChange = useCallback(value => {
    setShowPhoneError(false)
    setFormData(formData.set('phone_number', value))
  })

  const closeModal = () => {
    formRef.current.updateDataAndErrors('name', Map(), List())
    setFormData(Map({name: '', phone_number: ''}))
    setShowPhoneError(false)
    setProcessing(false)
    props.closeModal()
  }

  const onNameChange = useCallback(() => {
    if (!formRef.current) return

    setFormData(
      formData.set('name', formRef.current.formData().getIn(['name', 'value']))
    )
  })

  const sendList = useCallback(() => {
    const name = formData.get('name')
    const phone_number = formData.get('phone_number')
    const isPhoneNumberValid = isValidPhoneNumber(phone_number)
    const form = formRef.current
    form.onSubmit()

    if (isPhoneNumberValid && form.isValid()) {
      setProcessing(true)
      const resource = {type: 'jicl-recipients', attributes: {recipient: {name, phone_number}}}
      props.createResource(resource)
        .then(() => {
          alwaysNotify.shortSuccess(`🎉 We've sent ${name} your Just-In-Case List.`)
          closeModal()
        })
    } else {
      setShowPhoneError(!isPhoneNumberValid)
    }
  })

  const closer = () => <Closer closer={closeModal} />

  return (
    <Modals.PopUpModalLarge showModal={props.showModal} closerComponent={closer}>
      <div className='jicl-send-to-recipients-modal forms-playground'>
        <div className='section-title border-bottom'>
          Who should have this information?
        </div>
        <TextGutterMedium>
          We suggest sending your list to your grown children, siblings, and
          close friends and relatives. You'll be able to add as many as you like
          later.
        </TextGutterMedium>
        <Form className='core' onUpdate={onNameChange} ref={formRef}>
          <Fields.Text
            data={fromJS({
              legend: 'Recipient name:',
              name: 'name',
              id: 'name'
            })}
            validator={validators.required}
          />
          <fieldset>
            <legend>Phone number:</legend>
            <PhoneInput
              className='phone-number-container'
              numberInputProps={{className: 'phone-number'}}
              onChange={onPhoneNumberChange}
              defaultCountry='US'
              flags={flags}
              limitMaxLength={true}
              value={formData.get('phone_number')}
            />
            {showPhoneError && (
              <div className='error'>
                <span className='error-text'>Phone number is invalid</span>
              </div>
            )}
          </fieldset>
          <Accept onClick={sendList} processing={processing}>
            Send
          </Accept>
        </Form>
      </div>
    </Modals.PopUpModalLarge>
  )
}

JiclSendToRecipientsModal.propTypes = {
  createResource: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.bool
}

export default JiclSendToRecipientsModal
