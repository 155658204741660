import PropTypes from 'prop-types'

import {monthDayYear} from '../../../../../lib/dateTools'


const Name = ({accountManager, row}) => (
  <span>
    <i className='fa fa-user' /> {row.name}
    {
      accountManager &&
      row.last_login &&
      <div>{`(Last login: ${monthDayYear(row.last_login)})`}</div>
    }
  </span>
)

Name.propTypes = {
  accountManager: PropTypes.bool,
  row: PropTypes.shape({
    last_login: PropTypes.string,
    name: PropTypes.string
  })
}

export default Name
