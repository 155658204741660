import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {useEverplanData} from '../../../../lib/hooks'
import Household from './Household'
import NewLoader from '../../../../shared_components/NewLoader'

import './household.scss'

const HouseholdController = props => {
  const {itemResponses, loadingEverplanData, newContacts} = useEverplanData(props.everplan.get('id'))

  if (loadingEverplanData) {
    return <NewLoader loading={true} />
  } else {
    return (
      <Household
        {...props}
        itemResponses={itemResponses}
        newContacts={newContacts}
      />
    )
  }
}

HouseholdController.propTypes = {
  everplan: PropTypes.instanceOf(Map)
}

export default HouseholdController
