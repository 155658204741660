import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import EditDeputizable from './EditDeputizable'
import ShadowWrapper from '../../ShadowWrapper'
import ShadowHeader from '../../ShadowHeader'


export const DeputizableSettings = props => (
  <div className='deputizable-settings'>
    <ShadowHeader headerText='Advisors as Deputies' />
    <ShadowWrapper>
      <div className='settings row-wrapper'>
        <p>Status: </p>
        <div className='column-wrapper'>
          <p>{props.isDeputizable ? 'Allowed' : 'Not Allowed'}</p>
          <p className='description'>{props.isDeputizable ?
            'Clients are permitted to add Advisors and Assistants as Deputies.' :
            'Clients cannot add Advisors or Assistants as Deputies.'}
          </p>
          <EditDeputizable {...props} />
        </div>
      </div>
    </ShadowWrapper>
  </div>
)


const mapStateToProps = state => ({
  items: state.items
})

DeputizableSettings.propTypes = {
  firmId: PropTypes.string,
  readEndpoint: PropTypes.func,
  isDeputizable: PropTypes.bool
}

export default connect(mapStateToProps)(DeputizableSettings)
