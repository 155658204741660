import {fromJS, List} from 'immutable'

export const defaultView = fromJS({
  id: '1',
  name: 'default-en',
  data: {
    elements: [
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb540644c6',
        'element-id': 'ffbc0151-951d-43a9-8e46-3c52006c7479',
        placeholder: '',
        'display-name': 'Type of Care Provider',
        'field-prompt': 'Other type of care provider'
      },
      {
        id: '021136f6-0be5-4e64-8bfa-9b5894e25801',
        'element-id': 'f88856e8-0720-4daa-8eff-3fb9037641db',
        placeholder: '',
        'display-name': 'Type of Care Provider',
        'field-prompt': 'Type of care provider'
      },
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb540644c6',
        'element-id': 'c4b70a1e-202e-4491-84e6-341db8355b68',
        placeholder: '',
        'display-name': 'How did you create your will',
        'field-prompt': 'How did you create your will?'
      },
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb540644554',
        'element-id': '590c2aca-39d4-4196-8c61-573867ec7cd4',
        placeholder: '',
        'display-name': 'Type of Pet',
        'field-prompt': 'Type of Pet'
      },
      {
        id: '021136f6-0be5-4e64-8bfa-9b5894e258023',
        'element-id': '51027462-816e-41e6-9517-64e21aa1db2c',
        placeholder: '',
        'display-name': 'Name',
        'field-prompt': 'Name'
      },
      {
        id: '031456f6-0be4-4e24-8bfa-9b5894e111123',
        'element-id': 'c5f8f354-3fac-41e7-22be-4f87caeb7396',
        placeholder: '',
        'display-name': 'Types of Pets',
        'field-prompt': 'Types of Pets'
      },
      {
        id: '045467f6-0be4-4e24-8bfa-9b5894e111124',
        'element-id': 'f5f8f354-3fac-41e7-22be-4f87caeb5647',
        placeholder: '',
        'display-name': 'Types of Files',
        'field-prompt': 'Types of Files'
      },
      {
        id: '045467f6-0be4-4e24-8bfa-9b5894e2233232',
        'element-id': '8549c85b-879b-4c84-a913-9f4128c3347e',
        placeholder: '',
        'display-name': 'Date of birth',
        'field-prompt': 'Date of birth'
      },
      {
        id: '045467f6-0be4-4e24-8bfa-9b4334534sdf',
        'element-id': '9549c85b-979b-4984-a913-9f41423423',
        placeholder: '',
        'display-name': 'Home Address',
        'field-prompt': 'Home Address'
      },
      {
        id: '021136f6-0be5-4e64-8bfa-9b5894e258023',
        'element-id': 'dbb44697-0c91-4204-a9d5-10f9c087c23b',
        placeholder: 'Example: Cat Litter',
        'display-name': 'Where do you keep the signed and notarized document?',
        'field-prompt': 'Where do you keep the signed and notarized document?'
      },
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb54022222',
        'element-id': '1b1f9c2c-1f40-48d0-b698-2a395f564023',
        'field-prompt': 'Name of Insurance Company',
        'display-name': 'Name of Insurance Company',
        placeholder: 'e.g KBL'
      },
      {
        id: 'b342e593-05b6-4b30-925b-5825cfa9f9eb',
        'element-id': '9e7d94e7-abe4-4e24-875d-eeef84b9b7a2',
        placeholder: '',
        'display-name': 'Name of Service',
        'field-prompt': 'Name of Service'
      },
      {
        id: '4d1583d6-bedc-430c-93bf-2307fad57808',
        'element-id': '75b2e3e4-6858-447f-98ca-32978e6f9ec1',
        'field-prompt': 'Attorney Contact Info',
        'display-name': 'Attorney Contact Info',
        placeholder: ''
      },
      {
        id: '5c844664-0a5b-4f15-bfde-5b70b57ed4ce',
        'element-id': '0c93a0cc-1cb0-47c1-872a-57281ef9e8af',
        'field-prompt': 'State of Coperation',
        'display-name': 'State of Coperation',
        placeholder: ''
      },
      {
        id: '09c352f6-0b42-47a6-bc71-75c1b1e636f6',
        'element-id': '0f8ab40d-bae7-4309-a721-052d32e3fb7d',
        'field-prompt' : 'Who provides your insurance, what type of policy it is, and more.',
        'display-name' : 'Policy Info',
        placeholder: ''
      },
      {
        'id': 'e04b46fc-dd39-421b-8d9a-7438fd9d623c',
        'element-id': 'ddce415a-2c09-4eb9-a6f9-1cf55271a3e5',
        'field-prompt': 'Who are the beneficiaries of your policy?',
        'display-name': 'Beneficiaries',
        placeholder: ''
      },
      {
        id: '82e685d4-2925-4e10-be95-1f8ecf3520aa',
        'field-prompt': 'Type of document or ID',
        'display-name': 'Type of document or ID',
        'element-id': '29f24d23-2b4a-41a2-ba2f-895ea2c660d6',
        placeholder: ''
      },
      {
        id: 'dfdb14a4-2fd2-4506-85da-b2f863e7e441',
        'field-prompt': 'Will Basic Info',
        'display-name': 'Will Basic Info',
        'element-id': '9f718686-5409-4fbc-a31b-b104b4076b2d',
        placeholder: ''
      },
      {
        id: '4e28026d-b59f-4285-a81a-4d5d74c357ee',
        'field-prompt': 'Executors',
        'display-name': 'Executors',
        'element-id': '8886be2d-bc06-48f0-9492-9a205f86e5ce',
        placeholder: ''
      },
      {
        id: '585e4370-81d6-443c-bef7-1a14b9c169ac',
        'field-prompt': 'Guardians',
        'display-name': 'Guardians',
        'element-id': '6ef1a28c-2c8a-4186-8e42-b9cb8b4357e0',
        placeholder: ''
      }
    ]
  }
})

export const powerOfAttorneyBasicView = fromJS({
  id: '2',
  name: 'Power of Attorney',
  data: {
    elements: [
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb540644c6',
        'element-id': '01e738f5-5e15-4944-9e6b-af34f94711ac',
        placeholder: '',
        'display-name': 'Power of Attorney',
        'field-prompt': 'Who did you name as Power Of Attorney (POA)?',
        'sort-order': 1
      },
      {
        id: '021136f6-0be5-4e64-8bfa-9b5894e25801',
        'element-id': 'a774a953-d651-44d1-95d0-cdd21c08e38e',
        placeholder: '',
        'display-name': 'Alternate Power of Attorney',
        'field-prompt': 'Who did you name as your alternate Power Of Attorney (POA)?',
        'sort-order': 2
      },
      {
        id: '852a6a62-2291-413f-91bf-3794cd429c93',
        'element-id': 'c4b70a1e-202e-4491-84e6-341db8355b68',
        placeholder: '',
        'display-name': 'Upload a scan, photo, or PDF of POA document',
        'field-prompt': 'Upload a scan, photo, or PDF of POA document',
        'sort-order': 3
      },
      {
        id: '021136f6-0be5-4e64-8bfa-9b212121212',
        'element-id': 'dbb44697-0c91-4204-a9d5-10f9c087c23b',
        placeholder: 'Example: Cat Litter',
        'display-name': 'Where do you keep the signed and notarized document?',
        'field-prompt': 'Where do you keep the signed and notarized document?',
        'sort-order': 4
      }
    ]
  }
})

export const dependentResponseElements = fromJS([{
  'element-id': 'c4b70a1e-202e-4491-84e6-341db8355b68',
  value: 'Attorney',
  'dependent-element-ids': [
    '75b2e3e4-6858-447f-98ca-32978e6f9ec1',
    '0c93a0cc-1cb0-47c1-872a-57281ef9e8af'
  ]
}])

export const simpleTextFieldsView = fromJS({
  id: '6',
  name: 'My Pet',
  data: {
    elements: [
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb540644222',
        'element-id': '51027462-816e-41e6-9517-64e21aa1db2c',
        placeholder: '',
        'display-name': 'Name',
        'field-prompt': 'Pet Name',
        'sort-order': '1'
      },
      {
        id: '021136f6-0be5-4e64-8bfa-9b5894e25801',
        'element-id': '590c2aca-39d4-4196-8c61-573867ec7cd4',
        placeholder: '',
        'display-name': 'Type of Pet',
        'field-prompt': 'Type of Pet.',
        'sort-order': '2'
      }
    ]
  }
})

export const viewWithDependentResponseElements = fromJS({
  id: '4',
  name: 'Social Security',
  data: {
    elements: [
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb5406448787',
        'element-id': '20ab41ea-3c2e-4803-bcb8-374b83768bc4',
        placeholder: '',
        'display-name': 'Social Security Number',
        'field-prompt': 'Social Security Number',
        'sort-order': 1
      },
      {
        id: '021136f6-0be5-4e64-8bfa-9b5894e25801',
        'element-id': 'cf8a716f-b603-43c1-ada7-748b96a4bce4',
        placeholder: '',
        'display-name': 'Location of Social Security Card',
        'field-prompt': 'Location of Social Security Card',
        'sort-order': 2
      }
    ],
    'dependent-response-elements': [{
      id: 'c4218f9e-4ed2-4d45-9324-e3c2f9fb05ae',
      'element-id': '29f24d23-2b4a-41a2-ba2f-895ea2c660d6',
      value: 'Social Security Card',
      'dependent-element-ids': [
        '20ab41ea-3c2e-4803-bcb8-374b83768bc4',
        'cf8a716f-b603-43c1-ada7-748b96a4bce4'
      ]
    }]
  }
})

export const viewWithConditionalElements = fromJS({
  id: '3',
  name: 'Will',
  data: {
    elements: [
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb5406111111',
        'element-id': 'c4b70a1e-202e-4491-84e6-341db8355b68',
        placeholder: '',
        'display-name': 'How did you create your will',
        'field-prompt': 'How did you create your will?',
        'sort-order': 1
      },
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb540612323',
        'element-id': '8549c85b-879b-4c84-a913-9f4128c3347e',
        placeholder: '',
        'display-name': 'Will Last Updated',
        'field-prompt': 'When was it last updated?',
        'sort-order': 2
      }
    ],
    'conditional-elements': [
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb5406112222',
        'element-id': '75b2e3e4-6858-447f-98ca-32978e6f9ec1',
        placeholder: '',
        'display-name': 'Attorney Contact Info',
        'field-prompt': 'Attorney Contact Info',
        'sort-order': 2
      },
      {
        id: '7a519a95-3f4e-4bb4-91f7-cdfb540611j1213a',
        'element-id': '9e7d94e7-abe4-4e24-875d-eeef84b9b7a2',
        placeholder: '',
        'display-name': 'Name of Service',
        'field-prompt': 'Name of Service',
        'sort-order': 3
      }
    ]
  }
})

export const views = List([
  defaultView,
  powerOfAttorneyBasicView,
  viewWithConditionalElements,
  viewWithDependentResponseElements,
  simpleTextFieldsView
])

//// Views Tool Manager Fixtures ////////
export const selectedElementsWithConditionalElementIds = fromJS([
  {
    'list-mapping': '983e94c9-7e55-48f8-b00a-5f6c15a0daf6',
    id: 'c4b70a1e-202e-4491-84e6-341db8355b68',
    name: 'How did you create your will',
    type: 'TEXT',
    addMore: false,
    ordinality: 'SELECT-WITH-OTHER',
    selectType: 'Select',
    conditional: false,
    'conditional-element-ids': [
      '75b2e3e4-6858-447f-98ca-32978e6f9ec1',
      '0c93a0cc-1cb0-47c1-872a-57281ef9e8af',
      '9e7d94e7-abe4-4e24-875d-eeef84b9b7a2',
      'd63f8cff-14aa-4e0e-9dcc-0c3c24e78c64'
    ]
  }
])

export const selectedConditionalElementsWithListMappingValue = fromJS([
  {
    id: '75b2e3e4-6858-447f-98ca-32978e6f9ec1',
    name: 'Attorney Contact Info',
    ordinality: 'NONE',
    type: 'CONTACT',
    'list-mapping-value': 'Attorney'
  },
  {
    id: '0c93a0cc-1cb0-47c1-872a-57281ef9e8af',
    name: 'State of corp',
    ordinality: 'NONE',
    type: 'TEXT',
    'list-mapping-value': 'Attorney'
  }
])


