import React from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {ButtonGroupWrapper} from '../../../../shared_components/blocks/Blocks'
import {Back, Continue} from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'

import BulkInviteActions from '../../actions/BulkInviteActions'
import storeWrapper from '../../../../shared_components/storeWrapper'
import Container from '../../../../lib/Container'

export class PickRecipients extends React.Component {
  constructor(props) {
    super(props)

    this.state = {processing: false}

    this.store = Container.getStore('bulkInvitedClients')

    this.continue = this.continue.bind(this)
    this.toggleSelectAll = this.toggleSelectAll.bind(this)
    this.updateCheckAll = this.updateCheckAll.bind(this)
  }

  componentDidUpdate(prevProps) {
    const {invites_sent} = this.props.bulkInvitedClients.data

    if (invites_sent && (invites_sent !== prevProps.bulkInvitedClients.data.invites_sent)) {
      this.setState({processing: false}, () => {
        this.props.closer()
        this.store.clearState() // Clearing the store so that when an advisor attempts to bulk resend invites, old state data does not result in weirdness where modal doesn't close
      })
    }
  }

  toggleSelectAll(event) {
    const newSelectAllValue = event.target.checked

    Object.keys(this.refs).filter(key => key !== 'form').forEach(key => {
      const checkbox = this.refs[key]
      checkbox.context.setFormValue(checkbox.name(), newSelectAllValue) // Let form know the checkbox values now
    })

    $('.select-clients-column input[type="checkbox"]').prop('checked', newSelectAllValue) // Visually toggle the checkboxes
  }
  updateCheckAll(event) {
    var selectAll = $('input[name="select-all"]')[0]
    if (selectAll.checked && !event.target.checked)
      selectAll.checked = false

    if ($('.select-clients-column input[type="checkbox"]:not([name="select-all"]):checked').length === ($('.select-clients-column input[type="checkbox"]').length - 1))
      selectAll.checked = true
  }
  continue() {
    if (this.refs.form.validate().length === 0) {
      const formData = this.refs.form.formData()
      const selectedClients = this.props.clients.data.filter(client => formData[client.client_seat_id])

      if (selectedClients.length) {
        if (this.props.organization.get('organization-client-invite') === 'engagement_automation')
          this.setState({processing: true}, () => BulkInviteActions.sendOrResendInvitationBulk({recipients: selectedClients}))
        else
          this.props.next(this.props.advisor, selectedClients)
      }
    }
  }

  render() {
    return (
      <div>
        <Forms.Form className='row-wrapper' ref='form' onSubmit={this.continue}>
          <div className='column-wrapper select-clients-column'>
            <label className='form-element'>{/* Forms.Label is too opinionated for this situation, so I added the class to apply consistent styling as a workaround. --BLR */}
              <Forms.Checkbox name='select-all' ref='select-all' onChange={this.toggleSelectAll} wrap={false} />
              <strong>Clients</strong>
            </label>
            {
              this.props.clients.data.map(row => (
                <Forms.Checkbox
                  key={row.client_seat_id.toString()}
                  label={`${row.last_name}, ${row.first_name}`}
                  name={row.client_seat_id.toString()}
                  ref={row.client_seat_id.toString()}
                  onChange={this.updateCheckAll}
                />
              ))
            }
          </div>
          <div className='column-wrapper select-clients-column'>
            <strong>Status</strong>
            {this.props.clients.data.map(client => <span key={client.client_seat_id.toString()}>{client.status}</span>)}
          </div>
          <div className='column-wrapper select-clients-column'>
            <strong>{this.props.firmConfig.get('professional-designation')}</strong>
            {this.props.clients.data.map(client => <span key={client.client_seat_id.toString()}>{client.advisor_name}</span>)}
          </div>
        </Forms.Form>
        <ButtonGroupWrapper>
          <Back />
          <Continue loading={this.props.processing || this.state.processing} onClick={this.continue} />
        </ButtonGroupWrapper>
      </div>
    )
  }
}

PickRecipients.propTypes = {
  advisor: PropTypes.object,
  bulkInvitedClients: PropTypes.object,
  clients: PropTypes.arrayOf(PropTypes.object),
  closer: PropTypes.func,
  firmConfig: PropTypes.instanceOf(Map),
  next: PropTypes.func,
  organization: PropTypes.instanceOf(Map),
  processing: PropTypes.bool
}

export default storeWrapper(PickRecipients, [{actionTypes: [BulkInviteActions.Types.DID_REINVITE_CLIENTS], name: 'bulkInvitedClients'}])
