import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const InterstitialsActions = new LegacyActionFactory([
  'DID_ACCEPT_DEPUTY_INVITE',
  'DID_DECLINE_DEPUTY_INVITE',
  'GOT_DEPUTY_INVITE',
  'GOT_FIRST_INTERSTITIAL',
  'GOT_INTERSTITIALS'
])

InterstitialsActions.buildBoundGet('interstitials', 'interstitials', InterstitialsActions.Types.GOT_INTERSTITIALS)

InterstitialsActions.fetchFirstInterstitial = firstInterstitialToken => {
  InterstitialsActions.fireApi(
    'get',
    `interstitials/first_interstitial/${firstInterstitialToken}`,
    null,
    {
      JSONHead: 'first_interstitial',
      successAction: InterstitialsActions.Types.GOT_FIRST_INTERSTITIAL
    }
  )
}

// TODO: move these Deputy-related actions to a more-appropriate location--BLR
InterstitialsActions.fetchDeputyInvite = deputyInviteId => {
  InterstitialsActions.fireApi(
    'get',
    `interstitials/deputies/${deputyInviteId}`,
    null,
    {
      JSONHead: 'deputy_invite',
      successAction: InterstitialsActions.Types.GOT_DEPUTY_INVITE
    }
  )
}

InterstitialsActions.acceptDeputyInvite = deputyInviteId => {
  InterstitialsActions.fireApi(
    'post',
    `deputies/${deputyInviteId}/accept`,
    null,
    {
      JSONHead: 'deputy',
      successAction: InterstitialsActions.Types.DID_ACCEPT_DEPUTY_INVITE
    }
  )
}

InterstitialsActions.declineDeputyInvite = deputyInviteId => {
  InterstitialsActions.fireApi(
    'put',
    `deputies/${deputyInviteId}/decline`,
    null,
    {
      JSONHead: 'deputy',
      successAction: InterstitialsActions.Types.DID_DECLINE_DEPUTY_INVITE
    }
  )
}

export default InterstitialsActions
