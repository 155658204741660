import PropTypes from 'prop-types'

import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import EditInvitationMessageModal from './EditInvitationMessageModal'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import UserSubpage from './UserSubpage'
import ShadowWrapper from './ShadowWrapper'

import FirmUserConfigStore from '../../stores/FirmUserConfigStore'
import FirmActions from '../../actions/FirmActions'

import './invitationMessage.scss'

export const InvitationMessage = props => {
  const messageDescription = 'This is the personalized message included on invitation emails to clients.'

  return (
    <UserSubpage className='invitation-message' headerText='Invitation Message'>
      <p>{messageDescription}</p>
      <ShadowWrapper>
        <LegacyLoader loading={props.loadingFirmUserConfig}>
          <p>{props.firm_user_config.data.default_invite_email_text}</p>
          <EditInvitationMessageModal
            currentInvitationMessage={props.firm_user_config.data.default_invite_email_text}
            messageDescription={messageDescription}
            params={props.params}
          />
        </LegacyLoader>
      </ShadowWrapper>
    </UserSubpage>
  )
}

InvitationMessage.defaultProps = {
  firm_user_config: {
    data: {},
    errors: [],
    message: null
  }
}

InvitationMessage.propTypes = {
  firm_user_config: PropTypes.shape({
    data: PropTypes.shape({
      default_invite_email_text: PropTypes.string,
      hide_draft_edit_warnings: PropTypes.bool,
      redtail_user_key: PropTypes.string
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string
  }).isRequired,
  loadingFirmUserConfig: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    firmId: PropTypes.string,
    firmUserSeatId: PropTypes.string
  }).isRequired
}

Container.registerAction('firm_user_config', FirmActions)
Container.registerStore('firm_user_config', FirmUserConfigStore)
export default bindResources(InvitationMessage, {name: 'firm_user_config', type: 'itemAllParams'})
