import {fromJS, Map} from 'immutable'
import PropTypes from 'prop-types'
import {useCallback, useRef} from 'react'

import {Accept, Cancel} from '../../../../dashboard/src/components/blocks/Buttons'
import Form from '../../../../shared_components/forms/v2/Form'
import Fields from '../../../../shared_components/forms/v2/fields'
import NewProgressBar from '../../../../shared_components/NewProgressBar'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'

import {ADDITIONAL_INFO} from '../../lib/jiclMainSectionConfiguration'

const JiclAnythingElse = props => {
  const formRef = useRef()

  const submitInfo = useCallback(() => {
    const additionalInfo = formRef.current.formData().getIn(['additional-info', 'value'])
    props.onInfoDataSubmit(Map({'additional-info': additionalInfo}))
  })

  return (
    <div className='jicl-anything-else'>
      <div className='section-title'>{ADDITIONAL_INFO.get('header')}</div>
      {!props.editScreen && (
        <NewProgressBar
          step={props.numberOfScreens - 1}
          total={props.numberOfScreens}
        />
      )}
      {ADDITIONAL_INFO.get('responseIntroTexts').map(text => (
        <TextGutterMedium key={text}>{text}</TextGutterMedium>
      ))}
      <Form className='core' ref={formRef}>
        <Fields.TextArea
          data={fromJS({
            legend: '',
            name: 'additional-info',
            id: 'additional-info',
            value: props.defaultValue
          })}
        />
        {props.editScreen ?
          (
            <div>
              <Accept onClick={submitInfo} processing={props.processing}>
              Save
              </Accept>
              <Cancel onClick={props.onResponseCancel} />
            </div>
          ) :
          (
            <Accept onClick={submitInfo}>Continue</Accept>
          )}
      </Form>
    </div>
  )
}

JiclAnythingElse.propTypes = {
  defaultValue: PropTypes.string,
  editScreen: PropTypes.bool,
  onInfoDataSubmit: PropTypes.func,
  numberOfScreens: PropTypes.number,
  onResponseCancel: PropTypes.func,
  processing: PropTypes.bool
}

export default JiclAnythingElse
