import PropTypes from 'prop-types'
import {Component} from 'react'

import LegacyLoader from '../../../../shared_components/LegacyLoader'
import PageTitle from '../../../../shared_components/PageTitle'
import OneStepConfirmUser from './one_step_sign_up/ConfirmUser'
import TwoStepConfirmUser from './two_step_sign_up/ConfirmUser'
import SignUpActions from '../../actions/SignUpActions'
import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'
import AuthAppClassSideEffect from '../AuthAppClassSideEffect'

import routerUtils from '../../../../lib/routerUtils'

import './confirmUser.scss'

export default class ConfirmUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false
    }

    this.nextStep = this.nextStep.bind(this)
  }

  nextStep(data = {}) {
    this.setState({processing: true})

    const userConfirmationData = this.userConfirmationData(data)

    // Just show errors if the user was not successfully confirmed: - SIMI
    if (!userConfirmationData)
      return this.setState({processing: false})

    const locationParam = userConfirmationData.client ? '2' : '1'

    return routerUtils.push(`/auth/security?loc=${locationParam}`)
  }

  userConfirmationData(data) {
    return $.isEmptyObject(data) ? this.props.user_confirmation.data : data
  }

  render() {
    const firstName = (this.props.user_confirmation && this.props.user_confirmation.data) ? this.props.user_confirmation.data.firstName : ''

    return (
      <div className='confirm-user central-content-box'>
        <HeaderFlavor flavor='transactional' />
        <PageTitle title='Email Confirmed' />
        <AuthAppClassSideEffect className='auth-interstitial' />
        <LegacyLoader loading={this.props.loadingUserConfirmation}>
          {
            this.props.showForm ?
              (
                <TwoStepConfirmUser
                  nextStep={this.nextStep}
                  processing={this.state.processing}
                  user={this.props.user_confirmation}
                  confirmationToken={this.props.params.userConfirmationId}
                />
              ) :
              <OneStepConfirmUser firstName={firstName} nextStep={this.nextStep} processing={this.state.processing} />
          }
        </LegacyLoader>
        {this.props.loadingUserConfirmation && <div className='confirming-message'>Confirming your account...</div>}
      </div>
    )
  }
}

ConfirmUser.onBoundUpdate = function (newBoundData) {
  if (newBoundData.errors && newBoundData.errors.length > 0) { this.goToThere('/auth/invalid_token/consumer_invite') } else if (!$.isEmptyObject(newBoundData.data) && !newBoundData.data.profile_complete) {
    this.setState({
      loading: false,
      loadingUserConfirmation: false,
      user_confirmation: newBoundData,
      showForm: true
    })
  } else {
    // NOTE: do not repeat the approach used below. I swear on everything I hold dear that while this works--i.e, the
    // insane context chain allows `this` to refer to the bindToStores context--it is not a pattern that should be
    // used ever again. Beware of strong penalties for repeating this approach. --Bart
    const dataValue = {}
    dataValue.confirmationToken = this.props.params.userConfirmationId

    SignUpActions.confirmUser(dataValue, data => {
      this.setState({
        loading: false,
        loadingUserConfirmation: false,
        user_confirmation: newBoundData
      })
    })
  }
}

ConfirmUser.defaultProps = {
  loadingUserConfirmation: true,
  showForm: false,
  user_confirmation: {
    data: {},
    errors: [],
    message: null
  }
}

ConfirmUser.propTypes = {
  loadingUserConfirmation: PropTypes.bool,
  showForm: PropTypes.bool,
  user_confirmation: PropTypes.shape({
    data: PropTypes.object,
    errors: PropTypes.array,
    message: PropTypes.string
  })
}


ConfirmUser.contextTypes = {
  router: PropTypes.func.isRequired
}
