/** @class ItemPermissionsStatus
 *
 * @desc
 * Displays the status of the item permissions for an everplan.
 *
 */

import PropTypes from 'prop-types'

const statusText = props => {
  if (props.hasPendingRequestItemPermissionsInvite)
    return 'Your request to edit this Everplan is still pending client approval.'
  else if (props.allUsersDeceased || !props.writeAccess || !props.hasEditPermissions)
    return 'You cannot edit this Everplan.'
  else
    return 'You can edit this Everplan.'
}

const ItemPermissionsStatus = props => (
  <span className='item-permissions-status'>{statusText(props)}</span>
)


ItemPermissionsStatus.propTypes = {
  allUsersDeceased: PropTypes.bool,
  writeAccess: PropTypes.bool,
  hasPendingRequestItemPermissionsInvite: PropTypes.bool,
  hasEditPermissions: PropTypes.bool
}

export default ItemPermissionsStatus
