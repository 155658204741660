import PropTypes from 'prop-types'

import ContactDetails from './ContactDetails'
import MarkdownText from '../shared_firm/settings/MarkdownText'

const EmailPreview = props => (
  <div className='email-preview'>
    {props.description && <p className='bold description'>{props.description}</p>}
    <div className='preview-body'>
      <button className='btn email-button' disabled>{`${props.buttonText} »`}</button>
      <div className='email-text' dangerouslySetInnerHTML={{__html: props.emailText}} />
      <ContactDetails contactCard={props.contactCard} />
      {
        props.disclosure.enabled && props.disclosure.show_in_email &&
        (
          <div className='email-disclosure'>
            <div className='email-disclosure-header'>Disclosure:</div>
            <MarkdownText displayText={props.disclosure.markdown_text} />
          </div>
        )
      }
    </div>
  </div>
)

EmailPreview.propTypes = {
  buttonText: PropTypes.string.isRequired,
  contactCard: PropTypes.object.isRequired,
  description: PropTypes.string,
  emailText: PropTypes.string.isRequired,
  disclosure: PropTypes.shape({
    enabled: PropTypes.bool,
    markdown_text: PropTypes.string,
    show_in_email: PropTypes.bool
  })
}

EmailPreview.defaultProps = {
  buttonText: '',
  description: '',
  emailText: '',
  disclosure: {
    enabled: false,
    markdown_text: '',
    show_in_email: false
  }
}

export default EmailPreview
