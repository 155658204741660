import {List} from 'immutable'
import PropTypes from 'prop-types'
import {Fragment} from 'react'
import {Link} from 'react-router'

import * as Links from '../../../../../shared_components/Links'

const JiclFormFooter = ({footer}) => {
  if (footer.isEmpty()) {
    return null
  } else {
    return (
      <Fragment>
        {footer.map(footerLink => {
          const FooterName = footerLink.get('linkComponentName')
          const FooterLink = FooterName ? Links[FooterName] : Link
          const linkPath = footerLink.get('path')
          const linkTarget = footerLink.get('target')

          return (
            <div key={footerLink.get('startText')} className='link-with-extra-text'>
              <span>{footerLink.get('startText')}</span>
              <FooterLink to={linkPath} target={linkTarget}>
                {footerLink.get('linkText')}
              </FooterLink>
              <span>{footerLink.get('endText')}</span>
            </div>
          )
        })}
      </Fragment>
    )
  }
}

JiclFormFooter.propTypes = {
  footer: PropTypes.instanceOf(List)
}

export default JiclFormFooter
