import {fromJS, Map} from 'immutable'
import PropTypes from 'prop-types'

import JiclSubSection from './sub_sections/JiclSubSection'

const sectionConfig = fromJS({
  header: '🎉 All done!',
  assessmentConfig: {
    notes: [
      'Do you want to send the Just-In-Case List to yourself or to someone else?'
    ],
    yesButtonText: 'Send it to me',
    noButtonText: 'Send it to someone else',
    value: 'send-to-self'
  }
})

const JiclAllDone = props => {
  const onAssessmentSubmit = value => {
    props.onInfoDataSubmit(Map({'send-to-self': value}))
  }

  return (
    <JiclSubSection
      className='jicl-all-done'
      numberOfScreens={props.numberOfScreens}
      subSection='assessment'
      sectionConfig={sectionConfig}
      sectionIndex={props.numberOfScreens - 1}
      onAssessmentSubmit={onAssessmentSubmit}
    />
  )
}

JiclAllDone.propTypes = {
  numberOfScreens: PropTypes.number,
  onInfoDataSubmit: PropTypes.func
}

export default JiclAllDone
