import adascLogo from '../../../../assets/adasc-logo.svg'
import ComplianceImage from './ComplianceImage'
import LinkListItem from './LinkListItem'
import EverplansLinksList from './EverplansLinksList'
import EverplansSocialMediaLinkListItem from './EverplansSocialMediaLinkListItem'
import MailchimpSubscriptionForm from './MailchimpSubscriptionForm'
import {footerData} from './footerData'
import './footer.scss'

const Footer = () => (
  <footer className='footer content-site-footer'>
    <div className='top-section'>
      <div className='main-container'>
        <div className='outer-container'>
          <div className='left-inner-container'>
            {footerData && footerData.everplansLinksLists.map(
              ({ariaLabel, listTitle, linksList}, index) => (
                <EverplansLinksList
                  key={index}
                  ariaLabel={ariaLabel}
                  listTitle={listTitle}
                  linksList={linksList}
                />
              )
            )}
          </div>
          <div className='right-inner-container'>
            <ul className='social-media-links-list'>
              {footerData.everplansSocialMediaLinks &&
                footerData.everplansSocialMediaLinks.map(
                  ({ariaLabel, logoSrc, url}, index) => (
                    <EverplansSocialMediaLinkListItem
                      key={index}
                      ariaLabel={ariaLabel}
                      logoSrc={logoSrc}
                      url={url}
                    />
                  )
                )
              }
            </ul>
            <MailchimpSubscriptionForm />
          </div>
        </div>
      </div>
    </div>
    <div className='bottom-section'>
      <div className='main-container'>
        <div className='outer-container'>
          <div className='left-inner-container'>
            {footerData && footerData.complianceImages.map(
              ({altText, className, imageSrc}, index) => (
                <ComplianceImage
                  key={index}
                  altText={altText}
                  className={className}
                  imageSrc={imageSrc}
                />
              )
            )}
            <a
              className='compliance-logo compliance-link'
              href='https://adasitecompliancetools.com/ap.asp?h=EVRPLN'
              rel='noreferrer'
              target='_blank'>
              <img alt='ADA Site Compliance' className='compliance-img' src={adascLogo} />
            </a>
          </div>
          <div className='right-inner-container'>
            <div className='copyright'>©{new Date().getFullYear()} Everplans</div>
            <ul className='agreement-links-list'>
              {footerData.everplansAgreementLinks &&
                footerData.everplansAgreementLinks.map(({linkText, url}, index) => (
                  <LinkListItem
                    key={index}
                    linkText={linkText}
                    url={url}
                  />
                ))
              }
            </ul>
          </div>
        </div>
        <div className='disclaimer'>Everplans is not a licensed healthcare provider, medical professional, law firm, or financial advisory firm, and the employees of Everplans are not acting as your healthcare providers, medical professionals, attorneys, or financial advisors.</div>
      </div>
    </div>
  </footer>
)

export default Footer
