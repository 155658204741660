import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const DeputyActions = new LegacyActionFactory([
  'DID_DECLINE_DEPUTY_INVITE'
])

DeputyActions.declineDeputyInviteAsPendingOnlyDeputy = deputyInviteId => {
  DeputyActions.fireApi(
    'put',
    `deputies/${deputyInviteId}/decline_by_token`,
    null,
    {
      JSONHead: 'deputy',
      successAction: DeputyActions.Types.DID_DECLINE_DEPUTY_INVITE
    }
  )
}

export default DeputyActions
