import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import CustomMessageEditor from '../../customize_invite/CustomMessageEditor'
import LegacyEmailPreviews from '../../customize_invite/LegacyEmailPreviews'
import Forms from '../../../../../shared_components/forms'
import HighComplianceNote from '../../customize_invite/HighComplianceNote'
import LegacyToAndFrom from '../../customize_invite/LegacyToAndFrom'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import {Cancel, Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import RequestItemPermissionsContainer from './RequestItemPermissionsContainer'
import ClientApprovalNote from './ClientApprovalNote'
import {requestedItemPermissions} from '../../../../../lib/itemPermissionTools'

import './inviteClient.scss'

export default class InviteClient extends Component {
  constructor() {
    super()

    this.state = {
      processing: false,
      itemPermissionIds: []
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.saveItemPermissions = this.saveItemPermissions.bind(this)
  }

  saveItemPermissions(checkedItemIds) {
    this.setState({itemPermissionIds: checkedItemIds})
  }

  handleSubmit() {
    this.setState({processing: true})
    const formData = this.refs.form.formData()

    this.props.handleSubmit({
      inviteText: formData.invite_text,
      saveEdits: formData.save_edits,
      itemPermissions: requestedItemPermissions({itemIds: this.state.itemPermissionIds, permissionType: 'edit'})
    })
  }

  anyoneActive() {
    return this.props.clientSeats.some(clientSeat => clientSeat.get('status') === 'Active')
  }

  render() {
    return (
      <div className='invite-client'>
        <HighComplianceNote isHighCompliance={this.props.isHighCompliance} />
        <LegacyToAndFrom {...this.props} recipients={[this.props.recipient]} />
        <Forms.Form ref='form'>
          <CustomMessageEditor {...this.props} />
        </Forms.Form>
        <RequestItemPermissionsContainer
          needsClientApproval={this.props.needsClientApproval}
          clientSeat={this.props.clientSeat}
          anyoneActive={this.anyoneActive()}
          saveItemPermissions={this.saveItemPermissions}
        />
        <LegacyEmailPreviews {...this.props} />
        <ClientApprovalNote {...this.props} />
        <ButtonGroup>
          <Cancel onClick={this.props.closeModal} />
          <Accept onClick={this.handleSubmit} processing={this.state.processing}>
            Send Invitation
          </Accept>
        </ButtonGroup>
      </div>
    )
  }
}

InviteClient.defaultProps = {
  disclosure: {
    data: {}
  }
}

InviteClient.propTypes = {
  anyoneActive: PropTypes.bool,
  closeModal: PropTypes.func,
  disclosure: PropTypes.shape({
    enabled: PropTypes.bool,
    markdown_text: PropTypes.string,
    show_in_email: PropTypes.bool
  }),
  isHighCompliance: PropTypes.bool,
  recipient: PropTypes.object,
  contactCard: PropTypes.shape({
    name: PropTypes.string
  }),
  emailTexts: PropTypes.array,
  handleSubmit: PropTypes.func,
  household: PropTypes.shape({
    clients: PropTypes.array
  }),
  clientSeat: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map),
  needsClientApproval: PropTypes.bool
}
