import PropTypes from 'prop-types'


const PurchasedSeatsCount = ({seatsCount, unlimited}) => (
  <span className='purchased-seats-count'>
    {unlimited ? 'Unlimited' : `${seatsCount} Purchased`}
  </span>
)

PurchasedSeatsCount.propTypes = {
  seatsCount: PropTypes.number,
  unlimited: PropTypes.bool
}

export default PurchasedSeatsCount
