/** @class CategoryDetails
 *
 * @desc
 * Class that displays a single `Category` and all its child `Items`.
 */

import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {memo} from 'react'

import CategoryHeader from '../../../../../dashboard/src/components/corp_max/everplan_preview/category/CategoryHeader'
import ItemDetails from './ItemDetails'
import {categoryItems} from '../../../../../lib/plan_data/category'

import './categoryDetails.scss'

const CategoryDetails = props => (
  <div className='category-details'>
    <CategoryHeader categoryName={props.category.get('name')} />
    {
      categoryItems(props.category, props.itemResponses).sortBy(
        itemResponse => itemResponse.get('sort-order')).map(itemResponse => (
        <ItemDetails
          {...props}
          key={itemResponse.get('item-id')}
          itemResponse={itemResponse}
        />
      ))
    }
  </div>
)

CategoryDetails.propTypes = {
  category: PropTypes.instanceOf(Map),
  itemResponses: PropTypes.instanceOf(List)
}

const areEqual = (prevProps, nextProps) => (
  nextProps.itemViews.equals(prevProps.itemViews) &&
  nextProps.itemResponses.equals(prevProps.itemResponses) &&
  prevProps.newContacts.equals(nextProps.newContacts)
)

export default memo(CategoryDetails, areEqual)
