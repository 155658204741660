export default [
  {
    id: '983e94c9-7e55-48f8-b00a-5f6c15a0daf6',
    mappings: [
      {
        'conditional-kit-id': 'f1d2d05b-3ac8-41ea-8721-4302f3330fc4',
        value: 'Attorney'
      },
      {
        'conditional-kit-id': 'bf4e0329-391b-4129-a73e-5342b1dadc4f',
        value: 'Online'
      }
    ],
    name: 'Will'
  },
  // list-mapping for simple conditional item
  {
    id: 'bf2353cf-ede9-469b-a435-5902ce1880e5',
    mappings: [
      {
        'conditional-kit-id': '8e0937d0-5cd5-48f1-b9b8-285d058bbd02',
        value: 'Social Security Card'
      },
      {
        'conditional-kit-id': 'b48ef133-a571-4f0e-893d-109a47fd1f46',
        value: "Driver's License"
      },
      {
        'conditional-kit-id': '78d9fddf-6764-4f0e-893d-109a47fd1f46',
        value: 'Other'
      }
    ],
    name: 'Document/ID list_mapping'
  },
  {
    // ListMapping without coniditional-kit-ids
    id: '8e5fe3e3-25ed-4c2c-a5fb-4b1c989cce4d',
    name: 'Types of Insurance list_mapping',
    mappings: [
      {
        value: 'Homeowners'
      },
      {
        value: 'Renters'
      }
    ]
  }
]
