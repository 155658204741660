import PropTypes from 'prop-types'
import React from 'react'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import * as ButtonTypes from '../../../../shared_components/ButtonTypes'
import Container from '../../../../lib/Container'
import Forms from '../../../../shared_components/forms'
import Modal from '../../../../shared_components/Modal'
import modalWrapper from '../../../../shared_components/ModalWrapper'
import StorePrototype from '../../../../shared_components/StorePrototype'
import validate from 'validate.js'
import {trimString} from '../../../../lib/textTools'

import FirmActions from '../../actions/FirmActions'

import './editFirmUserEmail.scss'

export class EditFirmUserEmail extends React.Component {
  constructor(props) {
    super(props)
    this.editFirmUser = this.editFirmUser.bind(this)
  }
  editFirmUser() {
    this.props.updating(() => this.context.closeModal())

    if (this.refs.form.validate().length === 0)
      FirmActions.editFirmUser(Object.assign(this.props.params, this.refs.form.formData()))
    else
      this.props.finished()
  }
  emailValidator(name, data) {
    data.email = trimString(data.email)
    const error = validate.single(data.email, {presence: true, email: true})

    if (error)
      return {name: 'email', errors: [`Email ${error}`]}
  }

  render() {
    return (
      <span className='edit-firm-user-email'>
        <a onClick={this.context.showModal}>Change email</a>
        <Modal visible={this.props.showModal}>
          <div className='modal-header'>Change Email Address</div>
          <Forms.Form ref='form' errors={this.props.errors}>
            <Forms.Input name='email' defaultValue={this.props.email} label='Email Address:' validator={this.emailValidator} />
          </Forms.Form>
          <div className='button-group'>
            <ButtonTypes.Cancel onClick={this.context.closeModal} />
            <ButtonTypes.Continue loading={this.props.processing} onClick={this.editFirmUser}>Save</ButtonTypes.Continue>
          </div>
        </Modal>
      </span>
    )
  }
}

EditFirmUserEmail.contextTypes = {
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func,
  showModal: PropTypes.func
}

EditFirmUserEmail.defaultProps = {
  errors: [],
  processing: false
}

EditFirmUserEmail.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  finished: PropTypes.func,
  params: PropTypes.shape({
    firmId: PropTypes.string,
    firmUserSeatId: PropTypes.string
  }).isRequired,
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  updating: PropTypes.func
}

Container.registerStore('editFirmUser', StorePrototype(FirmActions.Types.DID_EDIT_FIRM_USER))
export default modalWrapper(bindUpdate(EditFirmUserEmail, 'editFirmUser', 'email'))
