import classnames from 'classnames'
import PropTypes from 'prop-types'

import './statbox.scss'


const StatBox = props => (
  <div className={classnames('stat-box', props.className)}>
    <div className={classnames('box', props.boxStyle)}>
      <div className='stat'>{props.stat}</div>
      <div className='label'>{props.label}</div>
    </div>
  </div>
)

StatBox.propTypes = {
  boxStyle: PropTypes.oneOf([
    'active-box',
    'black-box',
    'pending-box',
    'purple-box'
  ]),
  label: PropTypes.string,
  stat: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}


export default StatBox
