import 'babel-polyfill'
import './shared_components/favicons'
import './jqueryShim'

import 'font-awesome/css/font-awesome.min.css'

import React from 'react'
import {Route} from 'react-router'
import payment from 'jquery.payment'

import AuthRoutes from './auth/src/lib/routes'
import ClientOnboardingRoutes from './client_onboarding/src/lib/routes'
import ConsumerRoutes from './consumer/src/lib/routes'
import DeputyFeedbackRoutes from './deputy_feedbacks/src/lib/routes'
import ProRoutes from './pro/src/lib/routes'
import InternalOnlyRoutes from './dev_only/lib/routes' // require doesn't work in ESModules and Vite is smart enough to not include unused import in production build
import DashboardRoutes from './dashboard/src/lib/routes'
import NotFoundRoute from './shared_components/NotFoundRoute'
import JiclRoutes from './jicl/src/lib/routes'
import ApplicationContainer from './shared_components/ApplicationContainer'

import SessionStore from './auth/src/stores/SessionStore'
import TfaConfigStore from './auth/src/stores/TfaConfigStore'
import TfaWizardStore from './auth/src/stores/TfaWizardStore'

/* eslint-enable global-require, no-unused-vars */

import EpRouter from './lib/epRouter'
import bootUtils from './lib/bootUtils'
import routerUtils from './lib/routerUtils'

// Note, the load order of these base style files is essential for the CSS override to funciton. DO NOT CHANGE IT.
// Messing with this order is a fireable offense. (And no one will like you.) -- BJK
import './styles/base.scss'

// This will prevent issues when we eventually get to server side rendering - Atanda
if (window) {
  window.TfaConfigStore = TfaConfigStore
  window.SessionStore = SessionStore
  window.TfaWizardStore = TfaWizardStore

  window.React = React
  window.payment = payment
}

const appRoutes = [
  JiclRoutes,
  DeputyFeedbackRoutes,
  AuthRoutes,
  ClientOnboardingRoutes,
  ConsumerRoutes,
  ProRoutes,
  DashboardRoutes
]

/* eslint-disable no-undef */
// Only include certain routes outside the production environment:
if (INTERNAL_ONLY)
  appRoutes.push(InternalOnlyRoutes)
/* eslint-enable no-undef */

// NotFoundRoute MUST STAY AS THE LAST ROUTE... OR ELSE the 404 page won't be the default route --NTP
appRoutes.push(NotFoundRoute) // DO NOT CHANGE --NTP

const routes = React.createElement(
  Route,
  {component: ApplicationContainer},
  appRoutes
)

function redirectNakedPath(location) {
  if (location.pathname === '/')
    routerUtils.setLocation(API_HOST)
}

function bindRoutes() {
  EpRouter(
    routes,
    $('#app'),
    location => {
      redirectNakedPath(location)
      bootUtils.setAppName(location)
    })
}

export default bindRoutes

if (!__TEST__)
  bootUtils.bootConfig(bindRoutes)


// look here when we want to do async loading of the bundles:
// https://github.com/rackt/react-router/blob/master/examples/partial-app-loading/app.js

// and here too:
// https://github.com/petehunt/webpack-howto/issues/18
