import PropTypes from 'prop-types'
import React from 'react'

import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'
import NewLoader from '../../../../shared_components/NewLoader'
import * as Links from '../../../../shared_components/Links'

import AuthAppClassSideEffect from '../AuthAppClassSideEffect'
import routerUtils from '../../../../lib/routerUtils'
import {scrollToTopOfPage} from '../../../../lib/scrollTools'
import currentUserWrapper from '../../../../shared_components/currentUserWrapper'
import GlobalStyleOverrides from '../../../../shared_components/core/style-overrides/GlobalStyleOverrides'
import PageTitle from '../../../../shared_components/PageTitle'
import SignUpActions from '../../actions/SignUpActions'

import './resendConfirmation.scss'

export class ResendConfirmation extends React.Component {
  UNSAFE_componentWillMount() {
    if (this.props.preAuthEmail)
      this.resendConfirmation()
    else
      routerUtils.push('/#/auth/sign_in')

    scrollToTopOfPage()
  }

  resendConfirmation() {
    SignUpActions.resendConfirmationEmail({
      email: this.props.preAuthEmail,
      medium: 'web failed sign in'
    })
  }

  isLoading() { return !this.props.preAuthEmail }

  render() {
    return (
      <NewLoader loading={this.isLoading()}>
        <GlobalStyleOverrides className='resend-confirmation central-content-box'>
          <AuthAppClassSideEffect className='auth-interstitial' />
          <HeaderFlavor flavor='transactional' />
          <PageTitle title='Resend Confirmation Email' />
          <div className='check-email-confirmation'>
            <h1>Please check your email to confirm your account.</h1>
            <p>For security purposes, we need you to confirm your email address before you can use Everplans.</p>
            <p>To do this, please check your email for a message we just sent to <strong>{this.props.preAuthEmail}</strong> with the subject line "Confirmation instructions."</p>
            <p>You must click the "Confirm your email" button in that email.</p>
          </div>
          <div className='having-trouble'>
            <h6>Having Trouble?</h6>
            <p>Please be patient while waiting for your email. Depending on your network settings, it might take a few minutes to be delivered.</p>
            <p>If you need assistance, please visit our <Links.Help>Help Center</Links.Help>.</p>
          </div>
        </GlobalStyleOverrides>
      </NewLoader>
    )
  }
}

// The `preAuthEmail` is being passed to this component by the `currentUserWrapper`.
// Do not remove the currentUserWrapper. - Adebayo/Bart
export default currentUserWrapper(ResendConfirmation)

ResendConfirmation.propTypes = {
  preAuthEmail: PropTypes.string
}
