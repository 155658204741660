import classnames from 'classnames'
import PropTypes from 'prop-types'

import ShadowHeader from './ShadowHeader'

import './userSubpage.scss'

const UserSubpage = props => (
  <div className={classnames('subpage', props.className)}>
    <ShadowHeader headerText={props.headerText} />
    {props.children}
  </div>
)

UserSubpage.propTypes = {
  headerText: PropTypes.string
}

export default UserSubpage
