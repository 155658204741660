import Loader from '../../../../../shared_components/NewLoader'

import './loadingClientActivities.scss'

const LoadingClientActivities = () => (
  <div className='loading-client-activities clients-activities'>
    <div className='content'>
      <p>Finding recent client activities...</p>
      <Loader loading={true} />
    </div>
  </div>
)

export default LoadingClientActivities
