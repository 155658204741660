import PropTypes from 'prop-types'


const NoRecords = props => (
  <fieldset className='add-records'>
    <p>
      There were no valid entries to upload. Please see the warning(s) below. Please update your .csv and <a onClick={props.goBack}>upload a new version</a>.
    </p>
  </fieldset>
)

NoRecords.propTypes = {
  goBack: PropTypes.func.isRequired
}

export default NoRecords
