import {Fragment} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {capitalize} from '../../../../../lib/tools'

const Recipient = ({recipient}) => (
  <Fragment>
    <span className='name-list'>{`${capitalize(recipient.get('first-name'))} ${capitalize(recipient.get('last-name'))}`}</span>
    <span className='email-label'>{recipient.get('email').toLowerCase()}</span>
  </Fragment>
)

Recipient.propTypes = {recipient: PropTypes.instanceOf(Map)}

export default Recipient
