import {Fragment} from 'react'
import PropTypes from 'prop-types'
import {List} from 'immutable'

import Recipient from './Recipient'
import UnorderedList from '../../../../../shared_components/UnorderedList'

import './recipientsDetails.scss'

const RecipientsDetails = props => (
  <span className='recipients-details'>
    {
      props.recipientSeats.size > 1 ?
        <Fragment>
          <span className='recipient-count'>
            <span>{props.recipientSeats.size} Recipients </span>
            <a onClick={props.toggleShow}>{props.showRecipients ? 'hide' : 'show'}</a>
          </span>
          {
            props.showRecipients && (
              <div className='recipient-list'>
                <UnorderedList
                  children={props.recipientSeats.map(seat => (
                    <Recipient key={seat.get('id')} recipient={seat} />
                  ))}
                />
              </div>
            )
          }
        </Fragment> :
        <span className='recipient-count'>
          <Recipient recipient={props.recipientSeats.first()} />
        </span>
    }
  </span>
)

RecipientsDetails.propTypes = {
  recipientSeats: PropTypes.instanceOf(List),
  showRecipients: PropTypes.bool,
  toggleShow: PropTypes.func
}

export default RecipientsDetails
