import {Fragment} from 'react'
import {Link} from 'react-router'
import {formatDate} from '../../../../../../lib/dateTools'

import {InputText} from 'primereact/inputtext'
import {MultiSelect} from 'primereact/multiselect'

export const nameTemplate = rowData => (
  <Link to={`pro/household/${rowData.id}`}>
    <span>{rowData.name}</span>
  </Link>
)

export const statusClass = status => (
  ['Active', 'Pending', 'Requesting Approval'].includes(status) ?
    status.replace(' ', '-').toLowerCase() :
    ''
)

export const statusBodyTemplate = rowData => {
  const statuses = rowData.status.split(', ')

  if (statuses.length > 1) {
    return (
      <Fragment>
        <span className={statusClass(statuses[0])}>{statuses[0]}</span>
        <span>, </span>
        <span className={statusClass(statuses[1])}>{statuses[1]}</span>
      </Fragment>
    )
  } else {
    return <span className={statusClass(statuses[0])}>{statuses[0]}</span>
  }
}

export const advisorItemTemplate = option => <span>{option.label}</span>

export const advisorCellTemplate = advisors => rowData => {
  const firmUser = advisors.find(
    advisor => advisor.value === rowData['advisor-id']
  )
  return <span>{firmUser.label}</span>
}

export const lastLoginDateTemplate = rowData => (
  <span>{formatDate(rowData['last-login-date'])}</span>
)

export const loginCountTemplate = rowData => (
  <span>{rowData['sign-in-count'] || 0}</span>
)

export const advisorMultiSelectTemplate = ({
  advisorNames,
  onAdvisorChange,
  selectedAdvisorTemplate,
  selectedAdvisors
}) => (
  <div className='p-field'>
    <label htmlFor='advisor'>Advisors:</label>
    <MultiSelect
      name='advisor'
      value={selectedAdvisors}
      options={advisorNames}
      itemTemplate={advisorItemTemplate}
      selectedItemsLabel='{0} Advisors selected'
      onChange={onAdvisorChange}
      placeholder='All'
      selectedItemTemplate={selectedAdvisorTemplate}
      maxSelectedLabels={1}
      className='advisor'
    />
  </div>
)

export const statusMultiSelectTemplate = ({
  selectedStatuses,
  statuses,
  onStatusesChange
}) => (
  <div className='p-field'>
    <label htmlFor='status'>Status:</label>
    <MultiSelect
      name='status'
      value={selectedStatuses}
      options={statuses}
      selectedItemsLabel='{0} Statuses selected'
      onChange={onStatusesChange}
      optionLabel='name'
      optionValue='name'
      placeholder='All'
      maxSelectedLabels={1}
      className='status'
    />
  </div>
)

export const headerTemplate = params => (
  <div className='table-header p-d-flex p-flex-column p-flex-md-row'>
    <span className='p-input-icon-left p-d-inline'>
      <i className='pi pi-search' />
      <InputText
        type='search'
        value={params.name}
        onInput={params.searchName}
        placeholder='Search clients'
      />
    </span>
    <div className='p-formgroup-inline'>
      {statusMultiSelectTemplate({
        selectedStatuses: params.selectedStatuses,
        statuses: params.statuses,
        onStatusesChange: params.onStatusesChange
      })}
      {advisorMultiSelectTemplate({
        advisorNames: params.advisorNames,
        onAdvisorChange: params.onAdvisorChange,
        selectedAdvisorTemplate: params.selectedAdvisorTemplate,
        selectedAdvisors: params.selectedAdvisors
      })}
    </div>
  </div>
)
