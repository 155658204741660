import {Link} from 'react-router'
import PropTypes from 'prop-types'
import React from 'react'
import {Text} from '../../../../../dashboard/src/components/blocks/Texts'

import Wrench from '../../../../assets/images/wrench.png'

import './noActiveAdvisors.scss'


const NoActiveAdvisors = props => (
  <div className='component no-active-advisors'>
    <div className='content'>
      <Text className='heading flex-container sub-header'>
        <h1>Client List</h1>
      </Text>
      <div className='message row-wrapper-sb'>
        <img src={Wrench} />
        <section>
          <p>
              In order to add clients, assign them to advisors, or otherwise manage client accounts, you must have at
              least one Advisor listed as "active" in your firm.
          </p>
          <p>To resolve this issue, please do the following:</p>
          <ol>
            <li>Go to your <Link to={`/pro/firm/${props.firmId}/users`}>Firm Settings » Firm Users</Link> page.</li>
            <li>If no Advisors are listed, please <strong>add an advisor</strong>.</li>
            <li>
                If an Advisor is listed, but marked as "Pending," please reach out to that person and ask them to accept
                their invitation to Everplans Professional.
            </li>
          </ol>
          <div>- - -</div>
          <section>If you are unable to resolve this issue on your own, please contact your account manager.</section>
        </section>
      </div>
    </div>
  </div>
)

NoActiveAdvisors.propTypes = {
  firmId: PropTypes.number
}

export default NoActiveAdvisors
