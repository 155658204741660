import PropTypes from 'prop-types'
import React from 'react'

import Button from '../../../../../shared_components/mui_base_components/button/Button'
import Forms from '../../../../../shared_components/forms'

import Container from '../../../../../lib/Container'
import {expireCookie, getCookie} from '../../../../../lib/cookieJar'
import {parseUtmParams} from '../../../../../lib/partner'
import routerUtils from '../../../../../lib/routerUtils'
import SignUpActions from '../../../actions/SignUpActions'
import {trimString} from '../../../../../lib/textTools'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'

export default class SignUp extends React.Component {
  constructor() {
    super()

    this.store = Container.getStore('sign_up')

    this.state = {processing: false, errors: [], emailError: []}

    this.onUpdate = this.onUpdate.bind(this)
    this.resetError = this.resetError.bind(this)
    this.signUp = this.signUp.bind(this)
  }

  componentDidMount() {
    this.store.addChangeListener(this.onUpdate)

    if (!window.dataLayer) {
      const gtmScript = document.getElementById('gtm-react')

      if (gtmScript)
        eval(gtmScript.textContent)
    }
  }
  componentWillUnmount() {
    this.store.removeChangeListener(this.onUpdate)
  }
  onUpdate() {
    const state = this.store.getState()
    this.setState({errors: state.errors, processing: false})

    if (state.errors.email)
      this.setState({emailError: [state.errors.email], errors: state.errors.email})

    if (state.errors.length === 0) {
      expireCookie('ep_ref_data')
      expireCookie('ep_con_ref')
      routerUtils.push('auth/sign-up/check-email')
    }
  }

  /**
   * Gets the ep_ref_data cookie and parses it into a javascript
   * object for easy manipulation of data that will be sent to the payload
   * @returns {object} ep_ref_data
   */
  signUp() {
    MixpanelLogger.track('click_create_account')
    this.resetError()
    const errors = this.refs.form.validate()

    if (errors.length === 0) {
      this.props.setEmail(this.refs.form.formData().email)
      const data = {
        sign_up_medium: 'web standard signup', // Cannot use just `medium` as that is used in the UTM params merged in below. --BLR
        ...this.refs.form.formData(),
        ...parseUtmParams(this.props.query)
      }

      this.setState({errors, processing: true}, () => {
        SignUpActions.signUp(data)
      })
    } else {
      this.setState({errors})
    }
  }

  emailValidator(name, data) {
    const constraints = {}
    constraints[name] = {email: {message: '^Email is not valid'}, presence: true}
    data.email = trimString(data.email)
    return Forms.Validators.template(name, data, constraints)
  }
  resetError() {
    this.setState({emailError: []})
  }

  render() {
    // This cookie gets set in the instapage and expires in 5 minutes so users coming from nudge emails won't have it. -- JA
    const emailCookie = getCookie('email') || this.props.query.email || ''
    return (
      <Forms.Form onSubmit={this.signUp} ref='form' errors={[{name: 'email', errors: this.state.emailError}]}>
        <Forms.Input label='Email address:' name='email' placeholder='Email address' type='email' className='email' validator={this.emailValidator} defaultValue={emailCookie.toLowerCase().trim()} />
        <Forms.Password className='password-control' name='password' placeholder='Password' label='Password:' />
        <Forms.ServiceTerms name='agreementAccepted' />
        <div className='button-container'>
          <Button
            loading={this.state.processing}
            onClick={this.signUp}
            type='submit'>
              Create account
          </Button>
        </div>
      </Forms.Form>
    )
  }
}

SignUp.contextTypes = {
  router: PropTypes.func.isRequired
}

SignUp.propTypes = {
  query: PropTypes.shape({
    email: PropTypes.string,
    ep_ref: PropTypes.string,
    utm_campaign: PropTypes.string,
    utm_content: PropTypes.string,
    utm_medium: PropTypes.string,
    utm_source: PropTypes.string,
    utm_term: PropTypes.string
  }),
  setEmail: PropTypes.func
}

SignUp.defaultProps = {
  query: PropTypes.shape({
    email: '',
    ep_ref: ''
  })
}
