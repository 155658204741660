export default [
  {
    id: '2505df42-9f71-4e0f-a9a5-8605eb8977fa',
    'kit-id': 'e10f1b41-236a-4197-a8e7-9677bde4cf2d',
    header: 'My Life Insurance Policy',
    name: 'Life Insurance-My Life Insurance Policy',
    prompt: 'If you have multiple insurance policies you can add each one individually. Yaniv',
    slug: 'life-insurance-my-life-insurance-policy-1',
    'category-id': '292bbbfa-d3e1-4307-926e-c176dfd7f5f4',
    importable: true,
    importance: 'non-essential',
    'sort-order': 1
  },
  {
    id: '52ebdd98-2247-4102-a4ad-0f54af443809',
    'kit-id': '3fe99584-7784-42b9-b336-f0364eb3c953',
    header: 'My second will',
    name: 'Will-My Will',
    prompt: "Wait till I'm dead before you look, Kay.",
    slug: 'will-details-about-your-will-two',
    'category-id': 'b3e4028d-6138-424d-a47f-3493f64ee0b8',
    importable: null,
    importance: 'essential',
    'sort-order': 2
  },
  {
    id: '52ebdd98-2247-4102-a4ad-0f54af443803',
    'kit-id': '3fe99584-7784-42b9-b336-f0364eb3c953',
    header: 'My will',
    name: 'Will-My Will',
    prompt: "Wait till I'm dead before you look, maneesh.",
    slug: 'will-details-about-your-will',
    'category-id': 'b3e4028d-6138-424d-a47f-3493f64ee0b8',
    importance: 'essential',
    importable: null,
    'sort-order': 1
  },
  {
    id: '52esftdd98-2247-4e02-a4ad-0f54af443803',
    'kit-id': '6b2f4ec7-5d3f-4da4-804f-c4f9906ba840',
    header: 'My Life Insurance Policy',
    name: 'Life Insurance-My Life Insurance Policy',
    prompt: "Wait till I'm dead before you look, maneesh.",
    slug: 'life-insurance-my-life-insurance-policy',
    'category-id': '1c9af535-ffa9-43e0-9755-c8cee6ae3057',
    importable: null,
    importance: 'non-essential',
    'sort-order': 1
  },
  {
    id: '732557e4-6f0d-4b2e-83fa-90b625d3976d',
    name: 'Financial Advisors-Financial Advisors',
    slug: 'financial-advisors-financial-advisors',
    'kit-id': '4b82c8b0-9607-43ec-ba9d-e9a11b2128d2',
    header: 'Advisors and Agents',
    prompt: 'Enter information about your Financial Planners, Investment Managers, and other Advisors.',
    'category-id': 'b3e4028d-6138-424d-a47f-3493f64ee0b8',
    importable: true,
    importance: 'non-essential',
    'sort-order': 1
  },
  {
    id: '31782abb-17bc-4810-b7b2-7afeebec22f8',
    name: 'Pets-My Pets',
    slug: 'pets-my-pets',
    'kit-id': 'f216dc88-d40c-4a63-8985-2d986ccd39f4',
    header: 'My Pets',
    prompt: 'If you have multiple Pets you can add each one individually.',
    'category-id': '9c6024c6-251a-4507-b841-99fdc7181363',
    importable: null,
    importance: 'non-essential',
    'sort-order': 1
  },
  {
    id: '9135a9f1-052d-449b-9ef3-263264f79d62',
    name: 'IDs and Vital Documentation',
    slug: 'about-me-about-me-q-vital-documentation',
    'kit-id': '2f5f0985-2eb2-4a99-a73c-7f0e51ee0275',
    header: 'IDs and Vital Documentation',
    prompt: null,
    'category-id': 'b3e4028d-6138-424d-a47f-3493f64ee0b8',
    importable: null,
    importance: 'non-essential',
    'sort-order': 3
  },
  {
    id: '9f06a092-6df5-4cf5-8866-66fa64e700eb',
    name: 'Advance Directive',
    slug: 'advance-directive',
    'kit-id': '30c04330-7cc1-460a-b75d-316c33ffe4ce',
    header: 'Advance Directive',
    prompt: null,
    'category-id': 'a4d06d17-bc65-4db4-8222-8738e19ae348',
    importable: null,
    importance: 'essential',
    'sort-order': 3,
    'guidance-text': 'Where do you keep the original, signed paperwork for your Advance Directive?',
    legacyDocumentId: 73
  },
  {
    id: 'd2e33237-5ec3-469d-951f-58504b94204e',
    name: 'Power of Attorney',
    slug: 'power-of-attorney-my-power-of-attorney',
    'kit-id': '60fd87e7-98a4-4cd1-9bc3-a06dac1464b9',
    header: 'Power of Attorney',
    prompt: '',
    'category-id': 'ef54efda-8a26-4bf4-b217-7284cf793767',
    importable: null,
    importance: 'essential',
    'sort-order': 3,
    'guidance-text': 'What kind of Power of Attorney have you created, and where is the signed paperwork?',
    legacyDocumentId: 45
  }
]
