import OpenIdConnectLink from '../../../../auth/src/components/sso/OpenIdConnectLink'
import {TextGutterLarge} from '../../../../dashboard/src/components/blocks/Texts'

import './interstitialOpenIdConnectLink.scss'

const InterstitialOpenIdConnectLink = () => (
  <div className='interstitial-open-id-connect-link central-content-box'>
    <h2>Link your Microsoft Account</h2>
    <TextGutterLarge>
      Your firm currently belongs to an organization (Northwestern Mutual) that
      requires you to connect your Microsoft account with your Everplans
      account. Please do so before we continue.
    </TextGutterLarge>
    <OpenIdConnectLink nextPath='/pending-requests' />
  </div>
)

export default InterstitialOpenIdConnectLink
