import PropTypes from 'prop-types'
import React from 'react'

import {Cancel, Continue, ControlButton} from '../../../../../shared_components/ButtonTypes'
import Modal from '../../../../../shared_components/Modal'
import modalWrapper from '../../../../../shared_components/ModalWrapper'
import ShadowHeader from '../ShadowHeader'
import StatusToggle from './StatusToggle'

import FirmActions from '../../../actions/FirmActions'

import './editFirmConfig.scss'

import ContactAccountManagerNote from './ContactAccountManagerNote'
import ShadowWrapper from '../ShadowWrapper'

export class EditCompliance extends React.Component {
  constructor(props) {
    super(props)

    this.state = {isHighCompliance: props.isHighCompliance}

    this.toggleComplianceLevel = this.toggleComplianceLevel.bind(this)
    this.saveComplianceLevel = this.saveComplianceLevel.bind(this)
  }
  toggleComplianceLevel() {
    this.setState({isHighCompliance: !this.state.isHighCompliance})
  }
  saveComplianceLevel() {
    FirmActions.updateFirmConfig({
      firmId: this.props.firmId,
      data: {firm_config: {compliance_level: (this.state.isHighCompliance ? 'high' : 'standard')}}
    })
    this.context.closeModal()
  }

  render() {
    const isHighCompliance = this.props.isHighCompliance
    return (
      <ShadowWrapper>
        <div className='edit-compliance settings row-wrapper'>
          <p>Status: </p>
          <div className='column-wrapper'>
            <p>{isHighCompliance ? 'Not Allowed' : 'Allowed'}</p>
            <p className='description'>{isHighCompliance ?
              'Firm users cannot send customized emails to clients.' :
              'Firm users can send customized emails to clients.'}</p>
            {
              this.props.isFirmActive && this.props.isAccountManager &&
              (
                <div className='edit-config'>
                  <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
                  <Modal visible={this.props.showModal}>
                    <ShadowHeader headerText='Custom Invite Message' />
                    <div className='config-wrapper'>
                      <StatusToggle
                        description={{
                          disabled: 'Firm users can send customized emails to clients.',
                          enabled: 'Firm users cannot send customized emails to clients.'
                        }}
                        enabledChecked={isHighCompliance}
                        name='compliance'
                        onChange={this.toggleComplianceLevel}
                        statusText={{
                          disabled: 'Allowed',
                          enabled: 'Not Allowed'
                        }}
                      />
                    </div>
                    <div className='button-group'>
                      <Cancel onClick={this.context.closeModal} />
                      <Continue onClick={this.saveComplianceLevel}>Save</Continue>
                    </div>
                  </Modal>
                </div>
              )
            }
            <ContactAccountManagerNote isAccountManager={this.props.isAccountManager} />
          </div>
        </div>
      </ShadowWrapper>
    )
  }
}

EditCompliance.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

EditCompliance.defaultProps = {
  isHighCompliance: false,
  showModal: false
}

EditCompliance.propTypes = {
  statusText: PropTypes.string,
  isAccountManager: PropTypes.bool,
  description: PropTypes.string,
  isHighCompliance: PropTypes.bool.isRequired,
  firmId: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  isFirmActive: PropTypes.bool
}

export default modalWrapper(EditCompliance)
