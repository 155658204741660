import PropTypes from 'prop-types'
import {Component} from 'react'

import {Cancel, Continue, ControlButton} from '../../../../../../shared_components/ButtonTypes'
import Form from '../../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../../shared_components/forms/v2/fields'
import {fromJS} from 'immutable'

import ImageUpload from '../../../admin/ImageUpload'
import Modal from '../../../../../../shared_components/Modal'
import ShadowHeader from '../../ShadowHeader'
import StatusToggle from '../StatusToggle'

import bindUpdate from '../../../../../../shared_components/BoundUpdate'
import CobrandingActions from '../../../../../../shared_actions/CobrandingActions'
import modalWrapper from '../../../../../../shared_components/ModalWrapper'

import EverplansBrandedFields from './EverplansBrandedFields'
import SharedCobrandingFields from './SharedCobrandingFields'
import WhiteLabelFields from './WhiteLabelFields'

import CobrandingPreviewLink from '../CobrandingPreviewLink'
import ContactAccountManagerNote from '../ContactAccountManagerNote'
import ShadowWrapper from '../../ShadowWrapper'


import './editCobranding.scss'


export class EditCobranding extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cobrandingEnabled: props.cobranding.attributes['cobranding-enabled'],
      dataUrl: props.cobranding.attributes['cobranding-logo-url'],
      isWhiteLabelStyle: props.cobranding.attributes.style === 'white_label'
    }

    this.resetState = this.resetState.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.setDataUrl = this.setDataUrl.bind(this)
    this.toggleStyle = this.toggleStyle.bind(this)
    this.toggleCobranding = this.toggleCobranding.bind(this)
  }

  toggleCobranding() {
    this.setState({cobrandingEnabled: !this.state.cobrandingEnabled})
  }

  toggleStyle() {
    this.setState({isWhiteLabelStyle: !this.state.isWhiteLabelStyle})
  }

  setDataUrl(dataUrl) { this.setState({dataUrl}) }

  onSubmit() {
    this.props.updating(() => this.context.closeModal())

    // Negative case must come first because when cobranding is disabled this.refs.form is null and validate() fails. --BLR
    if (!this.state.cobrandingEnabled) {
      CobrandingActions.updateCobranding({
        id: this.props.cobranding.id,
        attributes: {'cobranding-enabled': false}
      })
    } else {
      const formData = this.state.isWhiteLabelStyle ? this.refs.whiteLabelForm.onSubmit() : this.refs.everplansBrandedForm.onSubmit()
      if (formData) {
        CobrandingActions.updateCobranding({
          id: this.props.cobranding.id,
          attributes: {
            ...formData.map(data => data.get('value')).toJS(),
            'cobranding-logo': this.state.dataUrl,
            'cobranding-enabled': true,
            style: this.state.isWhiteLabelStyle ? 'white_label' : 'everplans_branded'
          }
        })
      }
    }
    this.props.finished()
  }

  resetState() {
    this.setState({
      cobrandingEnabled: this.props.cobranding.attributes['cobranding-enabled'],
      dataUrl: this.props.cobranding.attributes['cobranding-logo-url'],
      isWhiteLabelStyle: this.props.cobranding.attributes.style === 'white_label'
    })
    this.context.closeModal()
  }

  render() {
    const cobrandingEnabled = this.state.cobrandingEnabled
    return (
      <ShadowWrapper>
        <div className='edit-cobranding settings row-wrapper'>
          <p>Status: </p>
          <div className='column-wrapper'>
            <p>{this.props.cobranding.attributes['cobranding-enabled'] ? 'Enabled' : 'Disabled'}</p>
            <p className='description'>{this.props.cobranding.attributes['cobranding-enabled'] ?
              'Clients will get a cobranded Everplans experience.' :
              'Clients will see standard Everplans logo and colors.'}</p>
            <CobrandingPreviewLink {...this.props} />
            {
              this.props.isFirmActive && this.props.isAccountManager &&
              (
                <div className='edit-config'>
                  <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
                  {/* show with state */}
                  <Modal visible={this.props.showModal}>
                    <ShadowHeader headerText='Cobranding' />
                    <div className='config-wrapper'>
                      <StatusToggle
                        description={{
                          disabled: 'Clients see the standard Everplans header and footer.',
                          enabled: 'Clients see a cobranded header and footer.'}}
                        enabledChecked={cobrandingEnabled}
                        name='cobranding'
                        onChange={this.toggleCobranding}
                        statusText={{disabled: 'Disabled', enabled: 'Enabled'}}
                      />
                      { cobrandingEnabled &&
                      (
                        <section className='cobranding-form forms-playground'>
                          <div className='image-upload-container'>
                            <span className='logo-upload-label'>Firm Logo:</span>
                            <span className='logo-upload-wrapper'>
                              {this.state.dataUrl && <img className='cobranding-logo' src={this.state.dataUrl} alt='logo' />}
                              <ImageUpload onImageLoad={this.setDataUrl}>
                                <span className='upload-image'>{this.state.dataUrl ? 'Change' : 'Upload'}</span>
                              </ImageUpload>
                            </span>
                          </div>
                          <Form className='core side-labels' ref='form'>
                            <Fields.VerticalRadioList
                              data={
                                fromJS({
                                  id: 'style',
                                  items: [
                                    {id: 'white_label', label: 'White label', value: 'white_label'},
                                    {id: 'everplans_branded', label: 'Everplans branded', value: 'everplans_branded'}
                                  ],
                                  name: 'style',
                                  legend: 'Style:',
                                  value: this.props.cobranding.attributes.style
                                })
                              }
                              onChange={this.toggleStyle}
                            />
                          </Form>
                          {
                            this.state.isWhiteLabelStyle ?
                              <Form ref='whiteLabelForm' className='core side-labels'>
                                <SharedCobrandingFields cobranding={this.props.cobranding.attributes} />
                                <WhiteLabelFields cobranding={this.props.cobranding.attributes} />
                              </Form> :
                              <div>
                                <Form ref='everplansBrandedForm' className='core side-labels'>
                                  <SharedCobrandingFields cobranding={this.props.cobranding.attributes} />
                                  <EverplansBrandedFields cobranding={this.props.cobranding.attributes} />
                                </Form>
                              </div>
                          }
                        </section>
                      )
                      }
                      <div className='button-group'>
                        <Cancel onClick={this.resetState} />
                        <Continue loading={this.props.processing} onClick={this.onSubmit}>Save</Continue>
                      </div>
                    </div>
                  </Modal>
                </div>
              )
            }
            <ContactAccountManagerNote isAccountManager={this.props.isAccountManager} />
          </div>
        </div>
      </ShadowWrapper>
    )
  }
}

EditCobranding.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

EditCobranding.defaultProps = {
  cobrandingSetting: {},
  showModal: false
}

EditCobranding.propTypes = {
  statusText: PropTypes.string,
  isAccountManager: PropTypes.bool,
  description: PropTypes.string,

  cobranding: PropTypes.shape({
    attributes: PropTypes.shape({
      'cobranding-enabled': PropTypes.bool,
      'cobranding-logo-url': PropTypes.string,
      'cobranding-background-color': PropTypes.string,
      'cobranding-text-color': PropTypes.string,
      'cobranding-url-string': PropTypes.string,
      'cobranding-prefix': PropTypes.string,
      style: PropTypes.string
    }),
    id: PropTypes.string
  }),
  finished: PropTypes.func,
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  updating: PropTypes.func,
  isFirmActive: PropTypes.bool
}

export default modalWrapper(bindUpdate(EditCobranding, 'cobrandings', 'cobranding_url_string'))
