import classnames from 'classnames'

import './inputs.scss'


export const Base = props => <input {...props} className={classnames('core', props.className)} />

export const Text = props => (
  <Base
    {...props}
    className={classnames('text', props.className)}
    type='text'
  />
)
