import PropTypes from 'prop-types'
import {Component} from 'react'
import uuid from 'uuid'

import Forms from '../../../../../shared_components/forms'
import Modal from '../../../../../shared_components/Modal'
import modalWrapper, {MODAL_CONTEXT_TYPES} from '../../../../../shared_components/ModalWrapper'
import {Cancel, Continue, ControlButton} from '../../../../../shared_components/ButtonTypes'
import {objectValues} from '../../../../../lib/shims'

import ShadowHeader from '../ShadowHeader'
import StatusToggle from './StatusToggle'
import EmailAddressInput from './EmailAddressInput'

import FirmActions from '../../../actions/FirmActions'

import './editComplianceBcc.scss'

class EditComplianceBcc extends Component {
  constructor(props) {
    super(props)

    this.state = {emails: this.initializeEmails(props)}

    this.addNewEmail = this.addNewEmail.bind(this)
    this.removeEmail = this.removeEmail.bind(this)
    this.resetComplianceBccState = this.resetComplianceBccState.bind(this)
    this.updateComplianceBccSetting = this.updateComplianceBccSetting.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.firmConfig.compliance_bcc_emails.length !== this.props.firmConfig.compliance_bcc_emails.length)
      this.setState({emails: this.initializeEmails(nextProps)})
  }

  initializeEmails(props) {
    let emailMap = {}

    if (props.firmConfig.compliance_bcc_emails.length > 0) {
      props.firmConfig.compliance_bcc_emails.forEach(email => {
        emailMap[uuid.v4()] = email
      })
    } else {
      emailMap = {[uuid.v4()]: ''}
    }

    return emailMap
  }

  addNewEmail() { this.setState({emails: {...this.state.emails, [uuid.v4()]: ''}}) }

  removeEmail(emailKey) {
    const {[emailKey]: removedEmail, ...otherEmails} = this.state.emails

    this.setState({emails: otherEmails})
  }

  updateComplianceBccSetting() {
    if (this.isValidForm()) {
      FirmActions.updateFirmConfig({
        firmId: this.props.firmId,
        data: {
          firm_config:
          {
            compliance_bcc_enabled: this.props.isEnabled,
            compliance_bcc_emails: this.complianceBccEmails()
          }
        }
      })
      this.context.closeModal()
    }
  }

  complianceBccEmails() {
    if (this.refs.form && this.refs.form.formData())
      return Object.keys(this.state.emails).map(key => this.refs.form.formData()[key]).filter(value => value.trim())
    else
      return objectValues(this.state.emails)
  }

  isValidForm() {
    if (this.refs.form)
      return (this.refs.form.validate().length === 0)
    else
      return true
  }

  resetComplianceBccState() {
    this.props.resetComplianceBccState()
    this.context.closeModal()
  }

  statusToggleProps(isEnabled) {
    return {
      description: {
        disabled: 'No copy of the client invitation will be sent.',
        enabled: 'A copy of each client invitation will be sent to the addresses listed below.'
      },
      enabledChecked: isEnabled,
      name: 'compliance_invitation',
      onChange: this.props.toggleComplianceBccState,
      statusText: {
        disabled: 'Disabled',
        enabled: 'Enabled'
      }
    }
  }

  emailProps(emailKey, emailValue, index) {
    return {
      emailKey,
      email: emailValue,
      removeEmail: this.removeEmail,
      index,
      name: emailKey,
      validator: index === 0 ? Forms.Validators.emailValidator : Forms.Validators.emailNotRequiredValidator
    }
  }

  render() {
    return (
      <div className='edit-config edit-compliance-bcc'>
        <ControlButton faClass='fa-pencil-square-o' onClick={this.context.showModal} buttonText='Edit' />
        <Modal visible={this.props.showModal}>
          <ShadowHeader headerText='Compliance: Invitation BCC' />
          <div className='config-wrapper'>
            <StatusToggle {...this.statusToggleProps(this.props.isEnabled)} />
            {
              this.props.isEnabled &&
                <div className='email-address-inputs'>
                  <Forms.Form ref='form'>
                    {
                      Object.keys(this.state.emails).map((emailKey, index) => {
                        const emailValue = this.state.emails[emailKey]
                        return (
                          <EmailAddressInput
                            key={emailKey}
                            {...this.emailProps(emailKey, emailValue, index)}
                          />
                        )
                      })
                    }
                  </Forms.Form>
                  <a onClick={this.addNewEmail} className='add-another'>+ Add another</a>
                </div>
            }
          </div>
          <div className='button-group'>
            <Cancel onClick={this.resetComplianceBccState} />
            <Continue onClick={this.updateComplianceBccSetting}>Save</Continue>
          </div>
        </Modal>
      </div>
    )
  }
}

EditComplianceBcc.contextTypes = MODAL_CONTEXT_TYPES

EditComplianceBcc.defaultProps = {
  showModal: false
}

EditComplianceBcc.propTypes = {
  description: PropTypes.string,
  firmId: PropTypes.string.isRequired,
  firmConfig: PropTypes.shape({
    compliance_bcc_enabled: PropTypes.bool,
    compliance_bcc_emails: PropTypes.array
  }),
  isAccountManager: PropTypes.bool,
  isEnabled: PropTypes.bool,
  isFirmActive: PropTypes.bool,
  resetComplianceBccState: PropTypes.func,
  toggleComplianceBccState: PropTypes.func,
  statusText: PropTypes.string,
  showModal: PropTypes.bool
}

export default modalWrapper(EditComplianceBcc)
