import {useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {createResource} from 'redux-json-api'

import routerUtils from '../../../../lib/routerUtils'
import OrganizationHeader from './OrganizationHeader'
import OrganizationForm from './OrganizationForm'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

export const OrganizationsTableHeader = props => {
  const [processing, setProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {alwaysNotify, dismissToastNotification} = useNotificationContext()

  const toggleModal = event => {
    if (event) event.preventDefault()

    setShowModal(prevShowModal => !prevShowModal)
  }

  const handleNotificationClick = organizationId => {
    routerUtils.push(`/pro/organization-admin/organizations/${organizationId}/overview`)
    dismissToastNotification()
  }

  const notifyAndFetchOrganizations = organizationData => {
    props.fetchOrganizations()
    alwaysNotify.longSuccess(
      <span>
        <a onClick={() => handleNotificationClick(organizationData.id)}>
          {organizationData.name}
        </a> has been added.
      </span>
    )
  }

  const addOrganization = data => {
    setProcessing(true)

    const resource = {
      type: 'organizations',
      attributes: {
        name: data.getIn(['organization-name', 'value']),
        'organization-logo': data.get('organization-logo'),
        'contact-card-logo': data.get('contact-card-logo')
      }
    }

    props.createResource(resource).then(response => {
      setProcessing(false)

      toggleModal()
      notifyAndFetchOrganizations({...response.data.attributes, id: response.data.id})
    }).catch(error => {
      setProcessing(false)

      if (error)
        alwaysNotify.shortError('Please add a unique name.')
    })
  }


  return (
    <span>
      <OrganizationHeader headerText='Organizations'>
        <a className='btn btn-primary' onClick={toggleModal}>Add Organization</a>
      </OrganizationHeader>
      <OrganizationForm
        actionText='Add'
        modalheaderText='Add Organization'
        loadingOrganizations={props.loadingOrganizations}
        showModal={showModal}
        closeModal={toggleModal}
        submit={addOrganization}
        toggleModal={toggleModal}
        processing={processing}
      />
    </span>
  )
}

OrganizationsTableHeader.propTypes = {
  createResource: PropTypes.func,
  fetchOrganizations: PropTypes.func,
  loadingOrganizations: PropTypes.bool
}

export default connect(null, {createResource})(OrganizationsTableHeader)
