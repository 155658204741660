import PropTypes from 'prop-types'

import ActiveLicenseClientSeatsUsage from './ActiveLicenseClientSeatsUsage'
import PurchasedSeatsCount from './PurchasedSeatsCount'

// The `seat-type-details` class is needed to have styles applied without significant refactor or duplication. --BLR
const ClientSeatsUsage = props => (
  <div className='client-seats-usage seat-type-details'>
    <h5>Client Accounts</h5>
    {
      props.status === 'active' ?
        <ActiveLicenseClientSeatsUsage {...props} /> :
        <PurchasedSeatsCount seatsCount={props.client_seats} unlimited={props.unlimited} />
    }
  </div>
)

ClientSeatsUsage.propTypes = {
  client_seats: PropTypes.number,
  status: PropTypes.string,
  unlimited: PropTypes.bool
}

export default ClientSeatsUsage
