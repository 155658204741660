import {Link} from 'react-router'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import './deputyInformation.scss'

const DeputyInformation = ({currentUserIsDeputy, everplanSummary}) => (
  <div className='deputy-information'>
    <span className='deputy-status'>You are{!currentUserIsDeputy && ' not'} a Deputy for this Everplan.</span>
    {
      currentUserIsDeputy &&
      <Link to={`cm/everplan-preview/${everplanSummary.get('id')}`} target='_blank'>View this Everplan</Link>
    }
  </div>
)

DeputyInformation.propTypes = {
  currentUserIsDeputy: PropTypes.bool,
  everplanSummary: PropTypes.instanceOf(Map)
}
export default DeputyInformation
