import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import EditProfessionalDesignation from './EditProfessionalDesignation'
import ShadowWrapper from '../../ShadowWrapper'
import ShadowHeader from '../../ShadowHeader'

import {findRawResourceById} from '../../../../../../lib/plan_data/userData'

export const ProfessionalDesignationSettings = props => {
  if (props.isAccountManager) {
    return (
      <div className='professional-designation-settings'>
        <ShadowHeader headerText='Professional Designation' />
        <ShadowWrapper>
          <div className='settings row-wrapper'>
            <p>Designation: </p>
            <div className='column-wrapper'>
              <p>{props.professionalDesignation}</p>
              <p className='description' />
              {
                <EditProfessionalDesignation {...props} />
              }
            </div>
          </div>
        </ShadowWrapper>
      </div>
    )
  } else {
    return null
  }
}


const mapStateToProps = (state, {firmId}) => ({
  firm: findRawResourceById({
    rawResource: state.api.firms,
    id: firmId
  })
})

ProfessionalDesignationSettings.propTypes = {
  firmId: PropTypes.string,
  firm: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  professionalDesignation: PropTypes.string
}

export default connect(mapStateToProps, {readEndpoint})(ProfessionalDesignationSettings)
