import {Map} from 'immutable'

export const femalePronouns = Map({
  id: 1,
  subject: 'she',
  object: 'her',
  'modifying-possessive': 'her',
  'standalone-possessive': 'hers',
  reflexive: 'herself',
  'legacy-gender': 'f',
  'plural-conjugation': false
})

export const malePronouns = Map({
  id: 2,
  subject: 'he',
  object: 'him',
  'modifying-possessive': 'his',
  'standalone-possessive': 'his',
  reflexive: 'himself',
  'legacy-gender': 'm',
  'plural-conjugation': false
})

export const nonBinaryPronouns = Map({
  id: 3,
  subject: 'they',
  object: 'them',
  'modifying-possessive': 'their',
  'standalone-possessive': 'theirs',
  reflexive: 'themself',
  'legacy-gender': null,
  'plural-conjugation': true
})
