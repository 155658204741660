import PropTypes from 'prop-types'
import React from 'react'
import {Map} from 'immutable'

import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import BulkInviteActions from '../../actions/BulkInviteActions'
import Picker from './Picker'


import LegacyLoader from '../../../../shared_components/LegacyLoader'

export class PickPending extends React.Component {
  advisor() { return (this.props.advisorSeats.find(seat => seat.advisor_seat_id.toString() === this.props.params.advisorSeatId) || {}) }
  render() {
    return (
      <LegacyLoader loading={this.props.loadingPendingClients}>
        <div className='preheader'>RESEND PENDING INVITATIONS</div>
        <h2 className='select-bulk-clients-header'>Select Clients</h2>
        <p className='select-bulk-clients-intro'>
          Below are the clients of <strong>{this.advisor().name}</strong> who have not responded to their invitations to
          join Everplans. Please select the ones you would like to resend invitations to.
        </p>
        <Picker
          advisor={this.advisor()}
          clients={this.props.pending_clients}
          closer={this.props.closer}
          next={this.props.next}
          firmConfig={this.props.firmConfig}
          organization={this.props.organization}
        />
      </LegacyLoader>
    )
  }
}

Container.registerAction('pending_clients', BulkInviteActions)
export default bindResources(PickPending, {id: 'advisorSeatId', name: 'pending_clients', type: 'item'})

PickPending.defaultProps = {
  advisorSeats: [],
  pending_clients: {
    data: []
  }
}

PickPending.propTypes = {
  pending_clients: PropTypes.object,
  firmConfig: PropTypes.instanceOf(Map),
  closer: PropTypes.func,
  next: PropTypes.func,
  processing: PropTypes.bool,
  organization: PropTypes.instanceOf(Map)
}
