import PropTypes from 'prop-types'
import ImageUpload from '../admin/ImageUpload'

const UploadWrapper = props => (
  <div className='upload-wrapper flex-container'>
    <label className='logo-upload-label'>{props.labelText}:</label>
    <div className='logo-upload-wrapper flex-container'>
      {
        props.imageUrl &&
        <img className={props.className} src={props.imageUrl} alt='logo' />
      }
      <ImageUpload onImageLoad={props.setImageUrl}>
        <a>{props.imageUrl ? 'Change' : 'Upload Logo'}</a>
      </ImageUpload>
    </div>
  </div>
)

UploadWrapper.propTypes = {
  className: PropTypes.string,
  labelText: PropTypes.string,
  imageUrl: PropTypes.string,
  setImageUrl: PropTypes.func
}

export default UploadWrapper
