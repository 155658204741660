import PropTypes from 'prop-types'
import {Map} from 'immutable'
import {Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import {Link} from 'react-router'

import routerUtils from '../../../../../lib/routerUtils'
import {addPossessive} from '../../../../../lib/tools'
import {dateToFromNowDaily} from '../../../../../lib/dateTools'
import {activityButtonText} from '../../../../../lib/clientActivityTools'

import './clientActivity.scss'

const ClientActivity = ({activity}) => {
  const onButtonClick = () => {
    if (activity.get('type') === 'network-lead')
      return routerUtils.push(`pro/clients/add_individual/${activity.get('deputy-id')}/${activity.get('id')}`)
    routerUtils.push(`pro/household/${activity.get('id')}`)
  }

  const name = () => (
    activity.get('type') === 'network-lead' ? addPossessive(activity.get('name')) : activity.get('name')
  )

  return (
    <div className='client-activity'>
      <div className='left-activity'>
        <div className='activity-text'>
          <Link className='client-name' to={`pro/household/${activity.get('id')}`}>
            <span>{name()}</span>
          </Link>
          &nbsp;
          <span>{activity.get('text')}</span>
        </div>
        <span className='activity-time'>{dateToFromNowDaily(activity.get('timestamp'))}</span>
      </div>
      <Accept className='activity-action' onClick={onButtonClick}>{activityButtonText(activity)}</Accept>
    </div>
  )
}

ClientActivity.propTypes = {
  activity: PropTypes.instanceOf(Map)
}

export default ClientActivity
