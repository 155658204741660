import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {useMemo} from 'react'

import JiclPreviewResponseGroup from './JiclPreviewResponseGroup'

import {SCREEN_CONFIG} from '../../../lib/jiclMainSectionConfiguration'
import {allViewElementIds, findViewByName} from '../../../../../lib/viewTools'


const JiclPreviewResponseGroupContainer = props => {
  const sectionConfig = SCREEN_CONFIG.get(props.section)
  const viewResponses = (view, responses) => {
    const viewElementIds = allViewElementIds(view)
    const potentialResponses = responses.filter(response =>
      viewElementIds.includes(response.get('element-id'))
    )

    const dependentResponseElements = view.getIn([
      'data',
      'dependent-response-elements'
    ])

    if (!dependentResponseElements.isEmpty()) {
      const dependentResponseElementValues = dependentResponseElements.map(
        element => element.get('value')
      )
      const dependentResponseElementIds = dependentResponseElements.map(
        element => element.get('element-id')
      )

      const groupIdResponse = potentialResponses.find(
        response =>
          dependentResponseElementValues.includes(response.get('value')) &&
          dependentResponseElementIds.includes(response.get('element-id'))
      )

      return (
        groupIdResponse ?
          potentialResponses.filter(response => response.get('group-id').equals(groupIdResponse.get('group-id'))) :
          List()
      )
    } else {
      return potentialResponses
    }
  }

  const responsesWithViewIdMap = ({responses, view}) => {
    const reducer = (collection, response) =>
      collection.set(
        response.get('id'),
        response.set('view-id', view.get('id'))
      )

    return responses.reduce(reducer, Map())
  }

  const allViews = (viewNames, views, responses) => {
    const reducer = (collection, viewName) => {
      const foundView = findViewByName({views, viewName})
      const foundResponses = responsesWithViewIdMap({
        responses: viewResponses(foundView, responses),
        view: foundView
      })

      return collection.merge(
        Map({
          views: collection.get('views').push(foundView),
          responses: collection.get('responses').merge(foundResponses)
        })
      )
    }

    return viewNames.reduce(reducer, Map({views: List(), responses: Map()}))
  }

  const viewsData = allViews(
    sectionConfig.getIn(['viewConfig', 'names']),
    props.views,
    props.everplanResponse.get('responses')
  )

  // I don't want this to be calculated all the time but only when the props.section changes.
  const formattedResponses = useMemo(
    () =>
      viewsData
        .get('responses')
        .toList()
        .groupBy(response => response.get('view-id')),
    [sectionConfig, props.responses, props.newContacts]
  )

  return (
    <JiclPreviewResponseGroup
      {...props}
      sectionConfig={sectionConfig}
      sectionViews={viewsData.get('views')}
      formattedResponses={formattedResponses}
      responses={viewsData.get('responses')}
    />
  )
}

JiclPreviewResponseGroupContainer.propTypes = {
  everplanResponse: PropTypes.instanceOf(Map),
  section: PropTypes.string,
  views: PropTypes.instanceOf(List),
  responses: PropTypes.instanceOf(Map),
  newContacts: PropTypes.instanceOf(List)
}

export default JiclPreviewResponseGroupContainer

