import PropTypes from 'prop-types'

import {Button} from '../../../../../dashboard/src/components/blocks/Buttons'

import {everplansWhiteLogo} from '../images'

import './jiclRecipientViewBanner.scss'

const JiclRecipientViewBanner = props => (
  <div className='jicl-recipient-view-banner'>
    <img
      className='everplans-logo'
      src={everplansWhiteLogo}
      alt='everplans-logo'
    />
    <div className='header-text'>Create your own Everplan</div>
    <div className='body-text'>
      Organize your most critical information and share it with important people
      in your life.
    </div>
    <Button onClick={props.goToSignUp}>Get Started</Button>
    <div className='bottom-text'>
      It's free, secure and only takes a few mins.
    </div>
  </div>
)


JiclRecipientViewBanner.propTypes = {
  goToSignUp: PropTypes.func
}

export default JiclRecipientViewBanner
