import {connect} from 'react-redux'
import {updateResource, createResource} from 'redux-json-api'
import Categories from './Categories'

const EverplanDetails = ({loading, ...otherProps}) => {
  if (loading) {
    return null
  } else {
    return (
      <div className='everplan-details'>
        <Categories {...otherProps} actor='pro' context='pro_client_dashboard' />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  categories: state.categories,
  kits: state.kits,
  listMappings: state.listMappings,
  views: state.views
})

export default connect(mapStateToProps, {updateResource, createResource})(EverplanDetails)
