import PropTypes from 'prop-types'
import {useState, useCallback} from 'react'
import {Map} from 'immutable'

import {Cancel, Continue, ControlButton} from '../../../../../shared_components/ButtonTypes'
import Modal from '../../../../../shared_components/Modal'
import ShadowHeader from '../../shared_firm/ShadowHeader'
import ShadowWrapper from '../../shared_firm/ShadowWrapper'
import StatusToggle from '../../shared_firm/settings/StatusToggle'

import './editMandatoryTfa.scss'

const TEXTS = {
  disabled: 'Clients of the firm will not be required to set up two-factor authentication during signup.',
  enabled: 'Clients of the firm will be required to set up two-factor authentication during signup.'
}

const EditMandatoryTfa = ({organizationConfig, processing, updateSettings}) => {
  const [showModal, setShowModal] = useState(false)
  const [enabled, setEnabled] = useState(organizationConfig.get('mandatory-tfa-enabled', false))

  const toggleModal = useCallback(() => setShowModal(!showModal))

  const onChange = useCallback(() => setEnabled(!enabled))

  const onSubmit = useCallback(() => updateSettings({'mandatory-tfa-enabled': enabled}, toggleModal))

  return (
    <ShadowWrapper>
      <div className='edit-mandatory-tfa flex-container'>
        <p>Status:</p>
        <div className='details'>
          <div className='status'>
            {enabled ? 'Enabled' : 'Disabled'}
          </div>
          <div className='description'>{enabled ? TEXTS.enabled : TEXTS.disabled}</div>
          <div>
            <ControlButton faClass='fa-pencil-square-o' onClick={toggleModal} buttonText='Edit' />
            <Modal closer={toggleModal} visible={showModal}>
              <ShadowHeader headerClassName='heading' headerText='Mandatory Two-Factor Authentication for Clients' />
              <div className='content'>
                <StatusToggle
                  description={TEXTS}
                  enabledChecked={enabled}
                  name='cobranding'
                  onChange={onChange}
                  statusText={{
                    disabled: 'Disabled',
                    enabled: 'Enabled'
                  }}
                />
              </div>
              <div className='button-group'>
                <Cancel onClick={toggleModal} />
                <Continue loading={processing} onClick={onSubmit}>Save</Continue>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </ShadowWrapper>
  )
}

EditMandatoryTfa.propTypes = {
  organizationConfig: PropTypes.instanceOf(Map),
  processing: PropTypes.bool,
  updateSettings: PropTypes.func
}

export default EditMandatoryTfa
