import MyHomeInsuranceForm from './MyHomeInsuranceForm'

const MyHomeInsurance = () => (
  <section>
    <h2>My Home Insurance</h2>
    <MyHomeInsuranceForm />
  </section>
)

export default MyHomeInsurance

