import PropTypes from 'prop-types'

import {newSubscriptionMessaging} from '../../../../lib/tools'

import AppShell from '../../../../shared_components/blocks/AppShell'
import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import CouponOnlyActivation from '../payment_page/CouponOnlyActivation'
import Loader from '../../../../shared_components/NewLoader'
import PartnerActions from '../../actions/PartnerActions'
import PaymentPage from '../payment_page/PaymentPage'

import './newSubscriptionController.scss'

Container.registerAction('partner', PartnerActions)

const NewSubscriptionController = props => {
  if (props.loadingPartner)
    return <Loader loading={props.loadingPartner} />

  const partner = props.partner.data

  return (
    <AppShell
      className='new-subscription-controller'
      headerFlavor='transactional'
      includeFooter={false}
      useNewLoader={true}>
      {
        partner.coupon_only_activation ?
          <CouponOnlyActivation className='core box content-box' partner={partner} /> :
          <PaymentPage
            className='core box content-box'
            interstitials
            messaging={newSubscriptionMessaging(partner)}
            partner={partner}
          />
      }
    </AppShell>
  )
}

NewSubscriptionController.propTypes = {
  partner: PropTypes.shape({
    data: PropTypes.shape({
      coupon_only_activation: PropTypes.bool
    })
  }),
  loadingPartner: PropTypes.bool
}

export default bindResources(NewSubscriptionController, [{name: 'partner', type: 'itemNoId'}])
