import {Route, IndexRoute} from 'react-router'

import bindResources from '../../../shared_components/BindToStores'
import storePrototype from '../../../shared_components/StorePrototype'
import Container from '../../../lib/Container'

import AuthGateway from '../../../shared_components/AuthGateway'
import AuthAppController from '../components/AuthAppController'
import CheckResetEmail from '../components/reset_password/CheckResetEmail'
import PasswordResetComplete from '../components/reset_password/PasswordResetComplete'
import TfaSettings from '../components/tfa/TfaSettings'
import TfaIntro from '../components/tfa/TfaIntro'
import PrivacySecurity from '../components/PrivacySecurity'
import SendResetInstructions from '../components/reset_password/SendResetInstructions'
import ResetPassword from '../components/reset_password/ResetPassword'
import SignInController from '../components/sign_in/SignInController'
import TfaPrimarySignIn from '../components/sign_in/TfaPrimarySignIn'
import TfaSecondarySignIn from '../components/sign_in/TfaSecondarySignIn'
import TfaBackupSignIn from '../components/sign_in/TfaBackupSignIn'
import TfaSignInAlternatives from '../components/sign_in/SignInAlternatives'
import EmailAndPasswordSignIn from '../components/sign_in/EmailAndPasswordSignIn'
import InvalidToken from '../components/invalid_token/InvalidToken'
import SignUpActions from '../actions/SignUpActions'
import ResendConfirmation from '../components/resend_confirmation/ResendConfirmation'
import SignUpController from '../components/sign_up/SignUpController'
import SignUp from '../components/sign_up/SignUp'
import CheckEmail from '../components/sign_up/CheckEmail'
import ConfirmUser from '../components/sign_up/ConfirmUser'

// SSO stuff
import OrionActions from '../actions/OrionActions'
Container.registerAction('orion_sso', OrionActions)

import OrionSSO from '../components/sso/OrionSSO'
import OrionLink from '../components/sso/OrionLink'
import OAuth2SSO from '../components/sso/OAuth2SSO'
import OpenIdConnectLogin from '../components/sso/OpenIdConnectLogin'
import OpenIdConnectLoginCallback from '../components/sso/OpenIdConnectLoginCallback'
import OpenIdConnectLinkCallback from '../components/sso/OpenIdConnectLinkCallback'
import AxiosConnectedComponentReact from '../../../shared_components/AxiosConnectedComponent'

Container.registerAction('user_confirmation', SignUpActions)

Container.registerStore('sign_up', storePrototype([SignUpActions.Types.DID_SIGN_UP]))
Container.registerStore('user_confirmation', storePrototype(SignUpActions.Types.DID_FETCH_USER_CONFIRMATION))
Container.registerStore('resendConfirmationEmail', storePrototype([SignUpActions.Types.DID_RESEND_CONFIRMATION_EMAIL]))
Container.registerStore('confirm_user', storePrototype(SignUpActions.Types.DID_CONFIRM_USER))


// redirects can be found in AuthApp.react.js
// logged in users and any user navigating to `/auth` and `/` are redirected to the api host --NP
export default (
  <Route path='/' component={AuthAppController}>
    <Route path='auth'>
      <Route path='password/new' component={SendResetInstructions} />
      <Route path='password/edit/:password_reset_token' component={ResetPassword} />
      <Route path='password/check-mail' component={CheckResetEmail} />
      <Route path='password/reset-complete' component={PasswordResetComplete} />
      <Route path='invalid_token/:invite_type' component={InvalidToken} />
      <Route path='confirmation/:userConfirmationId' component={bindResources(ConfirmUser, {name: 'user_confirmation', type: 'item'}, ConfirmUser.onBoundUpdate)} />
      <Route path='resend-confirmation' component={ResendConfirmation} />
      <Route path='sign-up' component={SignUpController}>
        <IndexRoute component={SignUp} />
        <Route path='check-email' component={CheckEmail} />
      </Route>
      <Route path='sign_in' component={SignInController}>
        <IndexRoute component={EmailAndPasswordSignIn} />
        <Route path='tfa_primary' component={TfaPrimarySignIn} />
        <Route path='tfa_secondary' component={TfaSecondarySignIn} />
        <Route component={AxiosConnectedComponentReact}>
          <Route path='tfa_backup' component={TfaBackupSignIn} />
        </Route>
        <Route path='tfa_alternatives' component={TfaSignInAlternatives} />
      </Route>
      <Route path='sso'>
        <Route path='orion/:token' component={OrionSSO} />
        <Route component={AuthGateway}>
          <Route path='orion/link/:token' component={OrionLink} />
        </Route>
        <Route path='oidc/login/:organizationSsoSettingId' component={OpenIdConnectLogin} />
        <Route path='oidc/login-callback' component={OpenIdConnectLoginCallback} />
        <Route path='oidc/link-callback' component={OpenIdConnectLinkCallback} />
        <Route path='orion' component={OrionSSO} />
        <Route path='oauth2/:token' component={OAuth2SSO} />
      </Route>
      <Route component={AuthGateway}>
        <Route path='security' component={PrivacySecurity} />
        <Route path='tfa-intro' component={TfaIntro} /> {/* The footer on the tfa-intro is hidden when it is everplans branded cobranding style becasue of a check in `Footer.react` --ZD*/}
        <Route path='tfa-settings' component={TfaSettings} />
      </Route>
    </Route>
  </Route>
)
