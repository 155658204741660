import {useCallback, useEffect} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {deleteResource, hydrateStore, readEndpoint} from 'redux-json-api'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {v4} from 'uuid'

import {capitalize} from '../../../../lib/tools'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import {currentUserConfig} from '../../../../lib/userTools'

const DevBulkActionsDropdown = props => {
  const {alwaysNotify} = useNotificationContext()

  const id = v4()
  const devClientsStub = {data: [{id, type: 'dev-clients', attributes: {}}]}

  useEffect(() => {
    // need to hydrate store so that redux-json-api finds the record with the id being used and not barf - Atanda
    if (__DEV__)
      props.dispatch(hydrateStore(devClientsStub))
  }, [props.dispatch])

  const deleteClientSeats = (payload, config = {}) => props.deleteResource(payload, config).then(
    () => {
      alwaysNotify.longSuccess(`${capitalize(config.params.type)} client seats deleted successfully.`)
      props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)
    }).catch(() => alwaysNotify.shortError(`Oops! something went wrong while deleting ${capitalize(config.params.type)} client seats.`))

  const deleteDraftClients = useCallback(() => {
    const payload = {id, type: 'dev-clients'}

    deleteClientSeats(payload, {params: {type: 'draft'}})
  }, [])

  const deletePendingClients = useCallback(() => {
    const payload = {id, type: 'dev-clients'}

    deleteClientSeats(payload, {params: {type: 'pending'}})
  }, [])

  if (__DEV__) {
    return (
      <span>
        <li><a onClick={deleteDraftClients}>Delete Draft Clients</a></li>
        <li><a onClick={deletePendingClients}>Delete Pending Clients</a></li>
      </span>
    )
  } else {
    return null
  }
}

DevBulkActionsDropdown.propTypes = {
  userConfig: PropTypes.instanceOf(Map),
  deleteResource: PropTypes.func,
  readEndpoint: PropTypes.func
}

const mapStateToProps = ({api}) => ({userConfig: currentUserConfig(api)})

const mapDispatchToProps = dispatch => ({
  dispatch,
  deleteResource: bindActionCreators(deleteResource, dispatch),
  readEndpoint: bindActionCreators(readEndpoint, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DevBulkActionsDropdown)
