import PropTypes from 'prop-types'
import React from 'react'

import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'

import checkmarkCircleSvg from '../../../../shared_assets/checkmark-circle-black.svg'
import {noop} from '../../../../lib/tools'

import './beforeWeContinue.scss'


export default class BeforeWeContinue extends React.Component {
  constructor() {
    super()

    this.state = {processing: false}

    this.returnToController = this.returnToController.bind(this)
  }
  returnToController() { this.setState({processing: true}, () => this.props.returnToController()) }

  render() {
    return (
      <div className='before-we-continue central-content-box'>
        <h1>Before we continue...</h1>
        <div className='row-wrapper'>
          <img alt='Check mark in circle' className='checkmark-circle-black' src={checkmarkCircleSvg} />
          <p>
            You have one or more action items associated with your account that must be addressed before continuing.
          </p>
        </div>
        <Accept processing={this.state.processing} onClick={this.returnToController}>Continue</Accept>
      </div>
    )
  }
}

BeforeWeContinue.defaultProps = {returnToController: noop}

BeforeWeContinue.propTypes = {returnToController: PropTypes.func.isRequired}
