import PropTypes from 'prop-types'
import React from 'react'

import {Cancel, Continue, ControlButton} from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import Modal from '../../../../shared_components/Modal'
import ModalWrapper from '../../../../shared_components/ModalWrapper'
import SessionStore from '../../../../auth/src/stores/SessionStore'

import FirmActions from '../../actions/FirmActions'

export class SendFirmUserInvite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {currentUser: SessionStore.getState().currentUser}
    this.sendInvite = this.sendInvite.bind(this)
  }

  sendInvite() {
    FirmActions.inviteFirmUser(Object.assign(
      this.state.currentUser.account_manager ? this.refs.form.formData() : {},
      this.props.params
    ))
    this.context.closeModal()
  }

  render() {
    const buttonText = this.props.status === 'draft' ? `Send Invitation to ${this.props.firstName}` : 'Resend Invitation'
    return (
      <section>
        <ControlButton
          buttonText={buttonText}
          faClass='fa-envelope-o'
          onClick={this.state.currentUser.account_manager ? this.context.showModal : this.sendInvite}
        />
        <Modal closer={this.context.closeModal} visible={this.props.showModal}>
          <div className='modal-header'>Send Invitation Email</div>
          <p>Add a personal message:</p>
          <Forms.Form ref='form' onSubmit={this.sendInvite}>
            <Forms.TextArea autoFocus={true} name='invite_text' wrap={false} />
          </Forms.Form>
          <div className='button-group'>
            <Cancel onClick={this.context.closeModal} />
            <Continue onClick={this.sendInvite}>Send Invitation</Continue>
          </div>
        </Modal>
      </section>
    )
  }
}

SendFirmUserInvite.defaultProps = {
  firstName: '',
  status: ''
}

SendFirmUserInvite.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

SendFirmUserInvite.propTypes = {
  firstName: PropTypes.string.isRequired,
  params: PropTypes.shape({
    firmId: PropTypes.string,
    firmUserSeatId: PropTypes.string
  }).isRequired,
  status: PropTypes.string.isRequired
}

export default ModalWrapper(SendFirmUserInvite)
